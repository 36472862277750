import { Commit, Dispatch } from 'vuex'

import {
  Account,
  State,
  Task
} from '@/types/ravana'

// initial state
const state: State = {
  accounts: null,
  current: null
}

// getters
const getters = {
  accounts (): Array<Account> | null {
    return state.accounts
  },
  current (): Account | null {
    return state.current
  }
}

// mutations
const mutations = {
  accounts (state: State, data: Array<Account> | null): void {
    state.accounts = data
  },
  current (state: State, data: Account | null): void {
    state.current = data
  }
}

// actions
const actions = {
  deleteAccount ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_RAVANA_URL}/accounts/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccount ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Account> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_RAVANA_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          commit('current', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Account>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_RAVANA_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((data: Array<Account>) => {
          commit('accounts', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveAccount (_: unknown, account: Account): Promise<Account> {
    let url = `${process.env.VUE_APP_RAVANA_URL}/accounts`

    if (account.id) {
      url += `/${account.id}`
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, account)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  },
  saveAccountTasks (_: unknown, data: { id: string, tasks: Array<Task> }): Promise<Array<Task>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_RAVANA_URL}/accounts/${data.id}/tasks`, {
        tasks: data.tasks
      })
        .then((response: Response) => response.json())
        .then((data: Array<Task>) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
