<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="cancelModal" />
      <h3 class="gb-title">
        <icon name="payment"/>
        {{ $t('modal.confirm_paymentmethod_title') }}
      </h3>
    </div>

    <div class="modal-body">
      <div>
        <form-gen class="form" :formDesc="formDesc" :model="model" :sendData="sendData"/>
      </div>
    </div>

    <div class="modal-footer">
      <router-link class="gb-btn gb-btn-secondary" :to="{ name: 'settings-billing-payment-add' }"
        v-on:click.native="closeModal()">
        {{ $t('modal.confirm_paymentmethod_choose_other_msg')}}
      </router-link>
      <button class="gb-btn" type="submit" v-on:click="confirm()" >
        {{ $t('modal.confirm_paymentmethod_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'
import FormGen from '@/components/FormGen.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  mixins: [BaseModal],
  name: 'ConfirmPaymentMethodModal',
  components: {
    CloseModalButton,
    Icon,
    FormGen
  },
  data () {
    return {
      formDesc: {
        nonFieldsErrors: null,
        fieldsets: [
          {
            legend: null,
            fields: [
              {
                kind: 'Select',
                name: 'card',
                options: [],
                placeholder: null,
                validation: null,
                validationMsg: null,
                disabled: false,
                errors: null
              }
            ]
          }
        ]
      },
      model: {
        card: ''
      },
      sendData: false
    }
  },
  computed: {
    card () {
      let defaultCard
      this.$store.getters['payment/cards'].forEach(card => {
        if (card.default === true) {
          defaultCard = card
        }
      })
      return defaultCard
    },
    cards () {
      return this.$store.getters['payment/cards']
    }
  },
  methods: {
    confirm () {
      const card = this.model.card
      this.closeModal()
      this.$store.dispatch('global/showLoading')

      if (card) {
        this.$store.dispatch('payment/setDefaultCard', card)
          .then(() => {
            this.$store.dispatch('payment/fetchCards')
            this.subscribe()
          })
      } else {
        this.subscribe()
      }
    },
    subscribe () {
      this.$router.push({ name: 'settings-billing-summary' })
    }
  },
  created () {
    this.$store.dispatch('payment/fetchCards')
  },
  watch: {
    cards: {
      handler (cards) {
        const opts = [];
        (cards || []).forEach(card => {
          opts.push({
            text: `${card.alias} (**** **** **** ${card.last4})`,
            value: card.id
          })
          if (card.default) {
            this.model.card = card.id
          }
        })

        this.formDesc.fieldsets[0].fields[0].options = opts
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.modal-container[action="confirm-paymentmethod"] {
  .gb-btn {
    margin-top: 1rem !important;
  }

  select {
    margin-bottom: 0 !important;
  }
}
</style>
