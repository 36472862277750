<template>
  <form ref="form" v-on:submit.prevent="validate">
    <div class="gb-content-col">
      <form-gen class="form" :formDesc="formDesc" :model="model"
                :sendData="sendData" :countryChange="countryChange" />
      <div class="form-footer">
        <button class="gb-btn" v-on:click="confirm()">
          {{ $t('validate') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script type="text/javascript">
import FormView from '@/components/FormView.vue'

export default {
  name: 'OfferForm',
  extends: FormView,
  props: {
    callback: Function
  },
  data () {
    return {
      formDesc: {
        nonFieldsErrors: null,
        fieldsets: [
          {
            legend: null,
            fields: [{
              kind: 'InputText',
              name: 'name',
              label: this.$t('settings.offers.name'),
              placeholder: this.$t('settings.offers.name'),
              validation: null,
              validationMsg: null,
              disabled: false,
              errors: null,
              required: true
            }, {
              kind: 'InputTel',
              name: 'phone_number',
              label: this.$t('settings.offers.phone_number'),
              placeholder: this.$t('settings.offers.phone_number'),
              validation: null,
              validationMsg: null,
              disabled: false,
              errors: null,
              required: true
            }, {
              kind: 'Textarea',
              name: 'details',
              label: this.$t('settings.offers.details'),
              placeholder: this.$t('settings.offers.details'),
              validation: null,
              validationMsg: null,
              disabled: false,
              errors: null,
              required: false
            }]
          }
        ]
      },
      model: {
        country: null,
        name: null,
        phone_number: null,
        details: ''
      }
    }
  },
  computed: {
    customer () {
      return this.$store.getters['user/customer']
    },
    formData () {
      if (this.user !== null && this.customer !== null) {
        const data = Object.assign({}, this.user, this.customer)
        return data
      }
      return null
    },
    user () {
      return this.$store.getters['user/user']
    }
  },
  methods: {
    countryChange (args) {
      this.model.country = args.iso2
    },
    fetchData () {
      if (this.customer === null) {
        this.$store.dispatch('user/fetchCustomer')
      }
      if (this.formData.first_name || this.formData.last_name) {
        this.model.name = `${this.formData.first_name} ${this.formData.last_name}`
      }
      if (this.formData.phone_number) {
        this.model.phone_number = this.formData.phone_number
      }
    },
    confirm () {
      if (this.$refs.form.reportValidity()) {
        const data = this.model
        this.$store.dispatch('user/chooseOffer', data)
          .then(() => {
            this.$store.dispatch('global/hideModal')
            this.$store.dispatch(
              'global/notification', 'settings.offers.changes_saved')
          })
          .catch(err => {
            if (err.status === 400) {
              this.setErrorsNew(err)
            } else {
              this.$store.commit('global/handleHttpError', err)
            }
          })
      }
    },
    validate () {}
  },
  watch: {
    $route: 'fetchData'
  },
  created () {
    this.fetchData()
  }
}
</script>

<style lang="scss" scoped>
.form-footer {
  margin-top: 35px;
  text-align: right;

  .gb-btn {
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
