<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <h2>{{ $t(isUserPaused ? 'header.unbreak' : 'header.break') }}</h2>
    </div>

    <div class="modal-body">

    </div>

    <div class="modal-footer">
      <button v-on:click="closeModal" class="gb-btn gb-btn-secondary">
        {{ $t('Cancel') }}
      </button>
      <button v-on:click="validate" class="gb-btn">
        {{ $t('validate') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'

export default {
  mixins: [BaseModal],
  name: 'ConfirmAgentPauseModal',
  computed: {
    isUserPaused () {
      return this.$store.getters['GBA/user/isUserPaused']
    }
  },
  methods: {
    validate () {
      this.$store.dispatch('GBA/user/sendPause', !this.isUserPaused)
      this.closeModal()
    }
  }
}
</script>
