import { Diagram, NodeModel } from '@syncfusion/ej2-vue-diagrams'
import { GbNode } from '@/types/botBuilder'

const getDiagram = (): Diagram | null => {
  const diagramObj = document.getElementById('diagram')
  if (diagramObj !== null) {
    return (diagramObj as any).ej2_instances[0]
  }
  return null
}

const getSelectedItem = (): NodeModel | null => {
  const diagram = getDiagram()

  let selectedItem
  if (diagram && diagram.selectedItems &&
    diagram.selectedItems.nodes &&
    diagram.selectedItems.nodes.length > 0) {
    selectedItem = diagram.selectedItems.nodes[0]
    return selectedItem
  }
  return null
}

const validators = {
  checkUrlValidator: function (value: string, $store: any): boolean {
    if (value) {
      const inputUrl = document.createElement('input')
      inputUrl.setAttribute('type', 'url')
      inputUrl.setAttribute('value', value)
      return inputUrl.checkValidity()
    }
    return true
  },
  customTagValidator: function (value: string, $store: any): boolean {
    if (value) {
      const selectedItem = getSelectedItem()
      if (selectedItem && selectedItem.data) {
        const bot = $store.getters['botBuilder/getBot']
        let found = false
        const selectedNodeUuid = (selectedItem.data as any).uuid.substr(4)

        for (const nodeUuid in bot.nodes) {
          const node: GbNode = bot.nodes[nodeUuid]
          if (nodeUuid !== selectedNodeUuid &&
              node.customTag &&
              node.customTag !== '' &&
              node.customTag.toLowerCase() === value.toLowerCase()) {
            found = true
          }
        }
        return !found
      }
    }
    return true
  },
  subtitleValidator: function (value: string, $store: any): boolean | Array<boolean | string> {
    const diagram = getDiagram()
    const router = (diagram as any).vueInstance.$router
    if (router.currentRoute.params.channelId &&
        router.currentRoute.params.channelId === 'whatsapp') {
      const selectedItem = getSelectedItem()
      if (!value || value === '') {
        if (selectedItem && (selectedItem as any).data.customData.action === 'button') {
          return false
        }
      } else {
        if (diagram && selectedItem) {
          let parentNode
          let connectorId: string | null = null
          if ((selectedItem as any).inEdges && (selectedItem as any).inEdges.length === 1) {
            connectorId = (selectedItem as any).inEdges[0]
          }
          diagram.nodes.forEach((node: any) => {
            if (node.outEdges.includes(connectorId)) {
              parentNode = node
            }
          })
          if ((selectedItem as any).data.customData.action === 'choice' &&
              (parentNode as any).data.customData.action === 'button' &&
              value.length > 72) {
            return [false, 'diagram.actions.forms.choice.subtitle.error-too-long-71-msg']
          } else if ((selectedItem as any).data.customData.action === 'button' &&
              value.length > 20) {
            return [false, 'diagram.actions.forms.button.subtitle.error-too-long-19-msg']
          }
        }
      }
    }
    return true
  },
  titleValidator: function (value: string, $store: any): boolean | Array<boolean | string> {
    if (value) {
      const diagram = getDiagram()
      if (diagram) {
        const selectedItem = getSelectedItem()
        if (selectedItem) {
          let parentNode
          let connectorId: string | null = null
          if ((selectedItem as any).inEdges && (selectedItem as any).inEdges.length === 1) {
            connectorId = (selectedItem as any).inEdges[0]
          }
          diagram.nodes.forEach((node: any) => {
            if (node.outEdges.includes(connectorId)) {
              parentNode = node
            }
          })
          const router = (diagram as any).vueInstance.$router
          if ((parentNode as any).data.customData.action !== 'button') {
            if (router.currentRoute.params.channelId &&
                router.currentRoute.params.channelId === 'whatsapp' &&
                value.length > 20) {
              return false
            }
          } else if ((selectedItem as any).data.customData.action === 'choice' &&
            (parentNode as any).data.customData.action === 'button' &&
            router.currentRoute.params.channelId &&
            router.currentRoute.params.channelId === 'whatsapp' &&
            value.length > 24) {
            return [false, 'diagram.actions.forms.button.title.error-too-long-23-msg']
          }
        }
      }
    }
    return true
  }
}

export default validators
