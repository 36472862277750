// Basic implementation of autoconnect with a native WS
// based on https://github.com/websockets/ws/wiki/Websocket-client-implementation-for-auto-reconnect
// and https://codepen.io/louisjordan/pen/NggLBQ

export default function WebSocketClient () {
  this.number = 0 // Message number
  this.autoReconnectInterval = 5 * 1000 // ms
  this.lastSend = null
  this.msgIsSent = false
}

WebSocketClient.prototype.open = function (url) {
  this.url = url
  this.instance = new WebSocket(this.url)
  this.instance.onopen = () => {
    this.onopen()
  }
  this.instance.onmessage = (data, flags) => {
    this.lastSend = new Date()
    this.number++
    this.onmessage(data, flags, this.number)
    this.msgIsSent = false
  }
  this.instance.onclose = (e) => {
    switch (e.code) {
      case 1000: // CLOSE_NORMAL
        // console.log('WebSocket: closed')
        break
      default: // Abnormal closure
        this.reconnect(e)
        break
    }
    this.onclose(e)
  }
  this.instance.onerror = (e) => {
    // console.log('ERROR', e)
    switch (e.code) {
      case 'ECONNREFUSED':
        this.reconnect(e)
        break
      default:
        this.onerror(e)
        break
    }
  }
}

WebSocketClient.prototype.send = function (data, option) {
  try {
    this.instance.send(data, option)
    this.msgIsSent = true
    const that = this

    window.setTimeout(() => {
      // console.log('TIMEOUT!!', that.msgIsSent)
      if (that.msgIsSent === true) {
        // Should send Raven alert
      }
    }, this.autoReconnectInterval)
  } catch (e) {
    // this.instance.emit('error', e)
  }
}

WebSocketClient.prototype.reconnect = function () {
  // console.log(`WebSocketClient: retry in ${this.autoReconnectInterval}ms`, e)
  // this.instance.removeAllListeners()
  const that = this
  setTimeout(function () {
    // console.log('WebSocketClient: reconnecting...')
    that.open(that.url)
  }, this.autoReconnectInterval)
}

WebSocketClient.prototype.onopen = function () {
  // console.log('WebSocketClient: open', arguments)
}
WebSocketClient.prototype.onmessage = function () {
  // console.log('WebSocketClient: message', arguments)
}
WebSocketClient.prototype.onerror = function () {
  // console.log('WebSocketClient: error', arguments)
}
WebSocketClient.prototype.onclose = function () {
  // console.log('WebSocketClient: closed', arguments)
}
