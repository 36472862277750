import $settings from '@/settings'

const passwordErrors = {
  min_length_digit: 'Votre mot de passe doit contenir au moins un chiffre',
  min_length_lower_characters: 'Votre mot de passe doit contenir au moins une minuscule',
  min_length_special_characters: 'Votre mot de passe doit contenir au moins un caractère spécial',
  min_length_upper_characters: 'Votre mot de passe doit contenir au moins une majuscule',
  password_entirely_numeric: 'Votre mot de passe ne peut contenir uniquement des chiffres',
  password_required: 'Mot de passe requis',
  password_requirements: 'Le mot de passe doit contenir au moins 8 caractères',
  password_too_common: 'Votre mot de passe est trop commun',
  password_too_short: 'Votre mot de passe doit contenir au moins 12 caractères',
  passwords_mismatch: 'Les deux champs ne sont pas identiques'
}

const getInvalidTimeFormat = (day) => {
  return `${day} contient un format invalide pour les horaires`
}

export default {
  Cancel: 'Annuler',
  Loading: 'Chargement en cours',
  OK: 'OK',
  TFA: {
    continue: 'Continuer',
    enable: 'Activer',
    enable_TFA: 'Activer l\'authentificateur',
    enter_code: 'Entrez le code de sécurité pour continuer',
    setup_code: 'Code de sécurité',
    steps: {
      1: {
        content: 'Téléchargez et installez Google Authenticator sur votre téléphone.',
        title: 'Télécharger Google Authenticator'
      },
      2: {
        content: 'Ouvrez Google Authenticator et scannez l\'image sur la droite depuis votre téléphone.',
        title: 'Scanner le QR Code'
      },
      3: {
        content: 'Entrez le code de sécurité pour continuer.',
        title: 'Connexion avec code de sécurité'
      },
      title: 'Sécurisez votre compte en 3 étapes simples :'
    }
  },
  account: {
    activate_title: 'Activation de votre compte',
    user_not_configured: {
      body: 'Les permissions de votre compte utilisateur n\'ont pas été définies, veuillez contacter votre administrateur afin de finaliser la configuration de votre compte.',
      title: 'Configuration de votre compte en cours'
    },
    your_email_has_been_activated: 'Votre adresse mail a été activée'
  },
  add: 'Ajouter',
  agent: {
    already_exist_same_email: 'Adresse mail déjà utilisée'
  },
  ai: {
    dialogflow: {
      agent: 'Nom de l\'agent',
      ask_delete: 'Voulez-vous vraiment supprimer cet agent Dialogflow ?',
      channel: 'Nom du canal',
      configure: 'Configurer',
      corpus: 'Corpus',
      corpus_details: 'Pour alimenter votre intelligence artificielle, importez vos intentions, entités et small talk en vous appuyant sur ce {0}.',
      corpus_link: 'modèle',
      created: 'Agent Dialogflow créé',
      deleted: 'L\'agent Dialogflow a été supprimé',
      export: 'Exporter',
      import: 'Importer',
      import_status: {
        done: 'Importé',
        failed: 'Échec d\'importation',
        running: 'En cours de traitement',
        title: 'Statut : ',
        undefined: 'Inconnu'
      },
      imported: 'Fichier importé',
      latest_import: 'Dernier import le {0}',
      login: 'Associer un compte Dialogflow',
      project: 'Projet Dialogflow',
      renew_token: 'Renouveler le jeton de connexion',
      saved: {
        body: 'L\'Agent Dialogflow "{0}" a bien été configuré, vous pouvez dès maintenant alimenter cet agent en important un corpus.',
        cancel: 'Retour à la liste d\'agents IA',
        confirm: 'Importer un corpus',
        notification: 'Agent Dialogflow mis à jour',
        title: 'Agent Dialogflow configuré'
      },
      skill_placeholder: 'Sélectionner une compétence',
      skill: 'Compétence',
      status: 'Statut',
      step1: 'Association du compte Dialogflow',
      step2: 'Configuration de l\'agent',
      subtitle: 'Liste des Agents Dialogflow',
      tree: 'Arborescence',
      type: 'Type'
    },
    knowledge_bases: {
      ask_delete: 'Voulez-vous vraiment supprimer cette base de connaissances ?',
      ask_delete_file: 'Voulez-vous vraiment supprimer le fichier "{0}" ?',
      ask_replace_file: 'Un fichier "{0}" existe déjà dans cette base de connaissances, souhaitez-vous le remplacer ?',
      configure: 'Configurer',
      deleted: 'La base de connaissances a été supprimé',
      file_deleted: 'Le fichier a été supprimé',
      kind: 'Type',
      kinds: {
        agent: 'Agent',
        placeholder: 'Sélectionner un type',
        smartcoaching: 'Smart Coaching'
      },
      label: 'Nom',
      language: 'Langue',
      languages: {
        english: 'Anglais',
        french: 'Français',
        placeholder: 'Sélectionner une langue',
        unknown: 'Inconnue'
      },
      latest_imports: 'Derniers fichiers importés :',
      file: 'Fichier',
      imported: 'Fichier importé',
      replace: 'Remplacer',
      saved: {
        body: 'La base de connaissances "{0}" a bien été créée, vous pouvez dès maintenant l\'alimenter en important un fichier.',
        cancel: 'Retour à la liste',
        confirm: 'Importer un fichier',
        notification: 'Base de connaissances mis à jour',
        title: 'Base de connaissances créée'
      },
      skills: 'Compétences',
      title: 'Liste des bases de connaissances',
      updated_at: 'Mise à jour'
    },
    ravana: {
      ask_delete: 'Voulez-vous vraiment supprimer cet agent conversationnel ?',
      behavior: 'Attitude',
      configure: 'Configurer',
      context: 'Contexte',
      created: 'Agent conversationnel créé',
      deleted: 'L\'agent conversationnel a été supprimé',
      goal: 'Objectif',
      knowledge_bases: 'Bases de connaissances',
      label: 'Libellé',
      role: 'Rôle',
      saved: {
        body: 'L\'Agent conversationnel "{0}" a bien été configuré, vous pouvez dès maintenant lui assigner des tâches optionnelles.',
        cancel: 'Retour à la liste d\'agents IA',
        confirm: 'Configurer des tâches',
        notification: 'Agent conversationnel mis à jour',
        title: 'Agent conversationnel configuré'
      },
      skill_placeholder: 'Sélectionner une compétence',
      skill: 'Compétence',
      subtitle: 'Liste des Agents conversationnels',
      session_data: {
        kind: {
          business_activity: 'Secteur d\'activité',
          country: 'Pays',
          email: 'Email',
          firstname: 'Prénom',
          generic: 'Champs génériques',
          int: 'Nombre',
          lastname: 'Nom',
          phone_number: 'Numéro de téléphone',
          siren: 'SIREN',
          static: 'Champs fixes',
          str: 'Chaîne de caractères'
        },
        kind_placeholder: 'Sélectionner un type',
        label: 'Libellé',
        label_placeholder: 'Libellé',
        title: 'Données de session'
      },
      task: {
        add: {
          label: 'Type de tâche à ajouter',
          placeholder: 'Sélectionner le type de tâche',
          title: 'Ajouter une tâche'
        },
        form: {
          business_activity: {
            keywords: 'Secteurs d\'activité',
            keywords_details: 'Saisissez ici les secteurs d\'activité, séparés par des virgules, qui ne sont pas à prendre en compte ou bien les seuls secteurs d\'activité à prendre en compte (En fonction de l\'option inclure/exclure)',
            whitelist: 'Inclure ?',
            whitelist_details: 'Choisissez quels secteurs d\'activité inclure/exclure dans la considèration de l\'agent formulaire pour récupérer des informations'
          },
          country: {
            keywords: 'Pays',
            keywords_details: 'Saisissez ici les pays, séparés par des virgules, qui ne sont pas à prendre en compte ou bien les seuls pays à prendre en compte (En fonction de l\'option inclure/exclure)',
            whitelist: 'Inclure ?',
            whitelist_details: 'Choisissez quels pays inclure/exclure dans la considèration de l\'agent formulaire pour récupérer des informations'
          },
          fields: 'Informations à récupérer',
          intents: 'Intentions',
          intents_details: 'Quelle(s) question(s) de l\'utilisateur peuve(nt) déclencher votre formulaire ?\nExemple : "Quels sont les prix ? Quelles sont vos offres ?"',
          label: 'Libellé',
          label_placeholder: 'Libellé',
          params: 'Paramètres',
          required: 'Requis ?',
          session_data_id_placeholder: 'Sélectionner une donnée de session',
          title: 'Formulaire'
        },
        kind: {
          form: 'Formulaire'
        }
      },
      tasks: 'Tâches',
      third_parties_account: 'Compte GPT',
      third_parties_account_placeholder: 'Sélectionner un compte GPT',
      timeout_message: 'Message de dépassement de délai',
      timeout_message_details: 'Message de secours utilisé lorsque le délai maximum d\'une génération de réponse par l\'agent IA est dépassé'
    },
    smartcoaching: {
      ask_delete: 'Voulez-vous vraiment supprimer cet agent smartcoaching ?',
      configure: 'Configurer',
      created: 'Agent smartcoaching créé',
      deleted: 'L\'agent smartcoaching a été supprimé',
      error: {
        gpt_mistake: 'Une erreur est survenue durant la génération des suggestions, veuillez réessayer.',
        skill_already_exists: 'La compétence "{0}" est déjà utilisée par un autre agent smartcoaching'
      },
      knowledge_bases: 'Bases de connaissances',
      label: 'Libellé',
      saved: {
        notification: 'Agent smartcoaching mis à jour',
        title: 'Agent smartcoaching configuré'
      },
      skills: 'Compétences',
      subtitle: 'Liste des Agents smartcoaching',
      third_party_account: 'Compte GPT',
      third_party_account_placeholder: 'Sélectionner un compte GPT',
      tones: {
        details: 'Tons employés par l\'agent, maximum 3, chaque suggestion se basera sur un ton à la fois',
        list: [
          'Amical',
          'Professionnel',
          'Concis',
          'Chaleureux'
        ],
        title: 'Tons de l\'agent'
      }
    }
  },
  already_exists: 'La valeur renseignée existe déjà',
  back: 'Retour',
  bot: {
    actions: {
      'find-invalid-node': 'Centrer la vue sur le prochain noeud invalide du SVI Visuel',
      preview: 'Aperçu',
      publish: 'Enregistrer',
      redo: 'Refaire',
      undo: 'Annuler',
      zoom_in: 'Zoomer',
      zoom_out: 'Dézoomer'
    },
    back: 'Retour',
    changes_saved: 'Modifications enregistrées',
    copy_from_default_channel_ok: 'Copie réussie!',
    copy_trees_from_default_channel: 'Merci de patienter pendant la copie des arbres depuis le canal par défaut',
    node: {
      choice_fallback: 'Choix non détecté',
      dialogflow_end: 'Fin d\'intéraction IA',
      fallback: 'Non détection d\'intention',
      gbagent: 'Mise en relation',
      image: '1 Image ajoutée',
      job: 'Construisez votre bot à l\'aide des différentes actions (Votre message de bienvenue ou d\'absence sera joué en fonction de votre calendrier).',
      totree: 'Retour accueil bot'
    },
    trees: {
      add: 'Ajouter une arborescence',
      title: 'Structure'
    },
    unsaved_changes: 'Voulez-vous vraiment quitter cette page ? Toute modification non sauvegardée sera perdue.'
  },
  botgroups: {
    botgroup: {
      ask_cancel: 'Vous allez perdre vos modifications, êtes-vous sûr de vouloir annuler vos modifications ?',
      ask_delete: 'Voulez-vous vraiment supprimer ce point d\'entrée ?',
      ask_submit: 'Vos modifications vont s\'appliquer au widget en production, êtes-vous sûr de vouloir sauvegarder vos modifications ?',
      calendar: 'Disponibilités',
      channel_trees: 'Canaux digitaux',
      columns: {
        label: 'Nom du canal',
        type: 'Type',
        skill: 'Compétence',
        tree: 'Arborescence',
        status: 'Statut',
        webivr: 'SVI Visuel',
        webivr_tree: 'Nom de l\'arborescence du SVI Visuel'
      },
      configure: 'Configurer',
      created: 'Point d\'entrée créé',
      customize: {
        btn: 'Personnaliser',
        save_before_customize: 'Vous devez sauvegarder le widget pour pouvoir le personnaliser',
        title: 'Personnaliser votre widget'
      },
      deleted: 'Le point d\'entrée a été supprimé',
      embed: 'Mise en ligne',
      embed_url: 'Copier/coller l\'URL suivante sur les pages où vous souhaitez voir apparaître votre URL :',
      embed_widget: 'Copier/coller le code d\'intégration suivant sur les pages où vous souhaitez voir apparaitre votre widget :',
      embed_presets_widget: 'Copier/coller le code d\'intégration suivant sur les pages où vous souhaitez voir apparaitre votre widget :',
      error: {
        not_found: 'Ce point d\'entrée n\'existe plus.',
        only_one_integration_channel_allowed_for_url_integration: 'Un seul canal peut être associé à un point d\'entrée de type URL'
      },
      general: 'Général',
      integration_channels: 'Choix des canaux',
      name: 'Nom du point d\'entrée',
      saved: 'Point d\'entrée mis à jour',
      skill: 'Compétence',
      skill_placeholder: 'Sélectionner une compétence',
      type: {
        presets_widget: 'Widget personnalisable',
        messenger_menu: 'Menu Messenger',
        search: 'Moteur de recherche',
        subtitle: 'Vous ne pourrez pas changer ce mode d\'intégration par la suite.',
        title: 'Type d\'intégration',
        url: 'Lien URL',
        widget: 'Widget'
      },
      warning: 'Attention',
      webivr: {
        account: 'SVI Visuel publié',
        account_placeholder: 'Sélectionner un SVI visuel',
        add_tree: {
          title: 'Associer une arborescence publiée',
          placeholder: 'Sélectionner l\'arborescence à associer'
        },
        auto_open_delay: {
          details: 'Laisser vide pour désactiver l\'ouverture automatique du widget',
          disabled: 'Ouverture automatique désactivée',
          label: 'Délais d\'ouverture automatique (en secondes)'
        },
        customize: {
          btn: 'Barre de navigation'
        },
        html_root: 'Nœud racine HTML (laisser vide pour utiliser le défaut)',
        icon_url: {
          label: 'Icone'
        },
        information: 'Informations',
        max_open_events_per_session: 'Limiter le nombre de clics',
        max_open_events_per_session_details: 'Laisser vide pour ne pas limiter',
        mode: {
          embed: 'Widget embarqué',
          label: 'Type d\'affichage',
          full: 'Page complète'
        },
        navbar: {
          'add-item': 'Ajouter un bouton de navigation',
          save: 'Barre de navigation mise à jour',
          title: 'Barre de navigation',
          trees: {
            calendar: 'Calendrier',
            delete: 'Suppression',
            identifier: 'Classe CSS',
            label: 'Libellé',
            no_trees: 'Aucun arborescence n\'a été associée à la navigation.',
            title: 'Liste des arborescences',
            tree: 'Arborescence'
          }
        },
        no_tree: 'Aucune arborescence n\'a été associée au point d\'entrée.',
        session_ttl_hours: 'Durée de vie de l\'URL (En jour)',
        session_ttl_hours_details: 'Laisser vide pour ne pas limiter',
        step1: 'Informations du point d\'entrée',
        step2: 'Configuration des arborescences',
        tree_ask_delete: 'Voulez-vous vraiment retirer cette arborescence de ce point d\'entrée ?',
        tree_cal_saved: 'Calendrier de l\'arborescence mis à jour',
        tree_created: 'Arborescence associée',
        tree_deleted: 'Arborescence retirée',
        tree_saved: 'Arborescence modifiée',
        trees: 'Configuration des arborescences'
      }
    },
    embed: {
      copy: 'Copier',
      copied_to_clipboard: 'Copié dans le presse-papier !',
      preview: 'Prévisualiser',
      title: 'Mise en ligne'
    },
    messaging_groups: 'Points d\'entrée {0}',
    messenger_menu: {
      add_button: 'Ajouter un bouton',
      buttons: {
        reset: 'Réinitialisation de bot',
        text: 'Texte',
        title: 'Boutons',
        tree: 'Arborescence',
        tree_redirect: 'Rediriger vers une arborescence',
        type: 'Type',
        url: 'URL'
      },
      channel: 'Messagerie Messenger'
    },
    title: 'Liste de vos points d\'entrée'
  },
  calendar: {
    all: 'Ouverture 24h/24, 7J/7',
    back: 'Retour',
    closed: 'Fermé',
    custom: "Personnaliser les créneaux d'ouverture",
    error: {
      cant_choose_existing_off_day: 'Vous ne pouvez pas choisir un jour déjà présent dans la liste',
      date_must_be_in_the_future: 'La date doit être dans le futur',
      empty_day: 'Les jours d\'ouvertures ne contenant pas d\'horaires ont été ignorés.',
      Monday_as_invalid_time_format: getInvalidTimeFormat('Lundi'),
      Tuesday_as_invalid_time_format: getInvalidTimeFormat('Mardi'),
      Wednesday_as_invalid_time_format: getInvalidTimeFormat('Mercredi'),
      Thursday_as_invalid_time_format: getInvalidTimeFormat('Jeudi'),
      Friday_as_invalid_time_format: getInvalidTimeFormat('Vendredi'),
      Saturday_as_invalid_time_format: getInvalidTimeFormat('Samedi'),
      Sunday_as_invalid_time_format: getInvalidTimeFormat('Dimanche')
    },
    form: {
      add: 'Ajouter',
      add_new_custom_day: 'Ajouter un nouveau jour férié',
      delete_ask: 'Voulez-vous vraiment supprimer ce calendrier?',
      calendar: 'Calendrier',
      deleted: 'Calendrier supprimé',
      label: 'Libellé',
      off_groups: 'Groupes de jours fériés',
      saved: 'Calendrier a été enregistré'
    },
    friday: 'Vendredi',
    table: {
      add: 'Ajouter un calendrier',
      created: 'Date de création',
      custom: 'Jours fériés',
      edit: 'Modifier',
      label: 'Libellé',
      subtitle: 'Liste des calendriers',
      timezone: 'Fuseau horaire',
      last_update: 'Date de mise à jour'
    },
    monday: 'Lundi',
    saturday: 'Samedi',
    saved: 'Votre calendrier a été enregistré',
    sunday: 'Dimanche',
    thursday: 'Jeudi',
    title: 'Calendrier',
    tuesday: 'Mardi',
    used_TZ: 'Horaires précédemment enregistrés sur le fuseau horaire :',
    wednesday: 'Mercredi'
  },
  campaigns: {
    add_whatsapp_channel: 'Ajouter un canal WhatsApp',
    author: 'Auteur',
    back: 'Retour à l\'accueil des campagnes',
    campaigns: {
      back: 'Retour à la liste des campagnes'
    },
    contacts: {
      add: 'Créer une audience',
      ask_delete: 'Voulez-vous vraiment supprimer cette audience ?',
      back: 'Retour à la liste des audiences',
      deleted: 'Audience supprimée',
      export: 'Exporter',
      form: {
        'contacts*': 'Contacts*',
        created: 'Audience créée',
        email: 'Email',
        error: {
          blocked_by_campaign: 'Impossible de supprimer l\'audience car celle-ci est utilisée par une campagne.'
        },
        external_id: 'Identifiant',
        external_id_kind: {
          FACEBOOK_PSID: 'ID Facebook',
          GBM_CONVERSATION_ID: 'ID de conversation',
          PHONE_NUMBER: 'Numéro de tél.',
          placeholder: 'Sélectionner un type',
          title: 'Type*'
        },
        filters: {
          contact_name: 'Nom',
          contact_reasons: 'Motifs de contacts',
          email: 'Email',
          language: 'Langue des contacts',
          last_interaction_at: 'Dernier échange',
          origin: {
            bot: 'Messageries',
            csv: 'CSV',
            title: 'Origine'
          },
          origin_integrations: 'Points d\'entrée',
          origin_tree: 'Arborescence',
          reset: 'Réinitialiser',
          selected: 'Contacts sélectionnés',
          skills: 'Compétences',
          solicitations: {
            from: 'De',
            title: 'Nombre de sollicitations',
            to: 'à'
          }
        },
        import: {
          'first_column_must_be:external_id': 'La première colonne du fichier CSV doit avoir pour titre "external_id" et contenir les identifiants des contacts (ID Google / Compte Facebook / Numéros de téléphones aux formats international sans le signe "+").',
          'header:min:2': 'Les deux premières colonnes de la première ligne du fichier CSV doivent être respectivement égales à "external_id" et "language".',
          'second_column_must_be:language': 'La seconde colonne du fichier CSV doit avoir pour titre "language" et contenir les langues des différents contacts.',
          add_btn: 'Ajouter',
          ask_add: 'Souhaitez-vous ajouter les contacts importés à la sélection de l\'audience ?',
          do_not_add_btn: 'Ne pas ajouter',
          errored_lines_separator: ', ',
          errored_lines: 'Numéros des lignes invalides du CSV :',
          example: 'Importez vos contacts manuellement en vous appuyant sur ce {0}.',
          existing_contacts: 'Nombre de contacts déjà existants :',
          imported: 'Contacts importés',
          inserted_contacts: 'Nombre de contacts importés :',
          label: 'Fichier CSV',
          ref_file: 'fichier de référence',
          title: 'Importer de nouveaux contacts'
        },
        language: 'Langue',
        last_interaction_at: 'Dernier échange',
        legals: {
          1: 'J’ai reçu le consentement spécifique et éclairé de tous mes contacts, m’autorisant à leur envoyer des messages.',
          2: 'Aucun de mes contacts n’a été obtenu à partir de listes de diffusion tierces.',
          3: 'Je n’enverrai que des informations légales et légitimes',
          title: 'Je certifie que :'
        },
        name: 'Nom de l\'audience',
        'name*': 'Nom de l\'audience*',
        origin_skill: 'Compétence',
        origin_integration: 'Point d\'entrée',
        origin_tree: 'Arborescence',
        raw: {
          label: 'Entrez vos numéros de téléphone, sans espace et séparés par des ";"'
        },
        results: 'Résultat de la recherche : {0} contact | Résultat de la recherche : {0} contact | Résultats de la recherche : {0} contacts',
        saved: 'Audience mise à jour',
        search: 'Recherche par filtres',
        search_btn: 'Rechercher...',
        selected: 'Aucun contact sélectionné | {0} contact sélectionné | {0} contacts sélectionnés',
        source: {
          options: {
            existing: 'Ajouter des contacts existants',
            file: 'Importer un fichier CSV',
            raw: 'Insérer manuellement'
          },
          title: 'Import de l\'audience*'
        },
        username: 'Nom'
      },
      contacts_count: 'Contacts',
      created: 'Créé le',
      edit: {
        title: 'Gérer'
      },
      kind: 'Type',
      label: 'Nom',
      language: 'Langue',
      modified: 'Dernière modification',
      search: 'Rechercher...',
      status: 'Statut',
      subtitle: 'Gérez et consultez les contacts de vos audiences.\nCréez et gérez vos audiences.',
      title: 'Liste d\'audiences'
    },
    create: 'Créer',
    list: {
      add: 'Créer une nouvelle campagne',
      attempt: 'Nombre de tentative(s)',
      attempt_delay: 'Temps entre 2 tentatives (en heure)',
      bot: 'BOT',
      cancel: 'Annuler la campagne',
      cancel_ask: 'Vous êtes sur le point d\'annuler cette campagne, souhaitez-vous continuer ?',
      configuration_title: 'Configuration',
      confirm_ask: 'Les variables de modèle ne sont pas correctement renseignées, voulez-vous tout de même valider la campagne ?',
      contact_list: 'Audiences',
      contact_list_length: 'Aucun contact | 1 contact | {0} contacts',
      conversation: 'Echange',
      count: 'Nombre d\'envoi',
      count_tooltip: 'Ce champ correspond au nombre d\'envoi total de la campagne, vous pouvez laisser ce champ vide si vous voulez que votre envoi de campagne se renouvelle automatiquement et indéfiniment.',
      create_to_import_vars: 'Finalisez la création de la campagne afin de pouvoir importer de nouvelles variables.',
      date: 'Date de création',
      delete_ask: 'Voulez-vous vraiment supprimer cette campagne ?',
      deleted: 'Campagne supprimée',
      dtstart: 'Date et heure d\'envoi',
      edit: {
        title: 'Gérer'
      },
      error: {
        csv_parsing_error: 'Le format du fichier CSV est incorrecte.',
        'header:min:2': 'Le fichier CSV doit contenir au moins 2 colonnes avec chacune leur titre.',
        'header:unique': 'Les noms des colonnes du fichier CSV doivent être uniques.',
        invalid_start_date: 'La date et heure d\'envoi doivent être postérieures à la date et l\'heure du jour',
        whatsapp_tier_limit_reached: 'Vous ne pouvez envoyer que {0} messages par tranche de 24h pour l\'instant. Votre audience comporte trop de contacts, si vous voulez envoyer cette campagne, vous devez réduire le nombre de contacts dans votre audience.'
      },
      export: 'Télécharger le rapport',
      fallback_template_list_details_rcs: 'Ce champ vous permet de définir un modèle d\'envoi de SMS lorsque le mobile du destinataire n\'est pas compatible RCS.',
      fallback_template_list_placeholder: 'Sélectionner un modèle de secours',
      fallback_template_list: 'Modèle de secours',
      fallback_template_vars_set: 'Assignation des variables du modèle de secours*',
      freq: 'Fréquence',
      freqs: {
        3: 'Quotidienne',
        1: 'Mensuelle',
        2: 'Hebdomadaire',
        0: 'Annuelle'
      },
      frequency: 'Fréquence (nombre de messages/heure)',
      import_vars: 'Importer des variables',
      import_vars_details: 'Associez de nouvelles variables personnalisées à la campagne en important un fichier CSV grace au bouton "{0}" ci-dessous.',
      import_vars_ref_file: 'Fichier de référence',
      import_vars_success: 'Variables importées',
      integration_list: 'Point d\'entrée',
      interval: 'Intervalle d\'envoi',
      interval_tooltip: 'L\'intervalle d\'envoi est l\'écart temporel entre chaque envoi en fonction de la fréquence définie.',
      name: 'Nom',
      opened: 'Ouverture',
      phone: 'Téléphone',
      recurrence: 'Campagne récurrente',
      rrule_disabled: 'Planification désactivée',
      rrule_preview: 'Dates prévisionnelles d\'envoi :',
      rrule_preview_footer: 'Nombre d\'envoi illimité - Affichage des 30 premières dates',
      saved: 'Campagne mise à jour',
      schedule_title: 'Planification',
      search: 'Rechercher...',
      send: {
        campaign: 'Campagne',
        contact: 'Contact N° {0}',
        sent: 'Campagne envoyée manuellement',
        template_body: 'Contenu du modèle',
        title: 'Envoi manuel',
        to: 'Numéro de tél.'
      },
      send_date: 'Envoyer maintenant ?',
      skill_placeholder: 'Sélectionner une compétence',
      skill: 'Compétence',
      stats: {
        clicks: 'Taux d\'ouverture de SVI Visuels',
        failed: 'Nombre d\'échecs',
        gbagent: 'Taux d\'échanges',
        link: 'Détail des statistiques',
        read: 'Taux d\'ouvertures',
        sent: 'Nombre d\'envois',
        title: 'Statistiques'
      },
      status: {
        CANCELLED: 'Annulée',
        FINISHED: 'Terminée',
        MANUAL: 'Non planifiée',
        PENDING: 'En attente',
        RUNNING: 'En cours',
        title: 'Statut'
      },
      subtitle: 'Gérez et consultez vos campagnes.',
      template: 'Modèle',
      template_list_placeholder: 'Sélectionner un modèle',
      template_list: 'Modèle',
      template_var: 'Définissez {0}',
      template_vars: 'Import des variables',
      template_vars_set: 'Assignation des variables du modèle*',
      trees: `Votre modèle contient des réponses rapides qui sont liées à des arborescences.
        Avant d’envoyer votre campagne, assurez-vous d’avoir bien paramétré vos différentes arborescences dans le bot builder.

        Si ce n’est pas encore fait vous pouvez y accédez directement en cliquant sur les liens ci-dessous:`,
      type: 'Type',
      types: {
        whatsapp: 'WhatsApp'
      },
      tzid_placeholder: 'Sélectionner un fuseau horaire',
      tzid: 'Fuseau horaire',
      vars: {
        contact: 'Variables de l\'audience',
        custom: 'Variable personnalisée',
        custom_placeholder: 'Nom de la variable personnalisée',
        others: 'Autres',
        url_placeholder: 'URL personnalisée',
        webivr: 'Liens de SVI Visuel'
      },
      vars_file_report: {
        choose_var_per_colmun: 'Maintenant, assignez vos variables avec les colonnes correspondantes de votre fichier dans les listes déroulantes ci-dessus.',
        deduplicated_rows: '{0} ensemble de variables importé | {0} ensemble de variables importé | {0} ensembles de variables importés',
        matched_audience_contacts: '{0} compatible avec les contacts de cette campagne | {0} compatible avec les contacts de cette campagne | {0} compatibles avec les contacts de cette campagnes',
        title: 'Rapport d\'importation de variables :'
      },
      variables: 'Importez vos variables au format CSV',
      variables_title: 'Définition des variables',
      view_agenda: 'Agenda',
      view_list: 'Liste'
    },
    templates: {
      'add-button': 'Ajouter un bouton',
      back: 'Retour à la liste des modèles',
      body: 'Contenu',
      body_card: {
        description: 'Description',
        image: 'Image',
        title: 'Titre'
      },
      body_details: 'Pour intégrer les valeurs variables dans votre corps de message, il vous suffit d\'inscrire le numéro de la variable entre 2 accolades à l\'emplacement que vous souhaitez. Exemple : {{1}}, {{2}}, etc.',
      body_kind: {
        rich_card: 'Carte enrichie',
        text: 'Texte',
        title: 'Type de contenu'
      },
      body_warning: 'Ce champ est limité à {0} caractères, attention les variables ne sont pas comptabilisés ici. | Ces champs sont limités à {0} et {1} caractères, attention les variables ne sont pas comptabilisés ici.',
      body_warning_sms: 'Un SMS est limité à 160 caractères, dépasser ce quota entraînera l\'envoi de plusieurs SMS. Attention les caractères spéciaux comptent comme plusieurs caractères et les variables ne sont pas comptabilisés ici.',
      'body*': 'Corps du message*',
      button: 'Bouton interactif',
      'button-content': {
        get button () {
          return Object.assign({}, this['quick-reply'], {
            too_many_buttons: 'Vous avez sélectionné le modèle "Boutons" et ce dernier ne peut comporter que {0} boutons maximum.'
          })
        },
        cta: {
          kind: {
            phone: 'Numéro de téléphone',
            placeholder: 'Sélectionner un type de bouton',
            title: 'Type de bouton*',
            url: 'Lien externe'
          },
          phone: 'Numéro de téléphone*',
          too_many_buttons: 'Vous avez sélectionné le modèle "Call to action" et ce dernier ne peut comporter que 2 réponses maximum.',
          url: 'URL*'
        },
        errors: {
          'no-emoji': 'Vous ne pouvez saisir d\'emojis ici'
        },
        'quick-reply': {
          channel_required: 'Veuillez sélectionner un compte {0} afin de pouvoir configurer une arborescence à associer à ce bouton.',
          choose_tree_placeholder: 'Sélectionner une arborescence',
          choose_tree: 'Choisissez une arborescence*',
          kind: {
            add_tree: 'Créer une arborescence',
            existing_tree: 'Choisir une arborescence existante',
            title: 'Modèle d\'arborescence*'
          },
          too_many_buttons: 'Vous avez sélectionné le modèle "Réponses rapide" et ce dernier ne peut comporter que {0} réponses maximum.',
          tree_name: 'Nom de l\'arborescence*'
        },
        text: 'Texte du bouton*'
      },
      'button-kind': 'Bouton interactif',
      'button-kind-options': {
        buttons: 'Boutons',
        'call-to-action': 'Call to action',
        'quick-reply': 'Réponse rapide'
      },
      categories: {
        ACCOUNT_UPDATE: 'Mise à jour du compte',
        ALERT_UPDATE: 'Mise à jour d’alerte',
        APPOINTMENT_UPDATE: 'Mise à jour du rendez-vous',
        AUTO_REPLY: 'Réponse automatique',
        ISSUE_RESOLUTION: 'Résolution d’un problème',
        MARKETING: 'Marketing',
        PAYMENT_UPDATE: 'Mise à jour de paiement',
        PERSONAL_FINANCE_UPDATE: 'Mise à jour sur les finances personnelles',
        RESERVATION_UPDATE: 'Mise à jour de réservation',
        SHIPPING_UPDATE: 'Mise à jour d’expédition',
        TICKET_UPDATE: 'Mise à jour d’un ticket',
        TRANSPORTATION_UPDATE: 'Mise à jour de transport',
        UTILITY: 'Transactionnel'
      },
      conversational: 'SMS Conversationnel',
      conversational_details: 'Activez cette fonctionnalité pour sélectionner l\'arborescence vers laquelle vous souhaitez rediriger le client s\'il répond à votre campagne de SMS. Le nom d\'expéditeur ne sera pas personnalisable dans ce cas.',
      'category*': 'Catégorie*',
      category_placeholder: 'Sélectionner une catégorie',
      category: 'Catégorie',
      create: 'Soumettre le modèle',
      delete: 'Supprimer',
      delete_ask: 'Voulez-vous vraiment supprimer ce modèle ?',
      deleted: 'Demande de suppression du modèle envoyée !',
      error: {
        more_than_one_button_required: 'La configuration d\'un bouton ou plus est requise pour ce type de bouton interactif',
        name_format: 'Seul les caractères alphanumériques et les underscores sont autorisés'
      },
      footer: 'Bas de page',
      gbm: {
        'channel*': 'Compte {0}',
        channel_placeholder: 'Sélectionner un compte {0}'
      },
      header: 'En-tête',
      'header-kind': 'Type d\'en-tête',
      'header-content-image': 'Image d\'en-tête',
      'header-content-image_details': 'Format recommandé : Rapport d\'aspect 1.91:1, 5MB Max.',
      'header-content-text': 'Texte de l\'en-tête',
      'header-options': {
        image: 'Image',
        text: 'Texte'
      },
      'language*': 'Langage*',
      language_placeholder: 'Sélectionner une langue',
      language: 'Langage',
      messenger: {
        'add-block': 'Ajouter un bloc',
        blocks: {
          block: 'Bloc',
          buttons: {
            button_action: {
              game_metadata_context: 'Contexte du jeu',
              game_metadata_player_id: 'Id du joueur',
              payload: 'Payload',
              url: 'URL'
            },
            title: 'Titre',
            type: {
              label: 'Type',
              options: {
                account_link: {
                  text: 'Connexion'
                },
                game_play: {
                  text: 'Jeu'
                },
                phone_number: {
                  text: 'Appel téléphonique'
                },
                postback: {
                  text: 'Postback'
                },
                unaccount_link: {
                  text: 'Déconnexion'
                },
                web_url: {
                  text: 'URL'
                }
              }
            }
          },
          defaultAction: {
            type: {
              label: 'Action par défaut',
              options: {
                web_url: {
                  text: 'URL'
                }
              }
            },
            url: 'URL'
          },
          image: {
            label: 'Image'
          },
          subtitle: {
            label: 'Sous-titre'
          },
          title: {
            label: 'Titre'
          },
          type: {
            label: 'Type'
          }
        },
        'blocks*': 'Blocs',
        'block-kind*': 'Type de bloc',
        'block-kind-options': {
          button: 'Bouton',
          generic: 'Générique',
          media: 'Média',
          receipt: 'Reçu'
        },
        button_action: {

        },
        'channel*': 'Canal digital*',
        'content*': 'Contenu*',
        errors: {
          'block-kind': 'Type de bloc non reconnu'
        },
        messenger_account_placeholder: 'Sélectionner un compte Messenger',
        'messenger_account*': 'Compte Messenger*',
        notification_kind: {
          recurring: 'Notification récurrente',
          unique: 'Notification unique'
        },
        'notification_kind*': 'Type de notification*'
      },
      modal: {
        confirm_delete_button: 'Supprimer le modèle',
        confirm_delete_msg: 'Etes-vous sûr(e) de vouloir supprimer définitivement ce modèle ?',
        confirm_delete_title: 'Suppression du modèle'
      },
      name: 'Nom',
      'name*': 'Nom*',
      preview: 'Aperçu',
      save_draft: 'Enregistrer le brouillon',
      saved: {
        body: 'Votre modèle de campagne "{0}" a bien été enregistré, vous pouvez le retrouver dans l\'onglet "liste de modèles", vous devez attendre sa validation avant de pouvoir le publier.',
        cancel: 'Retour à la liste de modèle',
        confirm: 'Créer un autre modèle',
        notification: 'Brouillon du modèle enregistré',
        updated: 'Modèle mis à jour',
        title: 'Enregistré'
      },
      sms: {
        categories: {
          MARKETING: 'Marketing',
          TRANSACTIONAL: 'Transactionnel'
        },
        category_conversational: 'Les campagnes marketing sont uniquement disponibles pour les SMS de type non conversationnel.',
        'category*': 'Catégorie',
        category_placeholder: 'Sélectionner une catégorie',
        channel_placeholder: 'Sélectionner un canal SMS',
        'channel*': 'Canal SMS',
        channel_required: 'Veuillez sélectionner un canal SMS afin de pouvoir configurer une arborescence à associer.',
        type: 'Type de SMS'
      },
      sms_sender_name: 'Nom d\'expéditeur',
      table: {
        add: 'Créer un nouveau modèle',
        category: 'Catégorie',
        channel: 'Canal',
        created: 'Création',
        language: 'Langue',
        last_update: 'Dernière mise à jour',
        name: 'Nom du modèle',
        skill: 'Compétence',
        status: 'Statut',
        subtitle: 'Liste des modèles'
      },
      search: 'Recherche',
      'skill*': 'Compétence*',
      skill_placeholder: 'Sélectionner une compétence',
      status: {
        DRAFT: 'Brouillon',
        REFUSED: 'Refusé',
        SUBMITTED: 'En cours de validation',
        UNKNOWN: 'Inconnu',
        VALIDATED: 'Validé'
      },
      subtitle: 'Créez vos modèles.',
      title: 'Modèles',
      tree: 'Arborescence',
      variable_example: 'Exemple de variable {0}',
      variables_examples: 'Exemples de variables',
      variables_examples_details: 'Pour faciliter la validation de votre modèle, nous vous recommandons de renseigner des exemples de variables. Exemple : Pour une variable contenant un prénom, renseignez "John".',
      webivr_url: 'Lien du SVI Visuel',
      'whatsapp_account*': 'Compte WhatsApp*',
      whatsapp_account: 'Compte WhatsApp',
      whatsapp_account_placeholder: 'Sélectionner un compte WhatsApp'
    },
    whatsapp_channel_required: 'Avant de pouvoir accéder aux campagnes, vous devez d’abord lier un compte WhatsApp à ChayAll.'
  },
  cancel: 'Annuler',
  cannot_be_blank: 'Le champ ne peut pas être vide',
  cannot_be_null: 'Merci de remplir ce champ',
  channel: {
    abc: {
      already_in_done_status: 'Compte déjà associé',
      config_saved: 'Votre demande a bien été envoyée',
      details_required: 'Pour envoyer votre demande, merci de remplir le champ descriptif',
      faq: 'https://support.chayall.com/article/98-comment-configurer-apple-business-chat',
      form: {
        part: 'Comment configurer Apple Messages for Business ?',
        subtitle: 'Pour configurer la messagerie Apple Messages for Business, vous devez créer un compte Apple Messages for Business.\nPour cela, cliquez sur le bouton « Commencer ». Vous serez ensuite redirigé vers la page d’inscription Apple. Un mail automatique ChayAll vous sera également envoyé pour vous aider à configurer la messagerie, pas à pas.'
      },
      ka_desc: 'Intégrez Messages iOS sur votre site web, vos SMS, vos mails, etc. et échangez avec vos clients grâce à la solution Apple Messages for Business. Enrichissez l’expérience de vos clients grâce aux fonctionnalités proposées.',
      linked: 'Compte associé !',
      linked_callback: 'Afficher les messageries',
      linking: 'Association de votre compte Apple Messages for Business',
      send: 'Commencer',
      sent_content: 'Votre demande nous a bien été transmise ! Nous reviendrons vers vous rapidement.',
      sent_title: "Confirmation d'envoi",
      subtitle: 'Proposez à vos clients de vous contacter depuis la messagerie de Apple en intégrant un bouton Apple Messages for Business sur votre site Web.\nIls pourront vous contacter facilement depuis leur iPhone / iPad et profiter de toutes les fonctionnalités de cette messagerie instantanée.'
    },
    auto_msgs: {
      changes_saved: 'Vos modifications ont bien été prises en compte.'
    },
    common: {
      timezone_saved: 'Le fuseau horaire a bien été enregistré'
    },
    edit_confirmed: {
      body: 'Votre demande nous a bien été transmise ! Nous reviendrons vers vous rapidement.',
      title: 'Confirmation de demande de modification'
    },
    embed: {
      api_plugin: 'Copiez la clé API ci-dessous puis collez-la dans la page configuration du plugin :',
      copied_to_clipboard: "Code d'intégration copié !",
      copy: 'Copier le code',
      copy_paste_to_gtm: `Cliquez sur Balises > Nouveau.<br />
Cliquez sur Configuration des balises et sélectionnez HTML personnalisé.<br />
Copiez le code de balise fourni ci-dessous puis collez-le dans le champ HTML.`,
      copy_paste_to_your_html_page: "Copier/coller le code d'intégration suivant sur les pages où vous souhaitez voir apparaitre vos canaux digitaux.",
      copy_paste_to_your_html_page_details: 'Ce code doit être inséré à intérieur de la balise "body" des pages sur lesquelles vous souhaitez ajouter notre widget.',
      default: {
        download_plugin: 'Téléchargez le plugin {0} ChayAll :',
        download_plugin_btn: 'Téléchargez le plugin',
        install_plugin: 'Installez-le depuis les extensions {0}',
        shop_link: 'Boutique {0}'
      },
      embed_kind: {
        gtm: 'Google Tag Manager',
        javascript: 'Web',
        joomla: 'Joomla',
        make_a_selection: 'Faites votre choix',
        prestashop: 'PrestaShop',
        shopify: 'Shopify',
        url: 'URL',
        wordpress: 'WordPress'
      },
      select_an_integration_method: 'Sélectionner une méthode d\'intégration',
      url_desc: 'Utiliser le lien URL pour intégrer vos canaux digitaux sur n\'importe quel support digital (signature mail, image, document, etc.)',
      url_select_channel: 'Pour quel(s) canau(x) digitaux(s) souhaitez-vous obtenir le lien',
      get gtm () {
        return Object.assign({}, this.default)
      },
      get prestashop () {
        return Object.assign({}, this.default)
      },
      get shopify () {
        return Object.assign({}, this.default, {
          download_plugin: 'Ajoutez l\'app ChayAll {0} à votre boutique :',
          install_plugin: 'Autorisez l\'accès à votre boutique lorsque demandé',
          shop_link: '{0} App Store'
        })
      },
      get web () {
        return Object.assign({}, this.default)
      },
      get wordpress () {
        return Object.assign({}, this.default)
      }
    },
    facebook_comments: {
      config_saved: 'Votre compte Facebook est maintenant connecté à ChayAll !',
      failed: 'La connexion à votre compte Facebook a échoué',
      faq: 'https://support.chayall.com/article/97-facebook-commentaires-configuration',
      form: {
        login_part: 'Connexion',
        page_part: 'Choix de la page',
        page_subtitle: 'Choisissez la page Facebook à lier à votre compte ChayAll.',
        subtitle: 'Connectez-vous à votre compte Facebook pour commencer la configuration.'
      },
      ka_desc: 'Échangez facilement avec vos clients et répondez leur rapidement à travers votre console de chat.',
      logged_out: 'Votre compte Facebook est maintenant déconnecté',
      logout: 'Se déconnecter',
      subtitle: 'Gérez vos commentaires Facebook depuis votre Console Chat ChayAll.'
    },
    faq: "Besoin d'aide ?",
    fb_login: 'Se connecter à Facebook',
    googlebm: {
      blacklisted_domain: 'Seules les addresses email d\'entreprise sont acceptées',
      config_saved: 'Votre demande a bien été envoyée !',
      detail: "Pour des raisons de sécurité, vous allez recevoir 2 emails de Google :\n- Un premier vous indiquant votre clé de sécurité\n- Un deuxième contenant un texte à renvoyer par mail à Google, afin d'autoriser ChayAll en tant qu'intégrateur de cette messagerie.\n\nNous vous tiendrons ensuite informé dès que votre bouton \"Message\" sera visible sur le moteur de recherche Google et Google Maps.",
      edit: 'Modifier ma demande',
      faq: 'https://support.chayall.com/article/94-comment-configurer-google-business-messages',
      form: {
        subtitle: 'Ces informations ne seront pas publiques et serviront uniquement à valider votre entreprise auprès de Google.'
      },
      ka_desc: 'Intégrez un bouton "Discuter" sur votre fiche Google My Business, Google Maps ou votre annonce Google. Vos clients pourront ainsi vous contacter directement depuis le moteur de recherche Google.',
      logo_url: {
        required: 'Le logo est obligatoire'
      },
      place_id: {
        required: 'L\'entreprise est obligatoire'
      },
      send: 'Valider',
      send_edit: 'Envoyer la modification',
      subtitle: 'Permettez à vos clients de vous contacter par message depuis le moteur de recherche Google et Google Maps.\nSoyez là où sont vos clients ! Simple et rapide à intégrer.'
    },
    google_reviews: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      faq: 'https://support.chayall.com/',
      form: {
        account_placeholder: 'Sélectionner un compte Google Avis',
        account: 'Compte Google Avis',
        choose: 'Choisissez votre compte',
        error: {
          unique: 'Cette localisation n\'est pas disponible car elle est déjà associée à un autre canal.'
        },
        location: 'Localisation',
        login_placeholder: 'Sélectionner un compte Google',
        login: 'Compte Google'
      },
      ka_desc: 'Améliorer votre relation client en interagissant avec vos clients sur leurs avis Google.',
      logged: 'Compte Google connecté',
      login: 'Se connecter à Google',
      logout: 'Se déconnecter',
      subtitle: 'Centralisez vos avis Google sur notre interface. Vous pourrez répondre à vos clients depuis la console de chat.'
    },
    instagram_comments: {
      config_saved: 'Votre compte Instagram est maintenant connecté à ChayAll !',
      facebook_insta: 'Comment lier sa page Facebook à son compte Instagram ?',
      facebook_insta_link: 'https://support.chayall.com/article/83-comment-connecter-son-compte-instagram-professionnel-a-une-page-facebook',
      failed: 'La connexion à votre compte Instagram a échoué',
      faq: 'https://support.chayall.com/article/82-comment-configurer-instagram-commentaires',
      form: {
        login_part: 'Connexion',
        page_part: 'Choix de la page',
        page_subtitle: 'Choisissez la page Facebook liée à votre compte Instagram.',
        subtitle: "Connectez-vous à votre compte Facebook pour commencer la configuration d'Instagram."
      },
      ka_desc: 'Recensez l\'ensemble de vos interactions avec vos clients sous vos posts Instagram dans notre plateforme.',
      logged_out: 'Votre compte Instagram est maintenant déconnecté',
      logout: 'Se déconnecter',
      no_pro_account: "Vous n'avez pas de compte Instagram professionnel ?",
      no_pro_account_link: 'https://support.chayall.com/article/74-comment-avoir-un-compte-instagram-professionnel',
      subtitle: 'Gérez vos commentaires Instagram depuis votre Console Chat ChayAll.\nVotre compte Instagram doit être un compte professionnel.'
    },
    instagram_dm: {
      config_saved: 'Votre compte Instagram est maintenant connecté à ChayAll !',
      facebook_insta: 'Comment lier sa page Facebook à son compte Instagram ?',
      facebook_insta_link: 'https://support.chayall.com/article/83-comment-connecter-son-compte-instagram-professionnel-a-une-page-facebook',
      failed: 'La connexion à votre compte Instagram a échoué',
      faq: 'https://support.chayall.com/article/133-comment-configurer-instagram-direct',
      form: {
        login_part: 'Connexion',
        page_part: 'Choix de la page',
        page_subtitle: 'Choisissez la page Facebook liée à votre compte Instagram.',
        subtitle: "Connectez-vous à votre compte Facebook pour commencer la configuration d'Instagram."
      },
      icebreakers: {
        button: 'Configurer les icebreakers',
        buttons: {
          add: 'Ajouter une arborescence'
        },
        deleted: 'Icebreakers supprimés',
        'delete-body-icebreakers': 'Etes-vous sûr(e) de vouloir supprimer les icebreakers ?',
        'delete-title-icebreakers': 'Supprimer les icebreakers ?',
        form: {
          delete: 'Supprimer',
          text: 'Libellé',
          to_tree: 'Arborescence'
        },
        list: 'Liste',
        locales: {
          add: 'Ajouter une langue',
          'add-title': 'Ajouter une nouvelle langue?',
          choose: 'Choisissez une langue',
          'default-explanation': '*Correspond à Anglais américain ou tout autre langue non paramétrée.',
          'delete-body-locale': 'Etes-vous sûr(e) de vouloir supprimer cette langue ?',
          'delete-button': 'Supprimer une langue',
          'delete-title-locale': 'Supprimer la langue ?',
          list: {
            flags: {
              ar_AR: 'Arabe',
              cs_CZ: '🇨🇿',
              da_DK: '🇩🇰',
              de_DE: '🇩🇪',
              default: 'Par défaut*',
              el_GR: '🇬🇷',
              en_GB: '🇬🇧',
              es_ES: '🇪🇸',
              es_LA: 'Espagnol (Amérique Latine)',
              fi_FI: '🇫🇮',
              fr_FR: '🇫🇷',
              he_IL: '🇮🇱',
              hi_IN: '🇮🇳',
              hu_HU: '🇭🇺',
              id_ID: '🇮🇩',
              it_IT: '🇮🇹',
              ja_JP: '🇯🇵',
              ko_KR: '🇰🇷',
              nb_NO: '🇳🇴',
              nl_NL: '🇳🇱',
              pl_PL: '🇵🇱',
              pt_BR: '🇧🇷',
              pt_PT: '🇵🇹',
              ro_RO: '🇷🇴',
              ru_RU: '🇷🇺',
              sv_SE: '🇸🇪',
              th_TH: '🇹🇭',
              tr_TR: '🇹🇷',
              vi_VN: '🇻🇳',
              zh_CN: '🇨🇳',
              zh_HK: '🇭🇰',
              zh_TW: '🇹🇼'
            },
            labels: {
              ar_AR: 'Arabe',
              cs_CZ: 'Tchèque',
              da_DK: 'Danois',
              de_DE: 'Allemand',
              default: 'Langue par défaut',
              el_GR: 'Grec',
              en_GB: 'Anglais (Royaume-Uni)',
              es_ES: 'Espagnol (Espagne)',
              es_LA: 'Espagnol (Amérique Latine)',
              fi_FI: 'Finnois',
              fr_FR: 'Français',
              he_IL: 'Hébreux',
              hi_IN: 'Hindi',
              hu_HU: 'Hongrois',
              id_ID: 'Indonésien',
              it_IT: 'Italien',
              ja_JP: 'Japonais',
              ko_KR: 'Coréen du Sud',
              nb_NO: 'Norvégien (Bokmål)',
              nl_NL: 'Néerlandais',
              pl_PL: 'Polonais',
              pt_BR: 'Portuguais (Brésil)',
              pt_PT: 'Portuguais (Portugal)',
              ro_RO: 'Roumanie',
              ru_RU: 'Russe',
              sv_SE: 'Suédois',
              th_TH: 'Thai',
              tr_TR: 'Turc',
              vi_VN: 'Vietnamien',
              zh_CN: 'Chinois Simplifié (Chine)',
              zh_HK: 'Chinois Traditionnel (Hong-kong)',
              zh_TW: 'Chinois Traditionnel (Taiwan)'
            }
          }
        },
        saved: 'Icebreakers sauvegardés',
        title: 'Icebreakers'
      },
      ig_account_not_eligible_for_api: 'Le compte Instagram lié à cette page n\'est pas éligible (cf. conditions requises ci-dessus)',
      ka_desc: 'Communiquez avec vos clients rapidement via Instagram Direct Messages depuis votre console de chat et améliorer votre relation client sur ce canal.',
      logged_out: 'Votre compte Instagram est maintenant déconnecté',
      logout: 'Se déconnecter',
      prerequisite: {
        content: `1/ Avoir un compte Instagram professionnel (nous vous expliquons comment {0})
2/ Sur votre téléphone, aller dans les paramètres > Confidentialité > Messages et activer "Autoriser l'accès aux messages".`,
        label: 'ici',
        link: 'https://support.chayall.com/article/74-comment-avoir-un-compte-instagram-professionnel',
        title: 'Pré-requis'
      },
      subtitle: 'Seuls les comptes Instagram ayant entre 1 000 et 100 000 followers peuvent utiliser ce service. Instagram débloquera ce service pour les autres comptes d\'ici fin 2021.'
    },
    menu: {
      embedding: 'Intégration',
      settings: 'Configuration'
    },
    messenger: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      failed: 'La connexion à votre compte Facebook a échouée',
      faq: 'https://support.chayall.com/article/59-comment-configurer-la-messagerie-messenger',
      form: {
        choose: 'Choisissez une page',
        login_part: 'Connexion',
        page_part: 'Choix de la page',
        subtitle: 'Connectez-vous à votre compte Facebook pour commencer la configuration de Messenger.',
        subtitle2: 'Choisissez la page sur laquelle vos clients pourront vous contacter, via votre page Facebook ou votre site internet.'
      },
      ka_desc: 'Boostez vos ventes et améliorez votre relation client en échangeant avec vos clients sur la messagerie instantanée Facebook Messenger.',
      logged_out: 'Votre compte Facebook est maintenant déconnecté',
      pages: 'pages',
      subtitle: 'Echangez avec vos clients sur Messenger. \nIls pourront vous contacter depuis votre page Facebook ou la pastille Messenger sur votre site web.'
    },
    preview: 'Aperçu du widget',
    preview_mobile: 'Aperçu',
    rcs: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      form: {
        identifier: 'Identifiant',
        serviceid: 'Service ID'
      },
      ka_desc: 'Communiquez avec vos prospects et vos clients via des discussions RCS.',
      subtitle: 'Permettez à vos clients d’échanger avec vos clients par RCS.\nSimple et rapide à intégrer. Il vous suffit d’ajouter ce canal puis d’envoyer un message RCS à votre client pour déclencher un échange. Ces informations ne seront pas publiques.'
    },
    refresh: 'Renouveler le jeton de connexion',
    send_button: 'send_button',
    send_confirmed: {
      body: 'Votre demande nous a bien été transmise ! Nous reviendrons vers vous rapidement.',
      title: "Confirmation d'envoi"
    },
    settings: {
      get status_creating () { return this.status_in_progress },
      get status_done () { return this.status_active },
      get status_enabled () { return this.status_available },
      get status_ok () { return this.status_active },
      hide: 'Masquer le canal dans le widget',
      hide_details: 'Attention, si vous masquez ce canal digital, vos utilisateurs ne la verront plus dans votre widget et ne pourront plus vous contacter via ce canal.',
      show: 'Afficher le canal dans le widget',
      show_details: 'Voulez-vous vraiment réafficher ce canal et la rendre à nouveau visible dans votre widget ?',
      status_active: 'Activé',
      status_auth_ko: 'Erreur',
      status_available: 'Configurer',
      status_in_progress: 'En cours',
      status_not_visible: 'Hors-ligne'
    },
    sms: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      form: {
        brand: 'Nom de la marque',
        service_id: 'ID de service'
      },
      ka_desc: 'Communiquez avec vos prospects et vos clients via des discussions SMS.',
      subtitle: 'Permettez à vos clients d’échanger avec vos clients par SMS texte.\nSimple et rapide à intégrer. Il vous suffit d’ajouter ce canal puis d’envoyer un SMS à votre client pour déclencher un échange. Ces informations ne seront pas publiques.'
    },
    to_embed: 'Une fois votre configuration validée, allez dans "Intégration" puis intégrez votre canal sur votre site web',
    telegram: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      faq: 'https://support.chayall.com/article/99-comment-configurer-la-messagerie-telegram',
      form: {
        token: 'Jeton Telegram'
      },
      ka_desc: 'Vendez, conseillez et accompagnez vos clients depuis la messagerie instantanée ultra sécurisée et cryptée : Telegram.',
      logged_out: 'Votre compte Telegram est maintenant déconnecté',
      logout: 'Se déconnecter',
      subtitle: 'Echangez avec vos clients via Telegram ! Pour connecter votre compte Telegram à notre interface, merci de renseigner le jeton que vous avez reçu au cours de la création de votre Bot Telegram.'
    },
    trustpilot: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      faq: 'https://support.chayall.com/',
      form: {
        choose: 'Choisissez un compte',
        login_part: 'Connexion',
        business_unit_part: 'Choix du compte Trustpilot',
        subtitle: 'Connectez-vous à votre compte Trustpilot pour commencer la configuration.',
        subtitle2: 'Recherchez et sélectionnez le business unit que vous souhaitez lier à notre interface.'
      },
      ka_desc: 'Intégrez Trustpilot pour recenser l\'ensemble des avis de vos clients et améliorez votre relation client.',
      logged: 'Compte Trustpilot connecté',
      login: 'Se connecter à Truspilot',
      logout: 'Se déconnecter',
      subtitle: 'Centralisez vos avis Trustpilot sur notre interface. Vous pourrez répondre à vos clients depuis la console de chat.'
    },
    twitter_dm: {
      config_saved: 'Vos modifications ont bien été enregistrées',
      failed: 'La connexion à votre compte X a échouée',
      faq: 'https://support.chayall.com',
      form: {
        login_part: 'Connexion',
        page_part: 'Finaliser la configuration',
        subtitle: 'Connectez-vous à votre compte X pour commencer la configuration.'
      },
      ka_desc: 'Recevez tous vos messages privés X dans votre console de chat et renforcez la fidélité de vos clients envers votre marque.',
      logged_out: 'Votre compte X est maintenant déconnecté',
      logout: 'Se déconnecter',
      subtitle: 'Echangez avec vos clients sur X. \nIls pourront vous contacter depuis votre page X ou la pastille X sur votre site web.'
    },
    twitter_comments: {
      config_saved: 'Votre compte X est maintenant connecté à ChayAll !',
      failed: 'La connexion à votre compte X a échoué',
      faq: 'https://support.chayall.com/',
      form: {
        login_part: 'Connexion',
        page_part: 'Finaliser la configuration',
        subtitle: 'Connectez-vous à votre compte X pour commencer la configuration.'
      },
      ka_desc: ' Renforcez la satisfaction de vos clients en interagissant avec eux via les commentaires X depuis votre console de chat.',
      logged_out: 'Votre compte X est maintenant déconnecté',
      logout: 'Se déconnecter',
      subtitle: 'Gérez vos commentaires X depuis votre Console Chat ChayAll.'
    },
    web_chat: {
      bad_color: "La couleur que vous avez sélectionnée n'est pas valide",
      color: 'Couleur',
      color_required: 'Un choix de couleur est requis',
      config_saved: 'Vos modifications ont bien été prises en compte',
      default_punchline: 'Nous sommes là pour vous aider',
      faq: 'https://support.chayall.com/article/60-comment-configurer-le-live-chat',
      ka_desc: 'Intégrez très facilement un Live Chat sur votre site web, vos SMS, vos mails, etc.',
      subtitle: 'Proposez à vos clients de vous contacter directement depuis votre site Web.\nPersonnalisez votre Live Chat et intégrez-le en quelques minutes.',
      title: 'Configuration du Live Chat',
      widget: {
        author: 'par ChayAll',
        input: 'Tapez votre message...',
        msg1: 'Bonjour, comment pouvons-nous vous aider ?',
        msg1_date: '10:18',
        msg2: 'Je cherche vos baskets argentées en 38 ?',
        msg2_date: '10:20',
        msg3: 'Parlez-vous de celles-ci ? 😊',
        msg3_date: '10:21'
      }
    },
    whatsapp: {
      ask_for_subscription: "Souscrire à l'offre complète",
      back_to_free: "Retour à l'offre gratuite",
      change_form: {
        details: 'Merci de préciser quelles modifications vous souhaitez apporter à votre profil WhatsApp',
        sent: 'Votre demande de modification a été envoyée',
        title: 'Demande de modification'
      },
      config_saved: 'Votre demande a bien été envoyée.',
      faq: 'https://support.chayall.com/article/35-comment-configurer-whatsapp-offre-gratuite',
      form: {
        subtitle: "Il ne vous reste plus qu'à cliquer sur \"Activer\". Ensuite, passez à la prochaine étape pour intégrer votre pastille WhatsApp sur votre site Web.",
        subtitle_full: "Echangez avec vos clients via WhatsApp et profitez de toutes ses fonctionnalités ! La création de compte peut prendre quelques jours.\n\nMerci de remplir ce formulaire. L'ensemble de ces informations seront visibles depuis votre {0}.",
        title: 'A vous de jouer !',
        wa_profil_label: 'profil WhatsApp',
        wa_profil_link: 'https://support.chayall.com/'
      },
      free: 'Ce canal n\'est disponible que pour les offres Essential, Pro et Entreprise.',
      ka_desc: 'Intégrez WhatsApp sur votre site web, vos SMS, vos mails, etc. et centralisez vos échanges sur notre plateforme.',
      language: 'language',
      send: 'Valider',
      subtitle: "Profitez gratuitement de WhatsApp Business. A noter qu'avec cette version, votre profil WhatsApp sera au nom de ChayAll."
    }
  },
  channels: {
    abc: {
      form: {
        details: 'Dites-nous en plus sur votre besoin'
      }
    },
    auto_msgs: {
      changes_saved: 'Vos modifications ont bien été prises en compte.'
    },
    channel_to_activate: {
      description: "Cliquez sur l'une de ces icônes pour ajouter le canal de votre choix sur votre site Web."
    },
    facebook_comments: {
      form: {
        page_id_placeholder: 'Sélectionner une page Facebook',
        page_id: 'Votre page Facebook'
      }
    },
    googlebm: {
      form: {
        brand_contact_email_address: 'Email du contact',
        brand_contact_email_address_details: 'Seules les addresses email d\'entreprise sont acceptées',
        brand_contact_name: 'Nom de famille du contact principal',
        brand_name: 'Nom de la marque',
        brand_website_url: 'Site Web de la marque',
        logo_url: 'Logo',
        logo_url_details: 'Recommandé : Format carré, 640x640px, 5MB Max.',
        place_id: 'Trouvez votre entreprise sur Google',
        privacy_policy: 'Page Web de votre politique de confidentialité (URL accessible au public)',
        welcome_message: 'Message de bienvenue'
      }
    },
    instagram_comments: {
      form: {
        page_id_placeholder: 'Sélectionner une page Facebook',
        page_id: 'Votre page Facebook liée au compte'
      }
    },
    instagram_dm: {
      form: {
        page_id_placeholder: 'Sélectionner une page Facebook',
        page_id: 'Votre page Facebook liée au compte'
      }
    },
    key_account: {
      form: {
        label: 'Nom de la messagerie',
        skill_placeholder: 'Sélectionner une compétence',
        skill: 'Compétence associée'
      }
    },
    messenger: {
      form: {
        choose: 'Choisissez une page',
        page_id_placeholder: 'Sélectionner une page Facebook',
        page_id: 'Votre page Facebook'
      }
    },
    no_embedding: "Aucun point d'entrée n'a été paramétré",
    timezone: {
      placeholder: 'Sélectionner un fuseau horaire',
      title: 'Fuseau horaire'
    },
    visibility_toggle: 'Messagerie en ligne',
    visibility_toggle_helper: 'Permet de rendre accessible ou non le canal aux utilisateurs',
    visibility_toggle_details: 'Vous devez valider afin que la modification soit prise en compte',
    web_chat: {
      form: {
        color: 'Couleur',
        logo: 'Logo de votre entreprise',
        logo_details: 'Format carré, Recommandé 640x640px, max 5MB.',
        logo_required: 'Le logo de votre entreprise est requis',
        name: 'Nom de votre entreprise',
        name_required: 'Le nom de votre entreprise est requis',
        powered_by: 'Afficher la mention "par ChayAll"',
        punchline: 'Accroche',
        punchline_details: "Correspond à la phrase située dans l'en-tête de la fenêtre de chat.",
        punchline_required: 'Une accroche est requise'
      }
    },
    whatsapp: {
      config_saved: 'Votre demande a bien été envoyée',
      config_saved_b: 'Bravo ! Votre demande de création de compte WhatsApp a bien été envoyée !',
      detail: 'Encore un peu de patience... Votre compte WhatsApp Business sera validé dans les prochains jours. Vous serez informé par mail dès que vous pourrez commencer à échanger avec vos clients depuis WhatsApp.',
      edit: 'Modifier ma demande',
      form: {
        business_name: "Nom de l'entreprise",
        join: "<span class=\"tag\">WhatsApp</span>Message pré-rempli à envoyer par l'utilisateur",
        join_details: 'Ce message sera pré-rempli dans le champ texte de WhatsApp et devra être envoyé par le client pour démarrer la discussion. \nChoisissez la langue de ce message.'
      },
      form_full: {
        brand_logo: 'Logo',
        brand_logo_details: 'Format carré, recommandé 640x640px, max 5 MB.',
        business_desc: "Description de l'entreprise",
        business_desc_details: 'Max 256 caractères',
        business_name: "Nom de l'entreprise",
        city: 'Ville',
        country: 'Pays',
        email: 'Adresse email du contact',
        existing_phone_number: 'Utiliser un numéro de téléphone existant',
        facebook_business_manager_id: 'Facebook Business Manager ID',
        facebook_business_manager_id_details: '<a class="gb-link faq" href="{0}" target="_blank">{1}</a>',
        facebook_business_manager_id_details_label: 'Où trouver mon Facebook Business Manager ID ?',
        facebook_business_manager_id_details_link: 'https://support.chayall.com/',
        phone_number: 'Numéro de téléphone',
        phone_number_details: 'Le numéro de téléphone ne doit pas être connecté à un compte WhatsApp personnel ou business existant',
        postal_address: 'Adresse',
        postal_code: 'Code postal',
        url: 'Site Web de la marque'
      },
      join_saved: 'Vos modifications ont bien été enregistrées',
      phone_number: 'Voici le numéro de téléphone associé à votre compte WhatsApp :',
      send: 'Envoyer la demande',
      send_edit: 'Envoyer la modification'
    }
  },
  chart: {
    axys_day_format: '%e %b',
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
    shortMonths: [
      'Janv.',
      'Févr.',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juil.',
      'Août',
      'Sept.',
      'Oct.',
      'Nov.',
      'Déc.'
    ],
    tooltip_day_format: '%A %e %b %Y',
    weekdays: [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi'
    ]
  },
  click_here_to_choose_a_file: 'Cliquer ici pour choisir un fichier',
  click_here_to_choose_a_new_file: 'Cliquer ici pour modifier le fichier',
  close: 'Fermer',
  close_modal: {
    close: 'Fermer',
    surveys: {
      empty: 'Pour envoyer une enquête de satisfaction à votre client, il suffit d\'en créer une dans la section "Enquêtes de satisfaction" de vos Paramètres.',
      send_and_close: 'Envoyer et fermer',
      send_survey: 'Proposez à votre client de laisser un avis sur votre échange.'
    }
  },
  cobrowsing: {
    invit: 'Démarrer une session de co-navigation',
    join: 'Afficher la co-navigation',
    rich_content: 'Co-navigation en cours d\'initialisation...'
  },
  confirm_email: {
    button: "Renvoyer l'email",
    subtitle: 'Pour avoir accès à {APP_NAME}, merci de valider votre adresse email',
    title: 'Confirmez votre adresse email ☺️'
  },
  crm: {
    button_label: 'Recherche CRM',
    no_result: 'Aucun contact du CRM ne correspond aux informations client de la discussion.',
    search_results: 'Résultats de la recherche CRM',
    select_contact: 'Associer le contact'
  },
  customer_quote: {
    content: `“La solution ChayAll est à ma<br>
      connaissance la plus complète du<br>
      marché à ce jour. Une équipe avec<br>
      la volonté de faire évoluer la<br>
      solution avec de nouvelles<br> fonctions.”`,
    title: "Brew's Immo"
  },
  dashboard: {
    bots: 'Bots',
    channels: 'Canaux digitaux',
    key_account: {
      activity: {
        'log-duration_details': 'Exprimé en hh:mm',
        'status-1': 'Disponible',
        'status-2': 'Discussion en cours',
        'status-3': 'Nombre de discussion maximum atteint',
        'status-4': 'En pause',
        'status-5': 'Déconnecté',
        agent_skills: 'Compétences associeés',
        agent_running: 'En cours',
        answers: 'Réponses envoyées',
        answered: 'Réponses envoyées',
        autofill: {
          disabled: 'Affectation automatique des discussions désactivée',
          enabled: 'Affectation automatique des discussions activée'
        },
        average_close_transfer: 'DMT (mm:ss)',
        average_stars: 'Note moyenne',
        average_take: 'DMA (mm:ss)',
        closed: 'Traités',
        deleted: 'Suppressions',
        deleted_answer: 'Supprimés',
        dma: 'DMA (mm:ss)',
        dma_details: 'Durée moyenne d’attente d\'une discussion exprimée en mm:ss',
        dmt_details: 'Durée moyenne de traitement d\'une discussion exprimée en mm:ss',
        dmt: 'DMT (mm:ss)',
        error: {
          'agent:forbidden': 'Vous ne disposez pas des autorisations nécessaires pour modifier le statut de ce conseiller.'
        },
        iterations_details: 'Correspond au nombre de messages envoyés et reçus par discussion prise sur une période donnée',
        iterations: 'Interactions',
        late_rooms: 'Nombre de discussions en retard pour cet agent',
        log_duration: 'Temps de log.',
        logout_user: 'Déconnecter le conseiller',
        msg_sent: 'Mess. envoyés',
        name: 'Nom',
        nps: 'NPS',
        pause_user: 'Mettre le conseiller en pause',
        ratio: 'Disc. / Heure',
        read: 'Vus',
        rooms_closed_details: 'Correspond au nombre de discussions traitées par vos agents',
        rooms_closed: 'Fermées',
        rooms_running: 'En cours',
        rooms_total: 'Prises',
        rooms_transferred: 'Transférées',
        satisfaction: 'Satisf.',
        status: 'Statut',
        subtitle: 'Conseillers actifs',
        taken: 'Pris en charge',
        title: 'Activité des conseillers',
        updated_answer: 'Modifiés'
      },
      campaigns: {
        global_data: {
          delivered_percent: 'Taux de délivrabilité',
          delivered_percent_helper: 'Pourcentage moyen des messages délivrés par rapport aux messages envoyés sur la période donnée',
          delivered: 'Délivrés',
          delivered_helper: 'Nombre de messages délivrés sur la période donnée',
          failed: 'Échecs',
          failed_helper: 'Nombre de messages en échecs sur la période donnée',
          seen_percent: 'Taux d\'ouverture',
          seen_percent_helper: 'Pourcentage moyen des messages ouverts par rapport aux messages envoyés sur la période donnée',
          seen: 'Ouverts',
          seen_helper: 'Nombre de messages ouverts sur la période donnée',
          sent: 'Envoyés',
          sent_helper: 'Nombre de messages envoyés sur la période donnée',
          title: 'Données globales',
          visual_svi_opened: 'Ouvertures de SVI visuels',
          visual_svi_opened_helper: 'Nombre de SVI visuels ouverts sur la période donnée',
          x: 'Intervalle sélectionné',
          y: 'Nombre de messages'
        },
        group_by: {
          campaign: 'Par campagne',
          delivery_batch: 'Par envoi',
          template_kind: 'Par messagerie'
        },
        interval: {
          day: 'Par jour',
          month: 'Par mois',
          week: 'Par semaine'
        },
        messages_per_interval: 'Messages par intervalle',
        messages_per_interval_helper: 'La liste d\'intervalles peut varier en fonction de la période de temps sélectionnée.',
        table: {
          kind: 'Type',
          name: 'Nom de la campagne',
          send_at: 'Date et heure d\'envoi'
        },
        title: 'Campagnes'
      },
      comments: {
        title: 'Commentaires'
      },
      contact_reasons_count: 'Nombre d\'occurrences',
      discussions: {
        channels: 'Messageries',
        closed: 'Fermées',
        created: 'Entrantes',
        msg_sent: 'Messages envoyés',
        nps: 'NPS',
        running: 'En cours',
        running_title: `En cours

Cette colonne n'est pas impactée par les filtres sélectionnés et affiche des valeurs en temps réel`,
        satisfaction: 'Satisfaction',
        taken: 'Prises',
        title: 'Discussions',
        transferred: 'Transférées',
        waiting: 'En attente',
        waiting_title: `En attente

Cette colonne n'est pas impactée par les filtres sélectionnés et affiche des valeurs en temps réel`
      },
      export: 'Exporter les données',
      filter_by: 'Filtrer par :',
      global: {
        active_agents: 'Conseillers actifs',
        active_agents_details: 'Nombre total de conseillers en ligne à l’instant T',
        avg_running: 'Temps conv.',
        avg_running_details: 'Durée moyenne de traitement des discussions depuis le début de la journée, exprimé en hh:mm',
        avg_satisfaction: 'Satisfaction',
        avg_satisfaction_details: 'Note moyenne des conseillers depuis le début de la journée',
        avg_waiting: 'Attente moy.',
        avg_waiting_details: 'Durée moyenne d\'attente des discussions depuis le début de la journée, exprimée en hh:mm',
        paused_agents: 'Conseillers en pause',
        paused_agents_details: 'Nombre total de conseillers en pause à l’instant T'
      },
      groups: 'Groupes de compétences',
      groups_webivr: 'Groupes de SVI',
      integrations: 'Points d\'entrée',
      msg_per_channel: {
        read: 'Reçu(s)',
        sent: 'Envoyé(s)',
        x: 'Réseaux sociaux',
        y: 'Nombre de commentaires'
      },
      reviews: {
        answered: 'Réponses envoyées',
        average_stars: 'Note moyenne',
        average_stars_details: 'Note moyenne des notes reçues depuis l\'intégration des avis sur ChayAll',
        closed: 'Traités',
        deleted_answer: 'Supprimés',
        incoming: 'Entrants',
        platforms_score: {
          details: 'Note moyenne par plateforme sur la période donnée',
          title: 'Note moyenne par plateforme',
          x: 'Plateformes',
          y: 'Notes moyennes'
        },
        reviews_per_status: {
          title: 'Nombre d’avis par statut',
          x: 'Statuts',
          y: 'Nombre d\'avis'
        },
        running: 'En cours',
        title: 'Données par plateforme',
        updated_answer: 'Modifiés',
        waiting: 'En attente'
      },
      rooms_per_channel: {
        title: 'Discussions totales par canal',
        x: 'Messageries',
        y: 'Nombre de discussions'
      },
      rooms_per_status: {
        title: 'Discussions totales par statut',
        x: 'Intervalle sélectionné',
        y: 'Nombre de discussions'
      },
      skills: 'Compétences',
      subaccounts: 'Sous-comptes',
      subtitle: 'Retrouvez les chiffres essentiels de votre activité, pour plus de détails\nrendez-vous dans les {0}',
      template_kinds: 'Types de campagne',
      threads: {
        answers: 'Réponses envoyées',
        dealt: 'Traités',
        deleted: 'Suppressions',
        in: 'Entrants',
        read: 'Vus',
        social_networks: 'Réseaux sociaux',
        title: 'Fils de discussion'
      },
      title: 'Activité globale',
      webivr: {
        distribution: {
          title: 'Répartition'
        },
        export_events: 'Evénement par SVI Visuel',
        export_forms: 'Détails des formulaires',
        flow: {
          title: 'Customer analytics'
        },
        journey: {
          title: 'Analyse du parcours client',
          x: 'Total sur l\'intervalle sélectionné',
          y: 'Quantité'
        },
        productivity: {
          choice: 'Choix',
          choice_details: 'Nombre de clics sur un choix par session unique',
          choice_percent: '% Choix',
          choice_percent_details: 'Nombre de clics sur un choix par rapport au nombre d\'ouvertures de SVI Visuel',
          day: 'Par jour',
          gbagent_percent: '% Mise en relation',
          gbagent_percent_details: 'Nombre de demande de contact par rapport au nombre d\'ouvertures de SVI Visuel',
          gbagent: 'Demande de contact',
          gbagent_details: 'Nombre de clics sur un mail, appel direct ou rappel par session unique',
          half_hour: 'Par demi heure',
          hour: 'Par heure',
          interval: 'Intervalle',
          month: 'Par mois',
          open_percent: '% Ouverts',
          open_percent_details: 'Nombre d\'ouvertures de SVI Visuels par rapport au nombre de SMS envoyés',
          open: 'Ouv. SVI Visuel',
          open_details: 'Nombre d\'ouvertures de SVI Visuels par session unique',
          proposal_details: 'Nombre de sessions utilisateur créées',
          proposal: 'Proposit SVI Visuel',
          quarter_hour: 'Par quart d\'heure',
          selfcare_percent: '% Résolus',
          selfcare_percent_details: 'Nombre d\'auto-résolution par rapport au nombre d\'ouvertures de SVI Visuel',
          selfcare: 'Auto. résolution',
          selfcare_details: 'Nombre de clics sur un URL ou une FAQ par session unique',
          sms_details: 'Nombre de SMS envoyés sur la période donnée',
          sms_percent: '% SMS',
          sms: 'SMS',
          subtitle: 'Activité',
          title: 'Productivité de vos SVI Visuels',
          week: 'Par semaine'
        },
        volume: {
          title: 'Volume',
          x: 'Intervalle sélectionné',
          y: 'Quantité'
        },
        sankey: {
          all_versions: 'Toutes les versions',
          empty_list: 'Merci de sélectionner un SVI Visuel pour afficher ses versions',
          trees: 'Arborescences',
          usage_rate_min: 'Taux de passage minimum (%)',
          versions: 'Versions'
        }
      }
    },
    please_configure_channel: 'Pour commencer, activez un canal digital.',
    please_configure_channel_subtitle: "Aucun canal n'est pour le moment activé. Configurez-en un pour commencer à échanger avec vos clients.",
    please_confirm_email: 'Merci de confirmer votre adresse email en cliquant sur le lien envoyé par mail',
    selected_channel: '{0} canal selectionnée',
    selected_channels: '{0} canaux digitaux selectionnées',
    subtitle: 'Suivez votre activité en temps réel',
    title: 'Dashboard',
    over_quota: 'Vous avez utilisé tous les messages compris dans votre offre ce mois-ci, changez d\'offre pour continuer à envoyer et recevoir des messages'
  },
  diagram: {
    actions: {
      api: 'API',
      button: 'Bouton',
      call: 'Appel direct',
      callback: 'Rappel',
      campaign_template: 'Modèle de campagne',
      campaigns_contactlist: 'Opt-in campagne',
      chat: 'Console Chat',
      choice: 'Choix',
      choice_fallback: 'Choix non détecté',
      delete: 'Suppression',
      dialogflow: 'Agent Dialogflow',
      dialogflow_intent: 'Intention',
      dialogflow_end: 'Fin d\'intéraction IA',
      dialogflow_fallback: 'Non détection d\'intention',
      entrypoint: 'Point d\'entrée',
      console: 'Console externe',
      forms: {
        button: {
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Sous-titre du bouton',
            'error-msg': `Le sous-titre est requis pour les listes de plus de 3 choix WhatsApp
              <span title="Si vous proposez une liste de moins de 3 choix, le sous-titre du bouton sera ignoré dans WhatsApp">
              [?]</span>`,
            'error-too-long-19-msg': 'Le sous-titre est trop long'
          },
          title: {
            placeholder: 'Titre du bouton',
            'error-too-long-23-msg': 'Le titre est trop long'
          }
        },
        callback: {
          eligible_for_contact_request: {
            placeholder: 'Demande de contact'
          },
          title: {
            placeholder: 'Titre du bouton'
          },
          thirdparties_id: {
            placeholder: 'Rappel'
          }
        },
        campaigns_contactlist: {
          contact_list: 'Audience cible'
        },
        choice: {
          thirdparties_id: {
            placeholder: 'Identifiant de rappel'
          },
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Sous-titre du choix',
            'error-too-long-71-msg': 'Le sous-titre est trop long'
          },
          test_kind: {
            options: {
              none: 'Aucune',
              callback: 'Rappel'
            },
            placeholder: 'Condition d\'affichage'
          },
          title: {
            'error-msg': 'Le titre est trop long',
            'error-too-long-23-msg': 'Le titre est trop long',
            placeholder: 'Titre du choix'
          },
          toogle: {
            placeholder: 'Affichage conditionnel'
          }
        },
        choice_fallback: {
          details: 'Définissez un parcours vers lequel rediriger votre client dans le cas où l\'utilisateur saisit du texte au lieu de faire un choix',
          title: 'Choix non détecté'
        },
        console: 'Console externe',
        'custom-tag': {
          details: 'Ce libellé sera visible dans vos rapports et dans le customer analytics',
          duplicates: 'Ce libellé est déjà utilisé',
          label: 'Libellé de l\'action',
          placeholder: 'Saisissez un libellé personnalisé'
        },
        'css-classes': {
          label: 'Classes CSS',
          placeholder: 'Saisissez des classes CSS pour personnaliser l\'affichage'
        },
        'default-tag': 'Libellé par défaut',
        dialogflow: {
          title: 'Dialogflow',
          label: 'Agent',
          details: 'Sélectionner l’agent Dialogflow que vous souhaitez activer dans votre parcours',
          wait_input: 'Attendre une saisie utilisateur'
        },
        dialogflow_end: {
          title: 'Fin d\'intéraction IA',
          details: 'Définissez un parcours vers lequel rediriger votre client dans le cas où l\'intention identifiée est une fin de conversation'
        },
        dialogflow_fallback: {
          title: 'Non détection d\'intention',
          details: 'Définissez un parcours vers lequel rediriger votre client dans le cas où nous n\'arrivons pas à identifier son intention'
        },
        dialogflow_intent: {
          title: 'Intent'
        },
        errors: {
          'custom_node_label:unique': 'Ce libellé est déjà utilisé',
          'label:unique': 'Le libellé doit être unique',
          unknown_identifier: 'Identifiant de rappel inconnu'
        },
        faq: {
          title: 'FAQ',
          eligible_for_selfcare: {
            placeholder: 'Auto-résolution'
          },
          faq: {
            placeholder: 'Sélectionner une FAQ'
          }
        },
        form: {
          title: 'Formulaire',
          form: {
            placeholder: 'Sélectionner un formulaire'
          },
          is_survey: 'Enquête de satisfaction',
          score_ranges: 'Redirections de score'
        },
        gbagent: {
          auto_msgs: 'Messages auto.',
          auto_msgs_agents: 'Messages automatiques joués en fonction de la disponibilité de vos agents',
          auto_msgs_cal: 'Messages automatiques joués en fonction de votre calendrier',
          title: 'Console Chat'
        },
        image: {
          title: 'Image'
        },
        messaging: {
          title: {
            placeholder: 'Point d\'entrée'
          }
        },
        messenger_optin_request: {
          contact_list: 'Audience cible',
          image: 'Image',
          title: 'Titre'
        },
        page: {
          icon: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Sous-titre de la page'
          },
          title: {
            'error-msg': 'Le titre est trop long',
            placeholder: 'Titre de la page'
          },
          template: {
            placeholder: 'Sélectionner un thème',
            title: 'Thème'
          }
        },
        ravana: {
          title: 'Agent conversationnel',
          label: 'Agent',
          details: 'Sélectionner l’agent conversationnel que vous souhaitez activer dans votre parcours',
          wait_input: 'Attendre une saisie utilisateur'
        },
        routing_load_balancer: {
          subtitle: 'Définissez les pourcentages sur lesquels effectuer des répartition de flux',
          title: 'Répartition flux'
        },
        totree: {
          title: 'Arborescence'
        },
        text: {
          title: {
            placeholder: 'Texte'
          }
        },
        txt: {
          'editor-title': 'Texte',
          txt: {
            placeholder: 'Saisissez le texte envoyé par le bot',
            variable_not_exists: 'La variable {0} n\'existe pas | Les variables suivantes n\'existent pas : {0}'
          },
          wa_vars: 'Personnalisez vos échanges avec vos clients à l’aide de l’utilisation de la variable suivante : {username}. Par exemple : “Bonjour {username}, comment puis-je vous aider ?”'
        },
        url: {
          eligible_for_contact_request: {
            placeholder: 'Demande de contact'
          },
          eligible_for_selfcare: {
            placeholder: 'Auto-résolution'
          },
          email: {
            placeholder: 'Email'
          },
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Sous-titre du bouton'
          },
          tel: {
            placeholder: 'Numéro de télépone'
          },
          url: {
            'error-msg': 'L\'URL que vous avez fournie ne peut être interprétée comme une URL valide',
            placeholder: 'URL'
          },
          title: {
            placeholder: 'Titre du bouton'
          }
        },
        'user-input': {
          subtitle: 'Initilisez une saisie utilisateur',
          title: 'Saisie utilisateur'
        },
        'user-input-check': {
          placeholder: 'Ajouter un mot clé ...',
          subtitle: 'Ajouter des mots-clés (à l\'aide de la touche Entrée) qui permettront au bot de comprendre et analyser la réponse de l’utilisateur',
          title: 'Saisie utilisateur'
        },
        'user-input-error': {
          placeholder: 'Tapez votre message d\'erreur ...',
          subtitle: 'Ecrivez le message d’erreur qu’enverra le bot si la saisie est incorrecte',
          title: 'Message d\'erreur'
        }
      },
      job: 'Tâche',
      image: 'Image',
      link: 'URL',
      messenger_optin_ko: 'Opt-in ignorée',
      messenger_optin_ok: 'Opt-in acceptée',
      messenger_optin_request: 'Opt-in campagne',
      ravana: 'Agent conversationnel',
      ravana_output: {
        title: 'Sortie',
        kind: {
          default: 'Sortie par défaut',
          unknown: 'Sortie inconnue'
        }
      },
      routing_load_balancer: 'Répartition flux',
      get routing () {
        return this.routing_load_balancer
      },
      passthrough: {
        title: 'Sortie'
      },
      get routing_output () {
        return this.passthrough
      },
      split: 'Extract',
      start: 'Démarrer votre bot',
      survey: 'Sondage',
      text: 'Texte',
      tooltips: {
        button: 'Intégrez des choix dans un bouton',
        gbagent: 'Mettez en relation vos clients avec vos agents',
        choice: 'Proposez un ou plusieurs choix à vos clients',
        console: 'Redirigez vos clients vers vos consoles externes',
        dialogflow: 'Interprétez les intentions de vos clients avec l\'IA',
        image: 'Envoyez une image à votre client',
        totree: 'Redirigez votre client vers le début d\'une arborescence',
        txt: 'Envoyez du texte à votre client',
        url: 'Envoyez un lien à votre client',
        input: 'Attendez une saisie utilisateur et analysez la avec l\'action "Analyse de saisie"',
        input_check: 'Analysez la saisie utilisateur à l\'aide des mots clés',
        input_error: 'Envoyez un message d\'erreur lorsque le bot n\'arrive pas à analyser la saisie utilisateur'
      },
      totree: 'Arborescence',
      update_root: 'Changer de nœud racine',
      update_parent: 'Changer de nœud parent',
      'user-input': 'Saisie utilisateur',
      'user-input-check': 'Analyse de saisie',
      'user-input-error': 'Erreur de saisie'
    },
    'confirm-delete': {
      delete_all: 'Suppression globale',
      delete_one: 'Suppression unitaire',
      subtitle: 'Souhaitez-vous uniquement supprimer l\'action sélectionnée ou toutes les actions qui y sont rattachées ?',
      title: 'Supprimer',
      webivr: {
        subtitle: 'Attention, si vous supprimez cette action, toutes celles qui y sont rattachées seront également supprimées.'
      }
    },
    'copy-paste-nodes': {
      'copy-ok': 'Copie OK',
      'paste-ok': 'Collage OK',
      'paste-totree-warn': 'Attention, vous avez copié/collé une ou plusieurs actions "arborescence" d\'un autre bot builder, pensez à les mettre à jour dans votre bot builder actuel'
    },
    errors: {
      bad_validation_node_dialogflow_forbidden_kinds: `Cette action ne peut
        être suivie que par des "Intents" et des "Non détection d'intention" Dialogflow.`,
      bad_validation_node_ravana_forbidden_kinds: `Cette action ne peut
        être suivie que par des sorties générées automatiquement.`,
      bad_validation_node_button_requires_choice: `Cette action ne peut
        être suivie que par des "Choix" et un "Choix non détecté".`,
      bad_validation_node_choice_cant_be_root: `Un nœud dont l'action
        est "Choix" ne peut être racine`,
      bad_validation_node_choice_fallback_cant_be_root: `Un nœud dont l'action
      est "Choix non détecté" ne peut être racine`,
      bad_validation_node_entrypoints_cant_has_different_children: `Un nœud dont l'action est "Point d'entrée" ou "Modèle de campagne"
      ne peut avoir des enfants différents des autres nœuds du même type.`,
      bad_validation_node_input_cant_be_root: `Un nœud dont l'action
        est "analyse saisie" ou "erreur de saisie" ne peut être racine`,
      bad_validation_node_input_check_error_only_child_input: `Cette action ne peut
        être précédée que par une "Saisie utilisateur".`,
      bad_validation_node_input_only_one_error: `Les nœuds dont
        l'action est "saisie utilisateur" n'acceptent qu'un seul nœud avec
        l'action "erreur de saisie".`,
      bad_validation_node_input_requires_check_error: `Cette action ne peut être
        suivie que par "Analyse de saisie" et "Erreur de saisie".`,
      bad_validation_node_messenger_optin_output_cant_be_root: `Un nœud dont l'action
        est "Opt-in acceptée" ou "Opt-in ignorée ne peut être racine`,
      bad_validation_node_messenger_optin_output_only_child_messenger_optin_request: `Cette action ne peut
        être précédée que par une "Opt-in campagne".`,
      bad_validation_node_messenger_optin_request_too_much_children: `Un nœud "Opt-in campagne" ne peut
        être suivi que par un seul nœud, plusieurs nœuds "Choix" ou de deux nœuds : "Opt-in acceptée" et "Opt-in ignorée".`,
      bad_validation_node_not_button_too_much_children: `Impossible d'ajouter plus d'une action
        après les actions suivantes : Texte, Url, Image, Démarrer votre bot, Console chat,
        Analyse de saisie, Erreur de saisie, Choix. Sauf s'il s'agit d'actions "Choix" et d'une action "Choix non détecté".`,
      bad_validation_node_routing_only_child_output: `Cette action ne peut être
        suivie que par des actions "Sortie".`,
      bad_validation_node_too_much_choice_wa: `Il est interdit de mettre plus
        de 3 choix avec la messagerie WhatsApp`,
      bad_validation_node_too_much_choice_wa_list_picker: `Il est interdit de mettre plus
        de 10 choix avec la messagerie WhatsApp pour un list picker`,
      bad_validation_node_totree_cant_be_root: `Un nœud  dont l'action
        est "Arborescence" ne peut être racine`,
      bad_validation_node_totree_cant_have_children: `Impossible d'ajouter une
        action après "Arborescence".`,
      bad_validation_cant_drop_node_on_itself: 'Une action doit obligatoirement être rattachée à une autre action.',
      bad_validation_existing_node_cant_drop_on_connector: `Vous ne pouvez pas
        déplacé un nœud existant entre deux autres nœuds.`,
      general: 'Erreur générale',
      unauthorized_action: 'Action impossible',
      warning: 'Attention'
    },
    faq: 'https://support.chayall.com/article/108-comment-fonctionne-le-bot-builder',
    'floating-dropdown': {
      copy: 'Copier l’action et ses enfants',
      edit: 'Éditer',
      paste: 'Coller l’action et ses enfants',
      paste_and_replace: 'Remplacer l’action et ses enfants'
    },
    ai: {
      'intent-added': 'Intentions ajoutées'
    },
    punchline: 'Glissez-déposez à droite',
    title: 'Actions'
  },
  download: 'Télécharger',
  email_does_not_exist: 'L\'adresse mail renseignée n\'existe pas.',
  finish: 'Terminer',
  input_channel_trees: {
    prefilled_msg_placeholder: 'Message pré-rempli',
    prefilled_msg_tooltip: 'Ce message sera pré-rempli dans le champ texte de WhatsApp et devra être envoyé par le client pour démarrer la discussion.',
    tree_placeholder: 'Sélectionner une arborescence'
  },
  import: 'Importer',
  install: 'Installer',
  stats: {
    api: 'API',
    browsing_through_ivr: 'Navigation au sein de l\'arborescence',
    exports: {
      dialogflow: 'Analyse IA',
      events: 'Événements par discussion',
      rooms: 'Discussions par statut',
      smartcoaching: 'Smartcoaching'
    },
    please_choose_bot: 'Merci de sélectionner un bot pour afficher le customer analytics',
    please_choose_tree: 'Merci de sélectionner un SVI Visuel, une version et une arborescence pour afficher le customer analytics',
    please_choose_webivr: 'Merci de sélectionner un SVI Visuel pour afficher le customer analytics',
    please_choose_another_data: 'Aucune donnée sur la période et/ou le SVI Visuel et/ou le point d\'entrée sélectionné(s)',
    please_choose_another_data_tree: 'Aucune donnée sur la période et/ou l\'arborescence sélectionnée',
    title: 'Statistiques',
    subtitle: 'Suivez votre activité en détail grâce à vos statistiques',
    today_msg_count: {
      desc: 'Message(s) total(s) aujourd\'hui'
    },
    full: {
      total_rooms: {
        desc: 'Discussion(s) totale(s)',
        helper: 'Correspond à l\'ensemble des discussions arrivées dans la Console Chat'
      }
    },
    total_closed_rooms: {
      desc: 'Discussion(s) traitée(s)',
      helper: 'Correspond à l\'ensemble des discussions fermées'
    },
    total_late_rooms: {
      desc: 'Discussion(s) en retard',
      helper: 'Correspond à l\'ensemble des discussions en retard'
    },
    total_msg_count: {
      desc: 'Message(s) échangé(s)'
    },
    avg_waiting_time: {
      desc: 'Temps d\'attente moyen'
    },
    empty: 'Aucune donnée sur la période et/ou les canaux digitaux sélectionnés',
    export: 'Exporter CSV',
    'user-rooms': {
      label: 'Répartition des discussions ancien & nouvel utilisateur',
      y: 'Nombre de discussions'
    },
    new_user: {
      true: 'Nouvel utilisateur',
      false: 'Ancien utilisateur'
    },
    msg_count: {
      title: 'Suivi de votre forfait',
      label: 'soit {0}% de {1} messages du forfait mensuel'
    },
    room_count: {
      title: 'Echanges du jour'
    },
    today_rooms: {
      desc: 'Discussion(s) totale(s) aujourd\'hui',
      helper: 'Correspond à l\'ensemble des discussions arrivées dans la Console Chat aujourd\'hui'
    },
    running_rooms: {
      desc: 'Discussion(s) en cours actuellement',
      helper: 'Correspond aux discussions en statut en cours'
    },
    late_rooms: {
      desc: 'Discussion(s) en retard actuellement',
      helper: 'Correspond aux discussions auxquelles l\'utilisateur n\'a pas eu de réponse au bout de 10 min'
    },
    facebook: 'Messenger',
    facebook_comments: 'Facebook',
    web: 'Live Chat',
    get webchat () { return this.web },
    whatsapp: 'WhatsApp',
    googlebm: 'Google\'s Business Messages',
    apple: 'Apple Messages for Business',
    instagram_comments: 'Instagram',
    instagram_dm: 'Instagram Direct',
    rcs: 'RCS',
    sms: 'SMS',
    telegram: 'Telegram',
    twitter_comments: 'X',
    twitter_dm: 'X',
    total_rooms: {
      label: 'discussion(s)',
      desc: 'Nombre total de discussions'
    },
    take_rate: {
      label: 'de taux de réponse',
      desc: 'Discussions prises en charge / discussions totales'
    },
    msg_average: {
      label: 'interaction(s)',
      desc: 'Messages en moyenne, au cours d\'une discussion'
    },
    channels: {
      label: 'Discussions par canal'
    },
    'channel-rooms': {
      label: 'Répartition des discussions',
      x: 'Intervalle sélectionné',
      y: 'Nombre de discussions'
    },
    'waiting-rooms': {
      label: 'Temps d\'attente moyen',
      x: 'Intervalle sélectionné',
      y: 'Durée (s)'
    },
    key_account: {
      channels: {
        title: 'Répartition des canaux digitaux'
      },
      conversations: {
        title: 'Productivité de vos conversations',
        subtitle: 'Conversations',
        interval: 'Intervalle',
        sms: 'SMS',
        guests: 'Visiteurs',
        disc_bot: 'Disc. Bot',
        disc_bot_details: 'Correspond au nombre de discussions prises en charge par le bot',
        disc_agent: 'Disc. Agent',
        disc_agent_details: 'Correspond au nombre de discussions prises en charge par les agents',
        first_response_duration: 'DMPR (mm:ss)',
        first_response_duration_details: 'Délai moyen de première réponse exprimé en mm:ss',
        waiting: 'Présentée',
        waiting_details: 'Correspond au nombre de discussions présentées dans la console agent (qui ont été au statut en attente)',
        closed: 'Traitée',
        closed_details: 'Correspond au nombre de discussions traitées par vos agents',
        msg_received: 'Mess. reçus',
        msg_received_details: 'Correspond au nombre de messages envoyés par le visiteur',
        msg_sent: 'Mess. envoyés',
        msg_sent_details: 'Correspond au nombre de messages envoyés par le bot et par l\'agent',
        response_rate: 'Tx de réponse',
        response_rate_details: 'Correspond au nombre de discussions prises en charge par un agent, par rapport au nombre de discussions présentées sur la console agent',
        treatment: 'DMT (mm:ss)',
        treatment_details: 'Durée moyenne de traitement d\'une discussion exprimée en mm:ss',
        initiation: 'Init. conv. %',
        linking: 'Mise en relation',
        linking_details: 'Correspond au nombre de discussions prise en charge par un agent par rapport au nombre de discussions totales, arrivant sur le bot',
        quarter_hour: 'Par quart d\'heure',
        half_hour: 'Par demi heure',
        hour: 'Par heure',
        day: 'Par jour',
        week: 'Par semaine',
        month: 'Par mois',
        unicity: {
          guest: 'Unicité client',
          off: 'Temps réel',
          session: 'Unicité session'
        },
        waiting_duration: 'DMA (mm:ss)',
        waiting_duration_details: 'Durée moyenne d’attente d\'une discussion exprimée en mm:ss'
      },
      export: 'exportez les données',
      messages: {
        title: 'Répartition des messages',
        x: 'Intervalle sélectionné',
        y: 'Nombre de messages',
        bot_type: 'Chatbot',
        agent_type: 'Agent',
        received_type: 'Messages reçus',
        sent_type: 'Messages envoyés'
      },
      response_time: {
        details: 'Correspond au temps de réponse à une discussion avant qu’elle ne soit prise en charge',
        title: 'Temps de réponse en minutes',
        x: 'Temps de réponse (min)',
        y: 'Nombre de réponses'
      },
      rooms_details: {
        title: 'Discussions en détail'
      },
      rooms_per_channel: {
        title: 'Discussions par canal',
        x: 'Intervalle sélectionné',
        y: 'Nombre de discussions'
      },
      sankey: {
        title: 'Flux'
      },
      subtitle: 'Retrouvez les chiffres détaillés de votre activitité, affinez votre\nrecherche avec les filtres.' // et {0}.'
    }
  },
  date: {
    dateFormat: 'DD/MM/YYYY',
    datetimeFormat: 'DD/MM/YYYY HH:mm',
    hourFormat: 'HH:00',
    hrdateformat: 'D MMM YYYY',
    hrdatetimeformat: 'D MMM YYYY, H[h]mm',
    longdatetimeFormat: 'DD/MM/YYYY HH:mm:ss',
    longtimeFormat: 'HH:mm:ss',
    relativeTime: {
      future: 'dans %s',
      past: 'il y a %s',
      s: 'quelques secondes',
      s_short: '< 1 min',
      m: 'une minute',
      m_short: '1 min',
      mm: '{0} minutes',
      mm_short: '{0} min',
      h: 'une heure',
      h_short: '1 h',
      hh: '{0} heures',
      hh_short: '{0} h',
      d: 'un jour',
      d_short: '1 j',
      dd: '{0} jours',
      dd_short: '{0} j',
      M: 'un mois',
      M_short: '1 m',
      MM: '{0} mois',
      MM_short: '{0} m',
      y: 'un an',
      y_short: '1 an',
      yy: '{0} ans',
      yy_short: '{0} ans'
    },
    shorttimeFormat: 'HH:mm'
  },
  datepicker: {
    cancel: 'Annuler',
    last_month: 'Le mois dernier',
    last_week: 'La semaine dernière',
    last_year: 'L\'année dernière',
    reset: 'Réinitialiser',
    save: 'Valider',
    today: "Aujourd'hui",
    yesterday: 'Hier'
  },
  timepicker: {
    hour: 'Heure',
    minute: 'Minute',
    placeholder: 'hh:mm'
  },
  transferred_agent_modal: {
    now_in_waiting_section: 'La discussion a bien été transférée à l\'utilisateur sélectionné.',
    title: 'Confirmation du transfert utilisateur'
  },
  delete: 'Supprimer',
  details: {
    account: 'Compte',
    botgroup: 'Point d\'entrée',
    botgroup_page: 'Page point d\'entrée',
    bottree: 'Arborescence',
    browser: 'Navigateur',
    company: 'Entité',
    contact_comment: 'Commentaire',
    current_session: '* Session actuelle',
    device: 'Appareil',
    file: 'Fiche client',
    general: 'Informations',
    guest_id: 'ID du client',
    history: 'Historique',
    info: 'Infos compl.',
    language: 'Langue',
    location: 'Emplacement',
    note: 'Notes',
    nps: {
      detractor: 'Détracteur',
      developer: 'Promoteur',
      passive: 'Passif',
      title: 'NPS'
    },
    phone: 'N° de téléphone',
    satisfaction: 'Satisfaction client',
    since: 'Actif depuis',
    username: 'Nom complet',
    validate: 'validate'
  },
  edit: 'Modifier',
  email_resent: 'Email envoyé !',
  email_validation: {
    email_already_validated: 'Votre email est déjà validé',
    bad_token: 'Le lien d\'activation de votre à compte à expiré'
  },
  error: {
    maximum_file_size_excedeed: 'Désolée, nous n\'avons pas pu envoyer votre fichier, car il est trop volumineux. La taille maximum autorisée est de 5MB.',
    Oups: "Oups, une erreur s'est produite !",
    permission_denied: {
      details: "Vous n'avez pas les droits requis pour accéder à cette page.",
      title: 'Accès non autorisé'
    },
    too_big: 'Désolé, nous n\'avons pas pu envoyer votre fichier, car il est trop volumineux. La taille maximum autorisée est de 5Mo',
    unauthorized: 'Action non autorisée'
  },
  'third-parties': {
    azure_open_ai: {
      api_version: 'Version d\'API',
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Microsoft Azure Open AI ?',
      deleted: 'Compte Microsoft Azure Open AI supprimé',
      deployment_name: 'Nom de votre modèle de déploiement',
      description: 'Connectez-vous à votre environnement Microsoft Azure Open AI',
      endpoint: 'Point de terminaison',
      label: 'Libellé',
      saved: 'Compte Microsoft Azure Open AI sauvegardé',
      secret_key: 'Clé secrète',
      skills_placeholder: 'Compétences',
      skills: 'Compétences'
    },
    callback: {
      description: 'Configurez un rappel Greenbureau',
      error: {
        invalid_callback_identifier: 'Identifiant de rappel incorrect'
      },
      identifier: 'Identifiant',
      label: 'Libellé',
      skills: 'Compétences',
      skills_placeholder: 'Compétences',
      timeslot_required: 'Sélection d\'un créneau requis?'
    },
    genesys: {
      client_id: 'ID client',
      client_secret: 'Jeton client (Clé secrète)',
      delete_ask: 'Voulez-vous vraiment supprimer cette console externe ?',
      deleted: 'Console externe supprimée',
      description: 'Redirigez les échanges de vos différents canaux de messageries vers votre console Genesys.',
      error: {
        login_failed: 'Échec d\'authentification'
      },
      kind: 'Type de console',
      label: 'Nom',
      location_placeholder: 'Sélectioner une localisation',
      location: 'Localisation',
      locations: {
        canada: 'Amériques (Canada)',
        dublin: 'EMEA (Dublin)',
        frankfurt: 'EMEA (Francfort)',
        london: 'EMEA (Londres)',
        mumbai: 'Asie-Pacifique (Mumbai)',
        seoul: 'Asie-Pacifique (Séoul)',
        sydney: 'Asie-Pacifique (Sydney)',
        tokyo: 'Asie-Pacifique (Tokyo)',
        useast: 'Amériques (Est, États-Unis)',
        useast2: 'Amériques (US Est 2)',
        uswest: 'Amériques (Ouest, États-Unis)'
      },
      name: 'Nom de la console',
      saved: 'Console externe sauvegardée',
      skills_placeholder: 'Sélectionner une compétence',
      skills: 'Compétence'
    },
    goodays: {
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Goodays ?',
      deleted: 'Compte Goodays supprimé',
      description: 'Connectez-vous à votre environnement Goodays',
      error: {
        fail_to_fetch: 'Échec de la récupération'
      },
      label: 'Libellé',
      nps: 'Net Promoter Score (NPS)',
      sat: 'Satisfaction',
      saved: 'Compte Goodays sauvegardé',
      skills_placeholder: 'Compétences',
      skills: 'Compétences',
      survey: 'Enquête de satisfaction',
      token: 'Jeton'
    },
    kiamo: {
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Kiamo ?',
      deleted: 'Compte Kiamo supprimé',
      description: 'Configurez un rappel Kiamo',
      endpoint: 'Point de terminaison',
      error: {
        invalid_credentials: 'Échec d\'authentification'
      },
      label: 'Libellé',
      saved: 'Compte Kiamo sauvegardé',
      service_id: 'ID de service',
      skills_placeholder: 'Compétences',
      skills: 'Compétences',
      token: 'Jeton'
    },
    list: {
      kind: 'Type',
      name: 'Nom',
      skills: 'Compétences'
    },
    odigo: {
      api_subdomain: 'Sous domaine de l\'API',
      app_uid: 'Identifiant de l\'application',
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Odigo ?',
      deleted: 'Compte Odigo supprimé',
      description: 'Configurez un rappel Odigo',
      error: {
        invalid_credentials: 'Échec d\'authentification',
        invalid_skill_keyword: 'Compétence Odigo invalide'
      },
      label: 'Libellé',
      saved: 'Compte Odigo sauvegardé',
      skill_keyword: 'Compétence Odigo',
      skills_placeholder: 'Compétences',
      skills: 'Compétences',
      user_uid: 'Identifiant de l\'utilisateur'
    },
    open_ai: {
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Open AI ?',
      deleted: 'Compte Open AI supprimé',
      description: 'Connectez-vous à votre environnement Open AI',
      label: 'Libellé',
      saved: 'Compte Open AI sauvegardé',
      secret_key: 'Clé secrète',
      skills_placeholder: 'Compétences',
      skills: 'Compétences'
    },
    pipedrive: {
      api_token: 'API Token',
      description: 'Configurez une connexion Pipedrive',
      error: {
        invalid_callback_identifier: 'Identifiant de rappel incorrect'
      },
      label: 'Libellé',
      skills: 'Compétences',
      skills_placeholder: 'Compétences'
    },
    qemotion: {
      config_id: 'Configuration',
      description: 'Configurez une connexion Q Emotion',
      error: {
        invalid_callback_identifier: 'Identifiant de rappel incorrect'
      },
      label: 'Libellé',
      project_id: 'Projet',
      skills: 'Compétences',
      skills_placeholder: 'Compétences'
    },
    salesforce: {
      consumer_key: 'Clé consommateur',
      consumer_secret: 'Secret consommateur',
      delete_ask: 'Voulez-vous vraiment supprimer cette connexion Salesforce ?',
      deleted: 'Connexion Salesforce supprimée',
      description: 'Configurez une connexion Salesforce',
      domain: 'Domaine',
      error: {
        invalid_credentials: 'Échec d\'authentification'
      },
      label: 'Libellé',
      saved: 'Connexion Salesforce sauvegardée',
      skills_placeholder: 'Compétences',
      skills: 'Compétences'
    },
    sftp: {
      ask_confirm: 'Confirmer la clé publique "{0}" et ajouter la connexion SFTP ?',
      ask_delete: 'Voulez-vous vraiment supprimer cette connexion SFTP ?',
      confirm: 'Vérification de la clé publique',
      authentication: {
        copied_to_clipboard: 'Copié dans le presse-papier !',
        gb_public_key: 'Clé publique Greenbureau :',
        password: 'Mot de passe',
        public_key: 'Clé publique (SSH)',
        title: 'Méthode d\'authentification'
      },
      deleted: 'Connexion SFTP supprimé',
      description: 'Configurez une connexion SFTP',
      error: {
        invalid_callback_identifier: 'Identifiant de rappel incorrect'
      },
      host: 'Hôte',
      label: 'Libellé',
      port: 'Port',
      saved: 'Vos modifications ont bien été enregistrées',
      skills: 'Compétences',
      skills_placeholder: 'Compétences',
      username: 'Utilisateur'
    },
    worldline: {
      delete_ask: 'Voulez-vous vraiment supprimer ce compte Worldline ?',
      deleted: 'Compte Worldline supprimé',
      description: 'Configurez un rappel Worldline',
      identifier: 'Identifiant de la campagne',
      label: 'Libellé',
      saved: 'Compte Worldline sauvegardé',
      skills_placeholder: 'Compétences',
      skills: 'Compétences'
    },
    zencall: {
      description: 'Configurez un rappel Greenbureau',
      error: {
        invalid_callback_identifier: 'Identifiant de rappel incorrect'
      },
      identifier: 'Identifiant',
      label: 'Libellé',
      skills: 'Compétences',
      skills_placeholder: 'Compétences',
      timeslot_required: 'Sélection d\'un créneau requis?'
    }
  },
  faq: {
    link: 'https://support.chayall.com/',
    file: {
      ai: 'key-account/Book de formation ChayAll - ADMIN - Intelligence Artificielle.pdf',
      auto_allocation: 'key-account/Book de formation ChayAll - ADMIN - Affectation automatique.pdf',
      'bot-builder': 'key-account/Book de formation ChayAll - ADMIN - Bot builder.pdf',
      botgroups: 'key-account/Book de formation ChayAll - ADMIN - Points d entree.pdf',
      campaigns: 'key-account/Book de formation ChayAll - OWNER - Campagnes.pdf',
      'third-parties': 'key-account/Book de formation ChayAll - ADMIN - Consoles externes.pdf',
      dashboard: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Dashboard.pdf',
      'gb-agent_agent': 'key-account/Book de formation ChayAll - AGENTS Console agent.pdf',
      'gb-agent': 'key-account/Book de formation ChayAll - ADMIN_MANAGER Console agent.pdf',
      messaging: 'key-account/Book de formation ChayAll - ADMIN - Messagerie.pdf',
      settings_agent: 'key-account/Book de formation ChayAll - AGENTS Paramètres.pdf',
      settings: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Paramètres.pdf',
      stats: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Statistiques.pdf',
      third_parties: 'key-account/Book de formation ChayAll - ADMIN - Services externes.pdf',
      webivr: 'key-account/Book de formation ChayAll - ADMIN MANAGERS - SVI Visuel.pdf',
      webivr_builder: 'key-account/Book de formation ChayAll - ADMIN MANAGERS - SVI builder.pdf'
    },
    title: $settings.saas ? 'Accéder à la F.A.Q.' : 'Aide'
  },
  field: {
    place_id: {
      picked: 'Entreprise enregistrée',
      to_pick: 'Ajouter une entreprise'
    }
  },
  file_format_not_allowed: 'Format de fichier invalide',
  filter: 'Filtrer',
  finished: 'Terminé',
  form: {
    empty_upload: 'Supprimer le fichier',
    upload_preview: 'Aperçu du téléchargement'
  },
  gba: {
    agent_transferred: 'Transférée à {0}',
    agent_transferred_long: 'La discussion a été transférée à {0}',
    header: {
      autofill: 'Activer l\'affectation auto.',
      break: 'Se mettre en pause',
      unbreak: 'Revenir de pause'
    },
    infos: 'Informations',
    message: {
      error: {
        quota: 'Dépassement du quota de messages'
      },
      not_delivered: 'Message non délivré'
    },
    origin: {
      instagram_dm: 'Instagram Direct'
    },
    search: {
      agent_placeholder: 'Pris en charge par (Agent)',
      channels_placeholder: 'Messageries',
      clear: 'Réinitialiser les filtres',
      contact_reasons_placeholder: 'Motifs de contact',
      content_placeholder: 'Contient (Texte, lien, fichier, adresse)',
      dates_placeholder: 'Date (JJ/MM/AAAA)',
      email_placeholder: 'Mail',
      extra_placeholder: 'Infos. complémentaires',
      filter_by: 'Filtrer par',
      filters: '1 Filtre actif | {count} Filtres actifs',
      groups_placeholder: 'Groupes de compétences',
      guest_int_id_placeholder: 'ID du client',
      guest_info_placeholder: 'Fiche client (Nom, n° de téléphone, mail, etc.)',
      media_id_placeholder: 'ID du post',
      name_placeholder: 'Nom',
      phone_number_placeholder: 'Numéro de téléphone',
      placeholder: 'Rechercher...',
      rating: {
        1: '1 étoile',
        2: '2 étoiles',
        3: '3 étoiles',
        4: '4 étoiles',
        5: '5 étoiles'
      },
      rating_placeholder: 'Note de l\'avis',
      result: 'Aucun résultat | 1 résultat | {count} résultats',
      session_group_int_id_placeholder: 'ID de la discussion',
      skills_placeholder: 'Compétences',
      status_placeholder: 'Statuts',
      submit: 'Rechercher'
    },
    skill_transferred: 'Transférée sur la compétence "{0}"',
    skill_transferred_long: 'La discussion a été transférée sur la compétence "{0}"',
    sort: 'Trier',
    smartcoaching: {
      suggestions: 'Suggestions de réponses'
    },
    translate: {
      in_progress: 'Traduction en cours...',
      show_original: 'Afficher le message original',
      show_translated: 'Afficher la traduction'
    }
  },
  google_place: {
    search: 'Rechercher',
    subtitle: 'Tapez le nom de votre entreprise dans le champ, puis sélectionnez votre adresse dans la liste déroulante',
    title: 'Votre entreprise sur Google'
  },
  invalid: "Le champ n'est pas valide",
  langs: {
    en: 'Anglais',
    fr: 'Français'
  },
  login: {
    button: 'Se connecter',
    connect: 'Se connecter',
    create_account: 'Créer un compte',
    error: {
      banned: 'Compte suspendu jusqu\'au {0}.',
      your_email: 'Adresse email incorrecte'
    },
    ka_link: 'Vous êtes une Grande Entreprise ?',
    no_account: "Vous n'avez pas compte ?",
    title: 'Connectez-vous à {APP_NAME}',
    your_email: 'Entreprise@email.com',
    your_password: 'Mot de passe',
    your_phone_number: 'Numéro de téléphone'
  },
  manage: 'Gérer',
  max_agents_reached: 'Nombre maximal d\'agents atteint',
  max_length: 'Nombre maximum de caractères dépassé',
  'max_length:128': 'Nombre maximum de caractères dépassé',
  menu: {
    abc: 'Apple Messages for Business',
    ai: 'Intelligence artificielle',
    'ai-agents': 'Agents IA',
    'ai-dialogflow-add': 'Ajouter un agent Dialogflow',
    'ai-dialogflow-edit': 'Configurer l\'agent Dialogflow',
    'ai-dialogflow-import': 'Alimenter l\'agent Dialogflow',
    'ai-knowledge-bases': 'Bases de connaissances',
    'ai-knowledge-bases-add': 'Ajouter une base de connaissances',
    'ai-knowledge-bases-edit': 'Configurer la base de connaissances',
    'ai-knowledge-bases-import': 'Alimenter la base de connaissances',
    'ai-ravana-add': 'Ajouter un agent conversationnel',
    'ai-ravana-edit': 'Configurer l\'agent conversationnel',
    'ai-ravana-tasks': 'Configurer les tâches de l\'agent conversationnel',
    'ai-smartcoaching-add': 'Ajouter un agent smartcoaching',
    'ai-smartcoaching-edit': 'Configurer l\'agent smartcoaching',
    get apple () {
      return this.abc
    },
    get 'apple-business-chat' () {
      return this.abc
    },
    api: 'API',
    azure_open_ai: 'Microsoft Azure Open AI',
    'bot-builder': 'Bot builder',
    get 'bot-builder-beta' () {
      return this['bot-builder']
    },
    botgroups: 'Points d\'entrée',
    'botgroup-add': 'Ajouter un point d\'entrée',
    get 'botgroup-add-messenger-menu' () { return this['botgroup-add'] },
    'botgroup-edit': 'Configurer le point d\'entrée',
    get 'botgroup-edit-messenger-menu' () { return this['botgroup-edit'] },
    get 'botgroup-webivr-add' () { return this['botgroup-add'] },
    get 'botgroup-webivr-edit' () { return this['botgroup-edit'] },
    callback: 'Rappel',
    'calendars-add': 'Ajouter un calendrier',
    'calendars-edit': 'Gérer un calendrier',
    'campaigns-add': 'Ajouter une campagne',
    'campaigns-contacts-add': 'Créer une nouvelle audiences',
    'campaigns-contacts-edit': 'Gérer une audience',
    'campaigns-contacts': 'Audiences',
    'campaigns-edit': 'Gérer une campagne',
    'campaigns-send': 'Envoi manuel',
    'campaigns-templates-add': 'Créer un nouveau modèle',
    'campaigns-templates-edit': 'Gérer un modèle',
    'campaigns-templates': 'Modèles',
    'campaigns-wrapper': 'Liste de campagnes',
    campaigns: 'Campagnes',
    change_password_title: 'Modifier votre mot de passe',
    'channel-embedding': 'Intégration du canal',
    'channel-settings': 'Configuration du canal',
    'channel-subscription': 'WhatsApp version complète',
    channels: 'Messageries',
    'confirm-email': 'Validation du compte',
    'third-parties': 'Services externes',
    'third-parties-add': 'Ajouter un service externe',
    'third-parties-add-title': {
      ai: 'Intelligence artificielle',
      callback: 'Rappels',
      console: 'Consoles externes',
      crm: 'CRMs',
      satisfaction: 'Satisfaction',
      sftp: 'SFTPs'
    },
    get 'third-parties-add-form' () {
      return this['third-parties-add']
    },
    'third-parties-edit': 'Modifier un service externe',
    'third-parties-list': 'Liste des services externes',
    dashboard: 'Tableau de bord',
    'external-services': 'Services supplémentaires',
    get facebook () {
      return this.messenger
    },
    facebook_comments: 'Facebook Commentaires',
    'gb-agent': 'Console Chat',
    get gbm () {
      return this.googlebm
    },
    genesys: 'Genesys',
    goodays: 'Goodays',
    googlebm: "Google's Business Messages",
    google_reviews: 'Google Avis',
    instagram_comments: 'Instagram Commentaires',
    instagram_dm: 'Instagram',
    kiamo: 'Kiamo',
    login: 'Se connecter',
    logout: 'Se déconnecter',
    messaging: 'Canaux digitaux',
    'messaging-add': 'Ajouter un canal',
    'messaging-add-form': 'Formulaire',
    'messaging-edit': 'Modifier un canal',
    'messaging-logged': 'Canaux digitaux',
    messenger: 'Messenger',
    odigo: 'Odigo',
    open_ai: 'Open AI',
    pipedrive: 'Pipedrive',
    prototype: 'Prototype',
    qemotion: 'Q Emotion',
    rcs: 'RCS',
    register: 'Créer un compte',
    reviews: 'Avis',
    salesforce: 'Salesforce',
    settings: 'Paramètres',
    'settings-account': 'Compte',
    'settings-account-delete': 'Suppression de compte',
    'settings-agent-add': 'Ajouter un utilisateur',
    'settings-agent-edit': 'Modifier un utilisateur',
    'settings-agents': 'Gestion des utilisateurs',
    'settings-calendars': 'Gestion des calendriers',
    'settings-calendars-add': 'Ajouter un calendrier',
    'settings-calendars-edit': 'Modifier un calendrier',
    'settings-calendars-list': 'Liste des calendriers',
    'settings-external-views': 'Vues externes',
    'settings-external-views-add': 'Ajouter une vue externe',
    'settings-external-views-edit': 'Modifier une vue externe',
    'settings-external-views-list': 'Liste des vues externes',
    'settings-billing': 'Offres et paiement',
    'settings-billing-address': 'Adresse de facturation',
    'settings-billing-payments': 'Moyens de paiement',
    'settings-billing-payment-add': 'Ajouter un moyen de paiement',
    'settings-billing-invoices': 'Factures',
    'settings-billing-summary': 'Récapitulatif de votre commande',
    'settings-chayall-api': 'Intégration API ChayAll',
    'settings-contact-reason-add': 'Ajouter un motif de contact',
    'settings-contact-reason-edit': 'Modifier un motif de contact',
    'settings-contact-reasons': 'Motifs de contact',
    'settings-group-add': 'Ajouter un groupe de compétences',
    'settings-group-edit': 'Modifier un groupe de compétences',
    'settings-groups': 'Groupes de compétences',
    'settings-msglib': 'Messages pré-enregistrés',
    'settings-msglib-add': 'Ajouter un élément',
    'settings-msglib-edit': 'Modifier un élément',
    'settings-rooms': 'Configuration des discussions',
    'settings-scheduled-exports': 'Exports planifiés',
    'settings-scheduled-exports-add': 'Ajouter un export planifié',
    'settings-scheduled-exports-edit': 'Modifier un export planifié',
    'settings-scheduled-exports-list': 'Liste des exports planifiés',
    'settings-skill-add': 'Ajouter une compétence',
    'settings-skill-edit': 'Modifier une compétence',
    'settings-skills': 'Compétences',
    'settings-subaccount-add': 'Ajouter un sous-compte',
    'settings-subaccount-edit': 'Modifier un sous-compte',
    'settings-subaccounts': 'Sous-comptes',
    'settings-subscription': 'Offres',
    'settings-surveys': 'Enquêtes de satisfaction',
    'settings-surveys-add': 'Ajouter une enquête de satisfaction',
    'settings-surveys-edit': 'Modifier une enquête de satisfaction',
    'settings-surveys-list': 'Liste des enquêtes de satisfaction',
    'settings-public-api': 'Connexions',
    'settings-zapier': 'Intégration Zapier',
    sftp: 'SFTP',
    sms: 'SMS',
    stats: 'Statistiques',
    'stats-bot': 'Statistics',
    'stats-bot-main': 'Statistiques',
    'stats-bot-flow': 'Customer analytics',
    'stats-webivr-main': 'Statistiques',
    'stats-webivr-flow': 'Flux client',
    'stats-webivr-flow-tree': 'Flux arborescence',
    telegram: 'Telegram',
    title: 'Accueil',
    trustpilot: 'Trustpilot',
    twitter_dm: 'X',
    twitter_comments: 'X Commentaires',
    get 'tyntec-wa' () {
      return this.whatsapp
    },
    webivr: 'SVI Visuels',
    get web () {
      return this.web_chat
    },
    get webchat () {
      return this.web_chat
    },
    web_chat: 'Live Chat',
    'webivr-add': 'Ajouter un SVI Visuel',
    'webivr-edit': 'Modifier le SVI Visuel',
    'webivr-faqs-add': 'Ajouter une FAQ',
    'webivr-faqs-edit': 'Modifier une FAQ',
    'webivr-forms-add': 'Ajouter un formulaire',
    'webivr-forms-edit': 'Modifier un formulaire',
    'webivr-templates-add': 'Ajouter un thème',
    'webivr-templates-edit': 'Modifier un thème',
    whatsapp: 'WhatsApp',
    worldline: 'Worldline',
    zencall: 'Rappel'
  },
  messaging: {
    add: 'Ajouter un canal',
    back: 'Retour à la liste des canaux digitaux',
    bot: {
      messaging: 'Messageries instantanées',
      builder: 'Bot',
      edit: 'Editer'
    },
    comments: 'Réseaux sociaux',
    edit: {
      title: 'Configurer le canal'
    },
    embed: {
      faq: "Besoin d'aide ?",
      gtm: {
        faq: 'https://support.chayall.com/article/125-comment-integrer-grace-a-google-tag-manager'
      },
      prestashop: {
        faq: 'https://support.chayall.com/article/93-comment-integrer-le-plugin-prestashop'
      },
      subtitle: 'Cette étape vous permet d\'intégrer tous les canaux précédemment configurés sur vos supports digitaux, selon la méthode d\'intégration de votre choix. Dernière étape et vous pourrez commencer à échanger avec vos clients depuis les canaux digitaux.',
      title: 'Intégrer sur vos supports digitaux',
      web: {
        faq: 'https://support.chayall.com/article/81-integration-dun-code-javascript'
      },
      wordpress: {
        faq: 'https://support.chayall.com/article/79-integration-wordpress'
      }
    },
    key_account: {
      'bot-builder': 'Bot',
      name: 'Nom du canal',
      kind: 'Type de canal',
      settings: 'Paramètres',
      skill: 'Compétence',
      state: 'Statut',
      title: 'Canaux digitaux'
    },
    logged: {
      title: 'Plus qu\'une étape !',
      subtitle: 'Vous pouvez fermer cette page et retourner sur l\'onglet ChayAll précédent afin de finaliser la configuration.'
    },
    no_messaging_enabled: 'Pour commencer, merci de configurer le canal que vous souhaitez intégrer sur votre site web.',
    params: {
      customization: {
        agent: {
          name: 'Nom de l\'agent'
        },
        agents: 'Agents',
        agents_details: 'Merci de télécharger une image par agent et de renseigner leur nom',
        agents_error: {
          'name:cannot_be_blank': 'Merci de renseigner le nom de votre agent',
          'url:cannot_be_blank': 'Merci de télécharger une image'
        },
        auto_open: 'Délais d\'ouverture automatique (en secondes)',
        auto_open_details: 'Une valeur de zéro correspond à la désactivation de l\'ouverture automatique du widget',
        changes_saved: 'Vos modifications ont bien été prises en compte.',
        cta: "Phrase d'incitation à l'action",
        cta_default: 'Bonjour, comment pouvons-nous vous aider ?',
        cta_details: 'Laisser ce champ vide désactivera l\'affichage du CTA',
        faq: 'https://support.chayall.com/article/109-comment-personnaliser-mon-bouton-chayall',
        force_cta_display: 'Afficher la phrase d\'incitation à l\'action sur chaque page',
        force_cta_display_details: 'Par défaut la phrase d\'incitation à l\'action n\'apparait qu\'une seule fois par session.',
        preview: 'Aperçu du bouton',
        preview_widget: 'Prévisualiser le widget',
        title: 'Personnaliser votre widget',
        widget_color: 'Couleur du bouton',
        widget_logo: 'Logo',
        widget_logo_details: 'Format carré, JPEG ou PNG',
        widget_offset_x: 'Décalage horizontal (px)',
        widget_offset_y: 'Décalage vertical (px)',
        widget_position: {
          left: 'En bas à gauche',
          right: 'En bas à droite',
          title: 'Position du bouton'
        },
        widget_subtitle: 'Sous titre du widget',
        widget_title: 'Titre du widget',
        widget_title_placeholder: 'Échangeons sur le canal de votre choix 👋🏻',
        widget_version: {
          1: 'Multi-pastilles',
          2: 'Chatbox'
        }
      },
      title: 'Personnaliser votre expérience client'
    },
    reviews: 'Avis',
    skills: 'Compétence :',
    subaccount: 'Sous-compte :'
  },
  modal: {
    confirm_account_delete_button: 'Supprimer définitivement mon compte ChayAll',
    confirm_account_delete_msg: 'Etes-vous sûr(e) de vouloir supprimer définitivement votre compte ChayAll ?',
    confirm_account_delete_title: 'Suppression de votre compte ChayAll',
    confirm_agent_delete_button: "Supprimer l'utilisateur",
    confirm_agent_delete_msg: 'Etes-vous sûr(e) de vouloir supprimer définitivement cet utilisateur ?',
    confirm_agent_delete_title: 'Suppression de l\'utilisateur',
    confirm_dfnode_delete_button: 'Confirmer',
    confirm_dfnode_delete_msg: 'Etes-vous sûr de supprimer cette action ? Cette action ainsi que ses enfants seront également supprimés',
    confirm_dfnode_delete_title: 'Suppression d\'une action',
    confirm_paymentmethod_button: 'Confirmer',
    confirm_tree_delete_title: 'Supprimer l\'arborescence',
    confirm_tree_delete_msg: 'Etes-vous sûr(e) de vouloir supprimer cette arborescence ?',
    confirm_tree_delete_button: 'Supprimer',
    // card is passed as arg to confirm_paymentmethod_msg.
    // you can use `card.alias` or `card.brand` for example
    // `card.alias` may be null
    confirm_paymentmethod_msg: 'Valider',
    confirm_paymentmethod_choose_other_msg: 'Ajouter moyen de paiement',
    confirm_paymentmethod_title: 'Confirmez votre moyen de paiement',
    facebook_login_refresh_msg: 'Merci de cliquer sur OK pour rafraîchir votre page',
    password_change_title: 'Modifier votre mot de passe',
    tree_delete_linkedtree_nodes_msg: 'Attention! Cette arborescence est liée à plusieurs nœuds qui seront également supprimés en cas de confirmation.',
    warn_open_room_details: 'Êtes-vous sûr de vouloir vous déconnecter ?',
    warn_open_room_title: 'Vous avez toujours des conversations en cours'
  },
  multiselect: {
    selected_options: '{0} options sélectionnées'
  },
  'n/a': 'n.c.',
  none: 'Aucun',
  note: {
    at: 'Note du {date}'
  },
  notes: {
    form_content_label: 'Contenu',
    form_subject_label: 'Objet'
  },
  offer: {
    contact_us: 'Contactez-nous',
    current: 'En cours',
    details: {
      default: {
        abc_gbm_registration: 'Inscription au chat Apple et Google',
        all_integrations: 'Intégration de tous les canaux',
        all_integrations_but_wa: 'Tous les canaux disponibles excepté WhatsApp',
        api: 'Connexion aux CRMs et API',
        ass_dedicated: 'Accompagné par un chef de projet',
        chat_console: '',
        chatbot_integration: 'Intégration chatbot',
        custom_monitoring: 'Suivi des performances personnalisé',
        custom_widget: 'Personnalisation du widget',
        dashboard: 'Console de discussion et tableau de bord',
        max_messages: 'Package de {0} messages',
        max_subaccounts: '{0} sous-comptes autorisés',
        max_users: '{0} utilisateurs autorisés',
        max_wa_messages: '{0} messages WhatsApp/mois',
        monitoring: 'Suivi des performances',
        msg_price: 'Prix par message supplémentaire : {0}',
        msg_unlimited: 'Messages illimités',
        noprice: '-',
        standard_support: 'Support standard',
        // eslint-disable-next-line no-template-curly-in-string
        wa_msg_price: 'Prix par message WhatsApp supplémentaire: {0}€/message',
        welcome_guide: 'Guide de Bienvenue'
      },
      get free () {
        const details = Object.assign({}, this.default, {
          noprice: 'Gratuit'
        })

        return {
          month: details,
          year: details
        }
      },
      get essential () {
        const details = Object.assign({}, this.default, {
          ass_custom: 'Support à l\'activation'
        })

        return {
          month: details,
          year: Object.assign({}, details, {
            max_messages: '{0} messages/mois'
          })
        }
      },
      get pro () {
        const details = Object.assign({}, this.default, {
          ass_custom: 'Support à l\'activation et formation'
        })

        return {
          month: details,
          year: Object.assign({}, details, {
            max_messages: '{0} messages/mois'
          })
        }
      },
      get custom () {
        const details = Object.assign({}, this.default, {
          noprice: 'Sur devis'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier1 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Suivi et analyse des performances',
          msg_unlimited: 'Un nombre illimité de messages/mois',
          noprice: '49€'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier2 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Suivi et analyse des performances',
          msg_unlimited: 'Un nombre illimité de messages/mois',
          noprice: '99€'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier3 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Suivi et analyse des performances',
          msg_unlimited: 'Un nombre illimité de messages/mois',
          noprice: '189€'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier4 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          max_users: 'Un nombre illimité d\'utilisateurs autorisés',
          monitoring: 'Suivi et analyse des performances',
          msg_unlimited: 'Un nombre illimité de messages/mois',
          noprice: '299€'
        })

        return {
          month: details,
          year: details
        }
      }
    },
    per_month: 'par mois',
    per_year: 'par an',
    quotation: 'Sur devis',
    real_subscribe: {
      default: 'Souscrire',
      free: 'Démarrer',
      get custom () {
        return 'Contactez-nous'
      },
      get essential () {
        return this.default
      },
      get pro () {
        return this.default
      },
      get appsumo_chayall_tier1 () {
        return this.default
      },
      get appsumo_chayall_tier2 () {
        return this.default
      },
      get appsumo_chayall_tier3 () {
        return this.default
      },
      get appsumo_chayall_tier4 () {
        return this.default
      }
    },
    subscribe: 'Nous contacter'
  },
  ok: 'Fermer',
  or_drag_it_here: 'ou glisser le fichier ici',
  or_drag_newer_here: 'ou glisser le nouveau fichier ici',
  paging: 'Page {0} sur {1}',
  password: {
    ...passwordErrors,
    back_to_login: 'Retour à la page de connexion',
    change: {
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      new_password: 'Nouveau mot de passe',
      old_password: 'Ancien mot de passe',
      send_button: 'Valider'
    },
    change_title: 'Modifier votre mot de passe',
    email_has_been_sent: 'Email envoyé',
    email_has_been_sent_check_mailbox_incl_spam: "Un mail de confirmation vient de vous être envoyé afin de valider votre adresse mail. Merci de vérifier qu'il ne soit pas arrivé dans vos spams.",
    reset: {
      error: {
        your_email: 'Adresse email incorrecte'
      },
      your_email: 'Adresse email'
    },
    reset_button: 'Réinitialiser mon mot de passe',
    'reset_button ': 'Réinitialiser mon mot de passe',
    reset_title: 'Réinitialisation du mot de passe',
    subtitle: 'Vous allez recevoir un email afin de réinitialiser votre mot de passe.',
    your_password_is_changed_you_can_login: 'La modification de votre mot de passe a bien été prise en compte. Vous pouvez vous connecter dès à présent avec votre nouveau mot de passe.'
  },
  password_change: {
    ...passwordErrors
  },
  prefilled_msg_uniqueness: 'Votre message prérempli WhatsApp doit être unique',
  read_more: 'En savoir plus',
  refresh: 'Actualiser',
  register: {
    already_registered: 'Vous avez déjà un compte ?',
    appsumo: {
      customer: 'Nom de votre entreprise',
      password: 'Votre mot de passe doit comporter au moins 8 caractères et ne doit pas être composé uniquement de chiffres.',
      register_button: 'Commencer',
      subtitle: 'Créez maintenant votre compte ChayAll :',
      terms_link: 'J\'accepte les Conditions Générales de Vente',
      title: 'Merci de vous être abonné sur AppSumo!'
    },
    baseline: 'Aucune carte bancaire requise | Gratuit, sans engagement',
    customer: 'Entreprise',
    first_name: 'Prénom',
    last_name: 'Nom',
    login: 'Connectez-vous',
    phone_number: {
      invalid: 'Numéro de téléphone invalide'
    },
    register_button: 'Démarrer',
    register_button_free: 'Démarrer gratuitement',
    registered: 'Vous allez recevoir un mail de confirmation contenant un lien. Merci de cliquer dessus pour activer votre compte.',
    shopped_on: 'Acheté sur',
    subtitle: 'avec la plateforme de messagerie omnicanale ChayAll',
    success: 'Félicitations ! Votre compte a bien été créé.',
    terms: "J'accepte les conditions générales de vente",
    terms_link: 'Conditions Générales de Vente',
    terms_link_url: 'https://chayall.fr/conditions-generales-de-vente/',
    title: 'Gérez et centralisez tous vos canaux digitaux',
    user_email_already_exist: 'Un compte est déjà lié à cette adresse email',
    your_company: 'Votre entreprise'
  },
  required: 'Champ obligatoire manquant',
  resend_email: 'Renvoyer l\'email',
  view_offers: 'Voir les offres',
  reset_password: {
    title: 'Mot de passe oublié ?'
  },
  review: {
    ask_close: 'Voulez-vous vraiment fermer cet avis ?',
    ask_transfer: 'Voulez-vous vraiment remettre cet avis en file d\'attente ?',
    cancel: 'Annuler',
    close: 'Fermer l\'avis',
    close_inactive: 'Marquer cet avis comme fermé',
    deleted: 'Supprimé client',
    error: {
      reply_already_exists: 'Une réponse à cet avis existe déjà.',
      sync_in_progress: 'Synchronisation en cours... Veuillez réessayer ultérieurement.'
    },
    experienced_at: 'Date de l\'expérience :',
    join_btn: 'Cliquez ici pour prendre en charge l\'avis',
    last_contact: 'Dernier contact :',
    location: 'Localisation :',
    message: {
      ask_delete: 'Voulez-vous vraiment supprimer ce message ?',
      delete: 'Supprimer',
      edit: 'Modifier',
      edit_long: 'Modifier le message sélectionné',
      edited: 'Modifié le {0}'
    },
    source: {
      AFS: 'Invitation automatique',
      AFSv1: 'Invitation automatique',
      AFSv2: 'Invitation automatique',
      BasicLink: 'Invitation basique',
      BigCommerce: 'Invitation automatique',
      BusinessGeneratedLink: 'Lien généré',
      CopyPasteInvitation: 'Invitation manuelle',
      DomainLink: 'Naturelle',
      EmbeddedBusinessGeneratedLinkForm: 'Formulaire d\'évaluation intégré',
      EmbeddedUniqueLinkForm: 'Formulaire d\'évaluation intégré',
      FileUploadInvitation: 'Invitation manuelle',
      GTM: 'Invitation automatique',
      InvitationApi: 'Invitation automatique',
      InvitationLinkApi: 'Lien généré',
      InvitationScript: 'Invitation automatique',
      Kickstart: 'Invitation manuelle',
      LegacyUniqueLink: 'Lien généré',
      Magento: 'Invitation automatique',
      MagentoV1: 'Invitation automatique',
      MagentoV2: 'Invitation automatique',
      ManualInputInvitation: 'Invitation manuelle',
      OpenCart: 'Invitation automatique',
      Organic: 'Naturelle',
      PayPal: 'Invitation automatique',
      PrestaShop: 'Invitation automatique',
      Segment: 'Invitation automatique',
      Shopify: 'Invitation automatique',
      Shopware: 'Invitation automatique',
      Square: 'Invitation automatique',
      title: 'Source :',
      Trustpilot: 'Naturelle',
      UniqueLink: 'Lien généré',
      Wix: 'Invitation automatique',
      WooCommerce: 'Invitation automatique'
    },
    place_id: 'Place ID :',
    to_transfer: 'Remise en file d\'attente',
    transfer: 'Remettre en file d\'attente',
    transferred: 'Remis en file',
    type_your_message: 'Tapez votre message…',
    waiting_message: 'Avis fermé'
  },
  reviews: {
    anonymous: 'Anonyme',
    button: 'Avis',
    kind: {
      google_reviews: 'Google Avis',
      trustpilot: 'Trustpilot'
    },
    pick_review: 'Merci de sélectionner un avis'
  },
  room: {
    agent_transfer: 'Transférer à un utilisateur',
    agent_transferred: 'Transfert utilisateur',
    ask: {
      audiocall: 'appel audio',
      screensharing: 'partage d\'écran',
      videocall: 'appel vidéo'
    },
    ask_webrtc: 'Souhaitez-vous vraiment proposer un {currentKind} à votre destinataire ?',
    auto_closed: 'Fermée auto.',
    auto_requeued_title: 'Remis en file',
    close_inactive: 'Marquer la discussion comme résolue',
    closed_details: "Liste de toutes vos discussions fermées manuellement. | Liste de vos discussions fermées manuellement et automatiquement. Une discussion est fermée automatiquement au bout d'une heure sans réponse de l'agent | Liste de vos discussions fermées manuellement et automatiquement. Une discussion est fermée automatiquement au bout de {count} heures sans réponse de l'agent.",
    comment_bank: 'Bibliothèque de messages',
    confirm: 'Confirmer',
    download: 'Téléchargement',
    inactive: 'Inactif',
    late_details: "Statut désactivé | En retard au bout d'une minute sans réponse de l'agent. | En retard au bout de {count} min sans réponse de l'agent.",
    pick_room: 'Aucune discussion sélectionnée',
    quickreply: 'Messages pré-enregistrés',
    running_details: 'Discussion(s) que vous avez pris en charge. Vous seul pouvez les voir.',
    skill_transfer: 'Transférer vers une compétence',
    transfer: $settings.saas ? 'Remise en file' : 'Transférer',
    transferred_agent_title: 'Transférée (Agent)',
    transferred_skill_title: 'Transférée',
    transferred_title: 'Transférée',
    upload: 'Envoyer un document',
    via_transfer: $settings.saas ? 'Remise en file' : 'Discussion transférée',
    waiting_details: 'En attente tant qu’elle n’a pas été prise en charge par un conseiller.',
    yes: 'Cliquez ici pour prendre en charge la discussion'
  },
  search: 'Rechercher...',
  send: 'Envoyer',
  settings: {
    account: {
      ask_for_delete: 'Supprimer mon compte',
      bad_email: "L'adresse mail n'est pas valide",
      bad_phone_number: "Le numéro de téléphone n'est pas valide",
      bad_timezone: "Le fuseau horaire sélectionné n'est pas valide",
      change_password: 'Changer le mot de passe',
      changes_saved: 'Vos modifications ont bien été enregistrées ',
      delete_bad_why: 'Format incorrect',
      delete_button: 'Supprimer mon compte',
      delete_details: 'Dites-nous en plus',
      delete_no_details: 'Pourquoi souhaitez-vous supprimer votre compte ?',
      delete_no_why: 'Veuillez sélectionner une raison',
      delete_title: 'Demande de suppression du compte',
      delete_why: 'Pourquoi souhaitez-vous supprimer votre compte ?',
      delete_why_better_offer_elsewhere: "J'ai trouvé mieux ailleurs",
      delete_why_dont_meet_expectation: 'ChayAll ne correspond pas à mes attentes',
      delete_why_dont_need_it_anymore: "Je n'ai plus besoin d'utiliser ChayAll",
      delete_why_other_reason: 'Autre raison',
      delete_why_too_expensive: 'Les forfaits sont trop chers',
      email: 'Adresse email',
      email_already_used: 'Cette adresse email est déjà utilisée',
      enterprise: 'Entreprise',
      first_name: 'Prénom',
      last_name: 'Nom',
      no_email: 'Aucune adresse mail enregistrée',
      no_timezone: 'Aucun fuseau horaire sélectionné',
      password_changed: 'Votre demande de modification de mot de passe a bien été prise en compte !',
      phone_number: 'Numéro de téléphone',
      timezone_placeholder: 'Sélectionner un fuseau horaire',
      timezone: 'Fuseau horaire',
      title: 'Compte'
    },
    agent: {
      back: 'Retour aux utilisateurs',
      create: "Envoyer l'invitation",
      delete: 'Supprimer',
      deleted: 'Utilisateur supprimé',
      edit: 'Modifier un utilisateur',
      email: 'Adresse email',
      first_name: 'Prénom',
      groups: 'Groupes de compétences',
      last_name: 'Nom',
      max_reviews: 'Nombre d\'avis max. en simultané',
      max_rooms: 'Nombre de discussions max. en simultané',
      resend: "Renvoyer l'invitation",
      role_placeholder: 'Sélectionner un rôle',
      role: 'Rôle',
      roles: {
        admin: 'Admin',
        agent: 'Agent',
        content_manager: 'Gestionnaire contenu',
        flow_manager: 'Gestionnaire flux',
        manager: 'Manager',
        stats_manager: 'Statistiques',
        supervisor: 'Superviseur'
      },
      saved: 'Vos modifications ont bien été enregistrées',
      subaccounts: 'Sous-comptes'
    },
    agents: {
      add: 'Ajouter un utilisateur',
      agents_table_created: 'Création',
      agents_table_email: 'Adresse mail',
      agents_table_last_logon: 'Dernière connexion',
      agents_table_name: 'Nom',
      agents_table_role: 'Rôle',
      edit: 'Modifier',
      email_sent: "L'email a bien été renvoyé",
      faq: 'https://support.chayall.com/article/67-comment-modifier-supprimer-un-utilisateur',
      messages_section_title: 'Messages automatiques',
      saved: 'Votre invitation a bien été envoyée',
      search: 'Rechercher un utilisateur',
      subtitle: 'Liste des utilisateurs'
    },
    billing: {
      title: 'Nos offres'
    },
    channel: {
      fb_logged: 'Connecté à Facebook',
      fb_login: 'Se connecter à Facebook',
      ig_login: 'Se connecter à Instagram',
      logged: 'Vous êtes connecté',
      tw_logged: 'Connecté à X',
      tw_login: 'Se connecter à X'
    },
    chat: {
      absence_msg: "Message d'absence",
      absence_msg_details: "Informez votre client que vous n'êtes pas disponible pour le moment.",
      auto_msg: 'Messages automatiques',
      auto_msg_btn: 'Configuration des messages automatiques',
      auto_msg_warn: 'Ces messages automatiques concernent tous les canaux digitaux sauf Google\'s Business Messages, rendez-vous dans le formulaire Google\'s Business Messages pour les paramétrer.',
      calendar: {
        title: 'Horaires de disponibilité'
      },
      msglib: 'Bibliothèque de messages',
      notif: 'Notifications',
      notif_late_msg: 'Recevoir une notification par mail lorsqu\'une discussion passe en statut "en retard"',
      notif_new_msg_sound: 'Recevoir une notification sonore en cas de nouveau message client',
      notif_push_android: 'Recevoir des notifications push sur cet appareil Android',
      notif_push_apple: 'Recevoir des notifications push sur cet appareil Apple',
      notif_unread_msg: 'Recevoir une notification par mail en cas de nouvelle discussion',
      predictive_msglib: 'Activer la saisie intuitive de la bibliothèque de messages',
      subtitle: 'Personnalisez vos horaires de disponibilité et vos messages automatiques.',
      waiting_msg: 'Message de patience',
      waiting_msg_details: "Faites patienter votre client en l'informant que vous avez bien reçu son message et que vous allez bientôt lui répondre.",
      welcome_msg: 'Message de bienvenue',
      welcome_msg_details: 'Souhaitez la bienvenue à votre client grâce à ce message envoyé automatiquement dès le début de la discussion.'
    },
    'contact-reason': {
      ask_delete: 'Voulez-vous vraiment supprimer ce motif de contact ?',
      back: 'Retour à la liste des motifs de contact',
      created: 'Motif de contact créé',
      deleted: 'Motif de contact supprimé',
      faq: 'https://support.chayall.com/article/113-comment-parametrer-des-motifs-de-contact',
      label: 'Nom',
      saved: 'Vos modifications ont bien été enregistrées',
      skills: 'Compétences',
      subaccounts: 'Sous-compte',
      subtitle: 'Vous avez la possibilité d\'ajouter des motifs de contact qui seront ensuite disponibles directement dans votre Console Chat. Ils vous permettent de taguer vos discussions par motif de contact et de connaître les raisons pour lesquelles vos clients vous contactent.',
      'table-title': 'Liste des motifs de contact'
    },
    'chayall-api': {
      button: 'Générer le jeton',
      copied_to_clipboard: 'Copié dans le presse-papier !',
      copy: 'Copier le jeton',
      subtitle: 'Gestion de la clé API principale et des sous-clés',
      token: 'Jeton d\'intégration :'
    },
    customer: {
      cant_be_blank: 'Ce champ ne peut pas être vide'
    },
    external_views: {
      ask_delete: 'Voulez-vous vraiment supprimez cette vue externe ?',
      back: 'Retour à la liste de vues externes',
      deleted: 'Vue externe supprimée',
      icon: 'Icône',
      label: 'Nom',
      saved: 'Vos modifications ont bien été enregistrées',
      skill: 'Compétence',
      url: 'URL'
    },
    faq: "Besoin d'aide ?",
    group: {
      ask_delete: 'Voulez-vous vraiment supprimer ce groupe ?',
      back: 'Retour aux groupes de compétence',
      created: 'Groupe de compétences créé',
      deleted: 'Groupe de compétences supprimé',
      label: 'Nom',
      saved: 'Vos modifications ont bien été enregistrées',
      skills: 'Compétences'
    },
    groups: {
      subtitle: 'Liste des groupes de compétences'
    },
    library: {
      title: 'Bibliothèque de messages'
    },
    menu: {
      account_title: 'Compte',
      billing_title: 'Nos offres',
      chat: {
        agents_title: 'Paramètres console',
        library_title: 'Bibliothèque de messages'
      },
      chat_title: 'Live Chat',
      subscription_title: 'Offres'
    },
    msglib: {
      ask_delete: 'Voulez-vous vraiment supprimer cet élément ?',
      back: 'Retour',
      category: 'Catégories',
      content: 'Contenu',
      deleted: 'Elément supprimé',
      faq: 'https://support.chayall.com/article/92-comment-creer-des-messages-pre-enregistres',
      label: 'Nom',
      open: 'Ouvrir',
      saved: {
        body: 'Souhaitez-vous ouvrir cette (sous) catégorie ?',
        cancel: 'Retour à la liste',
        confirm: 'Ouvrir',
        title: 'Vos modifications ont bien été enregistrées'
      },
      search: 'Rechercher...',
      sentence: 'Messages',
      skills: 'Compétences',
      subcategory: 'Sous-catégories',
      subtitle: "Grâce à notre bibliothèque de messages, vous pouvez enregistrer des phrases types vous permettant de gagner du temps sur la Console chat. \nPour créer une phrase type, vous devez tout d'abord créer une catégorie et une sous-catégorie. Pour retrouver une phrase type, vous pouvez utiliser les catégories ou directement la rechercher en tapant un mot-clé.",
      title: 'Messages pré-enregistrés'
    },
    offers: {
      choose_title: 'Formulaire de contact',
      details: 'Message',
      email: 'Adresse email',
      first_name: 'Prénom',
      last_name: 'Nom',
      name: 'Nom',
      phone_number: 'Numéro de téléphone'
    },
    password: {
      ...passwordErrors,
      bad_old_password: 'Ancien mot de passe incorrect',
      old_password_required: 'Ancien mot de passe requis'
    },
    payments: {
      'billing-address': 'Ajouter/Modifier l\'adresse de facturation',
      'billing-address-title': 'Votre adresse de facturation',
      invoices: {
        'billing-address': 'Adresse de facturation',
        search: 'Rechercher une facture',
        title: 'Factures'
      },
      paymentmethods: {
        add_card: 'Ajouter un moyen de paiement',
        add_title: 'Ajouter un moyen de paiement',
        alias: 'Nom de la carte',
        bad_card_number: 'Le numéro de carte est incorrect',
        card: 'Informations de la carte',
        card_holder: 'Nom de la carte',
        changes_saved: 'Votre moyen de paiement a bien été ajouté.',
        city: 'Ville',
        country: 'Pays',
        credit_card: 'Carte bleue',
        default: 'Définir comme paiement par défaut',
        default_payment: 'Par défaut',
        errors: {
          incomplete_number: 'Le numéro de la carte est incomplet'
        },
        name: 'Entreprise',
        network: 'Type de carte',
        no_cards: 'Aucun moyen de paiement enregistré',
        line1: 'Adresse',
        line2: 'Complément d\'adresse',
        optional: '*settings.payments.paymentmethods.optional*',
        postal_code: 'Code postal',
        remove_payment: 'Supprimer le moyen de paiement',
        set_as_default_payment: 'Définir comme paiement par défaut',
        state: 'Etat (optionnel)',
        terms: 'J\'accepte les {0}',
        title: 'Moyens de paiement',
        type: {
          default: 'Carte de paiement',
          get cartes_bancaires () {
            return this.default
          },
          mastercard: 'Mastercard',
          visa: 'Visa'
        }
      },
      menu: {
        invoices: 'Factures',
        payment: 'Moyens de paiement'
      },
      table: {
        amount: 'Montant',
        date: 'Date',
        offer: 'Offre',
        reference: 'Référence',
        state: 'Statut',
        'status-paid': 'Payée',
        'status-unpaid': 'Impayée',
        'status-draft': 'En attente',
        'status-open': 'Ouverte',
        'status-uncollectible': 'Erreur',
        'status-void': 'Annulée'
      },
      temporarily_disabled: 'Les fonctionnalités d\'offres et de paiement sont temporairement désactivées, merci de réessayer ultérieurement.',
      title: 'Offres et paiement',
      subscription: {
        changes_saved: 'Votre souscription a bien été prise en compte.',
        channels_list: 'Intégration des canaux digitaux :',
        dollars: 'Dollars',
        duty_free: 'HT',
        error: {
          insufficient_funds: 'Fonds insuffisants (Code erreur : {0})'
        },
        euros: 'Euros',
        if_monthly_billing: '/mois',
        if_yearly_billing: '/an',
        monthly_billing: 'Mensuel',
        show_other: 'Voir nos autres offres',
        switch_currency: 'Choisissez votre devise',
        switch_interval: 'Optez pour un paiement mensuel ou annuel',
        title: 'Choisissez l\'offre qui vous correspond',
        yearly_billing: 'Annuel',
        yearly_save: '2 mois offerts'
      },
      subscription_title: '*settings.payments.subscription_title*',
      subtitle: 'Gérez votre offre, vos moyens de paiement et retrouvez vos factures.',
      summary: {
        billing_address_missing: 'Vous devez renseigner une adresse de facturation valide afin de procéder au paiement.',
        offer: 'Offre {0}',
        paymentmethod: 'Méthode de paiement utilisée',
        period: 'Du {0} au {1}',
        subscribe: 'Souscrire',
        total: 'Total'
      }
    },
    rooms: {
      auto_close: 'Délai avant fermeture (HH:mm)',
      auto_close_toggle: 'Fermeture automatique des discussions',
      auto_close_toggle_details: 'La fermeture automatique permet de configurer le temps avant qu’une discussion en cours, en retard ou en attente ne passe au statut “fermé” de manière automatique. Une discussion est fermée automatiquement sans réponse de l’agent ou du visiteur depuis X temps.',
      auto_close_msg_toggle: 'Envoyer un message automatique à votre client après la fermeture automatique d\'une discussion dans la console chat',
      auto_close_msg_placeholder: 'Renseignez le message automatique à envoyer à votre client',
      hours: 'HH',
      late: 'Délai avant passage au statut « En retard » (HH:mm)',
      late_toggle: 'Basculer automatiquement au statut « En retard »',
      late_toggle_details: 'Le statut en retard permet de configurer la durée avant qu’une discussion en cours ne passe au statut “en retard”. Une discussion est considérée comme “en retard”, lorsqu’il s’agit d’une discussion acceptée par l’agent avec une non-réponse de l’agent depuis le dernier message visiteur depuis plus de X temps.',
      minutes: 'mm',
      re_queue: 'Délai avant passage au statut « En attente » (HH:mm)',
      re_queue_toggle: 'Remise en file automatique',
      re_queue_toggle_details: 'La remise en file permet de configurer la durée après laquelle une discussion “en retard” repassera “en attente”. Une discussion est donc remise en file après avoir passé X temps « en retard ». Si le passage au statut en retard n’est pas activé, la remise en file permet de configurer la durée après laquelle une discussion "en cours" et sans réponse de l’agent repasse “en attente”.',
      saved: 'Vos modifications ont bien été prises en compte.'
    },
    'scheduled-exports': {
      ask_delete: 'Voulez-vous vraiment supprimer cet export planifié ?',
      back: 'Retour à la liste d\'exports planifiés',
      configuration: 'Configuration',
      deleted: 'Export planifié supprimé',
      enabled: 'Actif',
      error: {
        'contains:{{datetime}}': 'Le nom doit contenir la variable {{datetime}}',
        invalid_extension: 'L\'extension du fichier n\'est pas valide'
      },
      file: {
        filename: 'Nom du fichier',
        filename_details: 'Utiliser la variable {{datetime}} dans le nom du fichier permet d\'y insérer la date de cet export, par exemple : "Export-{{datetime}}".',
        kind: 'Type d\'export',
        kind_campaigns: 'Campagnes',
        kind_rooms_per_status: 'Discussions par statut',
        kind_rooms_events: 'Événements par discussion',
        kind_webivrs: 'SVI Visuels',
        kind_webivr_forms: 'Détails des formulaires de SVI Visuels',
        title: 'Fichier n° {0}'
      },
      is_active: 'Activer l\'export planifié',
      label: 'Nom',
      recurrence_rule: 'Planification',
      report_files: 'Fichiers d\'export',
      saved: 'Export planifié enregistrés',
      sftp_path: 'Chemin de destination SFTP',
      state: 'Statut',
      target: 'Destination',
      thirdparties_sftp_id: 'Compte SFTP',
      user_id: 'Utilisateur'
    },
    show_more: 'Voir plus',
    skill: {
      auto_allocation: 'Affectation automatique des discussions',
      auto_allocation_details: 'L\'affectation automatique des discussions permet d\'attribuer chaque nouvelle discussion entrante à vos conseillers.',
      auto_msg: 'Messages automatiques',
      back: 'Retour aux compétences',
      botTrees: {
        button: 'Arborescences',
        channel: 'Canal',
        linked_tree: 'Arborescence associée',
        title: 'Arborescences',
        type: 'Type'
      },
      'change-services-warn': {
        body: 'Les modifications de service peuvent impacter votre interface Chayall, êtes-vous sûr de vouloir sauvegarder vos modifications ?',
        msg: 'Attention, les modifications de service peuvent impacter votre interface Chayall',
        title: 'Attention'
      },
      full_msg: 'Message d\'attente de mise en relation - agents occupés',
      full_msg_helper: 'Message envoyé lorsque vos agents sont disponibles, mais qu\'ils ont atteint leur seuil de discussions maximum en simultanée, et qui invite le visiteur à patienter',
      pause_msg: 'Message d\'attente de mise en relation - agents en pause',
      pause_msg_helper: 'Message envoyé lorsque vos agents sont en pause et qui invite le visiteur à patienter',
      created: 'Compétence créée',
      label: 'Nom',
      notification_msg: 'Notification aux conseillers',
      online_msg: 'Message de mise en relation',
      online_msg_helper: 'Message envoyé lorsque au moins un de vos agents est disponible pour prendre en charge une discussion',
      offline_msg: 'Message d\'échec de mise en relation',
      offline_msg_helper: 'Message envoyé lorsque tous vos agents sont déconnectés',
      options: 'Options',
      saved: 'Vos modifications ont bien été enregistrées',
      screensharing: {
        cobrowsing: 'Co-navigation',
        cobrowsing_details: 'Cette fonctionnalité permet de consulter la page Web sur laquelle se trouve le LiveChat de votre interlocuteur depuis la console agent ainsi qu\'une reconstitution de ses actions en temps réel.',
        mirroring: 'Partage d\'écran',
        mirroring_details: 'Cette fonctionnalité permet d\'envoyer une demande de partage à votre interlocuteur depuis la console agent afin que celui-ci puisse vous partager l\'entièreté de son écran ou la fenêtre d\'application de son choix.'
      },
      services: 'Services',
      service: {
        bot: 'Messageries',
        callback: 'Rappel',
        campaigns: 'Campagnes',
        dialogflow: 'IA - Dialogflow',
        emails: 'Mails',
        iframes: 'Vues externes',
        'knowledge-engine': 'IA - Bases de connaissances',
        social_networks: 'Réseaux sociaux',
        gb_agent: 'Console Agent',
        integration: 'Points d\'entrée',
        ravana: 'IA - Agents conversationnels',
        'reports-dispatcher': 'Exports planifiés',
        reviews: 'Avis',
        smartcoaching: 'IA - Smartcoaching',
        'third-parties': 'Services externes',
        webivr: 'SVI Visuels'
      },
      take_msg: 'Afficher un message à l\'agent au moment de la prise en charge d\'une discussion',
      take_msg_helper: 'Lorsqu\'un agent prend en charge une discussion, un message informatif s\'affiche avec le contenu que vous avez renseigné.',
      take_msg_placeholder: 'Message de prise en charge',
      translate: 'Traduction automatique',
      translate_bot: 'Traduction automatique du bot',
      translate_bot_details: 'Cette fonctionnalité permet de traduire automatiquement les différentes actions et messages envoyés par le bot.',
      translate_gbagent: 'Traduction automatique de la console agent',
      translate_gbagent_details: 'Cette fonctionnalité permet de traduire automatiquement les messages que vous recevez et que vous envoyez dans la console agent.',
      waiting_msg: 'Message de patience',
      waiting_msg_helper: 'Message envoyé lorsque le visiteur relance la discussion avant que celle-ci ne soit prise en charge par un agent'
    },
    skills: {
      subtitle: 'Liste des compétences'
    },
    get subaccount () {
      return Object.assign({}, this.skill, {
        back: 'Retour aux sous-comptes',
        created: 'Sous-compte créé'
      })
    },
    subaccounts: {
      subtitle: 'Vous pouvez créer des sous-comptes pour gérer plusieurs comptes, qu\'il s\'agisse de filiales ou de tiers.\nC\'est une fonctionnalité puissante, surtout si vous devez gérer plusieurs marques ou si, en tant qu\'agence Web, vous devez ajouter les sites Web de vos clients à votre propre compte.',
      table_title: 'Liste des sous-comptes'
    },
    surveys: {
      alert: 'Cette fonctionnalité n\'est disponible que pour les offres Pro et Entreprise.',
      alert_btn: 'En savoir plus',
      ask_delete: 'Attention, si vous supprimez cette enquête de satisfaction, aucune enquête ne sera envoyée à la fermeture d\'une discussion à vos clients sur les compétences qui étaient associées à cette enquête.',
      back: 'Retour à la liste d\'enquêtes de satisfaction',
      deleted: 'Enquête de satisfaction supprimée',
      default_text: 'Merci pour cet échange. N\'hésitez pas à nous laisser votre avis',
      error: {
        bad_var: 'Attention, la variable {0} est inconnue'
      },
      force: 'Envoyer automatiquement l\'enquête de satisfaction à la fermeture de la discussion',
      label: 'Nom',
      saved: 'Vos modifications ont bien été enregistrées',
      skills: 'Compétences',
      skills_details: 'Sélectionnez la ou les compétences sur lesquelles activer votre enquête de satisfaction.',
      subaccounts: 'Sous-comptes',
      subaccounts_details: 'Sélectionnez le ou les sous-comptes sur lesquelles activer votre enquête de satisfaction.',
      subtitle: 'Proposez à vos clients de noter votre échange et de laisser un avis sur votre entreprise. Vous pourrez envoyer ce message personnalisé lors de la fermeture d\'une discussion.',
      text: 'Message personnalisé',
      url: 'URL',
      url_long: 'Proposer une URL de plateforme d\'avis client ?'
    },
    zapier: {
      app_link: 'https://zapier.com/apps',
      app_link_label: 'nombreuses autres applications',
      button: 'Générer le jeton',
      copied_to_clipboard: 'Copié dans le presse-papier !',
      copy: 'Copier le jeton',
      faq: 'https://support.chayall.com/article/127-comment-connecter-chayall-a-dautres-apps-grace-a-zapier',
      faq_label: 'notre guide pas à pas',
      link: 'https://zapier.com/',
      link_label: 'Zapier',
      subtitle: `Zapier est un outil permettant de connecter ChayAll à de {0}. Vous pouvez ainsi créer des connexions automatisées, des Zaps, en quelques minutes afin d'automatiser des tâches et créer des workflows.
Pour commencer, il vous faut un compte {1}.
Suivez ensuite {2} pour créer votre premier Zap !`,
      token: 'Jeton d\'intégration :'
    }
  },
  skip: 'Passer',
  sort: 'Trier',
  sso: {
    button: 'Single Sign-On (SSO)',
    customer: 'Nom de l\'entreprise',
    email: 'Email',
    error: {
      login_error: 'Une erreur s\'est produite',
      login_timeout: 'Délai de connexion dépassé',
      unauthorized: 'Accès non autorisé'
    },
    or: 'Ou se connecter avec'
  },
  start: "J'ai compris",
  totp_error: 'Échec de Double Authentification',
  transfer_agent_modal: {
    select_agent: 'Veuillez sélectionner l\'utilisateur à qui va être transférée la discussion :',
    title: 'Transférer la discussion à un utilisateur'
  },
  transfer_modal: {
    select_skill: 'Veuillez confirmer la section dans laquelle va être transférée la discussion :',
    skill_availability: {
      ok: 'Agents disponibles sur cette compétence',
      nok: 'Agents non disponibles sur cette compétence'
    },
    skill_estimated_waiting_time: "{0} d'attente",
    title: $settings.saas ? 'Remise en file' : 'Transférer vers une compétence'
  },
  transferred_modal: {
    now_in_waiting_section: $settings.saas ? 'La discussion a bien été remise en file.' : 'La discussion a bien été transférée.' + '\n\nCelle-ci se trouve désormais dans la section "En attente", visible par les autres agents.',
    title: $settings.saas ? 'Confirmation de la remise en file' : 'Confirmation du transfert',
    transfer_successfull: ''
  },
  tree: {
    copy_tree_on_channel: 'Copier l\'arborescence sur un autre canal',
    copy_tree_same_channel: 'Dupliquer l\'arborescence',
    delete_tree: 'Supprimer',
    rename: {
      placeholder: 'Nom de l\'arboscence'
    },
    rename_tree: 'Renommer',
    set_as_init: 'Définir comme arbo initiale',
    set_as_fallback: 'Définir comme fallback'
  },
  unauthorized: 'Identifiant ou mot de passe invalide',
  user_stats: {
    conversation_time: 'Temps moyen de discussion',
    nps: 'NPS',
    waiting_time: "Temps d'attente moyen"
  },
  validate: 'Valider',
  warning: 'Attention',
  webivr: {
    ask_delete: 'Voulez-vous vraiment supprimer ce SVI visuel ?',
    back: 'Retour à la liste des SVI visuels',
    builder: {
      add_label: 'Nom de l\'arborescence :',
      action: {
        api: {
          subtitle: 'Bientôt disponible',
          title: 'API'
        },
        callback: {
          access_denied: 'Accès non autorisé',
          subtitle: 'Proposez à votre client d’être rappelé sur un créneau disponible',
          title: 'Rappel'
        },
        choice: {
          subtitle: 'Proposez une liste de choix à votre client',
          title: 'Choix'
        },
        default_label: 'Libellé par défaut :',
        faq: {
          access_denied: 'FAQ non accessible (Groupe de compétences)',
          subtitle: 'Présentez une FAQ à votre client',
          title: 'FAQ'
        },
        form: {
          access_denied: 'Formulaire non accessible (Groupe de compétences)',
          subtitle: 'Proposer un formulaire de contact ou une enquête de satisfaction à vos clients',
          title: 'Formulaire'
        },
        form_output: {
          no_score_range: 'À l\'envoi du formulaire',
          title: 'Sortie',
          score_range: 'De {0}% à {1}%'
        },
        messaging: {
          access_denied: 'Point d\'entrée non accessible (Groupe de compétences)',
          subtitle: 'Affichez des pastilles de canaux digitaux sur votre SVI Visuel',
          title: 'Canaux digitaux'
        },
        page: {
          subtitle: 'Présentez une nouvelle page à votre client',
          title: 'Ajouter une page'
        },
        routing_output: {
          title: 'Sortie',
          value: '{0}%'
        },
        routing: {
          subtitle: 'Définissez les pourcentages sur lesquels effectuer des répartition de flux',
          title: 'Répartition flux'
        },
        text: {
          subtitle: 'Insérez un court bloc de texte',
          title: 'Texte'
        },
        to_tree: {
          subtitle: 'Redirigez votre client vers le début d\'une arborescence',
          title: 'Arborescence'
        },
        uri: {
          subtitle: 'Présentez un lien URL à votre client',
          title: 'URL'
        },
        uri__tel: {
          subtitle: 'Proposez un contact par téléphone à votre client',
          title: 'Appel direct'
        },
        uri__mail: {
          subtitle: 'Proposez un contact par mail à votre client',
          title: 'Email'
        }
      },
      confirm_publish: 'Publier le SVI visuel',
      delete_label: 'Voulez-vous vraiment supprimer cette arborescence ?',
      delete_error_label: 'Impossible de supprimer l\'arborescence car celle-ci est associée au(x) point(s) d\'entrée(s) suivant :',
      error: {
        cannot_move_parent_under_child: 'Un noeud "parent" ne peut pas être déplacé sur un de ses noeuds "enfants"',
        first_node_must_be_page: 'Le premier noeud d\'une arborescence doit être un noeud de type "Page"',
        invalid_parent_child_relationship: 'La relation parent-enfant de ces noeuds est invalide',
        'kind:oneof:': 'Le noeud déposé doit être d\'un des types suivant pour réaliser cette action : {0}',
        'label:unique': 'Le nom de version doit être unique',
        parent_node_needed: 'Ce type de noeud requiert un noeud parent',
        source_and_target_nodes_are_the_same: 'Le noeud ciblé doit être différent du noeud copié',
        unvalidated_nodes_remaining: 'Impossible de publier la version actuelle : Il reste au moins un noeud invalide à traiter avant de pouvoir publier cette version.'
      },
      not_working_version: {
        title: 'Aperçu en lecture seule',
        body: 'Vous ne pouvez pas modifier cette version car celle-ci est différente de la version de travail.',
        link: 'Afficher la version de travail'
      },
      publish: 'Publier',
      publish_form_label: 'Nom de la version',
      publish_helptext: 'Personnaliser le nom de la nouvelle version du SVI visuel',
      publish_label_template: '{date} à {time}',
      published: 'SVI Visuel publié',
      saved: 'SVI Visuel sauvegardé'
    },
    created: 'SVI visuel créé',
    deleted: 'Le SVI visuel a été supprimé',
    duplicate: 'Dupliquer le SVI visuel',
    duplicated: 'SVI visuel dupliqué',
    edit: 'Editer',
    faqs: {
      ask_cancel: 'Vous allez perdre vos modifications, êtes-vous sûr de vouloir annuler vos modifications ?',
      ask_delete: 'Si vous supprimez cette FAQ, cette suppression s\'appliquera également au niveau des actions qui l\'utilisent. Voulez-vous vraiment supprimer cette FAQ ?',
      ask_submit: 'Vos modifications vont s\'appliquer à l\'ensemble des actions utilisant cette FAQ, êtes-vous sûr de vouloir sauvegarder vos modifications ?',
      back: 'Retour à la liste des FAQ',
      button: 'Gérer les FAQs',
      content: 'Contenu de la FAQ',
      created: 'FAQ créée',
      deleted: 'La FAQ a été supprimé',
      icon: 'Icône',
      label: 'Nom',
      saved: 'FAQ mise à jour',
      search: 'Rechercher une FAQ...',
      skill: 'Compétence',
      updated_at: 'Dernière modification',
      title: 'FAQ',
      trees: 'SVI Builder et arborescences'
    },
    forms: {
      'add-choice': 'Ajouter un choix',
      'add-row': 'Ajouter une ligne',
      api_body: 'Corps du payload',
      api_headers: 'Entêtes du payload',
      api_method: 'Méthode de l\'API',
      api_url: 'URL de l\'API',
      ask_cancel: 'Vous allez perdre vos modifications, êtes-vous sûr de vouloir annuler vos modifications ?',
      ask_delete: 'Si vous supprimez ce formulaire, cette suppression s\'appliquera également au niveau des actions qui l\'utilisent. Voulez-vous vraiment supprimer ce formulaire ?',
      ask_submit: 'Vos modifications vont s\'appliquer à l\'ensemble des actions utilisant ce formulaire, êtes-vous sûr de vouloir sauvegarder vos modifications ?',
      back: 'Retour à la liste des formulaires',
      button: 'Gérer les formulaires',
      button_label: 'Libellé du bouton',
      choices: {
        title: 'Configuration de la liste de choix'
      },
      created: 'Formulaire créé',
      delete: 'Supprimer',
      errors: {
        max_too_long: 'La valeur est trop longue. La longueur maximum est {0}.',
        min_too_long: 'La valeur est trop courte. La longueur minimum est {0}.',
        bad_notification_emails: 'Au moins un des emails de notification est mal formaté'
      },
      fields: {
        checkbox: 'Liste de choix multiple (case à cocher)',
        customize_name: 'Personnaliser le nom',
        customize_placeholder: 'Personnaliser le placeholder',
        date: 'Date',
        datetime: 'Date et heure',
        email: 'Email',
        file: 'Fichier',
        location: 'Emplacement',
        optgroups: {
          choices: 'Champs avec des choix',
          dates: 'Champs liés au temps',
          texts: 'Champs textes',
          locations: 'Champ lié à l\'emplacement'
        },
        radio: 'Liste de choix (bouton radio)',
        range: 'Intervalle de valeurs',
        select: 'Liste de choix',
        select_multiple: 'Liste de choix multiple',
        text: 'Texte',
        textarea: 'Texte long',
        time: 'Heure',
        title: 'Champs du formulaire'
      },
      identifier: 'Identifiant',
      label: 'Libellé',
      kind: 'Type',
      notification_emails: 'Emails de notification (séparés par des virgules)',
      notification_options: 'Options de notification',
      order: 'Ordre',
      required: 'Requis',
      saved: 'Formulaire mis à jour',
      search: 'Recherche',
      skill: 'Compétence',
      title: 'Formulaires',
      trees: 'Arborescences',
      use_weight: 'A prendre en compte dans le calcul ?',
      updated_at: 'Dernière modification',
      validation: 'Validation',
      weight: 'Pondération'
    },
    label: 'Nom',
    language: 'Langue',
    language_placeholder: 'Sélectionner une langue',
    saved: 'SVI visuel mis à jour',
    settings: 'Paramètres',
    skill_placeholder: 'Sélectionner une compétence',
    skill: 'Compétence',
    subtitle: 'Liste des SVI visuels',
    templates: {
      ask_cancel: 'Vous allez perdre vos modifications, êtes-vous sûr de vouloir annuler vos modifications ?',
      ask_delete: 'Si vous supprimez ce thème, cette suppression s\'appliquera également au niveau des actions qui l\'utilisent. Voulez-vous vraiment supprimer ce thème ?',
      ask_submit: 'Vos modifications vont s\'appliquer à l\'ensemble des actions utilisant ce thème, êtes-vous sûr de vouloir sauvegarder vos modifications ?',
      back: 'Retour à la liste des thèmes',
      button: 'Gérer les thèmes',
      created: 'Thème créée',
      deleted: 'Le thème a été supprimé',
      label: 'Nom',
      saved: 'Thème mise à jour',
      search: 'Rechercher un thème...',
      skill: 'Compétence',
      updated_at: 'Dernière modification',
      title: 'Thème',
      trees: 'SVI Builder et arborescences'
    },
    updated_at: 'Mis à jour le',
    webivr: 'SVI'
  },
  webrtc: {
    invit_call: 'Envoyer une demande d\'appel audio',
    invit_videocall: 'Envoyer une demande d\'appel vidéo',
    invit: 'Envoyer une demande de partage d\'écran',
    join: 'Rejoindre la session',
    quit_confirm: 'Vous êtes sur le point de quitter la session de partage, voulez-vous continuez ?',
    rich_content_call: 'Demande d\'appel audio',
    rich_content_videocall: 'Demande d\'appel vidéo',
    rich_content: 'Demande de partage d\'écran',
    waiting: 'Veuillez patienter, votre interlocuteur doit approuver votre participation'
  },
  wysiwyg: {
    align: 'Aligner',
    alignments: 'Alignements',
    alignmentsDropDownCenter: 'Aligner au centre',
    alignmentsDropDownJustify: 'Alignement justifié',
    alignmentsDropDownLeft: 'Aligner à gauche',
    alignmentsDropDownRight: 'Aligner à droite',
    alternateHeader: 'Texte alternatif',
    alttext: 'Texte alternatif',
    backgroundcolor: 'Couleur de fond',
    bold: 'Gras',
    box: 'Encadrer',
    browse: 'Parcourir',
    caption: 'Légende',
    cellpadding: 'Rembourrage des cellules',
    cellspacing: 'Espacement des cellules',
    cleanFormat: 'Nettoyer',
    clearall: 'Effacer tout',
    clearformat: 'Effacer le format',
    columns: 'Nombre de colonnes',
    copy: 'Copier',
    createLink: 'Insérer un lien',
    createTable: 'Créer un tableau',
    cut: 'Couper',
    deleteColumn: 'Supprimer la colonne',
    deleteRow: 'Supprimer une rangée',
    dialogCancel: 'Annuler',
    dialogInsert: 'Insérer',
    dialogUpdate: 'Mettre à jour',
    dimension: 'Dimension',
    display: 'Afficher',
    editImageHeader: 'Modifier l\'image',
    editLink: 'Modifier le lien',
    fontcolor: 'Couleur de la police',
    fontname: 'Nom de la police',
    fontNameArial: 'Arial',
    fontNameGeorgia: 'Georgia',
    fontNameImpact: 'Impact',
    fontNameSegoeUI: 'Segoe UI',
    fontNameTahoma: 'Tahoma',
    fontNameTimesNewRoman: 'Times New Roman',
    fontNameVerdana: 'Verdana',
    fontsize: 'Taille de la police',
    formats: 'Formats',
    formatsDropDownCode: 'Code',
    formatsDropDownHeading1: 'Titre 1',
    formatsDropDownHeading2: 'Titre 2',
    formatsDropDownHeading3: 'Titre 3',
    formatsDropDownHeading4: 'Titre 4',
    formatsDropDownParagraph: 'Paragraphe',
    formatsDropDownQuotation: 'Citation',
    fullscreen: 'Plein écran',
    html: 'Insérer le code',
    htmlHeader: 'Code HTML à insérer à l\'emplacement sélectionné :',
    image: 'Insérer une image',
    imageAlternateText: 'Texte alternatif',
    imageCaption: 'Légende',
    imageDeviceUploadMessage: 'Cliquez ici pour télécharger',
    imageDisplayDropDownBreak: 'Interrompre la ligne',
    imageDisplayDropDownInline: 'En ligne',
    imageHeader: 'Insérer une image',
    imageHeight: 'Hauteur',
    imageInsertLinkHeader: 'Insérer un lien',
    imageLinkHeader: 'Vous pouvez également fournir un lien depuis le web',
    imageSizeHeader: 'Taille de l\'image',
    imageUploadMessage: 'Déposez l\'image ici ou parcourez-la pour la télécharger',
    imageWidth: 'Largeur',
    indent: 'Augmenter le retrait',
    insertcode: 'Insérer le code',
    insertColumnLeft: 'Insérer une colonne à gauche',
    insertColumnRight: 'Insérer une colonne à droite',
    insertlink: 'insérer un lien',
    insertRowAfter: 'Insérer une rangée après',
    insertRowBefore: 'Insérer une rangée avant',
    inserttablebtn: 'Insérer un tableau',
    italic: 'Italique',
    justifycenter: 'Aligner au centre',
    justifyfull: 'Justifier',
    justifyleft: 'Aligner à gauche',
    justifyright: 'Aligner à droite',
    keepFormat: 'Garder',
    linkHeader: 'Insérer un lien',
    linkOpenInNewWindow: 'Ouvrir le lien dans une nouvelle fenêtre',
    linkText: 'Afficher le texte',
    linkTitle: 'Saisir un titre',
    linkTooltipLabel: 'Titre',
    linkWebUrl: 'Adresse Web',
    lowercase: 'Minuscules',
    maximize: 'Maximiser',
    mdimageLink: 'Veuillez fournir une URL pour votre image',
    minimize: 'Minimiser',
    openLink: 'Ouvrir un lien',
    orderedList: 'Liste ordonnée',
    outdent: 'Diminuer le retrait',
    paste: 'Coller',
    pasteFormat: 'Coller le format',
    pasteFormatContent: 'Choisissez l\'action de formatage',
    plainText: 'Texte brut',
    preview: 'Prévisualiser',
    print: 'Imprimer',
    redo: 'Refaire',
    remove: 'Supprimer',
    removeLink: 'supprimer un lien',
    removeTable: 'Supprimer un tableau',
    replace: 'Remplacer',
    rows: 'Nombre de rangées',
    sourcecode: 'Code source',
    strikethrough: 'Barré',
    styles: 'Styles',
    subscript: 'Abécédaire',
    superscript: 'Superscript',
    tableCellBackground: 'Arrière-plan des cellules de tableau',
    tableCellHorizontalAlign: 'Alignement horizontal des cellules de tableau',
    tableCellVerticalAlign: 'Alignement vertical des cellules de tableau',
    TableColText: 'Col',
    tableColumns: 'Colonnes du tableau',
    tabledialogHeader: 'Insérer un tableau',
    tableEditHeader: 'Modifier le tableau',
    tableEditProperties: 'Propriétés d\'édition du tableau',
    tableHeader: 'En-tête de tableau',
    TableHeadingText: 'Rubrique',
    tableInsertColumnDropDownDelete: 'Supprimer la colonne',
    tableInsertColumnDropDownLeft: 'Insérer une colonne à gauche',
    tableInsertColumnDropDownRight: 'Insérer une colonne à droite',
    tableInsertRowDropDownAfter: 'Insérer une ligne après',
    tableInsertRowDropDownBefore: 'Insérer une ligne avant',
    tableInsertRowDropDownDelete: 'Supprimer une ligne',
    tableRemove: 'Supprimer le tableau',
    tableRows: 'Lignes du tableau',
    tableStylesDropDownAlternateRows: 'Alterner les rangées',
    tableStylesDropDownDashedBorder: 'Bordures en pointillés',
    tableVerticalAlignDropDownBottom: 'Aligner en bas',
    tableVerticalAlignDropDownMiddle: 'Aligner au milieu',
    tableVerticalAlignDropDownTop: 'Aligner en haut',
    tableWidth: 'Largeur',
    textPlaceholder: 'Saisir le texte',
    underline: 'Souligné',
    undo: 'Défaire',
    unorderedList: 'Liste non ordonnée',
    uppercase: 'Majuscules',
    viewside: 'Afficher le côté',
    zoomin: 'Zoom avant',
    zoomout: 'Zoom arrière'
  },
  worker_reload_required: 'Une nouvelle version est disponible, veuillez cliquer ici pour actualiser.',
  country: {
    af: 'Afghanistan',
    za: 'Afrique du Sud',
    al: 'Albanie',
    dz: 'Algérie',
    de: 'Allemagne',
    ad: 'Andorre',
    ao: 'Angola',
    ag: 'Antigua-et-Barbuda',
    sa: 'Arabie saoudite',
    ar: 'Argentine',
    am: 'Arménie',
    au: 'Australie',
    at: 'Autriche',
    az: 'Azerbaïdjan',
    bs: 'Bahamas',
    bh: 'Bahreïn',
    bd: 'Bangladesh',
    bb: 'Barbade',
    by: 'Biélorussie',
    be: 'Belgique',
    bz: 'Belize',
    bj: 'Bénin',
    bt: 'Bhoutan',
    bo: 'Bolivie',
    ba: 'Bosnie-Herzégovine',
    bw: 'Botswana',
    br: 'Brésil',
    bn: 'Brunei',
    bg: 'Bulgarie',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    kh: 'Cambodge',
    cm: 'Cameroun',
    ca: 'Canada',
    cv: 'Cap-Vert',
    cf: 'République centrafricaine',
    cl: 'Chili',
    cn: 'Chine',
    cy: 'Chypre (pays)',
    co: 'Colombie',
    km: 'Comores (pays)',
    cg: 'République du Congo',
    cd: 'République démocratique du Congo',
    kr: 'Corée du Sud',
    kp: 'Corée du Nord',
    cr: 'Costa Rica',
    ci: 'Côte d\'Ivoire',
    hr: 'Croatie',
    cu: 'Cuba',
    dk: 'Danemark',
    dj: 'Djibouti',
    do: 'République dominicaine',
    dm: 'Dominique',
    eg: 'Égypte',
    sv: 'Salvador',
    ae: 'Émirats arabes unis',
    ec: 'Équateur (pays)',
    er: 'Érythrée',
    es: 'Espagne',
    ee: 'Estonie',
    us: 'États-Unis',
    et: 'Éthiopie',
    fj: 'Fidji',
    fi: 'Finlande',
    fr: 'France',
    ga: 'Gabon',
    gm: 'Gambie',
    ge: 'Géorgie (pays)',
    gh: 'Ghana',
    gr: 'Grèce',
    gd: 'Grenade (pays)',
    gt: 'Guatemala',
    gn: 'Guinée',
    gw: 'Guinée-Bissau',
    gq: 'Guinée équatoriale',
    gy: 'Guyana',
    ht: 'Haïti',
    hn: 'Honduras',
    hu: 'Hongrie',
    in: 'Inde',
    id: 'Indonésie',
    ir: 'Iran',
    iq: 'Irak',
    ie: 'Irlande (pays)',
    is: 'Islande',
    il: 'Israël',
    it: 'Italie',
    jm: 'Jamaïque',
    jp: 'Japon',
    jo: 'Jordanie',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    kg: 'Kirghizistan',
    ki: 'Kiribati',
    kw: 'Koweït',
    la: 'Laos',
    ls: 'Lesotho',
    lv: 'Lettonie',
    lb: 'Liban',
    lr: 'Liberia',
    ly: 'Libye',
    li: 'Liechtenstein',
    lt: 'Lituanie',
    lu: 'Luxembourg (pays)',
    mk: 'Macédoine du Nord',
    mg: 'Madagascar',
    my: 'Malaisie',
    mw: 'Malawi',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malte',
    ma: 'Maroc',
    mh: 'Îles Marshall (pays)',
    mu: 'Maurice (pays)',
    mr: 'Mauritanie',
    mx: 'Mexique',
    fm: 'États fédérés de Micronésie (pays)',
    md: 'Moldavie',
    mc: 'Monaco',
    mn: 'Mongolie',
    me: 'Monténégro',
    mz: 'Mozambique',
    mm: 'Birmanie',
    na: 'Namibie',
    nr: 'Nauru',
    np: 'Népal',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    no: 'Norvège',
    nz: 'Nouvelle-Zélande',
    om: 'Oman',
    ug: 'Ouganda',
    uz: 'Ouzbékistan',
    pk: 'Pakistan',
    pw: 'Palaos',
    pa: 'Panama',
    pg: 'Papouasie-Nouvelle-Guinée',
    py: 'Paraguay',
    nl: 'Pays-Bas',
    pe: 'Pérou',
    ph: 'Philippines',
    pl: 'Pologne',
    pt: 'Portugal',
    qa: 'Qatar',
    ro: 'Roumanie',
    gb: 'Royaume-Uni',
    ru: 'Russie',
    rw: 'Rwanda',
    kn: 'Saint-Christophe-et-Niévès',
    sm: 'Saint-Marin',
    vc: 'Saint-Vincent-et-les-Grenadines',
    lc: 'Sainte-Lucie',
    sb: 'Salomon',
    ws: 'Samoa',
    st: 'Sao Tomé-et-Principe',
    sn: 'Sénégal',
    rs: 'Serbie',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapour',
    sk: 'Slovaquie',
    si: 'Slovénie',
    so: 'Somalie',
    sd: 'Soudan',
    ss: 'Soudan du Sud',
    lk: 'Sri Lanka',
    se: 'Suède',
    ch: 'Suisse',
    sr: 'Suriname',
    sz: 'Eswatini',
    sy: 'Syrie',
    tj: 'Tadjikistan',
    tz: 'Tanzanie',
    td: 'Tchad',
    cz: 'Tchéquie',
    th: 'Thaïlande',
    tl: 'Timor oriental',
    tg: 'Togo',
    to: 'Tonga',
    tt: 'Trinité-et-Tobago',
    tn: 'Tunisie',
    tm: 'Turkménistan',
    tr: 'Turquie',
    tv: 'Tuvalu',
    ua: 'Ukraine',
    uy: 'Uruguay',
    vu: 'Vanuatu',
    ve: 'Venezuela',
    vn: 'Viêt Nam',
    ye: 'Yémen',
    zm: 'Zambie',
    zw: 'Zimbabwe'
  }
}
