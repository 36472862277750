import { Commit, Dispatch } from 'vuex'

import {
  Account,
  File,
  State
} from '@/types/knowledgeBases'

// initial state
const state: State = {
  accounts: null,
  current: null,
  currentFiles: null
}

// getters
const getters = {
  accounts (): Array<Account> | null {
    return state.accounts
  },
  current (): Account | null {
    return state.current
  },
  currentFiles (): Array<File> | null {
    return state.currentFiles
  }
}

// mutations
const mutations = {
  accounts (state: State, data: Array<Account> | null): void {
    state.accounts = data
  },
  current (state: State, data: Account | null): void {
    state.current = data
  },
  currentFiles (state: State, data: Array<File> | null): void {
    state.currentFiles = data
  }
}

// actions
const actions = {
  deleteAccount ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteAccountFile ({ dispatch }: { dispatch: Dispatch }, data: { account: string, id: string}): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases/${data.account}/file/${data.id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccount ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Account> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases/${id}`)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          commit('current', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccountFiles ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Array<File>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases/${id}/docs`)
        .then((response: Response) => response.json())
        .then(({ files }: { files: Array<File> }) => {
          commit('currentFiles', files)
          resolve(files)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Account>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases`)
        .then((response: Response) => response.json())
        .then((data: Array<Account>) => {
          commit('accounts', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  importFile (_: unknown, data: { account: Account, file: FormData }): Promise<File> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(
        `${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases/${data.account}/file`,
        data.file,
        { 'Content-Type': 'multipart/form-data;' }
      )
        .then((response: Response) => response.json())
        .then((data: File) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  },
  saveAccount (_: unknown, account: Account): Promise<Account> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_KNOWLEDGE_ENGINE_URL}/knowledgebases`, account)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
