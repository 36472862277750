var render, staticRenderFns
import script from "./BaseModal.vue?vue&type=script&lang=js"
export * from "./BaseModal.vue?vue&type=script&lang=js"
import style0 from "./BaseModal.vue?vue&type=style&index=0&id=0584ef35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0584ef35",
  null
  
)

/* custom blocks */
import block0 from "./BaseModal.vue?vue&type=custom&index=0&blockType=slot&name=main"
if (typeof block0 === 'function') block0(component)

export default component.exports