// initial state
const state = {
  contactReason: null,
  contactReasons: null,
  agentContactReason: { // the required payload in case the agent choose to select a contact reason
    contact_reason: null,
    customer: null,
    room_event: null
  },
  agentContactReasons: [] // list of contact reasons the agent may choose in
}

// getters
const getters = {
  agentContactReason () {
    return state.agentContactReason
  },
  agentContactReasons () {
    return state.agentContactReasons
  },
  contactReason () {
    return state.contactReason
  },
  contactReasons () {
    return state.contactReasons
  }
}

// mutations
const mutations = {
  resetAgentContactReason (state) {
    state.agentContactReason = {
      contact_reason: null,
      customer: null,
      room_event: null
    }
  },
  resetAgentContactReasons (state) {
    state.agentContactReasons = []
  },
  setAgentContactReason (state, data) {
    state.agentContactReason = Object.assign(
      {}, state.agentContactReason, data)
  },
  setAgentContactReasons (state, data) {
    state.agentContactReasons = data
  },
  setContactReason (state, data) {
    state.contactReason = data
  },
  setContactReasons (state, data) {
    state.contactReasons = data
  },
  setRoom (state, uuid) {
    state.room = uuid
  },
  setRoomEvent (state, uuid) {
    state.roomEvent = uuid
  }
}

// actions
const actions = {
  deleteContactReason ({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$http.delete(`${process.env.VUE_APP_GBA_URL}/contactreasons/${id}`)
        // .then(response => response.json()).then((data) => {
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchContactReason ({ commit, dispatch }, uuid) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_GBA_URL}/contactreasons/${uuid}`)
        .then(response => response.json())
        .then((data) => {
          commit('setContactReason', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchContactReasons ({ commit, dispatch, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_GBA_URL}/contactreasons/`)
        .then(response => response.json())
        .then((data) => {
          commit('setContactReasons', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  resetAllAgentContactReasons ({ commit }) {
    commit('resetAgentContactReason')
    commit('resetAgentContactReasons')
  },
  saveContactReason ({ commit, dispatch }, data) {
    let url = `${process.env.VUE_APP_GBA_URL}/contactreasons/`
    if (data.id) {
      url += data.id
    }

    return new Promise((resolve, reject) => {
      this._vm.$http.post(url, data)
        .then(response => response.json())
        .then((data) => {
          dispatch('resetAllAgentContactReasons')
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  sendContactReason ({ commit, dispatch, getters }, contactReason) {
    const data = JSON.parse(JSON.stringify(getters.agentContactReason))
    data.contact_reason = contactReason
    const url = `${process.env.VUE_APP_GBA_URL}/contactreasons/save-event`
    return new Promise((resolve, reject) => {
      this._vm.$http.post(url, data)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  startAgentProcess ({ commit, dispatch, getters, rootGetters }, data) {
    if (data.contactReasons.length > 0) {
      commit('setAgentContactReasons', data.contactReasons)
      commit('setAgentContactReason', {
        customer: data.customer,
        room_event: data.roomEvent
      })
      dispatch('GBA/global/showModal', 'contact-reason', { root: true })
    } else {
      dispatch('GBA/global/showModal', 'closed-room', { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
