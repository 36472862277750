import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

import '@savoygu/vue-material-design-icons/src/icons/account_balance_wallet'
import '@savoygu/vue-material-design-icons/src/icons/adb'
import '@savoygu/vue-material-design-icons/src/icons/add'
import '@savoygu/vue-material-design-icons/src/icons/add_a_photo'
import '@savoygu/vue-material-design-icons/src/icons/add_circle'
import '@savoygu/vue-material-design-icons/src/icons/add_circle_outline'
import '@savoygu/vue-material-design-icons/src/icons/add_to_queue'
import '@savoygu/vue-material-design-icons/src/icons/adjust'
import '@savoygu/vue-material-design-icons/src/icons/apps'
import '@savoygu/vue-material-design-icons/src/icons/arrow_downward'
import '@savoygu/vue-material-design-icons/src/icons/arrow_upward'
import '@savoygu/vue-material-design-icons/src/icons/av_timer'
import '@savoygu/vue-material-design-icons/src/icons/build'
import '@savoygu/vue-material-design-icons/src/icons/call'
import '@savoygu/vue-material-design-icons/src/icons/call_split'
import '@savoygu/vue-material-design-icons/src/icons/contact_mail'
import '@savoygu/vue-material-design-icons/src/icons/chat'
import '@savoygu/vue-material-design-icons/src/icons/check_circle'
import '@savoygu/vue-material-design-icons/src/icons/chevron_left'
import '@savoygu/vue-material-design-icons/src/icons/chevron_right'
import '@savoygu/vue-material-design-icons/src/icons/close'
import '@savoygu/vue-material-design-icons/src/icons/code'
import '@savoygu/vue-material-design-icons/src/icons/content_copy'
import '@savoygu/vue-material-design-icons/src/icons/content_paste'
import '@savoygu/vue-material-design-icons/src/icons/create_new_folder'
import '@savoygu/vue-material-design-icons/src/icons/delete'
import '@savoygu/vue-material-design-icons/src/icons/description'
import '@savoygu/vue-material-design-icons/src/icons/device_hub'
import '@savoygu/vue-material-design-icons/src/icons/dns'
import '@savoygu/vue-material-design-icons/src/icons/done_all'
import '@savoygu/vue-material-design-icons/src/icons/done'
import '@savoygu/vue-material-design-icons/src/icons/edit'
import '@savoygu/vue-material-design-icons/src/icons/email'
import '@savoygu/vue-material-design-icons/src/icons/error_outline'
import '@savoygu/vue-material-design-icons/src/icons/exit_to_app'
import '@savoygu/vue-material-design-icons/src/icons/file_download'
import '@savoygu/vue-material-design-icons/src/icons/filter_list'
import '@savoygu/vue-material-design-icons/src/icons/flag'
import '@savoygu/vue-material-design-icons/src/icons/folder_special'
import '@savoygu/vue-material-design-icons/src/icons/format_paint'
import '@savoygu/vue-material-design-icons/src/icons/get_app'
import '@savoygu/vue-material-design-icons/src/icons/group'
import '@savoygu/vue-material-design-icons/src/icons/headset_mic'
import '@savoygu/vue-material-design-icons/src/icons/healing'
import '@savoygu/vue-material-design-icons/src/icons/help_outline'
import '@savoygu/vue-material-design-icons/src/icons/home'
import '@savoygu/vue-material-design-icons/src/icons/hourglass_empty'
import '@savoygu/vue-material-design-icons/src/icons/image'
import '@savoygu/vue-material-design-icons/src/icons/import_contacts'
import '@savoygu/vue-material-design-icons/src/icons/insert_chart'
import '@savoygu/vue-material-design-icons/src/icons/insert_emoticon'
import '@savoygu/vue-material-design-icons/src/icons/keyboard'
import '@savoygu/vue-material-design-icons/src/icons/label'
import '@savoygu/vue-material-design-icons/src/icons/language'
import '@savoygu/vue-material-design-icons/src/icons/launch'
import '@savoygu/vue-material-design-icons/src/icons/lens'
import '@savoygu/vue-material-design-icons/src/icons/link'
import '@savoygu/vue-material-design-icons/src/icons/list'
import '@savoygu/vue-material-design-icons/src/icons/local_offer'
import '@savoygu/vue-material-design-icons/src/icons/lock_open'
import '@savoygu/vue-material-design-icons/src/icons/lock'
import '@savoygu/vue-material-design-icons/src/icons/mail_outline'
import '@savoygu/vue-material-design-icons/src/icons/menu'
import '@savoygu/vue-material-design-icons/src/icons/mode_comment'
import '@savoygu/vue-material-design-icons/src/icons/more_vert'
import '@savoygu/vue-material-design-icons/src/icons/open_in_new'
import '@savoygu/vue-material-design-icons/src/icons/pause'
import '@savoygu/vue-material-design-icons/src/icons/payment'
import '@savoygu/vue-material-design-icons/src/icons/people'
import '@savoygu/vue-material-design-icons/src/icons/perm_contact_calendar'
import '@savoygu/vue-material-design-icons/src/icons/perm_data_setting'
import '@savoygu/vue-material-design-icons/src/icons/perm_phone_msg'
import '@savoygu/vue-material-design-icons/src/icons/person'
import '@savoygu/vue-material-design-icons/src/icons/phone'
import '@savoygu/vue-material-design-icons/src/icons/pin_drop'
import '@savoygu/vue-material-design-icons/src/icons/playlist_add_check'
import '@savoygu/vue-material-design-icons/src/icons/poll'
import '@savoygu/vue-material-design-icons/src/icons/web_asset'
import '@savoygu/vue-material-design-icons/src/icons/question_answer'
import '@savoygu/vue-material-design-icons/src/icons/redo'
import '@savoygu/vue-material-design-icons/src/icons/refresh'
import '@savoygu/vue-material-design-icons/src/icons/remove'
import '@savoygu/vue-material-design-icons/src/icons/remove_circle_outline'
import '@savoygu/vue-material-design-icons/src/icons/repeat'
import '@savoygu/vue-material-design-icons/src/icons/search'
import '@savoygu/vue-material-design-icons/src/icons/send'
import '@savoygu/vue-material-design-icons/src/icons/sentiment_very_satisfied'
import '@savoygu/vue-material-design-icons/src/icons/settings'
import '@savoygu/vue-material-design-icons/src/icons/settings_ethernet'
import '@savoygu/vue-material-design-icons/src/icons/settings_phone'
import '@savoygu/vue-material-design-icons/src/icons/sms'
import '@savoygu/vue-material-design-icons/src/icons/sort'
import '@savoygu/vue-material-design-icons/src/icons/star'
import '@savoygu/vue-material-design-icons/src/icons/star_half'
import '@savoygu/vue-material-design-icons/src/icons/subdirectory_arrow_right'
import '@savoygu/vue-material-design-icons/src/icons/swap_vert'
import '@savoygu/vue-material-design-icons/src/icons/textsms'
import '@savoygu/vue-material-design-icons/src/icons/undo'
import '@savoygu/vue-material-design-icons/src/icons/view_list'
import '@savoygu/vue-material-design-icons/src/icons/visibility_off'
import '@savoygu/vue-material-design-icons/src/icons/visibility'
import '@savoygu/vue-material-design-icons/src/icons/warning'
import '@savoygu/vue-material-design-icons/src/icons/web'
import '@savoygu/vue-material-design-icons/src/icons/widgets'
import '@savoygu/vue-material-design-icons/src/icons/zoom_in'
import '@savoygu/vue-material-design-icons/src/icons/zoom_out'

Icon.register({
  mobile_friendly: {
    width: 23.868,
    height: 26,
    d: 'M19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM7.01 13.47l-2.55-2.55-1.27 1.27L7 16l7.19-7.19-1.27-1.27-5.91 5.93z'
  }
})
Icon.register({
  send_to_mobile: {
    width: 23.868,
    height: 26,
    d: 'M18,8l4,4l-4,4l-1.41-1.41L18.17,13H13v-2h5.17l-1.59-1.59L18,8z M7,1.01L17,1c1.1,0,2,0.9,2,2v4h-2V6H7v12h10v-1h2v4 c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V3C5,1.9,5.9,1.01,7,1.01z M7,21h10v-1H7V21z M7,4h10V3H7V4z'
  }
})
