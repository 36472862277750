<template>
  <div v-on:click="hide"></div>
</template>

<script type="text/javascript">
export default {
  name: 'Overlay',
  methods: {
    hide () {
      this.$store.commit('global/hideOverlay')
      // this.$store.commit('global/hideRoomMenu')
      this.$store.commit('global/hideUserMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  background: #fff;
  height: 100vh;
  left: 0px;
  opacity: .2;
  filter: alpha(opacity=20);
  position: fixed;
  text-align: center;
  top: 0px;
  width: 100%;
  z-index: 9;
}
</style>
