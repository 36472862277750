<template v-slot="main">
  <div class="modal-container">
    <div class="modal-body" style="color: red">
      <h3 class="gb-title err" v-if="errMsg.title">
        <!-- TODO: add errors to i18n.js -->
        <icon :name="errMsg.icon ? errMsg.icon : 'warning'"/><!--
        -->{{ errMsg.title }}<br><br>
      </h3>
      <pre v-if="errMsg.body" :class="{ prewrap: isJsonString }">{{ errMsg.body }}</pre>
    </div>

    <div class="modal-footer">
      <button v-on:click="validate" class="gb-btn">
        {{ $t('OK') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  extends: BaseModal,
  name: 'ErrorMsgModal',
  components: { Icon },
  computed: {
    errMsg () {
      const regex = /\s/gmi
      let msg = null

      if (this.$store.getters['global/getError']) {
        msg = this.$store.getters['global/getError']
      } else if (this.$store.hasModule('GBA') && this.$store.getters['GBA/global/getError']) {
        msg = this.$store.getters['GBA/global/getError']
      } else if (this.$store.getters['botBuilder/getError']) {
        msg = this.$store.getters['botBuilder/getError']
      }

      const data = {
        body: '',
        icon: '',
        title: ''
      }

      if (msg !== null && typeof msg === 'object') {
        data.body = this.$te(msg.body)
          ? this.$t(msg.body)
          : msg.body
        data.icon = msg.icon
        if (msg.title) {
          data.title = this.$t(msg.title.replace(regex, '_'))
        }
      } else {
        data.body = this.$t(msg)
      }

      return data
    },
    isJsonString () {
      try {
        JSON.parse(this.errMsg.body)
      } catch (e) {
        return false
      }
      return true
    }
  },
  methods: {
    validate () {
      this.$store.commit('global/updateError', null)
      this.$store.commit('GBA/global/updateError', null)
      this.$store.commit('botBuilder/setError', null)
      this.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

.modal-container {
  .gb-title.err {
    color: $secondary;
  }

  pre {
    color: $content;
    font-family: inherit;
    font-size: .8rem;
    max-height: 70vh;
    overflow: auto;
    white-space: inherit;

    &:first-child {
      color: $secondary;
      margin-top: 2rem;
    }

    &.prewrap {
      white-space: pre-wrap;
      text-align: left;
    }
  }
}
</style>
