// initial state
const state = {
  filters: null,
  searching: false
}

// getters
const getters = {
  filters () {
    return state.filters
  },
  isSearching () {
    return state.searching
  }
}

// actions
const actions = {
  fetchRooms ({ commit, dispatch, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      // data.order_by = (rootGetters['GBA/rooms/isListSortAsc']('search') ? '' : '-') + 'created'
      // // TODO WHEN IT'LL BE REQUIRED: Move sort management from backend to frontend and sort by sub search status

      dispatch('guests/searchSessions', {
        ...data,
        offset: data.offset
      }, { root: true })
        .then(results => {
          const tmp = {
            from_room: data.offset || null,
            rooms: results.sessions.map(session => {
              const agentName = (session.agent?.full_name || '').split(' ')
              return {
                ...session,
                agent: {
                  ...session.agent,
                  first_name: agentName[0],
                  last_name: agentName[1],
                  uuid: session.agent?.id
                },
                agent_transferred: session.transferred_agent,
                auto_closed: session.state === 'auto_closed',
                created: new Date(session.created_at).getTime() / 1000,
                infos: {
                  full_name: session.guest_full_name || session.full_name,
                  name: rootGetters['GBA/rooms/getLabelIdFromRoom'](session),
                  origin: session.origin,
                  username: session.guest_username || session.username
                },
                last_message: session.last_message?.rich_content || [],
                last_message_kind: session.last_message?.rich_content.kind,
                last_message_timestamp: new Date(session.last_message?.created_at).getTime() / 1000,
                session_guest_idle: session.guest_idle,
                session_id: session.id,
                skill_alert_short_late: session.skill.alert_short_late,
                skill_id: session.skill.id,
                skill_label: session.skill.label,
                status: session.state === 'auto_closed' ? 'closed' : session.state,
                unread_msg: session.unread_message_count,
                uuid: session.id
              }
            }),
            number_of_rooms: results.total_sessions,
            paging_end: !results.has_more
          }
          commit('updateSearching', true)
          commit('GBA/rooms/updateList2', { data: tmp }, { root: true })
          resolve(tmp)
        })
        .catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
          throw err
        })

      // this._vm.$http.post(`${process.env.VUE_APP_GBA_URL}/rooms/search`, data)
      //   .then(response => response.json())
      //   .then((data) => {
      //     commit('updateSearching', true)
      //     if (process.env.VUE_APP_SAAS !== undefined) {
      //       // commit('GBA/rooms/updateList2', { data }, { root: true })
      //     } else {
      //       // TO REMOVE WITH STANDALONE
      //       commit('GBA/rooms/updateList', { data }, { root: true })
      //     }
      //     // resolve(data)
      //   }).catch((err) => {
      //     dispatch('global/handleHttpError', err, { root: true })
      //     reject(err)
      //   })
    })
  }
}

// mutations
const mutations = {
  filters (state, filters) {
    state.filters = filters
  },
  updateSearching (state, data) {
    state.searching = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
