<template>
  <div class="user-stats" v-if="headerStats">
    <div>
      <h3>{{ $t('user_stats.guest_comments') }}</h3>
      <span>{{ headerStats.guests_comments }}</span>
    </div>
    <div>
      <h3>{{ $t('user_stats.customer_comments') }}</h3>
      <span>{{ headerStats.customer_comments }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThreadsStats',
  data () {
    return {
      itv: null
    }
  },
  computed: {
    headerStats () {
      return this.$store.getters['threads/headerStats']
    }
  },
  created () {
    this.$store.dispatch('threads/fetchHeaderStats')
    this.itv = window.setInterval(() => {
      this.$store.dispatch('threads/fetchHeaderStats')
    }, 5 * 60 * 1000)
  },
  beforeDestroy () {
    if (this.itv !== null) clearInterval(this.itv)
  }
}
</script>
