import { Commit, Dispatch } from 'vuex'
import { escapeRegExp } from 'lodash'

import { WebIVR } from '@/types/webivr/main'
import {
  WebivrBuilderClipboard,
  WebivrBuilderSnapshotState,
  WebivrBuilderNode,
  WebivrBuilderState,
  WebivrBuilderTree,
  WebivrBuilderVersion
} from '@/types/webivr/builder'

// initial state
const state: WebivrBuilderState = {
  account: null,
  actions: [
    'uri',
    'uri__tel',
    'uri__mail',
    'text',
    'callback',
    'messaging',
    'faq',
    'form',
    'choice',
    'routing',
    'to_tree',
    'api',
    'page'
  ],
  availableActions: [
    'uri',
    'uri__tel',
    'uri__mail',
    'callback',
    'messaging',
    'faq',
    'form',
    'form_output',
    'choice',
    'routing',
    'routing_output',
    'text',
    'to_tree',
    'page'
  ],
  clipboard: null,
  currentVersion: null,
  hasNextSnapshot: false,
  hasPrevSnapshot: false,
  nodes: null,
  nodesUnvalidated: null,
  snapshotExpiresAt: null,
  treeSearch: '',
  trees: null
}

// getters
const getters = {
  account (state: WebivrBuilderState): WebIVR | null {
    return state.account
  },
  actions (state: WebivrBuilderState): Array<string> {
    return state.actions
  },
  availableActions (state: WebivrBuilderState, _getters: any, _rootState_: any, rootGetters: any): Array<string> {
    const availableActions = JSON.parse(JSON.stringify(state.availableActions))
    const user = rootGetters['user/user']
    const userSkills = user.permissions.skills
    const customer = rootGetters['user/customer']
    const settings = rootGetters['global/settings']
    const isManager = settings.permissions[user.user_role] >= settings.permissions.manager
    const skills = rootGetters['skills/skills']
    let foundWcb = false
    let foundMsg = false

    const hasCallbackService: boolean = isManager && customer.services.some(
      (service: Record<string, string>) => service.kind === 'callback')
    const hasMessagingService: boolean = isManager && customer.services.some(
      (service: Record<string, string>) => service.kind === 'bot')

    if ((hasCallbackService || hasMessagingService) && skills) {
      skills.forEach((skill: Record<string, string | Array<Record<string, string>>>) => {
        if (hasCallbackService && foundWcb === false && state.account && skill.id === state.account.skill &&
          skill.services && typeof skill.services !== 'string') {
          skill.services.forEach((service: Record<string, string>) => {
            if (foundWcb === false && service.kind === 'callback' &&
              userSkills.find((us: Record<string, string>) => us.id === skill.id)) {
              foundWcb = true
            }
          })
        }
        if (hasMessagingService && foundMsg === false && state.account && skill.id === state.account.skill &&
          skill.services && typeof skill.services !== 'string') {
          skill.services.forEach((service: Record<string, string>) => {
            if (foundMsg === false && service.kind === 'bot' &&
              userSkills.find((us: Record<string, string>) => us.id === skill.id)) {
              foundMsg = true
            }
          })
        }
      })
    }

    if (!foundWcb) {
      availableActions.splice(availableActions.indexOf('callback'), 1)
    }
    if (!foundMsg) {
      availableActions.splice(availableActions.indexOf('messaging'), 1)
    }
    return availableActions
  },
  clipboard (state: WebivrBuilderState): WebivrBuilderClipboard | null {
    return state.clipboard
  },
  hasNextSnapshot (state: WebivrBuilderState): boolean {
    return state.hasNextSnapshot
  },
  hasPrevSnapshot (state: WebivrBuilderState): boolean {
    return state.hasPrevSnapshot
  },
  isWorkingVersion (state: WebivrBuilderState): boolean {
    return state.account !== null &&
      state.currentVersion !== null &&
      state.account.working_version === state.currentVersion
  },
  nodes (state: WebivrBuilderState): Array<WebivrBuilderNode> | null {
    return state.nodes
  },
  nodesUnvalidated (state: WebivrBuilderState): Array<WebivrBuilderNode> | null {
    return state.nodesUnvalidated
      ? state.nodesUnvalidated
        .concat([])
        .sort((a: WebivrBuilderNode, b: WebivrBuilderNode): number => {
          return a.tree.localeCompare(b.tree)
        })
      : null
  },
  snapshotExpiresAt (state: WebivrBuilderState): string | null {
    return state.snapshotExpiresAt
  },
  treeSearch (state: WebivrBuilderState): string {
    return state.treeSearch
  },
  trees (state: WebivrBuilderState): Array<WebivrBuilderTree> | null {
    return state.trees
  }
}

// mutations
const mutations = {
  account (state: WebivrBuilderState, value: WebIVR | null): void {
    state.account = value
  },
  accountProductionVersion (state: WebivrBuilderState, value: string): void {
    (this as any)._vm.$set(state.account, 'production_version', value)
  },
  clipboard (state: WebivrBuilderState, value: WebivrBuilderClipboard | null): void {
    state.clipboard = value
  },
  currentVersion (state: WebivrBuilderState, value: string | null): void {
    state.currentVersion = value
  },
  deleteTreeInList (state: WebivrBuilderState, value: string): void {
    const trees = state.trees as Array<WebivrBuilderTree>
    const index = trees.findIndex(tree => tree.id === value)

    if (index >= 0) {
      trees.splice(index, 1)
    }
  },
  nodes (state: WebivrBuilderState, value: Array<WebivrBuilderNode> | null): void {
    state.nodes = value
  },
  nodesUnvalidated (state: WebivrBuilderState, value: Array<WebivrBuilderNode> | null): void {
    state.nodesUnvalidated = value
  },
  // eslint-disable-next-line camelcase
  snapshotStates (state: WebivrBuilderState, states: WebivrBuilderSnapshotState): void {
    state.hasNextSnapshot = states.has_next
    state.hasPrevSnapshot = states.has_previous
    state.snapshotExpiresAt = states.expires_at
  },
  trees (state: WebivrBuilderState, value: Array<WebivrBuilderTree> | null): void {
    state.trees = value
  },
  treeSearch (state: WebivrBuilderState, value: string): void {
    state.treeSearch = value
  },
  updateNodeUnvalidatedInList (state: WebivrBuilderState, value: WebivrBuilderNode): void {
    const nodes = state.nodesUnvalidated as Array<WebivrBuilderNode>
    const index = nodes.findIndex(node => node.id === value.id)

    if (index >= 0) {
      if (value.validated) {
        nodes.splice(index, 1)
      } else {
        nodes.splice(index, 1, JSON.parse(JSON.stringify(value)))
      }
    } else if (!value.validated) {
      nodes.unshift(JSON.parse(JSON.stringify(value)))
    }
  },
  updateTreeInList (state: WebivrBuilderState, value: WebivrBuilderTree): void {
    const trees = state.trees as Array<WebivrBuilderTree>
    if (trees) {
      const index = trees.findIndex(tree => tree.id === value.id)

      if (index >= 0) {
        trees.splice(index, 1, value)
      } else {
        trees.push(value)
      }
    }
  }
}

// actions
const actions = {
  addNode ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, payload: Record<string, any>, treeId: string}): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}/nodes`, data.payload)
        .then((response: Response) => response.json())
        .then((node: WebivrBuilderNode) => {
          commit('updateNodeUnvalidatedInList', node)
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(node)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  addTree ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, label: string, preventUpdate?: boolean }): Promise<WebivrBuilderTree> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees`, {
        label: data.label
      }).then((response: Response) => response.json())
        .then((tree: WebivrBuilderTree) => {
          if (!data.preventUpdate) {
            commit('updateTreeInList', tree)
            dispatch('fetchNodesUnvalidated', data.accountId)
            dispatch('fetchSnapshotStates', data.accountId)
          }
          resolve(tree)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  clear ({ commit }: { commit: Commit }): void {
    commit('account', null)
    commit('nodes', null)
    commit('nodesUnvalidated', null)
    commit('trees', null)
  },
  deleteNode ({ dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, nodeId: string, treeId: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}/nodes/${data.nodeId}`)
        .then(() => {
          dispatch('fetchNodesUnvalidated', data.accountId)
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(data.nodeId)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteTree ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, treeId: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}`)
        .then(() => {
          commit('deleteTreeInList', data.treeId)
          dispatch('fetchNodesUnvalidated', data.accountId)
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(data.treeId)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  duplicateTree (
    { commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any },
    { srcAccountId, srcTreeId }: { srcAccountId: string, srcTreeId: string }
  ): Promise<WebivrBuilderTree> {
    const srcTree = getters.trees.find((tree: WebivrBuilderTree) => tree.id === srcTreeId)
    const regex = new RegExp('^' + escapeRegExp(srcTree.label) + '( \\(\\d+\\))?$')
    const count = getters.trees
      .reduce((count: number, tree: WebivrBuilderTree) => {
        if (regex.test(tree.label)) {
          count++
        }
        return count
      }, 0)

    return new Promise((resolve, reject) => {
      dispatch('addTree', {
        accountId: srcAccountId,
        label: srcTree.label + ` (${count})`,
        preventUpdate: true
      })
        .then((tree: WebivrBuilderTree) =>
          new Promise((resolve: (value: WebivrBuilderTree) => void, reject) => {
            dispatch('pasteNodes', {
              replace: true,
              srcAccountId,
              srcNodeId: srcTree.root_node,
              targetAccountId: srcAccountId,
              targetNodeId: tree.root_node
            })
              .then((nodes: Array<WebivrBuilderNode>) => {
                const newTree = {
                  ...tree,
                  root_node: nodes[0].id
                }
                commit('updateTreeInList', newTree)
                dispatch('fetchNodesUnvalidated', srcAccountId)
                dispatch('fetchSnapshotStates', srcAccountId)
                resolve(newTree)
              })
              .catch(reject)
          })
        )
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccount ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<WebIVR> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((account: WebIVR) => {
          commit('account', account)
          resolve(account)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchNodes ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, treeId: string }): Promise<Array<WebivrBuilderNode>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}/nodes`)
        .then((response: Response) => response.json())
        .then((nodes: Array<WebivrBuilderNode>) => {
          commit('nodes', nodes)
          resolve(nodes)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchNodesUnvalidated ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, accountId: string): Promise<Array<WebivrBuilderNode>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${accountId}/nodes?validated=false`)
        .then((response: Response) => response.json())
        .then((nodes: Array<WebivrBuilderNode>) => {
          commit('nodesUnvalidated', nodes)
          resolve(nodes)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSnapshotStates ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, accountId: string): Promise<WebivrBuilderSnapshotState> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${accountId}/snapshots/state`)
        .then((response: Response) => response.json())
        .then((data: WebivrBuilderSnapshotState) => {
          commit('snapshotStates', data)
          resolve(data)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchTrees ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, versionId?: string }): Promise<Array<WebivrBuilderTree>> {
    let url = `${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees`

    if (data.versionId) {
      url += `?version=${data.versionId}`
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(url)
        .then((response: Response) => response.json())
        .then((trees: Array<WebivrBuilderTree>) => {
          commit('trees', trees)
          resolve(trees)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  moveNode ({ dispatch }: { dispatch: Dispatch }, data: { accountId: string, payload: Array<{ children: Array<string>, node: string }>, treeId: string}): Promise<WebivrBuilderNode> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}/nodes/children`, data.payload)
        .then((response: Response) => response.json())
        .then((node: WebivrBuilderNode) => {
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(node)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  pasteNodes ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: {
    replace?: boolean,
    srcAccountId: string,
    srcNodeId: string
    targetAccountId: string,
    targetNodeId: string
  }): Promise<Array<WebivrBuilderNode>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.targetAccountId}/nodes/${data.targetNodeId}/paste`, {
        account_id: data.srcAccountId,
        node_id: data.srcNodeId,
        replace: data.replace || false
      })
        .then((response: Response) => response.json())
        .then((nodes: Array<WebivrBuilderNode>) => {
          nodes.forEach(node => {
            commit('updateNodeUnvalidatedInList', node)
          })
          resolve(nodes)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  publish ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: Record<string, string>): Promise<WebivrBuilderVersion> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.account}/publish`, {
        label: data.label,
        comment: ''
      })
        .then((response: Response) => response.json())
        .then((version: WebivrBuilderVersion) => {
          commit('accountProductionVersion', version.id)
          resolve(version)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveAccount ({ dispatch }: { dispatch: Dispatch }, data: WebIVR): Promise<WebIVR> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.id}`, data)
        .then((response: Response) => response.json())
        .then((data: WebIVR) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveNode ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, treeId: string, nodeId: string, payload: WebivrBuilderNode }): Promise<WebivrBuilderNode> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}/nodes/${data.nodeId}`, data.payload)
        .then((response: Response) => response.json())
        .then((node: WebivrBuilderNode) => {
          commit('updateNodeUnvalidatedInList', node)
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(node)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveTree ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, treeId: string, label: string }): Promise<WebivrBuilderTree> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/trees/${data.treeId}`, {
        label: data.label
      }).then((response: Response) => response.json())
        .then((tree: WebivrBuilderTree) => {
          commit('updateTreeInList', tree)
          dispatch('fetchSnapshotStates', data.accountId)
          resolve(tree)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  setSnapshot ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, data: { accountId: string, undo: boolean }): Promise<WebivrBuilderSnapshotState> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(
        `${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/snapshots/apply`,
        { mode: data.undo ? 'previous' : 'next' }
      )
        .then((response: Response) => response.json())
        .then((data: WebivrBuilderSnapshotState) => {
          commit('snapshotStates', data)
          resolve(data)
        })
        .catch((err: Response) => {
          if ((err.body as any).errors?.[0] === 'no_next_snapshot_found') {
            commit('snapshotStates', { has_next: false, has_previous: getters.hasPrevSnapshot })
          } else if ((err.body as any).errors?.[0] === 'no_previous_snapshot_found') {
            commit('snapshotStates', { has_previous: false, has_next: getters.hasNextSnapshot })
          }

          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
