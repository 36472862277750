<template>
  <div class="breadcrumb">
    <div v-if="mainPage">
      <h1><router-link :to="mainPage">{{ getName(mainPage) }}</router-link></h1>
      <ul v-if="otherPages.length">
        <li :key="page.name" v-for="page in otherPages">
          <icon name="chevron_right"/>
          <router-link :to="page" class="link">{{ getName(page) }}</router-link>
          <icon name="chevron_right"/>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  components: { Icon },
  computed: {
    mainPage () {
      if (this.pages[0].meta.customRoute) {
        return this.$router.options.routes.find(
          route => route.name === this.pages[0].meta.customRoute)
      }
      return this.pages[0]
    },
    otherPages () {
      const pages = []
      this.pages.slice(1).forEach(page => {
        if (page.meta.abstract !== true) pages.push(page)
      })
      return pages
    },
    pages () {
      return this.$route.matched
    }
  },
  methods: {
    getName (page) {
      return page.meta.name || this.$t('menu.' + page.name)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/global.scss";

  .breadcrumb {
    overflow: hidden;
  }

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    font-family: Poppins;
    margin: 0;

    a {
      color: $content;
    }
  }

  .link {
    font-size: 12px;
    color: $content-light;
    font-family: 'Be Vietnam';
  }

  ul {
    margin: -5px 0 0 0;
    padding: 0;
  }

  li {
    font-size: 0;
  }

  li, li > * {
    display: inline-block;

    .material-design-icon {
      vertical-align: bottom;
      color: $grey-dark;
      margin: 0 6px;
      font-size: 16px;

      &:first-child {
        display: none;
      }
    }
  }

  li {
    &:last-child .material-design-icon:last-child {
      display: none;
    }

    &:not(:first-child) .material-design-icon:first-child {
      display: none;
    }
  }
</style>
