import Vue from 'vue'
import $settings from '@/settings'

// initial state
const state = {
  agents: null,
  currAgent: null,
  customer: null,
  hasConfirmedAgentDeletion: null,
  lang: null,
  roomsSettings: null,
  token: null,
  user: null
}

// getters
const getters = {
  agents () {
    return state.agents
  },
  currAgent () {
    return state.currAgent
  },
  customer () {
    return state.customer
  },
  hasConfirmedAgentDeletion () {
    return state.hasConfirmedAgentDeletion
  },
  hasAppSumoOffer (_state, getters) {
    return getters.hasFeaturePerm('is_appsumo')
  },
  hasFeaturePerm () {
    return perm => {
      return state.customer !== null
        ? state.customer.offer_desc[perm] === true
        : false
    }
  },
  hasSubAccounts () {
    return $settings.saas === true &&
      state.customer !== null &&
      state.customer.offer_desc.max_skills &&
      state.customer.offer_desc.max_skills > 1
  },
  lang () {
    return state.lang
  },
  roomsSettings (state) {
    return state.roomsSettings
  },
  token () {
    return state.token
  },
  user () {
    return state.user
  },
  userAccountDelete () {
    return state.userAccountDelete
  }
}

// actions
const actions = {
  chooseOffer: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/offers/send/`
      Vue.http.post(url, data)
        .then(response => response.json()).then(() => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  deleteAgent: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/agents/${data.pk}/`
      Vue.http.delete(url)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchAgentSkillsLabels: ({ dispatch }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/agents/${id}/skills/labels`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAgents: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/agents/`)
        .then(response => response.json())
        .then((data) => {
          commit('setAgents', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchCustomer: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/customer/`)
        .then(response => response.json())
        .then((data) => {
          commit('setCustomer', data)
          dispatch('checkCustomerQuota')
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchRoomsSettings: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_GBA_URL}/customers/room-configuration`)
        .then(response => response.json())
        .then((data) => {
          commit('updateRoomsSettings', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchUser: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/user/account`)
        .then(response => response.json())
        .then((data) => {
          if (getters.token() !== null) commit('setUser', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  login: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/user/login`
      Vue.http.post(url, data)
        .then(response => response.json())
        .then((data) => {
          commit('token', data.token)
          commit('GBA/user/updateToken', data.token, { root: true })
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  loginSSO: (_, data) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_SSO_URL}/saml/login`, {
        params: {
          'no-redirect': true,
          ...data
        }
      })
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  logout: ({ dispatch, rootGetters }, prevent) => {
    const logout = () => {
      localStorage.removeItem('token')
      localStorage.removeItem('GBAtoken')
      if (prevent !== true) {
        // TODO: fix login redirect with all required params
        // const iframeSrc = rootGetters['global/iframeSrc']
        // const isNavEnabled = rootGetters['global/isNavEnabled']
        // const isNavGbaEnabled = rootGetters['global/isNavGbaEnabled']
        // window.location.href = `/login?redirect=${iframeSrc || ''}&nav=${isNavEnabled}&nav-gba=${isNavGbaEnabled}`

        const proto = window.location.protocol
        const host = window.location.host
        window.location.href = '/logout'
      }
    }

    if (rootGetters['GBA/user/isUserAutofill']) {
      dispatch('GBA/user/sendAutofill', false, { root: true })
        .then(logout)
    } else {
      logout()
    }
  },
  logoutUser: ({ dispatch }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_GBA_URL}/agents/logout`, {
        agent: id
      }).then(() => {
        resolve()
      }).catch((err) => {
        dispatch('global/handleHttpError', err, { root: true })
        reject(err)
      })
    })
  },
  pauseUser: ({ dispatch }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_GBA_URL}/agents/pause`, {
        agent: id,
        paused: true
      }).then(() => {
        resolve()
      }).catch((err) => {
        dispatch('global/handleHttpError', err, { root: true })
        reject(err)
      })
    })
  },
  passwordChange: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/password/change`
      Vue.http.post(url, data)
        .then(response => response.json())
        .then(data => {
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
    })
  },
  refresh: ({ commit }) => {
    const url = `${process.env.VUE_APP_BACKEND_URL}/user/token/refresh`
    Vue.http.post(url)
      .then(response => response.json()).then((data) => {
        commit('token', data.token)
      })
  },
  register: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/register`
      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  registerAppsumo: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/appsumo/register`
      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  resendInvitationToAgent: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/agents/${data.pk}/`
      Vue.http.put(url)
        .then(() => {
          resolve('true')
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveAgent: (_, data) => {
    return new Promise((resolve, reject) => {
      let url
      if (data.pk) {
        url = `${process.env.VUE_APP_BACKEND_URL}/agents/${data.pk}/`
      } else {
        url = `${process.env.VUE_APP_BACKEND_URL}/agents/`
      }

      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveCustomer: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/customer/`
      Vue.http.post(url, data)
        .then(response => response.json()).then((d) => {
          commit('GBA/user/updateAvatar', data.logo, { root: true })
          dispatch('GBA/user/sendSettings', {}, { root: true })
          dispatch('fetchCustomer').finally(() => {
            resolve(d)
          })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveRoomsSettings: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_GBA_URL}/customers/room-configuration`, data)
        .then(response => response.json())
        .then((data) => {
          commit('updateRoomsSettings', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveUser: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/user/account`
      Vue.http.post(url, data)
        .then(response => response.json()).then(() => {
          commit('GBA/user/updateUser', { data }, { root: true })
          dispatch('GBA/user/sendSettings', {}, { root: true })
          dispatch('fetchUser').finally(() => {
            resolve(data)
          })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  sendEmailValidate: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/email/send-validate`
      Vue.http.post(url, data)
        .then(response => response.json()).then(() => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  settingsPasswordChange: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/user/password`
      Vue.http.post(url, data)
        .then(response => response.json())
        .then(data => {
          resolve(data)
        }).catch((err) => {
          reject(err)
        })
    })
  },
  fetchLang ({ commit, dispatch, state }) {
    if (state.user !== null) {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/user/language`)
        .then(response => response.json())
        .then(data => {
          commit('updateLang', data.language)
        })
        .catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
        })
    }
  },
  updateLang ({ commit, dispatch, state }, lang) {
    localStorage.setItem('chayall_lang', lang)
    if (state.user !== null) {
      Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/user/language`, {
        language: lang
      })
        .then(() => {
          commit('updateLang', lang)
        })
        .catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
        })
    }
  },
  checkCustomerQuota ({ dispatch, rootGetters }) {
    const customer = rootGetters['user/customer']
    if (customer !== null) {
      dispatch('GBA/user/updateUserLock', customer.over_quota, { root: true })
    }
  }
}

// mutations
const mutations = {
  setAgents (state, data) {
    state.agents = data
  },
  setConfirmAgentDeletion (state, value) {
    state.hasConfirmedAgentDeletion = value
  },
  setCustomer (state, data) {
    // remove STRIPE_CUSTOMER_ID for DEBUG
    // console.log('REMOVE STRIPE_CUSTOMER_ID FOR DEBUG')
    // data.stripe_customer_id = null
    state.customer = data
  },
  setUser (state, data) {
    state.user = data
  },
  token (state, value) {
    state.token = value
    localStorage.setItem('token', value)
  },
  updateLang (state, value) {
    state.lang = value
  },
  updateRoomsSettings (state, data) {
    state.roomsSettings = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
