import Vue from 'vue'
import Vuex from 'vuex'

import botBuilder from './modules/botBuilder'
import botgroups from './modules/botgroups'
import calendar from './modules/calendar'
import campaigns from './modules/campaigns/index'
import channels from './modules/channels'
import dialogflow from './modules/dialogflow'
import externalViews from './modules/externalViews'
import global from './modules/global'
import groups from './modules/groups'
import guests from './modules/guests'
import knowledgeBases from './modules/knowledgeBases'
import messengerMenus from './modules/messengerMenus'
import msglib from './modules/msglib'
import offers from './modules/offers'
import payment from './modules/payment'
import publicApi from './modules/publicApi'
import pushNotifications from './modules/pushNotifications'
import ravana from './modules/ravana'
import reviews from './modules/reviews'
import roomDetails from './modules/roomDetails'
import scheduledExports from './modules/scheduledExports'
import skills from './modules/skills'
import smartcoaching from './modules/smartcoaching'
import stats from './modules/stats'
import statsReviews from './modules/statsReviews'
import surveys from './modules/surveys'
import thirdParties from './modules/thirdParties'
import translate from './modules/translate'
import user from './modules/user'
import webivr from './modules/webivr/index'
import websocket from './modules/websocket'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    botBuilder,
    botgroups,
    calendar,
    campaigns: {
      namespaced: true,
      modules: campaigns
    },
    channels,
    dialogflow,
    thirdParties,
    externalViews,
    global,
    groups,
    guests,
    knowledgeBases,
    messengerMenus,
    msglib,
    offers,
    payment,
    pushNotifications,
    ravana,
    reviews,
    roomDetails,
    scheduledExports,
    skills,
    smartcoaching,
    stats,
    statsReviews,
    surveys,
    publicApi,
    translate,
    user,
    webivr: {
      namespaced: true,
      modules: webivr
    },
    websocket
  },
  strict: debug
})

export default store
