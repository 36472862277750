import Vue from 'vue'
import { Commit, Dispatch } from 'vuex'

import { GbContacts, GbContactsImport, GbContactsList, GbState } from '@/types/campaigns/contacts'

const state: GbState = {
  contactsLists: null
}

const getters = {
  getContactsLists (state: GbState): Array<GbContactsList> | null {
    return state.contactsLists
  },
  getContactsListById: (state: GbState) => (id: string): GbContactsList | undefined => {
    if (state.contactsLists) {
      const contactsList = state.contactsLists.find(cl => cl.id === id)
      return contactsList
    }
  }
}

const mutations = {
  setContactsLists (state: GbState, data: Array<GbContactsList>): void {
    state.contactsLists = data
  }
}

const actions = {
  deleteContactsList ({ dispatch }: { dispatch: Dispatch }, uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/lists/${uuid}`)
        .then((response: Response) => response.json())
        .then(() => {
          dispatch('fetchContactsLists')
            .then(() => resolve(true))
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  exportContactList ({ dispatch }: { dispatch: Dispatch }, params: Record<string, any>): void {
    const filename = params.filename
    delete params.filename

    dispatch('global/showLoading', null, { root: true })

    return Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/lists/${params.id}/export`, {
      responseType: 'blob'
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style.display = 'none'
        a.href = url
        a.download = (filename || 'campaigns-events') + '.csv'

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err: Error) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  fetchContacts ({ dispatch }: { dispatch: Dispatch }, data: Record<string, any>): Promise<GbContacts> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/search`, data)
        .then((response: Response) => response.json())
        .then((contacts: GbContacts) => {
          resolve(contacts)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchContactsList ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<GbContactsList> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/lists/${id}`)
        .then((response: Response) => response.json())
        .then((data: GbContactsList) => {
          resolve(data)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchContactsLists ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<any> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/lists`)
        .then((response: Response) => response.json())
        .then((data: Array<GbContactsList>) => {
          commit('setContactsLists', data)
          resolve(data)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveContactsList ({ dispatch }: { dispatch: Dispatch }, data: GbContactsList): Promise<any> {
    let url = `${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/lists`

    if (data.id) {
      url += `/${data.id}`
    }

    return new Promise((resolve, reject) => {
      Vue.http.post(url, data)
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  importCSV ({ dispatch }: { dispatch: Dispatch }, file: File): Promise<GbContactsImport> {
    const formData = new FormData()
    formData.append('file', file)

    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/contacts/csv`, formData)
        .then((response: Response) => response.json())
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
