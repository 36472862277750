import { Commit, Dispatch } from 'vuex'

import {
  DFAccount,
  DFAccountIntents,
  DFAccountsTrees,
  DFState,
  DFAgent,
  DFIntent,
  DFProject
} from '@/types/dialogflow'

// initial state
const state: DFState = {
  accounts: null,
  intents: null
}

// getters
const getters = {
  accounts (): Array<DFAccount> | null {
    return state.accounts
  },
  intents: (state: DFState) => (account: string): Array<DFIntent> | null => {
    if (state.intents === null) {
      return null
    }
    return state.intents[account]
  }
}

// mutations
const mutations = {
  setAccounts (state: DFState, data: Array<DFAccount>): void {
    state.accounts = data
  },
  setIntents (state: DFState, data: DFAccountIntents): void {
    state.intents = data
  }
}

// actions
const actions = {
  deleteAccount ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_DF_URL}/accounts/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  downloadTemplate ({ dispatch }: { dispatch: Dispatch }, account: DFAccount): Promise<void> {
    dispatch('global/showLoading', null, { root: true })
    return (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/export/template`, {
      responseType: 'blob'
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style.display = 'none'
        a.href = url
        a.download = `${account.agent.displayName} - template.xlsx`

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err: Error) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  exportAccount ({ dispatch }: { dispatch: Dispatch }, account: DFAccount): Promise<void> {
    dispatch('global/showLoading', null, { root: true })
    return (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/${account.id}/export`, {
      responseType: 'blob'
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style.display = 'none'
        a.href = url
        a.download = `${account.agent.displayName}.xlsx`

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err: Error) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  fetchAccount ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<DFAccount> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: DFAccount) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<DFAccount>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((data: Array<DFAccount>) => {
          commit('setAccounts', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccountsTrees ({ dispatch }: { dispatch: Dispatch }): Promise<DFAccountsTrees> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_BB_HTTP}/bot/df-data`)
        .then((response: Response) => response.json())
        .then((data: DFAccountsTrees) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAgent ({ dispatch }: { commit: Commit, dispatch: Dispatch }, { id, projectId }: { id: string, projectId: string}): Promise<DFAgent> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/${id}/project/${projectId}/agent`)
        .then((response: Response) => response.json())
        .then((data: DFAgent) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchIntents ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Array<DFIntent>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/${id}/intents`)
        .then((response: Response) => response.json())
        .then((data: Array<DFIntent>) => {
          const intents: Record<string, Array<DFIntent>> = {}
          intents[id] = data
          commit('setIntents', intents)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchProjects ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Array<DFProject>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_DF_URL}/accounts/${id}/projects`)
        .then((response: Response) => response.json())
        .then((data: Array<DFProject>) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  importCorpus (_: unknown, { account, corpus }: { account: string, corpus: File }): Promise<void> {
    const formData = new FormData()

    formData.append('file', corpus)

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_DF_URL}/accounts/${account}/import`, formData)
        .then((response: Response) => response.json())
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          reject(err)
        })
    })
  },
  saveAgent (_: unknown, { account, agent }: { account: string, agent: DFAgent }): Promise<DFAccount> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_DF_URL}/accounts/${account}/agent`, {
        ...agent
      })
        .then((response: Response) => response.json())
        .then((data: DFAccount) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  },
  saveProject ({ dispatch }: { dispatch: Dispatch }, { account, projectId }: { account: string, projectId: DFProject }): Promise<DFAccount> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_DF_URL}/accounts/${account}/project`, {
        project_id: projectId
      })
        .then((response: Response) => response.json())
        .then((data: DFAccount) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
