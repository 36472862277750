<template>
  <div v-if="shown">
    <transition name="notification" >
      <div class="notification-wrapper ">
        <div class="notification-container ">
          <div class="notification-body">
            <p>{{ $t(ctx) }}</p>
            <check-circle-icon size="1.5x" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { CheckCircleIcon } from 'vue-feather-icons'

export default {
  name: 'notification',
  components: {
    CheckCircleIcon
  },
  data () {
    return {
      hideTimeout: 0
    }
  },
  computed: {
    ctx () {
      return this.$store.getters['global/getNotificationCtx']
    },
    shown () {
      return this.$store.getters['global/isNotificationShown'] === true
    }
  },
  methods: {
    closeNotification () {
      this.$store.commit('global/hideNotification')
    },
    initTimeout () {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout)
      }
      this.hideTimeout = setTimeout(() => {
        this.$store.commit('global/updateNotificationShown', false)
        clearTimeout(this.hideTimeout)
      }, 5000)
    }
  },
  watch: {
    ctx () {
      this.initTimeout()
    },
    shown (val) {
      if (val === true) {
        this.initTimeout()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";

.notification-wrapper {
  overflow-y: auto;
  position: fixed;
  bottom: 0px;
  width: 25%;
  z-index: 10000;
  min-width: 300px;
}

.notification-container {
  background-color: rgb(69, 194, 140);
  border-radius: .3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  color: #fff;
  margin: 1.75rem;
  padding: 0px;
  transition: all 2s ease;
  width: calc(100% - 1.75rem);
}

.notification-body {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  p {
    margin: 0;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="notification" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the notification transition by editing
 * these styles.
 */

.notification-enter {
  opacity: 0;
}

.notification-leave-active {
  opacity: 0;
}

.notification-enter .notification-container,
.notification-leave-active .notification-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
