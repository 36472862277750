import Vue from 'vue'
import VueI18n from 'vue-i18n'

export const messages = {
  en: {
    date: {
      dateFormat: 'MM/DD/YYYY',
      datetimeFormat: 'MM/DD/YYYY hh:mm A',
      hourFormat: 'hh:00',
      longdatetimeFormat: 'MM/DD/YYYY hh:mm:ss A',
      longtimeFormat: 'hh:mm:ss',
      shorttimeFormat: 'hh:mm A',
      hrdateformat: 'MMM D, YYYY',
      hrdatetimeformat: 'MMM D, YYYY h:mm A'
    },
    applepay: {
      addline: 'Add a line',
      amount: 'Amount',
      label: 'Label',
      message: {
        payment_title: 'Payment for',
        payment_succeeded: 'Payment successfull'
      },
      removeline: 'Remove',
      title: 'Ask for a paiement',
      title_placeholder: 'Provide a title',
      total: {
        amount: 'Total amount',
        label: 'Total'
      }
    },
    attachment: 'Attachement',
    authentication: {
      err: 'Authentication failed',
      success: 'Authentification success',
      title: 'Please, login'
    },
    block_modal: {
      confirm: 'Confirm you really wish to block this user?',
      title: 'Block user'
    },
    click_here_to_choose_a_file: 'Click here to choose a file',
    or_drag_it_here: 'or drag it here',
    close_modal: {
      confirm: 'Confirm you really wish to end this conversation?',
      title: 'Close room'
    },
    closed_modal: {
      title: 'Room closed'
    },
    'contact-reason': {
      title: 'What was the contact reason?',
      errors: {
        no_contact_reason: 'Please choose a contact reason'
      },
      search: 'Search...'
    },
    threads: {
      already_answered: 'This comment has already received an answer from your account, do you still want to continue?',
      back_button: 'Messages',
      button: 'Comments',
      finished_title: 'Finished',
      list_title: 'Threads',
      pick_thread: 'Please select a thread',
      running_title: 'Running',
      kind: {
        instagram: 'Instagram comments',
        facebook: 'Facebook comments',
        twitter: 'X comments'
      }
    },
    thread: {
      ask_finish: 'Do you really want to finish this thread?',
      ask_open: 'Do you really want to open this thread?',
      ask_remove: 'Do you really want to remove this thread?',
      removed: 'Comment removed',
      cancel: 'Cancel',
      cannot_reply_to_removed_comment: 'Unable to reply to this comment, it no longer exists.',
      comments_count: 'No comment | 1 comment | {0} comments',
      created_at: 'Posted the :',
      finish: 'Finish the thread',
      finished: 'Thread finished',
      hide: 'Hide',
      hide_replies: 'Hide replies',
      like: 'Like',
      dislike: 'Dislike',
      mark_as_read: 'Mark as read',
      new_unread_comments: '📪 New comment (1) | 📪 New comments ({0})',
      no: 'No',
      open: 'Open this thread',
      opened: 'Thread opened',
      remove: 'Remove',
      reply: 'Reply',
      show_replies: 'Show replies ({0})',
      type_your_message: 'Add a comment',
      unhide: 'Unhide',
      unread_replies: '1 unread | {0} unread',
      yes: 'Yes'
    },
    crm: {
      client_id: 'Client ID',
      category: 'Reason for contact',
      email: 'Email: ',
      phone: 'Phone: ',
      record_url: 'Record link: ',
      results_for: 'Result(s) for',
      search_users: 'Search for users',
      search_placeholder: 'Type a name or an email',
      title: 'CRM'
    },
    doc_title_new_msg: 'New message',
    push_title: 'New message',
    login: {
      connect: 'Se connecter',
      email: 'Email address',
      login: 'Sign in',
      password: 'Password',
      password_lost: 'Forgot your password ?',
      welcome: 'Welcome'
    },
    header: {
      home: 'Go to Dashboard',
      break: 'Take a break',
      unbreak: 'Quit break',
      stats: 'Statistics',
      admin: 'Admin',
      settings: 'Settings',
      logout: 'Logout'
    },
    details: {
      visitor: "Customer's infos",
      general: 'General infos',
      name: 'Name',
      language: 'Language',
      device: 'Device',
      browser: 'Browser',
      path: 'Customer path',
      since: 'Active since',
      contact_reason: 'Contact reason',
      source: 'Source',
      skill: 'Skill',
      file: 'Customer file',
      info: 'Customer info',
      phone: 'Phone number',
      email: 'Email',
      satisfaction: 'Customer satisfaction',
      note: 'Note',
      content: 'Content',
      validate: 'Validate',
      previous: 'Previous notes',
      history: 'History'
    },
    error: {
      bad_authentication: 'Invalid email or password',
      bad_rich_content: 'Content has erroneous format',
      bad_token: 'Invalid token',
      max_rooms_reached: 'Impossible to take care of this discussion',
      unable_to_find_user: 'Unable to find user',
      user_password_can_t_be_resetted: 'The user password can\'t be resetted.',
      skill_or_agent_required: 'A skill or an agent is required',
      transfer_max_rooms_reached: 'The user transfer could not be carried out because the selected user reached its maximum discussion threshold simultaneously'
    },
    guest_closed: 'Le client a supprimé la discussion',
    location_modal: {
      search: 'Search for an address…',
      title: 'Send locations'
    },
    logout_modal: {
      title: 'Do you want to logout?'
    },
    msglib: {
      categories: 'Categories',
      delete: {
        body: 'Do you really want to delete this item?',
        no: 'Cancel',
        title: 'Delete',
        yes: 'OK'
      },
      subcategories: 'Subcategories',
      search: 'Search...',
      sentences: 'Sentences',
      show_more: 'Show more',
      subtitle: 'Select message which you wish to send or search it by typing a keyword contains in searched message:',
      title: 'Messages librairy'
    },
    notes: {
      back: 'Back to disscussions',
      form_content_label: 'Content',
      form_subject_label: 'Subject',
      previous: 'Previous notes'
    },
    other_channels: {
      channels_title: 'Other channels',
      zencalls: 'Appel Zen'
    },
    params: {
      agent_email_label: 'Email',
      agent_first_name_label: 'Firstname',
      agent_last_name_label: 'Lastname',
      agent_skills_label: 'Skills',
      agent_kind_label: 'Category',
      agent_last_login_label: 'Last login',
      agent_max_rooms_label: 'Max simultaneous rooms',
      agent_want_notif_label: 'Receive notifications',
      agent_want_daily_report_label: 'Receive daily reports',
      back_to_dashboard: 'Back to conversations',
      choose_skill: 'Choose a skill',
      offline: 'Offline',
      online: 'Online',
      notification_label: 'Notifications',
      skills: 'Skills',
      table_kind_title: 'Category',
      table_last_login_title: 'Last login',
      table_max_rooms_title: 'Max conversations rooms',
      table_online_title: 'Status',
      table_username_title: 'Agent',
      title_agents: 'Agents parameters',
      title_msglib: 'Messages librairy',
      title_own: 'My parameters'
    },
    password: {
      change_title: 'Change password',
      confirm_new_password: 'Confirm new password',
      reset: 'Reset password',
      reset_title: 'Reset your password',
      type_new_password: 'Type new password'
    },
    reviews: {
      closed_title: 'Closed',
      deleted_title: 'Deleted',
      running_title: 'Running',
      transferred_title: 'Transferred',
      waiting_title: 'Waiting'
    },
    room: {
      address: 'Send address',
      agent_transfer: 'Transfer to an agent',
      auth: 'Ask for authentication',
      auto_closed: 'Fermée auto.',
      auto_closed_title: 'Auto. closed',
      ask_authentication: 'Ask for authentication',
      back: 'Back to conversation',
      block: 'Block user',
      blocked_title: 'Blocked',
      close: 'Close conversation',
      crm: 'Search in CRM',
      history: 'History from',
      payment: 'Ask for payment',
      send: 'Send',
      product: 'Send product',
      transfer: 'Transfer conversation',
      new: 'New conversation',
      accept: 'Would you like to take this new conversation ?',
      closed_title: 'Closed',
      late_title: 'Late',
      no: 'No, thanks !',
      list_title: 'Conversations',
      pick_room: 'Please select a conversation',
      pick_your_emoji: 'Select your emoji',
      running_title: 'Running',
      search: {
        back: 'Back',
        back_to_room: 'Back to the running conversation'
      },
      skill: 'Skill',
      timeslot: 'Timeslot',
      type_your_message: 'Type your message…',
      via_transfer: 'via transfer',
      waiting_message: 'Discussion closed',
      waiting_message_auto_allocation: 'Waiting discussion: automatic affectation',
      waiting_title: 'Waiting',
      yes: 'My pleasure 😊'
    },
    senditemslist: {
      title: 'Send a products\' list',
      no_product: 'No product available',
      products_list: 'Select and drag products in the right column'
    },
    statistics: {
      agent_count: 'Online agents',
      average_running: 'Average running time',
      average_waiting: 'Average waiting time',
      agent_stats: 'Agent statistics',
      back_to_dashboard: 'Back to conversations',
      choose_skill_or_agent: 'Choose a skill or an agent',
      closed_count: 'Conversations handled',
      custom: 'Custom interval',
      download: 'Download',
      download_all: 'Download CSV for all skills',
      handle_rate: 'Handled conversations / Total conversations',
      lastweek: 'Last 7 days',
      my_stats: 'My statistics',
      skills: 'Skills',
      stock_btn_label: 'Stock',
      stream_btn_label: 'Stream',
      title: 'Statistics',
      thismonth: 'This month',
      today: 'Today',
      waiting_count: 'Conversations',
      yesterday: 'Yesterday'
    },
    show_more: 'Show more',
    timeslot: {
      no_timeslot: 'No timeslot available',
      title: 'Choose timeslots to propose',
      timeslots_list: 'Available timeslots'
    },
    transfer_modal: {
      select_skill: 'Select to which skill you wish to transfer the conversation',
      skill_estimated_waiting_time: 'Estimated waiting time',
      title: 'Transfer conversation'
    },
    transferred_agent_modal: {
      now_in_waiting_section: 'The room has been successfully transferred to the agent.',
      title: 'Agent transfer confirmation'
    },
    transferred_modal: {
      now_in_waiting_section: 'It is now in the "Waiting" section, visible by agents of the selected skill.',
      title: 'Transfer confirmation',
      transfer_successfull: 'The room has been successfully transferred to the skill:'
    },
    user_stats: {
      conversation_time: 'Conversation duration',
      customer_satisfaction: 'Customer satisfaction',
      taken_discussions: 'Conversations count',
      waiting_time: 'Waiting time',
      guest_comments: 'Guest comments',
      customer_comments: 'Agent comments'
    },
    zencall: {
      immediate_or_schedule_callback: 'Immediate or scheduled callback',
      select_a_skill_for_callback: 'Select a skill for the callback:',
      thanks_for_choosing_a_skill: 'Please choose a skill',
      title: 'Appel Zen'
    },
    Cancel: 'Cancel',
    Loading: 'Loading',
    OK: 'OK',
    Remove: 'Remove'
  },
  fr: {
    date: {
      dateFormat: 'DD/MM/YYYY',
      datetimeFormat: 'DD/MM/YYYY HH:mm',
      hourFormat: 'HH:00',
      longdatetimeFormat: 'DD/MM/YYYY HH:mm:ss',
      longtimeFormat: 'HH:mm:ss',
      shorttimeFormat: 'HH:mm',
      hrdateformat: 'D MMM YYYY',
      hrdatetimeformat: 'D MMM YYYY, H[h]mm'
    },
    applepay: {
      addline: 'Ajouter une ligne',
      amount: 'Montant',
      label: 'Libellé',
      message: {
        payment_title: 'Paiement pour',
        payment_succeeded: 'Paiement réussi'
      },
      title_placeholder: 'Saisissez un titre',
      removeline: 'Supprimer',
      title: 'Demander un paiement',
      total: {
        amount: 'Montant total',
        label: 'Total'
      }
    },
    attachment: 'Pièce jointe',
    authentication: {
      err: 'Echec d\'authentication',
      success: 'Authentification réussie',
      title: 'Connectez-vous'
    },
    block_modal: {
      confirm: 'Souhaitez-vous vraiment bloquer ce visiteur ?',
      title: 'Bloquer ce visiteur'
    },
    click_here_to_choose_a_file: 'Cliquer ici pour choisir un fichier',
    or_drag_it_here: 'ou glisser le fichier ici',
    close_modal: {
      confirm: 'Souhaitez-vous vraiment fermer cette discussion ?',
      title: 'Fermer cette discussion'
    },
    closed_modal: {
      title: 'Discussion fermée'
    },
    'contact-reason': {
      title: 'Quel était le motif de contact ?',
      errors: {
        no_contact_reason: 'Veuillez choisir un motif de contact'
      },
      search: 'Rechercher...'
    },
    threads: {
      already_answered: 'Ce commentaire à déjà reçu une réponse de votre compte, souhaitez-vous tout de même continuer ?',
      back_button: 'Messages',
      button: 'Commentaires',
      finished_title: 'Terminés',
      list_title: 'Fils de discussion',
      pick_thread: 'Merci de sélectionner un fil de discussion',
      running_title: 'En cours',
      kind: {
        instagram: 'Commentaires Instagram',
        facebook: 'Commentaires Facebook',
        twitter: 'Commentaires X'
      }
    },
    thread: {
      ask_finish: 'Voulez-vous vraiment terminer ce fil de discussion ?',
      ask_open: 'Voulez-vous vraiment ré-ouvrir ce fil de discussion ?',
      ask_remove: 'Voulez-vous vraiment supprimer ce commentaire ?',
      removed: 'Commentaire supprimé',
      cancel: 'Annuler',
      cannot_reply_to_removed_comment: 'Impossible de répondre à ce commentaire, celui-ci n\'existe plus.',
      comments_count: 'Aucun commentaire | 1 commentaire | {0} commentaires',
      created_at: 'Posté le :',
      finish: 'Terminer le fil de discussion',
      finished: 'Fil de discussion terminé',
      hide: 'Masquer',
      hide_replies: 'Masquer les réponses',
      like: 'Aimer',
      dislike: 'Ne plus aimer',
      mark_as_read: 'Marquer comme lu',
      new_unread_comments: '📪 Nouveau commentaire (1) | 📪 Nouveaux commentaires ({0})',
      no: 'Non',
      open: 'Ouvrir ce fil de discussion',
      opened: 'Fil de discussion ouvert',
      remove: 'Supprimer',
      reply: 'Répondre',
      show_replies: 'Voir les réponses ({0})',
      type_your_message: 'Ajouter un commentaire',
      unhide: 'Ré-afficher',
      unread_replies: '1 non lu | {0} non lus',
      yes: 'Oui'
    },
    crm: {
      client_id: 'ID Client',
      category: 'Motif de contact',
      email: 'Email : ',
      phone: 'Téléphone : ',
      record_url: 'Lien de l\'enregistrement : ',
      results_for: 'Résultat(s) pour',
      search_users: 'Recherche d\'utilisateurs',
      search_placeholder: 'Saisissez un nom ou un email',
      title: 'CRM'
    },
    doc_title_new_msg: 'Nouveau message',
    push_title: 'Nouveau message',
    login: {
      connect: 'Se connecter',
      email: 'Adresse e-mail',
      login: 'Se connecter',
      password: 'Mot de passe',
      password_lost: 'Mot de passe oublié ?',
      welcome: 'Bienvenue'
    },
    header: {
      home: 'Aller au Dashboard',
      break: 'Se mettre en pause',
      unbreak: 'Revenir de pause',
      stats: 'Statistiques',
      admin: 'Admin',
      settings: 'Paramètres',
      logout: 'Déconnexion'
    },
    details: {
      visitor: 'Informations du client',
      general: 'Informations générales',
      name: 'Nom',
      language: 'Langue',
      device: 'Appareil',
      browser: 'Navigateur',
      path: 'Parcours client',
      since: 'Actif depuis',
      contact_reason: 'Motif de contact',
      source: 'Source',
      skill: 'Compétence',
      file: 'Fiche Client',
      info: 'Info client',
      phone: 'Téléphone',
      email: 'Mail',
      satisfaction: 'Satisfaction client',
      note: 'Note',
      content: 'Contenu',
      validate: 'Valider',
      previous: 'Notes antérieures',
      history: 'Historique'
    },
    error: {
      bad_authentication: 'E-mail ou mot de passe incorrect',
      bad_rich_content: 'Format du message erroné',
      bad_token: 'Jeton d\'authentification erroné',
      max_rooms_reached: 'Impossible de prendre en charge cette discussion',
      unable_to_find_user: 'Impossible de trouver l\'utilisateur',
      user_password_can_t_be_resetted: 'Le mot de passe de l\'utilisateur ne peut être réinitialisé.',
      skill_or_agent_required: 'Choisir une compétence ou un agent est requis',
      transfer_max_rooms_reached: 'Le transfert utilisateur n\'a pas pu être effectué car l\'utilisateur sélectionné a atteint son seuil de discussion maximum en simultanée'
    },
    location_modal: {
      search: 'Rechercher une adresse…',
      title: 'Envoyer une adresse'
    },
    logout_modal: {
      title: 'Souhaitez-vous vous déconnecter ?'
    },
    msglib: {
      categories: 'Catégories',
      delete: {
        body: 'Voulez-vous vraiment supprimer cet élément ?',
        no: 'Annuler',
        title: 'Supprimer',
        yes: 'OK'
      },
      subcategories: 'Sous catégories',
      search: 'Rechercher...',
      sentences: 'Phrases types',
      show_more: 'Afficher plus',
      subtitle: 'Sélectionner le message que vous souhaitez envoyer ou recherchez-le en tapant un mot clé contenu dans le message recherché :',
      title: 'Bibliothèque de messages'
    },
    notes: {
      back: 'Retour aux discussions',
      form_content_label: 'Contenu',
      form_subject_label: 'Objet',
      previous: 'Notes antérieures'
    },
    other_channels: {
      channels_title: 'Autres Canaux',
      zencalls: 'Appel Zen'
    },
    params: {
      agent_email_label: 'Email',
      agent_first_name_label: 'Prénom',
      agent_last_name_label: 'Nom',
      agent_skills_label: 'Compétences',
      agent_kind_label: 'Catégorie',
      agent_last_login_label: 'Dernière connexion',
      agent_max_rooms_label: 'Nombre de discussions simultanées maximales',
      agent_want_notif_label: 'Recevoir des notifications',
      agent_want_daily_report_label: 'Recevoir les rapports quotidiens',
      back_to_dashboard: 'Retour aux discussions',
      choose_skill: 'Choisissez une compétence',
      offline: 'Indisponible',
      online: 'En ligne',
      notification_label: 'Notifications',
      skills: 'Compétences',
      table_kind_title: 'Catégorie',
      table_last_login_title: 'Dernière connexion',
      table_max_rooms_title: 'Nombre de discussions max.',
      table_online_title: 'Statut',
      table_username_title: 'Agent',
      title_agents: 'Paramètres agents',
      title_msglib: 'Bibliothèque de messages',
      title_own: 'Mes paramètres'
    },
    password: {
      change_title: 'Modifier le mot de passe',
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      reset: 'Réinitialiser le mot de passe',
      reset_title: 'Réinitialisez votre mot de passe',
      type_new_password: 'Saisisser le nouveau mot de passe'
    },
    reviews: {
      closed_title: 'Fermé',
      deleted_title: 'Supprimé',
      running_title: 'En cours',
      transferred_title: 'Transféré ',
      waiting_title: 'En attente'
    },
    room: {
      address: 'Envoyer une adresse',
      agent_transfer: 'Transférer à un agent',
      ask_authentication: 'Demander une authentification',
      auth: 'Demander une identification',
      auto_closed_title: 'Fermée auto.',
      back: 'Retour à la discussion',
      block: 'Bloquer le visiteur',
      blocked_title: 'Bloquée',
      close: 'Fermer la discussion',
      crm: 'Recherche CRM',
      history: 'Historique du',
      payment: 'Demander un paiement',
      product: 'Envoyer une fiche produit',
      send: 'Envoyer',
      transfer: 'Transférer la discussion',
      new: 'Nouvelle conversation',
      accept: 'Souhaitez-vous prendre en charge cette nouvelle conversation ?',
      closed_title: 'Fermée',
      late_title: 'En retard',
      list_title: 'Discussions',
      no: 'Non, merci !',
      pick_room: 'Merci de sélectionner une conversation',
      pick_your_emoji: 'Choisissez votre emoji',
      running_title: 'En cours',
      search: {
        back: 'Retour',
        back_to_room: 'Revenir à la discussion en cours'
      },
      skill: 'Compétence',
      timeslot: 'Créneaux horaires',
      type_your_message: 'Tapez votre message…',
      via_transfer: 'via transfert',
      waiting_message: 'Discussion fermée',
      waiting_message_auto_allocation: 'Discussion en attente : affectation automatique',
      waiting_title: 'En attente',
      yes: 'Avec plaisir 😊'
    },
    senditemslist: {
      title: 'Envoyer une fiche produit',
      no_product: 'Aucun produit disponible',
      products_list: 'Sélectionnez et faîtes glisser les produits dans la colonne de droite'
    },
    statistics: {
      agent_count: 'Agents en ligne',
      average_running: 'Temps de traitement moyen',
      average_waiting: 'Temps d\'attente moyen',
      agent_stats: 'Statistiques agents',
      back_to_dashboard: 'Retour aux discussions',
      choose_skill_or_agent: 'Choisissez une compétence ou un agent',
      closed_count: 'Discussions traités',
      custom: 'Intervalle personnalisé',
      download: 'Télécharger',
      download_all: 'Télécharger le CSV toutes les compétences',
      handle_rate: 'Discussions traitées / Discussions arrivées',
      lastweek: 'Les 7 derniers jours',
      my_stats: 'Mes statistiques',
      skills: 'Compétences',
      stock_btn_label: 'Stock',
      stream_btn_label: 'Flux',
      title: 'Statistiques',
      thismonth: 'Ce mois-ci',
      today: 'Aujourd\'hui',
      waiting_count: 'Discussions',
      yesterday: 'Hier'
    },
    show_more: 'Afficher plus',
    transfer_modal: {
      select_skill: 'Sélectionnez la compétence vers laquelle vous souhaitez transférer la discussion.',
      skill_estimated_waiting_time: 'Temps d\'attente estimé',
      title: 'Transférer la discussion'
    },
    timeslot: {
      no_timeslot: 'Aucun créneau disponible',
      title: 'Choisissez les créneaux horaires à proposer',
      timeslots_list: 'Créneaux disponibles'
    },
    transferred_agent_modal: {
      now_in_waiting_section: 'La discussion a bien été transférée à l\'agent.',
      title: 'Confirmation du transfert agent'
    },
    transferred_modal: {
      now_in_waiting_section: 'Celle-ci se trouve désormais dans la section "En attente", visible par les agents de la compétence sélectionnée.',
      title: 'Confirmation du transfert',
      transfer_successfull: 'La discussion a bien été transférée à la compétence :'
    },
    user_stats: {
      conversation_time: 'Temps de conversation',
      customer_satisfaction: 'Satisfaction client',
      taken_discussions: 'Discussions prises',
      waiting_time: 'Temps d\'attente',
      guest_comments: 'Commentaires client',
      customer_comments: 'Commentaires agent'
    },
    zencall: {
      immediate_or_schedule_callback: 'Rappel immédiat ou planifié',
      select_a_skill_for_callback: 'Sélectionnez une compétence à laquelle le rappel est destiné :',
      thanks_for_choosing_a_skill: 'Merci de choisir une compétence Appel Zen',
      title: 'Appel Zen'
    },
    Cancel: 'Annuler',
    Loading: 'Chargement en cours',
    OK: 'OK',
    or: 'ou',
    Remove: 'Supprimer'
  }
}

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en',
  messages // set locale messages
})

export default i18n
