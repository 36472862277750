import { Commit, Dispatch } from 'vuex'

import { Faq, FaqsState } from '@/types/webivr/faqs'

// initial state
const state: FaqsState = {
  current: null,
  list: null
}

// getters
const getters = {
  current (): Faq | null {
    return state.current
  },
  list (): Array<Faq> | null {
    return state.list
  }
}

// mutations
const mutations = {
  setCurrent (state: FaqsState, data: Faq): void {
    state.current = data
  },
  setList (state: FaqsState, data: Array<Faq>): void {
    state.list = data
  }
}

// actions
const actions = {
  deleteFaq ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/faqs/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchFaq ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Faq> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/faqs/${id}`)
        .then((response: Response) => response.json())
        .then((data: Faq) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Faq>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/faqs`)
        .then((response: Response) => response.json())
        .then((data: Array<Faq>) => {
          commit('setList', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveFaq ({ dispatch }: { dispatch: Dispatch }, data: Faq): Promise<Faq> {
    let url = `${process.env.VUE_APP_WEBIVR_URL}/faqs`
    if (data.id) {
      url += '/' + data.id
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: Faq) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
