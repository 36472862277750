import Vue from 'vue'

// initial state
const state = {
  offgroups: null,
  rule: null,
  rules: null
}

// getters
const getters = {
  getOffGroups: (state) => {
    return state.offgroups
  },
  getRule: (state) => {
    return state.rule
  },
  getRules: (state) => {
    return state.rules
  }
}

// mutations
const mutations = {
  setOffGroups: (state, data) => {
    state.offgroups = data
  },
  setRule: (state, data) => {
    state.rule = data
  },
  setRules: (state, data) => {
    state.rules = data
  }
}

// actions
const actions = {
  addWeek: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_TIMESLOTS_URL}/weeks/week?add_new=true`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteRule: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_CHRONOS_URL}/rules/${id}`)
        // .then(response => response.json())
        .then(() => {
          commit('setRule', null)
          resolve(true)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteWeek: ({ commit }, id) => {
    Vue.http.delete(`${process.env.VUE_APP_TIMESLOTS_URL}/weeks/week/${id}`)
      .catch((err) => {
        commit('global/handleHttpError', err, { root: true })
      })
  },
  fetchOffGroups: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CHRONOS_URL}/off_groups`)
        .then(response => response.json())
        .then((data) => {
          commit('setOffGroups', data)
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchRule: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CHRONOS_URL}/rules/${id}`)
        .then(response => response.json())
        .then((data) => {
          commit('setRule', data)
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchRules: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CHRONOS_URL}/rules`)
        .then(response => response.json())
        .then((data) => {
          commit('setRules', data)
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchWeek: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_TIMESLOTS_URL}/weeks/week/${id}`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveRule: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_CHRONOS_URL}/rules`
      if (data.id) {
        url += `/${data.id}`
      }
      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveWeek: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_TIMESLOTS_URL}/weeks/week/${data.id}`, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
