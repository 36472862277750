<template>
  <div class="input-topt">
    <input type="text" class="gb-field"
      @focus="$event.target.select()"
      @input="handleInput($event, index - 1)"
      @keyup="handleKey($event, index - 1)"
      @paste="handlePaste($event, index - 1)"
      :class="{'gb-az-errors': field.errors}"
      :data-name="field.name"
      :id="field.name + '-input-totp-' + index"
      :key="field.name + '-input-totp-' + index"
      :required="field.required"
      autocomplete="off"
      maxlength="1"
      v-focus="field.focus && index === 1"
      v-for="index in length"
      v-model="model[index - 1]"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class InputTOTP extends Vue {
  @Prop({ default: {} }) field!: Record<string, any>
  @Prop({ default: '' }) value!: string

  private model = new Array(this.length)

  get length (): number {
    return this.field?.length || 6
  }

  get passcode (): string {
    return this.model.join('').toUpperCase()
  }

  created (): void {
    this.model = this.value.substring(0, this.length).split('')
  }

  getInputIndex (target: HTMLInputElement, index: number): number {
    const form = target.form
    let elIndex = index

    if (form) {
      for (let i = 0; i < form?.elements.length; i++) {
        if (form.elements[i] === target) {
          elIndex = i
          break
        }
      }
    }

    return elIndex
  }

  handlePaste (e: ClipboardEvent, index: number): void {
    if (e.clipboardData) {
      const lengthDiff = this.length - index
      const data = e.clipboardData
        .getData('text/plain')
        .substring(0, index + lengthDiff)
        .split('')
      const offset = new Array(this.length - lengthDiff)
      const target = e.target as HTMLInputElement

      this.model = offset.concat(data)

      target.blur()
    }
  }

  handleInput (e: Event, index: number): void {
    const target = e.target as HTMLInputElement

    if (target.value) {
      const elIndex = this.getInputIndex(target, index)
      const nextInput = target.form?.elements[elIndex + 1] as HTMLInputElement

      if (nextInput?.dataset.name === this.field.name) {
        nextInput.focus()
        nextInput.select()
      }
    }
  }

  handleKey (e: KeyboardEvent, index: number): void {
    if (e.key === 'Backspace') {
      const target = e.target as HTMLInputElement
      const elIndex = this.getInputIndex(target, index)
      const prevInput = target.form?.elements[elIndex - 1] as HTMLInputElement

      if (prevInput?.dataset.name === this.field.name) {
        prevInput.focus()
        prevInput.select()
      }
    }
  }

  @Watch('passcode')
  onChange (passcode: string): void {
    this.$emit('input', passcode)
  }
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  justify-content: center;

  input {
    text-align: center;
    text-transform: uppercase;
    width: 2.5rem;

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}
</style>
