<template>
  <div class="input-password">
    <input :type="toggleVisibility ? 'text' : 'password'"  :name="field.name" autocomplete="off"
        :required="field.required"
        :class="{'gb-az-errors': field.errors}"
        :disabled="field.disabled"
        :placeholder="field.placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)">
    <button type="button" class="toggle-visibility" @click.prevent="togglePasswordVisiblity">
      <icon :name="toggleVisibility ? 'visibility_off' : 'visibility'"/>
    </button>
  </div>
</template>

<script>
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  name: 'InputPassword',
  components: { Icon },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: String
  },
  data () {
    return {
      toggleVisibility: false
    }
  },
  methods: {
    togglePasswordVisiblity () {
      this.toggleVisibility = !this.toggleVisibility
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.input-password {
  position: relative;

  input {
    padding-right: 62.5px;
  }

  .toggle-visibility {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 0;
    background: transparent;
    border: none;
    font-size: 1.3rem;
    transform: translateY(-50%);
    color: $grey;
    outline: none;
    transition: color .15s;

    &:hover {
      color: $primary;
    }
  }
}
</style>
