import $settings from '@/settings'

const getInvalidTimeFormat = (day) => {
  return `${day} contains invalid time format.`
}

const passwordErrors = {
  min_length_digit: 'Your password must contain at least one digit',
  min_length_lower_characters: 'Your password must contain at least one lower case letter',
  min_length_special_characters: 'Your password must contain at least one special character',
  min_length_upper_characters: 'Your password must contain at least one upper case letter',
  password_entirely_numeric: "Your password can't contains only numbers",
  password_required: 'Password required',
  password_requirements: 'The password must be 8 characters long',
  password_too_common: 'Your password is too common',
  password_too_short: 'Your password must be at least 12 characters long',
  passwords_mismatch: 'The two fields are not identical'
}

export default {
  Cancel: 'Cancel',
  Loading: 'Loading',
  OK: 'OK',
  TFA: {
    continue: 'Continue',
    enable: 'Activate',
    enable_TFA: 'Enable the authenticator',
    enter_code: 'Enter security code to continue',
    setup_code: 'Security code',
    steps: {
      1: {
        content: 'Download and install Google Authenticator on your phone.',
        title: 'Download Google Authenticator'
      },
      2: {
        content: 'Open Google Authenticator and scan the image on the right from your phone.',
        title: 'Scan the QR Code'
      },
      3: {
        content: 'Enter the security code to continue.',
        title: 'Login with security code'
      },
      title: 'Secure your account in 3 easy steps:'
    }
  },
  account: {
    activate_title: 'Activating your account',
    user_not_configured: {
      body: 'Your user account permissions have not been set. Please contact your administrator to finalize your account configuration.',
      title: 'Account configuration in progress'
    },
    your_email_has_been_activated: 'Your email address has been activated'
  },
  add: 'Add',
  agent: {
    already_exist_same_email: 'Email address already used'
  },
  ai: {
    dialogflow: {
      agent: 'Agent\'s name',
      ask_delete: 'Do you really want to remove this Dialogflow agent?',
      channel: 'Messaging label',
      configure: 'Configure',
      corpus: 'Corpus',
      corpus_details: 'To feed your artificial intelligence, import your intentions, entities and Small Talk based on this {0}.',
      corpus_link: 'Template',
      created: 'Dialogflow agent created',
      deleted: 'The Agent IA has been deleted',
      export: 'Export',
      import: 'Import',
      import_status: {
        done: 'Imported',
        failed: 'Import failure',
        running: 'In process',
        title: 'Status: ',
        undefined: 'Unknown'
      },
      imported: 'File imported',
      latest_import: 'Latest import at {0}',
      login: 'Link a Dialogflow account',
      project: 'Dialogflow project',
      renew_token: 'Renew connexion token',
      saved: {
        body: 'The Dialogflow Agent "{0}" has been successfully configured, you can now feed this agent by importing a corpus.',
        cancel: 'Back to AI agents list',
        confirm: 'Import corpus',
        notification: 'Dialogflow agent updated',
        title: 'Dialogflow agent configured'
      },
      skill_placeholder: 'Choose a skill',
      skill: 'Skill',
      status: 'Status',
      step1: 'Dialogflow account association',
      step2: 'Agent configuration',
      subtitle: 'Dialogflow agent list',
      tree: 'Tree',
      type: 'Type'
    },
    knowledge_bases: {
      ask_delete: 'Do you really want to remove this knowledge base?',
      ask_delete_file: 'Do you really want to remove the file "{0}"?',
      ask_replace_file: 'A file "{0}" already exists in this knowledge base, would you like to replace it?',
      configure: 'Configure',
      deleted: 'The knowledge base has been deleted',
      file_deleted: 'The file has been deleted',
      kind: 'Kind',
      kinds: {
        agent: 'Agent',
        placeholder: 'Choose a kind',
        smartcoaching: 'Smart Coaching'
      },
      languages: {
        english: 'English',
        french: 'French',
        placeholder: 'Choose a language',
        unknown: 'Unknown'
      },
      label: 'Name',
      language: 'Language',
      latest_imports: 'Latest imported files:',
      file: 'File',
      imported: 'File imported',
      replace: 'Replace',
      saved: {
        body: 'The knowledge base "{0}" has been successfully created, you can now feed it by importing a file.',
        cancel: 'Back to knowledge base list',
        confirm: 'Import file',
        notification: 'Knowledge base updated',
        title: 'Knowledge base configured'
      },
      skills: 'Skills',
      title: 'Knowledge base list',
      updated_at: 'Updated'
    },
    ravana: {
      ask_delete: 'Do you really want to remove this conversational agent?',
      behavior: 'Attitude',
      configure: 'Configure',
      context: 'Contexte',
      created: 'Conversational agent created',
      deleted: 'The Agent IA has been deleted',
      goal: 'Objective',
      knowledge_bases: 'Knowledge bases',
      label: 'Label',
      role: 'Role',
      saved: {
        body: 'The Dialogflow Agent "{0}" has been successfully configured, you can now assign optional tasks to it.',
        cancel: 'Back to AI agents list',
        confirm: 'Configure tasks',
        notification: 'Conversational agent updated',
        title: 'Conversational agent configured'
      },
      skill_placeholder: 'Choose a skill',
      skill: 'Skill',
      subtitle: 'Conversational agent list',
      session_data: {
        kind: {
          business_activity: 'Business activity',
          country: 'Country',
          email: 'Email',
          firstname: 'First name',
          generic: 'Generic fields',
          int: 'Number',
          lastname: 'Last name',
          phone_number: 'Phone number',
          siren: 'SIREN',
          static: 'Static fixes',
          str: 'Character string'
        },
        kind_placeholder: 'Choose a kind',
        label: 'Label',
        label_placeholder: 'Label',
        title: 'Session data'
      },
      task: {
        add: {
          label: 'Type of task to add',
          placeholder: 'Select the type of task',
          title: 'Add a task'
        },
        form: {
          business_activity: {
            keywords: 'Lines of business',
            keywords_details: 'Enter here the lines of business, separated by commas, which are not to be taken into account or the only lines of business to be taken into account (Depending on the include/exclude option)',
            whitelist: 'Include ?',
            whitelist_details: 'Choose which lines of business to include/exclude in the form agent\'s consideration of retrieving information'
          },
          country: {
            keywords: 'Country',
            keywords_details: 'Enter here the countries, separated by commas, which are not to be taken into account or the only countries to be taken into account (depending on the include/exclude option)',
            whitelist: 'Include ?',
            whitelist_details: 'Choose which countries to include/exclude in the consideration of the form agent for retrieving information'
          },
          fields: 'Information to retrieve',
          intents: 'Intents',
          intents_details: 'What user question(s) can trigger your form?\nExample: "What are your prices? What are your offers?"',
          label: 'Label',
          label_placeholder: 'Label',
          params: 'Settings',
          required: 'Required?',
          session_data_id_placeholder: 'Choose a session data',
          title: 'Form'
        },
        kind: {
          form: 'Form'
        }
      },
      tasks: 'Tâches',
      third_parties_account: 'GPT account',
      third_parties_account_placeholder: 'Choose a GPT account',
      timeout_message: 'Timeout message',
      timeout_message_details: 'Fallback message used when the maximum AI agent response generation time is exceeded'
    },
    smartcoaching: {
      ask_delete: 'Do you really want to remove this smartcoaching agent?',
      configure: 'Configure',
      created: 'Smartcoaching agent created',
      deleted: 'The Agent IA has been deleted',
      error: {
        gpt_mistake: 'An error has occurred during suggestion generation, please try again.',
        skill_already_exists: 'The skill "{0}" is already used by another smartcoaching agent'
      },
      knowledge_bases: 'Knowledge bases',
      label: 'Label',
      saved: {
        notification: 'Smartcoaching agent updated',
        title: 'Smartcoaching agent configured'
      },
      skill: 'Skills',
      subtitle: 'Smartcoaching agent list',
      third_party_account: 'GPT account',
      third_party_account_placeholder: 'Choose a GPT account',
      tones: {
        details: 'Tones used by the agent, 3 maximum, each suggestion will be based on one tone at a time',
        list: [
          'Friendly',
          'Professional',
          'Concise',
          'Warm'
        ],
        title: 'Agent\'s tones'
      }
    }
  },
  already_exists: 'Enterred value already exists',
  back: 'Back',
  bot: {
    actions: {
      'find-invalid-node': 'Center the view on the next invalid node of the Visual IVR',
      preview: 'Preview',
      publish: 'Save',
      redo: 'Redo',
      undo: 'Undo',
      zoom_in: 'Zoom in',
      zoom_out: 'Zoom out'
    },
    back: 'Back',
    changes_saved: 'Changes saved',
    copy_from_default_channel_ok: 'Copy successful!',
    copy_trees_from_default_channel: 'Copying trees from the default channel, please wait',
    node: {
      choice_fallback: 'Choice not detected',
      dialogflow_end: 'End of AI interaction',
      fallback: 'Non-detection of intent',
      gbagent: 'Linking',
      image: '1 Image added',
      job: 'Build your bot using the different actions (Your welcome or absence message will be played according to your schedule).',
      totree: 'Back to bot home'
    },
    trees: {
      add: 'Add tree',
      title: 'Structure'
    },
    unsaved_changes: 'Do you really want to leave? You have unsaved changes!'
  },
  botgroups: {
    botgroup: {
      ask_cancel: 'You will lose your changes, are you sure you want to undo your changes?',
      ask_delete: 'Do you really want to delete this entry point?',
      ask_submit: 'Your changes will apply to your widget, are you sure you want to save your changes?',
      calendar: 'Availability',
      channel_trees: 'Digital channels',
      columns: {
        label: 'Messaging label',
        type: 'Type',
        skill: 'Skill',
        tree: 'Tree',
        status: 'Status',
        webivr: 'Visual IVR',
        webivr_tree: 'Visual IVR tree name'
      },
      configure: 'Set up',
      created: 'Entry point created',
      customize: {
        btn: 'Customize',
        save_before_customize: 'You have to save the widget in order to customize it',
        title: 'Customize your widget'
      },
      deleted: 'Entry point deleted',
      embed: 'Integration',
      embed_url: 'Copy /paste the following URL to those pages where you want it displayed:',
      embed_widget: 'Copy /paste the following code to embed it onto each display page:',
      embed_presets_widget: 'Copy /paste the following code to embed it onto each display page:',
      error: {
        not_found: 'Entry point doesn\'t exist anymore.',
        only_one_integration_channel_allowed_for_url_integration: 'Only one digital channel can be associated with a URL entry point'
      },
      general: 'General',
      integration_channels: 'Channels choices',
      name: 'Entry point name',
      saved: 'Entry point updated',
      skill: 'Skill',
      skill_placeholder: 'Select skill',
      type: {
        presets_widget: 'Customizable Widget',
        title: 'Integration type',
        subtitle: 'You will not be able to change this integration mode later.',
        search: 'Search engine',
        url: 'URL link',
        widget: 'Widget'
      },
      warning: 'Attention',
      webivr: {
        account: 'Published Visual IVR',
        account_placeholder: 'Choose a visual IVR',
        add_tree: {
          title: 'Link a published tree',
          placeholder: 'Select the publish tree to link'
        },
        auto_open_delay: {
          details: 'Leave empty to disable widget automatic opening',
          disabled: 'Automatic opening disabled',
          label: 'Automatic opening time (in seconds)'
        },
        customize: {
          btn: 'Navigation bar'
        },
        mode: {
          embed: 'Embedded widget',
          label: 'Display type',
          full: 'Full page'
        },
        navbar: {
          'add-item': 'Add a navigation button',
          save: 'Navigation bar saved',
          title: 'Navigation bar',
          trees: {
            calendar: 'Calendar',
            delete: 'Delete',
            identifier: 'CSS class',
            label: 'Label',
            no_trees: 'No tree has been added to the entry point.',
            title: 'Trees list',
            tree: 'Tree'
          }
        },
        html_root: 'HTML root node (leave empty to use default)',
        icon_url: {
          label: 'Icon'
        },
        information: 'Information',
        max_open_events_per_session: 'Limit the number of clicks',
        max_open_events_per_session_details: 'Leave empty for no limit',
        no_tree: 'No tree has been added to the entry point.',
        session_ttl_hours: 'URL time-to-live (In day)',
        session_ttl_hours_details: 'Leave empty for no limit',
        tree_ask_delete: 'Do you really want to remove this tree from this entry point?',
        tree_cal_saved: 'Tree calendar saved',
        tree_created: 'Tree added',
        tree_deleted: 'Tree removed',
        tree_saved: 'Tree saved',
        trees: 'Tree configuration'
      }
    },
    embed: {
      copy: 'Copy',
      copied_to_clipboard: 'Copied to clipboard!',
      preview: 'Preview',
      title: 'Integration'
    },
    messaging_groups: '{0} entry points',
    messenger_menu: {
      add_button: 'Add button',
      buttons: {
        reset: 'Bot reset',
        text: 'Text',
        title: 'Buttons',
        tree: 'Tree',
        tree_redirect: 'Redirect to a tree',
        type: 'Type',
        url: 'URL'
      },
      channel: 'Messenger messaging'
    },
    title: 'List of your entry points'
  },
  calendar: {
    all: 'Open 24//7',
    back: 'Back',
    closed: 'Closed',
    custom: 'Personalize your opening hours',
    error: {
      cant_choose_existing_off_day: 'You can\'t choose an already existing day',
      date_must_be_in_the_future: 'Date must be in the future',
      empty_day: 'Opening hours with no set schedule have been ignored.',
      Monday_as_invalid_time_format: getInvalidTimeFormat('Monday'),
      Tuesday_as_invalid_time_format: getInvalidTimeFormat('Tuesday'),
      Wednesday_as_invalid_time_format: getInvalidTimeFormat('Wednesday'),
      Thursday_as_invalid_time_format: getInvalidTimeFormat('Thursday'),
      Friday_as_invalid_time_format: getInvalidTimeFormat('Friday'),
      Saturday_as_invalid_time_format: getInvalidTimeFormat('Saturday'),
      Sunday_as_invalid_time_format: getInvalidTimeFormat('Sunday')
    },
    form: {
      add: 'Add',
      add_new_custom_day: 'Add new custom day',
      delete_ask: 'Do you really want to delete this calendar?',
      deleted: 'Calendar deleted',
      calendar: 'Calendar',
      label: 'Label',
      off_groups: 'Day off groups',
      saved: 'Calendar saved'
    },
    friday: 'Friday',
    monday: 'Monday',
    saturday: 'Saturday',
    saved: 'Your schedule has been saved',
    sunday: 'Sunday',
    table: {
      add: 'Add a calendar',
      created: 'Creation date',
      custom: 'Days off',
      edit: 'Edit',
      label: 'Label',
      subtitle: 'Calendars list',
      timezone: 'Timezone',
      last_update: 'Update date'
    },
    thursday: 'Thursday',
    title: 'Calendar',
    tuesday: 'Tuesday',
    used_TZ: 'Schedules previously saved in time zone:',
    wednesday: 'Wednesday'
  },
  campaigns: {
    add_whatsapp_channel: 'Add WhatsApp channel',
    author: 'Author',
    back: 'Back to campaigns\'s home',
    campaigns: {
      back: 'Back to campaigns list'
    },
    contacts: {
      add: 'Create an audience',
      ask_delete: 'Do you really want to delete this audience?',
      back: 'Back to the list of audiences',
      deleted: 'Audience deleted',
      export: 'Export',
      form: {
        'contacts*': 'Contacts*',
        created: 'Audience created',
        email: 'Email',
        error: {
          blocked_by_campaign: 'Impossible to delete the audience because it is used by a campaign.'
        },
        external_id: 'ID',
        external_id_kind: {
          FACEBOOK_PSID: 'Facebook ID',
          GBM_CONVERSATION_ID: 'Conversation ID',
          PHONE_NUMBER: 'Phone number',
          placeholder: 'Choose a kind',
          title: 'Kind*'
        },
        filters: {
          contact_name: 'Name',
          contact_reasons: 'Contact reasons',
          email: 'Email',
          language: 'Contacts language',
          last_interaction_at: 'Last interaction',
          origin: {
            bot: 'Messaging',
            csv: 'CSV',
            title: 'Origin'
          },
          origin_integrations: 'Entry points',
          origin_tree: 'Tree',
          reset: 'Reset',
          selected: 'Selected contacts',
          skills: 'Skills',
          solicitations: {
            from: 'From',
            title: 'Number of solicitations',
            to: 'to'
          }
        },
        import: {
          'first_column_must_be:external_id': 'The first column of the CSV file must have the title "external_id" and contain the contact\'s identifiers (Google ID / Facebook account / Phone numbers in the international formats without the sign "+").',
          'header:min:2': 'The first two columns of the first row of the CSV file must be equal to "external_id" and "language" respectively.',
          'second_column_must_be:language': 'The second column of the CSV file must be titled "language" and contain the languages of the different contacts.',
          add_btn: 'Add',
          ask_add: 'Would you like to add the imported contacts to the audience selection?',
          do_not_add_btn: 'Do not add',
          errored_lines_separator: ', ',
          errored_lines: 'Invalid CSV row numbers:',
          example: 'Import your contacts manually using this {0}.',
          existing_contacts: 'Number of existing contacts:',
          imported: 'Imported contacts',
          inserted_contacts: 'Number of imported contacts:',
          label: 'CSV file',
          ref_file: 'reference file',
          title: 'Import new contacts'
        },
        language: 'Language',
        last_interaction_at: 'Last interaction',
        legals: {
          1: 'I have received specific and informed consent from all my contacts to send them messages',
          2: 'None of my contacts were obtained from third party mailing lists',
          3: 'I will only send legal and legitimate information',
          title: 'I certify that:'
        },
        name: 'Audience name',
        'name*': 'Audience name*',
        origin_integration: 'Entry point',
        origin_skill: 'Skill',
        origin_tree: 'Tree',
        phone_number: 'Phone number',
        raw: {
          label: 'Enter your phone numbers, without spaces and separated by ";"'
        },
        results: 'Search result: {0} contact | Search result: {0} contact | Search results: {0} contacts',
        saved: 'Audience updated',
        search: 'Search by filters',
        search_btn: 'Search for...',
        selected: 'No contact selected | {0} contact selected | {0} contacts selected',
        source: {
          options: {
            existing: 'Add existing contacts',
            file: 'Import CSV file',
            raw: 'Insert manually'
          },
          title: 'Import audience*'
        },
        username: 'Name'
      },
      contacts_count: 'Contacts',
      created: 'Created on',
      edit: {
        title: 'Manage'
      },
      kind: 'Kind',
      label: 'Name',
      language: 'Language',
      modified: 'Last update',
      search: 'Search...',
      status: 'Status',
      subtitle: 'Manage and view contacts from your lists. \nCreate and manage your contact lists. ',
      title: 'Audience list'
    },
    create: 'Create',
    list: {
      add: 'Create a new campaign',
      attempt: 'Tentative name (s)',
      attempt_delay: 'Time between 2 attempts (on hour)',
      bot: 'bot',
      cancel: 'Cancel the campaign',
      cancel_ask: 'You are about to cancel this campaign, do you want to continue?',
      configuration_title: 'configuration',
      confirm_ask: 'The template variables are not correctly filled in, do you still want to validate the campaign?',
      contact_list: 'Audiences',
      contact_list_length: 'No contact | 1 contact | {0} contacts',
      conversation: 'Exchange',
      count: 'Number of sending',
      count_tooltip: 'This field corresponds to the total number of campaign, you can leave this empty field if you want your campaign sending automatically and indefinitely renewed.',
      create_to_import_vars: 'Finalize the creation of the campaign in order to import new variables.',
      date: 'Creation date',
      delete_ask: 'Do you really want to delete this campaign?',
      deleted: 'Campaign deleted',
      dtstart: 'Date and time of sending',
      edit: {
        title: 'Manage'
      },
      error: {
        csv_parsing_error: 'The CSV file format is incorrect.',
        'header:min:2': 'The CSV file must contain at least 2 columns with each their title.',
        'header:unique': 'The columns names of the CSV file must be unique.',
        invalid_start_date: 'The date and time of sending must be after the date and the day of the day',
        whatsapp_tier_limit_reached: 'You can only send {0} messages in 24 hour increments at this time. You have too many contacts in your audience, so if you want to send this campaign, you need to reduce the number of contacts in your audience.'
      },
      export: 'Download the report',
      fallback_template_list_details_rcs: 'This field allows you to define an SMS sending template when the recipient\'s mobile is not RCS compatible.',
      fallback_template_list_placeholder: 'Select a fallback template',
      fallback_template_list: 'Fallback template',
      fallback_template_vars_set: 'Assignment of fallback template\'s variables*',
      freq: 'Frequency',
      freqs: {
        3: 'Daily',
        2: 'Monthly',
        1: 'Weekly',
        0: 'Yearly'
      },
      frequency: 'Frequency (number of messages/hour)',
      import_vars: 'Import variables',
      import_vars_details: 'Combine new custom variables in the countryside by important a CSV file thanks to the "{0}" button below.',
      import_vars_ref_file: 'Reference file',
      import_vars_success: 'Variables imported',
      integration_list: 'Entry point',
      interval: 'Shipping interval',
      interval_tooltip: 'The sending interval is the time difference between each sending according to the defined frequency.',
      name: 'Name',
      opened: 'Opening',
      phone: 'Phone',
      recurrence: 'Recurrent campaign',
      rrule_disabled: 'Scheduling disabled',
      rrule_preview: 'Provisional dates of sending:',
      rrule_preview_footer: 'Unlimited number of sending - Display of the first 30 dates',
      saved: 'Update campaign',
      schedule_title: 'Scheduling',
      search: 'To research...',
      send: {
        campaign: 'Campaign',
        contact: 'Contact N° {0}',
        sent: 'Campaign sent manually',
        template_body: 'Template body',
        title: 'Manual dispatch',
        to: 'Phone number'
      },
      send_date: 'Send now?',
      skill_placeholder: 'Choose a skill',
      skill: 'Skill',
      stats: {
        clicks: 'Visual IVR opening rate',
        failed: 'Number of failures',
        gbagent: 'Exchange rate',
        link: 'Detailed statistics',
        read: 'The opening rate',
        sent: 'Number of sending',
        title: 'Statistics'
      },
      status: {
        CANCELLED: 'Canceled',
        FINISHED: 'Finished',
        MANUAL: 'Unscheduled',
        PENDING: 'Waiting',
        RUNNING: 'In progress',
        title: 'Status'
      },
      subtitle: 'Manage and consult your campaigns.',
      template: 'Template',
      template_list_placeholder: 'Choose a template',
      template_list: 'Template',
      template_var: 'Define {0}',
      template_vars: 'Import of variables',
      template_vars_set: 'Assignment of template\'s variables*',
      trees: `Your template contains rapid responses which are linked to tree structures.
        Before sending your campaign, make sure you have configured your various tree structures in the Bot Builder.

        If it is not done yet you can access it directly by clicking on the links below:`,
      type: 'type',
      types: {
        whatsapp: 'whatsapp'
      },
      tzid_placeholder: 'Choose a time zone',
      tzid: 'Time zone',
      vars: {
        contact: 'Audience\'s variables',
        custom: 'Custom variable',
        custom_placeholder: 'Custom variable name',
        others: 'Others',
        url_placeholder: 'Custom URL',
        webivr: 'Visual IVR links'
      },
      vars_file_report: {
        choose_var_per_colmun: 'Now assign your variables with the corresponding columns of your file in the drop-down lists above.',
        deduplicated_rows: '{0} set of imported variables | {0} set of imported variables | {0} sets of imported variables',
        matched_audience_contacts: '{0} compatible with the contacts of this campaign | {0} compatible with the contacts of this campaign | {0} compatible with the contacts of this campaigns',
        title: 'Import report of variables:'
      },
      variables: 'Import your variables in CSV format',
      variables_title: 'Definition of variables',
      view_agenda: 'agenda',
      view_list: 'Listing'
    },
    templates: {
      'add-button': 'Add button',
      back: 'Back to template list',
      body: 'Content',
      body_card: {
        description: 'Description',
        image: 'Image',
        title: 'Title'
      },
      body_details: 'To integrate the variable values in your message body, you have to write the variable number between 2 curly braces at the place you want. Example : {{1}}, {{2}}, etc.',
      body_kind: {
        rich_card: 'Rich card',
        text: 'Text',
        title: 'Content kind'
      },
      body_warning: 'This field is limited to {0} characters, please note that variables are not included here. | These fields are limited to {0} and {1} characters, please note that variables are not included here.',
      body_warning_sms: 'A SMS is limited to 160 characters, exceeding this quota will result in several SMS being sent. Please note that special characters count as more characters and variables are not included here.',
      'body*': 'Message body*',
      button: 'Interactive button',
      'button-content': {
        get button () {
          return Object.assign({}, this['quick-reply'], {
            too_many_buttons: 'You have selected the "Quick reply" template and it can only have a maximum of {0} buttons.'
          })
        },
        cta: {
          kind: {
            phone: 'Phone number',
            placeholder: 'Choose a button kind',
            title: 'Button kind*',
            url: 'External link'
          },
          phone: 'Phone number*',
          too_many_buttons: 'You have selected the "Call to action" template and it can only have a maximum of 2 responses.',
          url: 'URL*'
        },
        errors: {
          'no-emoji': 'You can\'t use emojis here'
        },
        'quick-reply': {
          channel_required: 'Please select a {0} account to set up a tree to associate with this button.',
          choose_tree_placeholder: 'Choose a tree',
          choose_tree: 'Choose a tree*',
          kind: {
            add_tree: 'Create a tree',
            existing_tree: 'Choose an existing tree',
            title: 'Model tree*'
          },
          too_many_buttons: 'You have selected the "Quick reply" template and it can only have a maximum of {0} responses.',
          tree_name: 'Tree name*'
        },
        text: 'Button text*'
      },
      'button-kind': 'Interactive button',
      'button-kind-options': {
        buttons: 'Buttons',
        'call-to-action': 'Call to action',
        'quick-reply': 'Quick reply'
      },
      categories: {
        ACCOUNT_UPDATE: 'Account update',
        ALERT_UPDATE: 'Alert update',
        APPOINTMENT_UPDATE: 'Update appointment',
        AUTO_REPLY: 'Automatic reply',
        ISSUE_RESOLUTION: 'Solving a problem',
        MARKETING: 'Marketing',
        PAYMENT_UPDATE: 'Update payment',
        PERSONAL_FINANCE_UPDATE: 'Update on personal finances',
        RESERVATION_UPDATE: 'Update reservation',
        SHIPPING_UPDATE: 'Shipping update',
        TICKET_UPDATE: 'Update a ticket',
        TRANSPORTATION_UPDATE: 'Transport update',
        UTILITY: 'Utility'
      },
      conversational: 'Conversational SMS',
      conversational_details: 'Enable this feature to select the tree to which you want to redirect the customer if they respond to your SMS campaign. The sender name will not be customisable in this case.',
      'category*': 'Category*',
      category_placeholder: 'Choose a category',
      category: 'Category',
      'channel*': 'Digital channel*',
      create: 'Submit the template',
      delete: 'Delete',
      delete_ask: 'Do you really want to delete this template?',
      deleted: 'Template deletion request sent!',
      error: {
        more_than_one_button_required: 'One or more button configuration is required for this type of interactive button',
        name_format: 'Only alphanumeric characters and underscores are allowed'
      },
      footer: 'Footer',
      gbm: {
        'channel*': '{0} account',
        channel_placeholder: 'Choose a {0} account'
      },
      header: 'Header',
      'header-kind': 'Header type',
      'header-content-image': 'Header image',
      'header-content-image_details': 'Recommended format: 1.91:1 aspect ratio, 5MB Max.',
      'header-content-text': 'Header text',
      'header-options': {
        image: 'Image',
        text: 'Text'
      },
      'language*': 'Language*',
      language: 'Language',
      language_placeholder: 'Choose a language',
      messenger: {
        'add-block': 'Add block',
        blocks: {
          block: 'Block',
          buttons: {
            button_action: {
              game_metadata_context: 'Game context',
              game_metadata_player_id: 'Game player id',
              payload: 'Payload',
              url: 'URL'
            },
            title: 'Title',
            type: {
              label: 'Type',
              options: {
                account_link: {
                  text: 'Log in'
                },
                game_play: {
                  text: 'Game'
                },
                phone_number: {
                  text: 'Phone call'
                },
                postback: {
                  text: 'Postback'
                },
                unaccount_link: {
                  text: 'Log out'
                },
                web_url: {
                  text: 'URL'
                }
              }
            }
          },
          defaultAction: {
            type: {
              label: 'Default action',
              options: {
                web_url: {
                  text: 'URL'
                }
              }
            },
            url: 'URL'
          },
          image: {
            label: 'Image'
          },
          subtitle: {
            label: 'Subtitle'
          },
          title: {
            label: 'Title'
          },
          type: {
            label: 'Type'
          }
        },
        'blocks*': 'Blocks',
        'block-kind*': 'Block type',
        'block-kind-options': {
          button: 'Button',
          generic: 'Generic',
          media: 'Media',
          receipt: 'Recepit'
        },
        button_action: {

        },
        errors: {
          'block-kind': 'block type not recognizable'
        },
        messenger_account_placeholder: 'Choose a Messenger account',
        'messenger_account*': 'Messenger account*',
        notification_kind: {
          recurring: 'Recurring notification',
          unique: 'Unique notification'
        },
        'notification_kind*': 'Notification kind*'
      },
      modal: {
        confirm_delete_button: 'Delete template',
        confirm_delete_msg: 'Are you sure you want to permanently delete this template?',
        confirm_delete_title: 'Deleteing template'
      },
      name: 'Name',
      'name*': 'Name*',
      preview: 'Preview',
      save_draft: 'Save draft',
      saved: {
        body: 'Your campaign template "{0}" has been saved, you can find it in the "template list" tab, you have to wait for its validation before you can publish it.',
        cancel: 'Return to the template list',
        confirm: 'Create another template',
        notification: 'Template draft saved',
        updated: 'Template updated',
        title: 'Saved'
      },
      sms: {
        categories: {
          MARKETING: 'Marketing',
          TRANSACTIONAL: 'Transactional'
        },
        category_conversational: 'Marketing campaigns are only available for non-conversational SMS.',
        'category*': 'Category',
        category_placeholder: 'Choose a category',
        channel_placeholder: 'Choose a SMS channel',
        'channel*': 'SMS channel',
        channel_required: 'Please select an SMS channel in order to set up a tree to associate.',
        type: 'Type of SMS'
      },
      sms_sender_name: 'Sender name',
      table: {
        add: 'Create a new template',
        category: 'Category',
        channel: 'Channel',
        created: 'Created',
        creator: 'Skill',
        language: 'Language',
        last_update: 'Last update',
        name: 'Template\'s name',
        status: 'Status',
        subtitle: 'Templates list'
      },
      search: 'Search',
      'skill*': 'Skill*',
      skill_placeholder: 'Choose a skill',
      status: {
        DRAFT: 'Draft',
        REFUSED: 'Refused',
        SUBMITTED: 'Submitted',
        UNKNOWN: 'Unknown',
        VALIDATED: 'Validated'
      },
      subtitle: 'Create your templates.',
      title: 'Templates',
      tree: 'Tree',
      variable_example: 'Variable example {0}',
      variables_examples: 'Variables examples',
      variables_examples_details: 'To facilitate the validation of your template, we recommend that you fill in examples of variables. Example: For a variable containing a first name, enter "John".',
      webivr_url: 'WebIVR link',
      'whatsapp_account*': 'WhatsApp account*',
      whatsapp_account: 'WhatsApp account',
      whatsapp_account_placeholder: 'Choose a WhatsApp account'
    },
    whatsapp_channel_required: 'Before you can access the campaigns, you must first link a WhatsApp account to ChayAll.'
  },
  cancel: 'Cancel',
  cannot_be_blank: 'Fill in this field',
  cannot_be_null: 'Please fill in this field',
  channel: {
    abc: {
      already_in_done_status: 'Account already associated',
      config_saved: 'Your request has been sent',
      details_required: 'To send your request again, please fill in the description field',
      faq: 'https://en.support.chayall.com/article/98-comment-configurer-apple-business-chat',
      form: {
        part: 'How to set up Apple Messages for Business?',
        subtitle: 'To set up Apple Messages for Business, you have to create an Apple Messages for Business account.\nTo do this, click on “Start”. You will be redirected to the Apple register page. Then, a ChayAll automatic mail will be sent to you to help you set up this messaging channel, step by step.'
      },
      ka_desc: 'Integrate a Messages iOS button to your website and communicate with your customers using Apple Messages for Business. Enhance the experience of your clients thanks to the many features it offers.',
      linked: 'Account associated!',
      linked_callback: 'Display messaging',
      linking: 'Associating your Apple Messages for Business account',
      send: 'Start',
      sent_content: 'We have received your request! We will get back to you shortly.',
      sent_title: 'Message sent',
      subtitle: "Allow your customers to contact you through Apple's messaging system by integrating an Apple Messages for Business widget into your website.\nThey will be able to contact you easily from their iPhones/iPads and make the most of all the features of this instant messaging system."
    },
    auto_msgs: {
      changes_saved: 'Your changes have been saved.'
    },
    common: {
      timezone_saved: 'Your time zone has been saved'
    },
    edit_confirmed: {
      body: 'We have received your request! We will get back to you shortly.',
      title: 'Change request received'
    },
    embed: {
      api_plugin: 'Copy the API key below and paste it to your plugin setup page',
      copied_to_clipboard: 'Integration code copied!',
      copy: 'Copy this code',
      copy_paste_to_gtm: `Click Tags> New.<br />
Click Tag Configuration and select Custom HTML.<br />
Copy the tag code provided below and paste it into the HTML field.`,
      copy_paste_to_your_html_page: 'Copy/paste the following integration code to the pages on which you want your channels displayed.',
      copy_paste_to_your_html_page_details: 'This code must be inserted into the widget displaying pages\'"body" tag',
      default: {
        download_plugin: 'Download the {0} ChayAll plugin:',
        download_plugin_btn: 'Download the plugin',
        install_plugin: 'Install plugin from {0} extensions',
        shop_link: '{0} shop'
      },
      embed_kind: {
        gtm: 'Google Tag Manager',
        javascript: 'Web',
        joomla: 'Joomla',
        make_a_selection: 'Make your choice',
        prestashop: 'PrestaShop',
        shopify: 'Shopify',
        url: 'URL',
        wordpress: 'WordPress'
      },
      select_an_integration_method: 'Select an integration method',
      url_desc: 'Use the URL link to integrate your digital channel on any digital medium (email signature, image, document, etc.)',
      url_select_channel: 'For which digital channel(s) do you want to obtain the link?',
      get gtm () {
        return Object.assign({}, this.default)
      },
      get prestashop () {
        return Object.assign({}, this.default)
      },
      get shopify () {
        return Object.assign({}, this.default, {
          download_plugin: 'Add the {0} ChayAll app to your shop:',
          install_plugin: 'Allow access to your shop when requested',
          shop_link: '{0} App Store'
        })
      },
      get web () {
        return Object.assign({}, this.default)
      },
      get wordpress () {
        return Object.assign({}, this.default)
      }
    },
    facebook_comments: {
      config_saved: 'Your Facebook account is now connected to ChayAll!',
      failed: 'Logging in to your Facebook has failed',
      faq: 'https://en.support.chayall.com/article/97-facebook-commentaires-configuration',
      form: {
        login_part: 'Login',
        page_part: 'Page selection',
        page_subtitle: 'Select the Facebook page you need to link to your ChayAll account.',
        subtitle: 'Log in to your Facebook account and set up the access to your Facebook page.'
      },
      ka_desc: 'Answer quickly to the comments of your client on Facebook and strengthen ties with them.',
      logged_out: 'You have logged out of your Facebook account',
      logout: 'Log out',
      subtitle: 'Manage your Facebook comments from your ChayAll Chat Console.'
    },
    faq: 'Need help?',
    fb_login: 'Connect to Facebook',
    googlebm: {
      blacklisted_domain: 'Only corporate addresses are allowed',
      config_saved: 'Your request has been sent!',
      detail: 'For security reasons, you will receive 2 emails from Google :\n- The first one will contain your personal security key\n- The second one will contain a message you\'ll have to customize and send back as an e-mail reply to Google, in order to authorize ChayAll as your messaging operator.\n\nWe will then keep you informed as soon as the "Message" widget is displayed on Google and Google Maps.',
      edit: 'Modify my request',
      faq: 'https://en.support.chayall.com/article/94-comment-configurer-google-business-messages',
      form: {
        subtitle: 'This information will not be displayed publicly and will only be used to process your business messaging account creation (and activation) with Google.'
      },
      ka_desc: 'Integrate a “Messages” button on your Google My Business, Google Maps or your Google ad. Your clients will then be able to contact you directly through the Google browser.',
      logo_url: {
        required: 'A logo is mandatory'
      },
      place_id: {
        required: 'A company is mandatory'
      },
      send: 'Confirm',
      send_edit: 'Confirm modification',
      subtitle: 'Enable your customers to text you directly from Google and Google Maps\' pages.\nBe where your customers are! Quick and easy to integrate.'
    },
    google_reviews: {
      config_saved: 'Your changes have been saved',
      faq: 'https://support.chayall.com/',
      form: {
        account_placeholder: 'Choose a Google Reviews account',
        account: 'Google Reviews account',
        choose: 'Choose your account',
        error: {
          unique: 'This location is not available because it is already associated with another channel.'
        },
        location: 'Location',
        login_placeholder: 'Choose a Google account',
        login: 'Google account'
      },
      ka_desc: 'Improve your customer relationship by interacting with your customers on their Google reviews.',
      logged: 'Google account connected',
      login: 'Sign in to Google',
      logout: 'Log out',
      subtitle: 'Centralize your Google reviews on our interface. You will be able to answer your customers from the chat console.'
    },
    instagram_comments: {
      config_saved: 'Your Instagram account is now connected to ChayAll!',
      facebook_insta: 'How to link your Facebook page to your Instagram account?',
      facebook_insta_link: 'https://en.support.chayall.com/article/83-comment-connecter-son-compte-instagram-professionnel-a-une-page-facebook',
      failed: 'Logging in to your Instagram has failed',
      faq: 'https://en.support.chayall.com/article/82-comment-configurer-instagram-commentaires',
      form: {
        login_part: 'Login',
        page_part: 'Page selection',
        page_subtitle: 'Select the Facebook page linked to your Instagram account.',
        subtitle: 'Log in to your Facebook account to start setting up Instagram.'
      },
      logged_out: 'You have logged out of your Instagram account',
      logout: 'Log out',
      ka_desc: 'Centralize all your interactions of Instagram and improve your customer satisfaction.',
      no_pro_account: "Don't have a professional Instagram account?",
      no_pro_account_link: 'https://en.support.chayall.com/article/74-comment-avoir-un-compte-instagram-professionnel',
      subtitle: 'Manage your Instagram comments from your ChayAll Chat Console.\nYour Instagram account must be a professional account.'
    },
    instagram_dm: {
      config_saved: 'Your Instagram account is now connected to ChayAll!',
      facebook_insta: 'How to link your Facebook page to your Instagram account?',
      facebook_insta_link: 'https://en.support.chayall.com/article/83-comment-connecter-son-compte-instagram-professionnel-a-une-page-facebook',
      failed: 'Connection to your Instagram account failed',
      faq: 'https://en.support.chayall.com/article/133-comment-configurer-instagram-direct',
      form: {
        login_part: 'Connection',
        page_part: 'Choice of page',
        page_subtitle: 'Choose the Facebook page linked to your Instagram account.',
        subtitle: 'Log in to your Facebook account to begin setting up Instagram.'
      },
      icebreakers: {
        button: 'Configure icebreakers',
        buttons: {
          add: 'Add tree'
        },
        deleted: 'Icebreakers deleted',
        'delete-body-icebreakers': 'Are you sur you want to delete all icebreakers?',
        'delete-title-icebreakers': 'Delete all icebreakers?',
        form: {
          delete: 'Delete',
          text: 'Label',
          to_tree: 'Tree'
        },
        list: 'List',
        locales: {
          add: 'Add language',
          'add-title': 'Add a new language?',
          choose: 'Choose a language',
          'default-explanation': '*Corresponds to American English or any other non-configured language.',
          'delete-body-locale': 'Are you sur you want to delete this language?',
          'delete-button': 'Delete language',
          'delete-title-locale': 'Delete the language?',
          default: 'Default*',
          list: {
            flags: {
              ar_AR: 'Arabic',
              cs_CZ: '🇨🇿',
              da_DK: '🇩🇰',
              de_DE: '🇩🇪',
              default: 'Default',
              el_GR: '🇬🇷',
              en_GB: '🇬🇧',
              es_ES: '🇪🇸',
              es_LA: 'Spanish (Latin America)',
              fi_FI: '🇫🇮',
              fr_FR: '🇫🇷',
              he_IL: '🇮🇱',
              hi_IN: '🇮🇳',
              hu_HU: '🇭🇺',
              id_ID: '🇮🇩',
              it_IT: '🇮🇹',
              ja_JP: '🇯🇵',
              ko_KR: '🇰🇷',
              nb_NO: '🇳🇴',
              nl_NL: '🇳🇱',
              pl_PL: '🇵🇱',
              pt_BR: '🇧🇷',
              pt_PT: '🇵🇹',
              ro_RO: '🇷🇴',
              ru_RU: '🇷🇺',
              sv_SE: '🇸🇪',
              th_TH: '🇹🇭',
              tr_TR: '🇹🇷',
              vi_VN: '🇻🇳',
              zh_CN: '🇨🇳',
              zh_HK: '🇭🇰',
              zh_TW: '🇹🇼'
            },
            labels: {
              ar_AR: 'Arabic',
              cs_CZ: 'Czech',
              da_DK: 'Danish',
              de_DE: 'German',
              default: 'Default language',
              el_GR: 'Greek',
              en_GB: 'English (United Kingdom)',
              es_ES: 'Spanish (Spain)',
              es_LA: 'Spanish (Latin America)',
              fi_FI: 'Finnish',
              fr_FR: 'French',
              he_IL: 'Hebrew',
              hi_IN: 'Hindi',
              hu_HU: 'Hungarian',
              id_ID: 'Indonesian',
              it_IT: 'Italian',
              ja_JP: 'Japanese',
              ko_KR: 'Korean',
              nb_NO: 'Norwegian (Bokmål)',
              nl_NL: 'Dutch',
              pl_PL: 'Polish',
              pt_BR: 'Portuguese (Brazil)',
              pt_PT: 'Portuguese (Portugal)',
              ro_RO: 'Romanian',
              ru_RU: 'Russian',
              sv_SE: 'Swedish',
              th_TH: 'Thai',
              tr_TR: 'Turkish',
              vi_VN: 'Vietnamese',
              zh_CN: 'Simplified Chinese (China)',
              zh_HK: 'Traditional Chinese (Hong Kong)',
              zh_TW: 'Traditional Chinese (Taiwan)'
            }
          }
        },
        saved: 'Icebreakers saved',
        title: 'Icebreakers'
      },
      ig_account_not_eligible_for_api: 'The Instagram account linked to this page is not eligible (see requirements above)',
      ka_desc: 'Communicate with your customers using Instagram Direct Messages on the chat console and improve your customer experience.',
      logged_out: 'Your Instagram account is now offline',
      logout: 'Logout',
      prerequisite: {
        content: `1/ Have a professional Instagram account (we explain how {0})
2/ On your phone, go to the settings > Confidentiality > Messages and activate "Allow access to messages".`,
        label: 'here',
        link: 'https://en.support.chayall.com/article/74-comment-avoir-un-compte-instagram-professionnel',
        title: 'Prerequisites'
      },
      subtitle: 'Only Instagram accounts with between 1,000 and 100,000 followers can use this service. Instagram will unblock this service for other accounts by the end of 2021.'
    },
    menu: {
      embedding: 'Integration',
      settings: 'Settings'
    },
    messenger: {
      config_saved: 'Your changes have been saved',
      failed: 'Logging in to Facebook account has failed',
      faq: 'https://en.support.chayall.com/article/59-comment-configurer-la-messagerie-messenger',
      form: {
        choose: 'Choose a page',
        login_part: 'Login',
        page_part: 'Page selection',
        subtitle: 'Connect to your Facebook account to start setting up Messenger.',
        subtitle2: 'Select your Facebook page to enable your customers to use Messenger via Facebook and your website to get in touch with you.'
      },
      ka_desc: 'Boost your sales and improve customer contacts management with Facebook Messenger’s instant messaging system.',
      logged_out: 'You have logged out of your Facebook account',
      pages: 'pages',
      subtitle: 'Use Messenger to chat with your customers. \nThey will be able to contact you from your Facebook page or the Messenger widget on your website.'
    },
    preview: 'Widget preview',
    preview_mobile: 'Preview',
    rcs: {
      config_saved: 'Your changes have been saved',
      form: {
        identifier: 'Identifier',
        serviceid: 'Service ID'
      },
      ka_desc: 'Communicate with your prospects and customers via RCS chats.',
      subtitle: 'Allow your customers to exchange with your customers by RCS.\nSimple and fast to integrate. Just add this channel and then send an RCS message to your customer to trigger an exchange. This information will not be public.'
    },
    refresh: 'Refresh authentification token',
    send_button: 'send_button',
    send_confirmed: {
      body: 'Your request has been sent ! We will get back to you shortly.',
      title: 'Message sent'
    },
    settings: {
      get status_creating () { return this.status_in_progress },
      get status_done () { return this.status_active },
      get status_enabled () { return this.status_available },
      get status_ok () { return this.status_active },
      hide: 'Hide channel in the widget',
      hide_details: 'Be careful, if you hide this channel, your users will no longer see it in your widget and will no longer be able to contact you via this channel.',
      show: 'Show messaging in the widget',
      show_details: 'Are you sure you want to redisplay this mailbox and make it visible again in your widget?',
      status_active: 'Active',
      status_auth_ko: 'Error',
      status_available: 'Set up',
      status_in_progress: 'In progress',
      status_not_visible: 'Offline'
    },
    sms: {
      config_saved: 'Your changes have been saved',
      form: {
        brand: 'Brand name',
        service_id: 'Service ID'
      },
      ka_desc: 'Communicate with your prospects and customers via SMS chats.',
      subtitle: 'Allow your customers to exchange with your customers by text message.\nSimple and fast to integrate. Just add this channel and then send an SMS to your customer to trigger an exchange. This information will not be public.'
    },
    to_embed: 'Once your settings confirmed, go to "Integration" and integrate your channel into your website',
    telegram: {
      config_saved: 'Your changes have been saved',
      faq: 'https://en.support.chayall.com/article/99-comment-configurer-la-messagerie-telegram',
      form: {
        token: 'Telegram token'
      },
      ka_desc: 'Sell, advise and support your customers from the ultra secure and encrypted instant messaging: Telegram.',
      logged_out: 'You have logged out of your Telegram account',
      logout: 'Logout',
      subtitle: 'Chat with your customers via Telegram! To connect your Telegram account to our interface, please enter the token you received during the creation of your Telegram bot.'
    },
    trustpilot: {
      config_saved: 'Your changes have been saved',
      faq: 'https://en.support.chayall.com/',
      form: {
        choose: 'Choose an account',
        login_part: 'Login',
        business_unit_part: 'Trustpilot account choice',
        subtitle: 'Log in to your Trustpilot account to start the setup.',
        subtitle2: 'Find and select the business unit you want to link to our interface.'
      },
      ka_desc: 'Integrate Trustpilot to centralize all the reviews of your clients and get to know their opinions better.',
      logged: 'Trustpilot account logged in',
      login: 'Truspilot login',
      logout: 'Logout',
      subtitle: 'Centralize your Trustpilot reviews on our interface. You will be able to respond to your customers from the chat console.'
    },
    twitter_dm: {
      config_saved: 'Your changes have been saved',
      failed: 'Logging in to X account has failed',
      faq: 'https://en.support.chayall.com/',
      form: {
        login_part: 'Login',
        page_part: 'Finalize the settings',
        subtitle: 'Log in to your X account to begin the settings.'
      },
      ka_desc: 'Enable your customers to contact you via X Direct Messages and save time by gathering all your conversations onto the same platform.',
      logged_out: 'You have logged out of your X account',
      logout: 'Log out',
      subtitle: 'Use X to chat with your clients. \nThey will be able to contact you from your X page or the X widget on your website.'
    },
    twitter_comments: {
      config_saved: 'Your X account is now connected to ChayAll!',
      failed: 'Logging in to your X has failed',
      faq: 'https://en.support.chayall.com/',
      form: {
        login_part: 'Login',
        page_part: 'Finalize the settings',
        subtitle: 'Log in to your X account to begin the settings.'
      },
      ka_desc: 'Centralize all the interactions with your clients on X onto the same platform and increase your customer satisfaction.',
      logged_out: 'You have logged out from your X account',
      logout: 'Log out',
      subtitle: 'Manage your X comments from your ChayAll Chat Console.'
    },
    web_chat: {
      bad_color: 'The color you have chosen is not valid',
      color: 'Color',
      color_required: 'A choice of color is required',
      config_saved: 'Your changes have been saved',
      default_punchline: 'We are here to help',
      faq: 'https://en.support.chayall.com/article/60-comment-configurer-le-live-chat',
      ka_desc: 'With ChayAll, integrate Live Chat in 3 minutes on your website. It’s quick and easy.',
      subtitle: 'Allow your customers to contact you through your website.\nCustomize your Live Chat and integrate it within minutes.',
      title: 'Settings of Live Chat',
      widget: {
        author: 'by ChayAll',
        input: 'Type your message...',
        msg1: 'Hello, how can we help you?',
        msg1_date: '10:18',
        msg2: "I'm looking for your silver sneakers. Size 6,5?",
        msg2_date: '10:20',
        msg3: 'Are these the ones you are looking for? 😊',
        msg3_date: '10:21'
      }
    },
    whatsapp: {
      ask_for_subscription: 'Subscribe to full plan',
      back_to_free: 'Return to free plan',
      change_form: {
        details: 'Please specify the changes you would like to make to your WhatsApp profile.',
        sent: 'The changes you have requested have been sent',
        title: 'Requesting a change'
      },
      config_saved: 'Your request has been sent.',
      faq: 'https://en.support.chayall.com/article/35-comment-configurer-whatsapp-offre-gratuite',
      form: {
        subtitle: 'All you have to do is click on "Activate". Then proceed to the next step and integrate a WhatsApp widget on your website.',
        subtitle_full: 'Interact with your customers via WhatsApp and make the most of all its features! Account creation can take a few days before activation.\n\nPlease fill in this form. All of this information will be visible on your {0}.',
        title: "It's your ball game now!",
        wa_profil_label: 'WhatsApp profile',
        wa_profil_link: 'https://en.support.chayall.com/'
      },
      free: 'This channel service is only available for the Essential, Pro and Enterprise offers.',
      ka_desc: 'Add a WhatsApp button on your website. Enable your customers to contact you via WhatsApp and save time by gathering all your conversations onto the same platform.',
      language: 'language',
      send: 'Confirm',
      subtitle: 'Enjoy WhatsApp Business free of charge. Please note that with this version, your WhatsApp profile will be in the name of ChayAll.'
    }
  },
  channels: {
    abc: {
      form: {
        details: 'Tell us more about what you require'
      }
    },
    auto_msgs: {
      changes_saved: 'Your changes have been saved.'
    },
    channel_to_activate: {
      description: 'Click on one of these icons to add to your website your choice of digital channel.'
    },
    facebook_comments: {
      form: {
        page_id_placeholder: 'Choose a Facebook page',
        page_id: 'Your Facebook page'
      }
    },
    googlebm: {
      form: {
        brand_contact_email_address: 'Contact email',
        brand_contact_email_address_details: 'Only corporate addresses are allowed',
        brand_contact_name: 'Family name of main contact',
        brand_name: 'Brand name',
        brand_website_url: 'Brand website',
        logo_url: 'Logo',
        logo_url_details: 'Recommended : Square format, 640x640px, 5MB max.',
        place_id: 'Your business on Google',
        privacy_policy: 'Web page of your privacy policy (publicly accessible URL)',
        welcome_message: 'Welcome message'
      }
    },
    instagram_comments: {
      form: {
        page_id_placeholder: 'Choose a Facebook page',
        page_id: 'Your Facebook page linked to the account'
      }
    },
    instagram_dm: {
      form: {
        page_id_placeholder: 'Choose a Facebook page',
        page_id: 'Your Facebook page linked to the account'
      }
    },
    key_account: {
      form: {
        label: 'Messaging label',
        skill_placeholder: 'Choose a skill',
        skill: 'Linked skill'
      }
    },
    messenger: {
      form: {
        choose: 'Choose a page',
        page_id_placeholder: 'Choose a Facebook page',
        page_id: 'Your Facebook page'
      }
    },
    no_embedding: 'No entry point has been set up',
    timezone: {
      placeholder: 'Choose a timezone',
      title: 'Time zone'
    },
    visibility_toggle: 'Online digital channel',
    visibility_toggle_helper: 'Make the digital channel accessible or not to users',
    visibility_toggle_details: 'You must save to apply your change',
    web_chat: {
      form: {
        color: 'Color',
        logo: 'Your business logo',
        logo_details: 'Square format, recommended 640x640px, 5MB max.',
        logo_required: 'Your company logo is required',
        name: 'Name of company',
        name_required: 'Your company name is required',
        powered_by: 'Show mention "by ChayAll"',
        punchline: 'Catchphrase',
        punchline_details: "This corresponds to the sentence in the chat window's header.",
        punchline_required: 'A catchphrase is required'
      }
    },
    whatsapp: {
      config_saved: 'Your request has been sent',
      config_saved_b: 'Congratulations! Your request for a WhatsApp account has been submitted!',
      detail: 'Please be patient... Your WhatsApp Business account will be confirmed in the next few days. You will be informed by email as soon as you can begin conversations with your customers through WhatsApp.',
      edit: 'Change my request',
      form: {
        business_name: 'Business name',
        join: '<span class="tag">WhatsApp</span>Pre-filled message to be sent by the user',
        join_details: 'This message will pre-fill the WhatsApp text field and must be sent by the clientbefore a conversation can begin. \nChoose the language for this message.'
      },
      form_full: {
        brand_logo: 'Logo',
        brand_logo_details: 'Square format, recommended 640x640px, 5 MB max.',
        business_desc: 'Description of the business',
        business_desc_details: '256 characters max',
        business_name: 'Business name',
        city: 'Town',
        country: 'Country',
        email: 'contact email address',
        existing_phone_number: 'Use an existing phone number',
        facebook_business_manager_id: 'Facebook Business Manager ID',
        facebook_business_manager_id_details: '<a class="gb-link faq" href="{0}" target="_blank">{1}</a>',
        facebook_business_manager_id_details_label: 'Where can I find my Facebook Business Manager ID?',
        facebook_business_manager_id_details_link: 'https://en.support.chayall.com/',
        phone_number: 'Phone number',
        phone_number_details: 'The phone number must not be connected to an existing personal or business WhatsApp account',
        postal_address: 'Address',
        postal_code: 'zip code',
        url: 'Brand website'
      },
      join_saved: 'Your changes have been saved',
      phone_number: 'Here is the phone number associated to your WhatsApp account :',
      send: 'Send my request',
      send_edit: 'Send change'
    }
  },
  chart: {
    axys_day_format: '%b %e',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    shortMonths: [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'June',
      'July',
      'Aug.',
      'Sept.',
      'Oct.',
      'Nov.',
      'Dec.'
    ],
    tooltip_day_format: '%A, %b %e, %Y',
    weekdays: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ]
  },
  click_here_to_choose_a_file: 'Click here to select a file',
  click_here_to_choose_a_new_file: 'Click here to change the file',
  close: 'Close',
  close_modal: {
    close: 'Close',
    surveys: {
      empty: 'To send a satisfaction survey to your customer, simply create one in the "Satisfaction Surveys" section of your Settings.',
      send_and_close: 'Send and close',
      send_survey: 'Suggest to your customer to leave a review of your discussion.'
    }
  },
  cobrowsing: {
    invit: 'Start a co-browsing session',
    join: 'Show co-browsing',
    rich_content: 'Co-browsing being initialized...'
  },
  confirm_email: {
    button: 'Send email',
    subtitle: 'To access {APP_NAME}, please confirm your email address',
    title: 'Confirm your email address ☺️'
  },
  crm: {
    button_label: 'CRM search',
    no_result: 'No CRM contact matches the discussion\'s client information.',
    search_results: 'CRM search results',
    select_contact: 'Associate contact'
  },
  customer_quote: {
    content: `“ChayAll is the best business messaging<br>
      platform in the market right now.<br>
      They're available if need be,<br>
      and they launch new features<br>
      all the time!”`,
    title: "Brew's Immo"
  },
  dashboard: {
    bots: 'Bots',
    channels: 'Digital channels',
    key_account: {
      activity: {
        'log-duration_details': 'Expressed in hh:mm',
        'status-1': 'Available',
        'status-2': 'Discussion in progress',
        'status-3': 'Maximum discussion number reached',
        'status-4': 'On pause',
        'status-5': 'Disconnected',
        agent_skills: 'Associated skills',
        agent_running: 'Ongoing',
        answered: 'Answers sent',
        answers: 'Replies sent',
        autofill: {
          disabled: 'Automatic assignment of conversations disabled',
          enabled: 'Automatic assignment of conversations enabled'
        },
        average_close_transfer: 'ACT (mm:ss)',
        average_stars: 'Average score',
        average_take: 'AWT (mm:ss)',
        closed: 'Processed',
        deleted: 'Deletions',
        deleted_answer: 'Deleted',
        dma: 'AWT (mm:ss)',
        dma_details: 'Average conversation waiting time for current day (mm:ss)',
        dmt: 'ACT (mm:ss)',
        dmt_details: 'Average conversation duration for current day (mm:ss)',
        error: {
          'agent:forbidden': 'You do not have the necessary permissions to change the status of this advisor.'
        },
        iterations_details: 'Number of exchanges (current day conversations)',
        iterations: 'Interactions',
        late_rooms: 'Number of late conversations for this agent',
        log_duration: 'Log time',
        logout_user: 'Logout the agent',
        msg_sent: 'Mess. sent ',
        name: 'Name',
        nps: 'NPS',
        pause_user: 'Pause the agent',
        ratio: 'Disc. / Hour ',
        read: 'Seen',
        rooms_closed_details: 'Correspond to the number of conversations finalized by the agents',
        rooms_closed: 'Closed',
        rooms_running: 'Ongoing',
        rooms_total: 'Taken',
        rooms_transferred: 'Transferred',
        satisfaction: 'Satisf.',
        status: 'Status',
        subtitle: 'Active agents',
        taken: 'Taken',
        title: 'Agents activity',
        updated_answer: 'Modified'
      },
      campaigns: {
        global_data: {
          delivered_percent: 'Deliverability rate',
          delivered_percent_helper: 'Average percentage of delivered messages compared to sent messages over the given period',
          delivered: 'Delivered',
          delivered_helper: 'Number of messages delivered over the given period',
          failed: 'Failed',
          failed_helper: 'Number of failed messages over the given period',
          seen_percent: 'Open rate',
          seen_percent_helper: 'Average percentage of messages opened compared to messages sent over the given period',
          seen: 'Opened',
          seen_helper: 'Number of messages opened in the given period',
          sent: 'Sent',
          sent_helper: 'Number of messages sent over the given period',
          title: 'Global data',
          visual_svi_opened: 'Visual IVR opening',
          visual_svi_opened_helper: 'Number of visual IVR opened in the given period',
          x: 'Selected interval',
          y: 'Number of messages'
        },
        group_by: {
          campaign: 'Per campaign',
          delivery_batch: 'Per dispatch',
          template_kind: 'Per channel'
        },
        interval: {
          day: 'Per day',
          month: 'Per month',
          week: 'Per week'
        },
        messages_per_interval: 'Messages per interval',
        messages_per_interval_helper: 'The intervals list may vary depending on the selected time period.',
        table: {
          kind: 'Type',
          name: 'Campaign name',
          send_at: 'Sending date and time'
        },
        title: 'Campaigns'
      },
      comments: {
        title: 'Comments'
      },
      contact_reasons_count: 'Number of occurrences',
      discussions: {
        channels: 'Messaging channels',
        closed: 'Closed',
        created: 'Incoming',
        msg_sent: 'Messages sent',
        nps: 'NPS',
        running: 'Ongoing',
        running_title: `Ongoing

This column is not affected by selected filters and displays values in real time.`,
        satisfaction: 'Satisfaction',
        taken: 'Taken',
        title: 'Conversations',
        transferred: 'Transferred',
        waiting: 'Waiting',
        waiting_title: `Waiting

This column is not affected by selected filters and displays values in real time.`
      },
      export: 'Export data',
      filter_by: 'Filter by:',
      global: {
        active_agents: 'Active agents',
        active_agents_details: 'Total number of active agents at time T',
        avg_running: 'Avg conv time',
        avg_running_details: 'Average Conversation Time: Average chat duration for current day (hh:mm)',
        avg_satisfaction: 'Sat rate',
        avg_satisfaction_details: 'Agents’ average rating',
        avg_waiting: 'Avg waiting time',
        avg_waiting_details: 'Average Waiting Time: Average chat waiting time for current day (hh:mm)',
        paused_agents: 'Agents on break',
        paused_agents_details: 'Total number of agents on break at time T'
      },
      groups: 'Skills groups',
      groups_webivr: 'IVR groups',
      integrations: 'Entry points',
      msg_per_channel: {
        read: 'Received',
        sent: 'Sent',
        x: 'Social networks',
        y: 'Number of comments'
      },
      reviews: {
        answered: 'Sent anwsers',
        average_stars: 'Average score',
        average_stars_details: 'Average score of the ratings received since the integration of the reviews on ChayAll',
        closed: 'Processed',
        deleted_answer: 'Deleted',
        incoming: 'Incoming',
        platforms_score: {
          details: 'Average score per platform over the given period',
          title: 'Average score per platform',
          x: 'Platforms',
          y: 'Average scores'
        },
        reviews_per_status: {
          title: 'Number of reviews per status',
          x: 'Channels',
          y: 'Number of reviews'
        },
        running: 'Ongoing',
        title: 'Data per platform',
        updated_answer: 'Modified',
        waiting: 'Pending'
      },
      rooms_per_channel: {
        title: 'Total conversations by channels',
        x: 'Messaging channels',
        y: 'Number of conversations'
      },
      rooms_per_status: {
        title: 'Total conversations by status',
        x: 'Selected interval',
        y: 'Number of conversations'
      },
      skills: 'Skills',
      subaccounts: 'Subaccounts',
      subtitle: 'Find the essential figures of your activity, for more details \n go to {0}',
      template_kinds: 'Campaign kinds',
      threads: {
        answers: 'Replies sent',
        dealt: 'Dealt',
        deleted: 'Deletions',
        in: 'Incoming',
        read: 'Seen',
        social_networks: 'Social networks',
        title: 'Threads'
      },
      title: 'Overall activity',
      webivr: {
        distribution: {
          title: 'Distribution'
        },
        export_events: 'Events by Visual IVR',
        export_forms: 'Forms details',
        flow: {
          title: 'Customer analytics'
        },
        journey: {
          title: 'Customer journey analysis',
          x: 'Total over the selected interval',
          y: 'Quantity'
        },
        productivity: {
          choice: 'Choice',
          choice_details: 'Number of clicks on a choice per single session',
          choice_percent: '% Choix',
          choice_percent_details: 'Number of clicks on a choice compared to the number of Visual IVR openings',
          day: 'Per day',
          gbagent_percent: '% Contact',
          gbagent_percent_details: 'Number of contact requests compared to the number of IVR Visual openings',
          gbagent: 'Contact request',
          gbagent_details: 'Number of contact requests compared to the number of IVR Visual openings',
          half_hour: 'Per half hour',
          hour: 'Per hour',
          interval: 'Interval',
          month: 'Per month',
          open_percent: '% Open',
          open_percent_details: 'Number of IVR Visual openings compared to the number of SMS sent',
          open: 'Open Visual IVR',
          open_details: 'Number of Visual IVR openings per unique session',
          proposal_details: 'Number of user sessions created',
          proposal: 'Visual IVR proposal',
          quarter_hour: 'Per quarter of an hour',
          selfcare_percent: '% Resolved',
          selfcare_percent_details: 'Number of auto-resolutions compared to the number of IVR openings Visual',
          selfcare: 'Auto. resolution',
          selfcare_details: 'Number of clicks on a URL or FAQ per unique session',
          sms_details: 'Number of SMS sent over the given period',
          sms_percent: '% SMS',
          sms: 'SMS',
          subtitle: 'Activity',
          title: 'Productivity of your Visual IVR',
          week: 'Per week'
        },
        sankey: {
          all_versions: 'All versions',
          empty_list: 'Please select a visual IVR to display its versions',
          trees: 'Trees',
          usage_rate_min: 'Minimum throughput rate (%)',
          versions: 'Versions'
        },
        volume: {
          title: 'Volume',
          x: 'Selected interval',
          y: 'Quantity'
        }
      }
    },
    please_configure_channel: 'To get started, activate a digital channel.',
    please_configure_channel_subtitle: 'No digital channel activated yet. Set up a service to begin a conversation with your clients.',
    please_confirm_email: 'Please confirm your email by clicking on the link received',
    selected_channel: '{0} digital channel selected',
    selected_channels: '{0} digital channels selected',
    subtitle: 'Track your activity in real-time',
    title: 'Dashboard',
    over_quota: 'You have reached your monthly messages limit, upgrade your plan to keep on sending & receiving messages'
  },
  diagram: {
    actions: {
      api: 'API',
      button: 'Button',
      call: 'Direct call',
      callback: 'Callback',
      campaign_template: 'Campaign template',
      campaigns_contactlist: 'Campaign optin',
      chat: 'Chat Console',
      choice: 'Choice',
      choice_fallback: 'Choice not detected',
      delete: 'Delete',
      dialogflow: 'Dialogflow agent',
      dialogflow_intent: 'Intent',
      dialogflow_end: 'End of AI interaction',
      dialogflow_fallback: 'Non-detection of intent',
      entrypoint: 'Entrypoint',
      console: 'External console',
      forms: {
        button: {
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Button subtitle',
            'error-msg': `The subtitle is required for WhatsApp lists with more than 3 choices
            <span title="If you provide a list with 3 items or less, the button subtitle will be ignored in WhatsApp">
            [?]</span>`,
            'error-too-long-19-msg': 'The subtitle is too long'
          },
          title: {
            placeholder: 'Button title',
            'error-too-long-23-msg': 'The title is too long'
          }
        },
        callback: {
          eligible_for_contact_request: {
            placeholder: 'Contact request'
          },
          title: {
            placeholder: 'Button title'
          },
          thirdparties_id: {
            placeholder: 'Callback'
          }
        },
        campaigns_contactlist: {
          contact_list: 'Target audience'
        },
        choice: {
          thirdparties_id: {
            placeholder: 'Callback identifier'
          },
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Choice subtitle',
            'error-too-long-71-msg': 'The subtitle is too long'
          },
          test_kind: {
            options: {
              none: 'None',
              callback: 'Callback'
            },
            placeholder: 'Display test'
          },
          title: {
            'error-msg': 'The title is too long',
            'error-too-long-23-msg': 'The title is too long',
            placeholder: 'Choice title'
          },
          toogle: {
            placeholder: 'Conditional display'
          }
        },
        choice_fallback: {
          details: 'Define a path to redirect your customer to if the user enters text instead of making a choice',
          title: 'Choice not detected'
        },
        console: 'External console',
        'custom-tag': {
          details: 'This label will be visible in your reports and in customer analytics',
          duplicates: 'This custom label is already in used',
          label: 'Action\'s label',
          placeholder: 'Type a custom label'
        },
        'css-classes': {
          label: 'CSS Classes',
          placeholder: 'Type CSS classes to customize layout'
        },
        'default-tag': 'Default label',
        dialogflow: {
          title: 'Dialogflow',
          label: 'Agent',
          details: 'Select the AI Dialogflow you want to activate in your navigation',
          wait_input: 'Wait for a user input'
        },
        dialogflow_end: {
          title: 'End of AI interaction',
          details: 'Define a path to redirect your customer to in case identified intent is an end of conversation'
        },
        dialogflow_fallback: {
          title: 'Non-detection of intent',
          details: 'Define a path to redirect your customer to in case we can\'t identify their intent'
        },
        dialogflow_intent: {
          title: 'Intent'
        },
        errors: {
          'custom_node_label:unique': 'This label is already in used',
          'label:unique': 'This label is already in used',
          unknown_identifier: 'Callback identifier unknown'
        },
        faq: {
          title: 'FAQ',
          eligible_for_selfcare: {
            placeholder: 'Self care'
          },
          faq: {
            placeholder: 'Select an FAQ'
          }
        },
        form: {
          title: 'Form',
          form: {
            placeholder: 'Select a form'
          },
          is_survey: 'Satisfaction survey',
          score_ranges: 'Score redirections'
        },
        gbagent: {
          auto_msgs: 'Auto. messages',
          auto_msgs_agents: 'Automatic messages played according to the availability of your agents',
          auto_msgs_cal: 'Automatic messages played according to your calendar',
          title: 'Chat Console'
        },
        image: {
          title: 'Image'
        },
        messenger_optin_request: {
          contact_list: 'Target audience',
          image: 'Image',
          title: 'Title'
        },
        messaging: {
          title: {
            placeholder: 'Entry point'
          }
        },
        page: {
          icon: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Page subtitle'
          },
          title: {
            'error-msg': 'Title is too long',
            placeholder: 'Page title'
          },
          template: {
            placeholder: 'Select a theme',
            title: 'Theme'
          }
        },
        ravana: {
          title: 'Conversational agent',
          label: 'Agent',
          details: 'Select the conversational agent you want to activate in your navigation',
          wait_input: 'Wait for a user input'
        },
        routing_load_balancer: {
          subtitle: 'Define the percentages on which to make load balancing',
          title: 'Load balancing'
        },
        totree: {
          title: 'Tree'
        },
        text: {
          title: {
            placeholder: 'Texte'
          }
        },
        txt: {
          'editor-title': 'Text',
          txt: {
            'error-msg': 'Title is too long',
            placeholder: 'Type the text sent by the bot',
            variable_not_exists: 'The variable {0} does not exist | The following variables do not exist: {0}'
          },
          wa_vars: 'Personalize your exchanges with your customers by using the following variable: {username}. For example: "Hello {username}, how can I help you?"'
        },
        url: {
          eligible_for_contact_request: {
            placeholder: 'Contact request'
          },
          eligible_for_selfcare: {
            placeholder: 'Self care'
          },
          email: {
            placeholder: 'Email'
          },
          image: {
            placeholder: 'Image'
          },
          subtitle: {
            placeholder: 'Button subtitle'
          },
          tel: {
            placeholder: 'Phone number'
          },
          url: {
            'error-msg': 'The url you provided can\'t be recognized as a valid URL',
            placeholder: 'URL'
          },
          title: {
            placeholder: 'Button title'
          }
        },
        'user-input': {
          subtitle: 'Init a user input',
          title: 'User input'
        },
        'user-input-check': {
          placeholder: 'Add a keyword ...',
          subtitle: 'Add keywords (using the Enter key) that will allow the bot to understand and analyze the user\'s response',
          title: 'User input'
        },
        'user-input-error': {
          placeholder: 'Type your error message ...',
          subtitle: 'Write the error message the bot will send if the entry is incorrect',
          title: 'Error message'
        }
      },
      image: 'Image',
      job: 'Job',
      link: 'URL',
      messenger_optin_ko: 'Opt-in ignored',
      messenger_optin_ok: 'Opt-in accepted',
      messenger_optin_request: 'Campaign optin',
      ravana: 'Conversational agent',
      ravana_output: {
        title: 'Output',
        kind: {
          default: 'Default output',
          unknown: 'Unknown output'
        }
      },
      routing_load_balancer: 'Load balancing',
      get routing () {
        return this.routing_load_balancer
      },
      passthrough: {
        title: 'Output'
      },
      get routing_output () {
        return this.passthrough
      },
      text: 'Text',
      tooltips: {
        button: 'Embed choices in a button',
        gbagent: 'Connect your customers with your agents',
        choice: 'Offer a list of choices to your customers',
        console: 'Redirect your customers to your external consoles',
        dialogflow: 'Interpret customer intent with AI',
        image: 'Send an image to your client',
        totree: 'Redirect your client to the beginning of a tree structure',
        txt: 'Send a message text to your client',
        url: 'Send a link to your client',
        input: 'Wait for a user input and analyze it with the action "user input check"',
        input_check: 'Analyze the user input with keywords',
        input_error: 'Send an error message when the bot fails to analyze the user input'
      },
      totree: 'Tree',
      split: 'Extract',
      start: 'Start your bot',
      survey: 'Survey',
      update_root: 'Update root',
      update_parent: 'Update parent',
      'user-input': 'User input',
      'user-input-check': 'User input check',
      'user-input-error': 'User input error'
    },
    'confirm-delete': {
      delete_all: 'Global deletion',
      delete_one: 'Unit deletion',
      subtitle: 'Do you want to delete only the selected action or all the actions linked to it ?',
      title: 'Delete',
      webivr: {
        subtitle: 'Be careful, if you delete this action, all those attached to it will also be deleted.'
      }
    },
    'copy-paste-nodes': {
      'copy-ok': 'Copy OK',
      'paste-ok': 'Paste OK',
      'paste-totree-warn': 'Warning, you have copied/pasted one or more "tree" actions from another bot builder, remember to update them in your current bot builder'
    },
    errors: {
      bad_validation_node_dialogflow_forbidden_kinds: `This action can only be
        followed by Dialogflow "Intents" and "Non-detection of intent".`,
      bad_validation_node_ravana_forbidden_kinds: `This action can only be
        followed by automatically generated outputs.`,
      bad_validation_node_button_requires_choice: `This action can only be
        followed by "Choice" and one "Choice not detected".`,
      bad_validation_node_choice_cant_be_root: `A node whose action
        is "Choice" can't be root`,
      bad_validation_node_choice_fallback_cant_be_root: `A node whose action
        is "Choice not detected" can't be root`,
      bad_validation_node_entrypoints_cant_has_different_children: `A node whose
       action is "Entry point" or "Campaign template" cannot have different children from other nodes of the same type.`,
      bad_validation_node_input_cant_be_root: `A node whose action is
        "User input" or "User error" can't be root`,
      bad_validation_node_input_check_error_only_child_input: `This action can
        only be preceded by a "User input".`,
      bad_validation_node_input_only_one_error: `The nodes whose
         the action is "user input" only accept one node with
         the "user input error" action.`,
      bad_validation_node_input_requires_check_error: `This action can only be
        followed by "User input check" and "User input error".`,
      bad_validation_node_messenger_optin_output_cant_be_root: `A node whose action is
        "Opt-in accepted" or "Opt-in ignored" can't be root`,
      bad_validation_node_messenger_optin_output_only_child_messenger_optin_request: `This action can only be
        followed by "Campaign optin".`,
      bad_validation_node_messenger_optin_request_too_much_children: `An "Opt-in campaign" node can only be followed
        by a single node, several "Choice" nodes or by two nodes: "Opt-in accepted" and "Opt-in ignored".`,
      bad_validation_node_not_button_too_much_children: `Cannot add more
        than one action after the following actions: Text, Url, Image, Start your bot,
        Chat console, User input check, User input error, Choice.
        Unless they are "Choice" actions and one "Choice not detected" action.`,
      bad_validation_node_routing_only_child_output: 'This action can only be followed by "Output" actions.',
      bad_validation_node_too_much_choice_wa: `More than 3 choices is forbidden
        with WhatsApp`,
      bad_validation_node_too_much_choice_wa_list_picker: `More than 10 choices
        is forbidden WhatsApp with a list picker`,
      bad_validation_node_totree_cant_be_root: `A node whose action
       is "Tree" can't be root`,
      bad_validation_node_totree_cant_have_children: `You can't add an action
        after the "Tree" action.`,
      bad_validation_cant_drop_node_on_itself: 'Each action has to be related to at least one other action.',
      bad_validation_existing_node_cant_drop_on_connector: `You cannot
        moved an existing node between two other nodes.`,
      general: 'General error',
      unauthorized_action: 'Unauthorized action',
      warning: 'Warning'
    },
    faq: 'https://en.support.chayall.com/article/108-comment-fonctionne-le-bot-builder',
    'floating-dropdown': {
      copy: 'Copy action and children',
      edit: 'Edit',
      paste: 'Paste action and children',
      paste_and_replace: 'Replace action and children'
    },
    ai: {
      'intent-added': 'Intents added'
    },
    punchline: 'Drag and drop to the right',
    title: 'Actions'
  },
  download: 'Download',
  email_does_not_exist: 'The provided email can\'t be found.',
  finish: 'Finish',
  input_channel_trees: {
    prefilled_msg_placeholder: 'Prefilled message',
    prefilled_msg_tooltip: 'This message will be pre-filled in the WhatsApp text field and must be sent by the customer to start the discussion.',
    tree_placeholder: 'Select tree'
  },
  import: 'Import',
  install: 'Install',
  stats: {
    api: 'API',
    browsing_through_ivr: 'Browsing through the trees',
    exports: {
      dialogflow: 'AI analysis',
      events: 'Events per discussion',
      rooms: 'Conversations per status',
      smartcoaching: 'Smartcoaching'
    },
    please_choose_bot: 'Please select a bot to view customer analytics',
    please_choose_tree: 'Please select a Visual IVR, a version and a tree to display customer analytics',
    please_choose_webivr: 'Please select a Visual IVR to display customer analytics',
    please_choose_another_data: 'No data on the period and/or the Visual IVR and/or the selected entry point(s)',
    please_choose_another_data_tree: 'No data on the period and/or the selected tree',
    title: 'Statistics',
    subtitle: 'Closely track your activity thanks to your statistics',
    today_msg_count: {
      desc: 'Message count today'
    },
    full: {
      total_rooms: {
        desc: 'Total number of conversations',
        helper: 'This corresponds to all the conversations having reached the Chat Console'
      }
    },
    total_closed_rooms: {
      desc: 'Conversations processed',
      helper: 'Corresponds to all the closed conversations'
    },
    total_late_rooms: {
      desc: 'Late Conversation',
      helper: 'Corresponds to all conversations not yet processed'
    },
    total_msg_count: {
      desc: 'Messages exchanges'
    },
    avg_waiting_time: {
      desc: 'Average wail time'
    },
    empty: 'No data available over the period and/or digital channel(s) selected',
    export: 'Export CSV',
    'user-rooms': {
      label: 'Distribution of old and new user threads',
      y: 'Number of conversations'
    },
    new_user: {
      true: 'New user',
      false: 'Old user'
    },
    msg_count: {
      title: 'Tracking your package',
      label: 'i.e. {0}% of {1} messages of monthly package'
    },
    room_count: {
      title: 'Exchanges of the day'
    },
    today_rooms: {
      desc: 'Total conversations today',
      helper: 'This corresponds to all the conversations having reached the Chat Console today'
    },
    running_rooms: {
      desc: 'Ongoing conversation(s)',
      helper: 'This corresponds to conversations currently in progress.'
    },
    late_rooms: {
      desc: 'Conversation(s) currently overdue',
      helper: 'This corresponds to conversations in which the user has not had an answer after 10 min.'
    },
    facebook: 'Messenger',
    facebook_comments: 'Facebook',
    get web () {
      return this.web_chat
    },
    get webchat () { return this.web },
    web_chat: 'Live Chat',
    whatsapp: 'WhatsApp',
    googlebm: 'Google\'s Business Messages',
    apple: 'Apple Messages for Business',
    instagram_comments: 'Instagram',
    instagram_dm: 'Instagram Direct',
    rcs: 'RCS',
    sms: 'SMS',
    telegram: 'Telegram',
    twitter_comments: 'X',
    twitter_dm: 'X',
    total_rooms: {
      label: 'conversation(s)',
      desc: 'Total number of conversations'
    },
    take_rate: {
      label: 'response rate',
      desc: 'Conversations processed / total conversations'
    },
    msg_average: {
      label: 'interaction(s)',
      desc: 'Average number of messages, during a conversation'
    },
    channels: {
      label: 'Conversations by channel'
    },
    'channel-rooms': {
      label: 'Distribution of conversations',
      x: 'Interval selected',
      y: 'Number of conversations'
    },
    'waiting-rooms': {
      label: 'Average wait time',
      x: 'Interval selected',
      y: 'Duration'
    },
    key_account: {
      channels: {
        title: 'Distribution of digital channels'
      },
      conversations: {
        title: ' Conversations: performance and productivity',
        subtitle: 'Conversations',
        interval: 'Interval',
        sms: 'SMS',
        guests: 'Visitors',
        disc_bot: 'Bot conv',
        disc_bot_details: 'Number of bot supported conversations',
        disc_agent: 'Agent conv',
        disc_agent_details: 'Corresponds to the number of conversations supported by agents',
        first_response_duration: 'DMPR (mm:ss)',
        first_response_duration_details: 'Average time to first response expressed in mm:ss',
        waiting: 'Presented',
        waiting_details: 'Corresponds to the number of conversations presented in the chat console (went through “waiting status”)',
        closed: 'Processed',
        closed_details: 'Correspond to the number of conversations finalized by the agents',
        msg_received: 'Mess. received ',
        msg_received_details: 'Number of messages received from users',
        msg_sent: 'Mess. sent ',
        msg_sent_details: 'Corresponds to the number of messages sent by both agents and chatbot',
        response_rate: 'Response rate',
        response_rate_details: 'Share of presented conversations answered by agent (%)',
        treatment: 'ACT (mm:ss)',
        treatment_details: 'Average conversation duration for current day (mm:ss)',
        initiation: 'Init. conv. % ',
        linking: 'Connection',
        linking_details: 'Share of bot conversations answered by agent (%)',
        quarter_hour: 'Per quarter of an hour',
        half_hour: 'Per half hour',
        hour: 'Per hour',
        day: 'Per day',
        week: 'Per week',
        month: 'Per month',
        unicity: {
          guest: 'Guest unicity',
          off: 'Real time',
          session: 'Session unicity'
        },
        waiting_duration: 'AWT (mm:ss)',
        waiting_duration_details: 'Average conversation waiting time for current day (mm:ss)'
      },
      export: 'export the data',
      messages: {
        title: 'Distribution of messages',
        x: 'Selected interval',
        y: 'Number of messages',
        bot_type: 'Chatbot',
        agent_type: 'Agent',
        received_type: 'Received messages',
        sent_type: 'Sent messages'
      },
      rooms_details: {
        title: 'More conversation datas'
      },
      rooms_per_channel: {
        title: 'Conversations per digital channels',
        x: 'Selected interval',
        y: 'Number of conversations'
      },
      response_time: {
        details: 'Average waiting time before a conversation is assigned to an agent',
        title: 'Response time in minutes',
        x: 'Response time (min)',
        y: 'Number of responses'
      },
      sankey: {
        title: 'Flux'
      },
      subtitle: 'Find the detailed figures of your chat activity, refine your research with the filters.' //  and {0}.'
    }
  },
  date: {
    dateFormat: 'MM/DD/YYYY',
    datetimeFormat: 'MM/DD/YYYY hh:mm A',
    hourFormat: 'hh:00',
    hrdateformat: 'MMM D, YYYY',
    hrdatetimeformat: 'MMM D, YYYY h:mm A',
    longdatetimeFormat: 'MM/DD/YYYY hh:mm:ss A',
    longtimeFormat: 'hh:mm:ss',
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      s_short: '< 1 min',
      m: 'a minute',
      m_short: '1 min',
      mm: '{0} minutes',
      mm_short: '{0} min',
      h: 'an hour',
      h_short: '1 h',
      hh: '{0} hours',
      hh_short: '{0} h',
      d: 'a day',
      d_short: '1 d',
      dd: '{0} days',
      dd_short: '{0} d',
      M: 'a month',
      M_short: '1 m',
      MM: '{0} months',
      MM_short: '{0} m',
      y: 'a year',
      y_short: '1 y',
      yy: '{0} years',
      yy_short: '{0} y'
    },
    shorttimeFormat: 'hh:mm A'
  },
  datepicker: {
    cancel: 'Cancel',
    last_month: 'Last month',
    last_week: 'Last week',
    last_year: 'Last year',
    reset: 'Reset',
    save: 'Confirm',
    today: 'Today',
    yesterday: 'Yesterday'
  },
  timepicker: {
    hour: 'Hour',
    minute: 'Minute',
    placeholder: 'hh:mm'
  },
  transferred_agent_modal: {
    now_in_waiting_section: 'The room has been successfully transferred to the selected user.',
    title: 'User transfer confirmation'
  },
  delete: 'Delete',
  details: {
    account: 'Account',
    botgroup: 'Entry point',
    botgroup_page: 'Entry point page',
    bottree: 'Bot tree',
    browser: 'Browser',
    company: 'Entity',
    contact_comment: 'Comment',
    current_session: '* Current session',
    device: 'Device',
    file: 'Client details',
    general: 'Information',
    guest_id: 'Guest ID',
    history: 'History',
    language: 'Language',
    location: 'Location',
    note: 'Notes',
    nps: {
      detractor: 'Detractor',
      developer: 'Developer',
      passive: 'Passive',
      title: 'NPS'
    },
    phone: 'Phone n°',
    satisfaction: 'Customer satisfaction',
    since: 'Active since',
    username: 'Full name',
    validate: 'Confirm'
  },
  edit: 'Edit',
  email_resent: 'Email sent!',
  email_validation: {
    email_already_validated: 'Your email has already been confirmed',
    bad_token: 'The link to activate your account has expired'
  },
  error: {
    maximum_file_size_excedeed: 'Sorry, we were unable to send your file because it is too large. The maximum size allowed is 5MB.',
    Oups: 'Oops, an error has occurred!',
    permission_denied: {
      details: 'You do not have the required rights to access this page.',
      title: 'Unauthorized access'
    },
    too_big: 'Sorry, we were unable to send your file because it is too large. The maximum size allowed is 5MB.',
    unauthorized: 'Unauthorized action'
  },
  'third-parties': {
    azure_open_ai: {
      api_version: 'API version',
      deployment_name: 'Name of your model deployment.',
      endpoint: 'Endpoint',
      delete_ask: 'Do you really want to delete this Microsoft Azure Open AI account?',
      deleted: 'Microsoft Azure Open AI account deleted',
      description: 'Connect to your Microsoft Azure Open AI environment',
      label: 'Label',
      saved: 'Microsoft Azure Open AI account saved',
      secret_key: 'Secret key',
      skills_placeholder: 'Skills',
      skills: 'Skills'
    },
    callback: {
      description: 'Configure a Greenbureau callback',
      error: {
        invalid_callback_identifier: 'Invalid callback identifier '
      },
      identifier: 'Identifier',
      label: 'Label',
      skills: 'Skills',
      skills_placeholder: 'Skills',
      timeslot_required: 'Selecting a timeslot is mandatory?'
    },
    genesys: {
      client_id: 'Client ID',
      client_secret: 'Client Secret',
      delete_ask: 'Do you really want to delete this external console?',
      deleted: 'External console deleted',
      description: 'Redirect exchanges from your different messaging channels to your Genesys console.',
      error: {
        login_failed: 'Authentication failed'
      },
      kind: 'Console type',
      label: 'Name',
      location_placeholder: 'Choose a location',
      location: 'Location',
      locations: {
        canada: 'Americas (Canada)',
        dublin: 'EMEA (Dublin)',
        frankfurt: 'EMEA (Frankfurt)',
        london: 'EMEA (London)',
        mumbai: 'Asia Pacific (Mumbai)',
        seoul: 'Asia Pacific (Seoul)',
        sydney: 'Asia Pacific (Sydney)',
        tokyo: 'Asia Pacific (Tokyo)',
        useast: 'Americas (US East)',
        useast2: 'Americas (US East 2)',
        uswest: 'Americas (US West)'
      },
      name: 'Console name',
      saved: 'External console saved',
      skills_placeholder: 'Choose a skill',
      skills: 'Skill'
    },
    goodays: {
      delete_ask: 'Do you really want to delete this Goodays account?',
      deleted: 'Goodays account deleted',
      description: 'Connect to your Goodays environment',
      error: {
        fail_to_fetch: 'Failed to retrieve'
      },
      label: 'Label',
      nps: 'Net Promoter Score (NPS)',
      sat: 'Satisfaction',
      saved: 'Goodays account saved',
      skills_placeholder: 'Skills',
      skills: 'Skills',
      survey: 'Satisfaction survey',
      token: 'Token'
    },
    kiamo: {
      delete_ask: 'Do you really want to delete this Kiamo account?',
      deleted: 'Kiamo account deleted',
      description: 'Configure an Kiamo callback',
      endpoint: 'Endpoint',
      error: {
        invalid_credentials: 'Authentication failed'
      },
      label: 'Label',
      saved: 'Kiamo account saved',
      service_id: 'Service ID',
      skills_placeholder: 'Skills',
      skills: 'Skills',
      token: 'Token'
    },
    list: {
      kind: 'Type',
      name: 'Name',
      skills: 'Skills'
    },
    odigo: {
      api_subdomain: 'API subdomain',
      app_uid: 'Application ID',
      delete_ask: 'Do you really want to delete this Odigo account?',
      deleted: 'Odigo account deleted',
      description: 'Configure an Odigo callback',
      error: {
        invalid_credentials: 'Authentication failed',
        invalid_skill_keyword: 'Invalid Odigo skill'
      },
      label: 'Label',
      saved: 'Odigo account saved',
      skill_keyword: 'Odigo skill',
      skills_placeholder: 'Skills',
      skills: 'Skills',
      user_uid: 'User ID'
    },
    open_ai: {
      delete_ask: 'Do you really want to delete this Open AI account?',
      deleted: 'Open AI account deleted',
      description: 'Connect to your Open AI environment',
      label: 'Label',
      saved: 'Open AI account saved',
      secret_key: 'Secret key',
      skills_placeholder: 'Skills',
      skills: 'Skills'
    },
    pipedrive: {
      api_token: 'Token d\'API',
      description: 'Configure a Pipedrive connection',
      label: 'Label',
      skills: 'Skills',
      skills_placeholder: 'Skills'
    },
    qemotion: {
      config_id: 'Configuration',
      description: 'Configure a Q Emotion connection',
      label: 'Label',
      project_id: 'Projet',
      skills: 'Skills',
      skills_placeholder: 'Skills'
    },
    salesforce: {
      consumer_key: 'Consumer key',
      consumer_secret: 'Consumer secret',
      delete_ask: 'Do you really want to delete this Salesforce connection?',
      deleted: 'Salesforce account deleted',
      description: 'Configure a Salesforce connection',
      domain: 'Domain',
      error: {
        invalid_credentials: 'Authentication failed'
      },
      label: 'Label',
      saved: 'Salesforce connection saved',
      skills_placeholder: 'Skills',
      skills: 'Skills'
    },
    sftp: {
      ask_confirm: 'Confirm public key "{0}" and add SFTP connection?',
      ask_delete: 'Do you really want to delete this SFTP connection?',
      authentication: {
        copied_to_clipboard: 'Copied to clipboard!',
        gb_public_key: 'Greenbureau public key:',
        password: 'Password',
        public_key: 'Public key (SSH)',
        title: 'Authentication method'
      },
      confirm: 'Public key verification',
      deleted: 'SFTP connection deleted',
      description: 'Configure a SFTP connection',
      host: 'Hôte',
      label: 'Label',
      port: 'Port',
      saved: 'Your changes have been saved',
      skills: 'Skills',
      skills_placeholder: 'Skills',
      username: 'Username'
    },
    worldline: {
      delete_ask: 'Do you really want to delete this Worldline account?',
      deleted: 'Worldline account deleted',
      description: 'Configure an Worldline callback',
      identifier: 'Campaign identifier',
      label: 'Label',
      saved: 'Worldline account saved',
      skills_placeholder: 'Skills',
      skills: 'Skills'
    },
    zencall: {
      description: 'Configure a Greenbureau callback',
      error: {
        invalid_callback_identifier: 'Invalid callback identifier '
      },
      identifier: 'Identifier',
      label: 'Label',
      skills: 'Skills',
      skills_placeholder: 'Skills',
      timeslot_required: 'Selecting a timeslot is mandatory?'
    }
  },
  faq: {
    link: 'https://en.support.chayall.com/',
    file: {
      ai: 'key-account/Book de formation ChayAll - ADMIN - Intelligence Artificielle.pdf',
      auto_allocation: 'key-account/Book de formation ChayAll - ADMIN - Affectation automatique.pdf',
      'bot-builder': 'key-account/Book de formation ChayAll - ADMIN - Bot builder.pdf',
      botgroups: 'key-account/Book de formation ChayAll - ADMIN - Points d entree.pdf',
      campaigns: 'key-account/Book de formation ChayAll - OWNER - Campagnes.pdf',
      'third-parties': 'key-account/Book de formation ChayAll - ADMIN - Consoles externes.pdf',
      dashboard: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Dashboard.pdf',
      'gb-agent_agent': 'key-account/Book de formation ChayAll - AGENTS Console agent.pdf',
      'gb-agent': 'key-account/Book de formation ChayAll - ADMIN_MANAGER Console agent.pdf',
      messaging: 'key-account/Book de formation ChayAll - ADMIN - Messagerie.pdf',
      settings_agent: 'key-account/Book de formation ChayAll - AGENTS Paramètres.pdf',
      settings: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Paramètres.pdf',
      stats: 'key-account/Book de formation ChayAll - ADMIN_MANAGER - Statistiques.pdf',
      third_parties: 'key-account/Book de formation ChayAll - ADMIN - Services externes.pdf',
      webivr: 'key-account/Book de formation ChayAll - ADMIN MANAGERS - SVI Visuel.pdf',
      webivr_builder: 'key-account/Book de formation ChayAll - ADMIN MANAGERS - SVI builder.pdf'
    },
    title: $settings.saas ? 'Access the FAQ' : 'Help'
  },
  field: {
    place_id: {
      picked: 'Business saved',
      to_pick: 'Add a business'
    }
  },
  file_format_not_allowed: 'Invalid file format',
  filter: 'Filter',
  finished: 'Finished',
  form: {
    empty_upload: 'Delete file',
    upload_preview: 'View download'
  },
  gba: {
    agent_transferred: 'Transferred to {0}',
    agent_transferred_long: 'The conversation has been transferred to {0}',
    header: {
      autofill: 'Activate auto assignment',
      break: 'Take a break',
      unbreak: 'Quit break'
    },
    infos: 'Information',
    message: {
      error: {
        quota: 'Message quota exceeded'
      },
      not_delivered: 'Not delivered'
    },
    origin: {
      instagram_dm: 'Instagram Direct'
    },
    search: {
      agent_placeholder: 'Supported by (Agent)',
      channels_placeholder: 'Messaging',
      clear: 'Reset filters',
      contact_reasons_placeholder: 'Contact reasons',
      content_placeholder: 'Contains (Text, link, file, address)',
      dates_placeholder: 'Date (MM / DD / YYYY)',
      email_placeholder: 'Email',
      extra_placeholder: 'Additional info.',
      filter_by: 'Filter by',
      filters: '1 Active filter | {count} Active filters',
      groups_placeholder: 'Skills groups',
      guest_int_id_placeholder: 'Guest ID',
      guest_info_placeholder: 'Client details (Name, phone number, email, etc.)',
      media_id_placeholder: 'Post ID',
      name_placeholder: 'Name',
      phone_number_placeholder: 'Phone number',
      placeholder: 'Search...',
      rating: {
        1: '1 star',
        2: '2 stars',
        3: '3 stars',
        4: '4 stars',
        5: '5 stars'
      },
      rating_placeholder: 'Rating',
      result: 'No result | 1 result | {count} results',
      session_group_int_id_placeholder: 'Conversation ID',
      skills_placeholder: 'Skills',
      status_placeholder: 'Status',
      submit: 'Search'
    },
    skill_transferred: 'Transferred to the skill "{0}"',
    skill_transferred_long: 'The conversation has been transferred to the skill "{0}"',
    sort: 'Sort',
    smartcoaching: {
      suggestions: 'Answer suggestions'
    },
    translate: {
      in_progress: 'Translation in progress...',
      show_original: 'Show original message',
      show_translated: 'Show translation'
    }
  },
  google_place: {
    search: 'Search',
    subtitle: 'Type your company name in the field, then select your address from the drop-down list',
    title: 'Your business on Google'
  },
  invalid: 'Invalid field',
  langs: {
    en: 'English',
    fr: 'French'
  },
  login: {
    button: 'Login',
    connect: 'Login',
    create_account: 'Create an account',
    error: {
      banned: 'Account suspended until {0}.',
      your_email: 'Incorrect email address'
    },
    ka_link: 'Are you a Large Company?',
    no_account: "You don't have an account?",
    title: 'Log in to {APP_NAME}',
    your_email: 'Company@email.com',
    your_password: 'Password',
    your_phone_number: 'Phone number'
  },
  manage: 'Manage',
  max_agents_reached: 'Maximum number of agents reached',
  max_length: 'Maximum number of characters exceeded',
  'max_length:128': 'Maximum number of characters exceeded',
  menu: {
    abc: 'Apple Messages for Business',
    ai: 'Artificial intelligence',
    'ai-agents': 'AI agents',
    'ai-dialogflow-add': 'Add an AI agent',
    'ai-dialogflow-edit': 'Configure the AI agent',
    'ai-dialogflow-import': 'AI agent feeding',
    'ai-knowledge-bases': 'Knowledge bases',
    'ai-knowledge-bases-add': 'Add a knowledge base',
    'ai-knowledge-bases-edit': 'Configure the knowledge base',
    'ai-knowledge-bases-import': 'Knowledge base feeding',
    'ai-ravana-add': 'Add a conversational agent',
    'ai-ravana-edit': 'Configure a conversational agent',
    'ai-ravana-tasks': 'Configure the conversational agent tasks',
    'ai-smartcoaching-add': 'Add a smartcoaching agent',
    'ai-smartcoaching-edit': 'Configure a smartcoaching agent',
    get apple () {
      return this.abc
    },
    get 'apple-business-chat' () {
      return this.abc
    },
    api: 'API',
    azure_open_ai: 'Microsoft Azure Open AI',
    'bot-builder': 'Bot builder',
    get 'bot-builder-beta' () {
      return this['bot-builder']
    },
    botgroups: 'Entry points',
    'botgroup-add': 'Add entry point',
    get 'botgroup-add-messenger-menu' () { return this['botgroup-add'] },
    'botgroup-edit': 'Configure the entry point',
    get 'botgroup-edit-messenger-menu' () { return this['botgroup-edit'] },
    get 'botgroup-webivr-add' () { return this['botgroup-add'] },
    get 'botgroup-webivr-edit' () { return this['botgroup-edit'] },
    callback: 'Callback',
    'calendars-add': 'Add a calendar',
    'calendars-edit': 'Manage a calendar',
    'campaigns-add': 'Add a campaign',
    'campaigns-contacts-add': 'Create a new audience',
    'campaigns-contacts-edit': 'Manage an audience',
    'campaigns-contacts': 'Audiences',
    'campaigns-edit': 'Manage a campaign',
    'campaigns-send': 'Manual dispatch',
    'campaigns-list': 'Campaigns',
    'campaigns-templates': 'Templates',
    'campaigns-templates-add': 'Create a new template',
    'campaigns-templates-edit': 'Manage a template',
    'campaigns-wrapper': 'Campaign list',
    campaigns: 'Campaigns',
    change_password_title: 'Change your password',
    'channel-embedding': 'Integration of the digital channel',
    'channel-settings': 'Settings of the digital channel',
    'channel-subscription': 'WhatsApp full version',
    channels: 'Messaging channels',
    'confirm-email': 'Account validation',
    'third-parties': 'Third-parties',
    'third-parties-add': 'Add external service',
    'third-parties-add-title': {
      ai: 'Artificial intelligence',
      callback: 'Callbacks',
      console: 'External consoles',
      crm: 'CRMs',
      satisfaction: 'Satisfaction',
      sftp: 'SFTPs'
    },
    get 'third-parties-add-form' () {
      return this['third-parties-add']
    },
    'third-parties-edit': 'Edit the third party',
    'third-parties-list': 'Third parties list',
    dashboard: 'Dashboard',
    'external-services': 'Additional services',
    get facebook () {
      return this.messenger
    },
    facebook_comments: 'Facebook Comments',
    'gb-agent': 'Chat Console',
    get gbm () {
      return this.googlebm
    },
    genesys: 'Genesys',
    goodays: 'Goodays',
    googlebm: "Google's Business Messages",
    google_reviews: 'Google Reviews',
    instagram_comments: 'Instagram Comments',
    instagram_dm: 'Instagram',
    kiamo: 'Kiamo',
    login: 'Login',
    logout: 'Log out',
    messaging: 'Digital channels',
    'messaging-add': 'Add new digital channel',
    'messaging-add-form': 'Form',
    'messaging-edit': 'Digital channel edit',
    'messaging-logged': 'Messaging',
    messenger: 'Messenger',
    odigo: 'Odigo',
    open_ai: 'Open AI',
    pipedrive: 'Pipedrive',
    prototype: 'Prototype',
    qemotion: 'Q Emotion',
    rcs: 'RCS',
    register: 'Create an account',
    reviews: 'Reviews',
    salesforce: 'Salesforce',
    settings: 'Settings',
    'settings-account': 'Account',
    'settings-account-delete': 'Delete account',
    'settings-agent-add': 'Add user',
    'settings-agent-edit': 'Edit user',
    'settings-agents': 'User management',
    'settings-calendars': 'Calendars management',
    'settings-calendars-add': 'Add a calendar',
    'settings-calendars-edit': 'Edit a calendar',
    'settings-calendars-list': 'Calendars list',
    'settings-external-views': 'External views list',
    'settings-external-views-add': 'Add an external view',
    'settings-external-views-edit': 'Edit an external view',
    'settings-billing': 'Plans & Billing',
    'settings-billing-address': 'Billing address',
    'settings-billing-payments': 'Payment',
    'settings-billing-payment-add': 'Add a payment method',
    'settings-billing-invoices': 'Invoices',
    'settings-billing-summary': 'Order summary',
    'settings-chayall-api': 'ChayAll API integration',
    'settings-contact-reason-add': 'Add contact reason',
    'settings-contact-reason-edit': 'Edit contact reason',
    'settings-contact-reasons': 'Contact reason',
    'settings-group-add': 'Add skills group',
    'settings-group-edit': 'Edit skills group',
    'settings-groups': 'Skills groups',
    'settings-msglib': 'Pre-recorded messages',
    'settings-msglib-add': 'Add an item',
    'settings-msglib-edit': 'Edit an item',
    'settings-rooms': 'Conversation settings',
    'settings-scheduled-exports': 'Scheduled exports',
    'settings-scheduled-exports-add': 'Add a scheduled export',
    'settings-scheduled-exports-edit': 'Edit a scheduled export',
    'settings-scheduled-exports-list': 'Scheduled exports list',
    'settings-skill-add': 'Add skill',
    'settings-skill-edit': 'Edit skill',
    'settings-skills': 'Skills',
    'settings-subaccount-add': 'Add subaccount',
    'settings-subaccount-edit': 'Edit subaccount',
    'settings-subaccounts': 'Subaccounts',
    'settings-subscription': 'Plans',
    'settings-surveys': 'Satisfaction surveys',
    'settings-surveys-add': 'Add a satisfaction survey',
    'settings-surveys-edit': 'Edit a satisfaction survey',
    'settings-surveys-list': 'Satisfaction surveys list',
    'settings-public-api': 'Connections',
    'settings-zapier': 'Zapier integration',
    sftp: 'SFTP',
    sms: 'SMS',
    stats: 'Statistics',
    'stats-bot': 'Statistics',
    'stats-bot-main': 'Statistics',
    'stats-bot-flow': 'Customer analytics',
    'stats-webivr-main': 'Statistics',
    'stats-webivr-flow': 'Customer flow',
    'stats-webivr-flow-tree': 'Tree flow',
    telegram: 'Telegram',
    title: 'Home',
    trustpilot: 'Trustpilot',
    twitter_dm: 'X',
    twitter_comments: 'X Comments',
    get 'tyntec-wa' () {
      return this.whatsapp
    },
    webivr: 'Visual IVR',
    get web () {
      return this.web_chat
    },
    get webchat () {
      return this.web_chat
    },
    web_chat: 'Live Chat',
    'webivr-add': 'Add visual IVR',
    'webivr-edit': 'Edit the visual IVR',
    'webivr-faqs-add': 'Add a FAQ',
    'webivr-faqs-edit': 'Modify a FAQ',
    'webivr-forms-add': 'Add a form',
    'webivr-forms-edit': 'Modify a form',
    'webivr-templates-add': 'Add a template',
    'webivr-templates-edit': 'Modify a template',
    whatsapp: 'WhatsApp',
    worldline: 'Worldline',
    zencall: 'Zencall'
  },
  messaging: {
    add: 'Add new digital channel',
    back: 'Back to digital channels list',
    bot: {
      messaging: 'Instant Messaging',
      builder: 'Bot',
      edit: 'Edit'
    },
    comments: 'Social networks',
    edit: {
      title: 'Set up the digital channels'
    },
    embed: {
      faq: 'Need help?',
      gtm: {
        faq: 'https://en.support.chayall.com/article/125-comment-integrer-grace-a-google-tag-manager'
      },
      prestashop: {
        faq: 'https://en.support.chayall.com/article/93-comment-integrer-le-plugin-prestashop'
      },
      subtitle: 'This step allows you to integrate all the digital channels previously set up on your digital media, according to the integration method of your choice. Last step and you can start interacting with your customers from instant messengers.',
      title: 'Integrating on your digital supports',
      web: {
        faq: 'https://en.support.chayall.com/article/81-integration-dun-code-javascript'
      },
      wordpress: {
        faq: 'https://en.support.chayall.com/article/79-integration-wordpress'
      }
    },
    key_account: {
      'bot-builder': 'Bot',
      name: 'Channel name',
      kind: 'Channel type',
      settings: 'Settings',
      skill: 'Skill',
      state: 'Status',
      title: 'Channels'
    },
    logged: {
      title: 'One more step!',
      subtitle: 'You can close this page and return to the previous ChayAll tab to finalize the settings.'
    },
    no_messaging_enabled: 'To start, please set up the digital channel you would like to integrate into your website.',
    params: {
      customization: {
        agent: {
          name: 'Agent name'
        },
        agents: 'Agents',
        agents_details: 'Please upload an image per agent and fill in their name',
        agents_error: {
          'name:cannot_be_blank': 'Please fill in the name of your agent',
          'url:cannot_be_blank': 'Please upload an image'
        },
        auto_open: 'Automatic opening time (in seconds)',
        auto_open_details: 'A zero value will disable widget automatic opening',
        changes_saved: 'Your changes have been saved',
        cta: 'Call to action',
        cta_default: 'Hello, How can we help you?',
        cta_details: 'Leaving this field blank will disable CTA display',
        faq: 'https://en.support.chayall.com/article/109-comment-personnaliser-mon-bouton-chayall',
        force_cta_display: 'Show call-to-action phrase on every page',
        force_cta_display_details: 'By default call-to-action phrase appears only once per session.',
        preview: 'Preview',
        preview_widget: 'Preview widget',
        title: 'Customize your widget',
        widget_color: 'Button color',
        widget_logo: 'Logo',
        widget_logo_details: 'Square format, JPEG or PNG',
        widget_offset_x: 'Offset X (px)',
        widget_offset_y: 'Offset Y (px)',
        widget_position: {
          left: 'Bottom left',
          right: 'Bottom right',
          title: 'Widget position'
        },
        widget_subtitle: 'Widget subtitle',
        widget_title: 'Widget title',
        widget_title_placeholder: 'Let\'s exchange on the digital channel of your choice 👋🏻',
        widget_version: {
          1: 'Multi-lozenges',
          2: 'Chatbox'
        }
      },
      title: 'Personalizing your customer experience'
    },
    reviews: 'Reviews',
    skills: 'Skill:',
    subaccount: 'Subaccount:'
  },
  modal: {
    confirm_account_delete_button: 'Permanently delete my {APP_NAME} account',
    confirm_account_delete_msg: 'Are you sure you want to permanently delete your {APP_NAME} account?',
    confirm_account_delete_title: 'Permanently deleting {APP_NAME} account',
    confirm_agent_delete_button: 'Delete user',
    confirm_agent_delete_msg: 'Are you sure you want to permanently delete this user?',
    confirm_agent_delete_title: 'Deleting user',
    confirm_dfnode_delete_button: 'Confirm',
    confirm_dfnode_delete_msg: 'Are you sure you want to delete this action? It will delete this action and its children',
    confirm_dfnode_delete_title: 'Delete an action',
    confirm_paymentmethod_button: 'Confirm my payment method',
    confirm_tree_delete_button: 'Delete',
    confirm_tree_delete_msg: 'Are you sure you want to delete this tree',
    confirm_tree_delete_title: 'Delete tree',
    // card is passed as arg to confirm_paymentmethod_msg.
    // you can use `card.alias` or `card.brand` for example
    // `card.alias` may be null
    confirm_paymentmethod_msg: 'Your payment method has been added',
    confirm_paymentmethod_choose_other_msg: 'Choose another payment method',
    confirm_paymentmethod_title: 'Confirm your payment method',
    facebook_login_refresh_msg: 'Please click on OK to refresh your page',
    password_change_title: 'Modifying your password',
    tree_delete_linkedtree_nodes_msg: 'Warning! This tree is linked to some nodes. These nodes will also be deleted.',
    warn_open_room_details: 'Are you sure you want to logout?',
    warn_open_room_title: 'You still have open conversations'
  },
  multiselect: {
    selected_options: '{0} selected items'
  },
  'n/a': 'N/A',
  none: 'None',
  note: {
    at: 'Note dated {date}'
  },
  notes: {
    form_content_label: 'Content',
    form_subject_label: 'Object'
  },
  offer: {
    contact_us: 'Contact us',
    current: 'Ongoing',
    details: {
      default: {
        abc_gbm_registration: 'Apple & Google chat signup registration',
        all_integrations: 'All digital channels apps integration',
        all_integrations_but_wa: 'All digital channels apps integration but WhatsApp',
        api: 'Connection to CRMs and more',
        ass_dedicated: 'Accompaniment by a project manager',
        chat_console: '',
        chatbot_integration: 'Chatbot integration',
        custom_monitoring: 'Premium performance monitoring',
        custom_widget: 'Customizable widget',
        dashboard: 'Chat console & dashboard',
        max_messages: 'Package of {0} messages',
        max_subaccounts: '{0} sub-accounts allowed',
        max_users: '{0} users allowed',
        max_wa_messages: '{0} WhatsApp messages/month',
        monitoring: 'Performance monitoring',
        msg_price: 'Price per extra message: {0}',
        msg_unlimited: 'Unlimited messages',
        noprice: '-',
        standard_support: 'Standard support',
        // eslint-disable-next-line no-template-curly-in-string
        wa_msg_price: 'Price per extra WhatsApp message: ${0}/message',
        welcome_guide: 'Welcome guide'
      },
      get free () {
        const details = Object.assign({}, this.default, {
          noprice: 'Free'
        })

        return {
          month: details,
          year: details
        }
      },
      get essential () {
        const details = Object.assign({}, this.default, {
          ass_custom: 'Activitation support'
        })

        return {
          month: details,
          year: Object.assign({}, details, {
            max_messages: '{0} messages/month'
          })
        }
      },
      get pro () {
        const details = Object.assign({}, this.default, {
          ass_custom: 'Activation and training support'
        })

        return {
          month: details,
          year: Object.assign({}, details, {
            max_messages: '{0} messages/month'
          })
        }
      },
      get custom () {
        const details = Object.assign({}, this.default, {
          noprice: 'Custom'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier1 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Performance tracking & analytics',
          msg_unlimited: 'An unlimited number of messages/month',
          noprice: '$49'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier2 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Performance tracking & analytics',
          msg_unlimited: 'An unlimited number of messages/month',
          noprice: '$99'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier3 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          monitoring: 'Performance tracking & analytics',
          msg_unlimited: 'An unlimited number of messages/month',
          noprice: '$189'
        })

        return {
          month: details,
          year: details
        }
      },
      get appsumo_chayall_tier4 () {
        const details = Object.assign({}, this.default, {
          chatbot: 'Chatbot',
          max_users: 'An unlimited number of users allowed',
          monitoring: 'Performance tracking & analytics',
          msg_unlimited: 'An unlimited number of messages/month',
          noprice: '$299'
        })

        return {
          month: details,
          year: details
        }
      }
    },
    per_month: 'per month',
    per_year: 'per year',
    quotation: 'Quote required',
    real_subscribe: {
      default: 'Subscribe',
      free: 'Start',
      get custom () {
        return 'Contact us'
      },
      get essential () {
        return this.default
      },
      get pro () {
        return this.default
      },
      get appsumo_chayall_tier1 () {
        return this.default
      },
      get appsumo_chayall_tier2 () {
        return this.default
      },
      get appsumo_chayall_tier3 () {
        return this.default
      },
      get appsumo_chayall_tier4 () {
        return this.default
      }
    },
    subscribe: 'Contact us'
  },
  ok: 'Close',
  or_drag_it_here: 'or drag and drop your file here',
  or_drag_newer_here: 'or drag and drop your newer file here',
  paging: 'Page {0} ofca {1}',
  password: {
    ...passwordErrors,
    back_to_login: 'Back to login page',
    change: {
      confirm_new_password: 'Confirm new password',
      new_password: 'New password',
      old_password: 'Old password',
      send_button: 'Confirm'
    },
    change_title: 'Change your password',
    email_has_been_sent: 'Email sent',
    email_has_been_sent_check_mailbox_incl_spam: 'A confirmation email has been sent to you so that you may confirm your email address. Please check your spam box if necessary.',
    reset: {
      error: {
        your_email: 'Incorrect Email address'
      },
      your_email: 'Email address'
    },
    reset_button: 'Reset my password',
    'reset_button ': 'Reset my password',
    reset_title: 'Reset password',
    subtitle: 'You will receive an email to reset your password.',
    your_password_is_changed_you_can_login: 'Your password has been changed. You can now connect using your new password.'
  },
  password_change: {
    ...passwordErrors
  },
  read_more: 'Read more',
  refresh: 'Refresh',
  register: {
    already_registered: 'You already have an account?',
    appsumo: {
      customer: 'Name of your company',
      password: 'Your password must be at least 8 characters long and should not contain only numbers.',
      register_button: 'Get started',
      subtitle: 'Now create your ChayAll account:',
      terms_link: 'I agree to Chayall\'s General Terms and Conditions',
      title: 'Thanks for subscribing to Chayall via AppSumo!'
    },
    baseline: 'No bank card required | Free, no strings attached',
    customer: 'Customer',
    first_name: 'First name',
    last_name: 'Last name',
    login: 'Login',
    phone_number: {
      invalid: 'Invalid phone number'
    },
    register_button: 'Start now',
    register_button_free: 'Start now for free',
    registered: 'You will receive a confirmation email containing a link. Please click on the link to activate your account.',
    shopped_on: 'Shopped on',
    subtitle: 'with the ChayAll omnichannel platform',
    success: 'Congratulations! Your account is now open.',
    terms: 'I accept the General Terms and Conditions of Sale',
    terms_link: 'General Terms and Conditions of Sale',
    terms_link_url: 'https://chayall.com/terms-and-conditions/',
    title: 'Centralize and manage all your digital channels',
    user_email_already_exist: 'An account is already linked to this email address',
    your_company: 'Your business'
  },
  required: 'Missing field',
  resend_email: 'Resend email',
  view_offers: 'View plans',
  reset_password: {
    title: 'Forgot password?'
  },
  review: {
    ask_close: 'Do you really want to close this review?',
    ask_transfer: 'Do you really want to put this review back in the queue?',
    cancel: 'Cancel',
    close: 'Close review',
    close_inactive: 'Mark this review as closed',
    deleted: 'Deletion customer',
    error: {
      reply_already_exists: 'An answer to this review already exists.',
      sync_in_progress: 'synchronization in progress... Please retry later. '
    },
    experienced_at: 'Experienced at:',
    join_btn: 'Click here to join the review',
    last_contact: 'Last contact :',
    location: 'Location:',
    message: {
      ask_delete: 'Do you really want to delete this message?',
      delete: 'Delete',
      edit: 'Edit',
      edit_long: 'Edit selected message',
      edited: 'Edited at {0}'
    },
    source: {
      AFS: 'Automatic Invitation',
      AFSv1: 'Automatic invitation',
      AFSv2: 'Automatic invitation',
      BasicLink: 'Basic invitation',
      BigCommerce: 'Automatic invitation',
      BusinessGeneratedLink: 'Generated link',
      CopyPasteInvitation: 'Manual invitation',
      DomainLink: 'Natural',
      EmbeddedBusinessGeneratedLinkForm: 'Embedded Evaluation Form',
      EmbeddedUniqueLinkForm: 'Embedded Evaluation Form',
      FileUploadInvitation: 'Manual Invitation',
      GTM: 'Automatic Invitation',
      InvitationApi: 'Automatic Invitation',
      InvitationLinkApi: 'Generated link',
      InvitationScript: 'Automatic invitation',
      Kickstart: 'Manual Invitation',
      LegacyUniqueLink: 'Generated link',
      Magento: 'Automatic invitation',
      MagentoV1: 'Automatic invitation',
      MagentoV2: 'Automatic invitation',
      ManualInputInvitation: 'Manual invitation',
      OpenCart: 'Automatic invitation',
      Organic: 'Natural',
      PayPal: 'Automatic invitation',
      PrestaShop: 'Automatic invitation',
      Segment: 'Automatic invitation',
      Shopify: 'Automatic Invitation',
      Shopware: 'Automatic Invitation',
      Square: 'Automatic invitation',
      title: 'Source:',
      Trustpilot: 'Natural',
      UniqueLink: 'Generated link',
      Wix: 'Automatic invitation',
      WooCommerce: 'Automatic Invitation'
    },
    place_id: 'Place ID:',
    to_transfer: 'Requeueing',
    transfer: 'Put back in the queue',
    transferred: 'Requeued',
    type_your_message: 'Type your message…',
    waiting_message: 'Review closed'
  },
  reviews: {
    anonymous: 'Anonymous',
    button: 'Reviews',
    kind: {
      google_reviews: 'Google Reviews',
      trustpilot: 'Trustpilot'
    },
    pick_review: 'Please select a review'
  },
  room: {
    agent_transfer: 'Transfer to a user',
    agent_transferred: 'User transfer',
    auto_closed: 'Auto. closed',
    auto_requeued_title: 'Requeued',
    ask: {
      audiocall: 'audio call',
      screensharing: 'screensharing',
      videocall: 'video call'
    },
    ask_webrtc: 'Do you really want to offer a {currentKind} to your recipient?',
    close_inactive: 'Mark the conversation as resolved',
    closed_details: 'List of all your manually closed conversations. | List of your conversations closed manually and automatically. A conversation is automatically closed after an hour without a response from the agent | List of your conversations closed manually and automatically. A conversation is automatically closed after {count} hours without a response from the agent.',
    comment_bank: 'Messages library',
    confirm: 'Confirm',
    download: 'Download',
    inactive: 'Inactive',
    late_details: 'Disabled status | Late after one minute without a response from the agent. | Late after {count} min without a response from the agent.',
    pick_room: 'No conversation selected',
    quickreply: 'Pre-recorded messages',
    running_details: 'Conversations you have picked up. Only you can see these.',
    skill_transfer: 'Transfer to a skill',
    transfer: $settings.saas ? 'Re-queue' : 'Transfer',
    transferred_agent_title: 'Transferred (Agent)',
    transferred_skill_title: 'Transferred',
    transferred_title: 'Transferred',
    upload: 'Send a document',
    via_transfer: $settings.saas ? 'Requeued' : 'Transferred',
    waiting_details: 'On hold until picked up by a salesperson.',
    yes: 'Click here to take the conversation'
  },
  send: 'Send',
  search: 'Search...',
  settings: {
    account: {
      ask_for_delete: 'Delete my account',
      bad_email: 'The email address entered is not valid',
      bad_phone_number: 'The telephone number entered is not valid',
      bad_timezone: 'The time zone selected is not valid',
      change_password: 'Change password',
      changes_saved: 'Your changes have been saved',
      delete_bad_why: 'Incorrect format',
      delete_button: 'Delete my account',
      delete_details: 'Tell us more',
      delete_no_details: 'Why do you want to delete your account?',
      delete_no_why: 'Please select a reason for deletion',
      delete_title: 'Requesting account deletion',
      delete_why: 'Why do you want to delete your account?',
      delete_why_better_offer_elsewhere: 'I have found a better offer elsewhere',
      delete_why_dont_meet_expectation: 'ChayAll does not meet my expectations',
      delete_why_dont_need_it_anymore: 'I no longer need ChayAll',
      delete_why_other_reason: 'Other reason',
      delete_why_too_expensive: 'Too expensive',
      email: 'Email address',
      email_already_used: 'The email address entered is already in use',
      enterprise: 'Business',
      first_name: 'First name',
      last_name: 'Last name',
      no_email: 'No email address registered',
      no_timezone: 'No time zone selected',
      password_changed: 'Your password has been changed!',
      phone_number: 'Phone number',
      timezone_placeholder: 'Choose a time zone',
      timezone: 'Time zone',
      title: 'Account'
    },
    agent: {
      back: 'Back to users',
      create: 'Send invitation',
      delete: 'Delete',
      deleted: 'User deleted',
      edit: 'Edit user',
      email: 'Email address',
      first_name: 'First name',
      group: 'Skills groups',
      last_name: 'Last name',
      max_reviews: 'Max number of reviews simultaneously',
      max_rooms: 'Max number of conversations simultaneously',
      resend: 'Resend invitation',
      role_placeholder: 'Choose a role',
      role: 'Role',
      roles: {
        admin: 'Admin',
        agent: 'Agent',
        content_manager: 'Content manager',
        flow_manager: 'Flow manager',
        manager: 'Manager',
        stats_manager: 'Statistics',
        supervisor: 'Supervisor'
      },
      saved: 'Your changes have been saved',
      subaccounts: 'Subaccounts'
    },
    agents: {
      add: 'Add user',
      agents_table_created: 'Created',
      agents_table_email: 'Email address',
      agents_table_last_logon: 'Last logon',
      agents_table_name: 'Name',
      agents_table_role: 'Role',
      edit: 'Edit',
      email_sent: 'The email has been sent',
      faq: 'https://en.support.chayall.com/article/67-comment-modifier-supprimer-un-utilisateur',
      messages_section_title: 'Automatic messages',
      saved: 'Your invitation has been sent',
      search: 'Research user',
      subtitle: 'Users list'
    },
    billing: {
      title: 'Plans and payment'
    },
    channel: {
      fb_logged: 'Logged in to Facebook',
      fb_login: 'Log in to Facebook',
      ig_login: 'Log in to Instagram',
      logged: 'You are logged in',
      tw_logged: 'Logged in to X',
      tw_login: 'Log in to X'
    },
    chat: {
      absence_msg: 'Absence message',
      absence_msg_details: 'Inform your customers that you are not available for the moment.',
      auto_msg: 'Automatic messages',
      auto_msg_btn: 'Setting automatic messages',
      auto_msg_warn: 'These automatic messages concern all digital channels except Google\'s Business Messages, go to the Google\'s Business Messages form to set them up.',
      calendar: {
        title: 'Opening hours'
      },
      msglib: 'Messages librairy',
      notif: 'Notifications',
      notif_late_msg: 'Receive an email notification when a discussion goes into "late" status',
      notif_new_msg_sound: 'Receive a sound notification in case of a new customer message',
      notif_push_android: 'Receive push notifications on this Android device',
      notif_push_apple: 'Receive push notifications on this Apple device',
      notif_unread_msg: 'Receive an email notification in case of a new conversation',
      predictive_msglib: 'Enable messages library intuitive entry',
      subtitle: 'Personalize your opening hours and your automatic messages.',
      waiting_msg: 'Wait message',
      waiting_msg_details: 'Bring your customers to wait by informing them that their message has been received and that you will soon reply.',
      welcome_msg: 'Welcome message',
      welcome_msg_details: 'Make your customer feel welcome with this message automatically sent from the start of a conversation.'
    },
    'chayall-api': {
      button: 'Generate the token',
      copied_to_clipboard: 'Copied to clipboard!',
      copy: 'Copy the token',
      subtitle: 'Main API key and sub-keys management',
      token: 'Integration token:'
    },
    'contact-reason': {
      ask_delete: 'Do you really want to delete this contact reason?',
      back: 'Back to contact reason',
      created: 'Contact reason created',
      deleted: 'Contact reason deleted',
      faq: 'https://en.support.chayall.com/article/113-comment-parametrer-des-motifs-de-contact',
      label: 'Name',
      saved: 'Your changes have been saved',
      skills: 'Skills',
      subaccounts: 'Subaccounts',
      subtitle: 'You can add contact reasons, they will then be available directly in your Chat Console. They allow you to tag your chats by contact reason and to know the reasons why your customers contact you.',
      'table-title': 'Contact reason list'
    },
    customer: {
      cant_be_blank: 'This field must be filled'
    },
    external_views: {
      ask_delete: 'Do you really want to delete this external view?',
      back: 'Back to the list of external views',
      deleted: 'External view deleted',
      icon: 'Icon',
      label: 'Name',
      saved: 'Your changes have been saved',
      skill: 'Skill',
      url: 'URL'
    },
    faq: 'Need help?',
    group: {
      ask_delete: 'Do you really want to delete this group?',
      back: 'Back to skills groups',
      created: 'Skills group created',
      deleted: 'Skills group deleted',
      label: 'Name',
      saved: 'Your changes have been saved',
      skills: 'Skills'
    },
    groups: {
      subtitle: 'Skills groups list'
    },
    library: {
      title: 'Message library'
    },
    menu: {
      account_title: 'Account',
      billing_title: 'Plans & Billing',
      chat: {
        agents_title: 'Console settings',
        library_title: 'Message Library'
      },
      chat_title: 'Live Chat',
      subscription_title: 'Subscription'
    },
    msglib: {
      ask_delete: 'Do you really want to delete this item?',
      back: 'Back',
      category: 'Categories',
      content: 'Content',
      deleted: 'Item deleted',
      faq: 'https://en.support.chayall.com/article/92-comment-creer-des-messages-pre-enregistres',
      label: 'Label',
      open: 'Open',
      saved: {
        body: 'Would you like to open this (sub)category?',
        cancel: 'Back to list',
        confirm: 'Open',
        title: 'Your changes have been saved'
      },
      search: 'Search...',
      sentence: 'Messages',
      skills: 'Skills',
      subcategory: 'Subcategories',
      subtitle: 'Using our message library, you can pre-record sentences to save time on the Chat Console. To create a sentence, you must first create a category and a subcategory. To find a phrase, you can use these categories or directly search for a sentence by typing in a keyword.',
      title: 'Pre-recorded messages'
    },
    offers: {
      choose_title: 'Contact form',
      details: 'Message',
      email: 'Email address',
      first_name: 'First Name',
      last_name: 'Last Name',
      name: 'Name',
      phone_number: 'Phone number'
    },
    password: {
      ...passwordErrors,
      bad_old_password: 'Old password incorrect',
      old_password_required: 'Old password required'
    },
    payments: {
      'billing-address': 'Add / Modify billing address',
      'billing-address-title': 'My billing address',
      invoices: {
        'billing-address': 'Billing address',
        search: 'Find an invoice',
        title: 'Invoices'
      },
      paymentmethods: {
        add_card: 'Add a payment method',
        add_title: 'Add a payment method',
        alias: 'Card name',
        bad_card_number: 'The card number is incorrect',
        card: 'Card number',
        card_holder: '*settings.payments.paymentmethods.card_holder*',
        changes_saved: 'Your payment method has been added.',
        city: 'City',
        country: 'Country',
        credit_card: 'Credit card',
        default: 'Define by default',
        default_payment: 'By default',
        errors: {
          incomplete_number: 'The card number is incomplete'
        },
        name: 'Business name',
        network: 'Card type',
        no_cards: 'No payment method registered',
        line1: 'Address ',
        line2: 'Additional address',
        optional: '*settings.payments.paymentmethods.optional*',
        postal_code: 'Postal code',
        remove_payment: 'Delete payment method',
        set_as_default_payment: 'Set as default payment',
        state: 'State (optionnal)',
        title: 'Payment methods',
        terms: 'I accept the {0}',
        type: {
          default: 'Payment card',
          get cartes_bancaires () {
            return this.default
          },
          mastercard: 'Mastercard',
          visa: 'Visa'
        }
      },
      menu: {
        invoices: 'Invoices',
        payment: 'Payment methods'
      },
      table: {
        amount: 'Amount',
        date: 'Date',
        offer: 'Plan',
        reference: 'Reference',
        state: 'State',
        'status-paid': 'Paid',
        'status-unpaid': 'Unpaid',
        'status-draft': 'Waiting',
        'status-open': 'Open',
        'status-uncollectible': 'Pefused payment',
        'status-void': 'Canceled invoice'
      },
      temporarily_disabled: 'Plans and billing features are temporarily disabled, please try again later.',
      title: 'Plans & Billing',
      subscription: {
        changes_saved: 'Your subscription has been taken into account.',
        channels_list: 'Integration of digital channels:',
        dollars: 'Dollars',
        duty_free: 'ex-tax',
        error: {
          insufficient_funds: 'Insufficient funds (Error code: {0})'
        },
        euros: 'Euros',
        if_monthly_billing: '/ month',
        if_yearly_billing: '/ year',
        monthly_billing: 'Monthly',
        show_other: 'Show other offers',
        switch_currency: '*settings.payments.subscription.switch_currency*',
        switch_interval: '*settings.payments.subscription.switch_interval*',
        title: '*settings.payments.subscription.title*',
        yearly_billing: 'Yearly',
        yearly_save: '2 months free'
      },
      subscription_title: '*settings.payments.subscription_title*',
      subtitle: 'Manage your offer, your payment methods and find your invoices.',
      summary: {
        billing_address_missing: 'You have to enter a valid billing address in order to proceed to payment.',
        offer: 'Offer {0}',
        paymentmethod: 'Payment method used',
        period: 'From {0} to {1}',
        subscribe: 'Subscribe',
        total: 'Total'
      }
    },
    rooms: {
      auto_close: 'Delay before closing (HH:mm)',
      auto_close_toggle: 'Automatically close conversations',
      auto_close_toggle_details: 'Auto-close allows you to set up the time before an in progress, late or waiting conversation automatically switches to "Closed" status. A conversation is automatically closed without a response from the agent or visitor for X time.',
      auto_close_msg_toggle: 'Send an automatic message to your client after automatically closing a discussion in the chat console',
      auto_close_msg_placeholder: 'Fill in the automatic message to send to your customer',
      hours: 'HH',
      late: 'Delay before changing to "Late" status (HH:mm)',
      late_toggle: 'Automatically switch to "Late" status',
      late_toggle_details: 'The “Late” status allows you to set up the amount of time before an in progress conversation changes to the “Late” status. A conversation is considered “Late”, when it is a conversation accepted by the agent with no response from the agent since the last visitor message for more than X time.',
      minutes: 'mm',
      re_queue: 'Delay before going to "Pending" status (HH:mm)',
      re_queue_toggle: 'Automatic re-queue',
      re_queue_toggle_details: 'Re-queuing allows you to set up the length of time after which a “Late” conversation will go back to “Waiting”. A conversation is therefore put re-queued after having spent X “Late” time. If the change to “Late” status is not enabled, re-queuing allows you to set up the length of time after which an "In progress" chat without an answer from the agent goes back to "Waiting".',
      saved: 'Your changes have been saved.'
    },
    'scheduled-exports': {
      ask_delete: 'Do you really want to delete this scheduled export?',
      back: 'Back to scheduled export list',
      configuration: 'Configuration',
      deleted: 'Scheduled export deleted',
      enabled: 'Enabled',
      error: {
        'contains:{{datetime}}': 'Name must contains {{datetime}} variable',
        invalid_extension: 'File extension is not valid'
      },
      file: {
        filename: 'File name',
        filename_details: 'Use the {{datetime}} variable in the file name allow to insert in it the export date, for example: "Export-{{datetime}}".',
        kind: 'Export type',
        kind_campaigns: 'Campaigns',
        kind_rooms_per_status: 'Conversations per status',
        kind_rooms_events: 'Events per discussion',
        kind_webivrs: 'Visual IVR',
        kind_webivr_forms: 'Visual IVR form details',
        title: 'File n° {0}'
      },
      is_active: 'Enable scheduled export',
      label: 'Name',
      recurrence_rule: 'Planning',
      report_files: 'Export files',
      saved: 'Scheduled export saved',
      sftp_path: 'SFTP target path',
      state: 'State',
      target: 'Target',
      thirdparties_sftp_id: 'SFTP account',
      user_id: 'User'
    },
    show_more: 'Even more',
    skill: {
      auto_allocation: 'Automatic assignment of discussions',
      auto_allocation_details: 'Automatic discussions assignment allows you to assign each new incoming discussion to your agent',
      auto_msg: 'Automatic messages ',
      back: 'Back to skills',
      botTrees: {
        button: 'Trees',
        channel: 'Channel',
        linked_tree: 'Related tree',
        title: 'Trees',
        type: 'Type'
      },
      'change-services-warn': {
        body: 'Service changes can impact your Chayall interface, are you sure you want to save your changes?',
        msg: 'Warning! Service changes may impact your Chayall interface',
        title: 'Warning'
      },
      full_msg: 'Message waiting for connection - agents busy',
      full_msg_helper: 'Message sent when your agents are available, but they have reached their maximum simultaneous discussions threshold, and which invites the visitor to wait',
      pause_msg: 'Message waiting for connection - agents on break',
      pause_msg_helper: 'Message sent when your agents are on break and which invites the visitor to wait',
      created: 'Skill added',
      label: 'Name',
      notification_msg: 'Notification to agents',
      online_msg: 'Connection message',
      online_msg_helper: 'Message sent when all your agents are available to support a chat',
      offline_msg: 'Connection failure message',
      offline_msg_helper: 'Message sent when all your agents are disconnected',
      options: 'Options',
      saved: 'Your changes have been saved',
      screensharing: {
        cobrowsing: 'Co-browsing',
        cobrowsing_details: 'This feature allows you to view the Web page where the LiveChat of your interlocutor is located from the agent console as well as a reconstruction of his actions in real time.',
        mirroring: 'Screen sharing',
        mirroring_details: 'This feature allows you to send a sharing request to your contact from the agent console so that he can share his entire screen or the application window of his choice with you.'
      },
      services: 'Services',
      service: {
        bot: 'Messaging',
        callback: 'Callback',
        campaigns: 'Campaigns',
        dialogflow: 'AI - Dialogflow',
        emails: 'Emails',
        iframes: 'Vues externes',
        'knowledge-engine': 'AI - Knowledge bases',
        social_networks: 'Social networks',
        gb_agent: 'Agent Console',
        integration: 'Entry points',
        ravana: 'AI - Conversational agents',
        'reports-dispatcher': 'Scheduled exports',
        reviews: 'Reviews',
        smartcoaching: 'AI - Smartcoaching',
        'third-parties': 'External services',
        webivr: 'Visual IVR'
      },
      take_msg: 'Display a message to the agent when a discussion is taken over',
      take_msg_helper: 'When an agent takes over a discussion, an informative message is displayed with the content you entered.',
      take_msg_placeholder: 'Takeover message',
      translate: 'Automatic translation',
      translate_bot: 'Automatic translation of the bot',
      translate_bot_details: 'This feature allows you to automatically translate different actions and messages sent by the bot.',
      translate_gbagent: 'Automatic translation of the console agent',
      translate_gbagent_details: 'This feature automatically translates messages you receive and send to the Agent Console.',
      waiting_msg: 'Waiting message',
      waiting_msg_helper: 'Message sent when the visitor relaunches the discussion before it is taken over by an agent'
    },
    skills: {
      subtitle: 'Skills list'
    },
    get subaccount () {
      return Object.assign({}, this.skill, {
        back: 'Back to subaccounts',
        created: 'Subaccount added'
      })
    },
    subaccounts: {
      subtitle: 'You can create sub-accounts to handle multiple accounts, be they subsidiaries or third parties.\nIt\'s a powerful feature, especially if you need to handle multiple brands, or if as a web agency you need to add your Clients\' websites to your own account.',
      table_title: 'Subaccounts list'
    },
    surveys: {
      alert: 'This feature is only available for the Pro and Enterprise plans.',
      alert_btn: 'Find out more',
      ask_delete: 'Please note, if you delete this satisfaction survey, no investigation will be sent at the discussion\'s closure to your customers on the skills that were associated with this survey.',
      back: 'Back to the satisfaction surveys list',
      deleted: 'Satisfaction survey deleted',
      default_text: 'Thank you for this exchange. Do not hesitate to leave us your opinion',
      error: {
        bad_var: 'Warning, the variable {0} is unknown'
      },
      force: 'Automatically send the satisfaction survey when the discussion is closed',
      label: 'Name',
      saved: 'Your changes have been saved',
      skills: 'Skills',
      skills_details: 'Select the skill(s) on which activate your satisfaction survey.',
      subaccounts: 'Subaccounts',
      subaccounts_details: 'Select the subaccount(s) on which activate your satisfaction survey.',
      subtitle: 'Suggest to your customers to rate your exchange and put a review on your business. You will be able to send this personalized message when closing a conversation.',
      text: 'Personalized message',
      url: 'URL',
      url_long: 'Give a customer review platform URL?'
    },
    zapier: {
      app_link: 'https://zapier.com/apps',
      app_link_label: 'many other applications',
      button: 'Generate the token',
      copied_to_clipboard: 'Copied to clipboard!',
      copy: 'Copy the token',
      faq: 'https://en.support.chayall.com/article/127-comment-connecter-chayall-a-dautres-apps-grace-a-zapier',
      faq_label: 'our step-by-step guide',
      link: 'https://zapier.com/',
      link_label: 'Zapier',
      subtitle: `Zapier is a tool for connecting ChayAll to {0}. You can create automated connections, Zaps, in a few minutes to automate tasks and create workflows.
To get started, you need a {1} account.
Then follow {2} to creating your first Zap!`,
      token: 'Integration token:'
    }
  },
  skip: 'Skip',
  sort: 'Sort',
  sso: {
    button: 'Single Sign-On (SSO)',
    customer: 'Company name',
    email: 'Email',
    error: {
      login_error: 'An error has occured',
      login_timeout: 'Connection time out',
      unauthorized: 'Unauthorized access'
    },
    or: 'Or sign in with'
  },
  start: 'I get it',
  totp_error: 'Two-Factor Authentication failed',
  transfer_agent_modal: {
    select_agent: 'Please confirm the user to which the conversation will be transferred:',
    title: 'Transfer the conversation to a user'
  },
  transfer_modal: {
    select_skill: 'Please confirm the section to which the conversation will be transferred:',
    skill_availability: {
      ok: 'Agents available on this skill',
      nok: 'Agents not available on this skill'
    },
    skill_estimated_waiting_time: '{0} wait',
    title: $settings.saas ? 'Back in queue' : 'Transfer to a skill'
  },
  transferred_modal: {
    now_in_waiting_section: $settings.saas ? 'The conversation has been re-queued.' : 'The conversation has been transferred' + '\n\n It is now in the "Pending" section, visible to other salespersons.',
    title: $settings.saas ? 'Confirmation of re-queuing' : 'Confirmation of transfer',
    transfer_successfull: ''
  },
  tree: {
    copy_tree_on_channel: 'Copy tree on other channel',
    copy_tree_same_channel: 'Duplicate tree',
    delete_tree: 'Delete',
    rename: {
      placeholder: 'Tree name'
    },
    rename_tree: 'Rename',
    set_as_init: 'Set as init',
    set_as_fallback: 'Set as fallback'
  },
  unauthorized: 'Invalid username or password',
  user_stats: {
    conversation_time: 'Average conversation time',
    nps: 'NPS',
    waiting_time: 'Average wait time'
  },
  validate: 'Confirm',
  warning: 'Warning',
  webivr: {
    ask_delete: 'Do you really want to delete this visual IVR?',
    back: 'Back to visual IVR list',
    builder: {
      add_label: 'Tree name:',
      action: {
        api: {
          subtitle: 'Coming soon',
          title: 'API'
        },
        callback: {
          access_denied: 'Access denied',
          subtitle: 'Offer your customer to be called back in an available time slot',
          title: 'Callback'
        },
        choice: {
          subtitle: 'Offer a list of choices to your customer',
          title: 'Choice'
        },
        default_label: 'Default label:',
        faq: {
          access_denied: 'FAQ not accessible (Skill group)',
          subtitle: 'Present a FAQ to your customer',
          title: 'FAQ'
        },
        form: {
          access_denied: 'Form not accessible (Skill group)',
          subtitle: 'Propose a contact form or satisfaction survey to your customers',
          title: 'Form'
        },
        form_output: {
          no_score_range: 'On form submit',
          title: 'Output',
          score_range: 'From {0}% to {1}%'
        },
        messaging: {
          access_denied: 'Entry point not accessible (Skill group)',
          subtitle: 'Display digital channels pads on your Visual IVR',
          title: 'Messaging'
        },
        page: {
          subtitle: 'Present a new page to your client',
          title: 'Add a page'
        },
        routing_output: {
          title: 'Output',
          value: '{0}%'
        },
        routing: {
          subtitle: 'Define the percentages on which to make load balancing',
          title: 'Load balancing'
        },
        text: {
          subtitle: 'Insert a short text block',
          title: 'Text'
        },
        to_tree: {
          subtitle: 'Redirect your client to the beginning of a tree',
          title: 'Tree'
        },
        uri: {
          subtitle: 'Present an URL link to your client',
          title: 'URL'
        },
        uri__tel: {
          subtitle: 'Suggest a phone contact to your customer',
          title: 'Direct call'
        },
        uri__mail: {
          subtitle: 'Propose a contact by email to your customer',
          title: 'Email'
        }
      },
      confirm_publish: 'Publish the visual IVR',
      delete_label: 'Do you really want to delete this tree?',
      delete_error_label: 'Unable to delete the tree because it is linked with the following entry point(s):',
      error: {
        cannot_move_parent_under_child: 'A "parent" node cannot be moved to one of its "child" nodes',
        first_node_must_be_page: 'The first node of a tree must be a node of type "Page"',
        invalid_parent_child_relationship: 'The parent-child relationship of these nodes is invalid',
        'kind:oneof:': 'The node dropped must be one of the following types to perform this action: {0}',
        'label:unique': 'The version\'s name should be unique',
        parent_node_needed: 'This node type requires a parent node',
        source_and_target_nodes_are_the_same: 'Target node must be different from copied node',
        unvalidated_nodes_remaining: 'Impossible to publish the current version: There is at least one invalid node left to process before we can publish this version.'
      },
      not_working_version: {
        title: 'Read-only preview',
        body: 'You cannot modify this version because it is different from the working version.',
        link: 'Show working version'
      },
      publish: 'Publish',
      publish_form_label: 'Version name',
      publish_helptext: 'Personnalize visual IVR new version\'s name',
      publish_label_template: '{date} at {time}',
      published: 'Visual IVR published',
      saved: 'Visual IVR saved'
    },
    created: 'Visual IVR created',
    deleted: 'Visual IVR deleted',
    duplicate: 'Duplicate the visual IVR',
    duplicated: 'Visual IVR duplicated',
    edit: 'Edit',
    faqs: {
      ask_cancel: 'You will lose your changes, are you sure you want to undo your changes?',
      ask_delete: 'If you delete this FAQ, the deletion will also apply to actions that use it. Do you really want to delete this FAQ?',
      ask_submit: 'Your changes will apply to all actions using this FAQ, are you sure you want to save your changes?',
      back: 'Return to the FAQ list',
      button: 'Manage FAQs',
      content: 'FAQ content',
      created: 'FAQ created',
      deleted: 'The FAQ has been deleted',
      icon: 'Icon',
      label: 'Name',
      saved: 'FAQ updated',
      search: 'Search for an FAQ...',
      skill: 'Skill',
      updated_at: 'Last modified',
      title: 'FAQ',
      trees: 'IVR Builder and tree structures'
    },
    forms: {
      'add-choice': 'Add a choice',
      'add-row': 'Add a row',
      api_body: 'Payload body',
      api_headers: 'Payload headers',
      api_method: 'API method',
      api_url: 'API URL',
      ask_cancel: 'You will lose your changes, are you sure you want to undo your changes?',
      ask_delete: 'Do you really want to delete this form?',
      ask_submit: 'Your changes will apply to all actions using this form, are you sure you want to save your changes?',
      back: 'Back',
      button: 'Manage forms ',
      button_label: 'Button label',
      choices: {
        title: 'Configuration of choices list'
      },
      created: 'Form created',
      delete: 'Delete',
      errors: {
        max_too_long: 'Value is too long. Max length is {0}.',
        min_too_long: 'Value is too short. Min length is {0}.',
        bad_notification_emails: 'At least one of the notification email is badly formatted'
      },
      fields: {
        checkbox: 'Multiple choice (checkboxes)',
        customize_name: 'Customize name',
        customize_placeholder: 'Customize placeholder',
        date: 'Date',
        datetime: 'Date and time',
        email: 'Email',
        file: 'File',
        location: 'Location',
        optgroups: {
          choices: 'Fields with choices',
          dates: 'Fields with dates',
          texts: 'Fields with texts',
          locations: 'Field with location'
        },
        radio: 'Multiple choice (radio buttons)',
        range: 'Range',
        select: 'Choices',
        select_multiple: 'Multiple choices',
        text: 'Text',
        textarea: 'Long text',
        time: 'Time',
        title: 'Title'
      },
      identifier: 'Identifier',
      label: 'Label',
      kind: 'Type',
      notification_emails: 'Notification mails (split by commas)',
      notification_options: 'Notification options',
      order: 'Order',
      required: 'Required',
      saved: 'Form saved',
      search: 'Search',
      skill: 'Skill',
      title: 'Forms',
      trees: 'Trees',
      use_weight: 'Include in ponderation',
      updated_at: 'Last update',
      validation: 'Validation',
      weight: 'Ponderation'
    },
    label: 'Name',
    language: 'Language',
    language_placeholder: 'Choose a language',
    saved: 'Visual IVR updated',
    settings: 'Settings',
    skill_placeholder: 'Choose a skill',
    skill: 'Skill',
    subtitle: 'List of visual IVR',
    templates: {
      ask_cancel: 'You will lose your changes, are you sure you want to undo your changes?',
      ask_delete: 'If you delete this template, the deletion will also apply to the actions that use it. Do you really want to delete this template?',
      ask_submit: 'Your changes will apply to all actions using this template, are you sure you want to save your changes?',
      back: 'Back to the list of templates',
      button: 'Manage templates',
      created: 'Template created',
      deleted: 'Template has been deleted',
      label: 'Name',
      saved: 'Updated template',
      search: 'Search for a template...',
      skill: 'Skill',
      updated_at: 'Last modified',
      title: 'Template',
      trees: 'IVR Builder and tree structures'
    },
    updated_at: 'Updated at',
    webivr: 'IVR'
  },
  webrtc: {
    invit_call: 'Send an audio call request',
    invit_videocall: 'Send a videocall request',
    invit: 'Send a screen sharing request',
    join: 'Join session',
    quit_confirm: 'You are about to leave the sharing session, do you want to continue?',
    rich_content_call: 'Audio call request',
    rich_content_videocall: 'Videocall request',
    rich_content: 'Screen sharing request',
    waiting: 'Please wait, your interlocutor must approve your participation'
  },
  worker_reload_required: 'A new version is available, please click here to update.',
  wysiwyg: {
    box: 'Frame',
    html: 'Insert code',
    htmlHeader: 'HTML code to insert at the selected location:'
  },
  country: {
    af: 'Afghanistan',
    al: 'Albania',
    dz: 'Algeria',
    ad: 'Andorra',
    ao: 'Angola',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bt: 'Bhutan',
    bo: 'Bolivia (Plurinational State of)',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    br: 'Brazil',
    bn: 'Brunei Darussalam',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    cv: 'Cabo Verde',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    co: 'Colombia',
    km: 'Comoros',
    cg: 'Congo',
    cd: 'Congo, Democratic Republic of the',
    cr: 'Costa Rica',
    ci: 'Côte d\'Ivoire',
    hr: 'Croatia',
    cu: 'Cuba',
    cy: 'Cyprus',
    cz: 'Czechia',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominican Republic',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    sz: 'Eswatini',
    et: 'Ethiopia',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    ga: 'Gabon',
    gm: 'Gambia',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gr: 'Greece',
    gd: 'Grenada',
    gt: 'Guatemala',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hn: 'Honduras',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Iran (Islamic Republic of)',
    iq: 'Iraq',
    ie: 'Ireland',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kp: 'Korea (Democratic People\'s Republic of)',
    kr: 'Korea, Republic of',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: 'Lao People\'s Democratic Republic',
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mr: 'Mauritania',
    mu: 'Mauritius',
    mx: 'Mexico',
    fm: 'Micronesia (Federated States of)',
    md: 'Moldova, Republic of',
    mc: 'Monaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    mk: 'North Macedonia',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pl: 'Poland',
    pt: 'Portugal',
    qa: 'Qatar',
    ro: 'Romania',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome and Principe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    rs: 'Serbia',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    ss: 'South Sudan',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syrian Arab Republic',
    tj: 'Tajikistan',
    tz: 'Tanzania, United Republic of',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom of Great Britain and Northern Ireland',
    us: 'United States of America',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela (Bolivarian Republic of)',
    vn: 'Viet Nam',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe'
  }
}
