<template>
  <div v-if="updateExists">
    <button @click="refreshApp">
      {{ $t('worker_reload_required') }}
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  created () {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  },
  methods: {
    updateAvailable (event) {
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp () {
      this.updateExists = false

      if (!this.registration || !this.registration.waiting) {
        return
      }

      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

div {
  display: flex;

  button {
    flex-grow: 1;
    background-color: $secondary;
    font-weight: 600;
    color: white;
    border: none;
    padding: 14px;
    transition: background-color .15s;

    &:hover {
      background-color: darken($secondary, 10%);
    }
  }
}
</style>
