<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="cancelModal" />
      <h3 class="gb-title">
        <icon name="delete"/>
        {{ $t('modal.confirm_tree_delete_title') }}
      </h3>
    </div>

    <div class="modal-body">
      {{ $t('modal.confirm_tree_delete_msg') }}

      <p v-if="hasLinkedToTreeNodes">
        {{ $t('modal.tree_delete_linkedtree_nodes_msg') }}
      </p>
    </div>

    <div class="modal-footer">
      <button class="gb-btn" type="submit" v-on:click="validate" >
        {{ $t('modal.confirm_tree_delete_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  mixins: [BaseModal],
  name: 'ConfirmTreeDeleteModal',
  components: {
    CloseModalButton,
    Icon
  },
  computed: {
    hasLinkedToTreeNodes () {
      const modalProps = this.$store.getters['global/getModalProps']
      if (modalProps && modalProps.linkedToTreeNodes &&
          modalProps.linkedToTreeNodes.length > 0) {
        return true
      }
      return false
    }
  },
  methods: {
    validate () {
      const currentProps = {
        ...JSON.parse(JSON.stringify(
          this.$store.getters['global/getModalProps'])),
        'confirm-delete': true
      }
      this.$store.commit('global/updateModalProps', currentProps)
      this.closeModal()
    }
  }
}
</script>
