<template>
  <div class="gb-select filter">
    <select ref="select" class="gb-field" name="language"
      :value="$root.$i18n.locale" @input="setLang($event.target.value)">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ $t('langs.' + lang) }}
      </option>
    </select>
    <span class="chevron"><icon name="chevron_right"/></span>
  </div>
</template>

<script>
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  name: 'LanguageSelect',
  components: { Icon },
  props: {
    callback: {
      type: Function,
      required: false,
      default: () => {
        // do nothing
      }
    }
  },
  computed: {
    browserLang () {
      return navigator.language || navigator.userLanguage
    },
    langs () {
      return Object.keys(this.$i18n.messages).filter(
        lang => this.$te(`langs.${lang}`))
    },
    lsLang () {
      return localStorage.getItem('chayall_lang') // Not reactive
    },
    selectedLanguage () {
      return (this.userLang || this.lsLang || this.browserLang).split('-')[0]
    },
    user () {
      return this.$store.getters['user/user']
    },
    userLang () {
      return this.$store.getters['user/lang']
    }
  },
  methods: {
    setLang (lang) {
      this.$refs.select.blur()

      if (this.user) {
        this.$store.dispatch('user/updateLang', lang)
      } else {
        this.$store.commit('user/updateLang', lang)
      }

      this.callback(false)
    }
  },
  watch: {
    selectedLanguage: {
      handler (lang) {
        this.$root.$i18n.locale = lang
        this.$date.locale(lang)
        this.$dayjs.locale(lang)
        if (this.$helphero !== undefined) {
          this.$helphero.update({
            lang: lang
          })
        }
      },
      immediate: true
    },
    user: {
      handler () {
        if (!this.userLang) {
          this.$store.dispatch('user/fetchLang')
        }
      },
      once: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.gb-select {

  .gb-field {
    margin-bottom: 0;
    padding: 5px 30px 5px 10px !important;
    height: auto;
    font-size: 14px;
    box-shadow: none;
    background-color: transparent !important;
    color: $grey-dark;
    border: 2px solid transparent;

    &:focus {
      border-color: $grey-light;
    }

  &:hover {
    cursor: pointer;
  }

    option {
      color: $content;
    }
  }

  .chevron {
    height: auto;
    width: auto;
    right: 5px;
    top: 50%;
    color: $grey-dark;
    background-color: transparent;
  }
}
</style>
