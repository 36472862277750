import { ActionContext, Commit, Dispatch } from 'vuex'
import { RootState } from './types'

import handlers from './handlers/index'

// interfaces
interface wsData {
  kind: string,
  data?: Record<string, unknown>
}

interface wsState {
  isOpen: boolean,
  keepAliveInterval: number | null,
  keepAliveIntervalDuration: number,
  reconnectInterval: number | null,
  reconnectIntervalDuration: number,
  socket: WebSocket | null
}

// initial state
const state: wsState = {
  isOpen: false,
  keepAliveInterval: null,
  keepAliveIntervalDuration: 60000,
  reconnectInterval: null,
  reconnectIntervalDuration: 30000,
  socket: null
}

// getters
const getters = {
  isOpen (state: wsState): boolean {
    return state.isOpen
  },
  keepAliveInterval (state: wsState): number | null {
    return state.keepAliveInterval
  },
  keepAliveIntervalDuration (state: wsState): number {
    return state.keepAliveIntervalDuration
  },
  reconnectInterval (state: wsState): number | null {
    return state.reconnectInterval
  },
  reconnectIntervalDuration (state: wsState): number {
    return state.reconnectIntervalDuration
  },
  socket (state: wsState): WebSocket | null {
    return state.socket
  }
}

// mutations
const mutations = {
  setKeepAliveInterval (state: wsState, keepAliveInterval: number): void {
    state.keepAliveInterval = keepAliveInterval
  },
  setReconnectInterval (state: wsState, reconnectInterval: number): void {
    state.reconnectInterval = reconnectInterval
  },
  setSocket (state: wsState, socket: WebSocket): void {
    state.socket = socket
  },
  updateIsOpen (state: wsState, isOpen: boolean): void {
    state.isOpen = isOpen
  }
}

// actions
const actions = {
  handleMessage ({ dispatch }: { dispatch: Dispatch }, message: string): void {
    const msgObj: wsData = JSON.parse(message)

    if (handlers[msgObj.kind]) {
      handlers[msgObj.kind](msgObj.data)
    } else {
      dispatch('throwMessage', msgObj)
    }
  },
  init ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, token: string): void {
    const socket: WebSocket = new WebSocket(`${process.env.VUE_APP_WEBSOCKET}/ws?${token}`)

    socket.onmessage = (message: MessageEvent) => {
      if (message.data !== 'pong') {
        dispatch('handleMessage', message.data)
      }
    }

    socket.onerror = () => {
      socket.close()
    }

    socket.onopen = () => {
      clearInterval(getters.keepAliveInterval)
      clearInterval(getters.reconnectInterval)

      commit('updateIsOpen', true)
      commit('setKeepAliveInterval', window.setInterval(() => {
        dispatch('ping')
      }, getters.keepAliveIntervalDuration))

      socket.onclose = () => {
        commit('updateIsOpen', false)
        commit('setReconnectInterval', window.setInterval(() => {
          dispatch('init', token)
        }, getters.reconnectIntervalDuration))
      }
    }

    commit('setSocket', socket)
  },
  ping ({ getters }: { getters: any }): void {
    getters.socket.send('ping')
  },
  send ({ getters }: { getters: any }, message: wsData): void {
    getters.socket.send(JSON.stringify(message))
  },
  throwMessage (_: ActionContext<wsState, RootState>, message: string): void {
    console.log('Uncaught message:', message)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
