<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="cancelModal" />
      <h3 class="gb-title">
        <icon name="delete"/>
        {{ $t('modal.confirm_account_delete_title') }}
      </h3>
    </div>

    <div class="modal-body">
      {{ $t('modal.confirm_account_delete_msg') }}
    </div>

    <div class="modal-footer">
      <button class="gb-btn" type="submit" v-on:click="closeModal()" >
        {{ $t('modal.confirm_account_delete_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  mixins: [BaseModal],
  name: 'ConfirmAccountDeleteModal',
  components: {
    CloseModalButton,
    Icon
  },
  data () {
    return {
    }
  }
}
</script>
