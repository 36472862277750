<template>
  <div class="gb-alert" v-if="!hidden">
    <slot/>
    <span v-if="closeBtn !== false" class="close" @click="close()">&times;</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

/**
 * Display an alert that can be closed. The message is defined as an unnamed
 * slot.
 * Close button can be removed by passing :closeBtn="false"
 *
 * By default the alert is bordered with red and text is also red.
 * Use `gb-alert-secondary` to have a blue-grey version.
 *
 * By default the icon is small.
 * Use `gb-alert-icon-big` to have a big icon.
 *
 * <alert :closeBtn="false" class="gb-alert gb-alert-secondary">
 *   <p>
 *     <alert-circle-icon class="gb-alert-icon gb-alert-big"/>
 *     {{ $t('msg') }}
 *   </p>
 * </alert>
 */
@Component({})
export default class Alert extends Vue {
  /**
   * Close button can be removed by passing :closeBtn="false"
   */
  @Prop({ default: true }) closeBtn!: boolean

  protected hidden = false

  close () {
    this.hidden = true
  }

  @Watch('$route', {
    deep: true
  })
  watchRoute () {
    this.hidden = false
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.gb-alert {
  border-radius: 10px;
  border: 1px solid #FF7464;
  color: #FF7464;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 16px 20px;
  position: relative;
  width: 100%;

  .close {
    display: inline-block;
    font-family: sans-serif;
    font-size: 25px;
    font-weight: 100;
    height: 20px;
    line-height: 20px;
    opacity: .5;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 7px;
    vertical-align: center;
    width: 20px;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  p, ul {
    margin: 0;
  }

  p {
    align-items: center;
    display: flex;
  }

  .gb-details {
    font-size: .75rem;
    margin-top: .5rem;
  }

  ::v-deep .gb-btn {
    background-color: #FF7464;
    min-width: auto;
  }
}

.gb-alert.gb-alert-secondary {
  background-color: $primary-light;
  border-color: $grey;
  color: $content-light;
  margin-top: 1.5rem;
}

.gb-alert-icon.gb-alert-big {
  font-size: 3rem;
  margin-right: .75rem;
  opacity: .35;
  top: 0;
  vertical-align: middle;
}
</style>
