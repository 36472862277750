<template>
  <transition name="fade-in">
    <div class="dropdown" v-if="show">
      <div>
        <slot/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HeaderDrowdown',
  props: {
    handler: HTMLElement,
    onClickOutside: Function,
    show: Boolean
  },
  methods: {
    clickOutsideEvent (e) {
      if (!this.$el.contains(e.target) && typeof this.onClickOutside === 'function' &&
        (!this.handler || !this.handler.contains(e.target))) {
        this.onClickOutside()
      }
    }
  },
  watch: {
    show (val) {
      if (val) {
        document.addEventListener('click', this.clickOutsideEvent)
      } else {
        document.removeEventListener('click', this.clickOutsideEvent)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.dropdown {
    position: absolute;
    z-index: 10001;
    top: calc(100% + 20px);
    right: 0;
    background-color: white;
    border: 1px solid $grey-light;
    border-radius: .35rem;
    box-shadow: 0 20px 40px 0 rgba(66,97,158,0.2);

    &.contact {
      right: -10px;

      &:after, &:before {
        right: 10px;
      }
    }

    &:after, &:before {
      bottom: 100%;
      right: 6px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: white;
      border-width: 10px;
      margin-right: 1px;
    }
    &:before {
      border-color: rgba(102, 111, 128, 0);
      border-bottom-color: $grey-light;
      border-width: 11px;
      margin-right: 0px;
    }

    & > div {
      min-width: 200px;
      right: 0;
      top: 0;
    }
  }

.fade-in-enter-active, .fade-in-leave-active {
  transition: transform .15s, opacity .15s;
}
.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

</style>
