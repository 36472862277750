<template>
  <div v-if="isReviewsView"></div>
  <threads-stats v-else-if="isThreadsView"/>
  <div class="user-stats" v-else-if="!isExternalView">
    <div>
      <h3>{{ $t('user_stats.taken_discussions') }}</h3>
      <span>{{ data.running_count }}</span>
    </div>
    <div>
      <h3>{{ $t('user_stats.waiting_time') }}</h3>
      <span>
        {{ seconds2string(data.avg_waiting) }}
      </span>
    </div>
    <div>
      <h3>{{ $t('user_stats.conversation_time') }}</h3>
      <span>
        {{ seconds2string(data.avg_running)  }}
      </span>
    </div>
    <div v-if="data.avg_sat !== null">
      <h3>{{ $t('user_stats.customer_satisfaction') }}</h3>
      <span>{{ data.avg_sat | percentToFixed(5, true) }}</span>
    </div>
    <div v-if="data.avg_nps !== null">
      <h3>{{ $t('user_stats.nps') }}</h3>
      <span>{{ data.avg_nps | percentToFixed(10, true) }}</span>
    </div>
  </div>
</template>

<script>
import ThreadsStats from '@gba-custom/Threads/ThreadsStats.vue'

import seconds2string from '@/utils/seconds2string'

export default {
  components: { ThreadsStats },
  data () {
    return {
      interval: null
    }
  },
  computed: {
    data () {
      return this.$store.getters['stats/GBA']
    },
    isExternalView () {
      return this.$route.name === 'GBA-external-view'
    },
    isReviewsView () {
      return this.$route.matched.some(({ name }) => name === 'GBA-reviews')
    },
    isThreadsView () {
      return this.$route.matched.some(({ name }) => name === 'GBA-threads')
    },
    settings () {
      return this.$settings
    }
  },
  methods: {
    seconds2string
  },
  filters: {
    percentToFixed (percent, max, showMax) {
      return percent === null || isNaN(percent)
        ? '-'
        : `${(percent * max / 100).toFixed(1)}${showMax ? ` / ${max}` : ''}`
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  created () {
    this.$store.dispatch('stats/fetchGBA')
    this.interval = window.setInterval(() => {
      this.$store.dispatch('stats/fetchGBA')
    }, 5 * 60 * 1000)
  }
}
</script>

<style lang="scss" scoped>
  .user-stats {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
</style>
