export default function (s, format) {
  const input = Math.round(s)
  const hours = parseInt(input / 60 / 60).toString().padStart('2', '0')
  const minutes = parseInt(input / 60 % 60).toString().padStart('2', '0')
  const minutesAndHours = parseInt(input / 60 % 60 + hours * 60).toString().padStart('2', '0')
  const seconds = parseInt(input % 60 % 60).toString().padStart('2', '0')

  switch (format) {
    case 'mm:ss':
      return `${minutesAndHours}:${seconds}`
    case 'hh:mm':
      return `${hours}:${minutes}`
    case 'hh:mm:ss':
    default:
      return `${hours}:${minutes}:${seconds}`
  }
}
