import { Commit } from 'vuex'
import { MessengerMenu } from '@/types/messengerMenus'
import Vue from 'vue'

interface State {
  list: Array<MessengerMenu> | null
}

// initial state
const state: State = {
  list: null
}

// getters
const getters = {
  list (): Array<MessengerMenu> | null {
    return state.list
  }
}

// mutations
const mutations = {
  updateInList (state: State, data: MessengerMenu): void {
    if (state.list) {
      (this as any).$set(
        state.list,
        state.list.findIndex(bg => bg.channel === data.channel),
        data
      )
    }
  },
  updateList (state: State, data: Array<MessengerMenu> | null): void {
    state.list = data
  }
}

// actions
const actions = {
  delete: ({ commit }: { commit: Commit }, channelId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_MISC_URL}/messenger/menu/${channelId}`)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetch: ({ commit }: { commit: Commit }, channelId: string): Promise<MessengerMenu> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_MISC_URL}/messenger/menu/${channelId}`)
        .then((response: Response) => response.json())
        .then((data: MessengerMenu) => {
          resolve(data)
        }).catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAll: ({ commit }: { commit: Commit }): Promise<Array<MessengerMenu>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_MISC_URL}/messenger/menu`)
        .then((response: Response) => response.json())
        .then((data: Array<MessengerMenu>) => {
          commit('updateList', data)
          resolve(data)
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save: ({ commit }: { commit: Commit }, data: MessengerMenu): Promise<void> => {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_MISC_URL}/messenger/menu`, data)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
