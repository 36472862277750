import { Commit, Dispatch } from 'vuex'
import { ExternalView, State } from '@/types/externalViews'

const state: State = {
  list: null
}

const getters = {
  list (): Array<ExternalView> | null {
    return state.list
  },
  item (): (id: string) => ExternalView | undefined {
    return (id: string) => {
      return (state.list || [])
        .find((view: ExternalView) => view.id === id)
    }
  }
}

const mutations = {
  list (state: State, data: Array<ExternalView>): void {
    state.list = data
  }
}

const actions = {
  deleteExternalView ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_MISC_URL}/iframes/${id}`)
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<ExternalView>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_MISC_URL}/iframes`)
        .then((response: Response) => response.json())
        .then((data: Array<ExternalView>) => {
          commit('list', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchExternalView ({ dispatch }: { dispatch: Dispatch }, id: string): Promise <ExternalView> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_MISC_URL}/iframes/${id}`)
        .then((response: Response) => response.json())
        .then((data: ExternalView) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveExternalView  ({ dispatch }: { dispatch: Dispatch }, data: ExternalView): Promise<ExternalView> {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_MISC_URL}/iframes`

      if (data.id) {
        url += `/${data.id}`
      }

      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: ExternalView) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state
}
