<template>
  <span :class="clss">{{ name }}</span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MdIcon extends Vue {
  @Prop() name!: string
  @Prop({ default: 'regular', required: false }) kind!: string

  get clss (): string {
    let classes = 'material-icons'
    if (this.kind && this.kind !== 'regular') {
      classes += ' ' + this.kind
    }
    return classes
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons Outline';
  font-style: normal;
  font-weight: 400;
  src: url('~@/assets/fonts/MaterialIcons-Outline.woff2') format('woff');
}

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;  /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  top: 3px;
  white-space: nowrap;
  word-wrap: normal;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.outline {
    font-family: 'Material Icons Outline';
  }
}
</style>
