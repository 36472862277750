<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <h2>{{ $t('google_place.title') }}</h2>
      <span class="gb-subtitle">
        {{ $t('google_place.subtitle') }}
      </span>
    </div>

    <div class="modal-body">

      <div v-if="google">
        <input class="gb-field" ref="input"
          type="text"
          :placeholder="$t('google_place.search')">
        <div class="map" ref="map" v-show="place"></div>
      </div>
      <loader v-else/>

    </div>

    <div class="modal-footer">
      <button v-on:click="closeModal" class="gb-btn gb-btn-secondary">
        {{ $t('Cancel') }}
      </button>
      <button v-on:click="submitPlace" class="gb-btn">
        {{ $t('OK') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import Loader from '@/components/Loader'
import { Loader as GLoader } from '@googlemaps/js-api-loader'

export default {
  name: 'GbmPlaceModal',
  extends: BaseModal,
  components: { Loader },
  props: {
    placeId: String
  },
  data () {
    return {
      autocompleteService: null,
      google: null,
      map: null,
      marker: null,
      place: null
    }
  },
  methods: {
    onPlaceChange () {
      this.place = this.autocompleteService.getPlace()
      this.updateMap()
    },
    onPlaceFound (place, status) {
      this.place = place
      if (this.place && status === this.google.maps.places.PlacesServiceStatus.OK) {
        this.$refs.input.value = `${this.place.name} - ${this.place.formatted_address}`
        this.updateMap()
      }
    },
    submitPlace () {
      this.$nextTick(this.confirmModal(this.place && this.place.place_id))
      this.closeModal()
    },
    updateMap () {
      this.$nextTick(() => {
        if (this.place && this.place.geometry) {
          if (this.place.geometry.location) {
            this.map.setCenter(this.place.geometry.location)
            this.marker.setPosition(this.place.geometry.location)
          }
          if (this.place.geometry.viewport) {
            this.map.fitBounds(this.place.geometry.viewport)
          }
        }
      })
    }
  },
  mounted () {
    const loader = new GLoader({
      apiKey: this.$settings.google_api_key,
      libraries: ['places'],
      version: 'weekly'
    })

    loader.load().then(() => {
      this.google = window.google

      this.$nextTick(() => {
        const fields = ['formatted_address', 'geometry', 'name', 'place_id']
        this.map = new this.google.maps.Map(this.$refs.map)
        this.marker = new this.google.maps.Marker({ map: this.map })

        this.autocompleteService = new this.google.maps.places.Autocomplete(this.$refs.input, {
          fields,
          strictBounds: false,
          types: ['establishment']
        })
        this.autocompleteService.addListener('place_changed', this.onPlaceChange)

        if (this.placeId) {
          const placesService = new this.google.maps.places.PlacesService(this.map)
          placesService.getDetails({
            fields,
            placeId: this.placeId
          }, this.onPlaceFound)
        }
      })
    })
  }
}

</script>

<style lang="scss">
.modal-container {
  width: 600px;

  .map {
    width: 100%;
    height: 300px;
    border-radius: 6px;
    overflow: hidden;
  }
  .modal-footer {
    justify-content: flex-end !important;

    .gb-btn:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
.pac-container {
  z-index: 99999;
}
</style>
