<template>
  <div>
    <div ref="number"></div>
    <div ref="expiry"></div>
    <div ref="cvc"></div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'InputStripePaymentCard',
  props: {
    field: {
      type: Object,
      required: true
    },
    resetFieldErrors: {
      type: Function,
      required: true
    },
    setFieldErrors: {
      type: Function,
      required: true
    },
    setNetworkModel: {
      type: Function,
      required: false
    }
  },
  data () {
    return {
      card: {
        number: null,
        expiry: null,
        cvc: null
      }
    }
  },
  mounted () {
    const elements = this.$stripe.elements({
      fonts: [
        {
          cssSrc: 'https://fonts.googleapis.com/css2?family=Be+Vietnam:wght@500&display=swap'
        }
      ]
    })

    // Due to the way the Stripe Elements API works, we have to put the styling
    // directly in the JS.
    const style = {
      base: {
        color: '#4C71A5',
        fontWeight: 500,
        fontFamily: 'Be Vietnam, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#d2d9e0'
        }
      },
      invalid: {
        color: '#ff0068'
      }
    }
    this.card.number = elements.create('cardNumber', { style: style })
    this.card.expiry = elements.create('cardExpiry', { style: style })
    this.card.cvc = elements.create('cardCvc', { style: style })
    this.card.number.mount(this.$refs.number)
    this.card.expiry.mount(this.$refs.expiry)
    this.card.cvc.mount(this.$refs.cvc)
    this.$emit('input', this.card)
    this.card.number.on('ready', () => {
      // send a signal that the stripe elements are ready to display the form
      // and prevent a FOUC
      this.$store.commit('payment/setStripeIsReady', true)
    })
    this.card.number.on('change', (e) => {
      this.resetFieldErrors(this.field.name)
      this.$emit('input', this.card)
      if (e.complete) {
        this.setNetworkModel(e.brand)
        this.card.expiry.focus()
      } else if (e.error) {
        this.setFieldErrors(
          this.field.name, 'settings.payments.paymentmethods.bad_card_number')
      }
    })
    this.card.expiry.on('change', (e) => {
      this.resetFieldErrors(this.field.name)
      this.$emit('input', this.card)
      if (e.complete) {
        this.card.cvc.focus()
      } else if (e.error) {
        this.setFieldErrors(
          this.field.name, 'settings.payments.paymentmethods.bad_card_number')
      }
    })
    this.card.cvc.on('change', (e) => {
      this.resetFieldErrors(this.field.name)
      this.$emit('input', this.card)
      if (e.error) {
        this.setFieldErrors(
          this.field.name, 'settings.payments.paymentmethods.bad_card_number')
      }
    })
  }
}
</script>

<style lang="scss">
label > div {
  width: 100%;
}

.StripeElement {
  background-color: white;
  border: none;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1.5px #D1D8E8;
  -webkit-box-shadow: inset 0 0 0 1.5px #D1D8E8;
  color: #4C71A5;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  margin-bottom: 15px;
  outline: none;
  padding: 15px 12.5px;
  -webkit-transition: -webkit-box-shadow 0.15s;
  transition: -webkit-box-shadow 0.15s;
  transition: box-shadow 0.15s;
  transition: box-shadow 0.15s, -webkit-box-shadow 0.15s;
  width: 100%
}

.StripeElement + .StripeElement {
  display: inline-block;
  margin-right: 10px;
  width: calc(50% - 8px);
}

.StripeElement + .StripeElement + .StripeElement {
  margin-left: 5px;
  margin-right: 0px;
}
</style>
