const compare = (a, b) => {
  if (a.full_name.toLowerCase() < b.full_name.toLowerCase()) {
    return -1
  }
  if (a.full_name.toLowerCase() > b.full_name.toLowerCase()) {
    return 1
  }
  return 0
}

// initial state
const state = {
  action: '',
  agents: null,
  defaultFontSize: window.getComputedStyle(
    document.body, null).getPropertyValue('font-size'),
  emojiPickerShown: false,
  errorMsg: null,
  features: null,
  lastMessageDate: null,
  modalShown: false,
  overlayShown: false,
  roomLeftMenuShown: false,
  roomRightMenuShown: false,
  userMenuShown: false,
  skills: null,
  wsReady: false
}

// getters
const getters = {
  getAction () {
    return state.action
  },
  getAgentByUuid: state => uuid => {
    let agent
    if (state.agents) {
      state.agents.forEach(agent_ => {
        if (agent_.uuid === uuid) {
          let fullName = `${agent_.first_name} ${agent_.last_name}`
          if (fullName === ' ') {
            fullName = agent_.email
          }
          agent = Object.assign({}, agent_, { full_name: fullName })
        }
      })
    }
    return agent
  },
  getAgentsBySkillUuid: state => uuid => {
    const skills = state.skills
    const agents = state.agents
    const result = []
    if (skills !== null && agents !== null) {
      skills.forEach((skill) => {
        if (skill.uuid === uuid) {
          agents.forEach((agent) => {
            const agentSkills = []
            agent.skills.forEach((agentSkill) => {
              agentSkills.push(agentSkill.uuid)
            })
            if (agentSkills.indexOf(skill.uuid) > -1) {
              let fullName = `${agent.first_name} ${agent.last_name}`
              if (fullName === ' ') {
                fullName = agent.email
              }
              agent = Object.assign({}, agent, { full_name: fullName })
              result.push(agent)
            }
          })
        }
      })
    }
    return result.sort(compare)
  },
  getAgentsBySkills: state => {
    const skills = state.skills
    const agents = state.agents
    const result = {}
    if (skills !== null && agents !== null) {
      skills.forEach((skill) => {
        result[skill.label] = {
          label: skill.label,
          uuid: skill.uuid,
          data: []
        }
        const agentData = []
        agents.forEach((agent) => {
          const agentSkills = []
          agent.skills.forEach((agentSkill) => {
            agentSkills.push(agentSkill.uuid)
          })
          if (agentSkills.indexOf(skill.uuid) > -1) {
            let fullName = `${agent.first_name} ${agent.last_name}`
            if (fullName === ' ') {
              fullName = agent.email
            }
            agent = Object.assign({}, agent, { full_name: fullName })
            agentData.push(agent)
          }
        })

        result[skill.label].data = agentData.sort(compare)
      })
    }
    return result
  },
  getError (state) {
    return state.errorMsg
  },
  getFeatures () {
    return state.features
  },
  getFontSize () {
    return state.defaultFontSize
  },
  getLastMessageDate () {
    return state.lastMessageDate
  },
  getSkillByUuid: state => uuid => {
    let skill = 'my'
    state.skills.forEach(skill_ => {
      if (skill_.uuid === uuid) {
        skill = skill_
      }
    })
    return skill
  },
  getSkills: state => {
    return state.skills
  },
  isEmojiPickerShown () {
    return state.emojiPickerShown
  },
  isModalShown () {
    return state.modalShown
  },
  isOverlayShown () {
    return state.overlayShown
  },
  isRoomLeftMenuShown () {
    return state.roomLeftMenuShown
  },
  isRoomRightMenuShown () {
    return state.roomRightMenuShown
  },
  isUserMenuShown () {
    return state.userMenuShown
  },
  isWsReady () {
    return state.wsReady
  }
}

// actions
const actions = {
  answerPing () {
    this._vm.$socket.send({
      cmd: 'pong'
    })
  },
  fetchPublicAgents ({ commit }, skill) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_GBA_URL}/agents/public/${skill}`)
        .then(response => response.json())
        .then(resolve)
        .catch(err => {
          this.$store.dispatch('global/handleHttpError', err)
          reject(err)
        })
    })
  },
  sendGetAgents () {
    this._vm.$socket.send({
      cmd: 'get_agents'
    })
  },
  sendGetFeatures () {
    this._vm.$socket.send({
      cmd: 'get_features'
    })
  },
  sendGetSkills () {
    this._vm.$socket.send({
      cmd: 'get_skills'
    })
  },
  showModal ({ commit }, action) {
    commit('updateAction', action)
    commit('updateModalShown', true)
    commit('hideLeftRoomMenu')
    commit('hideRightRoomMenu')
    commit('hideEmojiPicker')
  }
}

// mutations
const mutations = {
  hideModal (state) {
    state.modalShown = false
  },
  hideEmojiPicker (state) {
    state.emojiPickerShown = false
  },
  hideOverlay (state) {
    state.overlayShown = false
  },
  hideLeftRoomMenu (state) {
    state.roomLeftMenuShown = false
  },
  hideRightRoomMenu (state) {
    state.roomRightMenuShown = false
  },
  hideUserMenu (state) {
    state.userMenuShown = false
  },
  resetAction (state) {
    state.action = ''
  },
  resetError (state) {
    state.errorMsg = null
  },
  showOverlay (state) {
    state.overlayShown = true
  },
  updateAction (state, action) {
    state.action = action
  },
  updateAgents (state, data) {
    state.agents = data.data
  },
  updateError (state, msg) {
    // console.log('MSG!', msg, '??')
    state.errorMsg = msg
  },
  updateFeatures (state, data) {
    state.features = data.features
  },
  updateModalShown (state, force) {
    state.modalShown = force !== undefined ? force : !state.modalShown
    if (state.modalShown === true) {
      state.overlayShown = false
    }
  },
  updateOverlayShown (state) {
    state.overlayShown = !state.overlayShown
  },
  updateRoomLeftMenuShown (state) {
    state.roomLeftMenuShown = !state.roomLeftMenuShown
  },
  updateRoomRightMenuShown (state) {
    state.roomRightMenuShown = !state.roomRightMenuShown
  },
  updateUserMenuShown (state) {
    state.userMenuShown = !state.userMenuShown
  },
  updateEmojiPickerShown (state) {
    state.emojiPickerShown = !state.emojiPickerShown
  },
  updateLastMessageDate (state, data) {
    state.lastMessageDate = data
  },
  updateSkills: (state, data) => {
    // console.log('UPDATE SKILLS', rootState)
    state.skills = data.data
  },
  updateWsReady: (state, data) => {
    state.wsReady = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
