import i18n from '@/i18n'
import logo from '@/assets/img/logo.png'
import push from 'push.js'

import pageTitleNotification from '@/components/flash-title'

const flashTitle = pageTitleNotification()

export default function Handler ($store, router) {
  // eslint-disable-next-line
  const delete_medias = (data) => {
    const accounts = $store.getters['threads/accounts']()
    if (accounts && accounts.find(a => a.id === data.data.account)) {
      $store.dispatch('threads/deleteThreads', data.data)
    }
  }

  // eslint-disable-next-line
  const add_note = () =>  {
  }

  // eslint-disable-next-line
  const change_password = () => {
    window.location.href = '/login'
  }

  // eslint-disable-next-line
  const close_room = () =>  {
    // console.log('CLOSE ROOM', data)
  }

  // eslint-disable-next-line
  const crm_research = (data) => {
    $store.commit('custom/updateCrmResults', data)
  }

  // eslint-disable-next-line
  const crm_callback = (data) => {
    $store.dispatch('GBA/crm/executeCallback', data.data)
  }

  // eslint-disable-next-line
  const get_agents = (data) => {
    $store.commit('GBA/global/updateAgents', data)
  }

  // eslint-disable-next-line
  const get_features = ({ data }) => {
    $store.commit('GBA/global/updateFeatures', data)
  }

  // eslint-disable-next-line
  const get_header_stats = (data) => {
    $store.commit('GBA/user/updateHeaderStats', data)
  }

  // eslint-disable-next-line
  const get_room = (data) => {
    const queryFromSearch = String(router.currentRoute.query['from-search']).toLowerCase() === 'true'
    data.from_search = $store.getters['GBA/search/isSearching'] && queryFromSearch
    $store.commit('GBA/rooms/updateRoom', data)
  }

  // eslint-disable-next-line
  const get_messages = (data) => {
    // console.log('HANDLER -> GET ROOM MESSAGES : DATA', data)
    $store.commit('GBA/rooms/updateRoomMessages', data)
  }

  // eslint-disable-next-line
  const get_notes = (data) => {
    // console.log('HANDLER -> GET ROOM MESSAGES : DATA', data)
    $store.commit('GBA/rooms/updateRoomNotes', data)
  }

  // eslint-disable-next-line
  const get_products = (data) => {
    $store.commit('custom/updateProducts', data)
  }

  // eslint-disable-next-line
  const get_rooms = (data) => {
    // Code for "unit testing" the rooms list
    // data = {
    //   data: [{
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "5f6dfb79-9ab4-4311-8779-b111085ba56b",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "waiting", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID418",
    //       "extra": "",
    //       "skill": "Facturation",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "ed12d18a-1a1b-434e-ad39-0a232fdd5e3e",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "running", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID417",
    //       "extra": "",
    //       "skill": "SAV",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "ed12d18a-1a1b-434e-ad39-0a232fdd5e3e",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "transferred", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID410",
    //       "extra": "",
    //       "skill": "SAV",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "5f6dfb79-9ab4-4311-8779-b111085ba56b",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "running", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID421",
    //       "extra": "",
    //       "skill": "Facturation",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a2",
    //     "created": 1557750290,
    //     "skill": "5f6dfb79-9ab4-4311-8779-b111085ba56b",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "waiting", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID419",
    //       "extra": "",
    //       "skill": "Facturation",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "ed12d18a-1a1b-434e-ad39-0a232fdd5e3e",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "closed", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID006",
    //       "extra": "",
    //       "skill": "SAV",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }, {
    //     "uuid": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "created": 1557750290,
    //     "skill": "ed12d18a-1a1b-434e-ad39-0a232fdd5e3e",
    //     "guest": "2c50531c-2365-45c8-92e1-10ed697118a1",
    //     "agent": null,
    //     "last_waiting": 1557750290,
    //     "last_running": null, "last_closed": null,
    //     "status": "waiting", "unread_msg": 0,
    //     "infos": {
    //       "name": "ID437",
    //       "extra": "",
    //       "skill": "Facturation",
    //       "device": "",
    //       "origin": "",
    //       "browser": "",
    //       "language": "Fran\u00e7ais",
    //       "active_since": 1557750290,
    //       "phone_number": "",
    //       "satisfaction": -1
    //     }
    //   }]
    // }

    if (process.env.VUE_APP_SAAS !== undefined) {
      $store.commit('GBA/rooms/updateList2', data)
    } else {
      // TO REMOVE WITH STANDALONE
      $store.commit('GBA/rooms/updateList', data)

      let roomTimesFunction = $store.getters['custom/roomTimesFunction']
      if (roomTimesFunction === undefined) {
        roomTimesFunction = 'GBA/rooms/initRoomsTimes'
      }
      $store.dispatch(roomTimesFunction)
    }
  }

  // eslint-disable-next-line
  const get_rooms_by_skill = (data) => {
    $store.commit('GBA/rooms/updateListBySkill', data)
    let roomTimesFunction = $store.getters['custom/roomTimesFunction']
    if (roomTimesFunction === undefined) {
      roomTimesFunction = 'GBA/rooms/initRoomsTimes'
    }
    $store.dispatch(roomTimesFunction)
  }

  // eslint-disable-next-line
  const get_skills = (data) => {
    $store.commit('GBA/global/updateSkills', data)
  }

  // eslint-disable-next-line
  const get_stats = (data) => {
    $store.commit('GBA/statistics/updateStats', data)
  }

  // eslint-disable-next-line
  const get_csv_stats = (data) => {
    $store.commit('GBA/statistics/updateCsvStats', data)
  }

  // eslint-disable-next-line
  const get_supervisor_csv_stats = (data) => {
    $store.commit('GBA/statistics/updateCsvStats', data)
  }

  // eslint-disable-next-line
  const get_supervisor_stats = (data) => {
    $store.commit('GBA/statistics/updateStats', data)
  }

  // eslint-disable-next-line
  const get_zencalls = (data) => {
    $store.commit('GBA/rooms/updateZencallSkills', data)
  }

  // eslint-disable-next-line
  const join_room = () =>  {
    // console.log('HANDLER -> GET ROOMS : DATA', data)
    // $store.commit('GBA/rooms/initRoomHistory', data)
  }

  const login = (data) => {
    $store.commit('GBA/user/updateToken', data.token)
    if (router.currentRoute.query.redirect === undefined) {
      router.push({ name: 'GBA-dashboard' })
    } else {
      router.push(router.currentRoute.query.redirect)
    }
    $store.dispatch('GBA/user/sendUser')
  }

  const logout = () => {
    $store.commit('GBA/user/logout')
  }

  // eslint-disable-next-line
  const launch_zencall = () => {
  }

  const pause = (data) => {
    $store.commit('GBA/user/updatePause', data.pause)
  }

  const ping = () => {
    if (router.currentRoute.path === '/') {
      router.push({ name: 'GBA-dashboard' })
    }
    $store.dispatch('GBA/global/answerPing')
  }

  const pong = () => {
    if (router.currentRoute.path === '/') {
      router.push({ name: 'GBA-dashboard' })
    }
  }

  // eslint-disable-next-line
  const contact_reasons_listing = (data) => {
    const user = $store.getters['GBA/user/getUser']
    let roomAgent
    if (data.data.agent !== null) {
      roomAgent = data.data.agent
      if (roomAgent && user.uuid === roomAgent) {
        $store.dispatch('GBA/contactReasons/startAgentProcess', {
          contactReasons: data.data.contact_reasons,
          customer: data.data.customer,
          roomEvent: data.data.room_event
        })
      }
    }
  }

  // eslint-disable-next-line
  const reset_password = () => {
  }

  // eslint-disable-next-line
  const room_event = (data) => {
    if (!router.currentRoute.params.event_uuid) {
      $store.commit('GBA/rooms/updateRoomEvent', data)
    }

    if (process.env.VUE_APP_SAAS !== undefined) {
      $store.dispatch('GBA/rooms/fetchRoomToUpdate', data)
      // TO REMOVE WITH STANDALONE
    } else {
      [data.data.previous_status, data.data.current_status].forEach(status => {
        if (['running', 'late', 'waiting', 'closed', 'transferred'].indexOf(status) > -1) {
          status = status === 'transferred' ? 'waiting' : status

          const opt = { status }
          const tmp = $store.getters['GBA/rooms/getAll'][status].data
          const list = Array.isArray(tmp) === true
            ? tmp
            : tmp[data.data.skill].data
          const lastRoom = list[list.length - 1]

          // Reload first page without paging if room status is "running" or "late"
          if (['running', 'late'].indexOf(status) < 0 && lastRoom !== undefined) {
            opt.to_room = lastRoom.uuid
          }

          if (list !== tmp) {
            opt.skill = data.data.skill
          }

          $store.dispatch('GBA/rooms/sendRoomsList', opt)
        }
      })
    }

    // Disable guest typing on guest msg or if room is not longer used by agent
    if (['closed', 'transferred', 'guest_msg'].indexOf(data.data.kind) >= 0) {
      $store.commit('GBA/rooms/updateRoomTyping', {
        active: false,
        room: data.data.room.uuid
      })
    }

    const allowedAndWantedNotif = $store.getters['GBA/user/getUser'] &&
      $store.getters['GBA/user/getUser'].want_notif &&
      $store.getters['GBA/user/isAllowedToPush'] === true
    const msgHasContent = 'data' in data && 'rich_content' in data.data &&
      data.data.rich_content !== null &&
      data.data.rich_content.length > 0
    const showNotif = () => {
      window.parent.postMessage({
        kind: 'showNotification',
        value: data.data.rich_content[0].txt
      }, '*')
      flashTitle.on(`📫 ${i18n.t('doc_title_new_msg')}`, 1000)
      push.create(i18n.t('push_title'), {
        body: data.data.rich_content[0].txt,
        icon: logo
      })
    }

    if (allowedAndWantedNotif && msgHasContent) {
      if (window === window.parent && navigator.getInstalledRelatedApps) {
        navigator.getInstalledRelatedApps().then(apps => {
          if (!apps || !apps.length) {
            showNotif()
          }
        })
      } else {
        showNotif()
      }
    }

    if ('data' in data && 'rich_content' in data.data &&
        data.data.rich_content !== null &&
        data.data.rich_content.length > 0) {
      $store.commit('GBA/global/updateLastMessageDate', Date.now())
    }
  }

  // eslint-disable-next-line
  const room_is_closed = (data) => {

    if (data.data.agent !== null) {
      const user = $store.getters['GBA/user/getUser']
      const currentRoom = $store.getters['GBA/rooms/getRoom']
      const roomAgent = data.data.agent
      const room = data.data.room

      if (roomAgent && user && user.uuid === roomAgent && currentRoom && currentRoom.uuid === room) {
        $store.commit('GBA/rooms/setRoomHasBeenClosed')

        if ($store.getters['GBA/contactReasons/agentContactReasons'].length === 0) {
          // no contact reason, display directly the closedRoomModal
          $store.dispatch('GBA/global/showModal', 'closed-room', { root: true })
        }
      }
    }
  }

  // eslint-disable-next-line
  const send_message = () => {
  }

  // eslint-disable-next-line
  const set_guest_infos = () =>  {
  }

  const settings = (data) => {
    $store.commit('GBA/user/updateUser', data)
  }

  // eslint-disable-next-line
  const transfer_room = () =>  {
    //
  }

  const typing = (data) => {
    $store.commit('GBA/rooms/updateRoomTyping', data.data)
  }

  const unknownCmd = (cmd) => {
    $store.commit('GBA/global/updateError', `Unknown command: ${cmd}`)
  }

  // eslint-disable-next-line
  const update_medias = (data) => {
    const accounts = $store.getters['threads/accounts']()
    if (accounts && accounts.find(a => a.id === data.data.account)) {
      $store.dispatch('threads/updateThreads', data.data)
    }
  }

  return {
    add_note: add_note,
    change_password: change_password,
    close_room: close_room,
    contact_reasons_listing: contact_reasons_listing,
    crm_research: crm_research,
    crm_callback: crm_callback,
    delete_medias,
    get_agents: get_agents,
    get_features,
    get_header_stats: get_header_stats,
    get_messages: get_messages,
    get_notes: get_notes,
    get_products: get_products,
    get_room: get_room,
    // get_rooms: get_rooms,
    get_rooms_list: get_rooms,
    get_rooms_by_skills: get_rooms_by_skill,
    get_skills: get_skills,
    get_csv_stats: get_csv_stats,
    get_stats: get_stats,
    get_supervisor_csv_stats: get_supervisor_csv_stats,
    get_supervisor_stats: get_supervisor_stats,
    get_zencalls: get_zencalls,
    join_room: join_room,
    launch_zencall: launch_zencall,
    login: login,
    logout,
    pause: pause,
    ping: ping,
    pong: pong,
    reset_password: reset_password,
    room_event: room_event,
    room_is_closed: room_is_closed,
    send_message: send_message,
    set_guest_infos: set_guest_infos,
    settings: settings,
    transfer_room: transfer_room,
    typing,
    unknownCmd: unknownCmd,
    update_medias
  }
}
