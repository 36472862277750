import { Commit, Dispatch } from 'vuex'

import uuidv4 from '@/components/uuidv4'
import { Form, FormField, FormFieldChoice, FormsState } from '@/types/webivr/forms'

// initial state
const state: FormsState = {
  current: null,
  list: null
}

// getters
const getters = {
  current (): Form | null {
    return state.current
  },
  list (): Array<Form> | null {
    return state.list
  }
}

// mutations
const mutations = {
  setCurrent (state: FormsState, data: Form): void {
    if (data && data.fields) {
      data.fields.forEach((field: Record<string, any>) => {
        if (field.use_weight) {
          field.weight = parseInt(field.weight, 10)
        } else {
          field.weight = 0
        }
        if (field.choices) {
          if (field.choices.length > 0) {
            field.choices.forEach((choice: Record<string, any>) => {
              choice.weight = parseInt(choice.weight, 10)
            })
            field.choices.sort((a: FormFieldChoice, b: FormFieldChoice) => a.order < b.order ? -1 : 1)
          }
        }
      })
      data.fields?.sort((a: FormField, b: FormField) => a.order < b.order ? -1 : 1)
    }
    state.current = data
  },
  setList (state: FormsState, data: Array<Form>): void {
    state.list = data
  }
}

// actions
const actions = {
  deleteForm ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/forms/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchForm ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Form> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/forms/${id}`)
        .then((response: Response) => response.json())
        .then((data: Form) => {
          if (Array.isArray(data.notification_emails)) {
            data.notification_emails = data.notification_emails.join(',')
          }
          data.fields.forEach((field: FormField) => {
            field.choices?.forEach((choice: FormFieldChoice) => {
              choice.id = uuidv4()
            })
          })
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Form>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/forms`)
        .then((response: Response) => response.json())
        .then((data: Array<Form>) => {
          commit('setList', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveForm ({ dispatch }: { dispatch: Dispatch }, data: Form): Promise<Form> {
    let url = `${process.env.VUE_APP_WEBIVR_URL}/forms`
    if (data.id) {
      url += '/' + data.id
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: Form) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
