import WebSocketClient from '@/components/basic-native-rws'
import Handler from './handler'

export default function WS ($store, settings, router) {
  const rws = new WebSocketClient()

  function _getLocalToken () {
    const token = localStorage.GBAtoken ? localStorage.GBAtoken : ''
    $store.commit('GBA/user/updateToken', token)
    return token
  }

  function _handleMsgs (rawData, router) {
    const data = JSON.parse(rawData.data)
    const handler = Handler($store, router)

    // console.log('_H DATA', data)
    if (data && data.error !== undefined) {
      if (data.error === 'not_authenticated') {
        localStorage.removeItem('GBAtoken')
        const allowedRoutes = ['/login', '/change-password', '/password-reset']
        let startPathName = window.location.pathname
        if (window.location.pathname.lastIndexOf('/') > 0) {
          startPathName = startPathName.substring(
            0, window.location.pathname.indexOf('/', 1))
        }
        if (allowedRoutes.indexOf(startPathName) === -1) {
          router.push('/login')
        }
      } else {
        // display error in popin
        $store.commit('GBA/global/updateError', data.error)
        $store.commit('GBA/global/updateAction', 'error-msg')
        $store.commit('GBA/global/updateModalShown', true)
      }
      // console.warn('ERROR', data['error'])
    } else if (data && data.pause !== undefined) {
      if (handler[data.cmd] !== undefined) {
        handler[data.cmd](data)
      } else {
        handler.unknownCmd(data.cmd)
      }
    } else if (data && data.result === undefined && data.data === undefined &&
               data.messages === undefined) {
      // Return from the ping cmd at startup when the user is authenticated.
      // Proceed to the desired page
      if (handler[data.cmd] !== undefined) {
        handler[data.cmd]()
      } else {
        handler.unknownCmd(data.cmd)
      }
    } else if (data && data.result !== undefined && data.result === 'success') {
      // console.log('HANDLER WITH RESULT KEY', data)
      if (handler[data.cmd] !== undefined) {
        handler[data.cmd](data)
      } else {
        handler.unknownCmd(data.cmd)
      }
    } else if (data && (data.data !== undefined ||
        data.messages !== undefined)) {
      // console.log('HANDLER WITH DATA KEY')
      if (handler[data.cmd] !== undefined) {
        handler[data.cmd](data)
      } else {
        handler.unknownCmd(data.cmd)
      }
    } else {
      if (handler[data.cmd] !== undefined) {
        handler[data.cmd](data)
      } else {
        handler.unknownCmd(data.cmd)
      }
    }
  }

  const _start = function () {
    rws.send(JSON.stringify({ cmd: 'ping' }))
    return rws
  }

  const init = function () {
    const localToken = _getLocalToken()
    const wsUrl = `${settings.server.ws}?${localToken}`

    rws.open(wsUrl)

    rws.onmessage = function (data) {
      _handleMsgs(data, router)
    }

    $store.commit('GBA/global/updateWsReady', true)

    _start()

    return rws
  }

  const send = function (data) {
    // console.log('READY FOR SENDING ?', rws.instance.readyState)
    if (rws.instance !== undefined && rws.instance.readyState === 1) {
      $store.commit('GBA/global/resetError')
      rws.send(JSON.stringify(data))
    } else {
      window.setTimeout(() => {
        send(data)
      }, 100)
    }
  }

  return {
    rws: rws,
    init: init,
    send: send
  }
}
