
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import merge from 'deepmerge'

import { messages as GBAmessages } from '@gba/i18n'

import fr from '@/i18n/fr-fr/index.js'
import en from '@/i18n/en-gb/index.js'

Vue.use(VueI18n)
Vue.i18n = new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  messages: merge.all([
    GBAmessages,
    {
      en,
      fr
    } // higher priority
  ]) // set locale messages
})

const i18n = Vue.i18n

export default i18n
