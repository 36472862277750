<template>
  <div class="radio-group">
    <slot/>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue
} from 'vue-property-decorator'

@Component
export default class RadioGroup extends Vue {}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.radio-group {
  border-radius: 6px;
  display: inline-flex;
  font-family: 'Be Vietnam';
  font-size: .9rem;
  font-weight: 500;
  overflow: hidden;

  label {
    background-color: $grey-light;
    padding: 8px 1rem;
    font-weight: 500;
    margin: 0;
    position: relative;
    text-align: center;
    transition: background-color .15s, color .15s;

    &:hover {
      cursor: pointer;
      background-color: $grey;
    }

    &:not(:last-child):after {
      background-color: $grey;
      content: '';
      height: 60%;
      position: absolute;
      right: 0;
      top: 20%;
      width: 1px;
    }
  }

  input {
    display: none;

    &:not(:disabled) + label:hover {
      cursor: pointer;
      background-color: $grey;
    }

    &:checked + label {
      background-color: $primary !important;
      color: white;

      &:after {
        content: none;
      }
    }

    &:disabled + label:hover {
      cursor: not-allowed;
    }
  }
}
</style>
