import { Commit, Dispatch } from 'vuex'

import { WebIVR, WebIVRState, WebIVRVersion } from '@/types/webivr/main'

// initial state
const state: WebIVRState = {
  current: null,
  list: null
}

// getters
const getters = {
  current (): WebIVR | null {
    return state.current
  },
  list (): Array<WebIVR> | null {
    return state.list
  }
}

// mutations
const mutations = {
  setCurrent (state: WebIVRState, data: WebIVR): void {
    state.current = data
  },
  setList (state: WebIVRState, data: Array<WebIVR>): void {
    state.list = data
  }
}

// actions
const actions = {
  deleteWebIVR ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  duplicateWebIVR ({ dispatch }: { dispatch: Dispatch }, data: { id: string, label: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.id}/duplicate`, {
        label: data.label
      })
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchWebIVRVersions ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Array<WebIVRVersion>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${id}/versions`)
        .then((response: Response) => response.json())
        .then((data: Array<WebIVRVersion>) => {
          if (data.length > 0) {
            resolve(data)
          }
          reject(Error('No production version'))
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchWebIVR ({ dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<WebIVR> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: WebIVR) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<WebIVR>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((data: Array<WebIVR>) => {
          commit('setList', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveWebIVR ({ dispatch }: { dispatch: Dispatch }, data: WebIVR): Promise<WebIVR> {
    let url = `${process.env.VUE_APP_WEBIVR_URL}/accounts`
    if (data.id) {
      url += '/' + data.id
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: WebIVR) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
