import { Commit, Dispatch } from 'vuex'

import {
  WebivrIntegration,
  WebivrIntegrationsState,
  WebivrIntegrationsTree,
  WebivrIntegrationNavbarTree
} from '@/types/webivr/integrations'

// initial state
const state: WebivrIntegrationsState = {
  list: null
}

// getters
const getters = {
  list (): Array<WebivrIntegration> | null {
    return state.list
  }
}

// mutations
const mutations = {
  deleteIntegration (state: WebivrIntegrationsState, id: string): void {
    const index = (state.list || []).findIndex((bg: WebivrIntegration) => bg.id === id)
    if (index > -1 && state.list) {
      state.list.splice(index, 1)
    }
  },
  integration (state: WebivrIntegrationsState, data: WebivrIntegration): void {
    const index = (state.list || []).findIndex((bg: WebivrIntegration) => bg.id === data.id)
    if (index === -1) {
      if (state.list) {
        state.list.push(data)
      } else {
        state.list = [data]
      }
    } else {
      (this as any)._vm.$set(
        state.list,
        index,
        data
      )
    }
  },
  list (state: WebivrIntegrationsState, data: Array<WebivrIntegration>): void {
    data.forEach((integration: WebivrIntegration) => {
      if (integration.navbar) {
        integration.navbar.forEach((item: WebivrIntegrationNavbarTree, index: number) => {
          item.order = index + 1
        })
      }
    })
    state.list = data
  }
}

// actions
const actions = {
  delete ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, integrationId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations/${data.integrationId}`)
        .then(() => {
          commit('deleteIntegration', data.integrationId)
          resolve()
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteTree ({ dispatch }: { dispatch: Dispatch }, data: { accountId: string, integrationId: string, integrationTreeId: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations/${data.integrationId}/trees/${data.integrationTreeId}`)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetch ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, integrationId: string }): Promise<WebivrIntegration> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations/${data.integrationId}`)
        .then((response: Response) => response.json())
        .then((integration: WebivrIntegration) => {
          integration.navbar?.forEach((item: WebivrIntegrationNavbarTree, index: number) => {
            item.order = index + 1
          })
          commit('integration', integration)
          resolve(integration)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, accountId?: string): Promise<Array<WebivrIntegration>> {
    let url = `${process.env.VUE_APP_WEBIVR_URL}/integrations`

    if (accountId) {
      url += `?account_id=${accountId}`
    }
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(url)
        .then((response: Response) => response.json())
        .then((data: Array<WebivrIntegration>) => {
          if (!accountId) {
            commit('list', data)
          }
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, integrationId: string, payload: WebivrIntegration }): Promise<WebivrIntegration> {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations`

      if (data.integrationId) {
        url += `/${data.integrationId}`
      }
      (this as any)._vm.$http.post(url, data.payload)
        .then((response: Response) => response.json())
        .then((integration: WebivrIntegration) => {
          commit('integration', integration)
          resolve(integration)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveNavbar (
    { dispatch, commit, getters }: { dispatch: Dispatch, commit: Commit, getters: any },
    data: Record<string, any>): Promise<Array<WebivrIntegrationNavbarTree>> {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations/${data.integrationId}/navbar`;

      (this as any)._vm.$http.post(url, data.payload)
        .then((response: Response) => response.json())
        .then((result: Array<WebivrIntegrationNavbarTree>) => {
          const list = JSON.parse(JSON.stringify(getters.list))
          list.forEach((integration: WebivrIntegration) => {
            if (integration.id === data.integrationId && integration.navbar) {
              integration.navbar = result
            }
          })
          commit('list', list)
          resolve(result)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveTree ({ dispatch }: { commit: Commit, dispatch: Dispatch }, data: { accountId: string, integrationId: string, integrationTreeId: string, treeIdentifier: string }): Promise<WebivrIntegrationsTree> {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_WEBIVR_URL}/accounts/${data.accountId}/integrations/${data.integrationId}/trees`

      if (data.integrationTreeId) {
        url += `/${data.integrationTreeId}`
      }

      (this as any)._vm.$http.post(url, {
        tree_identifier: data.treeIdentifier
      })
        .then((response: Response) => response.json())
        .then((tree: WebivrIntegrationsTree) => {
          resolve(tree)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
