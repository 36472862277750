<template>
    <div v-if="$store.getters['global/isModalShown'] === true">
      <div class="modal-mask" v-on:click.self="cancelModal">
        <transition name="modal" >
          <div class="modal-wrapper"
               v-if="$store.getters['global/isModalOverlayShown'] === true">
            <component :is="modalComponent" :close-modal=closeModal
              :action=action :cancel-modal=cancelModal :confirm-modal="$store.getters['global/getModalConfirm']" v-bind="$store.getters['global/getModalProps']" >
            </component>
          </div>
        </transition>
      </div>
    </div>
</template>

<script>
import AbcChannelSent from '@/components/modals/AbcChannelSent.vue'
import ChannelEditConfirmed from '@/components/modals/ChannelEditConfirmed.vue'
import ChannelSendConfirmed from '@/components/modals/ChannelSendConfirmed.vue'
import ConfirmAccountDeleteModal from '@/components/modals/ConfirmAccountDeleteModal.vue'
import ConfirmAgentDeleteModal from '@/components/modals/ConfirmAgentDeleteModal.vue'
import ConfirmAgentPauseModal from '@/components/modals/ConfirmAgentPauseModal.vue'
import ConfirmDialogFlowNodeDeleteModal from '@/components/modals/ConfirmDialogFlowNodeDeleteModal.vue'
import ConfirmPaymentMethodModal from '@/components/modals/ConfirmPaymentMethodModal.vue'
import ConfirmTreeDeleteModal from '@/components/modals/ConfirmTreeDeleteModal.vue'
import ErrorMsgModal from '@/components/modals/ErrorMsgModal.vue'
import FbRefreshMsgModal from '@/components/modals/FbRefreshMsgModal.vue'
import GbmPlaceModal from '@/components/modals/GbmPlaceModal.vue'
import PasswordChangeModal from '@/components/modals/PasswordChangeModal.vue'
import PermissionDeniedModal from '@/components/modals/PermissionDeniedModal.vue'
import NoteModal from '@/components/modals/NoteModal.vue'
import OfferModal from '@/components/modals/OfferModal.vue'
import RegisteredAccountModal from '@/components/modals/RegisteredAccountModal.vue'
import WarnOpenRoomsModal from '@/components/modals/WarnOpenRoomsModal.vue'

export default {
  name: 'Modal',
  components: {
    ConfirmAccountDeleteModal,
    ConfirmAgentDeleteModal,
    ConfirmDialogFlowNodeDeleteModal,
    ConfirmPaymentMethodModal,
    ConfirmTreeDeleteModal,
    ErrorMsgModal,
    FbRefreshMsgModal,
    GbmPlaceModal,
    NoteModal,
    PasswordChangeModal,
    PermissionDeniedModal,
    OfferModal,
    RegisteredAccountModal,
    WarnOpenRoomsModal
  },
  data () {
    return {
      modalComponent: null
    }
  },
  computed: {
    action () {
      return this.$store.getters['global/getAction']
    }
  },
  methods: {
    cancelModal () {
      this.closeModal()
    },
    closeModal () {
      this.$store.commit('global/resetAction')
      this.$store.dispatch('global/hideModal')
    }
  },
  watch: {
    action (newValue) {
      if (newValue === 'abc-channel-sent') {
        this.modalComponent = AbcChannelSent
      } else if (newValue === 'channel-edit-confirmed') {
        this.modalComponent = ChannelEditConfirmed
      } else if (newValue === 'channel-send-confirmed') {
        this.modalComponent = ChannelSendConfirmed
      } else if (newValue === 'confirm-account-delete') {
        this.modalComponent = ConfirmAccountDeleteModal
      } else if (newValue === 'confirm-agent-delete') {
        this.modalComponent = ConfirmAgentDeleteModal
      } else if (newValue === 'confirm-agent-pause-modal') {
        this.modalComponent = ConfirmAgentPauseModal
      } else if (newValue === 'confirm-dfnode-delete') {
        this.modalComponent = ConfirmDialogFlowNodeDeleteModal
      } else if (newValue === 'confirm-paymentmethod') {
        this.modalComponent = ConfirmPaymentMethodModal
      } else if (newValue === 'confirm-tree-delete') {
        this.modalComponent = ConfirmTreeDeleteModal
      } else if (newValue === 'error-msg') {
        this.modalComponent = ErrorMsgModal
      } else if (newValue === 'fb-refresh-msg') {
        this.modalComponent = FbRefreshMsgModal
      } else if (newValue === 'choose-offer') {
        this.modalComponent = OfferModal
      } else if (newValue === 'gbm-place') {
        this.modalComponent = GbmPlaceModal
      } else if (newValue === 'password-change') {
        this.modalComponent = PasswordChangeModal
      } else if (newValue === 'permission-denied') {
        this.modalComponent = PermissionDeniedModal
      } else if (newValue === 'note') {
        this.modalComponent = NoteModal
      } else if (newValue === 'registered-account') {
        this.modalComponent = RegisteredAccountModal
      } else if (newValue === 'warn-open-rooms') {
        this.modalComponent = WarnOpenRoomsModal
      }
    }
  }
}
</script>

<style lang="scss">
body.modal-open {
  overflow-y: hidden;
}
.modal-container .modal-header {
  padding: 30px 0 20px 0;
  margin: 0;

  &.default {
    background: silver;

    + .modal-body .sidebar {
      background-color: #fff;
      margin: 0 auto;
      width: 400px !important;

      .sidebar-body {
        height: calc(100% - 36px);
        overflow-y: auto;

        .subtitle button {
          border-radius: 5px;
          height: 65px;
          margin: 10px;
          width: 75px;

          p {
            font-size: .55rem;
            line-height: .65rem;
            margin: .5rem 0 0 0;
            white-space: pre-line;
          }
        }
      }
    }
  }
}

div.channel-logo img {
  width: 32px;
}

div.form-control {
  height: 10rem;
  overflow: auto;
  white-space: pre-line;
}

.modal-mask {
  position: fixed;
  z-index: 19998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #2A4A88, $alpha: .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  position: fixed;
  width: 100%;
}

.modal-container {
  position: relative;
  background-color: #fff;
  border-radius: .3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  margin: 1.75rem auto;
  max-height: 100%;
  max-width: 90%;
  transition: all .3s ease;
}

.modal-body {
  padding: 0 30px;

  img {
    height: 64px;
  }

  p:where(:not(.gb-az-errors)) {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .emoji-menu-wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -40px;
    width: 100%;
    z-index: 1000;

    button {
      opacity: .5;
      padding: .5rem 1.2rem;
      transform: scale(0.75);
    }

    #EmojiPicker {
      position: absolute;
      margin-top: 32px;
    }
  }
}

.modal-footer {
  padding: 30px;
  flex-wrap: wrap;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.modal-enter-active {
  transition: .5s ease;
}

.modal-leave-active {
  transition: opacity 1s ease;
  position: absolute;
}

.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}

.modal-move {
  transition: 1s ease;
}

</style>
