<slot name="main">
</slot>

<script>
export default {
  name: 'BaseModal',
  props: {
    cancelModal: {
      type: Function,
      required: true
    },
    confirmModal: Function,
    closeModal: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

::v-deep .modal-header {
  margin: 1rem;
  text-align: center;

  h3 {
    color: $content;
  }
}

::v-deep .modal-body {
  margin: 1rem;
  text-align: center;

  p {
    font-size: .8rem;
  }
}

::v-deep .modal-footer {
  display: flex;
  justify-content: center;
}

::v-deep .material-design-icon {
  vertical-align: middle;
  margin-right: .5rem;
  font-size: 20px;
}

</style>
