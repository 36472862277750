import { render, staticRenderFns } from "./PasswordChangeModal.vue?vue&type=template&id=6ac1845b&scoped=true&v-slot=main"
import script from "./PasswordChangeModal.vue?vue&type=script&lang=js"
export * from "./PasswordChangeModal.vue?vue&type=script&lang=js"
import style0 from "./PasswordChangeModal.vue?vue&type=style&index=0&id=6ac1845b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ac1845b",
  null
  
)

export default component.exports