import Vue from 'vue'
import { Commit, Dispatch } from 'vuex'

import { GbChannel } from '@/types/campaigns/channels'
import { GbState, GbTemplate, GbTemplateQuickReply } from '@/types/campaigns/templates'

const state: GbState = {
  channels: null,
  template: null,
  templates: null
}

const getters = {
  getChannelById: (state: GbState) => (id: string) => {
    return (state.channels || []).find(c => c.id === id)
  },
  getChannels (state: GbState): Array<GbChannel> | null {
    return state.channels
  },
  getTemplate (state: GbState): GbTemplate | null {
    return state.template
  },
  getTemplateById: (state: GbState) => (id: string) => {
    return (state.templates || []).find(t => t.id === id)
  },
  getTemplates (state: GbState): Array<GbTemplate> | null {
    return state.templates
  }
}

const mutations = {
  setChannels (state: GbState, data: Array<GbChannel>): void {
    state.channels = data
  },
  setTemplate (state: GbState, data: GbTemplate): void {
    state.template = data
  },
  setTemplates (state: GbState, data: Array<GbTemplate>): void {
    state.templates = data
  }
}

const actions = {
  deleteTemplate ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates/${id}`)
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchChannels ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<any> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/channels`)
        .then((response: Response) => response.json())
        .then((data: Array<GbChannel>) => {
          commit('setChannels', data)
          resolve(data)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchTemplate ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<GbTemplate> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates/${id}`)
        .then((response: Response) => response.json())
        .then((template: GbTemplate) => {
          commit('setTemplate', template)
          resolve(template)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchTemplateQuickReplies ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<GbTemplateQuickReply> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates/${id}/quickreplies`)
        .then((response: Response) => response.json())
        .then((quickReplies: GbTemplateQuickReply) => {
          resolve(quickReplies)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchTemplates ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<GbTemplate>> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates`)
        .then((response: Response) => response.json())
        .then((templates: Array<GbTemplate>) => {
          commit('setTemplates', templates)
          resolve(templates)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveTemplate ({ dispatch }: { dispatch: Dispatch }, template: GbTemplate): Promise<GbTemplate> {
    let url = `${process.env.VUE_APP_CAMPAIGNS_URL}/templates/${template.kind}`

    if (template.id) {
      url += `/${template.id}`
    }

    return new Promise((resolve, reject) => {
      Vue.http.post(url, template)
        .then((response: Response) => response.json())
        .then((template: GbTemplate) => {
          resolve(template)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveTemplateQuickReplies ({ dispatch }: { dispatch: Dispatch }, { data, id }: { data: Array<GbTemplateQuickReply>, id: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates/${id}/quickreplies`, { data })
        .then(() => resolve(id))
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  submitWhatsAppTemplate ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<GbTemplate> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/templates/tyntec-wa/${id}/submit`)
        .then((response: Response) => response.json())
        .then((template: GbTemplate) => {
          resolve(template)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
