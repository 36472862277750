import { Dispatch } from 'vuex'

interface Data {
  'content': string
  'input_language'?: string // BCP_47
  'output_language': string // BCP_47
}

// actions
const actions = {
  translate ({ dispatch }: { dispatch: Dispatch }, data: Data): Promise<string> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_TRANSLATE_URL}/translate`, data)
        .then((response: Response) => response.json())
        .then(({ result }: { result: string }) => {
          resolve(result)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  actions
}
