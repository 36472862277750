// Settings init
export default {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  hasPermissions (user, route) {
    const routes = route.matched
    const userRole = user.user_role

    const allowedVersion = routes.every(record => {
      const saas = typeof record.meta.saas === 'function'
        ? record.meta.saas()
        : record.meta.saas
      return (saas === undefined ? this.saas : saas) === this.saas
    })

    const permissionRequired = Math.max(...routes.map(record => this.permissions[record.meta.permission] || 0))
    const permissionGranted = this.permissions[userRole] >= (permissionRequired || this.permissions.admin)

    const allowedRole = routes.every(record => {
      return (record.meta.roles || Object.keys(this.permissions)).indexOf(userRole) > -1
    })

    return allowedVersion && permissionGranted && allowedRole
  },
  isAllowedRoute (resolved) {
    const isSaaS = resolved.matched.some(record => {
      const saas = typeof record.meta.saas === 'function'
        ? record.meta.saas()
        : record.meta.saas
      return saas === undefined
        ? this.saas
        : saas
    })
    return isSaaS === this.saas
  },
  permissions: {
    owner: 5,
    admin: 4,
    manager: 3,
    content_manager: 2,
    flow_manager: 2,
    stats_manager: 2,
    agent: 1
  },
  prod: process.env.VUE_APP_ENV === 'prod',
  opengraph: process.env.VUE_APP_OPENGRAPH,
  sentry: process.env.VUE_APP_SENTRY_DSN,
  stripe_key: process.env.VUE_APP_STRIPE,
  saas: process.env.VUE_APP_SAAS !== 'false',
  server: {
    http: process.env.VUE_APP_HTTP,
    http_bb: process.env.VUE_APP_BB_HTTP + '/bot',
    ws: process.env.VUE_APP_WS
  },
  title: process.env.VUE_APP_TITLE,
  token: null
}
