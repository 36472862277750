import builder from './builder'
import faqs from './faqs'
import forms from './forms'
import integrations from './integrations'
import main from './main'
import templates from './templates'

export default {
  builder,
  faqs,
  forms,
  integrations,
  main,
  templates
}
