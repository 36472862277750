import { Commit, Dispatch } from 'vuex'

import {
  Suggestions,
  State,
  Account
} from '@/types/smartcoaching'

// initial state
const state: State = {
  accounts: null,
  current: null,
  suggestions: null
}

// getters
const getters = {
  accounts (): Array<Account> | null {
    return state.accounts
  },
  current (): Account | null {
    return state.current
  },
  suggestions (): Suggestions | null {
    return state.suggestions
  }
}

// mutations
const mutations = {
  accounts (state: State, data: Array<Account> | null): void {
    state.accounts = data
  },
  current (state: State, data: Account | null): void {
    state.current = data
  },
  suggestions (state: State, data: Suggestions | null): void {
    state.suggestions = data
  }
}

// actions
const actions = {
  deleteAccount ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_SMARTCOACHING_URL}/accounts/${id}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccount ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Account> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SMARTCOACHING_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          commit('current', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Account>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SMARTCOACHING_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((data: Array<Account>) => {
          commit('accounts', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSuggestions (
    { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
    // eslint-disable-next-line camelcase
    data: { event_id: string, session_id: string, text: string }): Promise<Suggestions> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_SMARTCOACHING_URL}/accounts/suggest/${data.session_id}/${data.event_id}`, {
        text: data.text
      })
        .then((response: Response) => response.json())
        .then((data: Suggestions) => {
          commit('suggestions', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveAccount (_: unknown, account: Account): Promise<Account> {
    let url = `${process.env.VUE_APP_SMARTCOACHING_URL}/accounts`

    if (account.id) {
      url += '/' + account.id
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, account)
        .then((response: Response) => response.json())
        .then((data: Account) => {
          resolve(data)
        }).catch((err: Error) => {
          reject(err)
        })
    })
  },
  // eslint-disable-next-line camelcase
  selectSuggestion (_: unknown, data: { final_text: string, response_index: number, sc_event_id: string, session_id: string}): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_SMARTCOACHING_URL}/accounts/suggest/${data.session_id}/${data.sc_event_id}/response`, {
        final_text: data.final_text,
        response_index: data.response_index
      })
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
