<template>
  <transition name="fade">
    <div class="loader" v-if="show">
      <div>
        <div>
          <img v-if="$settings.saas" src="@/assets/img/logo.png" alt="Logo ChayAll">
          <img v-else src="@/assets/img/logo-gb.png" alt="Logo Greenbureau">
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visibility: Boolean
  },
  computed: {
    show () {
      return this.$store.getters['global/isLoadingShown'] || this.visibility
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #2A4A88, $alpha: .5);
  z-index: 11000;
  top: 0;
  left: 0;

  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity .25s;
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0
  }

  & > div {
    height: 60px;
    width: 60px;
    position: absolute;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      animation: pulse3 3s infinite ease-out;
      width: 100%;
      height: 100%;
      position: relative;
      box-shadow: 0 8px 15px rgba($color: $content, $alpha: .1);

      img {
        position: relative;
        z-index: 3;
        max-width: 100%;
      }
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: darken($secondary, 20%);
      z-index: -1;
      border-radius: 25px;
      animation: pulse 3s infinite;
    }

    &:after {
      animation: pulse2 3s infinite .15s;
      z-index: -1;
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(0);
    opacity: .2;
  }
  30% {
    transform: scale(3.5);
    opacity: 0;
  }
  30.1% {
    transform: scale(0);
    opacity: 0;
  }
  30.2% {
    transform: scale(0);
    opacity: .2;
  }
  to {
    transform: scale(0);
    opacity: .2;
  }
}

@keyframes pulse2 {
  from {
    transform: scale(0);
    opacity: .05;
  }
  60% {
    transform: scale(8);
    opacity: 0;
  }
  60.1% {
    transform: scale(0);
    opacity: 0;
  }
  60.2% {
    transform: scale(0);
    opacity: .05;
  }
  to {
    transform: scale(0);
    opacity: .05;
  }
}

@keyframes pulse3 {
  from {
    transform: scale(1);
  }
  5% {
    transform: scale(.8);
  }
  10% {
    transform: scale(1.4);
  }
  13% {
    transform: scale(1);
  }
  to {
    transform: scale(1);
  }
}
</style>
