import i18n from '@/i18n'
import Vue from 'vue'

// initial state
const state = {
  conversationsKA: null,
  dashboard: {},
  dashboardKA: null,
  dashboardCommentsKA: null,
  dashboardWebivrsKA: null,
  flowData: null,
  GBA: {},
  global: {},
  globalKA: null,
  webivrsFlowData: null,
  webivrsFlowTreeData: null
}

// getters
const getters = {
  conversationsKA () {
    return state.conversationsKA
  },
  dashboard () {
    return state.dashboard
  },
  dashboardKA () {
    return state.dashboardKA
  },
  dashboardCommentsKA () {
    return state.dashboardCommentsKA
  },
  dashboardWebivrsKA () {
    return state.dashboardWebivrsKA
  },
  GBA () {
    return state.GBA
  },
  getFlowData () {
    return state.flowData
  },
  global () {
    return state.global
  },
  globalKA () {
    return state.globalKA
  },
  getWebivrsFlowData () {
    return state.webivrsFlowData
  },
  getWebivrsFlowTreeData () {
    return state.webivrsFlowTreeData
  }
}

// mutations
const mutations = {
  setConversationsKA (state, data) {
    state.conversationsKA = data
  },
  setDashboard (state, data) {
    state.dashboard = data
  },
  setDashboardKA (state, data) {
    state.dashboardKA = data
  },
  setDashboardCommentsKA (state, data) {
    state.dashboardCommentsKA = data
  },
  setDashboardWebivrsKA (state, data) {
    state.dashboardWebivrsKA = data
  },
  setGBA (state, data) {
    state.GBA = data
  },
  setFlowData (state, data) {
    const formattedData = []
    if (data !== null) {
      state.flowData = data
    }
  },
  setGlobal (state, data) {
    state.global = data
  },
  setGlobalKA (state, data) {
    state.globalKA = data
  },
  setWebivrsFlowData (state, data) {
    state.webivrsFlowData = data
  },
  setWebivrsFlowTreeData (state, data) {
    state.webivrsFlowTreeData = data
  }
}

// actions
const actions = {
  _export: ({ dispatch }, params, url, extension) => {
    dispatch('global/showLoading', null, { root: true })
    Vue.http.get(url, {
      params,
      responseType: 'blob'
    })
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style = 'display: none'
        a.href = url
        a.download = `export-${params.kind}.${extension}`

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  exportDashboardKA: ({ dispatch }, params) => {
    actions._export(
      { dispatch },
      params,
      `${process.env.VUE_APP_STATS_URL}/stats/dashboard2export`,
      'csv'
    )
  },
  exportDashboardCommentsKA: ({ dispatch }, params) => {
    actions._export(
      { dispatch },
      params,
      `${process.env.VUE_APP_STATS_URL}/stats/dashboard-comments-gc-export`,
      'csv'
    )
  },
  exportDashboardWebivrsKA: ({ dispatch }, params) => {
    actions._export(
      { dispatch },
      params,
      `${process.env.VUE_APP_STATS_GO_URL}/webivr/export`,
      'csv'
    )
  },
  exportDashboardWebivrFormsKA: ({ dispatch }, params) => {
    actions._export(
      { dispatch },
      params,
      `${process.env.VUE_APP_WEBIVR_URL}/forms/xlsx-export`,
      'xlsx'
    )
  },
  exportWebivrsFlowTreeKA: ({ dispatch }, params) => {
    actions._export(
      { dispatch },
      params,
      `${process.env.VUE_APP_STATS_GO_URL}/webivr/sankey-v2-export`,
      'csv'
    )
  },
  exportGlobalKA: ({ dispatch }, params) => {
    let url = `${process.env.VUE_APP_STATS_URL}/stats/statistics2export`
    if (params.kind === 'dialogflow') {
      url = `${process.env.VUE_APP_STATS_GO_URL}/bot/df-results`
      params.start_date = params.start_dt
      params.end_date = params.end_dt
    }

    actions._export(
      { dispatch },
      params,
      url,
      'csv'
    )
  },
  exportGlobalKA2: ({ dispatch }, params) => {
    let url = process.env.VUE_APP_GUESTS_URL

    params.end_date = params.end_dt
    params.start_date = params.start_dt

    switch (params.kind) {
      case 'dialogflow':
        url += '/stats/df-results'
        break
      case 'events':
        url += '/stats/export-events'
        break
      case 'smartcoaching':
        url += '/stats/export-smartcoaching-events'
        break
    }

    actions._export(
      { dispatch },
      params,
      url,
      'csv'
    )
  },
  fetchConversationsKA: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_GUESTS_URL}/stats/conversations`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setConversationsKA', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchContactReasonsKA: ({ dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_GUESTS_URL}/stats/contact-reasons`, { params })
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchDashboard: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/dashboard`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setDashboard', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchDashboardKA: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/dashboard2`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setDashboardKA', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchDashboardCommentsKA: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/dashboard-comments-gc`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setDashboardCommentsKA', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchDashboardWebivrsKA: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/webivr/dashboard`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setDashboardWebivrsKA', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchFlow: ({ commit }, params) => {
    Vue.http.get(`${process.env.VUE_APP_GUESTS_URL}/stats/sankey`, { params })
      .then(response => response.json()).then(data => {
        commit('setFlowData', data)
      }).catch((err) => {
        commit('global/updateError', err, { root: true })
      })
  },
  fetchGBA: ({ commit }) => {
    Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/gba-header`)
      .then(response => response.json()).then(data => {
        commit('setGBA', data)
      }).catch((err) => {
        commit('global/updateError', err, { root: true })
      })
  },
  fetchGlobal: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/statistics`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setGlobal', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGlobalKA: ({ commit, dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_URL}/stats/statistics2`, { params })
        .then(response => response.json())
        .then((data) => {
          commit('setGlobalKA', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchWebivrsFlowKA: ({ commit }, params) => {
    Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/webivr/sankey`, { params })
      .then(response => response.json()).then(data => {
        commit('setWebivrsFlowData', data)
      }).catch((err) => {
        commit('global/updateError', err, { root: true })
      })
  },
  fetchWebivrsFlowTreeKA: ({ commit }, params) => {
    Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/webivr/sankey-v2`, { params })
      .then(response => response.json()).then(data => {
        commit('setWebivrsFlowTreeData', data)
      }).catch((err) => {
        if (err.status === 400) {
          commit('global/updateError', err, { root: true })
        } else {
          commit(
            'global/updateError',
            i18n.t('error.Oups'),
            { root: true }
          )
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
