// initial state
const state = {
  group: null,
  groups: null
}

// getters
const getters = {
  group () {
    return state.group
  },
  groups () {
    return state.groups
  }
}

// mutations
const mutations = {
  setGroup (state, data) {
    state.group = data
  },
  setGroups (state, data) {
    state.groups = data
  }
}

// actions
const actions = {
  deleteGroup ({ dispatch }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$http.delete(`${process.env.VUE_APP_BACKEND_URL}/groups/${id}`)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGroup ({ commit, dispatch }, uuid) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/groups/${uuid}`)
        .then(response => response.json())
        .then((data) => {
          commit('setGroup', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGroups ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/groups/`)
        .then(response => response.json())
        .then((data) => {
          commit('setGroups', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveGroup ({ dispatch }, data) {
    let url = `${process.env.VUE_APP_BACKEND_URL}/groups/`
    if (data.id) {
      url += data.id
    }

    return new Promise((resolve, reject) => {
      this._vm.$http.post(url, data)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
