<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="close" />
      <h3 class="gb-title">
        {{ $t('note.at', { date: $options.filters.cdayjs(note.created, $t('date.datetimeFormat')) }) }}
      </h3>
    </div>

    <div class="modal-body">
      <h4>{{ note.subject }}</h4><br>
      <p>{{ note.content }}</p>
    </div>

    <div class="modal-footer"></div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'

export default {
  mixins: [BaseModal],
  name: 'NoteModal',
  components: {
    CloseModalButton
  },
  computed: {
    note () {
      return this.$store.getters['roomDetails/note']
    }
  },
  methods: {
    close () {
      this.$store.commit('roomDetails/updateNode')
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  h3, h4, p {
    max-width: 600px;
    margin: auto;
  }

  h3 {
    text-align: center;
  }
}
</style>
