import dayjs from 'dayjs'
import i18n from '@/i18n'

const getInitial = (interval) => {
  const dataRunning = []
  const dataWaiting = []
  const dataLate = []
  const dataClosed = []
  if (interval === 'lastweek') {
    for (let i = 6; i > -1; i--) {
      const day = dayjs().subtract(i, 'days').format(i18n.t('date.dateFormat'))
      dataRunning.splice(dataRunning.length, 0, [day, 0])
      dataWaiting.splice(dataWaiting.length, 0, [day, 0])
      dataLate.splice(dataLate.length, 0, [day, 0])
      dataClosed.splice(dataClosed.length, 0, [day, 0])
    }
  } else if (interval === 'today' || interval === 'yesterday') {
    for (let i = 23; i > -1; i--) {
      const hour = dayjs().startOf('day').add(1, 'day').subtract(
        i, 'hour').format(i18n.t('date.hourFormat'))
      dataRunning.splice(dataRunning.length, 0, [hour, 0])
      dataWaiting.splice(dataWaiting.length, 0, [hour, 0])
      dataLate.splice(dataLate.length, 0, [hour, 0])
      dataClosed.splice(dataClosed.length, 0, [hour, 0])
    }
  } else if (interval === 'thismonth') {
    for (let i = 30; i > -1; i--) {
      const day = dayjs().subtract(i, 'days').format(i18n.t('date.dateFormat'))
      dataRunning.splice(dataRunning.length, 0, [day, 0])
      dataWaiting.splice(dataWaiting.length, 0, [day, 0])
      dataLate.splice(dataLate.length, 0, [day, 0])
      dataClosed.splice(dataClosed.length, 0, [day, 0])
    }
  } else if (interval === 'custom') {
    const start = state.dt.start
    const end = state.dt.end
    let day = start
    while (dayjs(day).valueOf() < dayjs(end).valueOf()) {
      day = dayjs(day).add(1, 'days')
      dataRunning.splice(dataRunning.length, 0, [day.format(i18n.t('date.dateFormat')), 0])
      dataWaiting.splice(dataWaiting.length, 0, [day.format(i18n.t('date.dateFormat')), 0])
      dataLate.splice(dataLate.length, 0, [day.format(i18n.t('date.dateFormat')), 0])
      dataClosed.splice(dataClosed.length, 0, [day.format(i18n.t('date.dateFormat')), 0])
    }
  }
  return [{
    name: 'running',
    data: dataRunning,
    stack: 'stack1'
  }, {
    name: 'waiting',
    data: dataWaiting,
    stack: 'stack1'
  }, {
    name: 'late',
    data: dataLate,
    stack: 'stack1'
  }, {
    name: 'closed',
    data: dataClosed,
    stack: 'stack1'
  }]
}

// initial state
const state = {
  csvStats: null,
  dt: {
    start: dayjs().startOf('month').toDate(),
    end: dayjs().endOf('month').toDate()
  },
  interval: 'lastweek',
  stats: null,
  details: 'rooms'
}

// getters
const getters = {
  getCsvStats: state => {
    return state.csvStats
  },
  getChartStats: state => (selectedSkills) => {
    let stats = []
    if (state.stats !== null) {
      stats = getInitial(state.interval)
      if (state.details === 'events') {
        state.stats.details.forEach(item => {
          stats.forEach(serie => {
            if (serie.name === item.state &&
                selectedSkills.indexOf(item.skill) > -1) {
              let itemDate
              if (state.interval === 'lastweek' || state.interval === 'thismonth' ||
                  state.interval === 'custom') {
                itemDate = dayjs.unix(item.created).format(i18n.t('date.dateFormat'))
              } else if (state.interval === 'today' || state.interval === 'yesterday') {
                itemDate = dayjs.unix(item.created).format(i18n.t('date.hourFormat'))
              }
              serie.data.forEach(elt => {
                if (elt[0] === itemDate) {
                  elt[1]++
                }
              })
            }
          })
        })
      } else {
        state.stats.details.forEach(item => {
          stats.forEach(serie => {
            if (serie.name === item.state &&
                selectedSkills.indexOf(item.skill) > -1) {
              let itemDate
              if (state.interval === 'lastweek' || state.interval === 'thismonth' ||
                  state.interval === 'custom') {
                itemDate = dayjs.unix(item.created).format(i18n.t('date.dateFormat'))
              } else if (state.interval === 'today' || state.interval === 'yesterday') {
                itemDate = dayjs.unix(item.created).format(i18n.t('date.hourFormat'))
              }
              serie.data.forEach(elt => {
                if (elt[0] === itemDate) {
                  elt[1]++
                }
              })
            }
          })
        })
      }
    }
    return stats
  },
  getDetails: state => {
    return state.details
  },
  getDt: state => {
    return state.dt
  },
  getHeaderStats: state => {
    let headerStats = {
      waiting_count: 0,
      average_waiting: 0,
      average_running: 0,
      agent_count: 0,
      handle_rate: 0,
      closed_count: 0
    }
    if (state.stats !== null) {
      headerStats = {
        waiting_count: state.stats.waiting_count,
        average_waiting: state.stats.average_waiting,
        average_running: state.stats.average_running,
        agent_count: state.stats.agent_count,
        handle_rate: state.stats.handle_rate,
        closed_count: state.stats.closed_count
      }
    }
    return headerStats
  },
  getInterval: state => {
    return state.interval
  }
}

// actions
const actions = {
  sendGetStats ({ commit, state }) {
    commit('resetStats')
    const data = {
      cmd: 'get_stats',
      interval: state.interval,
      details: state.details
    }
    if (state.interval === 'custom') {
      data.dt_gt = state.dt.start
      data.dt_lt = state.dt.end
    }
    this._vm.$socket.send(data)
  },
  sendGetStatsCsv ({ commit, state }) {
    commit('resetCsvStats')
    const data = {
      cmd: 'get_csv_stats',
      interval: state.interval,
      details: state.details
    }
    if (state.interval === 'custom') {
      data.dt_gt = state.dt.start
      data.dt_lt = state.dt.end
    }

    this._vm.$socket.send(data)
  },
  sendGetSupervisorStats ({ commit, state }, params) {
    commit('resetStats')
    const data = {
      cmd: 'get_supervisor_stats',
      interval: state.interval,
      details: state.details
    }
    if (state.interval === 'custom') {
      data.dt_gt = state.dt.start
      data.dt_lt = state.dt.end
    }
    if (params.filter_by === 'skills') {
      data.skill = params.uuid
      if (params.and_by !== undefined && params.and_by === 'agents') {
        data.agent = params.other_uuid
      }
    } else if (params.filter_by === 'agents') {
      data.agent = params.uuid
    }

    this._vm.$socket.send(data)
  },
  sendGetSupervisorStatsCsv ({ commit, state }, params) {
    commit('resetCsvStats')
    const data = {
      cmd: 'get_supervisor_csv_stats',
      interval: state.interval,
      details: state.details
    }
    if (state.interval === 'custom') {
      data.dt_gt = state.dt.start
      data.dt_lt = state.dt.end
    }
    if (params.filter_by === 'skills') {
      data.skill = params.uuid
      if (params.and_by !== undefined && params.and_by === 'agents') {
        data.agent = params.other_uuid
      }
    } else if (params.filter_by === 'agents') {
      data.agent = params.uuid
    }

    this._vm.$socket.send(data)
  }
}

// mutations
const mutations = {
  resetCsvStats: (state) => {
    state.csvStats = null
  },
  resetStats: (state) => {
    state.stats = null
  },
  updateCsvStats: (state, data) => {
    state.csvStats = data.data
  },
  updateDetails: (state, value) => {
    state.details = value
  },
  updateDt: (state, value) => {
    state.dt = value
  },
  updateInterval: (state, value) => {
    state.interval = value
  },
  updateStats: (state, data) => {
    state.stats = data.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
