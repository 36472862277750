import Vue from 'vue'
import i18n from '@/i18n'
import { Commit, Dispatch } from 'vuex'

import { ConnectorModel, Diagram, NodeModel } from '@syncfusion/ej2-vue-diagrams'

import uuidv4 from '@/components/uuidv4'
import {
  compare,
  compareTree,
  getNodeChilds,
  removeRecursive
} from '@/components/bb/utils'

import { escapeRegExp } from 'lodash'

import { DFAccount, DFIntent } from '@/types/dialogflow'
import {
  GbAgents,
  GbBot,
  GbChannel,
  GbCustomData,
  GbDiagramNode,
  GbDialogflow,
  GbDialogflowAccount,
  GbDialogflowIntent,
  GbNode,
  GbNodes,
  GbSrvBot,
  GbState,
  GbStateEdit,
  GbTree,
  GbTrees,
  GbUrl,
  GbZencalls
} from '@/types/botBuilder'
import { ThirdParty } from '@/types/thirdParties'
import { Account, Task } from '@/types/ravana'

declare global {
  interface Window { Sentry: any; }
}

const state: GbState = {
  actionsReady: false,
  bot: {} as GbBot,
  botId: null,
  changesSaved: false,
  channelId: null,
  clipboard: null,
  currentTreeData: null,
  diagramReady: false,
  dialogflow: null,
  error: null,
  gbagents: {} as GbAgents,
  nodeList: [],
  tree: {} as GbTree,
  treeNodes: [],
  treeSearch: '',
  treeUuid: null,
  skillId: null,
  stateEdit: {} as GbStateEdit,
  token: null,
  unsavedChanges: false,
  zencalls: {} as GbZencalls
}

const getters = {
  availableActions (_state: GbState, getters: any, _rootState_: any, rootGetters: any): Record<string, unknown> {
    let webActions = ['txt', 'image', 'url', 'choice', 'choice_fallback', 'gbagent', 'console', 'routing_load_balancer',
      'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana']
    if (rootGetters['global/settings'].saas === false) {
      webActions = [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback',
        'console', 'routing_load_balancer', 'totree', 'input', 'input_check',
        'input_error', 'dialogflow', 'ravana']
    }
    const actions: Record<string, Array<string>> = {
      web: webActions,
      apple: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana'],
      instagram_dm: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana'],
      whatsapp: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana', 'campaigns_contactlist'],
      facebook: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana', 'messenger_optin_request', 'messenger_optin_ko', 'messenger_optin_ok'],
      googlebm: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana', 'campaigns_contactlist'],
      rcs: [
        'txt', 'image', 'url', 'choice', 'choice_fallback', 'button', 'gbagent', 'callback', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana', 'campaigns_contactlist'],
      sms: [
        'txt', 'gbagent', 'totree', 'routing_load_balancer', 'input', 'input_check', 'input_error', 'campaigns_contactlist'
      ],
      telegram: [
        'txt', 'image', 'url', 'gbagent', 'console', 'routing_load_balancer',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana'],
      twitter_dm: [
        'txt', 'image', 'url', 'gbagent', 'routing_load_balancer', // 'console',
        'totree', 'input', 'input_check', 'input_error', 'dialogflow', 'ravana']
    }

    const settings = rootGetters['global/settings']
    const skills = rootGetters['skills/skills']
    const user = rootGetters['user/user']
    const userRole = user.user_role
    const userSkills = user.permissions.skills
    const skillList = skills.filter((s: Record<string, string>) => {
      return userSkills.some((us: Record<string, string>) => us.id === s.id)
    })

    if (!settings.saas && userRole === 'owner') {
      for (const channel in actions) {
        actions[channel].push('job')
      }
    }

    // Check AI perms (targeted service + admin role required)
    const checkAIPermission = (name: string) => {
      const hasService = skillList
        .some((skill: { services: Array<{ kind: string }> }) => {
          return skill.services.some((service: { kind: string }) => service.kind === name)
        })

      if (!hasService || settings.permissions[userRole] < settings.permissions.admin) {
        for (const channel in actions) {
          const idx = actions[channel].indexOf(name)
          if (idx >= 0) {
            actions[channel].splice(idx, 1)
          }
        }
      }
    }

    // Check dialogflow perms
    checkAIPermission('dialogflow')

    // Check ravana perms
    checkAIPermission('ravana')

    return actions
  },
  getActionsReady (state: GbState): boolean {
    return state.actionsReady
  },
  getBot (state: GbState): GbBot {
    return state.bot
  },
  getBotId (state: GbState): string | null {
    return state.botId
  },
  getChangesSaved (state: GbState): boolean {
    return state.changesSaved
  },
  getChannelId (state: GbState): string | null {
    return state.channelId
  },
  getClipboard (state: GbState): Record<string, any> | null {
    return state.clipboard
  },
  getClipboardInstance (state: GbState): () => (Record<string, any> | null) {
    return () => {
      const conversionNodesTable: Record<string, string> = {}
      const newNodes: GbNodes = {}
      const nodes = state.clipboard

      if (!nodes) {
        return null
      }

      for (const nodeUuid in nodes) {
        const newUuid = uuidv4()
        const srcNode: GbNode = nodes[nodeUuid]
        const newNode = JSON.parse(JSON.stringify(srcNode))

        conversionNodesTable[nodeUuid] = newUuid
        newNode.key = newUuid
        newNodes[newUuid] = newNode
      }

      for (const newNodeUuid in newNodes) {
        const newNode = newNodes[newNodeUuid]

        newNode.childs.forEach((child, index) => {
          newNode.childs.splice(index, 1, conversionNodesTable[child])
        })

        if (newNode.parent) {
          const parent: string | null = newNode.parent
          if (parent) {
            newNode.parent = conversionNodesTable[parent]
          }
        }
      }

      return newNodes
    }
  },
  getNewCustomTag: (state: GbState) => (newNode: GbNode): string | undefined => {
    let count = 0
    let newCustomTag = newNode.customTag

    if (newCustomTag) {
      // Test if the newCustomTag is already a copy with a number at the end
      const newParts = newCustomTag.split(' ')
      const newLastPart = newParts[newParts.length - 1]
      if (isNaN(parseInt(newLastPart, 10)) === false) {
        newCustomTag = newParts.splice(0, newParts.length - 1).join(' ')
      }

      const bot: GbBot = state.bot
      for (const nodeUuid in bot.nodes) {
        const node = bot.nodes[nodeUuid]
        if (nodeUuid !== newNode.key && node.customTag &&
            node.customTag.match(new RegExp('^' + escapeRegExp(newCustomTag) + '$|^' + escapeRegExp(newCustomTag) + ' '))) {
          count++
        }
      }
    }

    if (count > 0) {
      newCustomTag = `${newCustomTag} ${count + 1}`
    }

    return newCustomTag
  },
  getDiagramReady (state: GbState): boolean {
    return state.diagramReady
  },
  getDialogflow (state: GbState): Record<string, any> | null | undefined {
    return state.dialogflow
  },
  getError (state: GbState): string | Record<string, unknown> | null {
    return state.error
  },
  getNodeList (state: GbState): Array<string> {
    return state.nodeList
  },
  getSkillId (state: GbState): string | null {
    return state.skillId
  },
  getStateEdit (state: GbState): GbStateEdit {
    return state.stateEdit
  },
  getToken (state: GbState): string | null {
    return state.token
  },
  getTree (state: GbState): GbTree | null {
    return state.tree
  },
  getTreeNodes (state: GbState): Array<GbNode> {
    return state.treeNodes
  },
  getTreeSearch (state: GbState): string {
    return state.treeSearch
  },
  getTrees (state: GbState, getters: any): Array<any> {
    // We can't use Array<GbTree> because of the sort() method
    if (state.channelId && state.bot && state.bot.channels !== undefined) {
      const stateTrees = (state.bot.channels as any)[state.channelId].trees
      let trees = Object.values(stateTrees)
      if (state.treeSearch !== '') {
        trees = []
        for (const uuid in stateTrees) {
          const treeTitle = stateTrees[uuid].title.toLowerCase()
          if (treeTitle.indexOf(state.treeSearch.toLowerCase()) > -1) {
            trees.push(stateTrees[uuid])
          }
        }
      }

      const entryPointTreeUuids = getters.getTreesEntryPointUuids
      trees.sort(
        compareTree(entryPointTreeUuids)
      )
      return trees
    }
    return []
  },
  getTreesEntryPointUuids (state: GbState, _getters: any, _rootState_: any, rootGetters: any): Array<string> {
    const trees: Array<string> = [];
    (rootGetters['botgroups/list'] || []).forEach((bg: any) => {
      bg.integration_channels.forEach((ic: any) => {
        if (ic.customer_channel.bot_identifier === state.bot.identifier) {
          trees.push(ic.tree.identifier)
        }
      })
    })
    return trees
  },
  getTreeUuid (state: GbState): string | null {
    return state.treeUuid
  },
  getUnsavedChanges (state: GbState): boolean {
    return state.unsavedChanges
  },
  getGbAgentConfigs (state:GbState): GbAgents {
    return state.gbagents
  }
}

const mutations = {
  copyNewNodes (state: GbState, nodes: GbNodes): void {
    state.bot.nodes = Object.assign(
      {}, JSON.parse(JSON.stringify(state.bot.nodes)), nodes)
  },
  copyNewTree (state: GbState, args: Record<string, any>): void {
    args.newTree.node = args.newRootNodeUuid
    args.newTree.key = args.newTreeUuid
    if (state.channelId) {
      const bot: GbBot = JSON.parse(JSON.stringify(state.bot));
      (bot.channels as any)[state.channelId].trees[args.newTreeUuid] = args.newTree
      state.bot = bot
    }
  },
  removeSubChild (state: GbState, data: any): void {
    const diagram = data.diagram
    const node = data.node
    removeRecursive(diagram, node)
  },
  setActionsReady (state: GbState, value: boolean): void {
    state.actionsReady = value
  },
  setBot (state: GbState, data: GbBot): void {
    state.bot = data
    if ((state.bot.nodeMaxCounter === null || state.bot.nodeMaxCounter === undefined) &&
        'node_max_counter' in data) {
      state.bot.nodeMaxCounter = (data as GbSrvBot).node_max_counter
    }
  },
  setBotId (state: GbState, value: string | null): void {
    state.botId = value
  },
  setBotNodes (state: GbState, data: GbNodes): void {
    const allNodes = state.bot.nodes
    const rootNode = (state.tree as any).node
    const head = allNodes[rootNode]
    const nodes: GbNodes = {}

    getNodeChilds(head, allNodes, nodes, this)

    const newNodes = JSON.parse(JSON.stringify(state.bot.nodes))

    for (const nodeUuid in nodes) {
      delete newNodes[nodeUuid]
    }
    for (const nodeUuid in data) {
      newNodes[nodeUuid] = data[nodeUuid]
    }
    state.bot.nodes = newNodes
  },
  setChangesSaved (state: GbState, value: boolean): void {
    state.changesSaved = value
  },
  setChannelId (state: GbState, value: string | null): void {
    state.channelId = value
  },
  setClipboard (state: GbState, data: Record<string, any> | null): void {
    state.clipboard = data
  },
  setDialogflow (state: GbState, data: Record<string, any> | null): void {
    if (data !== null) {
      state.dialogflow = data
    }
  },
  setDiagramReady (state: GbState, value: boolean): void {
    state.diagramReady = value
  },
  setError (state: GbState, data: Record<string, unknown> | string | null): void {
    state.error = data
  },
  setGbAgentConfigs (state: GbState, data: GbSrvBot): void {
    state.gbagents = data.gbagents
  },
  setNodeList (state: GbState, data: Array<string>): void {
    state.nodeList = data
  },
  setSkillId (state: GbState, value: string | null): void {
    state.skillId = value
  },
  setStateEdit (state: GbState, data: GbStateEdit): void {
    state.stateEdit = data
  },
  setToken (state: GbState, value: string | null): void {
    state.token = value
  },
  setTree (state: GbState, data: GbTree | null): void {
    state.tree = data
  },
  setTreeNodes (state: GbState, data: GbNodes): void {
    Vue.set(state, 'treeNodes', data)
  },
  setTreeSearch (state: GbState, value: string): void {
    state.treeSearch = value
  },
  setTreeUuid (state: GbState, value: string): void {
    state.treeUuid = value
  },
  setTrees (state: GbState, data: GbTrees): void {
    if (state.channelId) {
      Vue.set(
        (state.bot.channels as any)[state.channelId],
        'trees',
        data.trees)
    }
  },
  setTriggers (state: GbState, data: GbTrees): void {
    if (state.channelId) {
      for (const kind in (state.bot.channels as any)[state.channelId].triggers) {
        for (const newKind in data.triggers) {
          if (newKind === kind && state.channelId &&
              (state.bot.channels as any)[state.channelId]) {
            Vue.set(
              (state.bot.channels as any)[state.channelId].triggers,
              kind,
              (data.triggers as any)[newKind])
          }
        }
      }
    }
  },
  setUnsavedChanges (state: GbState, value: boolean): void {
    state.unsavedChanges = value
    if (value === true) {
      state.changesSaved = false
    }
  },
  setZencallConfigs (state: GbState, data: GbSrvBot): void {
    state.zencalls = data.zencalls
  },
  updateTitle (state: GbState, data: Record<string, any>): void {
    const bot: GbBot = state.bot
    const newTree = (bot.channels as any)[data.channel].trees[data.newTreeUuid]
    let newTitle = newTree.title
    // Test if the newTree is already a copy with a number at the end
    const newParts = newTitle.split(' ')
    const newLastPart = newParts[newParts.length - 1]
    if (isNaN(parseInt(newLastPart, 10)) === false) {
      newTitle = newParts.splice(0, newParts.length - 1).join(' ')
    }
    let count = 0
    for (const treeUuid in (bot.channels as any)[data.channel].trees) {
      const tree = (bot.channels as any)[data.channel].trees[treeUuid]
      if (tree.key !== newTree.key &&
          tree.title.match(new RegExp('^' + escapeRegExp(newTitle) + '$|^' + escapeRegExp(newTitle) + ' '))) {
        count++
      }
    }
    if (count > 0) {
      newTitle = `${newTitle} ${count + 1}`
    }
    newTree.title = newTitle
  }
}

const actions = {
  buildDiagramFromNodes ({ commit, dispatch, getters, rootGetters }: { commit: Commit, dispatch: Dispatch, getters: any, rootGetters: any }): void {
    // clear the node list before trying to rebuild the diagram
    commit('setNodeList', [])
    const availableActions = getters.availableActions
    const channelId = getters.getChannelId

    let bot = getters.getBot
    dispatch('unlinkedNodes', bot).then(() => {
      dispatch('buildNodeCounter').then(() => {
        commit('setNodeList', [])
        bot = getters.getBot
        const allNodes = bot.nodes

        const tree = getters.getTree
        if (tree) {
          const rootNode = tree.node

          const diagramNodes = []
          if (rootNode) {
            // rootNode may not exist in case of a newly created tree
            const head = allNodes[rootNode]
            const nodes = {}
            getNodeChilds(head, allNodes, nodes, this)
            for (const nodeUuid in nodes) {
              const node: GbNode = (nodes as any)[nodeUuid]
              const parentUuid = `UUID${node.parent}`
              let name = node.txt || ''
              if (node.action === 'button' && node.button) {
                name = node.button.title
              } else if (node.action === 'dialogflow' && node.dialogflow) {
                const account = rootGetters['dialogflow/intents'](node.dialogflow.account)
                name = account ? account.agent_name : ''
              } else if (node.action === 'dialogflow_intent' && node.dialogflow_intent) {
                name = node.dialogflow_intent.intent_label
                if (node && node.parent) {
                  const parentNode = allNodes[node.parent]
                  if (parentNode.action === 'dialogflow' && parentNode.dialogflow &&
                    parentNode.dialogflow.account) {
                    if (rootGetters['dialogflow/intents'](parentNode.dialogflow.account)) {
                      const intents = rootGetters['dialogflow/intents'](parentNode.dialogflow.account).intents
                      const nodeIntent = node.dialogflow_intent?.intent
                      if (intents && nodeIntent) {
                        const intent = intents.find((intent: DFIntent) => intent.id === nodeIntent)
                        if (intent) {
                          name = intent.displayName
                        } else {
                          name = '--' + name + '--'
                        }
                      }
                    }
                  }
                }
              } else if (node.action === 'choice' && node.choice) {
                name = node.choice.title
              } else if (node.action === 'choice_fallback') {
                name = i18n.t('diagram.actions.choice_fallback')
              } else if (node.action === 'console') {
                name = i18n.t('diagram.actions.console')
              } else if (node.action === 'gbagent') {
                name = i18n.t('diagram.actions.chat')
              } else if (node.action === 'input') {
                name = i18n.t('diagram.actions.user-input')
              } else if (node.action === 'input_check') {
                name = i18n.t('diagram.actions.user-input-check')
                if (node.input_check && node.input_check.words.length > 0) {
                  name = node.input_check.words.join(', ')
                }
              } else if (node.action === 'input_error') {
                name = i18n.t('diagram.actions.user-input-error')
              } else if (node.action === 'image') {
                name = i18n.t('diagram.actions.image')
              } else if (node.action === 'messenger_optin_ko') {
                name = i18n.t('diagram.actions.messenger_optin_ko')
              } else if (node.action === 'messenger_optin_ok') {
                name = i18n.t('diagram.actions.messenger_optin_ok')
              } else if (['campaigns_contactlist', 'messenger_optin_request'].includes(node.action) &&
                ((node.messenger_optin_request || node.campaigns_contactlist)?.contact_list)) {
                const cl = rootGetters['campaigns/contacts/getContactsListById']((node.messenger_optin_request || node.campaigns_contactlist)?.contact_list)
                if (cl) {
                  name = cl.name
                } else {
                  name = i18n.t('diagram.actions.missing')
                }
              } else if (node.action === 'ravana') {
                name = i18n.t('diagram.actions.ravana')
              } else if (node.action === 'survey') {
                name = i18n.t('diagram.actions.survey')
              } else if (node.action === 'totree' && node.totree) {
                const destTree = getters.getBot.channels[
                  getters.getChannelId].trees[node.totree]
                if (destTree) {
                  name = destTree.title
                } else {
                  name = i18n.t('diagram.actions.missing')
                }
              } else if (node.action === 'url' && node.url) {
                name = node.url.title
              } else if (node.action === 'callback') {
                name = i18n.t('diagram.actions.callback')
              }

              const diagramUuid = `UUID${nodeUuid}`
              const action = node.action
              let disable = false
              if (channelId !== null) {
                const linkedNodes: Record<string, string> = {
                  dialogflow_end: 'dialogflow',
                  dialogflow_fallback: 'dialogflow',
                  dialogflow_intent: 'dialogflow',
                  passthrough: 'routing_load_balancer',
                  ravana_output: 'ravana'
                }
                const tmp = linkedNodes[action] || action
                if (availableActions[channelId].indexOf(tmp) === -1) {
                  disable = true
                }
              }
              const customData: GbCustomData = {
                title: name,
                tag: node.tag,
                customTag: node.customTag,
                action: action,
                uuid: diagramUuid,
                disable: disable
              }
              const diagramNode: GbDiagramNode = {
                uuid: diagramUuid,
                customData: customData
              }
              if (parentUuid !== 'UUIDnull') {
                diagramNode.parentUuid = parentUuid
              }
              if (node.action === 'image') {
                diagramNode.customData.image = node.image
              }
              if (node.action === 'url') {
                diagramNode.customData.url = node.url
                diagramNode.customData.image = node.image
              }
              if (node.action === 'button') {
                diagramNode.customData.button = node.button
              }
              if (node.action === 'choice') {
                diagramNode.customData.choice = node.choice
              }
              if (node.action === 'console') {
                diagramNode.customData.console = node.console
              }
              if (node.action === 'dialogflow') {
                diagramNode.customData.dialogflow = node.dialogflow
              }
              if (node.action === 'dialogflow_intent') {
                diagramNode.customData.dialogflow_intent = node.dialogflow_intent
              }
              if (node.action === 'dialogflow_end') {
                diagramNode.customData.dialogflow_end = node.dialogflow_end
              }
              if (node.action === 'dialogflow_fallback') {
                diagramNode.customData.dialogflow_fallback = node.dialogflow_fallback
              }
              if (node.action === 'gbagent') {
                diagramNode.customData.gbagent = node.gbagent
              }
              if (node.action === 'input_check') {
                diagramNode.customData.input_check = node.input_check
              }
              if (node.action === 'input_error') {
                let txt = ''
                if (node && node.input_error) {
                  txt = node.input_error.txt
                }
                diagramNode.customData.input_error = {
                  txt: txt
                }
              }
              if (node.action === 'job') {
                diagramNode.customData.job = node.job
              }
              if (node.action === 'messenger_optin_request') {
                diagramNode.customData.messenger_optin_request = node.messenger_optin_request
              }
              if (node.action === 'campaigns_contactlist') {
                diagramNode.customData.campaigns_contactlist = node.campaigns_contactlist
              }
              if (node.action === 'routing_load_balancer') {
                diagramNode.customData.routing_load_balancer = node.routing_load_balancer
                diagramNode.customData.title = diagramNode.customData.routing_load_balancer?.weights.join('% - ') + '%'
              }
              if (node.action === 'passthrough') {
                const parentNode = allNodes[node.parent as string]
                const childIndex = parentNode.childs.indexOf(node.key)
                const weights = parentNode.routing_load_balancer.weights || []
                diagramNode.customData.title = weights[childIndex] + '%'
              }
              if (node.action === 'ravana') {
                diagramNode.customData.ravana = node.ravana
              }
              if (node.action === 'ravana_output') {
                const parentNode = allNodes[node.parent as string]
                const account = (rootGetters['ravana/accounts'] || [])
                  .find((a: Account) => a.id === parentNode.ravana?.account)
                const taskIdentifier = node.ravana_output?.task
                const task = account?.tasks
                  ?.find((task: Task) => (task.id === taskIdentifier))

                if ((taskIdentifier) === 'default') {
                  diagramNode.customData.title = i18n.t('diagram.actions.ravana_output.kind.' + taskIdentifier)
                } else if (account && task) {
                  diagramNode.customData.title = `${task.label} [${i18n.t(`ai.ravana.task.${task.kind}.title`).toString()}]`
                } else {
                  diagramNode.customData.title = i18n.t('diagram.actions.ravana_output.kind.unknown')
                }

                diagramNode.customData.ravana_output = node.ravana_output
              }
              if (node.action === 'totree') {
                diagramNode.customData.totree = node.totree
              }
              if (node.action === 'callback' && node.callback) {
                diagramNode.customData.callback = node.callback.thirdparties_id
                const callbacks = rootGetters['thirdParties/listByKind']('callback')
                if (callbacks) {
                  const callback = callbacks.find((c: ThirdParty) => {
                    return c.id === node.callback?.thirdparties_id
                  })
                  if (callback) {
                    diagramNode.customData.title = callback.label
                  }
                }
              }
              diagramNodes.push(diagramNode)
            }
          }
          commit('setTreeNodes', diagramNodes)

          window.setTimeout(() => {
            commit('setDiagramReady', true)
          }, 50)
        }
      })
    })
  },
  buildNodesFromDiagram ({ commit }: { commit: Commit }, diagram: Diagram): void {
    // This code builds a serialization of the diagram for vuex.
    // the "order" of childnodes is based on the offsetX
    const nodes: GbNodes = {}
    diagram.nodes.forEach((diagramNode: NodeModel) => {
      let nUuid = ''
      if (diagramNode && diagramNode.data) {
        const customData: GbCustomData = (diagramNode.data as any).customData
        if (['campaign_template', 'entrypoint'].includes(customData.action)) {
          return
        }
        if ((diagramNode.data as any).uuid !== undefined) {
          nUuid = (diagramNode.data as any).uuid
          nUuid = nUuid.substring(4)
        } else if (customData && customData.uuid) {
          nUuid = customData.uuid.substring(4)
        }
        const node: GbNode = {
          key: nUuid,
          action: customData.action,
          parent: null,
          tag: customData.tag,
          customTag: customData.customTag,
          childnodes: [],
          childs: []
        }
        if (customData.action === 'image') {
          node.image = customData.image
        }
        if (customData.action === 'url' && customData.url) {
          let customTitle = ''
          if (customData.title) {
            customTitle = customData.title
          }
          const url: GbUrl = {
            title: customTitle,
            image: customData.url.image,
            url: customData.url.url
          }
          node.url = url
        }
        if (customData.action === 'button') {
          node.button = customData.button
        }
        if (customData.action === 'choice') {
          node.choice = customData.choice
        }
        if (customData.action === 'console') {
          node.console = customData.console
        }
        if (customData.action === 'dialogflow' && customData.dialogflow) {
          node.dialogflow = customData.dialogflow
        }
        if (customData.action === 'dialogflow_intent' && customData.dialogflow_intent) {
          const dialogflowIntent: GbDialogflowIntent = {
            intent: customData.dialogflow_intent.intent,
            intent_label: customData.dialogflow_intent.intent_label || ''
          }
          node.dialogflow_intent = dialogflowIntent
        }
        if (customData.action === 'dialogflow_end' && customData.dialogflow_end) {
          node.dialogflow_end = customData.dialogflow_end
        }
        if (customData.action === 'dialogflow_fallback' && customData.dialogflow_fallback) {
          node.dialogflow_fallback = customData.dialogflow_fallback
        }
        if (customData.action === 'gbagent') {
          node.gbagent = customData.gbagent
        }
        if (customData.action === 'input_check') {
          node.input_check = customData.input_check
        }
        if (customData.action === 'input_error') {
          let txt = ''
          if (customData.input_error) {
            txt = customData.input_error.txt
          }
          node.input_error = {
            txt: txt
          }
        }
        if (customData.action === 'job') {
          node.job = customData.job
        }
        if (customData.action === 'messenger_optin_request') {
          node.messenger_optin_request = customData.messenger_optin_request
        }
        if (customData.action === 'campaigns_contactlist') {
          node.campaigns_contactlist = customData.campaigns_contactlist
        }
        if (customData.action === 'ravana') {
          node.ravana = customData.ravana
        }
        if (customData.action === 'ravana_output') {
          node.ravana_output = customData.ravana_output
        }
        if (customData.action === 'routing_load_balancer') {
          node.routing_load_balancer = customData.routing_load_balancer
        }
        if (customData.action === 'totree') {
          node.totree = customData.totree
        }
        if (customData.action === 'txt') {
          node.txt = customData.title
        }
        if (customData.action === 'callback' && customData.callback) {
          node.callback = {
            thirdparties_id: customData.callback,
            messages: {
              no_timeslots_avail: 'Je suis désolé, notre service client est actuellement fermé',
              ask_input_number: 'Merci de saisir votre numéro de rappel',
              wrong_number_input: 'Le numéro de téléphone renseigné semble erroné',
              timeslot_label_asap: 'Dès que possible',
              ask_timeslot_choice: 'Veuillez choisir un créneau de rappel valide',
              wrong_timeslot_choice: 'Le créneau ne peut pas être trouvé',
              callback_req_created: 'C\'est noté ! Votre demande de rappel a bien été prise en compte',
              callback_req_error: 'Une erreur est survenue'
            }
          }
        }

        const inEdges = (diagramNode as any).inEdges
        inEdges.forEach((edge: any) => {
          diagram.connectors.forEach((connector: ConnectorModel) => {
            if (connector.id === edge) {
              diagram.nodes.forEach((pnode: NodeModel) => {
                if (connector.sourceID === pnode.id) {
                  if (pnode && pnode.data && !['campaign_template', 'entrypoint'].includes((pnode.data as any).customData.action)) {
                    node.parent = (pnode.data as any).customData.uuid.substring(4)
                  }
                }
              })
            }
          })
        })
        const outEdges = (diagramNode as any).outEdges
        outEdges.forEach((edge: any) => {
          diagram.connectors.forEach((connector: ConnectorModel) => {
            if (connector.id === edge) {
              diagram.nodes.forEach((pnode: NodeModel) => {
                if (pnode && pnode.data) {
                  const pCustomData: GbCustomData = (pnode.data as any).customData
                  if (connector.targetID === pnode.id) {
                    let pUuid = (pnode.data as any).uuid
                    if (pUuid !== undefined) {
                      pUuid = pUuid.substring(4)
                    } else {
                      pUuid = uuidv4()
                    }
                    if (node && node.childnodes) {
                      node.childnodes.push({
                        key: pUuid,
                        left: pnode.offsetX,
                        action: pCustomData.action,
                        parent: null,
                        childs: []
                      })
                    }
                  }
                }
              })
            }
          })
        })
        nodes[node.key] = node
      }
    })
    for (const nUuid in nodes) {
      const node: GbNode = nodes[nUuid]
      if (node.childnodes) {
        node.childnodes.sort(compare)
        const children: Array<string> = []
        node.childnodes.forEach((child: GbNode) => {
          if (child.key) {
            children.push(child.key)
          }
        })
        delete node.childnodes
        node.childs = children
      }
    }
    commit('setNodeList', [])
    commit('setBotNodes', nodes)
    commit('setUnsavedChanges', true)
  },
  buildNodeCounter ({ commit, getters }: { commit: Commit, getters: any }): Promise<any> {
    return new Promise((resolve) => {
      commit('setNodeList', [])
      let nodeMaxCounter = 0
      if (getters.getChannelId) {
        const bot = JSON.parse(JSON.stringify(getters.getBot))
        if (!('nodeMaxCounter' in bot) || bot.nodeMaxCounter === null) {
          const allNodes = JSON.parse(JSON.stringify(bot.nodes))
          const linkedNodes: Record<string, GbNode> = {}
          for (const channelName in bot.channels) {
            const channel: GbChannel = (bot.channels as any)[channelName]
            for (const treeUuid in channel.trees) {
              const rootNode = channel.trees[treeUuid].node
              const head = allNodes[rootNode]
              getNodeChilds(head, allNodes, linkedNodes, this)
            }
            for (const nodeUuid in linkedNodes) {
              const node = linkedNodes[nodeUuid]
              nodeMaxCounter++
              node.tag = `${node.action}-${nodeMaxCounter}`
              linkedNodes[nodeUuid] = node
            }
          }
          bot.nodes = linkedNodes
          bot.nodeMaxCounter = nodeMaxCounter
          commit('setBot', bot)
        }
      }
      return resolve(true)
    })
  },
  copyNodes ({ commit, getters }: { commit: Commit, getters: any}, args: Record<string, any>): Promise<any> {
    return new Promise((resolve) => {
      commit('setNodeList', [])
      commit('setClipboard', null)

      const allNodes = getters.getBot.nodes
      const head = allNodes[args.nodeUuid]
      let nodes: GbNodes = {}

      getNodeChilds(head, allNodes, nodes, this)

      // Resetting the parent node of copy nodes
      nodes = JSON.parse(JSON.stringify(nodes))
      for (const uuid in nodes) {
        const node = nodes[uuid]
        if (node.key === args.nodeUuid) {
          node.parent = null
        }
      }

      commit('setClipboard', nodes)
      return resolve(true)
    })
  },
  copyTree ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, args: Record<string, any>): Promise<GbTree> {
    return new Promise((resolve) => {
      // Cloning tree
      const trees = getters.getBot.channels[getters.getChannelId].trees
      const srcTree = trees[args.treeKey]
      const newTree = JSON.parse(JSON.stringify(srcTree))

      newTree.key = uuidv4()
      newTree.kind = ''

      // Copying then pasting nodes
      dispatch('copyNodes', { nodeUuid: srcTree.node }).then(() => {
        dispatch('pasteNodes').then((nodes: Array<GbNode>) => {
          const rootNode = Object.values(nodes).find((node: GbNode) => node.parent === null)
          if (rootNode) {
            // Updating new tree root node
            newTree.node = rootNode.key

            // Clearing clipboard
            commit('setClipboard', null)

            // Updating bot
            commit('setTrees', Object.assign({
              trees: Object.assign({}, trees, { [newTree.key]: newTree })
            }))

            // Updating new tree name (copy label)
            commit('updateTitle', {
              copyLabel: args.copyLabel,
              channel: getters.getChannelId,
              newTreeUuid: newTree.key
            })

            resolve(newTree)
          }
        })
      })
    })
  },
  deleteTree ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, data: Record<string, unknown>): Promise<any> {
    return new Promise((resolve) => {
      const bot = JSON.parse(JSON.stringify(getters.getBot))

      const linkedToTreeNodes: Array<string> = []
      for (const nodeUuid in bot.nodes) {
        const node = bot.nodes[nodeUuid]
        if (node.action === 'totree' && node.totree === data.treeUuid) {
          linkedToTreeNodes.push(nodeUuid)
        }
      }
      if (linkedToTreeNodes.length > 0) {
        const allNodes = bot.nodes
        linkedToTreeNodes.forEach((uuid: string) => {
          delete allNodes[uuid]
        })
        bot.nodes = allNodes
        commit('setBot', bot)
      }

      if (getters.getChannelId) {
        const trees = (bot.channels as any)[getters.getChannelId].trees
        for (const index in trees) {
          const tree = trees[index]
          if (tree.key === data.treeUuid) {
            delete (trees as any)[index]
          }
        }
        commit('setTrees', { trees: trees })

        return resolve(true)
      }
      dispatch('unlinkedNodes', bot).then(() => {
        return resolve(true)
      })
    })
  },
  fetchBot ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BB_HTTP}/bot/${state.skillId}/${state.botId}/`
      Vue.http.get(url, {
        headers: {
          Authorization: `Bearer ${state.token}`
        }
      }).then((response: Response) => {
        // Update the bot
        const srvBot = response.body
        commit('setBot', srvBot)
        commit('setDialogflow', (srvBot as any).dialogflow)
        commit('setGbAgentConfigs', srvBot)
        commit('setZencallConfigs', srvBot)
        // Update the current TreeUuid
        const currentTreeUuid = getters.getTreeUuid
        if (currentTreeUuid === null) {
          let found = false
          getters.getTrees.forEach((tree: GbTree) => {
            if (tree.kind === 'init') {
              found = true
              commit('setTree', tree)
            }
          })
          if (found === false) {
            commit('setTree', getters.getTrees[0])
          }
        } else {
          commit('setTree', getters.getTrees[currentTreeUuid])
        }
        commit('setUnsavedChanges', false)
        const availableActions = getters.availableActions
        if (availableActions) {
          const channelId = getters.getChannelId
          const hasDialogFlow = availableActions[channelId].indexOf('dialogflow') > -1
          if (hasDialogFlow === true) {
            const bot = getters.getBot
            const dialogflowAccounts = []
            for (const uuid in bot.nodes) {
              const node = bot.nodes[uuid]
              if (node.action === 'dialogflow') {
                dialogflowAccounts.push(node.dialogflow.account)
              }
            }
            if (dialogflowAccounts.length > 0) {
              const url = `${process.env.VUE_APP_DF_URL}/accounts/intents`
              Vue.http.get(url, { accounts: dialogflowAccounts }, {
                headers: {
                  Authorization: `Bearer ${state.token}`
                }
              }).then((response: Response) => {
                commit('dialogflow/setIntents', response.body, { root: true })
                return resolve(true)
              })
            } else {
              return resolve(true)
            }
          } else {
            return resolve(true)
          }
        } else {
          return resolve(true)
        }
        // eslint-disable-next-line
      }).catch((err: Error) => {
        commit('global/handleHttpError', 'errors.bad_authentication', { root: true })
        window.console.error(err)
        return reject(err)
      })
    })
  },
  pasteNodes ({ commit, getters, rootGetters }: { commit: Commit, getters: any, rootGetters: any }, args: Record<string, any>): Promise<Array<GbNode>> {
    return new Promise((resolve) => {
      let totreeNodeHasBeenReset = false

      // Creating a copy of clipboard nodes set (manage node, chidlren and parent uuids)
      const newNodes = getters.getClipboardInstance()

      // Checking if it is a different bot
      const clipboard = getters.getClipboard
      const rootNewNode = (Object.values(clipboard) as Array<GbNode>)
        .find((node: GbNode) => node.parent === null)
      const differentBot = getters.getBot.nodes[(rootNewNode as GbNode).key] === undefined

      // Pasting clipboard nodes set (manage parent/children and tags max counter)
      let allNodes = JSON.parse(JSON.stringify(getters.getBot.nodes))
      let nodeMaxCounter = getters.getBot.nodeMaxCounter

      for (const newNodeUuid in newNodes) {
        const newNode = newNodes[newNodeUuid]
        const parent: string | null = newNode.parent

        // Attaching node set to selected parent node
        if (!parent && args && args.nodeUuid) {
          newNode.parent = args.nodeUuid
          allNodes[args.nodeUuid].childs.push(newNodeUuid)
        }

        // Updating tag
        newNode.tag = `${newNode.action}-${++nodeMaxCounter}`

        // Updating custom tag
        if (newNode.customTag) {
          const newCustomTag = getters.getNewCustomTag(newNode)
          newNode.customTag = newCustomTag
        }

        // Resetting totree action node if different bot
        if (differentBot === true && newNode.action === 'totree') {
          if (rootGetters['global/settings'].saas === true) {
            const trees = getters.getTrees
            newNode.totree = trees[0].key
          } else {
            newNode.totree = undefined
            totreeNodeHasBeenReset = true
          }
        }

        // Adding new node
        allNodes[newNodeUuid] = newNode

        // We need to commit on each iteration to have the proper number of
        // tag with the same name
        commit('copyNewNodes', allNodes)
        allNodes = JSON.parse(JSON.stringify(getters.getBot.nodes))
      }

      const bot = JSON.parse(JSON.stringify(getters.getBot))
      bot.nodeMaxCounter = nodeMaxCounter

      commit('setBot', bot)

      // Showing modal to warn user that one or more totree node has been reseted
      if (totreeNodeHasBeenReset === true) {
        commit(
          'global/updateError',
          i18n.t('diagram.copy-paste-nodes.paste-totree-warn'),
          { root: true }
        )
      }

      return resolve(newNodes)
    })
  },
  sendBot ({ commit, getters }: { commit: Commit, getters: any }): Promise<void> {
    const _getDialogflow = (): GbDialogflow => {
      const dialogflowNodes: GbDialogflow = {}
      commit('setNodeList', [])
      const bot = getters.getBot
      const allNodes = bot.nodes
      const trees = getters.getTrees
      for (const treeUuid in trees) {
        const tree = trees[treeUuid]
        const rootNode = tree.node

        const treeNodes = []
        if (rootNode) {
          // rootNode may not exist in case of a newly created tree
          const head = allNodes[rootNode]
          const nodes = {}
          getNodeChilds(head, allNodes, nodes, this)
          for (const nodeUuid in nodes) {
            const node: GbNode = (nodes as any)[nodeUuid]
            if (node.action === 'dialogflow' && node.dialogflow) {
              dialogflowNodes[node.key] = {
                tree_identifier: tree.key,
                tree_label: tree.title,
                account: node.dialogflow.account
              }
            }
          }
        }
      }
      return dialogflowNodes
    }

    const url = `${process.env.VUE_APP_BB_HTTP}/bot/${state.skillId}/${state.botId}/`
    const bot = JSON.parse(JSON.stringify(getters.getBot))
    bot.node_max_counter = bot.nodeMaxCounter
    bot.dialogflow = _getDialogflow()
    delete bot.nodeMaxCounter
    return Vue.http.post(url, bot, {
      headers: {
        Authorization: `Bearer ${state.token}`
      }
    }).then((response: Response) => {
      if (response.status === 200) {
        commit('setUnsavedChanges', false)
        commit('setChangesSaved', true)
      }
    }).catch((err: Response) => {
      console.log('ERR BACK', err)
      if (err && err.body && err.body.error) {
        commit('setError', {
          title: 'error.Oups',
          body: err.body.error
        })
        if (window.Sentry) {
          window.Sentry.captureException(new Error(err.body.error))
        }
      } else {
        commit('setError', 'error.Oups')
      }
    })
  },
  unlinkedNodes ({ commit, getters }: { commit: Commit, getters: any }, data: GbSrvBot): Promise<any> {
    return new Promise((resolve) => {
      if (getters.getChannelId) {
        const allNodes = JSON.parse(JSON.stringify(data.nodes))
        const linkedNodes = {}
        for (const channelName in data.channels) {
          const channel: GbChannel = (data.channels as any)[channelName]
          for (const treeUuid in channel.trees) {
            const rootNode = channel.trees[treeUuid].node

            const head = allNodes[rootNode]
            getNodeChilds(head, allNodes, linkedNodes, this)
          }
        }
        const allNodesUuids = Object.keys(allNodes)
        const linkedNodesUuids = Object.keys(linkedNodes)
        const unlinkedNodesUuids = allNodesUuids.filter(uuid => !linkedNodesUuids.includes(uuid))
        unlinkedNodesUuids.forEach((uuid: string) => {
          delete allNodes[uuid]
        })
        const bot = JSON.parse(JSON.stringify(getters.getBot))
        bot.nodes = allNodes
        commit('setBot', bot)
      }
      resolve(true)
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
