import { Commit, Dispatch } from 'vuex'
import { Skills, State, Survey } from '@/types/surveys'

const state: State = {
  surveys: null
}

const getters = {
  surveys (): Array<Survey> | null {
    return state.surveys
  }
}

const mutations = {
  setSurveys (state: State, surveys: Array<Survey>): void {
    state.surveys = surveys
  }
}

const actions = {
  deleteSurvey ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_SURVEYS_URL}/surveys/${id}`)
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkills ({ dispatch }: { dispatch: Dispatch }): Promise<Skills> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SURVEYS_URL}/skills`)
        .then((response: Response) => response.json())
        .then((data: Skills) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkillSurvey ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<Survey> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SURVEYS_URL}/skills/${id}`)
        .then((response: Response) => response.json())
        .then((data: Survey) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSurvey ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<Survey> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SURVEYS_URL}/surveys/${id}`)
        .then((response: Response) => response.json())
        .then((data: Survey) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSurveys ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<Survey>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_SURVEYS_URL}/surveys`)
        .then((response: Response) => response.json())
        .then((data: Array<Survey>) => {
          commit('setSurveys', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveSurvey  ({ dispatch }: { dispatch: Dispatch }, data: Survey): Promise<Survey> {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_SURVEYS_URL}/surveys`

      if (data.id) {
        url += `/${data.id}`
      }

      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: Survey) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
