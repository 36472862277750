<template>
  <div class="calendar-input">
    <div>
      <label for="timezone" class="gb-label">
        {{ $t('channels.timezone.title') }}
      </label>
      <div class="gb-select">
        <select name="timezone" id="timezone" class="gb-field" :value="value.timezone" @input="emitTimezone">
          <option value="">
            {{ $t('channels.timezone.placeholder') }}
          </option>
          <option v-for="timezone in timezones" :key="timezone.value" :value="timezone.value">
            {{ timezone.text }}
          </option>
        </select>
        <span class="chevron"><icon name="chevron_right"/></span>
      </div>

      <div>
        <label for="calendar-all" class="gb-toggle">
          <div class="toggle">
            <input class="toggle-state" type="checkbox" name="calendar-all" id="calendar-all"
              :checked="!value.customized"
              @input="emitCustomized"/>
            <div class="toggle-inner">
              <div class="indicator"></div>
            </div>
            <div class="active-bg"></div>
          </div>
          <div class="label-text">{{ $t('calendar.all') }}</div>
        </label>
      </div>
    </div>

    <div class="calendar" :class="{ 'not-used': value.customized === false }">
      <calendar-row v-for="(config, day) in calendar" :key="day"
        :value="config"
        @userAction="triggerCustomized"
        @input="emitCalendar(day, $event)"
        :day="day"/>
    </div>
  </div>
</template>

<script>
import CalendarRow from '@/components/CalendarRow.vue'
import countriesAndTz from 'countries-and-timezones'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  name: 'InputCalendar',
  components: { CalendarRow, Icon },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Object
    }
  },
  computed: {
    calendar () {
      const calendar = {
        Monday: {},
        Tuesday: {},
        Wednesday: {},
        Thursday: {},
        Friday: {},
        Saturday: {},
        Sunday: {}
      }

      for (const name in calendar) {
        const day = calendar[name]
        const d = this.value && this.value.days && this.value.days[name]

        day.datetimes = []
        day.closed = d === undefined

        if (d && d.length) {
          d.forEach(ts => {
            const start = ts[0].split(':')
            const end = ts[1].split(':')
            day.datetimes.push({
              start_dt: {
                HH: start[0],
                mm: start[1]
              },
              end_dt: {
                HH: end[0],
                mm: end[1]
              }
            })
          })
        } else {
          day.datetimes.push({
            start_dt: {
              HH: '',
              mm: ''
            },
            end_dt: {
              HH: '',
              mm: ''
            }
          })
        }
      }

      return calendar
    },
    timezones () {
      const timezones = []
      const data = countriesAndTz.getAllTimezones()
      for (const i in data) {
        const tz = data[i]
        timezones.push({
          text: tz.name,
          value: tz.name
        })
      }
      return timezones.sort((a, b) => {
        return a.text > b.text ? 1 : -1
      })
    }
  },
  methods: {
    emitCustomized (e) {
      this.emitInput('customized', !e.target.checked)
    },
    emitInput (key, val) {
      this.$emit('input', Object.assign({}, this.value, {
        [key]: val
      }))
    },
    emitCalendar (day, val) {
      const days = this.value.days
        ? JSON.parse(JSON.stringify(this.value.days))
        : {}

      delete days[day]

      if (val.closed !== true) {
        if (!days[day]) {
          days[day] = []
        }

        val.datetimes.forEach(ts => {
          days[day].push([ts.start_dt.HH + ':' + ts.start_dt.mm, ts.end_dt.HH + ':' + ts.end_dt.mm])
        })
      }

      this.emitInput('days', days)
    },
    emitTimezone (e) {
      this.emitInput('timezone', e.target.value)
    },
    triggerCustomized () {
      this.$nextTick(() => {
        if (this.value.customized === false) {
          this.emitInput('customized', true)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.gb-content-col {

  &,
  & > * {
    width: 100%;
  }
}

.gb-label {
  font-size: 12px;
  color: $grey-dark;
}

.gb-toggle {
  justify-content: space-between;
  margin: 20px 0 30px 0;

  .label-text {
    order: -1;
    margin-left: 0;
    margin-right: 1rem;
    font-weight: 500;
    color: $primary;
  }
}

.calendar {
  font-size: 12px;

  &.not-used {
    opacity: .2;
  }

  .day {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .closed {
    font-weight: 500;
    color: $primary;
  }
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all .15s;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
