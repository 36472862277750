const state = {
  data: null
}

const getters = {
  data () {
    return state.data
  }
}

const mutations = {
  updateData (state, data) {
    state.data = data
  }
}

const actions = {
  executeCallback ({ commit, dispatch }, data) {
    commit('updateData', data)
    dispatch('GBA/' + data.action, data, { root: true })
  },
  sendAction (_, data) {
    this._vm.$socket.send({
      cmd: 'crm_action',
      data
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
