<template>
  <div class="checklist" ref="checklistWrapper">
    <div v-if="field.multiselect" class="checklist__select gb-select" :title="valueLabel">
      <div class="gb-field" :class="{ active: showOptions, 'gb-az-errors': field.errors }"
        tabindex="0"
        ref= "checklistField"
        @focus="focusHandler">
        <span v-if="value && value.length">
          {{ valueLabel }}
        </span>
        <span v-else class="placeholder">
          {{ field.placeholder }}
        </span>
      </div>
      <span class="chevron">
        <icon name="chevron_right"/>
      </span>
    </div>
    <portal to="root" :disabled="!field.multiselect">
      <transition name="fadein">
        <div v-if="showOptions || !field.multiselect" class="checklist__options" :style="positionStyle"
          :class="{ 'checklist__options-floating': field.multiselect, 'gb-az-errors': field.errors && !field.multiselect, disabled: field.disabled }"
          ref="checklist">
          <div class="checklist__search" v-if="field.multiselect">
            <md-icon class="icon" name="search"/>
            <input type="text" class="gb-field" :placeholder="$t('search')" v-model.trim="search"
              ref=search tabindex="0"
              @keydown.down="setHighlight(true)"
              @keydown.enter="toggleHighlight"
              @keydown.tab="$refs.checklistField.focus(); showOptions = false"
              @keydown.up="setHighlight(false)"/>
            <span @click="search = ''" v-show="search.length > 0">
              <md-icon class="icon reset" name="close"/>
            </span>
          </div>
          <div>
            <ul>
              <li v-for="opt in options" :key="opt.value"
                :class="{ 'checklist__option-highlight': highlight === opt.value }">
                <input type="checkbox" :id="`${field.name}-${opt.value}`"
                  @change="updateSelectedOption($event, opt.value)"
                  @keydown.tab="showOptions = field.multiselect ? false : true"
                  :checked="isSelectedOption(opt.value)"
                  :disabled="opt.disabled"
                  :tabindex="field.multiselect ? -1 : 0">
                <label :for="`${field.name}-${opt.value}`">
                  <span class="checkbox" :class="{ radio: !field.multiple }">
                    <icon class="icon" name="done"/>
                  </span>
                  <slot :option="opt">
                    {{ opt.text }}
                  </slot>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon.vue'
import MdIcon from '@/components/MdIcon.vue'
import scrollIntoView from 'scroll-into-view-if-needed'

@Component({
  components: {
    Icon,
    MdIcon
  }
})
export default class Checklist extends Vue {
  @Prop() field!: Record<string, any>
  @Prop() value!: Array<string>

  private highlight = null
  private positionStyle = ''
  private search = ''
  private showOptions = false

  get options (): Array<{ text:string, value: string }> {
    return (this.field.options || [])
      .filter((opt: { text:string, value: string }) => {
        return !this.search.length ||
          opt.text.toLowerCase().includes(this.search.toLowerCase())
      })
  }

  get valueLabel (): string {
    return this.value.reduce((a: Array<string>, v: string) => {
      const opt = this.field.options
        .find((opt: { text: string, value: string}) => opt.value === v)
      const optLabel = opt?.text

      if (optLabel) {
        a.push(optLabel)
      }

      return a
    }, []).join(', ')
  }

  beforeDestroy (): void {
    document.removeEventListener('mousedown', this.clickHandler)
    window.removeEventListener('resize', this.updatePositionStyle)
    window.removeEventListener('scroll', this.updatePositionStyle, true)
  }

  clickHandler (e: Event): void {
    if (this.showOptions === true &&
      !this.$el.contains(e.target as HTMLElement) &&
      !(this.$refs.checklist as HTMLElement).contains(e.target as HTMLElement)) {
      this.showOptions = false
    }
  }

  focusHandler (e: FocusEvent): void {
    const relatedTarget = e.relatedTarget as HTMLElement

    if (this.$el.contains(relatedTarget) ||
      (this.$refs.checklist as HTMLElement)?.contains(relatedTarget)) {
      this.showOptions = false
      ;(e.target as HTMLElement).blur()
    } else {
      this.showOptions = true
      this.$nextTick(() => {
        this.$nextTick(() => {
          (this.$refs.search as HTMLElement)?.focus()
        })
      })
    }
  }

  isSelectedOption (opt: string): boolean {
    return this.value.includes(opt)
  }

  mounted (): void {
    if (this.field.multiselect) {
      document.addEventListener('mousedown', this.clickHandler)
      window.addEventListener('resize', this.updatePositionStyle)
      window.addEventListener('scroll', this.updatePositionStyle, true)
    }
  }

  setHighlight (next: boolean): void {
    const options = this.options
    const length = options.length
    const index = options
      .findIndex(({ value }: { value: string }) => value === this.highlight)

    let newIndex = index

    if (index === -1) {
      newIndex = next ? 0 : (length - 1)
    } else {
      newIndex += next ? 1 : -1

      if (newIndex < 0) {
        newIndex = length - 1
      } else if (newIndex >= length) {
        newIndex = 0
      }
    }

    this.highlight = this.field.options[newIndex]?.value

    this.$nextTick(() => {
      const el = this.$refs.checklist as HTMLElement

      this.$nextTick(() => {
        const highlighted = el.querySelector('.checklist__option-highlight') as HTMLElement
        scrollIntoView(highlighted, { scrollMode: 'if-needed' })
      })
    })
  }

  toggleHighlight (): void {
    if (this.highlight) {
      const checked = !this.value.includes(this.highlight)

      this.updateSelectedOption(
        { target: { checked } } as unknown as Event,
        this.highlight
      )
    }
  }

  updatePositionStyle (): void {
    const bbox = (this.$refs.checklistWrapper as HTMLElement).getBoundingClientRect()

    if (bbox.top + bbox.height / 2 < window.innerHeight / 2) {
      this.positionStyle = `top: ${bbox.bottom}px;`
    } else {
      this.positionStyle = `bottom: ${window.innerHeight - bbox.top}px;`
    }

    this.positionStyle += `; left: ${bbox.left}px; width: ${bbox.width}px;`
  }

  updateSelectedOption (e: Event, opt: string): void {
    this.$emit('input', (e.target as HTMLInputElement).checked
      ? (
          this.field.multiple
            ? this.value.concat([opt])
            : [opt]
        )
      : this.value.filter(v => v !== opt)
    )
  }

  @Watch('showOptions')
  onShowOptionsChange (value: boolean): void {
    if (!value) {
      this.highlight = null
      this.search = ''
    } else {
      this.updatePositionStyle()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.checklist {
  position: relative;
  transform: rotate3d(0.001deg);
}

.checklist__select {
  overflow: hidden;
  white-space: nowrap;

  & > div {
    align-items: center;
    display: flex;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .gb-field {
    user-select: none;

    &.active {
      box-shadow: inset 0 0 0 1.5px $primary, 0 0 15px rgba($color: $primary, $alpha: .1);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .placeholder {
    color: $grey-dark;
  }
}

.checklist__search {
  align-items: baseline;
  background-color: $grey-light;
  display: flex;
  z-index: 1;

  .icon {
    color: $grey-dark;
    text-align: center;
    font-size: 1.2rem;
    width: 40px;
    transition: color .15s;

    &.reset:hover {
      color: $primary;
      cursor: pointer;
    }
  }

  input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    height: auto;
    margin: 0;
    outline: none;
    padding: .5rem 0;
    width: 100%;

    &:focus {
      box-shadow: none
    }
  }
}

.checklist__options {
  @extend .gb-field;
  height: auto !important;
  overflow: hidden;
  padding: 0;

  & > div:last-child {
    max-height: 155px;
    min-height: 80px;
    overflow: auto;
  }

  &.disabled {
    background-color: lighten($color: $grey, $amount: 5%);
    color: $content-light;
    opacity: .7;
  }

  &.checklist__options-floating {
    border: 1px solid $grey;
    box-shadow: 0 20px 40px 0 rgba(66,97,158,0.2);
    position: fixed;
    z-index: 999;

    & > div:last-child {
      min-height: auto;
    }
  }

  &.fadein-leave-active,
  &.fadein-enter-active {
    transition: all .15s ease;
  }

  &.fadein-enter,
  &.fadein-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  input[type="checkbox"] {
    height: 1px;
    left: 10px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    width: 1px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 11px 0;

    li {
      padding: 0 12.5px;
      position: relative;

      &.checklist__option-highlight {
        background-color: $primary-light;
      }

      label {
        color: $content-light;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        margin: 0;
        padding: 4px 0;
        user-select: none;
      }

      .checkbox {
        border-radius: 7px;
        border: 2px solid $grey;
        display: inline-block;
        flex-shrink: 0;
        height: 20px;
        margin-right: .5rem;
        margin-top: 3px;
        position: relative;
        transition: background-color .05s, border-color .05s;
        width: 20px;

        & > * {
          color: white;
          left: 50%;
          opacity: 0;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: opacity .05s;
        }

        &.radio {
          border-radius: 50%;

          & > * {
            background-color: $primary;
            border-radius: 50%;
            border: 2px solid white;
            color: $primary;
            display: block;
            position: static;
            transform: none;
          }
        }
      }

      input[type="checkbox"]:checked + label .checkbox {
        background-color: $primary;
        border-color: $primary;

        & > * {
          opacity: 1;
        }
      }

      input[type="checkbox"]:disabled + label {
        opacity: .5;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>
