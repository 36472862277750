import dayjs from 'dayjs'
import i18n from '@/i18n'

const _compare = (a, b) => {
  if (a.created < b.created) {
    return -1
  }
  if (a.created > b.created) {
    return 1
  }
  return 0
}

const _getListStaticTime = (room) => {
  /*
    This is for old computers that are too slow to display elapsed time
    updated every second.
  */
  let value
  if (room.status === 'running') {
    value = dayjs.unix(room.last_running).format(
      i18n.t('date.longdatetimeFormat'))
  } else if (['waiting', 'transferred'].indexOf(room.status) > -1) {
    value = dayjs.unix(room.last_waiting).format(
      i18n.t('date.longdatetimeFormat'))
  } else if (room.status === 'late') {
    value = dayjs.unix(room.last_waiting).format(
      i18n.t('date.longdatetimeFormat'))
  } else if (room.status === 'closed') {
    value = dayjs.unix(room.last_closed).format(i18n.t('date.datetimeFormat'))
  }

  return value
}

const _getListTime = (startTime, room) => {
  /* We don't use date librairy here as it consumes too much memory */
  let value
  if (room.status === 'running') {
    value = startTime - room.last_running * 1000
  } else if (['waiting', 'transferred'].indexOf(room.status) > -1) {
    value = startTime - room.last_waiting * 1000
  } else if (room.status === 'late') {
    value = startTime - room.last_waiting * 1000
  } else if (room.status === 'closed') {
    value = room.last_closed * 1000 - room.last_running * 1000
  }
  value = new Date(value).toLocaleTimeString('en-GB', {
    timeZone: 'UTC'
  })
  return value
}

const sortAscData = localStorage.getItem('gb--gba--lists-sort')

// initial state
const state = {
  areTimersStarted: false,
  startTime: +new Date(),
  areRoomsLoading: true,
  isRoomLoading: true,
  list: null,
  messages: null,
  notes: null,
  openRoomsCount: 0,
  prevRoom: null,
  prevRunningRoom: null,
  room: null,
  roomHasBeenClosed: false,
  roomWebRTCConnected: false,
  history: null,
  sortAsc: sortAscData
    ? JSON.parse(sortAscData)
    : {
      closed: false,
      late: true,
      'only-waiting': true,
      running: true,
      search: false,
      transferred: true,
      waiting: true
    },
  typing: {},
  zencallSkills: null
}

// getters
const getters = {
  areRoomsLoading () {
    return state.areRoomsLoading
  },
  getAll () {
    return state.list
  },
  getEventFromHistory: (state) => (eventUuid) => {
    let event = null
    if (state.history) {
      state.history.forEach((evt) => {
        if (evt.id === eventUuid) {
          event = evt
        }
      })
    }
    return event
  },
  getLabelIdFromRoom: () => {
    return (room) => {
      const channel = (room.infos?.origin || room.origin || room.channel_public)
        .split('-')[0]
        .trim()
        .toLowerCase()

      let prefix = 'ID'

      if (channel) {
        switch (channel) {
          case 'api':
            prefix = 'API'
            break
          case 'apple business chat':
          case 'apple':
            prefix = 'AMB'
            break
          case 'facebook':
            prefix = 'FB'
            break
          case 'google business messages':
          case 'googlebm':
            prefix = 'GBM'
            break
          case 'google rcs':
            prefix = 'RCS'
            break
          case 'instagram dm':
          case 'instagram_dm':
            prefix = 'INS'
            break
          case 'sms':
            prefix = 'SMS'
            break
          case 'telegram':
            prefix = 'TEL'
            break
          case 'twitter_dm':
          case 'twitter':
            prefix = 'TWT'
            break
          case 'gupshup':
          case 'sinch':
          case 'twilio':
          case 'tyntec':
          case 'whatsapp':
            prefix = 'WA'
            break
          default:
            prefix = 'LC'
        }
      }

      return room.session_group_integer_id || room.session_group_int_id
        ? `${prefix}${room.session_group_integer_id || room.session_group_int_id}`
        : room.infos?.name
    }
  },
  getOpenRoomsCount: (state) => {
    return state.openRoomsCount
  },
  getPrevRoom () {
    return state.prevRoom
  },
  getPrevRunningRoom () {
    return state.prevRunningRoom
  },
  getRoom () {
    return state.room
  },
  getRoomMessages () {
    if (state.messages !== null) {
      const messages = JSON.parse(JSON.stringify(state.messages))
      return messages.sort(_compare)
    }
    return []
  },
  getRoomStatus () {
    let status = ''
    if (state.room !== null) {
      status = state.room.status
    }
    return status
  },
  getRoomHasBeenClosed () {
    return state.roomHasBeenClosed
  },
  getRoomHistory () {
    let history = ''
    if (state.history !== null) {
      history = state.history
    }
    return history
  },
  getRoomNotes () {
    let notes = ''
    if (state.notes !== null) {
      notes = state.notes
    }
    return notes
  },
  getRoomUserInfos () {
    let details = {
      active_since: '',
      avatar: '',
      browser: '',
      contact_reason: '',
      crm_data: {},
      customer: '',
      detected_language: '',
      device: '',
      email: '',
      extra: '',
      full_name: '',
      language: '',
      name: '',
      origin: '',
      phone_number: '',
      satisfaction: '',
      skill: '',
      username: ''
    }
    if (state.room) {
      details = {
        active_since: state.room.infos.active_since,
        avatar: state.room.infos.avatar,
        browser: state.room.infos.browser,
        contact_reason: state.room.infos.contact_reason,
        crm_data: state.room.infos.crm_data,
        customer: state.room.infos.customer,
        detected_language: state.room.infos.detected_language,
        device: state.room.infos.device,
        email: state.room.infos.email,
        extra: state.room.infos.extra,
        full_name: state.room.infos.full_name,
        language: state.room.infos.language,
        name: state.room.infos.name,
        origin: state.room.infos.origin,
        phone_number: state.room.infos.phone_number,
        satisfaction: state.room.infos.satisfaction,
        skill: state.room.infos.skill,
        username: state.room.infos.username
      }
    }
    return details
  },
  getRoomWebRTCConnected (state) {
    return state.roomWebRTCConnected
  },
  getZencallSkills () {
    return state.zencallSkills
  },
  isListSortAsc: state => status => {
    return state.sortAsc[status]
  },
  isRoomLoading () {
    return state.isRoomLoading
  },
  isTyping () {
    return roomUuid => !!state.typing[roomUuid]
  },
  roomToFocus (state) {
    let room = null
    ;['late', 'running', 'transferred', 'waiting'].some(status => {
      room = state.list && state.list[status]
      ? state.list[status].data[state.list[status].order[0]]
      : null
      return room
    })
    return room
  }
}

// actions
const actions = {
  fetchRoomToUpdate ({ commit, state, rootGetters }, { data }) {
    const skills = rootGetters['GBA/user/getUserSkills']
    const hasSkill = skills ? skills.find(s => s.uuid === data.skill) : false
    const userRole = rootGetters['user/user'].user_role
    const SHARED_STATUS = ['waiting']

    // Prevent update without skill right access except for transferred
    if (!hasSkill && data.current_status !== 'transferred') {
      return
    }

    // Import permissions rules
    import('@/settings').then($settings => {
      const permissions = $settings.default.permissions

      // Add closed status to shared status list
      // if user has right access (admin+ or manager of this skill)
      if (permissions[userRole] >= permissions.admin ||
        (permissions[userRole] >= permissions.manager && hasSkill)) {
        SHARED_STATUS.push('closed')
      }

      // Add transferred status to shared status list
      // if user has skill right access
      if (hasSkill) {
        SHARED_STATUS.push('transferred')
      }

      // Prevent update of previous/current status list
      // if user isn't the room owner except for shared status lists
      // (User shouldn't get this room outside of shared lists)
      if (data.agent && data.agent.uuid !== rootGetters['GBA/user/getUser'].uuid) {
        if (!SHARED_STATUS.includes(data.previous_status)) {
          data.previous_status = 'other'
        }
        if (!SHARED_STATUS.includes(data.current_status)) {
          data.current_status = 'other'
        }
      }

      // Prevent falsey display if has not right access
      // case 1: auto allocation - waiting (ok) then auto running for another agent (nok)
      // case 2: transfert to another skill not owned by agent
      if (!hasSkill && !SHARED_STATUS.includes(data.current_status)) {
        data.current_status = 'other'
      }

      // Modify (add, update or delete) room in list(s)
      commit('updateRoomInList', data)

      // Update current room if needed
      if (state.room && state.room.uuid === data.room.uuid) {
        commit('updateRoomData', data.room)
      }
    })
  },
  sendBlockRoom () {
    this._vm.$socket.send({
      cmd: 'block_room',
      room: state.room.uuid
    })
  },
  // eslint-disable-next-line
  sendCloseRoom (_, data) {
    this._vm.$socket.send(Object.assign({
        cmd: 'close_room',
        room: state.room.uuid
    }, data || {}))
  },
  // eslint-disable-next-line
  sendFiles ({ }, data) {
    const url = data.url
    const uploadFile = data.uploadFile
    this._vm.$socket.send({
      cmd: 'send_message',
      room: state.room.uuid,
      content: {
        url,
        txt: uploadFile.name,
        mimetype: uploadFile.type
      },
      rich_content: [{
        kind: 'file',
        file: {
          url,
          name: uploadFile.name,
          mimetype: uploadFile.type
        }
      }]
    })
  },
  // eslint-disable-next-line
  sendLaunchZenCall ({ }, skill_uuid) {
    this._vm.$socket.send({
      cmd: 'launch_zencall',
      room: state.room.uuid,
      zencall: skill_uuid
    })
  },
  // eslint-disable-next-line
  sendLocations ({ }, places) {
    let content = ''
    const richContent = []
    places.forEach((place) => {
      richContent.push(place)
      content += place.location.label + '\n'
    })

    this._vm.$socket.send({
      cmd: 'send_message',
      rich_content: richContent,
      content: content,
      room: state.room.uuid
    })
  },
  // eslint-disable-next-line
  sendPayment ({ }, data) {
    const payment = Object.assign(
      {}, data.payment, { kind: 'payment' })
    this._vm.$socket.send({
      cmd: 'send_message',
      room: data.uuid,
      rich_content: [payment],
      content: ''
    })
  },
  // eslint-disable-next-line
  sendRoom ({ commit, dispatch, getters, rootGetters }, route) {
    this._vm.$socket.send({
      cmd: 'get_room',
      sudo: rootGetters['GBA/search/isSearching'],
      uuid: route.params.uuid
    })
    if (!route.params.event_uuid) {
      this._vm.$socket.send({
        cmd: 'get_messages',
        room: route.params.uuid,
        lastevent: null,
        fromevent: null
      })
    }
  },
  // eslint-disable-next-line no-empty-pattern
  sendRoomsList ({ getters }, data) {
    const sortAsc = getters.isListSortAsc(data.status)
    data.order_by = sortAsc ? '' : '-'

    switch (data.status) {
      case 'running':
        data.order_by += 'last_running'
        break
      case 'closed':
        data.order_by += 'last_closed'
        break
      case 'transferred':
        data.order_by += 'last_transferred'
        break

      default:
        data.order_by += 'last_waiting'
        break
    }

    this._vm.$socket.send({
      cmd: 'get_rooms_list',
      data
    })
  },
  sendRoomsLists ({ getters }) {
    const sendWS = (status, orderBy) => {
      this._vm.$socket.send({
        cmd: 'get_rooms_list',
        data: {
          order_by: (getters.isListSortAsc(status) ? '' : '-') + orderBy,
          status: status
        }
      })
    }
    sendWS('running', 'last_running')
    sendWS('late', 'last_waiting')
    sendWS('closed', 'last_closed')
    if (process.env.VUE_APP_SAAS !== undefined) {
      sendWS('only-waiting', 'last_waiting')
      sendWS('transferred', 'last_transferred')
    } else {
      // TO REMOVE WITH STANDALONE
      sendWS('waiting', 'last_waiting')
    }
  },
  initRoomsTimes ({ commit, state }) {
    if (state.areTimersStarted === false) {
      window.setInterval(() => {
        commit('updateRoomsTimes')
      }, 1000)
    }
    commit('initTimersStarted')
  },
  sendRoomTyping ({ getters }, isTyping) {
    const room = getters.getRoom
    if (room !== null) {
      this._vm.$socket.send({
        cmd: 'typing',
        data: {
          active: !!isTyping,
          room: room.uuid
        }
      })
    }
  },
  sendRoomUnreadMsgs ({ commit, state }, room) {
    commit('updateRoomAsRead', room)
    if (['late', 'running'].indexOf(room.status) > -1) {
      this._vm.$socket.send({
        cmd: 'room_is_read',
        room: room.uuid
      })
    }
  },
  sendTransferRoom (_, skillOrData) {
    const data = {
      skill: typeof skillOrData === 'object'
        ? skillOrData.skill
        : skillOrData
    }

    if (skillOrData.agent) {
      data.agent = skillOrData.agent
    }

    this._vm.$socket.send({
      cmd: 'transfer_room',
      room: state.room.uuid,
      skill: data.skill,
      to_agent: data.agent
    })
  },
  sendZencallSkills () {
    this._vm.$socket.send({
      cmd: 'get_zencalls'
    })
  },
  toggleListSortAsc ({ commit, dispatch, getters, rootGetters, state }, status) {
    commit('setListSortAsc', {
      status,
      asc: !getters.isListSortAsc(status)
    })

    localStorage.setItem('gb--gba--lists-sort', JSON.stringify(state.sortAsc))

    if (status === 'search') {
      dispatch('GBA/search/fetchRooms', rootGetters['GBA/search/filters'], { root: true })
    } else {
      dispatch('sendRoomsList', { status })
    }
  },
  updateInfo ({ commit, dispatch, getters }, data) {
    return this._vm.$http.post(`${process.env.VUE_APP_GBA_URL}/guests/${data.guest}/info`, data)
      .then(() => {
        const room = JSON.parse(JSON.stringify(getters.getRoom))
        Object.assign(room.infos, data)
        commit('updateRoomInList', {
          current_status: room.status,
          room
        })
      })
      .catch((err) => {
        dispatch('global/handleHttpError', err, { root: true })
      })
  }
}

// mutations
const mutations = {
  initRoomHistory (state, data) {
    // console.log('HISTORY', data.data)
    state.history = data.history
    state.isRoomLoading = false
    state.roomHasBeenClosed = false
  },
  initTimersStarted (state) {
    state.areTimersStarted = true
  },
  setListSortAsc (state, data) {
    state.sortAsc[data.status] = !!data.asc
  },
  setRoomHasBeenClosed (state) {
    state.roomHasBeenClosed = true
  },
  setRoomIsLoading (state) {
    state.isRoomLoading = true
    state.messages = null
    state.room = null
    state.history = null
    state.roomHasBeenClosed = false
  },
  setRoomWebRTCConnected (state, value) {
    state.roomWebRTCConnected = value
  },
  updateAccordion (state, { status, force }) {
    for (const status_ in state.list) {
      if (status === status_) {
        state.list[status_].expanded = force !== undefined ? !!force : !state.list[status_].expanded
      }
    }
  },
  updateListBySkill (state, data) {
    for (const skill in data.data) {
      data.data[skill].by_skill = true
      this.commit('GBA/rooms/updateList', { data: data.data[skill] })
    }
  },
  updateList (state, data) {
    data = data.data
    const paging = data.from_room !== null
    const status = data.status || 'search'

    Object.defineProperty(data, 'data', Object.getOwnPropertyDescriptor(data, 'rooms'))
    delete data.rooms;

    (data.data || []).forEach(room => {
      const value = _getListTime(state.startTime, room)
      this._vm.$set(room, 'list_time', value)
    })

    if (paging === true) {
      if (data.skill !== null) {
        data.data = state.list[status].data[data.skill].data.concat(data.data)
        state.list[status].data[data.skill] = Object.assign({}, state.list[status].data[data.skill], data)
      } else {
        data.data = state.list[status].data.concat(data.data)
        state.list[status] = Object.assign({}, state.list[status], data)
      }
    } else {
      if (state.list === null) state.list = {}

      if (data.by_skill === true) {
        if (state.list[status] === undefined) this._vm.$set(state.list, status, {})
        if (state.list[status].data === undefined) this._vm.$set(state.list[status], 'data', {})

        this._vm.$set(state.list[status].data, data.skill, data)
        this._vm.$set(state.list[status], 'expanded', state.list && state.list[status] ? state.list[status].expanded : false)

        const ordered = {}
        let numberOfRooms = 0
        let unreadMsg = false

        Object.keys(state.list[status].data).sort().forEach(skill => {
          ordered[skill] = state.list[status].data[skill]
          numberOfRooms += state.list[status].data[skill].number_of_rooms
          unreadMsg = state.list[status].data[skill].unread_msg || unreadMsg
        })

        this._vm.$set(state.list[status], 'data', ordered)
        this._vm.$set(state.list[status], 'number_of_rooms', numberOfRooms)
        this._vm.$set(state.list[status], 'unread_msg', unreadMsg)
      } else {
        if (data.skill) {
          if (state.list[status] === undefined) this._vm.$set(state.list, status, {})
          if (state.list[status].data === undefined) this._vm.$set(state.list[status], 'data', {})

          state.list[status].data[data.skill] = Object.assign({}, state.list[status].data[data.skill] || {}, data)

          let numberOfRooms = 0
          let unreadMsg = false

          Object.keys(state.list[status].data).forEach(skill => {
            numberOfRooms += state.list[status].data[skill].number_of_rooms
            unreadMsg = state.list[status].data[skill].unread_msg || unreadMsg
          })

          state.list[status].number_of_rooms = numberOfRooms
          state.list[status].unread_msg = unreadMsg
        } else {
          data.expanded = state.list && state.list[status] ? state.list[status].expanded : false
          this._vm.$set(state.list, status, data)
        }
      }

      let tmp = 0
      for (const s in state.list) {
        if (s === 'running' || s === 'late') {
          tmp += state.list[s].number_of_rooms
        }
      }
      state.openRoomsCount = tmp
    }

    state.areRoomsLoading = false
  },
  updateList2 (state, data) {
    data = data.data
    const paging = data.from_room !== null
    let status = data.status || 'search'

    if (status === 'only-waiting') status = 'waiting'

    Object.defineProperty(data, 'data', Object.getOwnPropertyDescriptor(data, 'rooms'))
    delete data.rooms

    if (paging === true) {
      data.order = state.list[status].order.concat(data.data.map(room => room.uuid))
      data.data = Object.assign({}, state.list[status].data, data.data.reduce(
        (rooms, room) => ({ ...rooms, [room.uuid]: room }), {}
      ))
      state.list[status] = Object.assign({}, state.list[status], data)
    } else {
      if (state.list === null) state.list = {}

      data.expanded = state.list && state.list[status] ? state.list[status].expanded : false
      data.order = data.data.map(room => room.uuid)
      data.data = data.data.reduce(
        (rooms, room) => ({ ...rooms, [room.uuid]: room }), {}
      )
      this._vm.$set(state.list, status, data)

      let tmp = 0
      for (const s in state.list) {
        if (s === 'running' || s === 'late') {
          tmp += state.list[s].number_of_rooms
        }
      }
      state.openRoomsCount = tmp
    }

    state.areRoomsLoading = false
  },
  updateRoom (state, data) {
    if (data === null) {
      state.room = null
      state.prevRoom = null
      state.prevRunningRoom = null
    } else {
      if (data && data.from_search) {
        if (!state.prevRoom || (state.prevRoom.uuid !== data.data.uuid)) {
          state.prevRunningRoom = state.prevRoom && state.prevRoom.status === 'running'
            ? Object.assign({}, state.prevRoom)
            : false
        }
      } else {
        state.prevRunningRoom = null
        state.prevRoom = Object.assign({}, data.data)
      }

      state.room = Object.assign({}, state.room, data.data)
      state.isRoomLoading = false
      let roomStatus = state.room.status
      if (roomStatus === 'transferred') {
        roomStatus = 'waiting'
      }
      for (const status in state.list) {
        if (!data.from_search) {
          if (status === roomStatus) {
            state.list[status].expanded = true
          } else if (process.env.VUE_APP_SAAS !== undefined) {
            // TO REMOVE WITH STANDALONE
            state.list[status].expanded = false
          }
        }
      }
    }
  },
  updateRoomData (state, data) {
    state.room = Object.assign({}, state.room, data)
  },
  updateRoomInList (state, data) {
    // PREVIOUS STATE
    if (['running', 'late', 'waiting', 'closed', 'transferred'].includes(data.previous_status)) {
      const list = state.list[data.previous_status]
      if (list.data[data.room.uuid]) {
        // Delete room if exist
        list.unread_message -= list.data[data.room.uuid].unread_msg
        this._vm.$delete(list.order, list.order.indexOf(data.room.uuid))
        this._vm.$delete(list.data, data.room.uuid)
      } else if (list.order.length === list.number_of_rooms) {
        // Prevent paging if not exist anymore
        list.paging_end = true
      }
      list.number_of_rooms--
      if (['running', 'late'].includes(data.previous_status)) {
        state.openRoomsCount--
      }
    }

    // CURRENT STATE
    if (['running', 'late', 'waiting', 'closed', 'transferred'].includes(data.current_status)) {
      const list = state.list[data.current_status]
      if (list.data[data.room.uuid]) {
        // Update room if exist
        if (data.room.unread_msg !== undefined) {
          list.unread_message = list.unread_message - list.data[data.room.uuid].unread_msg + data.room.unread_msg
        }
        list.data[data.room.uuid] = Object.assign({}, list.data[data.room.uuid], data.room)
      } else if (data.previous_status) {
        if (data.current_status === 'closed') {
          // Last closed room are displayed first
          list.order.unshift(data.room.uuid)
          list.data = Object.assign({}, { [data.room.uuid]: data.room }, list.data)
        } else if (list.paging_end === true) {
          // No paging yet
          list.order.push(data.room.uuid)
          list.unread_message += data.room.unread_msg
          list.data = Object.assign({}, list.data, { [data.room.uuid]: data.room })
        }
        list.number_of_rooms++
        if (['running', 'late'].includes(data.current_status)) {
          state.openRoomsCount++
        }
      }
    }
  },
  updateRoomInfos (state, data) {
    state.room.infos = Object.assign({}, state.room.infos, data)
  },
  updateRoomAsRead (state, room) {
    room.unread_msg = 0

    let roomInList = null

    if (Array.isArray(state.list[room.status].data)) {
      roomInList = state.list[room.status].data.find(r => r.uuid === room.uuid)
    } else {
      roomInList = state.list[room.status].data[room.uuid]
    }

    if (roomInList) {
      roomInList.unread_msg = 0
    }
  },
  updateRoomHistory (state, data) {
    state.history = data
    state.isRoomLoading = false
  },
  updateRoomMessages (state, data) {
    state.messages = data.messages
    state.isRoomLoading = false
  },
  updateRoomNotes (state, data) {
    state.notes = data.data
    state.isRoomLoading = false
  },
  updateRoomEvent (state, data) {
    // console.log('EVENTS', data.data)
    // TODO:
    // - Also for rooms status changes
    // - Messages should not be push at end but after the last message with
    // created < to data.data.created
    state.isRoomLoading = false
    // console.log('ROOM EVENT', data.data.room, state.room.uuid)
    if (state.room && data.data.room.uuid === state.room.uuid) {
      if (data.data.rich_content !== null || data.data.kind === 'smartcoaching') {
        if (state.messages) {
          const messageIndex = state.messages.findIndex(msg => msg.event === data.data.event)
          if (messageIndex > -1) {
            state.messages.splice(messageIndex, 1, data.data)
          } else {
            state.messages.push(data.data)
          }

          if (data.data.room?.infos) {
            this._vm.$set(state.room.infos, 'detected_language', data.data.room.infos.detected_language)
          }
        }
      } else {
        this._vm.$set(state.room, 'status', data.data.kind)
      }
    }
  },
  updateRoomsTimes (state, data) {
    if (data !== undefined && data.staticTime !== undefined &&
        data.staticTime === true) {
      for (const status in state.list) {
        for (const uuid in state.list[status].data) {
          const item = state.list[status].data[uuid]
          if (item.by_skill === true) {
            item.data.forEach((room) => {
              room.list_time = _getListStaticTime(room)
            })
          } else {
            item.list_time = _getListStaticTime(item)
          }
        }
      }
    } else {
      state.startTime = state.startTime + 1000
      for (const status in state.list) {
        for (const uuid in state.list[status].data) {
          const item = state.list[status].data[uuid]
          if (item.by_skill === true) {
            item.data.forEach((room) => {
              room.list_time = _getListTime(state.startTime, room)
            })
          } else {
            item.list_time = _getListTime(state.startTime, item)
          }
        }
      }
    }
  },
  updateRoomTyping (state, data) {
    this._vm.$set(state.typing, data.room, data.active === true)
  },
  updateZencallSkills (state, data) {
    state.zencallSkills = data.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
