<template>
  <vue-tel-input
    v-bind="$attrs"
    v-on="$listeners"
    mode="international"
  />
</template>

<script>
import 'vue-tel-input/dist/vue-tel-input.css'
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'InputTel',
  components: { VueTelInput }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.vue-tel-input {
  @extend .gb-field;
  position: relative;

  ::v-deep {
    input {
      color: $content-light;
    }

    .vti__dropdown {
      position: static;

      &.disabled, &.open, &:hover {
        background-color: transparent;
      }

      &:hover .vti__dropdown-arrow {
        color: $secondary;
      }
    }

    .vti__dropdown-list.below {
      left: 0;
      top: 100%;
      width: 100% !important;
    }
  }
}
</style>
