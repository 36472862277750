// initial state
const state = {
  accounts: null
}

// getters
const getters = {
  account () {
    return kind => (state.accounts || []).find(account => account.kind === kind)
  },
  accounts () {
    return state.accounts
  }
}

// mutations
const mutations = {
  setAccount (state, data) {
    const index = state.accounts.findIndex(account => account.id === data.id)
    if (index >= 0) {
      this.$set(state.accounts, index, data)
    } else {
      if (Array.isArray(state.accounts)) {
        state.accounts.push(data)
      } else {
        state.accounts = [data]
      }
    }
  },
  setAccounts (state, data) {
    state.accounts = data
  }
}

// actions
const actions = {
  editAccount ({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      this._vm.$http.post(`${process.env.VUE_APP_API}/internal/accounts/${params.id}`, params)
        .then(response => response.json())
        .then((data) => {
          commit('setAccount', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_API}/internal/accounts`)
        .then(response => response.json())
        .then((data) => {
          commit('setAccounts', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  initAccount ({ commit, dispatch }, kind) {
    return new Promise((resolve, reject) => {
      this._vm.$http.post(`${process.env.VUE_APP_API}/internal/accounts`, { kind })
        .then(response => response.json())
        .then((data) => {
          commit('setAccount', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
