import { Commit } from 'vuex'
import { Botgroup, Customization } from '@/types/botgroup'
import Vue from 'vue'

interface State {
  list: Array<Botgroup> | null
}

// initial state
const state: State = {
  list: null
}

// getters
const getters = {
  list (): Array<Botgroup> | null {
    return state.list
  }
}

// mutations
const mutations = {
  updateInList (state: State, data: Botgroup): void {
    if (state.list) {
      (this as any).$set(
        state.list,
        state.list.findIndex(bg => bg.id === data.id),
        data
      )
    }
  },
  updateList (state: State, data: Array<Botgroup> | null): void {
    state.list = data
  }
}

// actions
const actions = {
  delete: ({ commit }: { commit: Commit }, id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_BACKEND_URL}/integrations/${id}`)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetch: ({ commit }: { commit: Commit }, id: string): Promise<Botgroup> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/integrations/${id}`)
        .then((response: Response) => response.json())
        .then((data: Botgroup) => {
          resolve(data)
        }).catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchWidgetCustomization: ({ commit }: { commit: Commit }, id: string): Promise<Customization> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/integrations/${id}/widget`)
        .then((response: Response) => response.json())
        .then((data: Customization) => {
          resolve(data)
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAll: ({ commit }: { commit: Commit }): Promise<Array<Botgroup>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/integrations/`)
        .then((response: Response) => response.json())
        .then((data: Array<Botgroup>) => {
          commit('updateList', data)
          resolve(data)
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save: ({ commit }: { commit: Commit }, data: Botgroup): Promise<Botgroup> => {
    const id = data.id || ''
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/integrations/${id}`, data)
        .then((response: Response) => response.json())
        .then((data: Botgroup) => {
          resolve(data)
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveWidgetCustomization: ({ commit }: { commit: Commit }, data: Botgroup): Promise<void> => {
    const id = data.id
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/integrations/${id}/widget`, data)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
