<template>
  <div>
    <ul class="gb-az-errors" v-if="formDesc.nonFieldsErrors && formDesc.nonFieldsErrors.length">
      <li v-for="(item, index) in nonFieldsErrors"
          v-bind:key="index" >
        {{ $t(item) }}
      </li>
    </ul>

    <fieldset v-for="(fieldset, index) in formDesc.fieldsets" :class="fieldset.cls"
              v-bind:key="index">
      <legend v-if="fieldset.legend" class="gb-title">{{ fieldset.legend }}</legend>
      <div>
        <div v-for="(field, index) in fieldset.fields"
            v-bind:key="index">
          <div v-if="field.kind == 'InputHidden'" :class="field.cls">
            <input type="hidden" :name="field.name" :required="field.required"
                  :class="{'form-control': true, 'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  v-model="computedModel[fieldset.fields[index].name]" >
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'InputText'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details && field.html" class="gb-details" v-html="field.details"></p>
            <p v-else-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input type="text" :name="field.name" autocomplete="off"
                  :required="field.required" :maxlength="field.max_length" :minlength="field.min_length"
                  :pattern="field.validation"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  :placeholder="field.placeholder"
                  v-focus="field.focus"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(model)">
            <slot :name="field.name" :field="field"/>
          </div>

          <div v-if="field.kind == 'InputDate'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details && field.html" class="gb-details" v-html="field.details"></p>
            <p v-else-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input type="date" :name="field.name" autocomplete="off"
                  :required="field.required" :max="field.max" :minlength="field.min"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  v-focus="field.focus"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(model)">
            <slot :name="field.name" :field="field"/>
          </div>

          <div v-if="field.kind == 'InputTOTP'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details && field.html" class="gb-details" v-html="field.details"></p>
            <p v-else-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-totp :field="field" v-model="computedModel[fieldset.fields[index].name]"/>
            <slot :name="field.name" :field="field"/>
          </div>

          <div v-if="field.kind == 'InputNumber'" :class="field.cls" :title="field.placeholder">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details && field.html" class="gb-details" v-html="field.details"></p>
            <p v-else-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input type="number" :name="field.name" autocomplete="off" :required="field.required" :min="field.min" :max="field.max"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  :placeholder="field.placeholder"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(model)">
          </div>
          <div v-if="field.kind == 'PlaceId'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <label :disabled="field.disabled" :for="field.name" class="gb-btn" :class="{ 'gb-btn-outline': !computedModel[fieldset.fields[index].name], 'gb-btn-secondary': model[fieldset.fields[index].name] }">
              <input type="text" :name="field.name" :id="field.name" autocomplete="off" :required="field.required"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  :placeholder="field.placeholder"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(computedModel)"
                  @click="placePicker($event, fieldset.fields[index].name)">
                <span v-if="computedModel[fieldset.fields[index].name]">{{ $t('field.place_id.picked') }}</span>
                <span v-else>{{ $t('field.place_id.to_pick') }}</span>
              </label>
          </div>
          <div v-if="field.kind == 'InputColor'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <div class="color">
              <label :for="field.name + '-' + uuid" :style="`background-color: ${computedModel[field.name]}`">
              </label>
              <input :id="field.name + '-' + uuid" type="color" name="color" v-model="computedModel[field.name]"
              class="form-control-sm">
              <input type="text" :name="field.name" autocomplete="off" :required="field.required"
                    :class="{'gb-az-errors': field.errors}"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    v-model="computedModel[fieldset.fields[index].name]"
                    v-on:blur="blur(computedModel)">
            </div>
          </div>
          <div v-if="field.kind == 'InputCalendar'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-calendar :value="computedModel[fieldset.fields[index].name]"
              @input="emitCalendar(fieldset.fields[index].name, $event)"
              :field="field"/>
          </div>
          <div v-if="field.kind == 'InputPassword'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-password :field="field" v-model="computedModel[fieldset.fields[index].name]"/>
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'InputEmail'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input type="email" :name="field.name" :required="field.required"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="field.disabled"
                  :readonly="field.readonly"
                  :placeholder="field.placeholder"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(computedModel)" >
          </div>
          <div v-if="field.kind == 'InputTel'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-tel
              v-model="computedModel[field.name]"
              :class="{'gb-az-errors': field.errors}"
              :inputOptions="{
                disabled: field.disabled,
                name: field.name,
                placeholder: field.placeholder,
                required: field.required
              }"
              @country-changed="countryChange"
              @input="inputTelChange"
              @blur="blur(computedModel)"
            />
          </div>
          <div v-if="field.kind == 'InputURL'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-file v-if="field.allow_upload" :field="field" v-model="computedModel[fieldset.fields[index].name]" />
            <template v-else >
              <input type="url" :name="field.name" :required="field.required"
                    :class="{'gb-az-errors': field.errors}"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    v-model="computedModel[fieldset.fields[index].name]"
                    v-on:blur="blur(computedModel)" >
            </template>
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'InputFile'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-file :field="field" :raw="true" v-model="computedModel[fieldset.fields[index].name]"/>
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'InputCheckbox'" :class="field.cls">
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
              <label class="checkbox">
                <div class="toggle" :class="{'gb-az-errors': field.errors}">
                  <input class="toggle-state" type="checkbox" :name="field.name" :required="field.required"
                    :class="{'gb-az-errors': field.errors}"
                    :disabled="field.disabled"
                    :placeholder="field.placeholder"
                    v-model="computedModel[fieldset.fields[index].name]"
                    v-on:blur="blur(computedModel)" >
                  <div class="toggle-inner">
                    <div class="indicator"></div>
                  </div>
                  <div class="active-bg"></div>
                </div>
                <div class="label-text" v-if="field.label && field.html" v-html="field.label"></div>
                <div class="label-text" v-else-if="$scopedSlots[`label-${field.name}`]">
                  <slot class="label-text" :name="`label-${field.name}`" :field="field"/>
                </div>
                <div class="label-text" v-else-if="field.label"><span>{{ field.label }}</span><helper v-if="field.helper" :content="$t(field.helper)" /></div>
              </label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
          </div>
          <div v-if="field.kind == 'InputRadio'" :class="field.cls">
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            <div class="label-text" v-if="field.label && field.html" v-html="field.label"></div>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label class="label-text" v-else-if="field.label">{{ field.label }}</label>
            <div class="input-radio-options">
              <span v-for="option in fieldset.fields[index].options"
                    v-bind:key="option.value">
                <input type="radio" :name="field.name" :required="field.required"
                  :id="`id_${fieldset.fields[index].name}_${option.value}`"
                  :value="option.value"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="option.disabled || field.disabled"
                  :placeholder="field.placeholder"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(computedModel)" >
                  <label class="radio"
                         :for="`id_${fieldset.fields[index].name}_${option.value}`">
                    <slot v-if="$scopedSlots['radio-' + field.name]" :name="'radio-' + field.name" :option="option"/>
                    <template v-else>{{ option.text }}</template>
                  </label>
              </span>
            </div>
            <slot :name="field.name" :field="field"/>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
          </div>
          <div v-if="field.kind == 'InputRadioToggle'" :class="field.cls">
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            <div class="label-text" v-if="field.label && field.html" v-html="field.label"></div>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <div class="label-text" v-else-if="field.label">{{ field.label }}</div>
            <radio-group>
              <template v-for="option in fieldset.fields[index].options">
                <input v-bind:key="'input_' + option.value" type="radio" :name="field.name" :required="field.required"
                  :id="`id_${fieldset.fields[index].name}_${option.value}`"
                  :value="option.value"
                  :class="{'gb-az-errors': field.errors}"
                  :disabled="option.disabled || field.disabled"
                  :placeholder="field.placeholder"
                  v-model="computedModel[fieldset.fields[index].name]"
                  v-on:blur="blur(computedModel)" >
                  <label v-bind:key="'label_' + option.value" class="radio"
                    :for="`id_${fieldset.fields[index].name}_${option.value}`">
                    {{ option.text }}
                  </label>
              </template>
            </radio-group>
            <slot :name="field.name" :field="field"/>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
          </div>
          <div v-if="field.kind == 'InputCard'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details && field.html" class="gb-details" v-html="field.details"></p>
            <p v-else-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <input-stripe-payment-card
              v-model="computedModel[fieldset.fields[index].name]"
              :resetFieldErrors="resetFieldErrors"
              :setNetworkModel="setNetworkModel"
              :setFieldErrors="setFieldErrors"
              :field="field"
              ref="card" />
          </div>
          <div v-if="field.kind == 'Select'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <div class="select" :title="field.placeholder">
              <select :name="field.name" :required="field.required" :multiple="field.multiple"
                    :class="{'gb-az-errors': field.errors, invisible: field.checklist || field.multiselect}"
                      :tabindex="(field.checklist || field.multiselect) ? -1 : 0"
                      v-model="computedModel[fieldset.fields[index].name]"
                      v-on:change="blur"
                      :disabled="field.disabled">
                <option v-if="field.placeholder"
                      value="">
                      {{ field.placeholder }}
                </option>
                <template v-if="Array.isArray(fieldset.fields[index].options)">
                  <option v-for="option in fieldset.fields[index].options"
                    v-bind:key="option.value"
                    v-bind:value="option.value"
                    :disabled="option.disabled"
                    >
                    {{ option.text }}
                  </option>
                </template>
                <optgroup
                  v-for="(options, optgroup) in fieldset.fields[index].options"
                  v-bind:key="optgroup"
                  :label="optgroup"
                  v-else>
                  <option v-for="option in options"
                    v-bind:key="option.value"
                    v-bind:value="option.value"
                    :disabled="option.disabled">
                    {{ option.text }}
                  </option>
                </optgroup>
              </select>
              <span class="chevron" v-if="!field.multiple"><icon name="chevron_right"/></span>
            </div>
            <checklist v-if="field.checklist || field.multiselect"
              :field="field"
              v-model="computedModel[fieldset.fields[index].name]">
              <template slot-scope="{ option }" v-if="$scopedSlots[`${field.name}-option`]">
                <slot :name="`${field.name}-option`" :option="option"/>
              </template>
            </checklist>
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'Textarea'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details" >{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <Textarea :field="field"
              v-model="computedModel[fieldset.fields[index].name]"/>
            <slot :name="field.name" :field="field"/>
          </div>
          <div v-if="field.kind == 'CodeEditor'" :class="field.cls">
            <!-- CodeEditor v-model="computedModel[fieldset.fields[index].name]"></CodeEditor -->
          </div>
          <div v-if="field.kind == 'Datepicker'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <slot :name="`label-${field.name}`" v-else-if="$scopedSlots[`label-${field.name}`]" :field="field"/>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details" >{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <datepicker :timePicker="field.timePicker" :closeOnSelect="field.closeOnSelect || !field.timePicker"
              :range="field.range"
              :name="field.name" :required="field.required"
              :class="{'gb-az-errors': field.errors}"
              :disabled="field.disabled"
              :placeholder="field.placeholder"
              v-model="computedModel[fieldset.fields[index].name]"
              v-on:blur="blur(computedModel)" />
          </div>
          <div v-if="field.kind == 'MultiRangeSlider'" :class="field.cls">
            <label v-if="field.label && field.html" v-html="field.label"></label>
            <label v-else-if="field.label">{{ field.label }}</label>
            <p v-if="field.details" class="gb-details">{{ field.details }}</p>
            <p class="gb-az-errors"
              v-if="field.errors">
              <span v-html="field.errors.join('<br>')"></span>
            </p>
            <multi-range-slider :field="field" v-model="computedModel[fieldset.fields[index].name]"/>
            <slot :name="field.name" :field="field"/>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script type="text/javascript">
import 'vue-tel-input/dist/vue-tel-input.css'

/* eslint-disable vue/no-mutating-props */
import Vue from 'vue'
// import CodeEditor from 'simple-code-editor'
import VTooltip from 'v-tooltip'
import InputTel from '@/components/InputTel'
import Checklist from '@/components/fields/Checklist'
import Datepicker from '@/components/Datepicker'
import InputCalendar from '@/components/InputCalendar'
import InputFile from '@/components/InputFile'
import InputPassword from '@/components/InputPassword'
import InputStripePaymentCard from '@/components/InputStripePaymentCard'
import InputTotp from '@/components/InputTOTP.vue'
import Helper from '@/components/Helper'
import MultiRangeSlider from '@/components/MultiRangeSlider'
import RadioGroup from '@/components/RadioGroup.vue'
import Textarea from '@/components/Textarea'
import validators from '@/components/validators'

import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

Vue.use(VTooltip)
// Prevent conflict with Bootstrap tooltip
VTooltip.options.defaultTemplate = `<div class="v-tooltip" role="tooltip">
  <div class="v-tooltip-arrow"></div><div class="tooltip-inner"></div>
</div>`

export default {
  name: 'FormGen',
  components: {
    Checklist,
    // CodeEditor,
    Datepicker,
    Icon,
    InputCalendar,
    InputFile,
    InputPassword,
    InputStripePaymentCard,
    InputTel,
    InputTotp,
    MultiRangeSlider,
    Helper,
    RadioGroup,
    Textarea
  },
  data () {
    return {
      uuid: this.uuidv4()
    }
  },
  props: {
    blur: {
      type: Function,
      required: false,
      default: () => {
        // do nothing methods if we don't want to use update form on
        // field's blur
      }
    },
    countryChange: {
      type: Function,
      required: false,
      default: () => {
        // do nothing methods if we don't want to use track input tel country
        // changes
      }
    },
    formDesc: {
      type: Object,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    setNetworkModel: {
      type: Function,
      required: false,
      default: () => {
        // do nothing methods if we don't want to use update form on
        // click on an external button
      }
    },
    sendData: {
      type: Boolean,
      required: true
    },
    submit: {
      type: Function,
      required: false,
      default: () => {
        // do nothing methods if we don't want to use update form on
        // click on an external button
      }
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    computedModel () {
      // watch the entire model as a new object to be able to watch new values
      return this.model
    },
    nonFieldsErrors () {
      return Array.isArray(this.formDesc.nonFieldsErrors) ? this.formDesc.nonFieldsErrors : [this.formDesc.nonFieldsErrors]
    }
  },
  methods: {
    emitCalendar (name, val) {
      this.$emit('input', Object.assign({}, this.value, { [name]: val }))
    },
    getFieldValidation (name) {
      let validation = null
      this.formDesc.fieldsets.forEach(fieldset => {
        fieldset.fields.forEach((item) => {
          if (item.name === name) {
            validation = item.validation
          }
        })
      })
      return validation
    },
    getFieldValidationMsg (name) {
      let msg
      this.formDesc.fieldsets.forEach(fieldset => {
        fieldset.fields.forEach((item) => {
          if (item.name === name) {
            msg = item.validationMsg
          }
        })
      })
      return msg
    },
    inputTelChange (number, args) {
      let name
      for (const item in this.computedModel) {
        if (this.computedModel[item] === number) {
          name = item
        }
      }
      const msg = this.getFieldValidationMsg(name)
      this.resetFieldErrors(name)
      if (args.valid === false && number !== '') {
        this.setFieldErrors(name, msg)
      }
    },
    placePicker (e, name) {
      this.$store.dispatch('global/showModal', {
        action: 'gbm-place',
        modalProps: {
          placeId: this.computedModel[name]
        },
        modalConfirm: data => {
          e.target.value = data || ''
          e.target.dispatchEvent(new Event('input'))
        }
      })
    },
    resetErrors () {
      this.formDesc.nonFieldsErrors = null
      this.formDesc.fieldsets.forEach((fieldset) => {
        fieldset.fields.forEach((field) => {
          if (field.kind !== 'InputTel' && field.kind !== 'InputCard') {
            // Don't reset InputTel nor InputCard as they come with a custom validation
            field.errors = null
          }
        })
      })
    },
    resetFieldErrors (name) {
      this.formDesc.fieldsets.forEach((fieldset) => {
        fieldset.fields.forEach((field) => {
          if (field.name === name) {
            field.errors = null
          }
        })
      })
    },
    setFieldErrors (name, msg) {
      this.formDesc.fieldsets.forEach(fieldset => {
        fieldset.fields.forEach((item) => {
          if (item.name === name) {
            if (item.errors === null) {
              item.errors = []
            }
            item.errors.push(this.$t(msg))
          }
        })
      })
    },
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    validateField (name, newValue) {
      const validator = this.getFieldValidation(name)
      let test
      let msg
      if (validator) {
        if (validator.indexOf('validators.') > -1) {
          const [_, funcName] = validator.split('.')
          test = validators[funcName](newValue, this.$store)
          if (typeof test !== 'boolean') {
            [test, msg] = test
          }
        } else {
          const re = new RegExp(validator)
          if (newValue === null || newValue === undefined) {
            // nulls are evaluated as 'null' and thus, pass the validation
            // if the validation requires the string to be greater than 1,
            // even if there's no value
            newValue = ''
          }
          test = re.test(newValue, 'gm')
        }
        if (!test) {
          if (!msg) {
            msg = this.getFieldValidationMsg(name)
          }
          this.setFieldErrors(name, msg)
        }
      }
    }
  },
  watch: {
    computedModel: {
      handler: function (val, oldVal) {
        this.resetErrors()
        if (val && oldVal) {
          for (const item in val) {
            for (const oldItem in oldVal) {
              if (item === oldItem && oldVal[oldItem] !== null) {
                // if (this.computedModel[item])
                this.validateField(item, val[item])
              }
            }
          }
        }
        // console.log('WATCH COMP MODEL', val, oldVal)
        // this.resetErrors()
        // if (val && oldVal) {
        //   let noCheck = false
        //   for (const item in val) {
        //     this.formDesc.fieldsets.forEach(fieldset => {
        //       fieldset.fields.forEach(field => {
        //         if (field.name === item && field.kind === 'InputCard') {
        //           noCheck = true
        //         }
        //       })
        //     })
        //   }
        //   // console.log('>>>', this.formDesc item)
        //   if (noCheck === false) {
        //     for (const item in val) {
        //       for (const oldItem in oldVal) {
        //         if (item === oldItem && oldVal[oldItem] !== null) {
        //           // if (this.computedModel[item])
        //           this.validateField(item, val[item])
        //         }
        //       }
        //     }
        //   }
        // }
      },
      deep: true,
      immediate: true
    },
    sendData (val) {
      // For sending data with a button at the bottom of the form
      if (val === true) {
        this.resetErrors()
        this.submit()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

legend {
  font-size: 1.25rem;
  float: left;
  margin-bottom: 1rem;

  & + * {
    clear: both;
  }
}

input,
::v-deep .input-password input,
textarea,
select {
  @extend .gb-field;
}

label:not(.gb-btn):not(.radio-group label) {
  @extend .gb-label;
}

.radio-group {
  margin-bottom: 1rem;
}

label.gb-btn {
  line-height: 50px;
  width: 100%;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

p, ul {
  &.gb-az-errors {
    color: $secondary;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
    list-style-position: inside;
  }
}

p.gb-az-errors span:empty {
  display: block;
  margin-bottom: -1rem
}

.checkbox {
  @extend .gb-toggle;
  .label-text .helper-wrapper {
    margin-left: 6px;
  }
}

::v-deep .checkbox .helper-wrapper .icon {
  vertical-align: text-bottom;
}

.input-radio-options {
  display: flex;

  span {
    display: flex;
    flex-basis: content;
    flex-grow: 1;

    input {
      height: 30px;
      width: 30px;

      &:disabled + label {
        opacity: .35;
        cursor: not-allowed;
      }
    }

    label {
      width: calc(100% - 30px);
    }
  }
}

.select {
  @extend .gb-select;
}

.color {
  position: relative;

  input {
    margin: 0;
  }

  input[type="text"] {
    padding-left: 50px;
  }

  label {
    position: absolute;
    top: 50%;
    left: 8px;
    height: 34px;
    width: 34px;
    border-radius: 5px;
    transform: translateY(-50%);
    border: 1px solid $grey;

    &:hover {
      cursor: pointer;
    }
  }

  input[type="color"] {
    overflow: hidden;
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.vue-tel-input {
  @extend .gb-field;

  ::v-deep {
    input {
      color: $content-light;
    }

    .vti__dropdown:hover {
      background-color: transparent;

      .vti__dropdown-arrow {
        color: $secondary;
      }
    }
  }
}

.gb-details {
  font-size: 13px;
  font-weight: 500;
  color: $content-light;
  margin-bottom: 8px;
}

.hidden {
  display: none;
}

.invisible {
  height: 1px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1px;
}
</style>
