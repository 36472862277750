import { Commit, Dispatch } from 'vuex'
import { ScheduledExport, State } from '@/types/scheduledExports'

// initial state
const state: State = {
  current: null,
  list: null
}

// getters
const getters = {
  current (state: State): ScheduledExport | null {
    return state.current
  },
  list (state: State): Array<ScheduledExport> | null {
    return state.list
  }
}

// mutations
const mutations = {
  add (state: State, value: ScheduledExport): void {
    if (state.list) {
      state.list.push(value)
    } else {
      state.list = [value]
    }
  },
  current (state: State, value: ScheduledExport | null): void {
    state.current = value
  },
  list (state: State, value: Array<ScheduledExport> | null): void {
    state.list = value
  },
  remove (state: State, value: string): void {
    if (state.list) {
      const index = state.list.findIndex(item => item.id === value)
      state.list.splice(index, 1)
    }
  },
  update (state: State, value: ScheduledExport): void {
    if (state.list) {
      const index = state.list.findIndex(item => item.id === value.id)
      state.list.splice(index, 1, value)
    }
  }
}

// actions
const actions = {
  fetch ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<ScheduledExport> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REPORTS_DISPATCHER_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((scheduledExport: ScheduledExport) => {
          commit('current', scheduledExport)
          resolve(scheduledExport)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<ScheduledExport>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REPORTS_DISPATCHER_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((scheduledExports: Array<ScheduledExport>) => {
          commit('list', scheduledExports)
          resolve(scheduledExports)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  remove ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_REPORTS_DISPATCHER_URL}/accounts/${id}`)
        .then((data: any) => {
          commit('remove', id)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, scheduledExport: ScheduledExport): Promise<ScheduledExport> {
    const id = scheduledExport.id
    let url = `${process.env.VUE_APP_REPORTS_DISPATCHER_URL}/accounts`
    if (id) {
      url += `/${id}`
    }
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, scheduledExport)
        .then((response: Response) => response.json())
        .then((scheduledExport: ScheduledExport) => {
          commit(id ? 'update' : 'add', scheduledExport)
          resolve(scheduledExport)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
