<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="closeModal" />
      <h3 class="gb-title">
        {{ $t('settings.offers.choose_title')}}
      </h3>
    </div>
    <div class="modal-body">
      <offer-form :callback="closeModal"/>
    </div>
  </div>
</template>

<script>
import OfferForm from '@/components/OfferForm.vue'
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'

export default {
  extends: BaseModal,
  name: 'registeredAccountModal',
  components: {
    OfferForm,
    CloseModalButton
  },
  methods: {
    validate () {
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.modal-container {
  padding: 50px;
  width: 753px;

  .modal-header {
    padding: 0;

    .gb-title {
      margin-bottom: 50px;
    }
  }
}

.modal-body {
  text-align: left;
  padding: 0;
}

.modal-footer {
  padding: 0;
}
</style>
