<template>
    <div class="close-modal-button"
         v-on:click="closeModal">
      <slot>&#10005;</slot>
    </div>
</template>

<script type="text/javascript">
export default {
  name: 'CloseModalButton',
  props: {
    closeModal: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.close-modal-button {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 1.2rem;
  line-height: 1rem;
  color: $grey-dark;

  &:hover {
    cursor: pointer;
    color: $content;
  }
}
</style>
