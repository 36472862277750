import { Commit, Dispatch } from 'vuex'
import {
  ReviewsAccount,
  ReviewsGoogleLocation,
  ReviewsGoogleLocations,
  ReviewsGoogleOAuth,
  ReviewsState,
  ReviewsTrustpilotBusinessUnit
} from '@/types/reviews'

// initial state
const state: ReviewsState = {
  accounts: null
}

// getters
const getters = {
  account (state: ReviewsState): (id?: string) => Array<ReviewsAccount> | ReviewsAccount | null | undefined {
    return (id?: string): Array<ReviewsAccount> | ReviewsAccount | null | undefined => {
      return id
        ? (state.accounts || []).find(a => a.id === id)
        : state.accounts
    }
  },
  accounts (state: ReviewsState): Array<ReviewsAccount> | null {
    return state.accounts
  }
}

// mutations
const mutations = {
  setAccount (state: ReviewsState, data: ReviewsAccount): void {
    const index = (state.accounts || []).findIndex(a => a.id === data.id)

    if (state.accounts === null) {
      state.accounts = []
    }

    if (index >= 0) {
      state.accounts.splice(index, 1, data)
    } else {
      state.accounts.push(data)
    }
  },
  setAccounts (state: ReviewsState, data: Array<ReviewsAccount>): void {
    state.accounts = data
  }
}

// actions
const actions = {
  fetchAccount ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id?: string): Promise<ReviewsAccount> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REVIEWS_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: ReviewsAccount) => {
          commit('setAccount', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchAccounts ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<ReviewsAccount>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REVIEWS_URL}/accounts`)
        .then((response: Response) => response.json())
        .then(({ data }: { data: Array<ReviewsAccount> }) => {
          commit('setAccounts', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGoogleOAuths ({ dispatch }: { dispatch: Dispatch }): Promise<Array<ReviewsGoogleOAuth>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REVIEWS_URL}/oauth/google`)
        .then((response: Response) => response.json())
        .then((data: Array<ReviewsGoogleOAuth>) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGoogleLocations ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<Array<ReviewsGoogleLocations>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REVIEWS_URL}/oauth/google/${id}/locations`)
        .then((response: Response) => response.json())
        .then((data: Array<ReviewsGoogleLocations>) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchTrustpilotBusinessUnits ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<Array<ReviewsTrustpilotBusinessUnit>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_REVIEWS_URL}/accounts/${id}/trustpilot`)
        .then((response: Response) => response.json())
        .then((data: Array<ReviewsTrustpilotBusinessUnit>) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  // eslint-disable-next-line camelcase
  saveGoogleLocations (
    { dispatch }: { dispatch: Dispatch },
    data: {
      'external_id': string
      force: true
      'google_oauth': string
      id?: string
      location: string
      skill: string
    }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_REVIEWS_URL}/accounts/google`, data)
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  // eslint-disable-next-line camelcase
  saveTrustpilotBusinessUnit ({ dispatch }: { dispatch: Dispatch }, data: { id: string, business_unit_id: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_REVIEWS_URL}/accounts/${data.id}/trustpilot`, data)
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
