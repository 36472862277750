import Vue from 'vue'
import { Commit, Dispatch } from 'vuex'

import { GbCampaign, GbCampaignContact, GbCampaignVars, GbCampaignVarsFileReport, GbState } from '@/types/campaigns/campaigns'
import { Filters, Stats } from '@/types/statsCampaigns'

const state: GbState = {
  campaign: null,
  campaigns: null
}

const getters = {
  getCampaign (state: GbState): GbCampaign | null {
    return state.campaign
  },
  getCampaigns (state: GbState): Array<GbCampaign> | null {
    return state.campaigns
  }
}

const mutations = {
  setCampaign (state: GbState, data: GbCampaign): void {
    state.campaign = data
  },
  setCampaigns (state: GbState, data: Array<GbCampaign>): void {
    state.campaigns = data
  }
}

const actions = {
  exportCampaigns ({ dispatch }: { dispatch: Dispatch }, params: Record<string, any>): void {
    const filename = params.filename
    delete params.filename

    dispatch('global/showLoading', null, { root: true })

    return Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/stats/campaigns/export`, {
      params,
      responseType: 'blob'
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style.display = 'none'
        a.href = url
        a.download = (filename || 'campaigns-events') + '.csv'

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err: Error) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  cancelCampaign ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${id}/cancel`)
        .then(() => {
          return resolve()
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  deleteCampaign ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Vue.http.delete(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${id}`)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchCampaign ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<GbCampaign> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${id}`)
        .then((response: Response) => response.json())
        .then((campaign: GbCampaign) => {
          commit('setCampaign', campaign)
          return resolve(campaign)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchCampaignAudienceReport ({ dispatch }: { dispatch: Dispatch }, { contactList, id }: { contactList: Array<string>, id: string }): Promise<GbCampaignVars> {
    const data: {
      'campaign_id'?: string
      'contact_lists': Array<string>
    } = {
      contact_lists: contactList
    }

    if (id) {
      data.campaign_id = id
    }

    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/audience-report`, data)
        .then((response: Response) => response.json())
        .then((vars: GbCampaignVars) => {
          return resolve(vars)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchCampaignVarsFileReport ({ dispatch }: { dispatch: Dispatch }, { contactList, id }: { contactList: Array<string>, id: string }): Promise<GbCampaignVarsFileReport> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${id}/extradata/report`, {
        contact_lists: contactList || []
      })
        .then((response: Response) => response.json())
        .then((report: GbCampaignVarsFileReport) => {
          return resolve(report)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchCampaigns ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<GbCampaign>> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns`)
        .then((response: Response) => response.json())
        .then((campaigns: Array<GbCampaign>) => {
          commit('setCampaigns', campaigns)
          return resolve(campaigns)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchStats ({ dispatch }: { dispatch: Dispatch }, params: Filters): Promise<Array<Stats>> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/stats/campaigns`, { params })
        .then((response: Response) => response.json())
        .then((stats: Array<Stats>) => {
          return resolve(stats)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchStatsInterval ({ dispatch }: { dispatch: Dispatch }, params: Filters): Promise<Array<Stats>> {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_CAMPAIGNS_URL}/stats/campaigns/interval`, { params })
        .then((response: Response) => response.json())
        .then((stats: Array<Stats>) => {
          return resolve(stats)
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveCampaign ({ dispatch }: { dispatch: Dispatch }, data: GbCampaign): Promise<GbCampaign> {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns`
      if (data.id) {
        url += `/${data.id}`
      }
      Vue.http.post(url, data)
        .then((response: Response) => response.json())
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveCampaignVariablesFile ({ dispatch }: { dispatch: Dispatch }, { id, file }: { id: string, file: File }): Promise<any> {
    const formData = new FormData()
    formData.append('file', file)

    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${id}/extradata`, formData)
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  sendCampaign ({ dispatch }: { dispatch: Dispatch }, data: { id: string, contacts: Array<GbCampaignContact> }): Promise<void> {
    return new Promise((resolve, reject) => {
      Vue.http.post(`${process.env.VUE_APP_CAMPAIGNS_URL}/campaigns/${data.id}/send`, data)
        .then(resolve)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
