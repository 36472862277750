const state = {
  data: null
}

const getters = {
  data () {
    return state.data
  }
}

const actions = {
  logInfo ({ commit }, data) {
    if (data.payload.event === 'new_message') {
      console.log(`${data.payload.room_id}: nouveau message client`)
    } else if (data.payload.event === 'closed_discussion') {
      console.log(`${data.payload.room_id}: fermeture discussion`)
    }
  }
}

const mutations = {
  updateData (state, data) {
    state.data = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
