import i18n from '@/i18n'

const state = {
  offers: null,
  plan: null // plan to be used when the user needs to create a paymentmethod first
}

const getters = {
  offers () {
    return state.offers
  },
  plan () {
    return state.plan
  }
}

const mutations = {
  setOffers (state, data) {
    state.offers = data
  },
  setPlan (state, data) {
    state.plan = data
  }
}

const actions = {
  async fetchOffers ({ commit, rootGetters }) {
    const hasAppSumoOffer = rootGetters['user/hasAppSumoOffer']
    let url = `${process.env.VUE_APP_BACKEND_URL}/offers/`

    if (hasAppSumoOffer) {
      url += '?codes=appsumo_chayall_tier1&codes=appsumo_chayall_tier2&codes=appsumo_chayall_tier3&codes=appsumo_chayall_tier4'
    }

    return this._vm.$http.get(url)
      .then(response => response.json())
      .then((data) => {
        commit('setOffers', data)
      }).catch((err) => {
        commit('global/handleHttpError', err, { root: true })
      })
  },
  subscribe ({ commit }, params) {
    return this._vm.$http.post(`${process.env.VUE_APP_BACKEND_URL}/offers/subscribe/`, params)
      .catch((err) => {
        if (err.status === 400 && err.body.errors) {
          const errorMsg = i18n.t(`settings.payments.subscription.error.${err.body.errors[0]}`, [err.body.stripe_code])
          commit('global/updateError', errorMsg, { root: true })
        }
        commit('global/handleHttpError', err, { root: true })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
