// initial state
const state = {
  skill: null,
  skills: null
}

// getters
const getters = {
  skill () {
    return state.skill
  },
  skills () {
    return state.skills
  }
}

// mutations
const mutations = {
  setSkill (state, data) {
    state.skill = data
  },
  setSkills (state, data) {
    state.skills = data
  }
}

// actions
const actions = {
  fetchSkill ({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/skills/${uuid}`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkillAutoMsgs ({ commit }, skillId) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/skills/${skillId}/live-chat`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkillBots ({ commit }, uuid) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BB_HTTP}/bot/used-by-skill/${uuid}`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkillWidget ({ commit }, skillId) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/skills/${skillId}/widget`)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSkills ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/skills/`)
        .then(response => response.json())
        .then((data) => {
          commit('setSkills', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveSkill ({ dispatch }, data) {
    let url = `${process.env.VUE_APP_BACKEND_URL}/skills/`
    if (data.id) {
      url += data.id
    }

    return new Promise((resolve, reject) => {
      this._vm.$http.post(url, data)
        .then(response => response.json())
        .then((data) => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveSkillAutoMsgs (_, data) {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/skills/${data.skill}/live-chat`
      this._vm.$http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveSkillWidget (_, data) {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_BACKEND_URL}/skills/${data.skill}/widget`
      this._vm.$http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  updateSkillCalendar (_, skillId) {
    return new Promise((resolve, reject) => {
      this._vm.$http.post(`${process.env.VUE_APP_BACKEND_URL}/skills/${skillId}/calendar-update`)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
