const pageTitleNotification = () => {
  const config = {
    initTitle: document.title,
    currentTitle: null
  }

  const on = function (notificationText, intervalSpeed) {
    if (!window.GbCmsTitleNotificationInterval) {
      config.currentTitle = document.title
      window.GbCmsTitleNotificationInterval = window.setInterval(function () {
        document.title = (config.currentTitle === document.title)
          ? notificationText
          : config.currentTitle
      // eslint-disable-next-line
      }, (intervalSpeed) ? intervalSpeed : 1000)
    }
  }

  const off = function () {
    window.clearInterval(window.GbCmsTitleNotificationInterval)
    window.GbCmsTitleNotificationInterval = null
    document.title = config.initTitle
  }

  return {
    on: on,
    off: off
  }
}

export default pageTitleNotification
