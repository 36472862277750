<template>
  <span class="helper-wrapper">
    <span @mouseenter="helperShown = true" @mouseleave="helperShown = false"><icon class="icon" name="help_outline"/></span>
    <transition name="fade">
      <div class="helper" v-if="helperShown">
        <slot>
          {{ $t(content) }}
        </slot>
      </div>
    </transition>
  </span>
</template>

<script>
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  name: 'Helper',
  components: { Icon },
  props: {
    content: String
  },
  data () {
    return {
      helperShown: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.fade-enter-active,
.fade-leave-active {
  transition: all .25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.helper-wrapper {
  position: relative;

  @media (max-width: $break-large) {
    display: none;
  }

  .icon {
    color: $grey-dark;
    font-size: 20px;
    vertical-align: baseline;
    cursor: pointer;
    pointer-events: none;
  }

  .helper {
    position: absolute;
    bottom: 100%;
    margin-bottom: 10px;
    left: 0;
    width: 218px;
    background-color: white;
    border: 1px solid $grey-light;
    border-radius: 10px;
    border: 1px solid $grey-light;
    box-shadow: 0 20px 40px 0 rgba(66,97,158,0.2);
    padding: 14px 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    color: $content-light;
  }
}
</style>
