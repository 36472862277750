// List came from https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales
export default [{
  value: 'ar_AR',
  text: 'Arabic'
}, {
  value: 'cs_CZ',
  text: 'Czech'
}, {
  value: 'da_DK',
  text: 'Danish'
}, {
  value: 'de_DE',
  text: 'German'
}, {
  value: 'el_GR',
  text: 'Greek'
}, {
  value: 'en_GB',
  text: 'English'
}, {
  value: 'es_ES',
  text: 'Spanish (Spain)'
}, {
  value: 'es_LA',
  text: 'Spanish'
}, {
  value: 'fi_FI',
  text: 'Finnish'
}, {
  value: 'fr_FR',
  text: 'French'
}, {
  value: 'he_IL',
  text: 'Hebrew'
}, {
  value: 'hi_IN',
  text: 'Hindi'
}, {
  value: 'hu_HU',
  text: 'Hungarian'
}, {
  value: 'id_ID',
  text: 'Indonesian'
}, {
  value: 'it_IT',
  text: 'Italian'
}, {
  value: 'ja_JP',
  text: 'Japanese'
}, {
  value: 'ko_KR',
  text: 'Korean'
}, {
  value: 'nb_NO',
  text: 'Norwegian'
}, {
  value: 'nl_NL',
  text: 'Dutch'
}, {
  value: 'pl_PL',
  text: 'Polish'
}, {
  value: 'pt_BR',
  text: 'Portuguese (Brazil)'
}, {
  value: 'pt_PT',
  text: 'Portuguese (Portugal)'
}, {
  value: 'ro_RO',
  text: 'Romanian'
}, {
  value: 'ru_RU',
  text: 'Russian'
}, {
  value: 'sv_SE',
  text: 'Swedish'
}, {
  value: 'th_TH',
  text: 'Thai'
}, {
  value: 'tr_TR',
  text: 'Turkish'
}, {
  value: 'vi_VN',
  text: 'Vietnamese'
}, {
  value: 'zh_CN',
  text: 'Simplified Chinese (China)'
}, {
  value: 'zh_HK',
  text: 'Traditional Chinese (Hong Kong)'
}, {
  value: 'zh_TW',
  text: 'Traditional Chinese (Taiwan)'
}]
