<template>
  <div class="mrs">
    <div class="mrs-buttons">
      <button type="button" @click="manageSliders(false)">
        <md-icon name="remove"/>
      </button>
      <button type="button" @click="manageSliders(true)">
        <md-icon name="add"/>
      </button>
    </div>
    <vue-slider
      :clickable="false"
      :contained="true"
      :disabled="field.disabled"
      :dot-options="dotOptions"
      :drag-on-click="false"
      :enable-cross="false"
      :min-range="1"
      :process="process"
      :tooltip="tooltip"
      :value="value"
      @change="emitInput"
      @drag-end="() => dragging = false"
      @drag-start="() => dragging = true"
    >
      <template #process="{ start, end, style, index }" v-if="$scopedSlots['process']">
        <slot name="process" v-bind="{ start, end, style, index }"/>
      </template>
    </vue-slider>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import MdIcon from '@/components/MdIcon.vue'
import VueSlider from 'vue-slider-component'

@Component({
  components: {
    MdIcon,
    VueSlider
  }
})
export default class MultiRangeSlider extends Vue {
  @Prop({ default: () => ({}) }) field!: Record<string, any>
  @Prop({ default: undefined }) process?: () => Array<[number, number]> | undefined
  @Prop() tooltip?: string
  @Prop() value!: Array<number>

  private dragging = false

  get dotOptions (): Array<{ disabled: boolean }> {
    const options = new Array(this.value.length)

    for (let i = 0; i < options.length; i++) {
      options[i] = {
        disabled: i === 0 || i === options.length - 1
      }
    }

    return options
  }

  addSlider (): void {
    const value = this.value.concat([
      this.value[this.value.length - 2] +
      Math.round((this.value[this.value.length - 1] - this.value[this.value.length - 2]) / 2)
    ])
    this.$emit('input', value)
  }

  emitInput (value: Array<number>): void {
    this.$emit('input', value)
  }

  manageSliders (add: boolean): void {
    if (this.value.length > 2 || add) {
      if (add) {
        this.addSlider()
      } else {
        this.removeSlider()
      }
    }
  }

  removeSlider (): void {
    const value = this.value.concat([])
    value.splice(value.length - 2, 1)
    this.$emit('input', value)
  }

  @Watch('dragging')
  onDrag (val: boolean): void {
    this.$emit('drag', val)
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@/assets/scss/global.scss";

$themeColor: $primary;

:deep() {
  @import '~vue-slider-component/lib/theme/default.scss';
}

.mrs {
  margin-bottom: 1rem;
}

.mrs-buttons {
  display: flex;
  justify-content: flex-end;

  button {
    background-color: transparent;
    border: 0;
    color: $primary;
    outline: none;
    padding: 0;

    &:active {
      color: $content;
    }

    * {
      font-size: 1rem;
    }
  }
}
</style>
