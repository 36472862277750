import { render, staticRenderFns } from "./Checklist.vue?vue&type=template&id=4db35f6c&scoped=true"
import script from "./Checklist.vue?vue&type=script&lang=ts"
export * from "./Checklist.vue?vue&type=script&lang=ts"
import style0 from "./Checklist.vue?vue&type=style&index=0&id=4db35f6c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4db35f6c",
  null
  
)

export default component.exports