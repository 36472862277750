import Vue from 'vue'
import VueResource from 'vue-resource'
import locales from '@/utils/meta-locales.ts'

Vue.use(VueResource)

// initial state
const state = {
  channels: null,
  instagramIcebreakers: null,
  lastSave: null,
  // List came from https://developers.facebook.com/docs/messenger-platform/messenger-profile/supported-locales
  locales: locales,
  order: [
    'messenger',
    'googlebm',
    'whatsapp',
    'abc',
    'web_chat',
    'twitter_dm',
    'telegram',
    'instagram_dm',
    'facebook_comments',
    'twitter_comments',
    'instagram_comments',
    'rcs'
  ],
  selectedSkill: null
}

// getters
const getters = {
  channels (state, _getters, _rootState, rootGetters) {
    const channelsObj = JSON.parse(JSON.stringify(state.channels))

    if (channelsObj !== null) {
      const filterChannels = (codes) => {
        channelsObj.channels = channelsObj.channels.filter(c => !codes.includes(c.code))
        channelsObj.customer_channels = channelsObj.customer_channels.filter(c => !codes.includes(c.name))
      }

      if (rootGetters['global/settings'].saas === false) {
        channelsObj.channels.push({
          code: 'api',
          kind: 'bot'
        })
      }

      if (rootGetters['global/settings'].saas === true) {
        filterChannels(['rcs', 'sms', 'twitter_dm', 'twitter_comments'])
      }
    }

    return channelsObj
  },
  instagramIcebreakers (state) {
    return state.instagramIcebreakers
  },
  lastSave () {
    return state.lastSave
  },
  locales (state) {
    return state.locales
  },
  order (state) {
    return state.order
  },
  selectedSkill () {
    return state.selectedSkill
  }
}

// actions
const actions = {
  deleteInstagramIcebreakers: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_MISC_URL}/instagram/ice-breakers/${data.id}`

      Vue.http.delete(url)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  disableChannel: (_, data) => {
    return new Promise((resolve, reject) => {
      let url = `${process.env.VUE_APP_BACKEND_URL}/channels/?code=${data.code}`

      if (data.id) {
        url = `${process.env.VUE_APP_BACKEND_URL}/channels/${data.id}`
      }

      Vue.http.delete(url)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  fetchInstagramIcebreakers: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_MISC_URL}/instagram/ice-breakers/${data.id}`

      Vue.http.get(url)
        .then(response => response.json()).then((data) => {
          commit('setInstagramIcebreakers', data)
          resolve(true)
        })
        .catch((err) => {
          // If 404, it's not an error, just that there's no icebreaker currently
          if (err.status === 404) {
            const icebreakers = {
              channel: data.id,
              customer: data.customer,
              locales: [{
                buttons: [{
                  text: '',
                  to_tree: ''
                }],
                locale: 'default'
              }]
            }
            commit('setInstagramIcebreakers', icebreakers)
            resolve(true)
          } else {
            reject(err)
          }
        })
    })
  },
  fetchChannels: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/channels/`)
        .then(response => response.json())
        .then((data) => {
          commit('setChannels', data)
          resolve(data)
        }).catch((err) => {
          commit('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveChannel: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      const autoSave = data.autoSave
      let url = `${process.env.VUE_APP_BACKEND_URL}/channels/`

      if (data.id) {
        url += data.id
      }

      delete data.autoSave

      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          if (autoSave !== true) {
            commit('updateLastSave')
          }
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  saveInstagramIcebreakers: (_, data) => {
    return new Promise((resolve, reject) => {
      const url = `${process.env.VUE_APP_MISC_URL}/instagram/ice-breakers/${data.id}`

      delete data.id
      Vue.http.post(url, data)
        .then(response => response.json()).then((data) => {
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  setChannels (state, data) {
    state.channels = data
  },
  setInstagramIcebreakers (state, data) {
    state.instagramIcebreakers = data
  },
  setSelectedSkill (state, data) {
    state.selectedSkill = data
  },
  updateLastSave (state) {
    state.lastSave = new Date()
  },
  updateChannel (state, data) {
    const index = state.channels.customer_channels.findIndex(cc => cc.id === data.id)
    state.channels.customer_channels.splice(index, 1, data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
