export default {
  computed: {
    isChatView () {
      return this.$route.matched.some(route => route.name === 'gb-agent') &&
             (
               this.$route.params.uuid !== undefined ||
               this.$route.params.thread_id !== undefined ||
               this.$route.params.review_id !== undefined
             )
    }
  }
}
