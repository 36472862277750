<template>
  <div class="day">
    <label class="checkbox">
      <input type="checkbox" :checked="!value.closed" @input="emitClosed">
      <icon class="icon" name="done"/>
      <span>{{ item }}</span>
    </label>

    <div>
      <ul>
        <li v-if="value.closed === true" class="closed">
          {{ $t('calendar.closed') }}
        </li>
        <li v-else :key="index" v-for="(time, index) in value.datetimes">
          <div>
            <vue-timepicker class="timepicker" :placeholder="$t('timepicker.placeholder')"
              :hour-label="$t('timepicker.hour')" :minute-label="$t('timepicker.minute')"
              close-on-complete auto-scroll
              :minute-interval="15"
              :value="time.start_dt"
              :class="{err: time.start_dt.HH === '' && time.start_dt.mm === '' && time.end_dt.HH !== '' && time.end_dt.mm !== ''}"
              @input="emitTime(index, 'start_dt', $event)"
              @open="emitUserAction"
              :ref="`ts-start_dt-${index}`"/>
            <vue-timepicker class="timepicker" :placeholder="$t('timepicker.placeholder')"
              :hour-label="$t('timepicker.hour')" :minute-label="$t('timepicker.minute')"
              close-on-complete auto-scroll
              :minute-interval="15"
              :hour-range="getHourRange(index)"
              :minute-range="getMinuteRange(index)"
              :value="time.end_dt"
              :class="{err: time.end_dt.HH === '' && time.end_dt.mm === '' && time.start_dt.HH !== '' && time.start_dt.mm !== ''}"
              @input="emitTime(index, 'end_dt', $event)"
              @open="emitUserAction"
              :ref="`ts-end_dt-${index}`"/>
          </div>
          <button type="button" :class="{hidden: value.datetimes.length === 1, alt: maxSlot && maxSlot > 2}" class="gb-btn gb-btn-secondary option" @click="remove(index)">
            <icon class="icon" name="remove"/>
          </button>
          <button type="button" :class="{hidden: value.datetimes.length === maxSlot, alt: maxSlot && maxSlot > 2}" class="gb-btn gb-btn-secondary option" @click="add(index)">
            <icon class="icon" name="add"/>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Dayjs from 'vue-dayjs'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

Vue.use(Dayjs)

export default {
  name: 'CalendarRow',
  components: { Icon, VueTimepicker },
  props: {
    day: {
      type: String,
      required: true
    },
    maxSlot: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    item () {
      if (!isNaN(this.$dayjs(this.day).$d)) {
        const format = this.$t('date.dateFormat')
        return this.$dayjs(this.day).format(format)
      }
      return this.$t(`calendar.${this.day.toLowerCase()}`)
    }
  },
  methods: {
    add (index) {
      const datetimes = (this.value.datetimes || []).concat([])

      datetimes.splice(index + 1, 0, {
        start_dt: {
          HH: '',
          mm: ''
        },
        end_dt: {
          HH: '',
          mm: ''
        }
      })

      this.emitInput('datetimes', datetimes)
      this.emitUserAction()
    },
    checkDates (time, name, index, datetimes) {
      if (!this.checkEnd(time)) {
        this.emitTime(index, 'end_dt', {
          HH: '',
          mm: ''
        })
      }

      if (!this.checkInterval(time, index, datetimes)) {
        this.emitTime(index, name, {
          HH: '',
          mm: ''
        })
      }
    },
    checkEnd (time) {
      if (time.start_dt && time.start_dt.HH && time.start_dt.mm && time.end_dt && time.end_dt.HH && time.end_dt.mm) {
        const dates = this.getDates(time)
        return dates[0].getTime() < dates[1].getTime()
      }
      return true
    },
    checkInterval (time, index, datetimes) {
      if ((time.start_dt && time.start_dt.HH && time.start_dt.mm) || (time.end_dt && time.end_dt.HH && time.end_dt.mm)) {
        const dates = this.getDates(time)

        return datetimes.every((t, i) => {
          if (i !== index && ((t.start_dt && t.start_dt.HH && t.start_dt.mm) || (t.end_dt && t.end_dt.HH && t.end_dt.mm))) {
            const ds = this.getDates(t)

            return !( // Check if a start or end date is inner another timeslots
              ((dates[0] && ds[0] && ds[1] && (dates[0].getTime() >= ds[0].getTime() && dates[0].getTime() <= ds[1].getTime())) ||
              (dates[1] && ds[0] && ds[1] && (dates[1].getTime() >= ds[0].getTime() && dates[1].getTime() <= ds[1].getTime()))) ||
              ((ds[0] && dates[0] && dates[1] && (ds[0].getTime() >= dates[0].getTime() && ds[0].getTime() <= dates[1].getTime())) ||
              (ds[1] && dates[0] && dates[1] && (ds[1].getTime() >= dates[0].getTime() && ds[1].getTime() <= dates[1].getTime())))
            )
          }
          return true
        })
      }
      return true
    },
    emitClosed (e) {
      this.emitInput('closed', !e.target.checked)
      this.emitUserAction()
    },
    emitInput (key, val) {
      const value = Object.assign(
        {},
        this.value,
        key ? { [key]: val } : val
      )
      this.$emit('input', value)
    },
    emitTime (index, name, val) {
      if (!!val.HH === !!val.mm) {
        const datetimes = (this.value.datetimes || []).concat([])

        datetimes[index][name] = val

        this.emitInput('datetimes', datetimes)
        this.checkDates(datetimes[index], name, index, datetimes)
      }
    },
    emitUserAction () {
      this.$emit('userAction', true)
    },
    getDates (time) {
      let start = null
      let end = null

      if (time.start_dt && time.start_dt.HH && time.start_dt.mm) {
        start = new Date()
        start.setHours(parseInt(time.start_dt.HH) || 0)
        start.setMinutes(parseInt(time.start_dt.mm) || 0)
      }

      if (time.end_dt && time.end_dt.HH && time.end_dt.mm) {
        end = new Date()
        end.setHours(parseInt(time.end_dt.HH) || 0)
        end.setMinutes(parseInt(time.end_dt.mm) || 0)
      }

      return [start, end]
    },
    getHourRange (index) {
      const value = this.$refs &&
        this.$refs[`ts-start_dt-${index}`] &&
        this.$refs[`ts-start_dt-${index}`][0]
      let start = '00'

      if (value && value.hour) {
        start = value.hour
        if (parseInt(value.minute) === 45) {
          start = parseInt(start) + 1
        }
      }

      return [[start, '23']]
    },
    getMinuteRange (index) {
      const valueStart = this.$refs &&
        this.$refs[`ts-start_dt-${index}`] &&
        this.$refs[`ts-start_dt-${index}`][0]
      const valueEnd = this.$refs &&
        this.$refs[`ts-end_dt-${index}`] &&
        this.$refs[`ts-end_dt-${index}`][0]
      let start = '00'

      if (valueStart && valueEnd &&
        parseInt(valueStart.hour) === parseInt(valueEnd.hour)) {
        start = parseInt(valueStart.minute || 0) + 1
      }

      return [[start, '45']]
    },
    remove (index) {
      const datetimes = (this.value.datetimes || []).concat([])

      datetimes.splice(index, 1)

      this.emitInput('datetimes', datetimes)
      this.emitUserAction()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.checkbox {
  display: block;
  font-weight: 500;
  color: $content;
  line-height: 40px;
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 2px solid $grey-dark;
    border-radius: 7px;
    margin-right: 16px;
    transition: all .15s;

    ::v-deep * {
      color: white;
      opacity: 0;
    }
  }

  input {
    display: none;

    &:checked + .icon {
      background-color: $primary;
      border-color: $primary;

      ::v-deep * {
        opacity: 1;
      }
    }
  }
}

.day {
  display: flex;
  justify-content: space-between;

  & > label {
    width: 120px;

    & + div {
      flex-grow: 1;
      text-align: right;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      clear: both;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  .option {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 5px;
    margin: 0 0 10px 10px !important;
    float: right;
    color: $primary;
    background-color: $primary-light;
    user-select: none;
    min-width: auto;
    vertical-align: middle;

    &:hover {
      cursor: pointer;
      background-color: $secondary-light;
      color: $secondary;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;

      &:not(.alt) {
        display: none;
      }
    }

    & > * {
      vertical-align: baseline;
    }
  }
}

.closed {
  font-weight: 500;
  line-height: 40px;
  color: $primary;
}

::v-deep .timepicker {
  margin-left: 10px;
  margin-bottom: 10px;

  input {
    @extend .gb-field;
    background-color: white !important;
    height: 40px !important;
    margin-bottom: 0 !important;
    text-align: center;

    &:hover {
      cursor: default !important;
    }
  }

  &.err input {
    box-shadow: inset 0 0 0 1.5px $secondary !important;
  }

  .clear-btn {
    height: 44px;
  }

  .active {
    background: $primary !important;
  }

  .dropdown {
    top: 40px;
  }
}
</style>
