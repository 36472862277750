import contactReasons from './modules/contactReasons'
import easiware from './modules/easiware'
import crm from './modules/crm'
import global from './modules/global'
import mercure from './modules/mercure'
import message from './modules/message'
import params from './modules/params'
import rooms from './modules/rooms'
import search from './modules/search'
import statistics from './modules/statistics'
import user from './modules/user'

export default {
  namespaced: true,
  modules: {
    contactReasons,
    easiware,
    crm,
    global,
    mercure,
    message,
    params,
    rooms,
    search,
    statistics,
    user
  }
}
