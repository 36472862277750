import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import $settings from '@/settings'

import dayjs from 'dayjs'

function loadView (view) {
  return () => import(
    /* webpackChunkName: "view-[request]" */
    `@/views/${view}.vue`
  )
}

function loadGBAView (view) {
  return () => import(
    /* webpackChunkName: "view-gba-[request]" */
    `@gba/views/${view}.vue`
  )
}

const HAS_SUBACCOUNTS = () => store.getters['user/hasSubAccounts'] ? true : !$settings.saas

const routes = [
  {
    path: '/',
    redirect: { name: 'dashboard' }
  },
  {
    path: '/gb-agent',
    name: 'gb-agent',
    component: loadView('Home'),
    meta: {
      requiresAuth: true,
      permission: 'agent',
      roles: ['agent', 'admin', 'manager', 'owner']
    },
    redirect: { name: 'GBA-dashboard' },
    children: [
      {
        path: 'im',
        name: 'GBA-dashboard',
        component: loadGBAView('Dashboard'),
        meta: { requiresAuth: true, sidebar: true, abstract: true }
      }, {
        path: 'im/:uuid',
        name: 'GBA-room',
        component: loadGBAView('Room'),
        meta: { requiresAuth: true, sidebar: true, abstract: true }
      }, {
        path: 'im/:uuid/event/:event_uuid',
        name: 'GBA-room-history',
        component: loadGBAView('Room'),
        meta: { requiresAuth: true, sidebar: true, abstract: true }
      }, {
        path: 'threads/:account_id?/:thread_id?',
        name: 'GBA-threads',
        component: () => import(
          /* webpackChunkName: "view-[request]" */
          /* webpackMode: "lazy" */
          /* webpackPrefetch: 0 */
          /* webpackPreload: 0 */
          '@gba-custom/Threads/Threads.vue'
        ),
        meta: { requiresAuth: true, abstract: true }
      }, {
        path: 'reviews/:review_id?',
        name: 'GBA-reviews',
        component: () => import(
          /* webpackChunkName: "view-[request]" */
          /* webpackMode: "lazy" */
          /* webpackPrefetch: 0 */
          /* webpackPreload: 0 */
          '@gba-custom/Reviews/Reviews.vue'
        ),
        meta: { requiresAuth: true, abstract: true }
      }, {
        path: 'external-view/:id',
        name: 'GBA-external-view',
        component: () => import(
          /* webpackChunkName: "view-[request]" */
          /* webpackMode: "lazy" */
          /* webpackPrefetch: 0 */
          /* webpackPreload: 0 */
          '@gba-custom/ExternalView.vue'
        ),
        meta: { requiresAuth: true, abstract: true },
        props: true
      }
    ]
  },
  {
    path: '/bot-builder',
    name: 'bot-builder',
    component: loadView('BotBuilder'),
    meta: {
      customRoute: 'messaging',
      requiresAuth: true,
      permission: 'manager'
    },
    children: [
      {
        path: 'skills/:skillId/bots/:botId/channel/:channelId/trees/',
        name: 'BB-bot',
        component: loadView('BotBuilder'),
        meta: { requiresAuth: true, abstract: true },
        props: route => ({
          fromURL: route
        })
      },
      {
        path: 'skills/:skillId/bots/:botId/channels/:channelId/trees/:treeUuid/',
        name: 'BB-tree',
        component: loadView('BotBuilder'),
        meta: { requiresAuth: true, abstract: true }
      }
    ]
  },
  {
    path: '/botgroups',
    name: 'botgroups',
    component: loadView('botgroups/Container'),
    meta: {
      permission: 'manager',
      requiresAuth: true,
      saas: false
    },
    redirect: { name: 'botgroups-list' },
    children: [
      {
        path: '',
        name: 'botgroups-list',
        component: loadView('botgroups/list/Wrapper'),
        meta: { requiresAuth: true, abstract: true },
        children: [
          {
            path: 'messaging',
            name: 'botgroups-messaging',
            component: loadView('botgroups/list/Messaging'),
            meta: { requiresAuth: true, abstract: true }
          }, {
            path: 'webivr',
            name: 'botgroups-webivr',
            component: loadView('botgroups/list/Webivr'),
            meta: { requiresAuth: true, abstract: true }
          }
        ]
      },
      {
        path: 'messaging/form',
        name: 'botgroup-add',
        component: loadView('botgroups/form/Messaging'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'messaging/form/:uuid',
        props: true,
        name: 'botgroup-edit',
        component: loadView('botgroups/form/Messaging'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'messaging/form/:uuid/customization',
        props: true,
        name: 'botgroup-customization',
        component: loadView('botgroups/form/Messaging'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'messaging/form-messenger-menu',
        name: 'botgroup-add-messenger-menu',
        component: loadView('botgroups/form/MessagingMessengerMenu'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'messaging/form-messenger-menu/:uuid',
        props: true,
        name: 'botgroup-edit-messenger-menu',
        component: loadView('botgroups/form/MessagingMessengerMenu'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'webivr/form',
        name: 'botgroup-webivr-add',
        component: loadView('botgroups/form/Webivr'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'webivr/form/:accountId/:botgroupId',
        props: true,
        name: 'botgroup-webivr-edit',
        component: loadView('botgroups/form/Webivr'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: 'webivr/form/:accountId/:botgroupId/navbar',
        props: true,
        name: 'botgroup-webivr-navbar',
        component: loadView('botgroups/form/Webivr'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      }

    ]
  },
  {
    path: '/webivr',
    name: 'webivr',
    component: loadView('webIVR/List'),
    meta: {
      permission: 'manager',
      requiresAuth: true,
      saas: false
    },
    children: [
      {
        path: 'add',
        name: 'webivr-add',
        component: loadView('webIVR/Form'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        }
      },
      {
        path: ':uuid',
        name: 'webivr-edit',
        component: loadView('webIVR/Form'),
        meta: {
          permission: 'admin',
          requiresAuth: true
        },
        props: true
      }
    ]
  },
  {
    path: '/webivr-builder/:versionId/:accountId/:treeId?/:nodeId?',
    name: 'webivr-builder',
    component: loadView('webIVR/Builder'),
    meta: {
      customRoute: 'webivr',
      requiresAuth: true,
      permission: 'manager',
      saas: false
    },
    children: [
      {
        path: 'faqs',
        name: 'webivr-faqs',
        component: loadView('webIVR/faqs/List'),
        meta: {
          abstract: true,
          requiresAuth: true
        },
        children: [
          {
            path: 'add',
            name: 'webivr-faqs-add',
            component: loadView('webIVR/faqs/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: ':uuid',
            name: 'webivr-faqs-edit',
            component: loadView('webIVR/faqs/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            },
            props: true
          }
        ]
      },
      {
        path: 'forms',
        name: 'webivr-forms',
        component: loadView('webIVR/forms/List'),
        meta: {
          abstract: true,
          requiresAuth: true
        },
        children: [
          {
            path: 'add',
            name: 'webivr-forms-add',
            component: loadView('webIVR/forms/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: ':uuid',
            name: 'webivr-forms-edit',
            component: loadView('webIVR/forms/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            },
            props: true
          }
        ]
      },
      {
        path: 'templates',
        name: 'webivr-templates',
        component: loadView('webIVR/templates/List'),
        meta: {
          abstract: true,
          requiresAuth: true
        },
        children: [
          {
            path: 'add',
            name: 'webivr-templates-add',
            component: loadView('webIVR/templates/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: ':uuid',
            name: 'webivr-templates-edit',
            component: loadView('webIVR/templates/Form'),
            meta: {
              abstract: true,
              requiresAuth: true
            },
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '/account',
    name: 'account',
    component: loadView('AccountSettings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/apple-business-chat',
    name: 'apple-business-chat',
    component: loadView('AppleBusinessChat'),
    meta: { requiresAuth: true }
  },
  {
    path: '/messaging/logged',
    name: 'messaging-logged',
    component: loadView('MessagingLogged'),
    meta: { requiresAuth: true }
  },
  {
    path: '/messaging/:skill?/:channel?',
    name: 'messaging',
    component: loadView($settings.saas ? 'Messaging' : 'keyAccount/Messaging'),
    meta: {
      requiresAuth: true,
      permission: 'manager'
    },
    props: (route) => ({ registered: route.query.registered }),
    children: [
      {
        path: 'add',
        name: 'messaging-add',
        component: loadView('keyAccount/MessagingAdd'),
        meta: {
          permission: 'admin',
          requiresAuth: true,
          saas: false
        },
        children: [
          {
            path: ':code',
            props (route) {
              return {
                ...route.params,
                expert: true
              }
            },
            name: 'messaging-add-form',
            component: loadView('MessagingEdit'),
            meta: { requiresAuth: true, saas: false }
          }
        ]
      },
      {
        path: 'edit/:code/:uuid?',
        props (route) {
          return {
            ...route.params,
            expert: true,
            uuid: route.params.uuid || route.query.account
          }
        },
        name: 'messaging-edit',
        component: loadView('MessagingEdit'),
        meta: {
          requiresAuth: true,
          saas: false
        }
      }
    ]
  },
  {
    path: '/third-parties',
    name: 'third-parties',
    component: loadView('thirdParties/Wrapper'),
    redirect: { name: 'third-parties-list' },
    meta: {
      permission: 'admin',
      requiresAuth: true,
      saas: false
    },
    children: [
      {
        path: '',
        name: 'third-parties-list',
        component: loadView('thirdParties/List'),
        meta: {
          abstract: true,
          requiresAuth: true
        }
      },
      {
        path: 'add',
        name: 'third-parties-add',
        component: loadView('thirdParties/Add'),
        meta: { requiresAuth: true }
      },
      {
        path: 'add/:id',
        props: (route) => ({
          ...route.params,
          isCodeId: true
        }),
        name: 'third-parties-add-form',
        component: loadView('thirdParties/Form'),
        meta: { requiresAuth: true }
      },
      {
        path: 'edit/:id',
        props: (route) => ({
          ...route.params,
          isCodeId: false
        }),
        name: 'third-parties-edit',
        component: loadView('thirdParties/Form'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/email/validate/:uid/:token',
    name: 'email-validate',
    component: loadView('EmailValidate')
  },
  {
    path: '/invitation/:uid/:token',
    name: 'invitation',
    component: loadView('Invitation')
  },
  {
    path: '/user-not-configured',
    name: 'user-not-configured',
    component: loadView('UserNotConfigured')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: loadView($settings.saas ? 'Dashboard' : 'keyAccount/Dashboard'),
    meta: {
      requiresAuth: true,
      permission: 'stats_manager',
      roles: ['stats_manager', 'manager', 'admin', 'owner']
    },
    children: [
      {
        path: 'bot',
        name: 'dashboard-bot',
        component: loadView('keyAccount/DashboardBot'),
        meta: { requiresAuth: true, abstract: true }
      },
      {
        path: 'comments',
        name: 'dashboard-comments',
        component: loadView('keyAccount/DashboardComments'),
        meta: { requiresAuth: true, abstract: true }
      },
      {
        path: 'reviews',
        name: 'dashboard-reviews',
        component: loadView('keyAccount/dashboardReviews/Main'),
        meta: { requiresAuth: true, abstract: true }
      },
      {
        path: 'webivr',
        name: 'dashboard-webivr',
        component: loadView('keyAccount/dashboardWebivr/Main'),
        meta: { requiresAuth: true, abstract: true }
      },
      {
        path: 'campaigns',
        name: 'dashboard-campaigns',
        component: loadView('keyAccount/dashboardCampaigns/Main'),
        meta: { requiresAuth: true, abstract: true }
      }
    ]
  },
  {
    path: '/m/',
    name: 'masquerade',
    component: loadView('Masquerade')
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('Register'),
    alias: '/creation-compte',
    meta: { saas: true }
  },
  {
    path: '/register/appsumo',
    name: 'register-appsumo',
    component: loadView('RegisterAppsumo'),
    meta: { saas: true }
  },
  { // TO REMOVE
    path: '/test/appsumo',
    name: 'test-appsumo',
    component: loadView('TestAppsumo'),
    meta: { saas: true }
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: loadView('PasswordReset')
  },
  {
    path: '/password/:uid/:token',
    name: 'password-change',
    component: loadView('PasswordChange')
  },
  {
    path: '/ai',
    name: 'ai',
    component: loadView('ai/Wrapper'),
    meta: {
      requiresAuth: true,
      saas: false
    },
    children: [
      {
        path: 'list',
        name: 'ai-list',
        component: loadView('ai/List'),
        meta: { requiresAuth: true, abstract: true }
      },
      {
        path: 'knowledge-bases',
        name: 'ai-knowledge-bases',
        component: loadView('ai/knowledgeBases/List'),
        meta: { requiresAuth: true, abstract: true }
      }
    ]
  },
  {
    path: '/ai/dialogflow',
    component: loadView('ai/dialogflow/Wrapper'),
    meta: { requiresAuth: true, abstract: true, customRoute: 'ai' },
    children: [
      {
        path: 'add',
        name: 'ai-dialogflow-add',
        component: loadView('ai/dialogflow/Form'),
        meta: { requiresAuth: true }
      },
      {
        path: 'edit/:id',
        name: 'ai-dialogflow-edit',
        component: loadView('ai/dialogflow/Form'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'import/:id',
        name: 'ai-dialogflow-import',
        component: loadView('ai/dialogflow/Import'),
        meta: { requiresAuth: true },
        props: true
      }
    ]
  },
  {
    path: '/ai/ravana',
    component: loadView('ai/ravana/Wrapper'),
    meta: { requiresAuth: true, abstract: true, customRoute: 'ai' },
    children: [
      {
        path: 'add',
        name: 'ai-ravana-add',
        component: loadView('ai/ravana/Form'),
        meta: { requiresAuth: true }
      },
      {
        path: 'tasks/:id',
        name: 'ai-ravana-tasks',
        component: loadView('ai/ravana/Tasks'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'edit/:id',
        name: 'ai-ravana-edit',
        component: loadView('ai/ravana/Form'),
        meta: { requiresAuth: true },
        props: true
      }
    ]
  },
  {
    path: '/ai/smartcoaching',
    component: loadView('ai/smartcoaching/Wrapper'),
    meta: { requiresAuth: true, abstract: true, customRoute: 'ai' },
    children: [
      {
        path: 'add',
        name: 'ai-smartcoaching-add',
        component: loadView('ai/smartcoaching/Form'),
        meta: { requiresAuth: true }
      },
      {
        path: 'edit/:id',
        name: 'ai-smartcoaching-edit',
        component: loadView('ai/smartcoaching/Form'),
        meta: { requiresAuth: true },
        props: true
      }
    ]
  },
  {
    path: '/ai/knowledge-bases',
    component: loadView('ai/knowledgeBases/Wrapper'),
    meta: { requiresAuth: true, abstract: true, customRoute: 'ai' },
    children: [
      {
        path: 'add',
        name: 'ai-knowledge-bases-add',
        component: loadView('ai/knowledgeBases/Form'),
        meta: { requiresAuth: true }
      },
      {
        path: 'edit/:id',
        name: 'ai-knowledge-bases-edit',
        component: loadView('ai/knowledgeBases/Form'),
        meta: { requiresAuth: true },
        props: true
      },
      {
        path: 'import/:id',
        name: 'ai-knowledge-bases-import',
        component: loadView('ai/knowledgeBases/Import'),
        meta: { requiresAuth: true },
        props: true
      }
    ]
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: loadView('campaigns/Campaigns'),
    meta: {
      permission: 'manager',
      requiresAuth: true,
      saas: false
    },
    children: [
      {
        path: 'templates',
        name: 'campaigns-templates',
        component: loadView('campaigns/templates/Wrapper'),
        redirect: { name: 'campaigns-templates-list' },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'list',
            name: 'campaigns-templates-list',
            component: loadView('campaigns/templates/List'),
            meta: { requiresAuth: true, abstract: true }
          },
          {
            path: 'add',
            name: 'campaigns-templates-add',
            component: loadView('campaigns/templates/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: ':kind/:uuid',
            name: 'campaigns-templates-edit',
            component: loadView('campaigns/templates/Form'),
            meta: { requiresAuth: true },
            props: true
          }
        ]
      },
      {
        path: 'contacts',
        name: 'campaigns-contacts',
        component: loadView('campaigns/contacts/Wrapper'),
        redirect: { name: 'campaigns-contacts-list' },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'list',
            name: 'campaigns-contacts-list',
            component: loadView('campaigns/contacts/List'),
            meta: { requiresAuth: true, abstract: true }
          },
          {
            path: 'add',
            name: 'campaigns-contacts-add',
            component: loadView('campaigns/contacts/Form'),
            // component: loadView('campaigns/contacts/Add'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit/:id',
            name: 'campaigns-contacts-edit',
            component: loadView('campaigns/contacts/Form'),
            // component: loadView('campaigns/contacts/Edit'),
            props: true,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'list',
        name: 'campaigns-wrapper',
        component: loadView('campaigns/campaigns/Wrapper'),
        redirect: { name: 'campaigns-list' },
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '/',
            name: 'campaigns-list',
            component: loadView('campaigns/campaigns/List'),
            meta: {
              requiresAuth: true,
              abstract: true
            }
          },
          {
            path: 'add',
            name: 'campaigns-add',
            component: loadView('campaigns/campaigns/Form'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: ':id',
            name: 'campaigns-edit',
            component: loadView('campaigns/campaigns/Form'),
            props: true,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'send/:id',
            name: 'campaigns-send',
            component: loadView('campaigns/campaigns/Send'),
            props: true,
            meta: {
              requiresAuth: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/settings',
    name: 'settings',
    component: loadView('Settings'),
    meta: { requiresAuth: true },
    redirect: { name: 'settings-account' },
    children: [
      {
        path: 'account',
        name: 'settings-account',
        component: loadView('AccountSettings'),
        meta: {
          permission: 'agent',
          requiresAuth: true,
          sidebar: true,
          notRequiresEmailConfirm: true
        }
      },
      {
        path: 'account/delete',
        name: 'settings-account-delete',
        component: loadView('AccountDeleteSettings'),
        meta: { requiresAuth: true, sidebar: true }
      },
      {
        path: 'agents',
        name: 'settings-agents',
        component: loadView('AgentsSettings'),
        meta: {
          requiresAuth: true,
          sidebar: true,
          permission: 'manager'
        },
        children: [
          {
            path: 'add',
            name: 'settings-agent-add',
            component: loadView('AgentSettings'),
            meta: {
              requiresAuth: true, sidebar: true
            }
          },
          {
            path: ':uuid',
            name: 'settings-agent-edit',
            component: loadView('AgentSettings'),
            meta: {
              requiresAuth: true, sidebar: true
            },
            props: true
          }
        ]
      },
      {
        path: 'calendars',
        name: 'settings-calendars',
        component: loadView('calendars/Wrapper'),
        redirect: { name: 'calendars-list' },
        meta: {
          requiresAuth: true,
          saas: false,
          sidebar: true,
          permission: 'admin'
        },
        children: [
          {
            path: 'list',
            name: 'calendars-list',
            component: loadView('calendars/List'),
            meta: { requiresAuth: true, abstract: true }
          },
          {
            path: 'add',
            name: 'calendars-add',
            component: loadView('calendars/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: 'customization/:id',
            name: 'calendars-custom',
            component: loadView('calendars/Customization'),
            meta: { requiresAuth: true },
            props: true
          },
          {
            path: ':id',
            name: 'calendars-edit',
            component: loadView('calendars/Form'),
            meta: { requiresAuth: true },
            props: true
          }
        ]
      },
      {
        path: 'groups',
        name: 'settings-groups',
        component: loadView('GroupsSettings'),
        meta: {
          requiresAuth: true,
          saas: false,
          sidebar: true,
          permission: 'content_manager'
        },
        children: [
          {
            path: 'add',
            name: 'settings-group-add',
            component: loadView('GroupSettings'),
            meta: {
              requiresAuth: true,
              saas: false,
              sidebar: true,
              permission: 'admin'
            }
          },
          {
            path: ':uuid',
            name: 'settings-group-edit',
            component: loadView('GroupSettings'),
            meta: {
              requiresAuth: true,
              saas: false,
              sidebar: true,
              permission: 'admin'
            },
            props: true
          }
        ]
      },
      {
        path: 'skills',
        name: 'settings-skills',
        component: loadView('SkillsSettings'),
        meta: {
          requiresAuth: true,
          saas: false,
          sidebar: true,
          permission: 'admin'
        },
        children: [
          {
            path: 'add',
            name: 'settings-skill-add',
            component: loadView('SkillSettings'),
            meta: {
              requiresAuth: true, saas: false, sidebar: true
            }
          },
          {
            path: ':uuid',
            name: 'settings-skill-edit',
            component: loadView('SkillSettings'),
            meta: {
              requiresAuth: true, saas: false, sidebar: true
            },
            props: true
          }
        ]
      },
      {
        path: 'subaccounts',
        name: 'settings-subaccounts',
        component: loadView('SubAccountsSettings'),
        meta: {
          requiresAuth: true,
          saas: HAS_SUBACCOUNTS,
          sidebar: true,
          permission: 'admin'
        },
        children: [
          {
            path: 'add',
            name: 'settings-subaccount-add',
            component: loadView('SubAccountSettings'),
            meta: {
              requiresAuth: true,
              saas: HAS_SUBACCOUNTS,
              sidebar: true
            }
          },
          {
            path: ':uuid',
            name: 'settings-subaccount-edit',
            component: loadView('SubAccountSettings'),
            meta: {
              requiresAuth: true,
              saas: HAS_SUBACCOUNTS,
              sidebar: true
            },
            props: true
          }
        ]
      },
      {
        path: 'contact-reasons',
        name: 'settings-contact-reasons',
        component: loadView('ContactReasonsSettings'),
        meta: {
          requiresAuth: true,
          sidebar: true,
          permission: 'manager'
        },
        children: [
          {
            path: 'add',
            name: 'settings-contact-reason-add',
            component: loadView('ContactReasonSettings'),
            meta: {
              requiresAuth: true, sidebar: true
            }
          },
          {
            path: ':uuid',
            name: 'settings-contact-reason-edit',
            component: loadView('ContactReasonSettings'),
            meta: {
              requiresAuth: true, sidebar: true
            },
            props: true
          }
        ]
      },
      {
        path: 'chat',
        name: 'settings-chat',
        component: loadView('ChatSettings'),
        meta: {
          requiresAuth: true, sidebar: true
        }
      },
      {
        path: 'surveys',
        name: 'settings-surveys',
        component: loadView('surveys/Wrapper'),
        redirect: { name: 'settings-surveys-list' },
        meta: {
          requiresAuth: true,
          sidebar: true
        },
        children: [
          {
            path: '',
            name: 'settings-surveys-list',
            component: loadView('surveys/List'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'settings-surveys-add',
            component: loadView('surveys/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit/:id',
            props: true,
            name: 'settings-surveys-edit',
            component: loadView('surveys/Form'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'scheduled-exports',
        name: 'settings-scheduled-exports',
        component: loadView('scheduled-exports/Wrapper'),
        redirect: { name: 'settings-scheduled-exports-list' },
        meta: {
          requiresAuth: true,
          sidebar: true,
          saas: false
        },
        children: [
          {
            path: '',
            name: 'settings-scheduled-exports-list',
            component: loadView('scheduled-exports/List'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'settings-scheduled-exports-add',
            component: loadView('scheduled-exports/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit/:id',
            props: true,
            name: 'settings-scheduled-exports-edit',
            component: loadView('scheduled-exports/Form'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'msglib',
        name: 'settings-msglib',
        component: loadView('msglib/Wrapper'),
        redirect: { name: 'settings-msglib-list' },
        meta: {
          requiresAuth: true,
          sidebar: true
        },
        children: [
          {
            path: 'list/:category?/:subcategory?',
            name: 'settings-msglib-list',
            component: loadView('msglib/List'),
            meta: { abstract: true, requiresAuth: true }
          },
          {
            path: 'add/:category?/:subcategory?',
            props: true,
            name: 'settings-msglib-add',
            component: loadView('msglib/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit/:category?/:subcategory?/:sentence?',
            props: route => ({
              ...route.params,
              edit: true
            }),
            name: 'settings-msglib-edit',
            component: loadView('msglib/Form'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'external-views',
        name: 'settings-external-views',
        component: loadView('externalViews/Wrapper'),
        redirect: { name: 'settings-external-views-list' },
        meta: {
          permission: 'owner',
          requiresAuth: true,
          saas: false,
          sidebar: true
        },
        children: [
          {
            path: '',
            name: 'settings-external-views-list',
            component: loadView('externalViews/List'),
            meta: {
              abstract: true,
              requiresAuth: true
            }
          },
          {
            path: 'add',
            name: 'settings-external-views-add',
            component: loadView('externalViews/Form'),
            meta: { requiresAuth: true }
          },
          {
            path: 'edit/:id',
            props: true,
            name: 'settings-external-views-edit',
            component: loadView('externalViews/Form'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'billing',
        name: 'settings-billing',
        component: loadView('BillingSettings'),
        props: true,
        meta: {
          requiresAuth: true, saas: true, sidebar: true
        },
        redirect: { name: 'settings-subscription' },
        children: [
          {
            path: 'address',
            name: 'settings-billing-address',
            component: loadView('BillingAddressSettings'),
            meta: { requiresAuth: true }
          },
          {
            path: 'invoices',
            name: 'settings-billing-invoices',
            component: loadView('Invoices'),
            meta: { requiresAuth: true }
          },
          {
            path: 'payments',
            name: 'settings-billing-payments',
            component: loadView('PaymentsSettings'),
            meta: { requiresAuth: true }
          },
          {
            path: 'payments/add',
            name: 'settings-billing-payment-add',
            component: loadView('PaymentSettings'),
            meta: { requiresAuth: true }
          },
          {
            path: 'subscription/:offer?/:currency?',
            props: true,
            name: 'settings-subscription',
            component: loadView('SubscriptionSettings'),
            meta: { requiresAuth: true }
          },
          {
            path: 'summary',
            name: 'settings-billing-summary',
            component: loadView('BillingSummary'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'messages',
        name: 'settings-messages',
        component: loadView('MessagesSettings'),
        meta: {
          requiresAuth: true,
          sidebar: true,
          permission: $settings.saas ? 'manager' : 'admin'
        }
      },
      {
        path: 'rooms',
        name: 'settings-rooms',
        component: loadView('RoomsSettings'),
        meta: { requiresAuth: true, sidebar: true }
      },
      {
        path: 'public-api',
        name: 'settings-public-api',
        component: loadView('PublicApiSettings'),
        meta: { requiresAuth: true, sidebar: true }
      }
    ]
  },
  {
    path: '/stats',
    name: 'stats',
    component: loadView($settings.saas ? 'Stats' : 'keyAccount/Stats'),
    meta: {
      requiresAuth: true,
      permission: 'stats_manager'
    },
    children: [
      {
        path: 'bot',
        name: 'stats-bot',
        component: loadView('keyAccount/StatsBot'),
        meta: {
          requiresAuth: true,
          abstract: true
        },
        redirect: { name: 'stats-bot-main' },
        children: [
          {
            path: 'main',
            name: 'stats-bot-main',
            component: loadView('keyAccount/StatsMessaging'),
            meta: { requiresAuth: true, abstract: true }
          },
          {
            path: 'customer-analytics',
            name: 'stats-bot-flow',
            component: loadView('keyAccount/StatsFlow'),
            meta: { requiresAuth: true, abstract: true }
          }
        ]
      },
      {
        path: 'webivr',
        name: 'stats-webivr',
        component: loadView('keyAccount/StatsWebivr'),
        meta: {
          requiresAuth: true,
          abstract: true
        },
        redirect: { name: 'stats-webivr-main' },
        children: [
          {
            path: 'main',
            name: 'stats-webivr-main',
            component: loadView('keyAccount/dashboardWebivr/Main'),
            meta: { requiresAuth: true, abstract: true, saas: false }
          },
          {
            path: 'customer-analytics',
            name: 'stats-webivr-flow',
            component: loadView('keyAccount/dashboardWebivr/Flow'),
            meta: { requiresAuth: true, abstract: true, saas: false }
          },
          {
            path: 'customer-analytics-tree',
            name: 'stats-webivr-flow-tree',
            component: loadView('keyAccount/dashboardWebivr/flowTree/Main'),
            meta: { requiresAuth: true, abstract: true, saas: false }
          }
        ]
      },
      {
        path: 'campaigns',
        name: 'stats-campaigns',
        component: loadView('keyAccount/dashboardCampaigns/Main'),
        meta: { requiresAuth: true, abstract: true, saas: false }
      }
    ]
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: loadView('ConfirmEmail'),
    meta: {
      permission: 'agent',
      requiresAuth: true,
      notRequiresEmailConfirm: true
    }
  }, {
    path: '/prototype',
    name: 'prototype',
    component: loadView('Prototype'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const userCallback = (data, to, next) => {
  const emailConfirmRequired = data.has_confirm_email === false &&
    dayjs(data.date_joined).add(7, 'day').isBefore(dayjs()) &&
    !to.matched.some(record => record.meta.notRequiresEmailConfirm)
  const userNotConfigured = data.permissions.skills.length === 0 &&
    $settings.permissions[data.user_role] < $settings.permissions.admin

  if (emailConfirmRequired) {
    next({ name: 'confirm-email' })
  } else if (userNotConfigured && to.meta.requiresAuth) {
    next({ name: 'user-not-configured', replace: true })
  } else if ($settings.hasPermissions(data, to)) {
    next()
  } else if (to.matched.some(record => record.name === 'dashboard')) {
    next({ name: 'gb-agent', replace: true })
  } else if (to.name === 'GBA-dashboard') {
    next({ name: 'settings-account', replace: true })
  } else {
    store.dispatch('global/showModal', 'permission-denied')
  }

  store.dispatch('global/hideLoading')
}

router.beforeEach((to, from, next) => {
  store.commit('GBA/global/hideModal')
  store.commit('GBA/global/hideOverlay')
  store.commit('GBA/global/hideUserMenu')
  store.commit('GBA/global/hideLeftRoomMenu')
  store.commit('GBA/global/hideRightRoomMenu')
  store.commit('GBA/global/hideEmojiPicker')
  store.commit('global/hideUserMenu')
  store.dispatch('global/showLoading')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    let token = store.getters['user/token']
    if (token === undefined || token === null || token === '' || token === 'null') {
      token = localStorage.token ? localStorage.token : ''
      store.commit('user/token', token)
      store.commit('GBA/user/updateToken', token)
    }
    if (token === undefined || token === null || token === '' || token === 'null') {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (store.getters['websocket/isOpen'] === false) {
        store.dispatch('websocket/init', store.getters['user/token'])
      }

      if (router.app.$socket.rws.url === undefined) {
        router.app.$socket.init()
        store.dispatch('GBA/user/sendUser')
      }

      const user = store.getters['user/user']
      if (user !== null) {
        userCallback(user, to, next)
      } else {
        store.dispatch('user/fetchUser').then(data => {
          store.dispatch('user/fetchCustomer').then(() => {
            userCallback(data, to, next)
          })
        })
      }
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.afterEach((to) => {
  store.dispatch('global/hideLoading')

  // Set document title
  Vue.nextTick(() => {
    let title = store.getters['global/settings'].title
    let mainPage = to.matched[0]

    if (!mainPage) {
      return
    }

    const getName = (page) => {
      const key = 'menu.' + page.name
      const translation = Vue.i18n.t(key)
      return page.meta.name ||
        (
          translation !== key
            ? translation
            : ''
        )
    }

    if (mainPage.meta && mainPage.meta.customRoute) {
      mainPage = router.options.routes.find(
        route => route.name === mainPage.meta.customRoute
      )
    }

    const name = getName(mainPage)

    if (name) {
      title += ` - ${getName(mainPage)}`

      to.matched.slice(1).forEach(page => {
        if (page.meta.abstract !== true) {
          title += ` > ${getName(page)}`
        }
      })
    }

    document.title = title
  })
})

export default router
