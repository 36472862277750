<template>
  <div id="app"
    v-if="microToken && (anon || (customer && skills))"
    :class="{
      anon, bb, gba, prod: $settings.prod,
      'hide-chawgt': hideChawgt,
      'disabled-nav': !isNavEnabled,
      'disabled-nav-gba': !isNavGbaEnabled }"
    >
    <!-- div id="debug" ref="debug"></div> -->
    <portal-target name="root" multiple />
    <modal />
    <notification></notification>
    <overlay v-if="$store.getters['global/isOverlayShown']" />
    <div id="wrapper">
      <Nav v-if="!anon && isNavEnabled"/>
      <div class="main">
        <LanguageSelect v-show="anon" id="language"/>
        <Header v-if="!anon"/>
        <router-view class="gb-content" :class="{ gba }"/>
      </div>
    </div>
    <reload-worker-banner v-if="isAndroidDevice" id="reload-worker-banner"/>
    <Loading :visibility="waitingForUser()"/>
    <audio id="notification-sound" ref="notificationSound" src="@/assets/sound/notification.mp3" preload="auto"></audio>
    <trackers/>
  </div>
</template>

<script type="text/javascript">
import Vue from 'vue'
import VueRouter from 'vue-router'
import { useObservable } from '@vueuse/rxjs'

import Header from '@/components/Header.vue'
import LanguageSelect from '@/components/LanguageSelect'
import Loading from '@/components/Loading.vue'
import Modal from '@/components/Modal.vue'
import Notification from '@/components/Notification.vue'
import Overlay from '@/components/Overlay.vue'
import ReloadWorkerBanner from '@/components/ReloadWorkerBanner.vue'
import Trackers from '@/components/Trackers.vue'
import { liveQuery } from 'dexie'

import { db } from '@shared/db/db'

import * as Sentry from '@sentry/vue'

import pageTitleNotification from '@/components/flash-title'
import push from 'push.js'

Vue.use(VueRouter)

const flashTitle = pageTitleNotification()

export default {
  name: 'App',
  components: {
    Header,
    Loading,
    LanguageSelect,
    Modal,
    Notification,
    Overlay,
    ReloadWorkerBanner,
    Trackers
  },
  data () {
    return {
      anon: true,
      microToken: null
    }
  },
  destroyed () {
    this.$root.$off('showLoading', this.showLoading)
    this.$root.$off('hideLoading', this.hideLoading)
  },
  computed: {
    action () {
      return this.$store.getters['GBA/global/getAction']
    },
    bb () {
      return this.$route.matched.some(route => ['bot-builder', 'webivr-builder'].includes(route.name))
    },
    customer () {
      return this.$store.getters['user/customer']
    },
    gba () {
      return this.$route.matched.some(route => route.name === 'gb-agent')
    },
    gbaNotification () {
      // Copy the code used to display the notification dot on the console icon
      // in the old cms navbar. The resulting boolean will be used in a watcher
      // to emit a custom JS event that is listened by the new navbar parcel
      const reviews = this.$store.getters['GBA/reviews/list']
      const rooms = this.$store.getters['GBA/rooms/getAll']
      const threads = this.$store.getters['threads/list']
      let hasUnreadThreadComment = false
      for (const status in threads) {
        hasUnreadThreadComment = threads[status].counters.unread_comments > 0
        if (hasUnreadThreadComment === true) break
      }
      const hasUnreadReview = reviews && reviews.waiting && reviews.waiting.data.length > 0

      const hasUnreadRooms = rooms && (
        (rooms.running && rooms.running.unread_message > 0) ||
        (rooms.waiting && rooms.waiting.number_of_rooms > 0) ||
        (rooms.late && rooms.late.number_of_rooms > 0)
      )

      const res = (
        hasUnreadRooms ||
        (
          threads && hasUnreadThreadComment === true
        ) ||
        hasUnreadReview
      )
      // console.log('RES', res, '\n UNREADTHREAD', hasUnreadThreadComment,
      //   '\n UNREADREVIEW', hasUnreadReview,
      //   '\n UNREADROOMS', hasUnreadRooms)

      return res
    },
    hasError () {
      return this.$store.getters['global/getError']
    },
    hideChawgt () {
      const routeName = this.$route.name || ''
      return routeName === 'dashboard' || this.gba || this.bb
    },
    isAndroidDevice () {
      return this.$store.getters['pushNotifications/isAndroidDevice']
    },
    isAppleDevice () {
      return this.$store.getters['pushNotifications/isAppleDevice']
    },
    isAppleDeviceAndLogged () {
      return this.isAppleDevice && this.customerAndUserReady
    },
    isNavEnabled () {
      return this.$store.getters['global/isNavEnabled']
    },
    isNavGbaEnabled () {
      return this.$store.getters['global/isNavGbaEnabled']
    },
    lastMessageDate () {
      return this.$store.getters['GBA/global/getLastMessageDate']
    },
    customerAndUserReady () {
      return this.customer !== null && this.user !== null
    },
    settings () {
      return this.$store.getters['global/settings']
    },
    skills () {
      return this.$store.getters['skills/skills']
    },
    token () {
      return this.$store.getters['users/token']
    },
    user () {
      return this.$store.getters['user/user']
    },
    userSkills () {
      return this.user.permissions.skills
    }
  },
  methods: {
    isAllowedNavLink (name) {
      const { resolved } = this.$router.resolve({ name })
      let requirements = true

      if (this.settings.saas === false) {
        if (name === 'ai') {
          requirements = (this.skills || []).find(s => {
            return (s.services || [])
              .find(service => ['dialogflow', 'gpt', 'knowledge-engine'].includes(service.kind))
          })
        } else if (name === 'botgroups') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => service.kind === 'integration')
          })
        } else if (name === 'campaigns') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => service.kind === 'campaigns') &&
                    this.userSkills.find(us => us.id === s.id)
          })
        } else if (name === 'third-parties') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => service.kind === 'third-parties')
          })
        } else if (name === 'gb-agent') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => service.kind === 'gb_agent')
          })
        } else if (name === 'messaging') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => ['bot', 'social_networks'].includes(service.kind))
          })
        } else if (name === 'webivr') {
          requirements = (this.skills || []).find(s => {
            return (s.services || []).find(service => service.kind === 'webivr') &&
                    this.userSkills.find(us => us.id === s.id)
          })
        }
      }

      return requirements && this.settings.hasPermissions(this.user, resolved)
    },
    askPushPermission () {
      if (this.anon === false) {
        push.Permission.request()
        document.removeEventListener('click', this.askPushPermission)
      }
    },
    hideLoading () {
      this.$store.dispatch('global/hideLoading')
    },
    showLoading () {
      this.$store.dispatch('global/showLoading')
    },
    waitingForUser () {
      return this.$route.meta.requiresAuth === true && this.user === null
    }
  },
  watch: {
    $route: {
      handler (val) {
        this.anon = this.user === null || !val.meta.requiresAuth

        // IFRAME
        if (window !== window.parent && val.name && val.meta.requiresAuth &&
          this.$store.getters['global/iframeSrc'] === null) {
          this.$store.commit(
            'global/iframeSrc',
            location.pathname
          )
        }
      },
      immediate: true
    },
    anon: {
      handler (val) {
        if (!val) {
          if (this.customer === null) {
            this.$store.dispatch('user/fetchCustomer')
          }
          if (this.skills === null) {
            this.$store.dispatch('skills/fetchSkills')
          }
        }
      },
      immediate: true
    },
    hasError (err) {
      if (err) {
        this.$store.dispatch('global/showModal', 'error-msg')
      }
    },
    gbaNotification: {
      handler (val, oldVal) {
        if (val !== null) {
          const event = new CustomEvent('gb:cms:gba:rooms:events:unread-msgs', {
            detail: {
              status: val
            }
          })
          document.body.dispatchEvent(event)
        }
      },
      immediate: true
    },
    isAppleDeviceAndLogged: {
      handler (val, oldVal) {
        if (val && val !== oldVal) {
          if (this.customer.id === 'b3a1ee92-d3ef-45bc-a1aa-652c5aa52cce') {
            alert(`::${val}-${oldVal}::
enabled: ${this.$store.getters['pushNotifications/enabled']}
authorized: ${this.$store.getters['pushNotifications/authorized']}`)
          }
          this.$store.dispatch('pushNotifications/triggerGetAppleDeviceToken')
          this.$store.dispatch('pushNotifications/triggerAskApplePerms')
        }
      },
      immediate: true
    },
    lastMessageDate () {
      if (
        (
          this.$router.currentRoute.name.indexOf('GBA') < 0 ||
          this.$store.getters['GBA/user/isAllowedToPush'] === true
        ) &&
          this.user.notif_new_msg_sound === true) {
        this.$refs.notificationSound.play()
      }
    },
    customerAndUserReady () {
      // Firebase PWA
      if (this.isAndroidDevice) {
        this.$store.dispatch('pushNotifications/initFirebase')

        document.addEventListener('click', () => {
          this.$store.dispatch('pushNotifications/triggerAskAndroidPerms')
            .then(permissionGranted => {
              if (permissionGranted) {
                this.$store.dispatch('pushNotifications/triggerGetAndroidDeviceToken')
              }
            })
        }, { once: true })
      }

      // HelpHero
      if (this.$helphero !== undefined) {
        this.$helphero.identify(this.user.id, {
          appsumo: this.$store.getters['user/hasAppSumoOffer'],
          created_at: this.user.date_joined,
          is_prod: this.$settings.prod,
          is_saas: this.$settings.saas,
          role: this.user.user_role,
          subaccount_created: false,
          user_created: false
        })
      }

      // Sentry
      Sentry.setUser({
        email: this.user.email,
        id: this.user.id
      })
    }
  },
  mounted () {
    useObservable(
      liveQuery(() => db.token.toCollection().first((data) => {
        this.microToken = data ? data.token : null
        this.$store.commit('user/token', this.microToken)
      }))
    )

    if (push.Permission.has() === false && !this.isAppleDevice && !this.isAndroidDevice) {
      document.addEventListener('click', this.askPushPermission)
    }
    this.$store.dispatch('pushNotifications/updateDeviceType')

    this.$root.$on('showLoading', this.showLoading)
    this.$root.$on('hideLoading', this.hideLoading)
  },
  created () {
    window.setInterval(() => {
      if (this.$store.getters['user/user'] !== null) {
        this.$store.dispatch('user/refresh')
        this.$store.dispatch('user/fetchCustomer')
      }
    }, 5 * 60 * 1000)

    // IFRAME
    const urlParams = new URLSearchParams(location.search)
    this.$store.commit('global/kind', urlParams.get('kind') || '')
    this.$store.commit('global/navEnabled', urlParams.get('nav') !== 'false')
    this.$store.commit('global/navGbaEnabled', urlParams.get('nav-gba') !== 'false')

    // GBA
    import('@gba-custom/store/custom').then((module) => {
      const storeCustom = module.default
      this.$store.registerModule('custom', storeCustom)
    })
    import('@gba-custom/Reviews/store/reviews').then((module) => {
      this.$store.registerModule('GBA/reviews', module.default)
    })
    import('@gba-custom/Threads/store/threads').then((module) => {
      this.$store.registerModule('threads', module.default)
    })
    const that = this
    window.addEventListener('blur', () => {
      that.$store.commit('GBA/user/updateIsAllowedToPush', true)
    })
    window.addEventListener('focus', () => {
      that.$store.commit('GBA/user/updateIsAllowedToPush', false)
      flashTitle.off()
    })

    if (this.$settings.saas === true && this.$settings.prod === true) {
      const script = document.createElement('script')
      script.setAttribute('src', 'https://widgets.chayall.fr/js/chayall.js')
      script.setAttribute('data-chayall-account', 'v2:45531017-7c68-4581-a710-a2bd688014be')
      document.head.appendChild(script)
    }

    this.$store.dispatch('skills/fetchSkills')
      .then(() => {
        // Fetch data for the notification dot in case we're not already on gba
        if (!this.$route.matched.some(route => route.name === 'gb-agent') &&
          this.isAllowedNavLink('gb-agent')) {
          if (!this.rooms) {
            this.$store.dispatch('GBA/rooms/sendRoomsLists')
          }
          if (this.$store.getters['threads/accounts']() === null) {
            this.$store.dispatch('threads/fetchAccounts')
          }
          if (!this.threads) {
            this.$store.dispatch('threads/fetchAllList')
          }
          if (this.$store.getters['GBA/reviews/accounts'] === null && !this.settings.saas) {
            this.$store.dispatch('GBA/reviews/fetchAccounts')
              .then(accounts => {
                if (accounts && accounts.length && this.reviews === null) {
                  this.$store.dispatch('GBA/reviews/fetchAllList')
                }
              })
          }
        }
      })
  }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/reboot.scss";
@import "@/assets/fonts/stylesheet.scss";
@import "@/assets/scss/global.scss";

html,
body,
#app {
  height: 100%;
  min-width: 320px;
}

#app {
  display: flex;
  flex-direction: column;
  transition: background-color .15s;
  width: 100vw;

  @media (min-width: ($break-large + 1)) {
    &:not(.disabled-nav) {
      min-height: 680px;
    }
  }

  &.bb,
  &.gba {
    background-color: $grey-light;
  }

  .dialog.calendar-form-view {
    position: absolute;
    z-index: 100000;
  }

}

#wrapper {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  overflow: hidden;
  position: relative;

  @media (max-width: $break-large) {
    flex-direction: column-reverse;
  }

}

body {
  font-family: "Be Vietnam";
  font-size: .9rem;
}

#notification-sound {
  display: none;
}

.prod .prod-hidden {
  display: none !important;
}

// Dragula ghost
.gu-mirror {
  opacity: 0.4;
  position: absolute;
  pointer-events: none;
  z-index: 999999999;
}

.main {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.material-design-icon {

  &:where(svg) {
    width: auto;
    height: 1em;
  }

  &:where(:not(svg)) {
    &, & > .material-design-icon__svg {
      height: 1em;
      line-height: 0;
      width: 1em;
    }
  }
}

.gb-content {
  overflow: auto;
  flex-grow: 1;
  position: relative;

  @media (max-width: $break-large) {
    overflow: visible;
  }
}

#app:not(.anon) .main > .gb-content {
    padding-top: 32px;
}

#app.anon #language {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.main > .gb-content {

  @media (max-width: $break-large) {
    overflow: auto;

    &.gba {
      padding: 0 !important;
    }
  }
}

.gb-content-title {
  margin-bottom: 70px;

  @media (max-width: $break-large) {
    margin-bottom: 40px
  }
}

.gb-wrapper {
  width: 100% !important;

  & > div {
    max-width: 1080px;
    width: 100%;
    margin: 0 auto 1rem auto;
  }

  .gb-block {
    border: 1px solid $grey;
    background-color: white;
    box-shadow: 0 40px 40px 0 rgba(66,97,158,0.15);
    border-radius: 10px;

    & + .gb-block {
      margin-top: 2rem;
    }

    &:not(.table-wrapper) {
      padding: 50px 40px;
    }

    & > .gb-header {
      display: flex;
      align-items: center;

      .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 1rem;
        border-radius: 18px;
        background-color: $content;
        color: white;
        font-size: 1.5rem;
        text-align: center;

        svg {
          vertical-align: baseline;
        }
      }

      .gb-subtitle {
        font-size: 13px;
      }
    }
  }
}

hr {
    border: none;
    border-top: 1px solid $grey;
}

.gb-title {
  margin: 0;
  color: $content;
  font-family: Poppins;
}

.gb-subtitle {
  color: $content-light;
  font-size: 14px;
  font-weight: 500;
  display: block;
  white-space: pre-wrap;

  ul {
    margin-top: .5rem;
    padding-left: 2rem;

    li:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}

h1.gb-title {
    font-size: 28px;
    font-weight: 600;
}

h2.gb-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 46px;
}

h3.gb-title {
    font-size: 20px;
    font-weight: 600;
}

h4.gb-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
}

.gb-link {
  font-size: 12px;
  font-weight: 500;
  color: $primary;
  cursor: pointer;

  .gb-link-ignore {
    font-weight: normal;
    color: $content-light;
  }

  &:hover {
      text-decoration: underline;
      color: $secondary;
  }
}

a.gb-link:not([href]):not([tabindex]) {
    color: $primary;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $secondary;
    }
}

.gb-content-gutter {
    & > *:first-child:not(:last-child) > *,
    & > *:last-child {
        padding: 0 $padding;
    }

    @media (max-width: ($break-large * 1.3)) {
      & > *:first-child:not(:last-child) > *,
      & > *:last-child {
        padding: 0 $padding * .5;
      }
    }

    @media (max-width: $break-large) {
        & > *:first-child:not(:last-child) > * {
            padding: 0 $padding 0 $padding-min;
        }
        & > *:last-child {
            padding: 0 $padding-min;
        }
    }
}

.gb-content-center > *:first-child:last-child {
    width: 1000px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.gb-content-cols {
  width: 100%;
  padding-left: math.div($padding, 2) !important;
  padding-right: math.div($padding, 2) !important;

  .gb-content-col {
    flex-grow: 1;
    flex-basis: 388px;
    width: 50%;
    padding: math.div($padding, 2);
    padding-top: 0;
  }

  @media (max-width: $break-large) {
    &, .gb-content-col {
      padding-left: math.div($padding-min, 2) !important;
      padding-right: math.div($padding-min, 2) !important;
    }
  }
}

.gb-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;

  input, select, textarea, button {
    margin: 0;
    font-size: 12px;
    height: 36px;
  }

  th, td {
    padding: 11px 8px;

    &:first-child {
      border-radius: 10px 0 0 10px;
      padding-left: 16px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
      padding-right: 16px;
    }
  }

  th {
    color: $primary;
    font-weight: bold;
    border: 2px solid $grey;
    font-size: 14px;
    padding: 16px;

    &:not(:first-child) {
      border-left: none;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &:first-child {
      border-right: none;
    }
  }

  td {
    background-color: $grey-light;
    font-weight: 500;
  }
}

.form-lite {
  max-width: 600px;
  padding-bottom: $padding;

  @media (max-width: $break-large) {
    margin: auto;
  }
}

.gb-light {
  color: $content-light;
  font-style: italic;
  opacity: 0.7;
}

// Dialogs
.dg-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.dg-backdrop,
.dg-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
}
.dg-content-cont {
    width: 100%;
}
.dg-content-cont--floating {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
}
.dg-main-content {
    background-color: white;
    border-radius: 5px;
    margin: 25px auto;
    max-width: 90%;
    min-width: 300px;
    padding: 42px 51px;
    width: 580px;
}
.dg-title {
  font-size: 1.5em;
  font-family: Poppins;
  margin: 0;

  &:has(+ .dg-content:empty) {
    text-align: center;
  }
}
.dg-content {
  padding: 20px 0 40px;
}
.dg-content-footer {
  font-size: 0;
  text-align: right;
}
.dg-btn {
  @extend .gb-btn;
  margin-top: 1rem;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &.dg-btn--cancel {
    @extend .gb-btn-secondary;
  }
}

.options-menu-wrapper {
  position: relative;
  display: inline-block;

  .arrow-box {
    position: relative;
    background: #FFF;
    border: 1px solid #c2e1f5;

    &:after, :before {
      top: 100%;
      left: 25px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #FFF;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: #c2e1f5;
      border-width: 11px;
      margin-left: -11px;
    }
  }

  .options-menu {
    background: #fff;
    border: 1px solid #e4e5ec;
    border-radius: .35rem;
    box-shadow: 0 5px 75px 2px rgba(64,70,74,.2);
    position: absolute;

    ul {
      list-style: none;
      margin: 0;
      padding: 5px 0;
      width: 100%;

      li {
        width: 100%;

        a, button {
          background: #fff;
          border: none;
          color: #57608b;
          display: block;
          font-size: 0.9rem;
          padding: 8px 12px;
          text-align: left;
          text-decoration: none;
          width: 100%;
          white-space: nowrap;

          &:hover {
            text-decoration: none;
            background-color: #f9fafd;
          }

          &:active {
            outline: none;
          }
        }

        svg {
          transform: scale(0.5);
        }
      }
    }
  }
}

/////////////////////////////////////////
// SYNCFUSION OVERRIDE
/////////////////////////////////////////

// DIAGRAM //////////////////////////////

// Drag clone
.e-dragclone {
  z-index: 999;
}

// XY Coordinates tooltip
.e-tip-content {
  display: none;
}

// ChayAll support widget
#app.hide-chawgt ~ .chawgt {
  display: none !important
}

@media (max-width: 1150px) {
  .chawgt-main-button,
  .chawgt-cta,
  .chawgt-widget-item,
  .chawgt-webchat-widget-wrapper {
    margin-bottom: 60px !important;
  }
}

#reload-worker-banner {
  bottom: 0;
  left: 0;
  position: relative;
  width: 100%;
  z-index: 9999;
}

#debug {
  background-color: red;
  color: white;
  font-weight: 500;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 99999999;

  &:empty {
    display: none;
  }
}

.disabled-nav .main > header {
  background-color: white;
  border: 1px solid $grey;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  bottom: 0;
  box-shadow: 0 -14px 12px -12px rgba(209, 216, 232, 75%);
  padding: 0;
  position: absolute;
  right: $padding-min;
  transition: background-color .15s;
  width: auto;
  z-index: 99;

  & > div > div {

    &:first-child {
      display: none;
    }

    & > ul {
      margin-right: 1rem;
    }
  }

  .user-infos {

    & > ul  > li {
      margin: 0;
    }

    button.user {
      background-color: $grey-light;
      border: 1px solid $grey;
      border-radius: 8px;
      color: $content-light;
      display: flex;
      flex-direction: row-reverse;
      font-size: .9rem;
      font-weight: 400;
      justify-content: center;
      margin: 2px;
      min-width: 200px;
      padding: 2px 5px;

      &:hover {
        background-color: $grey;
        color: $content;
      }

      .avatar {
        display: flex;
        height: 1rem;
        margin: 0 .35rem 0 0;
        width: 1rem;
      }

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .dropdown {
    bottom: calc(100% + 5px);
    top: auto;

    &:before, &:after {
      border-bottom-color: transparent;
      bottom: auto;
      top: 100%;
    }

    &:before {
      border-top-color: $grey-light;
    }
    &:after {
      border-top-color: white;
    }
  }

}

@media (min-width: ($break-large + 1)) {
  .disabled-nav-gba #GBA-container {
    padding: 0 !important;

    #GBA > .nav,
    #GBA > .details {
      display: none;
    }
    #GBA > .content[class] {
      margin: 1.5rem $padding !important;

      @media (max-width: ($break-large * 1.3)) {
        margin: 1.5rem $padding * .5 !important;
      }
    }
  }
}
</style>
