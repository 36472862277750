import { Commit, Dispatch } from 'vuex'
import {
  Callback,
  Console,
  Crm,
  CrmContact,
  KINDS,
  Sftp,
  State
} from '@/types/thirdParties'

// initial state
const state: State = {
  current: null,
  list: null,
  publicKey: ''
}

// getters
const getters = {
  current (state: State): Callback | Console | Crm | Sftp | null {
    return state.current
  },
  list (state: State): Array<Callback | Console | Crm | Sftp> | null {
    return state.list
  },
  listByKind (state: State): (kind: typeof KINDS[number]) => Array<Callback | Console | Crm | Sftp> {
    return (kind) => ((state.list || []).filter(thirdparty => thirdparty.kind === kind))
  },
  publicKey (state: State): string {
    return state.publicKey
  }
}

// mutations
const mutations = {
  current (state: State, current: Callback | Console | Crm | Sftp | null): void {
    state.current = current
  },
  list (state: State, list: Array<Callback | Console | Crm | Sftp> | null): void {
    state.list = list
  },
  publicKey (state: State, publicKey: string): void {
    state.publicKey = publicKey
  }
}

// actions
const actions = {
  confirmSftpKey ({ dispatch }: { dispatch: Dispatch}, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_THIRDPARTIES_URL}/sftp/${id}/confirm-key`, { confirm: true })
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSftpPublicKey ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}): Promise<string> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_THIRDPARTIES_URL}/sftp/public-key`)
        .then((response: Response) => response.text())
        .then((publicKey: string) => {
          commit('publicKey', publicKey)
          resolve(publicKey)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}): Promise<Array<Callback | Console | Crm | Sftp>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_THIRDPARTIES_URL}/accounts`)
        .then((response: Response) => response.json())
        .then((data: Array<Callback | Console | Crm | Sftp>) => {
          commit('list', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetch ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}, id :string): Promise<Callback | Console | Crm | Sftp> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_THIRDPARTIES_URL}/accounts/${id}`)
        .then((response: Response) => response.json())
        .then((data: Callback | Console | Crm | Sftp) => {
          commit('current', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  remove ({ dispatch }: { dispatch: Dispatch}, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_THIRDPARTIES_URL}/accounts/${id}`)
        .then(() => {
          resolve()
        })
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save ({ dispatch }: { dispatch: Dispatch}, data: Callback | Console | Crm | Sftp): Promise<Callback | Console | Crm | Sftp> {
    let url = `${process.env.VUE_APP_THIRDPARTIES_URL}/accounts/${data.kind}`

    if (data.id) {
      url += `/${data.id}`
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(url, data)
        .then((response: Response) => response.json())
        .then((data: Callback | Console | Crm | Sftp) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  searchCrmContacts (
    { dispatch }: { dispatch: Dispatch },
    params: { email?: string, name?: string, phone?: string}
  ): Promise<Array<CrmContact>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_THIRDPARTIES_URL}/crms/contacts`, {
        params
      })
        .then((response: Response) => response.json())
        .then((contacts: Array<CrmContact>) => {
          resolve(contacts)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
