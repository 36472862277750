// initial state
const state = {
  detailsShown: false,
  navShown: false,
  note: null,
  toggled: {
    general: true,
    file: false,
    history: true,
    satisfaction: true,
    note: true
  }
}

// getters
const getters = {
  detailsShown (state) {
    return state.detailsShown
  },
  navShown (state) {
    return state.navShown
  },
  note (state) {
    return state.note
  },
  toggled (state) {
    return state.toggled
  },
  isToggled (state) {
    return (name) => state.toggled[name]
  }
}

// mutations
const mutations = {
  toggle (state, name) {
    state.toggled[name] = !state.toggled[name]
  },
  updateDetailsShown (state, detailsShown) {
    state.detailsShown = detailsShown
  },
  updateNavShown (state, navShown) {
    state.navShown = navShown
  },
  updateNode (state, note) {
    state.note = note || null
  }
}

const actions = {
  toggleDetails: ({ commit }, value) => {
    commit('updateDetailsShown', value)
    if (value === true) commit('updateNavShown', false)
  },
  toggleNav: ({ commit }, value) => {
    commit('updateNavShown', value)
    if (value === true) commit('updateDetailsShown', false)
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
