<script lang="ts">
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import FormGen from './FormGen.vue'
import scrollIntoView from 'scroll-into-view-if-needed'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

const scrollIntoViewSmoothly =
  'scrollBehavior' in document.documentElement.style
    ? scrollIntoView
    : smoothScrollIntoView

interface GbResponse {
  status: number
  body: Record<string, Array<string>>
}

@Component({
  components: {
    FormGen
  }
})
export default class FormView extends Vue {
  private sendData = false

  protected formDesc!: Record<string, any>

  protected formI18n?: string

  setErrors (err: GbResponse, formDesc_?: Record<string, any>): void {
    let formDesc: Record<string, any>
    if (typeof formDesc_ === 'undefined') {
      formDesc = this.formDesc
    } else {
      formDesc = formDesc_
    }
    for (const item in err.body) {
      if (item === 'detail' || item === 'non_field_errors' ||
          item === 'error') {
        if (typeof err.body[item] === 'string') {
          formDesc.nonFieldsErrors = [(this.formI18n || '') + err.body[item]]
        } else {
          formDesc.nonFieldsErrors = []
          err.body[item].forEach(value => {
            formDesc.nonFieldsErrors.push((this.formI18n || '') + value)
          })
        }
      } else {
        formDesc.fieldsets.forEach((fieldset: any) => {
          fieldset.fields.forEach((field: any) => {
            if (item === field.name) {
              err.body[item].forEach((e, i) => {
                err.body[item][i] = this.$t((this.formI18n || '') + e) as string
              })
              field.errors = err.body[item]
            }
          })
        })
      }
    }

    this.$set(formDesc, 'fieldsets', formDesc.fieldsets.concat([]))

    this.$nextTick(() => {
      const firstErr = this.$el.querySelector('.gb-az-errors:not(input[type="hidden"])')
      if (firstErr !== null && firstErr.scrollIntoView !== undefined) {
        scrollIntoViewSmoothly(firstErr, { behavior: 'smooth', scrollMode: 'if-needed' })
      }
    })
  }

  setErrorsNew (err: GbResponse, formDesc_?: Record<string, any>): void {
    const errors: GbResponse = {
      status: err.status,
      body: {}
    }
    err.body.errors.forEach((error: string) => {
      if (error.includes(':')) {
        const tmp = error.split(':')
        const label = tmp.shift() as string
        const value = tmp.join(':')
        if (!errors.body[label]) {
          errors.body[label] = []
        }
        errors.body[label].push(value)
      } else {
        if (!errors.body.non_field_errors) {
          errors.body.non_field_errors = []
        }
        errors.body.non_field_errors.push(error)
      }
    })

    this.setErrors(errors, formDesc_)
  }
}
</script>
