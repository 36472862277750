<template>
  <div v-if="false"></div>
</template>

<script>
/* eslint-disable block-spacing */
/* eslint-disable brace-style */
/* eslint-disable comma-spacing */
/* eslint-disable dot-notation */
/* eslint-disable indent */
/* eslint-disable key-spacing */
/* eslint-disable keyword-spacing */
/* eslint-disable multiline-ternary */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable object-curly-spacing */
/* eslint-disable operator-linebreak */
/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable semi */
/* eslint-disable semi-spacing */
/* eslint-disable space-before-blocks */
/* eslint-disable space-before-function-paren */
/* eslint-disable space-infix-ops */
/* eslint-disable wrap-iife */

export default {
  name: 'Trackers',
  computed: {
    isAppleDevice () {
      return this.$store.getters['pushNotifications/isAppleDevice']
    },
    settings () {
      return this.$store.getters['global/settings']
    }
  },
  methods: {
    initFBPixels () {
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      window.fbq('init', '231953204575336');
      window.fbq('init', '262542269022553');
      window.fbq('track', 'PageView');
    },
    initGoogleAnalytics () {
      // Deprecated on July 1st
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', 'UA-156915666-2', 'auto', { 'allowLinker': true });
      ga('require', 'linker');
      ga('linker:autoLink', ['chayall.fr']);
      ga('send', 'pageview');

      // GA4
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-4WB1LB413K', {
        'linker': {
          'domains': ['chayall.fr']
        }
      });
    },
    initHotjar () {
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1528662,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    },
    initLinkedIn () {
      window._linkedin_data_partner_ids =
      window._linkedin_data_partner_ids || [];
      window._linkedin_data_partner_ids.push("2338145");

      (function(){var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);})();
    },
    initTrackers () {
      this.initHotjar()
      this.initGoogleAnalytics()
      this.initFBPixels()
      this.initLinkedIn()
    }
  },
  watch: {
    isAppleDevice (val) {
      if (val === false &&
        this.settings.prod === true &&
        this.settings.saas === true) {
        this.initTrackers()
      }
    }
  }
}
</script>
