<template v-slot="main">
  <div class="modal-container">
    <div class="modal-body">
      {{ $t('modal.facebook_login_refresh_msg') }}
    </div>

    <div class="modal-footer">
      <button v-on:click="validate" class="gb-btn">
        {{ $t('OK') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'

export default {
  extends: BaseModal,
  name: 'FbRefreshMsgModal',
  computed: {

  },
  methods: {
    validate () {
      window.location.reload()
      this.closeModal()
    }
  }
}
</script>
