<template>
  <li :class="{ inline, disabled:  isDisabled === true }">
    <router-link :to="route" v-if="isRoute" class="item" :class="{ outline, active }">
      <div class="icon">
        <md-icon :name="icon" v-if="icon" />
        <slot v-else/>
        <span class="count" v-if="count">{{ count }}</span>
        <transition name="pop">
          <span class="count" v-if="!count && notification"></span>
        </transition>
      </div>
      <slot name="label" v-if="inline === true && (label || $slots.label)">
        <span>{{label}}</span>
      </slot>
    </router-link>
    <button @click="route" v-else-if="isFunction" class="item" :class="{ outline, active }">
      <div class="icon">
        <md-icon :name="icon" v-if="icon" />
        <slot/>
        <span class="count" v-if="count">{{ count }}</span>
        <transition name="pop">
          <span class="count" v-if="!count && notification"></span>
        </transition>
      </div>
      <slot name="label" v-if="inline === true && (label || $slots.label)">
        <span>{{label}}</span>
      </slot>
    </button>
    <span target="_blank" v-else-if="route === undefined" class="item" :class="{ outline, active }">
      <div class="icon">
        <md-icon :name="icon" v-if="icon" />
        <slot/>
        <span class="count" v-if="count">{{ count }}</span>
        <transition name="pop">
          <span class="count" v-if="!count && notification"></span>
        </transition>
      </div>
      <slot name="label" v-if="inline === true && (label || $slots.label)">
        <span>{{label}}</span>
      </slot>
    </span>
    <a :href="route" target="_blank" v-else class="item" :class="{ outline, active }">
      <div class="icon">
        <!--icon :name="icon" v-if="icon" / -->
        <md-icon :name="icon" v-if="icon" />
        <slot/>
        <span class="count" v-if="count">{{ count }}</span>
        <transition name="pop">
          <span class="count" v-if="!count && notification"></span>
        </transition>
      </div>
      <slot name="label" v-if="inline === true && (label || $slots.label)">
        <span>{{label}}</span>
      </slot>
    </a>
    <slot name="label" v-if="inline !== true && (label || $slots.label)">
      <span>{{label}}</span>
    </slot>
  </li>
</template>

<script>
// import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'
import MdIcon from '@/components/MdIcon.vue'

export default {
  props: {
    active: Boolean,
    count: [Number, String],
    icon: String,
    inline: Boolean,
    isDisabled: {
      type: Boolean,
      default: false
    },
    label: String,
    notification: Boolean,
    outline: Boolean,
    route: {
      type: [Object, String, Function]
    }
  },
  components: { MdIcon },
  computed: {
    isFunction () {
      return typeof this.route === 'function'
    },
    isRoute () {
      return typeof this.route === 'object'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

li {
  display: block;
  position: relative;
  font-size: 0;
  color: white;

  & > .item {
    color: white;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0;

    &:focus {
      outline: none;
    }

    & > .icon {
      width: 45px;
      height: 45px;
      border-radius: 16px;
      background-color: #347DF5; // rgba($color: white, $alpha: .2);
      transition: all .25s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: relative;
      font-size: 20px;

      .material-icons {
        font-size: 16px;
        top: 0;
      }
    }

    &.outline > .icon {
      border: none;
      box-shadow: inset 0 0 0 1.5px $primary;
      color: $primary;
      background-color: transparent;
    }

    &.router-link-active > .icon {
      box-shadow: 0 0 40px $primary-fade;
    }

    &.router-link-active,
    &.active {
      & > .icon {
        background-color: $primary;
        color: white;
      }
    }

    &:hover {
      text-decoration: none;

      &:not(.outline) > .icon {
        transform: scale(1.1);
      }

      & + * {
        opacity: 1;
        transform: translate(20px, -50%);
      }
    }

    & > :last-child:not(:first-child):not(.count) {
      position: relative;
      font-size: 16px;
      color: $content;
      background-color: transparent;
      font-weight: 600;
      transition: all .25s;
    }

    & + * {
      position: absolute;
      left: calc(100% + 10px);
      top: 50%;
      transform: translate(0, -50%);
      pointer-events: none;
      opacity: 0;
      transition: opacity .5s, transform .5s, background-color .25s;
      transition-delay: 0;
      font-size: .75rem;
      background-color: $primary;
      padding: 10px 20px;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: Poppins;
      vertical-align: text-bottom;
      white-space: nowrap;

      @media (max-width: $break-large) {
        display: none;
      }
    }

    .count {
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 0.75rem;
      font-weight: bold;
      line-height: 1.4rem;
      text-align: center;
      border-radius: 10px;
      background-color: $secondary-light;
      padding: 0 7px;
      position: absolute;
      top: 0;
      left: 100%;
      min-width: 22px;
      transform: translate(-53%, 0);

      &:empty {
        height: 12px;
        min-width: 12px;
        padding: 0;
      }
    }
  }

  &.inline {
    user-select: none;

    &:hover > .item > .icon {
      transform: none;
    }

    & > .item {
      display: flex;
      align-items: center;

      & > .icon {
        margin-right: 21px;
        background-color: $grey-light;
      }

      &.active > .icon,
      &.router-link-active > .icon {
        box-shadow: none;
        background-color: $primary;

        & + * {
          color: $primary;
        }
      }
    }

    &.disabled {

      &:hover {
        cursor: not-allowed;
        color: white;
      }

      & > .item {
        pointer-events: none;

        .icon {
          background-color: $grey;
          color: white;

          & + * {
            color: $grey;
          }
        }
      }
    }
  }
}

.pop-enter-active {
  animation: pop-in .5s;
}
.pop-leave-active {
  animation: pop-in .5s reverse;
}
@keyframes pop-in {
  0% {
    transform: translate(-53%, 0) scale(0);
  }
  50% {
    transform: translate(-53%, 0) scale(1.5);
  }
  100% {
    transform: translate(-53%, 0) scale(1);
  }
}
</style>
