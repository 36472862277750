<template>
  <ul>
    <li v-if="!$settings.saas || hasSubAccounts">
      <div class="gba-label" :class="{ green: !maxRoomsReached, red: maxRoomsReached}">
        <icon name="question_answer"/>
        <span>{{ openRoomsCount }}</span>
      </div>
    </li>
    <li v-if="!$settings.saas">
      <button v-if="hasAutoAllocationSkill" class="gba-label"
        :class="{green: isUserAutofill, red: !isUserAutofill}" @click.stop="toggleDropdown()">
        <icon name="call_split"/>
      </button>
      <button class="gba-label" :class="{green: !isUserPaused, red: isUserPaused}"
        @click.stop="hasAutoAllocationSkill ? toggleDropdown() : togglePause()">
        <icon v-if="isUserPaused" name="pause"/>
        <icon v-else name="lens" class="small-icon"/>
        <span v-if="isUserPaused">{{ $t('gba.header.unbreak') }}</span>
        <span v-else>{{ $t('gba.header.break') }}</span>
      </button>
      <header-dropdown :show="dropdown" :onClickOutside="closeDropdown">
        <form-gen class="form" :formDesc="form" :model="model" :sendData="sendData"/>
      </header-dropdown>
    </li>
  </ul>
</template>

<script>
import HeaderDropdown from '@/components/HeaderDropdown'
import FormView from '@/components/FormView.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'

export default {
  name: 'GBAInfos',
  extends: FormView,
  components: { HeaderDropdown, Icon },
  data () {
    return {
      dropdown: false,
      formDesc: {
        nonFieldsErrors: null,
        fieldsets: [
          {
            legend: null,
            fields: [
              {
                kind: 'InputCheckbox',
                name: 'paused',
                label: 'gba.header.break',
                placeholder: null,
                validation: null,
                validationMsg: null,
                disabled: false,
                errors: null
              },
              {
                kind: 'InputCheckbox',
                name: 'autofill',
                label: 'gba.header.autofill',
                placeholder: null,
                validation: null,
                validationMsg: null,
                disabled: false,
                errors: null
              }
            ]
          }
        ]
      },
      model: {}
    }
  },
  computed: {
    form () {
      const form = JSON.parse(JSON.stringify(this.formDesc))

      form.fieldsets[0].fields.forEach(field => {
        field.label = this.$t(field.label)
      })

      return form
    },
    hasAutoAllocationSkill () {
      return (this.user.permissions.skills || []).some(s => s.auto_allocation === true)
    },
    hasSubAccounts () {
      return this.$store.getters['user/hasSubAccounts']
    },
    isUserAutofill () {
      return this.$store.getters['GBA/user/isUserAutofill']
    },
    isUserPaused () {
      return this.$store.getters['GBA/user/isUserPaused']
    },
    maxRoomsReached () {
      return this.user && this.user.max_rooms
        ? this.openRoomsCount >= this.user.max_rooms
        : false
    },
    openRoomsCount () {
      return this.$store.getters['GBA/rooms/getOpenRoomsCount']
    },
    user () {
      return this.$store.getters['user/user']
    },
    userGBA () {
      return this.$store.getters['GBA/user/getUser']
    }
  },
  methods: {
    closeDropdown () {
      this.dropdown = false
    },
    toggleDropdown () {
      this.dropdown = !this.dropdown
    },
    togglePause () {
      this.$store.dispatch('global/showModal', 'confirm-agent-pause-modal')
    }
  },
  watch: {
    'model.autofill' (val) {
      this.$store.dispatch('GBA/user/sendAutofill', val)
        .then(() => this.$store.dispatch('GBA/user/sendUser'))
    },
    'model.paused' (val) {
      this.$store.dispatch('GBA/user/sendPause', val)
    },
    userGBA: {
      handler (val) {
        if (val) {
          this.model = {
            autofill: this.isUserAutofill,
            paused: this.isUserPaused
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped="true">
@import "@/assets/scss/global.scss";

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  &:empty {
    display: none;
  }

  li {
    margin: 2px 0 2px 5px;
    display: flex;
    position: relative;
  }
}

.gba-label {
  font-weight: 500;
  font-size: 13.5px;
  padding: 2.5px 5px;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  white-space: nowrap;
  border: none;
  outline: none;
  position: relative;
  transition: background-color .15s, color .15s;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 0;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 1;

    &:not(:first-of-type) {
      border-left: 1px solid rgba($color: $grey-dark, $alpha: .2);
    }
  }

  .material-design-icon {
    font-size: 18px;

    &:not(:last-child) {
      margin-right: 3px
    }
  }

  .small-icon {
    font-size: 10px;
  }

  &.green {
    background-color: $green-light;
    color: $green;
  }

  &.red {
    background-color: $secondary-light;
    color: $secondary;
  }
}

.form {
  padding: 15px 20px 10px 20px;

  ::v-deep label {
    display: flex;
    flex-direction: row-reverse;
    white-space: nowrap;

    .label-text {
      margin-right: 1rem;
      margin-left: 0;
    }
  }
}
</style>
