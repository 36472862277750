<template>
  <div class="file-drag-drop"
    :disabled="field.disabled">
    <label :for="`files_id_${field.name}`">
      <div v-if="value" >
        <a v-if="!raw" :href="value" target="_blank">
          <template v-if="onlyImage(field.accepted_file_types)">
            <img :src="value" />
          </template>
          <template v-else>
            {{ $t('form.upload_preview')}}
          </template>
        </a>
        <a v-else :href="createFileURL(value)" target="_blank">
          <template v-if="onlyImage(field.accepted_file_types)">
            <img :src="value" />
          </template>
          <template v-else>
            {{ $t('form.upload_preview')}}
          </template>
        </a>
      </div>
      <div v-else>
        <slot :name="field.name" ></slot>
      </div>
      <input name="upload" :id="`files_id_${field.name}`" class="files_id" type="file"
            :accept="field.accepted_file_types"
            v-on:change="fileChosen" ref="el">
      <div style="margin: 10px 0">
        <template v-if="value">
          <strong>{{ $t('click_here_to_choose_a_new_file') }}</strong><br/>
          <span>{{ $t('or_drag_newer_here') }}</span>
        </template>
        <template v-else>
          <strong>{{ $t('click_here_to_choose_a_file') }}</strong><br/>
          <span>{{ $t('or_drag_it_here') }}</span>
        </template>
      </div>
      <input type="text" :name="field.name" :required="field.required" ref="input"
            :class="{'gb-az-errors': field.errors}" class="input-file-url"
            :disabled="field.disabled"
            :placeholder="field.placeholder"
            :value="value"
            @input="$emit('input', $event.target.value)" >
    </label>
    <button v-if="field.allow_empty && value "
            v-on:click="clearUrl" class="gb-btn gb-btn-small">
      {{ $t('form.empty_upload') }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'InputFile',
  props: {
    field: {
      type: Object,
      required: true
    },
    raw: Boolean,
    value: [String, File]
  },
  data () {
    return {
      fileData: null
    }
  },
  methods: {
    _checkDragAndDropUpload () {
      let ok = true
      const div = document.createElement('div')
      if (!(('draggable' in div) || ('ondragstart' in div && 'ondrop' in div))) {
        ok = false
        // console.error('Drag and drop is not available')
      }
      if (!('FormData' in window)) {
        ok = false
        // console.error('FormData is not available')
      }
      if (!('FileReader' in window)) {
        ok = false
        // console.error('FileReader is not available and thus DataTransfer')
      }
      return ok
    },
    clearUrl () {
      this.$emit('input', '')
    },
    createFileURL (file) {
      return window.URL.createObjectURL(file)
    },
    fileChosen () {
      const files = this.$refs.el.files
      if (this.raw) {
        this.$emit('input', files && files[0])
      } else {
        this.fileData = new FormData()
        for (let i = 0; i < files.length; i++) {
          const file_ = files[i]
          this.fileData.append('upload', file_)
        }
        this.sendFile()
      }
    },
    onlyImage (acceptedFileTypes) {
      if (!acceptedFileTypes) {
        return false
      }
      const fileTypes = acceptedFileTypes.split(',')
      const imgFileTypes = ['image/gif', 'image/jpeg', 'image/png']
      let onlyImg = true
      fileTypes.forEach(fileType => {
        const striped = fileType.replace(/^\s+|\s+$/g, '')
        if (onlyImg === true && imgFileTypes.indexOf(striped) === -1) {
          onlyImg = false
        }
      })
      return onlyImg
    },
    sendFile () {
      this.$parent.resetErrors()
      if (typeof this.$parent.resetErrors === 'function') {
        this.$parent.resetErrors()
      }
      const hiddenField = this.$refs.input
      this.$store.dispatch('global/uploadFile', this.fileData)
        .then(data => {
          this.fileData = null
          this.$emit('input', data.metadata.url)
          hiddenField.value = data.metadata.url
          if (this.$parent.model && 'mimetype' in this.$parent.model) {
            this.$parent.model.mimetype = data.metadata.mimetype
          }
        }).catch(err => {
          // An error 413 is reported with status 0 :/
          if (err.status === 400 || err.status === 0) {
            this.$store.commit('global/updateError', this.$t('error.maximum_file_size_excedeed'))
          } else {
            this.$store.commit('global/handleHttpError', err)
          }
        })
    }
  },
  mounted () {
    const that = this
    window.setTimeout(function () {
      // Drag and drop
      if (that._checkDragAndDropUpload() === true) {
        const form = that.$el
        if (form !== null) {
          const prevDefaultEvents = function (e) {
            e.preventDefault()
            e.stopPropagation()
          }

          const addDragClass = function () {
            form.classList.add('is-dragover')
          }

          const removeDragClass = function () {
            form.classList.remove('is-dragover')
          }

          const events = 'drag dragstart dragend dragover dragenter dragleave drop'
          events.split(' ').forEach(function (e) {
            form.addEventListener(e, prevDefaultEvents, false)
          })

          const dragOnEvents = 'dragover dragenter'
          dragOnEvents.split(' ').forEach(function (e) {
            form.addEventListener(e, addDragClass, false)
          })

          const dragOffEvents = 'dragleave dragend drop'
          dragOffEvents.split(' ').forEach(function (e) {
            form.addEventListener(e, removeDragClass, false)
          })

          form.addEventListener('drop', function (e) {
            let authorized = true

            const files = e.dataTransfer.files

            if (that.raw) {
              that.$emit('input', files && files[0])
            } else {
              that.fileData = new FormData()
              for (let i = 0; i < files.length; i++) {
                const file_ = files[i]
                that.fileData.append('upload', file_)
                if (file_.size > that.maxFileSize) {
                  authorized = false
                }
              }
              if (authorized) {
                that.sendFile()
              }
            }
          })
        }
      }
    }, 250)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.file-drag-drop {
  background-color: $grey-light;
  border: 2px dashed $grey;
  padding: 25px;
  border-radius: 5px;
  text-align: center;

  label {
    color: $content-light;
    text-align: center;
    display: block;
    margin: 0;

    & > div:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    img {
      max-height: 60px;
      max-width: 60px;
    }
  }

  button.gb-btn.gb-btn-small {
    margin: 0 auto !important;
    float: none !important;
  }

  .files_id {
    display: none;
  }

  &[disabled="disabled"] {
    background-color: lighten($color: $grey, $amount: 5%);

    &:hover {
      cursor: not-allowed;
    }

    label {
      pointer-events: none;
    }
  }

  .input-file-url {
    width: 1px;
    height: 1px;
    position: absolute;
    overflow: hidden;
    opacity: 0;
  }
}

</style>
