import Vue from 'vue'

import { Diagram, Connector, NodeModel, Node } from '@syncfusion/ej2-vue-diagrams'
import { GbCompare, GbCustomData, GbNode, GbNodes } from '@/types/botBuilder'

const annotationTemplate = (title: string, contentFormat?: boolean, height?: number): string => {
  title = title.replaceAll(/('|")/g, '\\$1') || '&nbsp;'
  if (contentFormat) {
    return `<div class="gb-annotation">
              <p title="${title}">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                ${title}
              </p>
            </div>`
  } else {
    return `<div class="gb-annotation gb-annotation--bg">
              <p title="${title}" ${height ? 'class="force-height" style="height: ' + height + 'px"' : ''}>
                ${title}
              </p>
            </div>`
  }
}

const compare = (a: GbCompare, b: GbCompare): number => {
  if (a.left && b.left) {
    if (a.left < b.left) {
      return -1
    }
    if (a.left > b.left) {
      return 1
    }
  }
  return 0
}

const compareTree = (entryPoints: Array<string> | undefined) => (a: GbCompare | any, b: GbCompare | any): number => {
  if (!a.title) {
    // Case of newly created trees without name
    return 1
  } else if (a.kind === 'init' && b.kind !== 'init') {
    return -1
  } else if (a.kind !== 'init' && b.kind === 'init') {
    return 1
  } else if (entryPoints && entryPoints.includes(a.key) && !entryPoints.includes(b.key)) {
    return -1
  } else if (entryPoints && !entryPoints.includes(a.key) && entryPoints.includes(b.key)) {
    return 1
  } else if (b.title) {
    return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
  }
  return 0
}

const getNodeChilds = (node: GbNode, allNodes: GbNodes, result: GbNodes, $store: any): void => {
  const nodeList = JSON.parse(JSON.stringify(
    $store.getters['botBuilder/getNodeList']))
  if (node && !nodeList.includes(node.key)) {
    nodeList.push(node.key)
    $store.commit('botBuilder/setNodeList', nodeList)
    result[node.key] = node
    node.childs.forEach((child: string) => {
      if (allNodes[child] !== undefined) {
        getNodeChilds(allNodes[child], allNodes, result, $store)
      }
    })
  }
}

const nl2br = (text: string): string => {
  if (typeof text === 'string') {
    let res = ''
    text.split('\n').forEach((item: string) => {
      res += item + '<br>'
    })
    return res
  }
  return ''
}

const removeRecursive = (diagram: Diagram, node: NodeModel): void => {
  if ((node as any).outEdges.length) {
    (node as any).outEdges.concat([]).forEach((connectorId: string) => {
      const connector = diagram.getObject(connectorId)
      const node = diagram.getObject((connector as any).targetID)
      removeRecursive(diagram, node)
    })
  }

  if ((node as any).inEdges.length) {
    (node as any).inEdges.concat([]).forEach((connectorId: string) => {
      const connector = diagram.getObject(connectorId)
      diagram.remove(connector)
    })
  }

  diagram.remove(node)
}

const rootValidate = (src: NodeModel): any => {
  // console.log('SRC', src)
  const validation = {
    ok: true,
    message: ''
  }
  const customData: GbCustomData = (src.data as any).customData
  if (customData.action === 'totree') {
    validation.ok = false
    validation.message = 'diagram.errors.bad_validation_node_totree_cant_be_root'
  }
  if (validation.ok === true && customData.action === 'choice') {
    validation.ok = false
    validation.message = 'diagram.errors.bad_validation_node_choice_cant_be_root'
  }
  if (validation.ok === true && customData.action === 'choice_fallback') {
    validation.ok = false
    validation.message = 'diagram.errors.bad_validation_node_choice_fallback_cant_be_root'
  }
  if (validation.ok === true && (
    customData.action === 'input_check' ||
    customData.action === 'input_error')) {
    validation.ok = false
    validation.message = 'diagram.errors.bad_validation_node_input_cant_be_root'
  }
  if (validation.ok === true &&
    ['messenger_optin_ko', 'messenger_optin_ok'].includes(customData.action)) {
    validation.ok = false
    validation.message = 'diagram.errors.bad_validation_node_messenger_optin_output_cant_be_root'
  }
  return validation
}

// /////////////////////////////////////////////////////////////////////
// BB-VALIDATOR:
// /////////////////////////////////////////////////////////////////////
// Please check all comment "BB-VALIDATOR" to ensure to every
// validator is correctly maintened before validate any modification
// /////////////////////////////////////////////////////////////////////
const validate = (diagram: Diagram, src: NodeModel, target?: Node | Connector, childIdToIgnore?: string, vue?: Vue): any => {
  const validation: { ok: boolean, message: string | Record<string, any> } = {
    ok: true,
    message: ''
  }
  if (!target || (target instanceof Node && !(target as any).data.uuid) || src.id === target.id) {
    validation.ok = false
    validation.message = {
      body: 'diagram.errors.bad_validation_cant_drop_node_on_itself',
      icon: 'warning',
      title: 'diagram.errors.warning'
    }
  } else {
    const children = [] as Array<NodeModel>
    if (target instanceof Node) {
      const outEdges = (target as any).outEdges
      outEdges.forEach((connectorId: string) => {
        diagram.connectors.forEach((connector) => {
          if (connector.id === connectorId) {
            diagram.nodes.forEach((node: NodeModel) => {
              // console.log(node, '===', connector.targetID)
              if (node.id === connector.targetID && node.id !== childIdToIgnore) {
                children.push(node)
              }
            })
          }
        })
      })
      if ((target.data as any).customData.action === 'dialogflow') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_dialogflow_forbidden_kinds',
          icon: 'call_split',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if ((target.data as any).customData.action === 'ravana') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_ravana_forbidden_kinds',
          icon: 'warning',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if ((target.data as any).customData.action === 'totree') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_totree_cant_have_children',
          icon: 'call_split',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if (validation.ok === true &&
          ['input_check', 'input_error'].indexOf((src.data as any).customData.action) > -1 &&
          (target.data as any).customData.action !== 'input') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_input_check_error_only_child_input',
          icon: 'keyboard',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if (validation.ok === true &&
          ['messenger_optin_ko', 'messenger_optin_ok'].includes((src.data as any).customData.action) &&
          (target.data as any).customData.action !== 'messenger_optin_request') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_messenger_optin_output_only_child_messenger_optin_request',
          icon: 'keyboard',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if (validation.ok === true &&
          (src.data as any).customData.action !== 'passthrough' &&
          (target.data as any).customData.action === 'routing_load_balancer') {
        validation.ok = false
        validation.message = {
          body: 'diagram.errors.bad_validation_node_routing_only_child_output',
          icon: 'settings',
          title: 'diagram.errors.unauthorized_action'
        }
      }
      if (validation.ok === true && (target.data as any).customData.action === 'input') {
        // node parents with action equal to "input"
        if (['input_check', 'input_error'].indexOf((src.data as any).customData.action) === -1) {
          validation.ok = false
          validation.message = {
            body: 'diagram.errors.bad_validation_node_input_requires_check_error',
            icon: 'keyboard',
            title: 'diagram.errors.unauthorized_action'
          }
        }
        if (validation.ok === true && (src.data as any).customData.action === 'input_error' &&
            children.length > 0) {
          let hasAlreadyInputErr = false
          children.forEach((child) => {
            if ((child.data as any).customData.action === 'input_error') {
              hasAlreadyInputErr = true
            }
          })
          if (hasAlreadyInputErr) {
            validation.ok = false
            validation.message = 'diagram.errors.bad_validation_node_input_only_one_error'
          }
        }
      }
      if (validation.ok === true && (target.data as any).customData.action === 'button') {
        // node parents with action equal to "button" can only have
        // "choice(_fallback)" children
        if ((src.data as any).customData.action !== 'choice' && (
          (src.data as any).customData.action !== 'choice_fallback' ||
          children.some(child => (child as any).data.customData.action === 'choice_fallback')
        )) {
          validation.ok = false
          validation.message = {
            body: 'diagram.errors.bad_validation_node_button_requires_choice',
            icon: 'done',
            title: 'diagram.errors.unauthorized_action'
          }
        }
        if (vue && vue.$route.params.channelId === 'whatsapp') {
          let countChoiceChild = 0
          children.forEach((child: NodeModel) => {
            if ((child as any).data.customData.action === 'choice') {
              countChoiceChild++
            }
          })
          if (countChoiceChild > 9) {
            validation.ok = false
            validation.message = 'diagram.errors.bad_validation_node_too_much_choice_wa_list_picker'
          }
        }
      }
      if (validation.ok === true && children.length > 0) {
        if ((target.data as any).customData.action === 'button' ||
            (target.data as any).customData.action === 'input') {
          // continue
        } else if ((target.data as any).customData.action === 'messenger_optin_request' &&
          !['choice', 'choice_fallback'].includes((src.data as any).customData.action)) {
          let countMessengerOptinKOChild = 0
          let countMessengerOptinOKChild = 0
          let countMessengerOtherChild = 0

          children.forEach((child: NodeModel) => {
            if ((child as any).data.customData.action === 'messenger_optin_ko') {
              countMessengerOptinKOChild++
            } else if ((child as any).data.customData.action === 'messenger_optin_ok') {
              countMessengerOptinOKChild++
            } else {
              countMessengerOtherChild++
            }

            if (
              ((src.data as any).customData.action === 'messenger_optin_ok' && countMessengerOptinOKChild + countMessengerOtherChild >= 1) ||
              ((src.data as any).customData.action === 'messenger_optin_ko' && countMessengerOptinKOChild + countMessengerOtherChild >= 1) ||
              (!['messenger_optin_ko', 'messenger_optin_ok'].includes((src.data as any).customData.action) && children.length >= 1)
            ) {
              validation.ok = false
              validation.message = {
                body: 'diagram.errors.bad_validation_node_messenger_optin_request_too_much_children',
                icon: 'warning',
                title: 'diagram.errors.unauthorized_action'
              }
            }
          })
        } else {
          let countChoiceChild = 0
          let countChoiceFallbackChild = 0
          children.forEach((child: NodeModel) => {
            if ((child as any).data.customData.action === 'choice') {
              countChoiceChild++
            } else if ((child as any).data.customData.action === 'choice_fallback') {
              countChoiceFallbackChild++
            }
          })

          if (children.length === countChoiceChild + countChoiceFallbackChild &&
            (
              (src.data as any).customData.action === 'choice' ||
              (
                (src.data as any).customData.action === 'choice_fallback' &&
                children.every(child => (child as any).data.customData.action !== 'choice_fallback')
              )
            )) {
            // console.log('VALIDATE OK', countChoiceChild)
            // console.log('VUE', vue)
            if (vue && vue.$route.params.channelId === 'whatsapp' && countChoiceChild > 2) {
              validation.ok = false
              validation.message = 'diagram.errors.bad_validation_node_too_much_choice_wa'
            }
          } else {
            validation.ok = false
            validation.message = {
              body: (target.data as any).customData.action === 'messenger_optin_request'
                ? 'diagram.errors.bad_validation_node_messenger_optin_request_too_much_children'
                : 'diagram.errors.bad_validation_node_not_button_too_much_children',
              icon: 'warning',
              title: 'diagram.errors.unauthorized_action'
            }
          }
        }
      }
      if (validation.ok === true && ['campaign_template', 'entrypoint'].includes((target.data as any).customData.action)) {
        validation.ok = false
        validation.message = 'diagram.errors.bad_validation_node_entrypoints_cant_has_different_children'
      }
    } else {
      if ((src as any).inEdges.length > 0 || (src as any).outEdges.length > 0) {
        validation.ok = false
        validation.message = 'diagram.errors.bad_validation_existing_node_cant_drop_on_connector'
      } else if (validation.ok === true) {
        const childNode: NodeModel | undefined = diagram.nodes.find(
          (node: any) => node.inEdges.includes(target.id)
        )
        const parentNode: NodeModel | undefined = diagram.nodes.find(
          (node: any) => node.outEdges.includes(target.id)
        )
        if (childNode && parentNode) {
          const parentValidation = validate(diagram, src, parentNode as Node, childNode.id)
          const childValidation = validate(diagram, childNode as NodeModel, src as Node)

          if (parentValidation.ok === false) {
            validation.ok = parentValidation.ok
            validation.message = parentValidation.message
          } else if (childValidation.ok === false) {
            validation.ok = childValidation.ok
            validation.message = childValidation.message
          }
        }
      }
    }
  }
  return validation
}

export {
  annotationTemplate,
  compare,
  compareTree,
  getNodeChilds,
  nl2br,
  rootValidate,
  removeRecursive,
  validate
}
