const state = {
  data: null,
  categories: [],
  record_url: null
}

const getters = {
  data () {
    return state.data
  },
  recordUrl () {
    return state.record_url
  }
}

const actions = {
  agentInput ({ commit, dispatch }, data) {
    commit('updateData', data)
    dispatch('GBA/global/showModal', 'easiware-agent-input', { root: true })
  },
  joinRoom (_, data) {
    window.open(data.payload.client_url, '_blank')
  },
  showRecordUrl ({ commit, dispatch }, data) {
    commit('updateRecordUrl', data.payload.record_url)
    dispatch('GBA/global/showModal', 'easiware-record-url', { root: true })
  }
}

const mutations = {
  updateData (state, data) {
    state.data = data
  },
  updateRecordUrl (state, data) {
    state.record_url = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
