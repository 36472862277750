<template>
  <textarea :rows="field.rows || 3" :name="field.name" :required="field.required"
    :class="{'gb-az-errors': field.errors}"
    :disabled="field.disabled"
    :placeholder="field.placeholder"
    :maxlength="field.maxLength"
    :value="value"
    v-on="listeners">
  </textarea>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'

@Component
export default class Textarea extends Vue {
  @Prop() field!: Record<string, any>
  @Prop() value?: string

  get listeners (): Record<string, (e: Event) => unknown> {
    return {
      ...this.$listeners,
      input: (e: Event) => this.$emit('input', (e.target as HTMLTextAreaElement).value)
    }
  }

  mounted (): void {
    this.updateSize()
  }

  updateSize (): void {
    if (this.field.autosize !== true) return

    requestAnimationFrame(() => {
      const el = this.$el as HTMLElement
      const style = (el as any).currentStyle || window.getComputedStyle(el)
      const borders = (parseFloat(style.borderTopWidth) || 0) + (parseFloat(style.borderBottomWidth) || 0)

      el.style.height = ''
      el.style.height = el.scrollHeight + borders + 'px'
    })
  }

  @Watch('value')
  watchValue (): void {
    this.updateSize()
  }
}
</script>

<style lang="scss" scoped>
textarea {
  display: block;
}
</style>
