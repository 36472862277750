import Vue from 'vue'

const state = {
  cards: null,
  billing_details: null,
  invoices: null,
  paymentIntentId: null,
  stripeReady: false
}

const getters = {
  cards () {
    return state.cards
  },
  billingDetails () {
    return state.billing_details
  },
  invoices () {
    return state.invoices
  },
  paymentIntentId (state) {
    return state.paymentIntentId
  },
  stripeReady () {
    return state.stripeReady
  }
}

const mutations = {
  setCards (state, data) {
    state.cards = data
  },
  setBillingDetails (state, data) {
    state.billing_details = data
  },
  setInvoices (state, data) {
    state.invoices = data
  },
  setPaymentIntentId (state, data) {
    state.paymentIntentId = data
  },
  setStripeIsReady (state, value) {
    state.stripeReady = value
  }
}

const actions = {
  createStripeCustomer: () => {
    return Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/customer/`, null)
  },
  fetchBillingDetails: ({ commit }) => {
    return Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/billingdetails/`)
      .then(response => response.json())
      .then((data) => {
        commit('setBillingDetails', data)
      })
      .catch((err) => {
        commit('global/handleHttpError', err, { root: true })
      })
  },
  fetchCards: ({ commit }) => {
    return Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/paymentmethods/`)
      .then(response => response.json())
      .then((data) => {
        commit('setCards', data)
      })
      .catch((err) => {
        commit('global/handleHttpError', err, { root: true })
      })
  },
  fetchInvoices: ({ commit }) => {
    return Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/invoices/`)
      .catch((err) => {
        commit('global/handleHttpError', err, { root: true })
      })
      .then(response => response.json())
      .then((data) => {
        commit('setInvoices', data)
      })
  },
  fetchTaxes: (_, countryCode) => {
    return Vue.http.get(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/taxrates/?country=${countryCode}`)
      .then(response => response.json())
  },
  removeCard: (_, paymentMethodId) => {
    return Vue.http.delete(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/paymentmethods/`, {
      body: { paymentmethod_id: paymentMethodId }
    })
  },
  setBillingDetails: (_, details) => {
    return Vue.http.post(
      `${process.env.VUE_APP_BACKEND_URL}/payment/stripe/billingdetails/`, details
    )
  },
  setDefaultCard: (_, paymentMethodId) => {
    return Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/paymentmethods/default/`, {
      paymentmethod_id: paymentMethodId
    })
  },
  setupStripePaymentMethod: (_, paymentMethodId) => {
    return Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/paymentmethods/setup/`, {
      paymentmethod_id: paymentMethodId
    })
  },
  attachStripePaymentMethod: (_, paymentMethodId) => {
    return Vue.http.post(`${process.env.VUE_APP_BACKEND_URL}/payment/stripe/paymentmethods/attach/`, {
      paymentmethod_id: paymentMethodId
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
