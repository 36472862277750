import Vue from 'vue'
import $settings from '@/settings'

// initial state
const state = {
  action: '',
  error: null,
  httpErr: null,
  iframeSrc: null,
  kind: '',
  modalConfirm: null,
  modalProps: null,
  modalShown: false,
  modalOverlayShown: false,
  navEnabled: true,
  navGbaEnabled: true,
  notificationCtx: null,
  notificationShown: false,
  overlayShown: false,
  userMenuShown: false,
  loadingShown: false,
  roles: null,
  settings: $settings
}

// getters
const getters = {
  getAction () {
    return state.action
  },
  getError () {
    return state.error
  },
  getModalConfirm () {
    return state.modalConfirm
  },
  getModalProps () {
    return state.modalProps
  },
  getNotificationCtx () {
    return state.notificationCtx
  },
  iframeSrc (state) {
    return state.iframeSrc
  },
  isLoadingShown () {
    return state.loadingShown
  },
  isModalShown () {
    return state.modalShown
  },
  isNavEnabled (state) {
    return state.navEnabled
  },
  isNavGbaEnabled (state) {
    return state.navGbaEnabled
  },
  isNotificationShown () {
    return state.notificationShown
  },
  isOverlayShown () {
    return state.overlayShown
  },
  isModalOverlayShown () {
    return state.modalOverlayShown
  },
  isUserMenuShown () {
    return state.userMenuShown
  },
  kind (state) {
    return state.kind
  },
  roles () {
    return state.roles
  },
  settings () {
    return state.settings
  }
}

// actions
const actions = {
  fetchRoles ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      this._vm.$http.get(`${process.env.VUE_APP_BACKEND_URL}/groups/list-roles`)
        .then(response => response.json())
        .then((data) => {
          commit('updateRoles', data)
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  handleHttpError ({ commit, dispatch }, httpErr) {
    commit('handleHttpError', httpErr)

    // console.log('STATUS', httpErr.status, '???', httpErr.body)
    if (httpErr.status === 401 || httpErr.status === 403) {
      dispatch('user/logout', null, { root: true })
    } else if (httpErr.status === 404) {
      // Display a 404 page
    } else if (httpErr.status === 500) {
      // Display a 500 page
    }
  },
  hideLoading ({ commit }) {
    commit('updateLoadingShown', false)
  },
  hideModal ({ commit }) {
    commit('updateModalShown', false)
    window.setTimeout(() => {
      commit('updateModalOverlayShown', false)
    }, 200)
  },
  notification ({ commit }, message) {
    commit('updateNotificationCtx', message)
    commit('updateNotificationShown', true)
  },
  showLoading ({ commit }) {
    commit('updateLoadingShown', true)
  },
  showModal ({ dispatch, commit }, action) {
    if (typeof action === 'object') {
      commit('updateModalConfirm', action.modalConfirm || null)
      commit('updateModalProps', action.modalProps || null)
      action = action.action
    }

    commit('updateAction', action)
    dispatch('toggleModalShown', true)
    // commit('hideEmojiPicker')
  },
  toggleModalShown ({ commit }, state) {
    commit('updateModalShown', state)
    window.setTimeout(() => {
      commit('updateModalOverlayShown', state)
    }, 200)
  },

  uploadFile: ({ dispatch }, args) => {
    return new Promise((resolve, reject) => {
      Vue.http.post(process.env.VUE_APP_UPLOAD_URL, args)
        .then(response => response.json()).then((data) => {
          // commit('setChannel', data)
          dispatch('notification', 'upload ok!')
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

// mutations
const mutations = {
  handleHttpError (state, httpErr) {
    state.httpErr = httpErr
  },
  hideModalOverlay (state) {
    state.modalShown = false
  },
  hideOverlay (state) {
    state.overlayShown = false
  },
  hideUserMenu (state) {
    state.userMenuShown = false
  },
  iframeSrc (state, url) {
    state.iframeSrc = url
  },
  kind (state, kind) {
    state.kind = kind
  },
  navEnabled (state, value) {
    state.navEnabled = value
  },
  navGbaEnabled (state, value) {
    state.navGbaEnabled = value
  },
  resetAction (state) {
    state.action = ''
  },
  resetModalConfirm (state) {
    state.modalConfirm = null
  },
  resetModalProps (state) {
    state.modalProps = null
  },
  setBBToken (state, token) {
    this._vm.$set(state.settings, 'token', token)
  },
  showOverlay (state) {
    state.overlayShown = true
  },
  updateAction (state, action) {
    state.action = action
  },
  updateError (state, error) {
    state.error = error
  },
  updateModalConfirm (state, modalConfirm) {
    state.modalConfirm = modalConfirm
  },
  updateModalProps (state, modalProps) {
    state.modalProps = modalProps
  },
  updateModalShown (state, value) {
    if (value === undefined) {
      state.modalShown = !state.modalShown
    } else {
      state.modalShown = value
    }
  },
  updateModalOverlayShown (state, value) {
    if (value === undefined) {
      state.modalOverlayShown = !state.modalOverlayShown
    } else {
      state.modalOverlayShown = value
    }
  },
  updateNotificationCtx (state, value) {
    state.notificationCtx = value
  },
  updateNotificationShown (state, value) {
    state.notificationShown = value !== undefined ? value : !state.notificationShown
  },
  updateOverlayShown (state) {
    state.overlayShown = !state.overlayShown
  },
  updateRoles (state, data) {
    state.roles = data.roles
  },
  updateRoomMenuShown (state) {
    state.roomMenuShown = !state.roomMenuShown
  },
  updateUserMenuShown (state, force) {
    state.userMenuShown = force !== undefined ? !!force : !state.userMenuShown
  },
  updateLoadingShown (state, value) {
    if (value === undefined) {
      state.loadingShown = !state.loadingShown
    } else {
      state.loadingShown = value
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
