// initial state
const state = {
  isAllowedToPush: false,
  stats: null,
  token: null,
  user: null,
  isLocked: false
}

// getters
const getters = {
  isAllowedToPush () {
    return state.isAllowedToPush
  },
  isUserAutofill () {
    if (state.user) {
      return state.user.auto_fill
    }
  },
  isUserPaused () {
    if (state.user) {
      return state.user.paused
    }
  },
  isUserAgent () {
    if (state.user && state.user.kind === 'agent') {
      return true
    }
    return false
  },
  isUserManager () {
    if (state.user && state.user.kind === 'manager') {
      return true
    }
    return false
  },
  isUserSupervisor () {
    if (state.user && state.user.kind === 'supervisor') {
      return true
    }
    return false
  },
  getAvatar () {
    if (state.user) {
      return state.user.avatar
    }
  },
  getHeaderStats () {
    return state.stats
  },
  getSkills () {
    if (state.user) {
      return state.user.customer_skills
    }
  },
  getToken () {
    return state.token
  },
  getUser () {
    return state.user
  },
  getUsername () {
    if (state.user) {
      return state.user.full_name
    }
  },
  getUserSkills () {
    if (state.user) {
      return state.user.skills
    }
  },
  isUserLocked () {
    return state.isLocked
  }
}

// actions
const actions = {
  // eslint-disable-next-line
  sendChangePassword ({}, data) {
    this._vm.$socket.send(data)
  },
  getHeaderStats () {
    this._vm.$socket.send({
      cmd: 'get_header_stats'
    })
  },
  sendAutofill ({ dispatch }, autofill) {
    return new Promise((resolve, reject) => {
      this._vm.$http.post(`${process.env.VUE_APP_GBA_URL}/agents/auto_fill`, {
        auto_fill: autofill
      })
        .then(response => response.json())
        .then(data => {
          resolve(data)
        }).catch((err) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  // eslint-disable-next-line
  sendPause ({}, pause) {
    this._vm.$socket.send({
      cmd: 'pause',
      pause: pause
    })
  },
  // eslint-disable-next-line
  sendResetPassword ({}, data) {
    this._vm.$socket.send(data)
  },
  sendSettings ({ commit, state }, data) {
    const settings = Object.assign({}, state.user)
    settings[data.prop] = data.value
    // console.log('UPDATED SETTINGS', settings, data)
    commit('updateUser', {
      data: settings
    })
    this._vm.$socket.send({
      cmd: 'settings',
      update: settings
    })
  },
  sendUser () {
    this._vm.$socket.send({
      cmd: 'settings'
    })
  },
  updateUserLock ({ commit, state }, data) {
    if (state.isLocked !== data) {
      commit('updateUserLock', data)
    }
  }
}

// mutations
const mutations = {
  logout (state, prevent) {
    localStorage.removeItem('token')
    localStorage.removeItem('GBAtoken')
    state.token = null
    state.user = null
    if (prevent !== true) window.location.href = '/'
  },
  updateAvatar (state, filename) {
    state.user.avatar = filename
  },
  updateHeaderStats (state, data) {
    state.stats = data.data
  },
  updateIsAllowedToPush (state, value) {
    state.isAllowedToPush = value
  },
  updatePause (state, pause) {
    state.user.paused = pause
  },
  updateToken (state, token) {
    localStorage.setItem('GBAtoken', token)
    state.token = token
  },
  updateUser (state, data) {
    let fullName = `${data.data.first_name} ${data.data.last_name}`
    if (fullName === ' ') {
      fullName = data.data.email
    }
    state.user = Object.assign({}, data.data, { full_name: fullName })
  },
  updateUserLock (state, data) {
    state.isLocked = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
