import store from '@/store'
import { GuestIdleEvent } from '@/types/guests'

function updateRoomGuestIdle (e: GuestIdleEvent, idle: boolean): void {
  const currentRoom = store.getters['GBA/rooms/getRoom']
  if (currentRoom?.uuid === e.room_id) {
    store.commit('GBA/rooms/updateRoomData', { session_guest_idle: idle })
  }
  store.commit('GBA/rooms/updateRoomInList', {
    current_status: e.state,
    room: {
      session_guest_idle: idle,
      uuid: e.room_id
    }
  })
}

export default {
  'guests:session:guest_idle' (e: GuestIdleEvent): void {
    updateRoomGuestIdle(e, true)
  },
  'guests:session:guest_idle_end' (e: GuestIdleEvent): void {
    updateRoomGuestIdle(e, false)
  }
} as Record<string, (e: any) => void>
