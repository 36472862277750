import { Agent, Filters, Platform } from '@/types/statsReviews'
import Vue from 'vue'
import { Commit, Dispatch } from 'vuex'

interface State {
  agents: Array<Agent> | null
  platforms: Array<Platform> | null
  platformsScore: Record<string, number> | null
  reviewsPerStatus: Record<string, number> | null
}

// initial state
const state: State = {
  agents: null,
  platforms: null,
  platformsScore: null,
  reviewsPerStatus: null
}

// getters
const getters = {
  agents (): Array<Agent> | null {
    return state.agents
  },
  platforms (): Array<Platform> | null {
    return state.platforms
  },
  platformsScore (): Record<string, number> | null {
    return state.platformsScore
  },
  reviewsPerStatus (): Record<string, number> | null {
    return state.reviewsPerStatus
  }
}

// mutations
const mutations = {
  agents (state: State, data: Array<Agent> | null): void {
    state.agents = data
  },
  platforms (state: State, data: Array<Platform> | null): void {
    state.platforms = data
  },
  platformsScore (state: State, data: Record<string, number> | null): void {
    state.platformsScore = data
  },
  reviewsPerStatus (state: State, data: Record<string, number> | null): void {
    state.reviewsPerStatus = data
  }
}

// actions
const actions = {
  export: ({ dispatch }: { dispatch: Dispatch }, params: Record<string, any>): void => {
    dispatch('global/showLoading', null, { root: true })
    return Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/reviews/export/events`, {
      params,
      responseType: 'blob'
    })
      .then((response: Response) => response.blob())
      .then((blob: Blob) => {
        const a = document.createElement('a')
        const url = window.URL.createObjectURL(blob)

        a.style.display = 'none'
        a.href = url
        a.download = 'reviews-events.csv'

        document.body.appendChild(a)

        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }).catch((err: Error) => {
        dispatch('global/handleHttpError', err, { root: true })
      }).finally(() => {
        dispatch('global/hideLoading', null, { root: true })
      })
  },
  fetchAgents: ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}, params: Filters): Promise<Array<Agent>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/reviews/dashboard/agents`, { params })
        .then((response: Response) => response.json())
        .then((data: Array<Agent>) => {
          commit('agents', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchPlatforms: ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}, params: Filters): Promise<Array<Platform>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/reviews/dashboard/platforms`, { params })
        .then((response: Response) => response.json())
        .then((data: Array<Platform>) => {
          commit('platforms', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchPlatformsScore: ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}, params: Filters): Promise<Record<string, number>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/reviews/dashboard/platforms-score`, { params })
        .then((response: Response) => response.json())
        .then((data: Record<string, number>) => {
          commit('platformsScore', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchReviewsPerStatus: ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch}, params: Filters): Promise<Record<string, number>> => {
    return new Promise((resolve, reject) => {
      Vue.http.get(`${process.env.VUE_APP_STATS_GO_URL}/reviews/dashboard/reviews-per-status`, { params })
        .then((response: Response) => response.json())
        .then((data: Record<string, number>) => {
          commit('reviewsPerStatus', data)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
