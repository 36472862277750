<template>
  <header :class="{hidden: isChatView}">
    <div>
      <div>
        <ul>
          <nav-link class="nav-link" :route="{name: 'dashboard'}">
            <img v-if="$settings.saas" src="@/assets/img/logo.png" alt="Logo ChayAll">
            <img v-else src="@/assets/img/logo-gb.png" alt="Logo Greenbureau">
          </nav-link>
        </ul>
        <div>
          <Breadcrumb />
          <UserStats id="gba-stats" :class="{ saas: $settings.saas }" v-if="isGBA"/>
        </div>
      </div>
      <div>
        <GBAInfos v-if="isGBA"/>
        <user-infos />
      </div>
    </div>
    <Alert ref="AlertEmail" class="alert"
           v-if="hasConfirmEmail === false && $route.name !== 'confirm-email'">
      <span>{{ $t('dashboard.please_confirm_email') }}</span>
      <button class="gb-btn alert-btn" v-on:click="resend()">
        {{ $t('resend_email') }}</button>
    </Alert>
    <Alert ref="AlertQuota" class="alert"
           v-if="isOverQuota === true && $route.name !== 'settings-subscription'">
      <span>{{ $t('dashboard.over_quota') }}</span>
      <button class="gb-btn alert-btn" v-on:click="$router.push({ name: 'settings-subscription' })">
        {{ $t('view_offers') }}</button>
    </Alert>
  </header>
</template>

<script type="text/javascript">
import Alert from '@/components/Alert.vue'
import Breadcrumb from '@/components/Breadcrumb'
import GBAInfos from '@/components/GBAInfos.vue'
import GbAgent from '@/mixins/GbAgent.js'
import NavLink from '@/components/NavLink.vue'
import UserInfos from '@/components/UserInfos'
import UserStats from '@/layout/UserStats.vue'

export default {
  components: { Alert, Breadcrumb, GBAInfos, NavLink, UserInfos, UserStats },
  mixins: [GbAgent],
  computed: {
    currentRoute () {
      return this.$route.matched[0].name
    },
    hasConfirmEmail () {
      if (this.user === null) {
        return true
      }
      return this.user.has_confirm_email
    },
    isOverQuota () {
      return this.customer !== null && this.customer.over_quota === true
    },
    isGBA () {
      return this.currentRoute === 'gb-agent'
    },
    user () {
      return this.$store.getters['user/user']
    },
    customer () {
      return this.$store.getters['user/customer']
    }
  },
  methods: {
    resend () {
      this.$store.dispatch('user/sendEmailValidate', {
        email: this.$store.getters['user/user'].email
      })
      this.$refs.AlertEmail.close()
      this.$store.dispatch('global/notification', 'email_resent')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/global.scss";

header {
    width: 100%;
    padding: 27px $padding;
    position: relative;
    z-index: 9;

    &:after {
      border-bottom: 1px solid $grey-light;
      bottom: 0;
      content: '';
      display: block;
      left: $padding;
      position: absolute;
      width: calc(100% - #{$padding * 2});
    }

    @media (max-width: $break-large * 1.3) {
        padding: 27px $padding * .5;

        &:after {
          left: $padding * .5;
          width: calc(100% - #{$padding});
        }
    }

    @media (max-width: $break-large) {
        padding: 20px $padding-min;
        background-color: $grey-light;

        &.hidden {
          display: none;
        }

        &:after {
          left: $padding-min;
          width: calc(100% - #{$padding-min * 2});
        }
    }

    & > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;

        &:first-child > ul:first-child {
          display: none;
          padding: 0;
          margin: 0 18px 0 0;

          @media (max-width: $break-large) {
            display: block;
          }

          li {
            width: 45px;
            height: 45px;

            ::v-deep .icon {
              background-color: $secondary-light;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }

    ::v-deep .nav-link > .item.router-link-active > .icon {
      box-shadow: none;
    }

    .alert {
      margin-top: 20px;

      span {
        margin-right: 35px;
      }
    }

    .alert-btn {
      font-size: 14px;
      line-height: 30px;
      height: 30px;
      padding: 0 14px;
      vertical-align: middle;
    }
}

#gba-stats {
  background-color: transparent;
  color: $content-light;
  justify-content: flex-start;
  height: auto;
  flex-wrap: wrap;
  line-height: 1;

  @media (max-width: $break-large) {
    order: 3
  }

  ::v-deep &.saas > div:nth-child(even) span {
    color: $secondary;
  }

  ::v-deep > div {
    width: auto;
    margin: 0;

    h3, span {
      display: inline-block;
      opacity: 1;
      font-weight: normal;
      font-size: 12px;
      padding: 0;
    }

    h3 {
      margin-right: 5px;
      margin-bottom: 0;
    }

    span {
      font-weight: bold;
      color: $primary
    }

    &:not(:last-child):after {
      content: '|';
      margin: 0 .4rem;
      display: inline-block;
      font-size: 12px;
    }

    @media (max-width: $break-large) {
      display: none;
    }
  }
}
</style>
