<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="cancelModal" />
      <h3 class="gb-title">
        {{ $t('modal.password_change_title') }}
      </h3>
    </div>

    <div class="modal-body">
      <form v-on:submit.prevent="submit">
        <form-gen :formDesc="formDesc" :model="model"
                  :sendData="sendData" :submit="submit" />
      </form>
    </div>

    <div class="modal-footer">
      <button class="gb-btn" type="submit" v-on:click="sendData = true" >
        {{ $t('password.change.send_button') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'
import FormView from '@/components/FormView.vue'

export default {
  extends: FormView,
  mixins: [BaseModal],
  name: 'PasswordChangeModal',
  components: {
    CloseModalButton
  },
  data () {
    return {
      formDesc: {
        nonFieldsErrors: null,
        fieldsets: [{
          legend: null,
          fields: [{
            kind: 'InputPassword',
            name: 'old_password',
            label: this.$t('password.change.old_password'),
            placeholder: this.$t('password.change.old_password'),
            validation: null,
            validationMsg: null,
            disabled: false,
            errors: null
          }, {
            kind: 'InputPassword',
            name: 'password1',
            label: this.$t('password.change.new_password'),
            placeholder: this.$t('password.change.new_password'),
            validation: null,
            validationMsg: null,
            disabled: false,
            errors: null
          }, {
            kind: 'InputPassword',
            name: 'password2',
            label: this.$t('password.change.confirm_new_password'),
            placeholder: this.$t('password.change.confirm_new_password'),
            validation: null,
            validationMsg: null,
            disabled: false,
            errors: null
          }]
        }]
      },
      hasErr: false,
      model: {
        oldPassword: '',
        password1: '',
        password2: ''
      },
      passwordChanged: false
    }
  },
  methods: {
    submit () {
      this.sendData = false
      if (this.model.password1 === this.model.password2) {
        const data = {
          old_password: this.model.old_password,
          password1: this.model.password1,
          password2: this.model.password2
        }
        this.$store.dispatch('user/settingsPasswordChange', data)
          .then(() => {
            this.closeModal()
            this.$store.dispatch(
              'global/notification', 'settings.account.password_changed')
          })
          .catch(err => {
            if (err.status === 400) {
              this.setErrors(err)
            } else {
              this.$store.commit('global/handleHttpError', err)
            }
          })
      } else {
        this.formDesc.nonFieldsErrors = ['password.passwords_mismatch']
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  width: 753px;
}
.modal-header {
  padding-bottom: 50px !important;
}
.gb-title {
  padding-left: 40px;
  padding-right: 40px;
}
form {
  text-align: left;
  max-width: 400px;
}
.modal-footer {
  text-align: right;
}
</style>
