// This store aims to handle Rich Links in message.
// This is a rather complexe feature as we have to
// 1 - Parse the string to find the URLs
// 2 - Grab data for each URLs using opengraph.io
// 3 - Build the resulting rich content and send it to the backend.
// Because step 2 can take some time, the agent can send a new message before
// the first one responds, so we have to handle it as a queue of messages.

import Vue from 'vue'
import VueResource from 'vue-resource'
import normalizeUrl from 'normalize-url'

import Urls from 'my-name-is-url/src/my-name-is-url'

Vue.use(VueResource)

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
}

const state = {
  queue: []
}

// getters
const getters = {
}

// actions
const actions = {
  async actionCrawlUrls ({ commit }, payload) {
    state.queue.forEach(item => {
      if (item.uuid === payload.uuid) {
        item.chunks.forEach(chunk => {
          if (chunk.kind === 'url') {
            const appId = payload.settings.opengraph
            const url = encodeURIComponent(normalizeUrl(chunk.url, { removeSingleSlash: false, removeTrailingSlash: false, stripWWW: false }))
            const updatedChunk = {
              hybridGraph: null,
              message: payload.message,
              roomUuid: payload.roomUuid,
              url: chunk.url,
              uuid: item.uuid
            }
            Vue.http.get(`https://opengraph.io/api/1.1/site/${url}?app_id=${appId}`, { timeout: 10000 })
              .then(response => {
                commit('updateChunk', Object.assign(updatedChunk, {
                  hybridGraph: response.body.hybridGraph
                }))
              }).catch(() => {
                // on error from opengraph, retry with proxy
                Vue.http.get(`https://opengraph.io/api/1.1/site/${url}?app_id=${appId}&use_proxy=true`, { timeout: 15000 })
                  .then(response => {
                    commit('updateChunk', Object.assign(updatedChunk, {
                      hybridGraph: response.body.hybridGraph
                    }))
                  }).catch(() => {
                    // on error from opengraph, just proceed to the next step
                    commit('updateChunk', updatedChunk)
                  })
              })
          }
        })
      }
    })
  },
  async actionFindChunks ({ commit }, payload) {
    return new Promise((resolve) => {
      commit('findChunks', {
        uuid: payload.uuid,
        message: payload.message,
        settings: payload.settings
      })
      resolve(true)
    })
  },
  async pushToQueue ({ dispatch, commit, state }, payload) {
    return new Promise((resolve) => {
      commit('setQueue', [])
      // 1/ generate uuid and add it to queue.
      const uuid = uuidv4()
      // 2/ pass uuid to findChunks so new items are added to the queue item with
      //    the id
      dispatch({
        type: 'actionFindChunks',
        uuid: uuid,
        message: payload.message,
        settings: payload.settings
      }).then(() => {
        // 3/ start crawling all url chunks
        state.queue.forEach(async (item) => {
          if (item.uuid === uuid) {
            if (item.totalUrls > 0) {
              await dispatch({
                type: 'actionCrawlUrls',
                uuid: uuid,
                message: payload.message,
                roomUuid: payload.roomUuid,
                settings: payload.settings
              })
            } else {
              item.chunks.forEach(chunk => {
                const content = chunk[0] ? chunk[0].txt : chunk.txt
                const richContent = chunk[0] ? chunk : [chunk]
                this._vm.$socket.send({
                  cmd: 'send_message',
                  room: payload.roomUuid,
                  content: content,
                  rich_content: richContent
                })
              })
              commit('setQueue', [])
            }
          }
        })
        resolve(true)
      })
    })
  }
}

// mutations
const mutations = {
  findChunks (state, data) {
    const value = data.message
    const res = {
      uuid: data.uuid,
      parsedUrls: 0,
      totalUrls: 0,
      chunks: []
    }
    const urls = Urls(value).get()
      .filter(url => url.startsWith('http://') || url.startsWith('https://'))
    if (urls.length === 0) {
      res.chunks.push([{
        kind: 'txt',
        txt: value
      }])
    } else {
      let last = 0
      urls.forEach((url, idx) => {
        const elt = value.substring(last, value.indexOf(url))
        if (elt !== '' && elt !== ' ') {
          res.chunks.push({
            kind: 'txt',
            txt: elt.trim()
          })
        }
        if (url.indexOf('@') > -1) {
          res.chunks.push({
            kind: 'txt',
            txt: url
          })
        } else {
          res.chunks.push({
            kind: 'url',
            url: url
          })
          res.totalUrls++
        }
        last = value.indexOf(url) + url.length
        if (idx === urls.length - 1 && last < value.length) {
           res.chunks.push({
            kind: 'txt',
            txt: value.substring(last, value.length)
          })
        }
      })
    }
    state.queue.push(res)
  },
  updateChunk (state, payload) {
    const that = this
    let idx = 0
    state.queue.forEach(item => {
      idx++
      if (item.uuid === payload.uuid) {
        item.chunks.forEach(chunk => {
          if (chunk.url === payload.url) {
            // payload.hybridGraph can be null when opengraph has returned an error
            const url = normalizeUrl(chunk.url, { removeSingleSlash: false, removeTrailingSlash: false, stripWWW: false })
            if (payload.hybridGraph !== null) {
              chunk.url = url
              chunk.txt = payload.hybridGraph.title || url
              chunk.img = payload.hybridGraph.image
            } else {
              chunk.url = url
              chunk.txt = url
              chunk.img = ''
            }
            item.parsedUrls++
          }
        })
        if (item.parsedUrls === item.totalUrls) {
          that._vm.$socket.send({
            cmd: 'send_message',
            room: payload.roomUuid,
            content: payload.message.trim(),
            rich_content: item.chunks
          })
          state.queue.splice(idx, 1)
        }
      }
    })
  },
  setQueue (state, queue) {
    state.queue = queue
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
