// This is the way the app shoud be polyfilled now that core-js3 replace
// babel.
// Why the fuck this can't be done in the config? All the tutorials give
// wrong solutions for now except this crappy one.
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './registerServiceWorker'
import './material-design-icons'

import '@/router/ComponentHooks' // Need to be imported before Vue

import Vue from 'vue'
import VueResource from 'vue-resource'
import i18n from '@/i18n'
import settings from '@/settings'
import singleSpaVue from 'single-spa-vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import App from './App.vue'
import router from './router'
import store from './store'

import VTooltip from 'v-tooltip'

import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'

import PortalVue from 'portal-vue'

import initHelpHero from 'helphero'

// GBA
import WS from '@gba/components/ws'
import GBA from '@gba/components/store/modules'

// DayJS
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'

import VuejsDialog from 'vuejs-dialog'

store.registerModule('GBA', GBA as any)

require('dayjs/locale/fr')
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(updateLocale)

function getDayjsLocale (number: number, withoutSuffix: boolean, key: string) {
  return i18n.t(`date.relativeTime.${key}${withoutSuffix ? '_short' : ''}`, [number])
}

const relativeTimeLocale = {
  s: getDayjsLocale,
  m: getDayjsLocale,
  mm: getDayjsLocale,
  h: getDayjsLocale,
  hh: getDayjsLocale,
  d: getDayjsLocale,
  dd: getDayjsLocale,
  M: getDayjsLocale,
  MM: getDayjsLocale,
  y: getDayjsLocale,
  yy: getDayjsLocale
}

Object.keys(i18n.messages).forEach((lang: string) => {
  dayjs.updateLocale(lang, {
    relativeTime: {
      future: i18n.t('date.relativeTime.future', lang),
      past: i18n.t('date.relativeTime.past', lang),
      ...relativeTimeLocale
    }
  })
})

Vue.prototype.$date = dayjs

Vue.filter('cdayjs', function (value: number, format: string, UTC: any) {
  return UTC
    ? dayjs.unix(value).utc().format(format)
    : dayjs.unix(value).format(format)
})

Vue.filter('percent', function (value: number) {
  return new Intl.NumberFormat('default', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value)
})

Vue.use(VTooltip)

Vue.config.productionTip = false

Vue.use(VueResource)

Vue.use(HighchartsVue)

Vue.use(PortalVue)

Vue.use(VuejsDialog)

exportingInit(Highcharts)
exportData(Highcharts)

declare global {
  interface Window {
    _GbChayallVm: Vue;
    Stripe: any;
  }
}

/*******/

const anonAllowedEndpoints = [
  '/email/validate',
  '/user/login'
]

// let startPathName = window.location.pathname
// if (window.location.pathname.lastIndexOf('/') > 0) {
//   startPathName = startPathName.substring(
//     0, window.location.pathname.indexOf('/', 1))
// }

Vue.http.interceptors.push((request: any) => {
  if (request) {
    const endPointName = request.url.substring(
      `${process.env.VUE_APP_BACKEND_URL}`.length)
    if (anonAllowedEndpoints.indexOf(endPointName) === -1) {
      if (request.headers.map.Authorization === undefined) {
        let token = store.getters['user/token']
        if (token === null || token === '') {
          token = localStorage.token ? localStorage.token : ''
          store.commit('user/token', token)
        }
        // store.commit('global/setBBToken', token)
        request.headers.set('Authorization', `Bearer ${token}`)
      }
    }
  }
})

// Fix Vue Resource GET params behavior: remove params name brackets
Vue.http.interceptors.push((request: any, next: () => void) => {
  if (request.method === 'GET') {
    const data = Object.assign({}, request.params)
    // eslint-disable-next-line no-cond-assign
    for (let i = 0, key; key = Object.keys(data)[i]; i++) {
      if (Array.isArray(data[key])) { request.url += '{?' + key + '*}' }
    }
  }
  next()
})

Vue.directive('focus', {
  inserted: function (el, e) {
    if (e.value === true) {
      el.focus()
    }
  }
})

function init () {
  Vue.prototype.$settings = settings

  if (process.env.VUE_APP_HELPHERO) {
    Vue.prototype.$helphero = initHelpHero(process.env.VUE_APP_HELPHERO)
  }

  if (Vue.prototype.$settings.sentry) {
    if (Vue.prototype.$settings.saas === true) {
      Sentry.init({ dsn: Vue.prototype.$settings.sentry })
    } else {
      // Enable Sentry performance feature
      Sentry.init({
        Vue: Vue,
        dsn: Vue.prototype.$settings.sentry,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        tracingOptions: {
          trackComponents: true
        }
      })
    }
  }

  if (Vue.prototype.$settings.saas === true) {
    if (typeof window.Stripe === 'function') {
      Vue.prototype.$stripe = window.Stripe(Vue.prototype.$settings.stripe_key)
    } else if (Vue.prototype.$settings.prod) {
      Sentry.captureException(new Error('Stripe initialization error'))
    }
  }

  Vue.prototype.$socket = WS(store, Vue.prototype.$settings, router)

  // window._GbChayallVm = new Vue({
  //   i18n,
  //   router,
  //   store,
  //   render: h => h(App)
  // }).$mount('#app')

  return singleSpaVue({
    Vue,
    appOptions: {
      i18n,
      router,
      store,
      render (h: any) {
        return h(App, {
          props: {
            // single-spa props are available on the "this" object. Forward them to your component as needed.
            // https://single-spa.js.org/docs/building-applications#lifecycle-props
            // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
            /*
            name: this.name,
            mountParcel: this.mountParcel,
            singleSpa: this.singleSpa,
            */
          }
        })
      }
    }
  })
}

const vueLifecycles = init()

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount

init()
