<template>
  <div class="datepicker-wrapper" ref="datepickerWrapper">
    <div class="gb-select">
      <span class="gb-field" type="text" @click="toggle" tabindex="0" :data-disabled="disabled">
        <span :class="{ placeholder: !formattedDate }">{{ formattedDate || placeholder || '-' }}</span>
      </span>
      <span class="chevron">
        <icon name="chevron_right"/>
      </span>
    </div>
    <portal to="root" ref="datepicker">
      <transition name="fade">
        <div class="datepicker" v-show="showDatepicker" :style="positionStyle">
          <div :class="{ 'no-padding': !presetPicker && !timePicker }">
            <ul v-if="range && presetPicker !== false" :style="heightStyle">
              <li :class="{ active: isDates('today') }" @click="setPreset('today')">{{ $t('datepicker.today') }}</li>
              <li :class="{ active: isDates('yesterday') }" @click="setPreset('yesterday')">{{ $t('datepicker.yesterday') }}</li>
              <li :class="{ active: isDates('last_week') }" @click="setPreset('last_week')">{{ $t('datepicker.last_week') }}</li>
              <li :class="{ active: isDates('last_month') }" @click="setPreset('last_month')">{{ $t('datepicker.last_month') }}</li>
              <li :class="{ active: isDates('last_year') }" @click="setPreset('last_year')">{{ $t('datepicker.last_year') }}</li>
              <li class="space"></li>
              <li class="reset" @click="setPreset('reset')">{{ $t('datepicker.reset') }}</li>
            </ul>
            <v-date-picker
              :value="dates"
              @input="setDates"
              color="gray"
              :is24hr="this.$t('date.datetimeFormat').slice(-1) !== 'A'"
              :is-range="range"
              :mode="timePicker ? 'dateTime' : 'date'"
              :locale="$root.$i18n.locale"
              :is-required="true"
              :available-dates="availableDates"
              @drag="updateMaxInterval"
              :style="heightStyle"
            />
          </div>
          <footer v-if="!closeOnSelect">
            <div>
              <button @click="cancel()" class="btn-cancel">{{ $t('datepicker.cancel') }}</button>
              <button @click="save()" class="btn-save">{{ $t('datepicker.save') }}</button>
            </div>
          </footer>
        </div>
      </transition>
    </portal>
  </div>
</template>

<script>
import Vue from 'vue'

import Dayjs from 'vue-dayjs'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'
import VCalendar from 'v-calendar'

Vue.use(Dayjs)
Vue.use(VCalendar)

export default {
  name: 'Datepicker',
  props: {
    closeOnSelect: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    margin: String,
    placeholder: String,
    presetPicker: {
      type: Boolean,
      default: true
    },
    range: {
      type: Boolean,
      default: true
    },
    timePicker: Boolean,
    value: {
      type: [Object, Date]
    }
  },
  components: { Icon },
  data () {
    return {
      availableDates: null,
      dates: null,
      heightStyle: '',
      positionStyle: '',
      selecting: false,
      showDatepicker: false
    }
  },
  computed: {
    formattedDate () {
      const format = this.timePicker ? this.$t('date.datetimeFormat') : this.$t('date.dateFormat')
      let value = ''
      if (this.value && this.value.start && this.value.end) {
        value = `${this.$dayjs(this.value.start).format(format)} - ${this.$dayjs(this.value.end).format(format)}`
      } else if (this.value) {
        value = this.$dayjs(this.value).format(format)
      }
      return value
    }
  },
  methods: {
    cancel () {
      this.showDatepicker = false
      this.dates = this.getDates('reset')
    },
    clickHandler (e) {
      if (this.showDatepicker === true &&
        !this.$refs.datepickerWrapper.contains(e.target) &&
        !this.$refs.datepicker.$slots.default[0].elm.contains(e.target)) {
        this.cancel()
      }
    },
    isDates (name) {
      const dates = this.getDates(name)
      return this.dates &&
        this.dates.end.valueOf() === dates.end.valueOf() &&
        this.dates.start.valueOf() === dates.start.valueOf()
    },
    getDates (name) {
      let dates = null

      switch (name) {
        case 'today':
          dates = {
            end: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').toDate(),
            start: this.$dayjs(new Date().setHours(0, 0, 0, 0)).toDate()
          }
          break
        case 'yesterday':
          dates = {
            end: this.$dayjs(new Date().setHours(0, 0, 0, 0)).toDate(),
            start: this.$dayjs(new Date().setHours(0, 0, 0, 0)).subtract(1, 'day').toDate()
          }
          break
        case 'last_week':
          dates = {
            end: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').toDate(),
            start: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').subtract(1, 'week').toDate()
          }
          break
        case 'last_month':
          dates = {
            end: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').toDate(),
            start: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').subtract(1, 'month').toDate()
          }
          break
        case 'last_year':
          dates = {
            end: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').toDate(),
            start: this.$dayjs(new Date().setHours(0, 0, 0, 0)).add(1, 'day').subtract(1, 'year').toDate()
          }
          break
        case 'reset':
          dates = this.value
          break
      }

      return dates
    },
    save () {
      this.$emit(
        'input',
        this.dates
      )
      this.cancel()
    },
    setDates (dates) {
      if (this.range) {
        const datesObj = dates && this.timePicker === false
          ? {
              end: this.$dayjs(new Date(dates.end).setHours(0, 0, 0, 0)).toDate(),
              start: this.$dayjs(new Date(dates.start).setHours(0, 0, 0, 0)).toDate()
            }
          : dates

        if (!datesObj || !this.dates ||
          datesObj.end.getTime() !== this.dates.end.getTime() ||
          datesObj.start.getTime() !== this.dates.start.getTime()) {
          this.dates = datesObj
          if (this.closeOnSelect === true) {
            this.save()
          }
        }
      } else {
        this.dates = dates
        if (this.closeOnSelect === true) {
          this.save()
        }
      }
    },
    setPreset (name) {
      this.setDates(this.getDates(name))
    },
    show () {
      this.showDatepicker = true
      this.updatePositionHeightStyle()
    },
    toggle () {
      if (this.showDatepicker === false && this.disabled === false) {
        this.show()
      } else {
        this.cancel()
      }
    },
    updatePositionHeightStyle () {
      const bbox = this.$refs.datepickerWrapper.getBoundingClientRect()
      const FOOTER_HEIGHT = this.closeOnSelect ? 2 : 56 // Footer height + borders

      if (bbox.top + bbox.height / 2 < window.innerHeight / 2) {
        this.positionStyle = `top: ${bbox.bottom}px`
        this.heightStyle = `max-height: ${window.innerHeight - bbox.bottom - FOOTER_HEIGHT}px`
      } else {
        this.positionStyle = `bottom: ${window.innerHeight - bbox.top}px`
        this.heightStyle = `max-height: ${window.innerHeight - (window.innerHeight - bbox.top) - FOOTER_HEIGHT}px`
      }

      if (bbox.left + bbox.width / 2 < window.innerWidth / 2) {
        this.positionStyle += `; left: ${bbox.left}px`
      } else {
        this.positionStyle += `; right: ${window.innerWidth - bbox.right}px`
      }

      if (this.margin) this.positionStyle += `; margin: ${this.margin} 0`
    },
    updateMaxInterval (val) {
      if (this.selecting === false) {
        this.selecting = true
        this.availableDates = {
          start: new Date(this.$dayjs(val.start).subtract(1, 'year').format()),
          end: new Date(this.$dayjs(val.start).add(1, 'year').format())
        }
      } else if (val === null) {
        this.selecting = false
        this.availableDates = null
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.updatePositionHeightStyle)
    window.addEventListener('scroll', this.updatePositionHeightStyle, true)
    document.addEventListener('click', this.clickHandler)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updatePositionHeightStyle)
    window.removeEventListener('scroll', this.updatePositionHeightStyle, true)
    document.removeEventListener('click', this.clickHandler)
  },
  watch: {
    value: {
      handler (val) {
        this.dates = val
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.datepicker-wrapper {
  position: relative;
  overflow: hidden;
}

.gb-field {
  display: flex;
  align-items: center;
  overflow: hidden;

  .placeholder {
    color: $grey-dark;
  }

  span {
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    cursor: pointer;
  }

  &[data-disabled="true"] {
    background-color: lighten($color: $grey, $amount: 5%);
    opacity: .75;

    &:focus {
      box-shadow: inset 0 0 0 1.5px $grey;
    }

    &:hover {
      cursor: not-allowed;
    }
  }
}

.datepicker {
  background-color: white;
  border-radius: 6px;
  border: 1px solid $grey;
  box-shadow: 0 20px 40px 0 rgba(66,97,158,0.2);
  overflow: auto;
  position: fixed;
  z-index: 999;

  & > div {
    display: flex;

    &.no-padding .vc-container {
      padding: 0;
    }
  }

  ul {
    list-style: none;
    padding: 10px 20px;
    margin: 0;
    border-right: 1px solid $grey;
    min-width: 180px;
    display: flex;
    flex-direction: column;

    li {
      font-weight: 500;
      padding: 5px 0;
      transition: color .1s;
      color: $content-light;

      &.active {
        font-weight: bold;
        color: $content;
      }

      &.space {
        flex-grow: 1;
      }

      &.reset {
        color: $grey-dark;
        font-size: 13px;
      }

      &:hover {
        cursor: pointer;
        color: $content;
      }
    }
  }

  .vc-container {
    border: none;
    margin: auto;
    padding: 10px;

    ::v-deep  .vc-date {
      font-size: 10px;
    }
  }

  ul,
  .vc-container {
    overflow: auto;
  }

  @media (max-width: $break-large) {
    top: 50% !important;
    left: 50% !important;
    bottom: auto !important;
    right: auto !important;
    transform: translate(-50%, -50%) !important;
    max-height: 95vh;

    & > div {
      flex-direction: column;

      & > ul {
        border: none;
      }

      & > ul,
      & > .vc-container {
        max-height: none !important;
      }
    }
  }

  footer {
    border-top: 1px solid $grey;
    padding: 10px;
    text-align: right;

    button {
      font-family: 'Be Vietnam';
      font-weight: 500;
      font-size: 14px;
      border-radius: 5px;
      border: none;
      padding: 5px 18px;
      background-color: transparent;
      outline: none;

      &.btn-save {
        background-color: $primary;
        color: white;
      }

      &.btn-cancel {
        border: 1px solid $grey-dark;
        color: darken($grey-dark, 20%);
      }

      &:not(:last-child) {
        margin-right: .5rem;
      }

      &:focus {
        box-shadow: 0 0 8px rgba($color: $primary, $alpha: 50%);
        border-color: $primary;
      }
    }
  }
}

.fade-leave-active,
.fade-enter-active {
  transition: all .25s ease;
  pointer-events: none;
}
.fade-enter, .fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
