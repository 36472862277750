import { Commit, Dispatch } from 'vuex'
import {
  Guest,
  SearchFilters,
  SearchResults,
  Session,
  SessionEvents,
  State
} from '@/types/guests'

const state: State = {
  extraDataKeys: null
}

const getters = {
  extraDataKeys (state: State): Array<string> | null {
    return state.extraDataKeys
  }
}

const mutations = {
  extraDataKeys (state: State, data: Array<string> | null): void {
    state.extraDataKeys = data
  }
}

const actions = {
  fetchExtraDataKeys ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }): Promise<Array<string>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_GUESTS_URL}/extra-data-keys`)
        .then((response: Response) => response.json())
        .then(({ keys }: { keys: Array<string> }) => {
          commit('extraDataKeys', keys)
          resolve(keys)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchGuest ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<Guest> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_GUESTS_URL}/session-groups/${id}/guests`)
        .then((response: Response) => response.json())
        .then((data: Guest) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSessionEvents (
    { dispatch, rootGetters }: { dispatch: Dispatch, rootGetters: any},
    params: { 'cutoff_datetime': string, id: string, order: string }
  ): Promise<SessionEvents> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_GUESTS_URL}/session-groups/${params.id}/guests/messaging-events`, {
        params: {
          page_size: 50,
          ...params
        }
      })
        .then((response: Response) => response.json())
        .then((data: SessionEvents) => {
          data.data = data.data
            .map(msg => {
              const fullname = (msg.agent_name || '').split(' ')
              return {
                ...msg,
                agent: {
                  avatar: rootGetters['user/customer'].logo,
                  email: msg.agent_email,
                  first_name: fullname[0],
                  last_name: fullname[1],
                  uuid: msg.agent_id
                },
                created: new Date(msg.created_at).getTime() / 1000,
                event: msg.id,
                guest: msg.guest_id,
                kind: msg.kind.replace('_message', '_msg'),
                skill: msg.skill_id
              }
            })

          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchSessions ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, id: string): Promise<Array<Session>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_GUESTS_URL}/session-groups/${id}/guests/sessions`)
        .then((response: Response) => response.json())
        .then((data: Array<Session>) => {
          commit('GBA/rooms/updateRoomHistory', data, { root: true })
          resolve(data)
        }).catch((err: Error) => {
          commit('GBA/rooms/updateRoomHistory', [], { root: true })
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  markSessionAsRead ({ dispatch }: { dispatch: Dispatch }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_GUESTS_URL}/sessions/${id}/reset-unread-message-count`)
        .then(() => {
          resolve()
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  saveGuest ({ dispatch }: { dispatch: Dispatch }, guest: Guest): Promise<Guest> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_GUESTS_URL}/session-groups/${guest.session_group_id}/guests`, guest)
        .then((response: Response) => response.json())
        .then((data: Guest) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  searchSessions ({ dispatch }: { dispatch: Dispatch }, filters: SearchFilters): Promise<SearchResults> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_GUESTS_URL}/sessions/search`, filters)
        .then((response: Response) => response.json())
        .then((data: SearchResults) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
