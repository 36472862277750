<template v-slot="main">
  <div class="modal-container">
    <div class="modal-header">
      <close-modal-button :closeModal="closeModal" />
      <h3 class="gb-title">
        {{ $t('error.permission_denied.title')}}
      </h3>
    </div>
    <div class="modal-body">
      <p>
        {{ $t('error.permission_denied.details') }}
      </p>
    </div>

    <div class="modal-footer">
      <button v-on:click="validate" class="gb-btn">
        {{ $t('OK') }}
      </button>
    </div>
  </div>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue'
import CloseModalButton from '@/components/CloseModalButton.vue'

export default {
  extends: BaseModal,
  name: 'PermissionDeniedModal',
  components: {
    CloseModalButton
  },
  methods: {
    validate () {
      this.closeModal()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

.modal-container {
  padding: 50px;

  .modal-header {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.modal-body {
  text-align: left;
  padding: 0;

  .gb-title {
    margin-bottom: 7px;
  }

  p {
    font-size: 16px;
    color: $content-light;
    line-height: 26px;
    margin: 33px 0;
  }
}

.modal-footer {
  padding: 0;

  .gb-btn {
    float: right;
  }
}
</style>
