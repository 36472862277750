import firebase from 'firebase/app'
import 'firebase/messaging'

import { Commit, Dispatch } from 'vuex'

interface ApplePayload {
  action: string,
  force?: boolean
}

interface State {
  authorized: boolean,
  enabled: boolean,
  firebaseMessaging: firebase.messaging.Messaging | null,
  isAndroidDevice: boolean | null,
  isAppleDevice: boolean | null,
  token: string | null
}

interface RegisterData {
  activated: boolean,
  kind: string,
  token: string | null
}

declare global {
  interface Window {
    webkit?: any
  }
}

// initial state
const state: State = {
  authorized: false,
  enabled: true,
  firebaseMessaging: null,
  isAndroidDevice: null,
  isAppleDevice: null,
  token: null
}

// getters
const getters = {
  authorized (state: State): boolean {
    return state.authorized
  },
  enabled (state: State): boolean {
    return state.enabled
  },
  firebaseMessaging (state: State): firebase.messaging.Messaging | null {
    return state.firebaseMessaging
  },
  isAndroidDevice (state: State): boolean | null {
    return state.isAndroidDevice
  },
  isAppleDevice (state: State): boolean | null {
    return state.isAppleDevice
  },
  token (state: State): string | null {
    return state.token
  }
}

// mutations
const mutations = {
  setAuthorized (state: State, data: boolean): void {
    state.authorized = data
  },
  setFirebaseMessaging (state: State, data: firebase.messaging.Messaging): void {
    state.firebaseMessaging = data
  },
  setIsAndroidDevice (state: State, data: boolean): void {
    state.isAndroidDevice = data
  },
  setIsAppleDevice (state: State, data: boolean): void {
    state.isAppleDevice = data
  },
  setEnabled (state: State, data: boolean): void {
    state.enabled = data
  },
  setToken (state: State, data: string | null): void {
    state.token = data
  }
}

// actions
const actions = {
  callNative ({ getters }: { getters: any }, payload: ApplePayload): void {
    if (getters.isAppleDevice) {
      try {
        window.webkit.messageHandlers.nativeInterface.postMessage(payload)
      } catch (err) {
        alert(`Can not reach native code: ${err}`)
      }
    }
  },
  fetchEnabled ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, token: string | null): Promise<any> {
    if (!token) {
      token = getters.token
    }

    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_NOTIFICATIONS_URL}/register?token=${token}`)
        .then((response: Response) => response.json())
        .then((data: any) => {
          commit('setEnabled', data.activated)
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  initFirebase ({ commit }: { commit: Commit }): void {
    firebase.initializeApp({
      apiKey: 'AIzaSyAGLD2KKn4B0fvTdLJnWf6yT7BBqNMgXPg',
      authDomain: 'app-chayall.firebaseapp.com',
      projectId: 'app-chayall',
      storageBucket: 'app-chayall.appspot.com',
      messagingSenderId: '330330730967',
      appId: '1:330330730967:web:a42b2750ef6d69554e1b92'
    })

    const messaging = firebase.messaging()
    messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE)
    commit('setFirebaseMessaging', messaging)
  },
  register ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, data: RegisterData): void {
    if (data.token) {
      commit('setToken', data.token)
      commit('setEnabled', data.activated)

      data.kind = getters.isAppleDevice
        ? 'apple'
        : 'android'

      ;(this as any)._vm.$http.post(`${process.env.VUE_APP_NOTIFICATIONS_URL}/register`, data)
        .catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
        })
    }
  },
  saveAuthorisation ({ commit, dispatch, getters }: { commit: Commit, dispatch: Dispatch, getters: any }, authorized: boolean): void {
    if (authorized !== getters.authorized) {
      commit('setAuthorized', authorized)
      dispatch('register', {
        activated: authorized,
        token: getters.token
      })
    }
  },
  saveEnabled ({ dispatch, getters }: { dispatch: Dispatch, getters: any }, enabled: boolean): void {
    dispatch('register', {
      activated: enabled,
      token: getters.token
    })
  },
  saveToken ({ dispatch, getters }: { dispatch: Dispatch, getters: any }, token: string): void {
    dispatch('fetchEnabled', token).finally(() => {
      dispatch('register', {
        activated: getters.enabled,
        token: token
      })
    })
  },
  triggerAskApplePerms ({ dispatch }: { dispatch: Dispatch }, force?: boolean): void {
    dispatch('callNative', { action: 'askApplePerms', force: !!force })
  },
  triggerGetAppleDeviceToken ({ dispatch }: { dispatch: Dispatch }): void {
    dispatch('callNative', { action: 'getDeviceToken' })
  },
  triggerGetAndroidDeviceToken ({ dispatch, getters }: { dispatch: Dispatch, getters: any }): void {
    getters.firebaseMessaging.getToken()
      .then((token: string) => {
        dispatch('saveToken', token)
      })
  },
  triggerAskAndroidPerms ({ dispatch }: { dispatch: Dispatch }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!Notification) {
        resolve(false)
      } else {
        Notification.requestPermission()
          .then(permission => {
            const permissionGranted = permission === 'granted'
            dispatch('saveAuthorisation', permissionGranted)
            resolve(permissionGranted)
          }).catch(err => {
            dispatch('saveAuthorisation', false)
            reject(err)
          })
      }
    })
  },
  updateDeviceType ({ commit }: { commit: Commit }): void {
    commit('setIsAppleDevice', !!(window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.nativeInterface)
    )

    commit('setIsAndroidDevice', !!matchMedia('(display-mode: standalone)').matches)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
