import { Commit, Dispatch } from 'vuex'
import { Category, Subcategory, Sentence, State } from '@/types/msglib'

interface Data {
  category?: string
  subcategory?: string
  sentence?: string
}

function formatUrl (data: Data, getChildren = false): string {
  let url = '/categories'

  if (data.category) {
    url += '/' + data.category
    if (getChildren && !data.subcategory) {
      url += '/subcategories'
    }
  }

  if (data.subcategory) {
    url += '/subcategories/' + data.subcategory
    if (getChildren && !data.sentence) {
      url += '/sentences'
    }
  }

  if (data.sentence) {
    url += '/sentences/' + data.sentence
  }

  return url
}

// initial state
const state: State = {
  current: null,
  list: null,
  selected: null
}

// getters
const getters = {
  current (state: State): Category | Subcategory | Sentence | null {
    return state.current
  },
  list (state: State): Array<Category | Subcategory | Sentence> | null {
    return state.list
  },
  selected (state: State): string | null {
    return state.selected
  }
}

// mutations
const mutations = {
  current (state: State, value: Category | Subcategory | Sentence | null): void {
    state.current = value
  },
  list (state: State, value: Array<Category | Subcategory | Sentence> | null): void {
    state.list = value
  },
  selected (state: State, value: string | null): void {
    state.selected = value
  }
}

// actions
const actions = {
  fetch ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: Data): Promise<Category | Subcategory | Sentence> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_MSGLIB_URL}${formatUrl(data)}`)
        .then((response: Response) => response.json())
        .then((item: Category | Subcategory | Sentence) => {
          commit('current', item)
          resolve(item)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  fetchList ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: Data): Promise<Array<Category | Subcategory | Sentence>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.get(`${process.env.VUE_APP_MSGLIB_URL}${formatUrl(data, true)}`)
        .then((response: Response) => response.json())
        .then((items: Array<Category | Subcategory | Sentence>) => {
          commit('list', items)
          resolve(items)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  remove ({ dispatch }: { dispatch: Dispatch }, data: Data): Promise<any> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.delete(`${process.env.VUE_APP_MSGLIB_URL}${formatUrl(data)}`)
        .then((data: any) => {
          resolve(data)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  save ({ dispatch }: { dispatch: Dispatch }, { data, edit }: { data: Category | Subcategory | Sentence, edit: boolean }): Promise<Category | Subcategory | Sentence> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_MSGLIB_URL}${formatUrl(data as unknown as Data, !edit)}`, data)
        .then((response: Response) => response.json())
        .then((item: Category | Subcategory | Sentence) => {
          resolve(item)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  },
  search ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, search: string): Promise<Array<Sentence>> {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$http.post(`${process.env.VUE_APP_MSGLIB_URL}/sentences/search`, {
        search
      })
        .then((response: Response) => response.json())
        .then((sentences: Array<Sentence>) => {
          commit('list', sentences)
          resolve(sentences)
        }).catch((err: Error) => {
          dispatch('global/handleHttpError', err, { root: true })
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
