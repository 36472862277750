<template>
  <div class="user-infos" v-if="user !== null">
    <ul>
      <li>
        <button class="user" v-on:click.stop="toggleOptions()">
          <span>{{ username }}</span>
          <div class="avatar">
            <img v-if="customer" :src="customer.logo" alt="Logo">
          </div>
        </button>
        <header-dropdown class="dropdown" :show="$store.getters['global/isUserMenuShown'] === true" :onClickOutside="closeDropdown">
          <ul>
            <li class="option-menu" id="language-select">
              <icon name="language" style="margin: 0"/>
              <LanguageSelect :callback="toggleOptions" />
            </li>
            <li v-if="$settings.saas === true || tutoURL">
              <a v-if="$settings.saas" class="option-menu" :href="$t('faq.link')" target="_blank"
                  v-on:click="toggleOptions(false)">
                <icon name="help_outline"/> {{ $t('faq.title') }}
              </a>
              <a v-else class="option-menu" :href="tutoURL" target="_blank"
                  v-on:click="toggleOptions(false)">
                <icon name="help_outline"/> {{ $t('faq.title') }}
              </a>
            </li>
            <li v-if="$settings.saas">
              <button class="option-menu" v-on:click="showPasswordModal()">
                <icon name="lock_open"/> {{ $t('menu.change_password_title') }}
              </button>
            </li>
            <li v-if="isAllowedNavLink('settings')">
              <router-link class="option-menu" :to="{ name: 'settings' }"
                  v-on:click.native="toggleOptions(false)">
                <icon name="settings"/> {{ $t('menu.settings') }}
              </router-link>
            </li>
            <li>
              <button class="option-menu" v-on:click="logout()">
                <icon name="exit_to_app"/> {{ $t('menu.logout') }}
              </button>
            </li>
          </ul>
        </header-dropdown>
      </li>
    </ul>
  </div>
</template>

<script>
import HeaderDropdown from '@/components/HeaderDropdown.vue'
import Icon from '@savoygu/vue-material-design-icons/src/components/Icon'
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'UserInfos',
  components: { HeaderDropdown, Icon, LanguageSelect },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    customer () {
      return this.$store.getters['user/customer']
    },
    tutoURL () {
      let url = ''
      const route = this.$route.name
      const isAgent = this.$settings.permissions[this.user.user_role] === this.$settings.permissions.agent
      const hasParentRoute = (parentRoute) => {
        if (this.$route.matched.some(route => route.name === parentRoute)) {
          return route
        }
        return null
      }

      switch (route) {
        case hasParentRoute('bot-builder'):
        case hasParentRoute('bot-builder-beta'):
          url += this.$t('faq.file.bot-builder')
          break
        case hasParentRoute('botgroups'):
          url += this.$t('faq.file.botgroups')
          break
        case hasParentRoute('campaigns'):
          url += this.$t('faq.file.campaigns')
          break
        case hasParentRoute('consoles'):
          url += this.$t('faq.file.consoles')
          break
        case hasParentRoute('dashboard'):
          url += this.$t('faq.file.dashboard')
          break
        case hasParentRoute('dialogflow'):
          url += this.$t('faq.file.dialogflow')
          break
        case hasParentRoute('gb-agent'):
          url += this.$t(isAgent ? 'faq.file.gb-agent_agent' : 'faq.file.gb-agent')
          break
        case hasParentRoute('messaging'):
          url += this.$t('faq.file.messaging')
          break
        case hasParentRoute('settings'):
          url += this.$t(isAgent ? 'faq.file.settings_agent' : 'faq.file.settings')
          break
        case hasParentRoute('stats'):
          url += this.$t('faq.file.stats')
          break
        case hasParentRoute('third-parties'):
          url += this.$t('faq.file.third_parties')
          break
        case hasParentRoute('webivr'):
          url += this.$t('faq.file.webivr')
          break
        case hasParentRoute('webivr-builder'):
          url += this.$t('faq.file.webivr_builder')
          break
      }

      return url ? this.publicPath + url : null
    },
    user () {
      return this.$store.getters['user/user'] || null
    },
    username () {
      let username
      if (this.user.first_name && this.user.last_name) {
        username = `${this.user.first_name} ${this.user.last_name}`
      } else {
        username = `${this.user.email}`
      }
      return username
    }
  },
  methods: {
    fetchData () {
      if (this.customer === null) {
        this.$store.dispatch('user/fetchCustomer')
      }
    },
    isAllowedNavLink (name) {
      const { resolved } = this.$router.resolve({ name })
      return this.$settings.isAllowedRoute(resolved)
    },
    logout () {
      const openRoomsCount = this.$store.getters['GBA/rooms/getOpenRoomsCount']
      if (openRoomsCount === 0) {
        this.realLogout()
      } else {
        this.$store.dispatch('global/showModal', {
          action: 'warn-open-rooms',
          modalConfirm: this.realLogout
        })
        this.toggleOptions(false)
      }
    },
    closeDropdown () {
      this.toggleOptions(false)
    },
    realLogout () {
      this.toggleOptions(false)
      this.$store.dispatch('user/logout')
    },
    showPasswordModal () {
      this.$store.dispatch('global/showModal', 'password-change')
      this.toggleOptions(false)
    },
    toggleOptions (force) {
      this.$nextTick(() => {
        this.$store.commit('global/updateUserMenuShown', force)
      })
    }
  },
  watch: {
    $route: 'fetchData'
  }
}
</script>

<style lang="scss" scoped="true">
@import "@/assets/scss/global.scss";
.user-infos {
  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    transition: all .15s;
    outline: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & > ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > li {
      position: relative;

      &:not(:last-child) {
        margin: 0 2.5px;

        @media (max-width: $break-large) {
          margin: 0 4px;
        }
      }

      &:last-child {
        margin-left: 30px;

        @media (max-width: $break-large) {
          margin-left: 0;
        }
      }
    }
  }

  .user {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
    white-space: nowrap;

    @media (max-width: $break-large) {
      font-size: 0;
    }
  }

  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 20px;
    flex-shrink: 0;

    img {
      max-width: 100%;
      max-height: 100%;
      filter: drop-shadow(0 0 1px $grey);
    }

    @media (max-width: $break-large) {
      margin-left: 10px;
    }
  }

  .material-design-icon {
    vertical-align: middle;
    font-size: 24px;
  }

  .dropdown {
    ul {
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      width: 100%;

      li {
        text-align: left;
      }
    }

    ::v-deep .option-menu,
    ::v-deep .gb-select * {
      font-weight: normal;
      margin: 0;
      width: 100%;
      border: none;
      color: $content-light;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: bold;
      padding: 8px 12px;
      text-align: left;
      text-decoration: none;
      width: 100%;
      white-space: nowrap;

      .material-design-icon {
        margin-right: 5px;
        vertical-align: bottom;
        font-size: 17px;
      }

      &:hover {
        background-color: rgba($color: $primary, $alpha: .1);

        &, * {
          text-decoration: none;
          color: $primary
        }
      }

      &:active {
        outline: none;
      }
    }
  }
}

#language-select {
  ::v-deep > .gb-select {
    flex-grow: 1;

    .gb-field {
      padding-left: 5px !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-right: 20px !important;
      border: none;
    }

    .chevron {
      &, svg {
        width: auto;
        margin: 0;
        padding: 0;
        right: 0;
      }
    }
  }
}
</style>
